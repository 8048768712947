const airportDetails = [
  {
    airportCode: "WMI",
    airportName: "Warsaw-Modlin",
    cityCode: "WAW",
    cityName: "Warsaw",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "YJB",
    airportName: "BARCELONA SANTS RAILWAY STATION",
    cityCode: "YJB",
    cityName: "Catalonia",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "YJE",
    airportName: "ALICANTE RAIL ALC",
    cityCode: "ALC",
    cityName: "Alicante",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "XOC",
    airportName: "MADRID ATOCHA RAILWAY STATION",
    cityCode: "MAD",
    cityName: "MADRID",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "JGS",
    airportName: "Ji An/Jinggangshan Airport",
    cityCode: "JGS",
    cityName: "Guiyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YTY",
    airportName: "Yangzhou Taizhou Airport",
    cityCode: "YTY",
    cityName: "Yangzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NJF",
    airportName: "Al-Najaf International Airport",
    cityCode: "NJF",
    cityName: "Al Najaf",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "HRI",
    airportName: "Mattala Rajapaksa International Airport",
    cityCode: "HRI",
    cityName: "Hambantota",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "IGD",
    airportName: "Igdir Airport",
    cityCode: "IGD",
    cityName: "Igdir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "NGQ",
    airportName: "Ngari Gunsa Airport",
    cityCode: "NGQ",
    cityName: "Ngari",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "RGS",
    airportName: "Burgos airport",
    cityCode: "RGS",
    cityName: "Burgos",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "GZP",
    airportName: "Gazipasa Airport",
    cityCode: "GZP",
    cityName: "Gazipasa",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "NBS",
    airportName: "Changbaishan Airport",
    cityCode: "NBS",
    cityName: "Baishan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WNH",
    airportName: "Wenshan Puzhehei Airport",
    cityCode: "WNH",
    cityName: "Wenshan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NBE",
    airportName: "Hammamet International Airport",
    cityCode: "NBE",
    cityName: "Enfidha",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "ABP",
    airportName: "Atkamba",
    cityCode: "ABP",
    cityName: "Atkamba",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ABQ",
    airportName: "Albuquerque Intl.",
    cityCode: "ABQ",
    cityName: "Albuquerque",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ABR",
    airportName: "Municipal",
    cityCode: "ABR",
    cityName: "Aberdeen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ABS",
    airportName: "Abu Simbel",
    cityCode: "ABS",
    cityName: "Abu Simbel",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "ABT",
    airportName: "Al-Baha Domestic Airport",
    cityCode: "ABT",
    cityName: "Al-Baha",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "ABU",
    airportName: "Atambua",
    cityCode: "ABU",
    cityName: "Atambua",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ABV",
    airportName: "Nnamdi Azikiwe Intl. Arpt.",
    cityCode: "ABV",
    cityName: "Abuja",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "ABW",
    airportName: "Abau",
    cityCode: "ABW",
    cityName: "Abau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LOP",
    airportName: "Lombok International Airport",
    cityCode: "LOP",
    cityName: "Lombok",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "QYG",
    airportName: "RAILWAY GERMANY",
    cityCode: "QYG",
    cityName: "RAILWAY GERMANY",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "NAV",
    airportName: "Nevsehir",
    cityCode: "NAV",
    cityName: "Nevsehir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ECP",
    airportName: "Northwest Florida Beaches Intl.",
    cityCode: "ECP",
    cityName: "Panama City, FL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "QOW",
    airportName: "Owerri",
    cityCode: "QOW",
    cityName: "Owerri",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "ACE",
    airportName: "Lanzarote",
    cityCode: "ACE",
    cityName: "Arrecife",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "ACH",
    airportName: "Altenrhein",
    cityCode: "ACH",
    cityName: "Altenrhein",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "ACI",
    airportName: "The Blaye",
    cityCode: "ACI",
    cityName: "Alderney",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ACJ",
    airportName: "Anuradhapura",
    cityCode: "ACJ",
    cityName: "Anuradhapura",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "ACK",
    airportName: "Nantucket Memorial",
    cityCode: "ACK",
    cityName: "Nantucket",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ACL",
    airportName: "Aguaclara",
    cityCode: "ACL",
    cityName: "Aguaclara",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ACM",
    airportName: "Arica",
    cityCode: "ACM",
    cityName: "Arica",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ACN",
    airportName: "Intl.",
    cityCode: "ACN",
    cityName: "Ciudad Acuna",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "ACO",
    airportName: "Ascona",
    cityCode: "ACO",
    cityName: "Ascona",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "ACP",
    airportName: "Sahand",
    cityCode: "ACP",
    cityName: "Sahand",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "ACR",
    airportName: "Araracuara",
    cityCode: "ACR",
    cityName: "Araracuara",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ACS",
    airportName: "Achinsk",
    cityCode: "ACS",
    cityName: "Achinsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ACT",
    airportName: "Municipal",
    cityCode: "ACT",
    cityName: "Waco",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ACU",
    airportName: "Achutupo",
    cityCode: "ACU",
    cityName: "Achutupo",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "ACV",
    airportName: "Arcata",
    cityCode: "ACV",
    cityName: "Arcata",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ACX",
    airportName: "Xingyi",
    cityCode: "ACX",
    cityName: "Xingyi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ACY",
    airportName: "Atlantic City Intl",
    cityCode: "ACY",
    cityName: "Atlantic City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ACZ",
    airportName: "Zabol A/P",
    cityCode: "ACZ",
    cityName: "Zabol",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "ADA",
    airportName: "Adana",
    cityCode: "ADA",
    cityName: "Adana",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ADB",
    airportName: "Adnan Menderes",
    cityCode: "ADB",
    cityName: "Izmir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ADC",
    airportName: "Andakombe",
    cityCode: "ADC",
    cityName: "Andakombe",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ADD",
    airportName: "Bole Intl.",
    cityCode: "ADD",
    cityName: "Addis Ababa",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "ADE",
    airportName: "Intl.",
    cityCode: "ADE",
    cityName: "Aden",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "ADF",
    airportName: "Adiyaman",
    cityCode: "ADF",
    cityName: "Adiyaman",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ADG",
    airportName: "Lenawee County",
    cityCode: "ADG",
    cityName: "Adrian",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ADH",
    airportName: "Aldan",
    cityCode: "ADH",
    cityName: "Aldan",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ADI",
    airportName: "Arandis",
    cityCode: "ADI",
    cityName: "Arandis",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "ADJ",
    airportName: "Marka Intl. Arpt.",
    cityCode: "AMM",
    cityName: "Amman",
    countryName: "Jordan",
    countryCode: "JO",
  },
  {
    airportCode: "ADK",
    airportName: "Adak Island Ns",
    cityCode: "ADK",
    cityName: "Adak Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ADL",
    airportName: "Adelaide Intl. Arpt.",
    cityCode: "ADL",
    cityName: "Adelaide",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ADM",
    airportName: "Ardmore Municipal Arpt",
    cityCode: "ADM",
    cityName: "Ardmore",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ADN",
    airportName: "Andes",
    cityCode: "ADN",
    cityName: "Andes",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ADO",
    airportName: "Andamooka",
    cityCode: "ADO",
    cityName: "Andamooka",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ADP",
    airportName: "Ampara",
    cityCode: "ADP",
    cityName: "Ampara",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "ADQ",
    airportName: "Kodiak Arpt.",
    cityCode: "ADQ",
    cityName: "Kodiak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ADR",
    airportName: "Andrews",
    cityCode: "ADR",
    cityName: "Andrews",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ADS",
    airportName: "Addison Arpt.",
    cityCode: "DFW",
    cityName: "Dallas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ADU",
    airportName: "Ardabil",
    cityCode: "ADU",
    cityName: "Ardabil",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "ADV",
    airportName: "Andover",
    cityCode: "ADV",
    cityName: "Andover",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ADW",
    airportName: "Andrews AFB",
    cityCode: "ADW",
    cityName: "Camp Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ADX",
    airportName: "Leuchars",
    cityCode: "ADX",
    cityName: "St Andrews",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ADY",
    airportName: "Alldays",
    cityCode: "ADY",
    cityName: "Alldays",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "ADZ",
    airportName: "San Andres Island",
    cityCode: "ADZ",
    cityName: "San Andres Island",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AGN",
    airportName: "Angoon",
    cityCode: "AGN",
    cityName: "Angoon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AGO",
    airportName: "Municipal",
    cityCode: "AGO",
    cityName: "Magnolia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AGP",
    airportName: "Pablo Ruiz Picasso",
    cityCode: "AGP",
    cityName: "Malaga",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "AGQ",
    airportName: "Agrinion",
    cityCode: "AGQ",
    cityName: "Agrinion",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "AGR",
    airportName: "Kheria",
    cityCode: "AGR",
    cityName: "Agra",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "AGS",
    airportName: "Bush Field",
    cityCode: "AGS",
    cityName: "Augusta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AGT",
    airportName: "Alejo Garcia",
    cityCode: "AGT",
    cityName: "Ciudad Del Este",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "AGU",
    airportName: "Aguascalients",
    cityCode: "AGU",
    cityName: "Aguascalientes",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "AGW",
    airportName: "Agnew",
    cityCode: "AGW",
    cityName: "Agnew",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AGX",
    airportName: "Agatti Island",
    cityCode: "AGX",
    cityName: "Agatti Island",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "AGY",
    airportName: "Argyle Downs",
    cityCode: "AGY",
    cityName: "Argyle Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AGZ",
    airportName: "Aggeneys",
    cityCode: "AGZ",
    cityName: "Aggeneys",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "AHA",
    airportName: "Naha Air Force Base",
    cityCode: "AHA",
    cityName: "Okinawa",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "AHB",
    airportName: "Abha",
    cityCode: "AHB",
    cityName: "Abha",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "AHC",
    airportName: "Amedee AAF",
    cityCode: "AHC",
    cityName: "Herlong",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AHD",
    airportName: "Downtown",
    cityCode: "AHD",
    cityName: "Ardmore",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AHE",
    airportName: "Ahe Arpt.",
    cityCode: "AHE",
    cityName: "Ahe",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "AHF",
    airportName: "Municipal",
    cityCode: "AHF",
    cityName: "Arapahoe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AHH",
    airportName: "Municipal",
    cityCode: "AHH",
    cityName: "Amery",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AHI",
    airportName: "Amahai",
    cityCode: "AHI",
    cityName: "Amahai",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AHL",
    airportName: "Aishalton",
    cityCode: "AHL",
    cityName: "Aishalton",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "AHN",
    airportName: "Athens",
    cityCode: "AHN",
    cityName: "Athens",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AHO",
    airportName: "Fertilia",
    cityCode: "AHO",
    cityName: "Alghero",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "AHS",
    airportName: "Ahuas",
    cityCode: "AHS",
    cityName: "Ahuas",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "AHT",
    airportName: "Amchitka",
    cityCode: "AHT",
    cityName: "Amchitka",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AHU",
    airportName: "Charif Al Idrissi",
    cityCode: "AHU",
    cityName: "Al Hoceima",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "AHY",
    airportName: "Ambatolahy",
    cityCode: "AHY",
    cityName: "Ambatolahy",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "AHZ",
    airportName: "Alpe D Huez",
    cityCode: "AHZ",
    cityName: "Alpe D Huez",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "AIA",
    airportName: "Alliance",
    cityCode: "AIA",
    cityName: "Alliance",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AIB",
    airportName: "Anita Bay",
    cityCode: "AIB",
    cityName: "Anita Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AIC",
    airportName: "Airok",
    cityCode: "AIC",
    cityName: "Airok, Ailinglaplap",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "AID",
    airportName: "Municipal",
    cityCode: "AID",
    cityName: "Anderson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AIE",
    airportName: "Aiome",
    cityCode: "AIE",
    cityName: "Aiome",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AIF",
    airportName: "Assis",
    cityCode: "AIF",
    cityName: "Assis",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AIG",
    airportName: "Yalinga",
    cityCode: "AIG",
    cityName: "Yalinga",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "AIH",
    airportName: "Aiambak",
    cityCode: "AIH",
    cityName: "Aiambak",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AII",
    airportName: "Alisabieh",
    cityCode: "AII",
    cityName: "Alisabieh",
    countryName: "Djibouti",
    countryCode: "DJ",
  },
  {
    airportCode: "AIK",
    airportName: "Municipal",
    cityCode: "AIK",
    cityName: "Aiken",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AIL",
    airportName: "Ailigandi",
    cityCode: "AIL",
    cityName: "Ailigandi",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "AIM",
    airportName: "Ailuk Island",
    cityCode: "AIM",
    cityName: "Ailuk Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "AIN",
    airportName: "Wainwright",
    cityCode: "AIN",
    cityName: "Wainwright",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AIO",
    airportName: "Municipal",
    cityCode: "AIO",
    cityName: "Atlantic",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AIP",
    airportName: "Ailinglapalap Island",
    cityCode: "AIP",
    cityName: "Ailinglapalap Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "AIS",
    airportName: "Arorae Island",
    cityCode: "AIS",
    cityName: "Arorae Island",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "AIT",
    airportName: "Aitutaki",
    cityCode: "AIT",
    cityName: "Aitutaki",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "AIU",
    airportName: "Atiu Island",
    cityCode: "AIU",
    cityName: "Atiu Island",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "AIV",
    airportName: "George Downer",
    cityCode: "AIV",
    cityName: "Aliceville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AIW",
    airportName: "Ai-ais",
    cityCode: "AIW",
    cityName: "Ai-ais",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "AIY",
    airportName: "Bader Field",
    cityCode: "AIY",
    cityName: "Atlantic City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AIZ",
    airportName: "Lee C Fine Memorial",
    cityCode: "AIZ",
    cityName: "Kaiser/Lake Ozark",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AJA",
    airportName: "Campo Dell Oro",
    cityCode: "AJA",
    cityName: "Ajaccio",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "AJF",
    airportName: "Jouf",
    cityCode: "AJF",
    cityName: "Sakaka Al Jouf",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "AJI",
    airportName: "AÄŸri",
    cityCode: "AJI",
    cityName: "Agri",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "AJJ",
    airportName: "Akjoujt",
    cityCode: "AJJ",
    cityName: "Akjoujt",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "AJK",
    airportName: "Araak",
    cityCode: "AJK",
    cityName: "Araak",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "AJL",
    airportName: "Aizawl",
    cityCode: "AJL",
    cityName: "Aizawl",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "AJN",
    airportName: "Ouani",
    cityCode: "AJN",
    cityName: "Anjouan",
    countryName: "Comoros",
    countryCode: "KM",
  },
  {
    airportCode: "AJO",
    airportName: "Aljouf",
    cityCode: "AJO",
    cityName: "Aljouf",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "AJR",
    airportName: "Arvidsjaur",
    cityCode: "AJR",
    cityName: "Arvidsjaur",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "AJS",
    airportName: "Abreojos",
    cityCode: "AJS",
    cityName: "Abreojos",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "AJU",
    airportName: "Santa Maria",
    cityCode: "AJU",
    cityName: "Aracaju",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AJY",
    airportName: "Agades",
    cityCode: "AJY",
    cityName: "Agades",
    countryName: "Niger",
    countryCode: "NE",
  },
  {
    airportCode: "ALW",
    airportName: "Walla Walla",
    cityCode: "ALW",
    cityName: "Walla Walla",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ALX",
    airportName: "Thomas C Russell Fld",
    cityCode: "ALX",
    cityName: "Alexander City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AOD",
    airportName: "Abou Deia",
    cityCode: "AOD",
    cityName: "Abou Deia",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "AOE",
    airportName: "Anadolu University",
    cityCode: "AOE",
    cityName: "Eskisehir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "AOG",
    airportName: "Anshan",
    cityCode: "AOG",
    cityName: "Anshan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "AOH",
    airportName: "Allen County",
    cityCode: "AOH",
    cityName: "Lima",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AOI",
    airportName: "Falconara",
    cityCode: "AOI",
    cityName: "Ancona",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "AOJ",
    airportName: "Aomori",
    cityCode: "AOJ",
    cityName: "Aomori",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "AOK",
    airportName: "Karpathos",
    cityCode: "AOK",
    cityName: "Karpathos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "AOL",
    airportName: "Paso De Los Libres",
    cityCode: "AOL",
    cityName: "Paso De Los Libres",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "AON",
    airportName: "Arona",
    cityCode: "AON",
    cityName: "Arona",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AOO",
    airportName: "Martinsburg",
    cityCode: "AOO",
    cityName: "Martinsburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AOR",
    airportName: "Alor Setar",
    cityCode: "AOR",
    cityName: "Alor Setar",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "AOS",
    airportName: "Amook",
    cityCode: "AOS",
    cityName: "Amook",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AOU",
    airportName: "Attopeu",
    cityCode: "AOU",
    cityName: "Attopeu",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "APA",
    airportName: "Arapahoe Co",
    cityCode: "DEN",
    cityName: "Denver",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "APB",
    airportName: "Apolo",
    cityCode: "APB",
    cityName: "Apolo",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "APC",
    airportName: "Napa County",
    cityCode: "APC",
    cityName: "Napa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "APE",
    airportName: "San Juan Aposento",
    cityCode: "APE",
    cityName: "San Juan Aposento",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "APF",
    airportName: "Naples",
    cityCode: "APF",
    cityName: "Naples",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "APG",
    airportName: "Phillips AAF",
    cityCode: "APG",
    cityName: "Aberdeen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "APH",
    airportName: "Camp A P Hill",
    cityCode: "APH",
    cityName: "Bowling Green",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "API",
    airportName: "Apiay",
    cityCode: "API",
    cityName: "Apiay",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "APK",
    airportName: "Apataki",
    cityCode: "APK",
    cityName: "Apataki",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "APL",
    airportName: "Nampula",
    cityCode: "APL",
    cityName: "Nampula",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "APN",
    airportName: "Alpena County Regional",
    cityCode: "APN",
    cityName: "Alpena",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "APO",
    airportName: "Apartado",
    cityCode: "APO",
    cityName: "Apartado",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "APP",
    airportName: "Asapa",
    cityCode: "APP",
    cityName: "Asapa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "APQ",
    airportName: "Arapiraca",
    cityCode: "APQ",
    cityName: "Arapiraca",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "APR",
    airportName: "April River",
    cityCode: "APR",
    cityName: "April River",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "APS",
    airportName: "Anapolis",
    cityCode: "APS",
    cityName: "Anapolis",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "APT",
    airportName: "Marion County",
    cityCode: "APT",
    cityName: "Jasper",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "APU",
    airportName: "Apucarana",
    cityCode: "APU",
    cityName: "Apucarana",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "APV",
    airportName: "Apple Valley",
    cityCode: "APV",
    cityName: "Apple Valley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "APW",
    airportName: "Faleolo",
    cityCode: "APW",
    cityName: "Apia",
    countryName: "Samoa",
    countryCode: "WS",
  },
  {
    airportCode: "APX",
    airportName: "Arapongas",
    cityCode: "APX",
    cityName: "Arapongas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "APY",
    airportName: "Alto Parnaiba",
    cityCode: "APY",
    cityName: "Alto Parnaiba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "APZ",
    airportName: "Zapala",
    cityCode: "APZ",
    cityName: "Zapala",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "AQA",
    airportName: "Araraquara",
    cityCode: "AQA",
    cityName: "Araraquara",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AQB",
    airportName: "Quiche Arpt.",
    cityCode: "AQB",
    cityName: "Quiche",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "AQG",
    airportName: "Anqing",
    cityCode: "AQG",
    cityName: "Anqing",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "AQI",
    airportName: "Qaisumah",
    cityCode: "AQI",
    cityName: "Qaisumah",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "AQJ",
    airportName: "King Hussein Intl",
    cityCode: "AQJ",
    cityName: "Aqaba",
    countryName: "Jordan",
    countryCode: "JO",
  },
  {
    airportCode: "AQM",
    airportName: "Ariquemes",
    cityCode: "AQM",
    cityName: "Ariquemes",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AQP",
    airportName: "Rodriguez Ballon",
    cityCode: "AQP",
    cityName: "Arequipa",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "AQS",
    airportName: "Saqani",
    cityCode: "AQS",
    cityName: "Saqani",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "AQY",
    airportName: "Alyeska",
    cityCode: "AQY",
    cityName: "Alyeska",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ARA",
    airportName: "Acadiana Regional",
    cityCode: "ARA",
    cityName: "New Iberia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ARB",
    airportName: "Municipal",
    cityCode: "ARB",
    cityName: "Ann Arbor, MI",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ARC",
    airportName: "Arctic Village",
    cityCode: "ARC",
    cityName: "Arctic Village",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ARD",
    airportName: "Alor Island",
    cityCode: "ARD",
    cityName: "Alor Island",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ARE",
    airportName: "Arecibo",
    cityCode: "ARE",
    cityName: "Arecibo",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "ARF",
    airportName: "Acaricuara",
    cityCode: "ARF",
    cityName: "Acaricuara",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ARG",
    airportName: "Walnut Ridge",
    cityCode: "ARG",
    cityName: "Walnut Ridge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ARH",
    airportName: "Arkhangelsk",
    cityCode: "ARH",
    cityName: "Arkhangelsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ARI",
    airportName: "Chacalluta",
    cityCode: "ARI",
    cityName: "Arica",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ARJ",
    airportName: "Arso",
    cityCode: "ARJ",
    cityName: "Arso",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ARK",
    airportName: "Arusha",
    cityCode: "ARK",
    cityName: "Arusha",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "ARL",
    airportName: "Arly",
    cityCode: "ARL",
    cityName: "Arly",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "ARM",
    airportName: "Armidale",
    cityCode: "ARM",
    cityName: "Armidale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ARN",
    airportName: "Arlanda",
    cityCode: "STO",
    cityName: "Stockholm",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "ARO",
    airportName: "Arboletas",
    cityCode: "ARO",
    cityName: "Arboletas",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ARP",
    airportName: "Aragip",
    cityCode: "ARP",
    cityName: "Aragip",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ARQ",
    airportName: "Arauquita",
    cityCode: "ARQ",
    cityName: "Arauquita",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ARR",
    airportName: "Alto Rio Senguerr",
    cityCode: "ARR",
    cityName: "Alto Rio Senguerr",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "ARS",
    airportName: "Aragarcas",
    cityCode: "ARS",
    cityName: "Aragarcas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ART",
    airportName: "Watertown",
    cityCode: "ART",
    cityName: "Watertown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ARU",
    airportName: "Aracatuba",
    cityCode: "ARU",
    cityName: "Aracatuba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ARV",
    airportName: "Noble F. Lee",
    cityCode: "ARV",
    cityName: "Minocqua",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ARW",
    airportName: "Arad",
    cityCode: "ARW",
    cityName: "Arad",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "ARX",
    airportName: "Asbury Park",
    cityCode: "ARX",
    cityName: "Neptune, NJ",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ARY",
    airportName: "Ararat",
    cityCode: "ARY",
    cityName: "Ararat",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ASA",
    airportName: "Assab Intl.",
    cityCode: "ASA",
    cityName: "Assab",
    countryName: "Eritrea",
    countryCode: "ER",
  },
  {
    airportCode: "ASB",
    airportName: "Ashgabat",
    cityCode: "ASB",
    cityName: "Ashgabat",
    countryName: "Turkmenistan",
    countryCode: "TM",
  },
  {
    airportCode: "ASC",
    airportName: "Ascension",
    cityCode: "ASC",
    cityName: "Ascension",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "ASD",
    airportName: "Andros Town",
    cityCode: "ASD",
    cityName: "Andros Town",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "ASE",
    airportName: "Aspen",
    cityCode: "ASE",
    cityName: "Aspen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ASF",
    airportName: "Astrakhan",
    cityCode: "ASF",
    cityName: "Astrakhan",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ASG",
    airportName: "Ashburton",
    cityCode: "ASG",
    cityName: "Ashburton",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "ASH",
    airportName: "Boire Field",
    cityCode: "ASH",
    cityName: "Nashua, NH",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ASI",
    airportName: "Wideawake Field",
    cityCode: "ASI",
    cityName: "Georgetown, Ascension Island",
    countryName: "St. Helena",
    countryCode: "SH",
  },
  {
    airportCode: "ASJ",
    airportName: "Amami O Shima",
    cityCode: "ASJ",
    cityName: "Amami O Shima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "ASK",
    airportName: "Yamoussoukro",
    cityCode: "ASK",
    cityName: "Yamoussoukro",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "ASM",
    airportName: "Yohannes IV",
    cityCode: "ASM",
    cityName: "Asmara",
    countryName: "Eritrea",
    countryCode: "ER",
  },
  {
    airportCode: "ASN",
    airportName: "Talladega",
    cityCode: "ASN",
    cityName: "Talladega",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ASO",
    airportName: "Asosa",
    cityCode: "ASO",
    cityName: "Asosa",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "ASP",
    airportName: "Alice Springs",
    cityCode: "ASP",
    cityName: "Alice Springs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ASQ",
    airportName: "Austin",
    cityCode: "ASQ",
    cityName: "Austin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ASR",
    airportName: "Erkilet Intl. Arpt.",
    cityCode: "ASR",
    cityName: "Kayseri",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "AST",
    airportName: "Astoria",
    cityCode: "AST",
    cityName: "Astoria",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ASU",
    airportName: "Silvio Pettirossi",
    cityCode: "ASU",
    cityName: "Asuncion",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "ASV",
    airportName: "Amboseli",
    cityCode: "ASV",
    cityName: "Amboseli",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "ASW",
    airportName: "Aswan",
    cityCode: "ASW",
    cityName: "Aswan",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "ASX",
    airportName: "Ashland",
    cityCode: "ASX",
    cityName: "Ashland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ASY",
    airportName: "Ashley",
    cityCode: "ASY",
    cityName: "Ashley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATA",
    airportName: "Anta",
    cityCode: "ATA",
    cityName: "Anta",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "ATB",
    airportName: "Atbara",
    cityCode: "ATB",
    cityName: "Atbara",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "ATD",
    airportName: "Atoifi",
    cityCode: "ATD",
    cityName: "Atoifi",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "ATE",
    airportName: "Antlers",
    cityCode: "ATE",
    cityName: "Antlers",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATF",
    airportName: "Chachoan",
    cityCode: "ATF",
    cityName: "Ambato",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "ATG",
    airportName: "Attock",
    cityCode: "ATG",
    cityName: "Attock",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "ATH",
    airportName: "Eleftherios Venizelos",
    cityCode: "ATH",
    cityName: "Athens",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "ATI",
    airportName: "Artigas",
    cityCode: "ATI",
    cityName: "Artigas",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "ATJ",
    airportName: "Antsirabe",
    cityCode: "ATJ",
    cityName: "Antsirabe",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "ATK",
    airportName: "Atqasuk",
    cityCode: "ATK",
    cityName: "Atqasuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATL",
    airportName: "Hartsfield-jackson Atlanta Intl.",
    cityCode: "ATL",
    cityName: "Atlanta, GA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATM",
    airportName: "Altamira",
    cityCode: "ATM",
    cityName: "Altamira",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ATN",
    airportName: "Namatanai",
    cityCode: "ATN",
    cityName: "Namatanai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ATO",
    airportName: "Ohio University",
    cityCode: "ATO",
    cityName: "Athens",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATP",
    airportName: "Airstrip",
    cityCode: "ATP",
    cityName: "Aitape",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ATQ",
    airportName: "Raja Sansi",
    cityCode: "ATQ",
    cityName: "Amritsar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "ATR",
    airportName: "Mouakchott",
    cityCode: "ATR",
    cityName: "Atar",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "ATS",
    airportName: "Artesia",
    cityCode: "ATS",
    cityName: "Artesia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATT",
    airportName: "Atmautluak",
    cityCode: "ATT",
    cityName: "Atmautluak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATU",
    airportName: "Casco Cove",
    cityCode: "ATU",
    cityName: "Attu Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATV",
    airportName: "Ati",
    cityCode: "ATV",
    cityName: "Ati",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "ATW",
    airportName: "Outagamie County",
    cityCode: "ATW",
    cityName: "Appleton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TCI",
    airportName: "Tenerife",
    cityCode: "TCI",
    cityName: "Tenerife",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "ATX",
    airportName: "Atbasar",
    cityCode: "ATX",
    cityName: "Atbasar",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "ATY",
    airportName: "Watertown",
    cityCode: "ATY",
    cityName: "Watertown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ATZ",
    airportName: "Assiut",
    cityCode: "ATZ",
    cityName: "Assiut",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "AUA",
    airportName: "Reina Beatrix",
    cityCode: "AUA",
    cityName: "Aruba",
    countryName: "Aruba",
    countryCode: "AW",
  },
  {
    airportCode: "AUB",
    airportName: "Itauba",
    cityCode: "AUB",
    cityName: "Itauba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AUC",
    airportName: "Arauca",
    cityCode: "AUC",
    cityName: "Arauca",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AUD",
    airportName: "Augustus Downs",
    cityCode: "AUD",
    cityName: "Augustus Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AUE",
    airportName: "Abu Rudeis",
    cityCode: "AUE",
    cityName: "Abu Rudeis",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "AUF",
    airportName: "Auxerre Branches",
    cityCode: "AUF",
    cityName: "Auxerre",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "AUG",
    airportName: "Augusta",
    cityCode: "AUG",
    cityName: "Augusta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AUH",
    airportName: "Zayed International Airport.",
    cityCode: "AUH",
    cityName: "Abu Dhabi",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "AUI",
    airportName: "Aua Island",
    cityCode: "AUI",
    cityName: "Aua Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AUJ",
    airportName: "Ambunti",
    cityCode: "AUJ",
    cityName: "Ambunti",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AUK",
    airportName: "Alakanuk",
    cityCode: "AUK",
    cityName: "Alakanuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AUL",
    airportName: "Aur Island",
    cityCode: "AUL",
    cityName: "Aur Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "AUM",
    airportName: "Austin",
    cityCode: "AUM",
    cityName: "Austin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AUN",
    airportName: "Auburn",
    cityCode: "AUN",
    cityName: "Auburn",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AUO",
    airportName: "Auburn-Opelika",
    cityCode: "AUO",
    cityName: "Auburn",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AUP",
    airportName: "Agaun",
    cityCode: "AUP",
    cityName: "Agaun",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AUQ",
    airportName: "Atuona",
    cityCode: "AUQ",
    cityName: "Atuona",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "AUR",
    airportName: "Aurillac",
    cityCode: "AUR",
    cityName: "Aurillac",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "AUS",
    airportName: "Austin-bergstrom Intl.",
    cityCode: "AUS",
    cityName: "Austin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AUT",
    airportName: "Atauro",
    cityCode: "AUT",
    cityName: "Atauro",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AUU",
    airportName: "Aurukun Mission",
    cityCode: "AUU",
    cityName: "Aurukun Mission",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AUV",
    airportName: "Aumo",
    cityCode: "AUV",
    cityName: "Aumo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AUW",
    airportName: "Municipal",
    cityCode: "AUW",
    cityName: "Wausau",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AUX",
    airportName: "Araguaina",
    cityCode: "AUX",
    cityName: "Araguaina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AUY",
    airportName: "Aneityum",
    cityCode: "AUY",
    cityName: "Aneityum",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "AUZ",
    airportName: "Municipal Arpt.",
    cityCode: "AUZ",
    cityName: "Aurora",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AVA",
    airportName: "An Shun/Huang Guo Shu",
    cityCode: "AVA",
    cityName: "An Shun",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "AVB",
    airportName: "Aviano",
    cityCode: "AVB",
    cityName: "Aviano",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "AVF",
    airportName: "Avoriaz",
    cityCode: "AVF",
    cityName: "Avoriaz",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "AVG",
    airportName: "Auvergne",
    cityCode: "AVG",
    cityName: "Auvergne",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AVI",
    airportName: "Maximo Gomez",
    cityCode: "AVI",
    cityName: "Ciego De Avila",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "AVK",
    airportName: "Arvaikheer",
    cityCode: "AVK",
    cityName: "Arvaikheer",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "AVL",
    airportName: "Asheville Regional Arpt.",
    cityCode: "AVL",
    cityName: "Fletcher",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AVN",
    airportName: "Avignon-Caumont",
    cityCode: "AVN",
    cityName: "Avignon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "AVO",
    airportName: "Municipal",
    cityCode: "AVO",
    cityName: "Avon Park",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AVP",
    airportName: "Wilkes-Barre/Scranton Intl. Arpt.",
    cityCode: "AVP",
    cityName: "Wilkes-Barre/Scranton, PA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AVU",
    airportName: "Avu Avu",
    cityCode: "AVU",
    cityName: "Avu Avu",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "AVV",
    airportName: "Avalon",
    cityCode: "MEL",
    cityName: "Melbourne",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AVX",
    airportName: "Avalon Bay",
    cityCode: "AVX",
    cityName: "Catalina Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AWA",
    airportName: "Awassa",
    cityCode: "AWA",
    cityName: "Awassa",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "AWB",
    airportName: "Awaba",
    cityCode: "AWB",
    cityName: "Awaba",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AWD",
    airportName: "Aniwa",
    cityCode: "AWD",
    cityName: "Aniwa",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "AWE",
    airportName: "Alowe",
    cityCode: "AWE",
    cityName: "Alowe",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "AWH",
    airportName: "Awareh",
    cityCode: "AWH",
    cityName: "Awareh",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "AWK",
    airportName: "Wake Island",
    cityCode: "AWK",
    cityName: "Wake Island",
    countryName: "US Minor Outlying Islands",
    countryCode: "UM",
  },
  {
    airportCode: "AWM",
    airportName: "Municipal",
    cityCode: "AWM",
    cityName: "West Memphis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AWN",
    airportName: "Alton Downs",
    cityCode: "AWN",
    cityName: "Alton Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AWP",
    airportName: "Austral Downs",
    cityCode: "AWP",
    cityName: "Austral Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AWR",
    airportName: "Awar",
    cityCode: "AWR",
    cityName: "Awar",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AWZ",
    airportName: "Ahwaz",
    cityCode: "AWZ",
    cityName: "Ahwaz",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "AXA",
    airportName: "Wallblake",
    cityCode: "AXA",
    cityName: "Anguilla",
    countryName: "Anguilla",
    countryCode: "AI",
  },
  {
    airportCode: "AXB",
    airportName: "Alexandria Bay",
    cityCode: "AXB",
    cityName: "Alexandria Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AXC",
    airportName: "Aramac",
    cityCode: "AXC",
    cityName: "Aramac",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AXD",
    airportName: "Dimokritos",
    cityCode: "AXD",
    cityName: "Alexandroupolis",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "AXE",
    airportName: "Xanxere",
    cityCode: "AXE",
    cityName: "Xanxere",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AXG",
    airportName: "Algona",
    cityCode: "AXG",
    cityName: "Algona",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AXK",
    airportName: "Ataq",
    cityCode: "AXK",
    cityName: "Ataq",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "AXL",
    airportName: "Alexandria",
    cityCode: "AXL",
    cityName: "Alexandria",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AXM",
    airportName: "El Eden",
    cityCode: "AXM",
    cityName: "Armenia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AXN",
    airportName: "Alexandria",
    cityCode: "AXN",
    cityName: "Alexandria",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AXP",
    airportName: "Springpoint Arpt",
    cityCode: "AXP",
    cityName: "Spring Point",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "AXR",
    airportName: "Arutua",
    cityCode: "AXR",
    cityName: "Arutua",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "AXS",
    airportName: "Municipal",
    cityCode: "AXS",
    cityName: "Altus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AXT",
    airportName: "Akita",
    cityCode: "AXT",
    cityName: "Akita",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "AXU",
    airportName: "Axum",
    cityCode: "AXU",
    cityName: "Axum",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "AXV",
    airportName: "Neil Armstrong",
    cityCode: "AXV",
    cityName: "Wapakoneta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AXX",
    airportName: "Angel Fire",
    cityCode: "AXX",
    cityName: "Angel Fire",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AYA",
    airportName: "Ayapel",
    cityCode: "AYA",
    cityName: "Ayapel",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AYC",
    airportName: "Ayacucho",
    cityCode: "AYC",
    cityName: "Ayacucho",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AYD",
    airportName: "Alroy Downs",
    cityCode: "AYD",
    cityName: "Alroy Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AYE",
    airportName: "AAF Heliport",
    cityCode: "AYE",
    cityName: "Fort Devens",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AYG",
    airportName: "Yaguara",
    cityCode: "AYG",
    cityName: "Yaguara",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AYH",
    airportName: "RAF Station",
    cityCode: "AYH",
    cityName: "Alconbury",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "AYI",
    airportName: "Yari",
    cityCode: "AYI",
    cityName: "Yari",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AYK",
    airportName: "Arkalyk",
    cityCode: "AYK",
    cityName: "Arkalyk",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "AYL",
    airportName: "Anthony Lagoon",
    cityCode: "AYL",
    cityName: "Anthony Lagoon",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AYN",
    airportName: "Anyang",
    cityCode: "AYN",
    cityName: "Anyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "AYO",
    airportName: "Ayolas",
    cityCode: "AYO",
    cityName: "Ayolas",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "AYP",
    airportName: "Yanamilla",
    cityCode: "AYP",
    cityName: "Ayacucho",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "AYQ",
    airportName: "Connellan",
    cityCode: "AYQ",
    cityName: "Ayers Rock",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AYR",
    airportName: "Ayr",
    cityCode: "AYR",
    cityName: "Ayr",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AYS",
    airportName: "Ware County",
    cityCode: "AYS",
    cityName: "Waycross",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AYT",
    airportName: "Antalya",
    cityCode: "AYT",
    cityName: "Antalya",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "AYU",
    airportName: "Aiyura",
    cityCode: "AYU",
    cityName: "Aiyura",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AYW",
    airportName: "Ayawasi",
    cityCode: "AYW",
    cityName: "Ayawasi",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AYZ",
    airportName: "Zahns",
    cityCode: "AYZ",
    cityName: "Amityville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AZB",
    airportName: "Amazon Bay",
    cityCode: "AZB",
    cityName: "Amazon Bay",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AZD",
    airportName: "Yazd",
    cityCode: "AZD",
    cityName: "Yazd",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "AZG",
    airportName: "Apatzingan",
    cityCode: "AZG",
    cityName: "Apatzingan",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "AZI",
    airportName: "Bateen",
    cityCode: "AZI",
    cityName: "Abu Dhabi",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "AZN",
    airportName: "Andizhan",
    cityCode: "AZN",
    cityName: "Andizhan",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "AZO",
    airportName: "Kalamazoo/Battle Creek Intl",
    cityCode: "AZO",
    cityName: "Kalamazoo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AZP",
    airportName: "Atizapan",
    cityCode: "MEX",
    cityName: "Mexico City",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "AZR",
    airportName: "Adrar",
    cityCode: "AZR",
    cityName: "Adrar",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "AZS",
    airportName: "SamanÃ¡ El Catey Intl.",
    cityCode: "AZS",
    cityName: "El Catey",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "AZT",
    airportName: "Zapatoca",
    cityCode: "AZT",
    cityName: "Zapatoca",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AZZ",
    airportName: "Ambriz",
    cityCode: "AZZ",
    cityName: "Ambriz",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "BAA",
    airportName: "Bialla",
    cityCode: "BAA",
    cityName: "Bialla",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BAC",
    airportName: "Barranca De Upia",
    cityCode: "BAC",
    cityName: "Barranca De Upia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "BAE",
    airportName: "Barcelonnette",
    cityCode: "BAE",
    cityName: "Barcelonnette",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BAG",
    airportName: "Loakan",
    cityCode: "BAG",
    cityName: "Baguio",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "BAH",
    airportName: "Bahrain Intl.",
    cityCode: "BAH",
    cityName: "Manama",
    countryName: "Bahrain",
    countryCode: "BH",
  },
  {
    airportCode: "BAI",
    airportName: "Buenos Aires",
    cityCode: "BAI",
    cityName: "Buenos Aires",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "BAJ",
    airportName: "Bali",
    cityCode: "BAJ",
    cityName: "Bali",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BAL",
    airportName: "Batman",
    cityCode: "BAL",
    cityName: "Batman",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "BAM",
    airportName: "Lander County",
    cityCode: "BAM",
    cityName: "Battle Mountain",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BAN",
    airportName: "Basongo",
    cityCode: "BAN",
    cityName: "Basongo",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BAO",
    airportName: "Udorn",
    cityCode: "BAO",
    cityName: "Ban Mak Khaen",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "BAP",
    airportName: "Baibara",
    cityCode: "BAP",
    cityName: "Baibara",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BAQ",
    airportName: "E Cortissoz",
    cityCode: "BAQ",
    cityName: "Barranquilla",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "BAR",
    airportName: "Baker Aaf",
    cityCode: "BAR",
    cityName: "Baker Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BAS",
    airportName: "Balalae",
    cityCode: "BAS",
    cityName: "Balalae",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "BAT",
    airportName: "Arpt. Chafei Amsei",
    cityCode: "BAT",
    cityName: "Barretos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BAU",
    airportName: "Bauru",
    cityCode: "BAU",
    cityName: "Bauru",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BAV",
    airportName: "Baotou",
    cityCode: "BAV",
    cityName: "Baotou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "BAW",
    airportName: "Biawonque",
    cityCode: "BAW",
    cityName: "Biawonque",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "BAX",
    airportName: "Barnaul",
    cityCode: "BAX",
    cityName: "Barnaul",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "BAY",
    airportName: "Baia Mare",
    cityCode: "BAY",
    cityName: "Baia Mare",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "BAZ",
    airportName: "Barbelos",
    cityCode: "BAZ",
    cityName: "Barbelos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BBA",
    airportName: "Teniente Vidal",
    cityCode: "BBA",
    cityName: "Balmaceda",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "BBB",
    airportName: "Municipal",
    cityCode: "BBB",
    cityName: "Benson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BBC",
    airportName: "Bay City",
    cityCode: "BBC",
    cityName: "Bay City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BBD",
    airportName: "Curtis Field",
    cityCode: "BBD",
    cityName: "Brady",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BBE",
    airportName: "Big Bell",
    cityCode: "BBE",
    cityName: "Big Bell",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BBF",
    airportName: "Burlington",
    cityCode: "BBF",
    cityName: "Burlington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BBG",
    airportName: "Butaritari",
    cityCode: "BBG",
    cityName: "Butaritari",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "BBH",
    airportName: "Barth",
    cityCode: "BBH",
    cityName: "Barth",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BBI",
    airportName: "Bhubaneswar",
    cityCode: "BBI",
    cityName: "Bhubaneswar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BBJ",
    airportName: "Bitburg Air Base",
    cityCode: "BBJ",
    cityName: "Bitburg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BBK",
    airportName: "Kasane",
    cityCode: "BBK",
    cityName: "Kasane",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "BBL",
    airportName: "Babolsar",
    cityCode: "BBL",
    cityName: "Babolsar",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "BBM",
    airportName: "Battambang",
    cityCode: "BBM",
    cityName: "Battambang",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "BBN",
    airportName: "Bario",
    cityCode: "BBN",
    cityName: "Bario",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "BBO",
    airportName: "Berbera",
    cityCode: "BBO",
    cityName: "Berbera",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "BBP",
    airportName: "Bembridge",
    cityCode: "BBP",
    cityName: "Bembridge",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BBQ",
    airportName: "Barbuda",
    cityCode: "BBQ",
    cityName: "Barbuda",
    countryName: "Antigua and Barbuda",
    countryCode: "AG",
  },
  {
    airportCode: "BBR",
    airportName: "Baillif",
    cityCode: "BBR",
    cityName: "Basse Terre",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "BBS",
    airportName: "Blackbushe",
    cityCode: "BBS",
    cityName: "Yateley",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BBT",
    airportName: "Berberati",
    cityCode: "BBT",
    cityName: "Berberati",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BBU",
    airportName: "Baneasa",
    cityCode: "BUH",
    cityName: "Bucharest",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "BBV",
    airportName: "Hkg",
    cityCode: "BBV",
    cityName: "Bereby",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "BBW",
    airportName: "Broken Bow",
    cityCode: "BBW",
    cityName: "Broken Bow",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BBX",
    airportName: "Wings Field",
    cityCode: "BBX",
    cityName: "Blue Bell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BBY",
    airportName: "Bambari",
    cityCode: "BBY",
    cityName: "Bambari",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BBZ",
    airportName: "Zambezi",
    cityCode: "BBZ",
    cityName: "Zambezi",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "BCA",
    airportName: "Baracoa",
    cityCode: "BCA",
    cityName: "Baracoa",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "BCB",
    airportName: "Virginia Tech",
    cityCode: "BCB",
    cityName: "Blacksburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BCC",
    airportName: "Bear Creek",
    cityCode: "BCC",
    cityName: "Bear Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BCD",
    airportName: "Bacolod",
    cityCode: "BCD",
    cityName: "Bacolod",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "BCE",
    airportName: "Bryce",
    cityCode: "BCE",
    cityName: "Bryce",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BCF",
    airportName: "Bouca",
    cityCode: "BCF",
    cityName: "Bouca",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BCG",
    airportName: "Bemichi",
    cityCode: "BCG",
    cityName: "Bemichi",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "BCH",
    airportName: "Cakung Intl.",
    cityCode: "BCH",
    cityName: "Baucau",
    countryName: "East Timor-Indonesia",
    countryCode: "TP",
  },
  {
    airportCode: "BCI",
    airportName: "Barcaldine",
    cityCode: "BCI",
    cityName: "Barcaldine",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BCJ",
    airportName: "Baca Grande",
    cityCode: "BCJ",
    cityName: "Baca Grande",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BCK",
    airportName: "Bolwarra",
    cityCode: "BCK",
    cityName: "Bolwarra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BCL",
    airportName: "Barra Colorado",
    cityCode: "BCL",
    cityName: "Barra Colorado",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "BCM",
    airportName: "Bacau",
    cityCode: "BCM",
    cityName: "Bacau",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "BCN",
    airportName: "El Prat De Llobregat",
    cityCode: "BCN",
    cityName: "Barcelona",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "BCO",
    airportName: "Jinka",
    cityCode: "BCO",
    cityName: "Jinka",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "BCP",
    airportName: "Bambu",
    cityCode: "BCP",
    cityName: "Bambu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BCQ",
    airportName: "Brack",
    cityCode: "BCQ",
    cityName: "Brack",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "BCR",
    airportName: "Boca Do Acre",
    cityCode: "BCR",
    cityName: "Boca Do Acre",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BCS",
    airportName: "Southern Seaplane",
    cityCode: "BCS",
    cityName: "Belle Chasse",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BCT",
    airportName: "Public",
    cityCode: "BCT",
    cityName: "Boca Raton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BCU",
    airportName: "Bauchi",
    cityCode: "BCU",
    cityName: "Bauchi",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "BCV",
    airportName: "Belmopan",
    cityCode: "BCV",
    cityName: "Belmopan",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "BCW",
    airportName: "Benguera Island",
    cityCode: "BCW",
    cityName: "Benguera Island",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "BCX",
    airportName: "Beloreck",
    cityCode: "BCX",
    cityName: "Beloreck",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "BCY",
    airportName: "Bulchi",
    cityCode: "BCY",
    cityName: "Bulchi",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "BCZ",
    airportName: "Bickerton Island",
    cityCode: "BCZ",
    cityName: "Bickerton Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BDA",
    airportName: "Kindley Field",
    cityCode: "BDA",
    cityName: "Bermuda",
    countryName: "Bermuda",
    countryCode: "BM",
  },
  {
    airportCode: "BDB",
    airportName: "Bundaberg",
    cityCode: "BDB",
    cityName: "Bundaberg",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BDC",
    airportName: "Barra Do Corda",
    cityCode: "BDC",
    cityName: "Barra Do Corda",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BDD",
    airportName: "Badu Island",
    cityCode: "BDD",
    cityName: "Badu Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BDE",
    airportName: "Baudette",
    cityCode: "BDE",
    cityName: "Baudette",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BDF",
    airportName: "Rinkenberger",
    cityCode: "BDF",
    cityName: "Bradford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BDG",
    airportName: "Blanding",
    cityCode: "BDG",
    cityName: "Blanding",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BDH",
    airportName: "Bandar Lengeh",
    cityCode: "BDH",
    cityName: "Bandar Lengeh",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "BDI",
    airportName: "Bird Island",
    cityCode: "BDI",
    cityName: "Bird Island",
    countryName: "Seychelles",
    countryCode: "SC",
  },
  {
    airportCode: "BDJ",
    airportName: "Sjamsudin Noor",
    cityCode: "BDJ",
    cityName: "Banjarmasin",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BDK",
    airportName: "Bondoukou",
    cityCode: "BDK",
    cityName: "Bondoukou",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "BDL",
    airportName: "Bradley Intl.",
    cityCode: "BDL",
    cityName: "Windsor Locks, CT",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BDM",
    airportName: "Bandirma",
    cityCode: "BDM",
    cityName: "Bandirma",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "BDN",
    airportName: "Talhar",
    cityCode: "BDN",
    cityName: "Badin",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "BDO",
    airportName: "Husein Sastranegara",
    cityCode: "BDO",
    cityName: "Bandung",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BDP",
    airportName: "Bhadrapur",
    cityCode: "BDP",
    cityName: "Bhadrapur",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BDQ",
    airportName: "Vadodara",
    cityCode: "BDQ",
    cityName: "Vadodara",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BDR",
    airportName: "Igor I. Sikorsky Mem.",
    cityCode: "BDR",
    cityName: "Bridgeport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BDS",
    airportName: "Papola Casale",
    cityCode: "BDS",
    cityName: "Brindisi",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "BDT",
    airportName: "Gbadolite",
    cityCode: "BDT",
    cityName: "Gbadolite",
    countryName: "Democratic Republic of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "BDU",
    airportName: "Bardufoss",
    cityCode: "BDU",
    cityName: "Bardufoss",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "BDV",
    airportName: "Moba",
    cityCode: "BDV",
    cityName: "Moba",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BDW",
    airportName: "Bedford Downs",
    cityCode: "BDW",
    cityName: "Bedford Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BDX",
    airportName: "Broadus",
    cityCode: "BDX",
    cityName: "Broadus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BDY",
    airportName: "State",
    cityCode: "BDY",
    cityName: "Bandon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BDZ",
    airportName: "Baindoung",
    cityCode: "BDZ",
    cityName: "Baindoung",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BEA",
    airportName: "Bereina",
    cityCode: "BEA",
    cityName: "Bereina",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BEB",
    airportName: "Benbecula",
    cityCode: "BEB",
    cityName: "Benbecula",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BEC",
    airportName: "Beech",
    cityCode: "BEC",
    cityName: "Wichita",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BED",
    airportName: "Hanscom Field",
    cityCode: "BED",
    cityName: "Bedford/Hanscom",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BEE",
    airportName: "Beagle Bay",
    cityCode: "BEE",
    cityName: "Beagle Bay",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BEF",
    airportName: "Bluefields",
    cityCode: "BEF",
    cityName: "Bluefields",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "BEG",
    airportName: "Nikola Tesla",
    cityCode: "BEG",
    cityName: "Belgrade",
    countryName: "Serbia",
    countryCode: "RS",
  },
  {
    airportCode: "BEH",
    airportName: "Ross Field",
    cityCode: "BEH",
    cityName: "Benton Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BEI",
    airportName: "Beica",
    cityCode: "BEI",
    cityName: "Beica",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "BEJ",
    airportName: "Kalimaru",
    cityCode: "BEJ",
    cityName: "Tanjung Redep",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BEK",
    airportName: "Bareli",
    cityCode: "BEK",
    cityName: "Bareli",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BEL",
    airportName: "Val De Cans",
    cityCode: "BEL",
    cityName: "Belem",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BEM",
    airportName: "Bossembele",
    cityCode: "BEM",
    cityName: "Bossembele",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BEN",
    airportName: "Benina Intl",
    cityCode: "BEN",
    cityName: "Benghazi",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "BEO",
    airportName: "Belmont",
    cityCode: "BEO",
    cityName: "Newcastle",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BEP",
    airportName: "Bellary",
    cityCode: "BEP",
    cityName: "Bellary",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BEQ",
    airportName: "Honington",
    cityCode: "BEQ",
    cityName: "Bury St Edmunds",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BER",
    airportName: "Berlin Metropolitan Area",
    cityCode: "BER",
    cityName: "Berlin",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BES",
    airportName: "Brest Lesquin",
    cityCode: "BES",
    cityName: "Brest",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BET",
    airportName: "Bethel Arpt.",
    cityCode: "BET",
    cityName: "Bethel",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BEU",
    airportName: "Bedourie",
    cityCode: "BEU",
    cityName: "Bedourie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BEV",
    airportName: "Beer Sheba",
    cityCode: "BEV",
    cityName: "Beer Sheba",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "BEW",
    airportName: "Beira",
    cityCode: "BEW",
    cityName: "Beira",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "BEY",
    airportName: "Beirut Rafic Hariri Arpt.",
    cityCode: "BEY",
    cityName: "Beirut",
    countryName: "Lebanon",
    countryCode: "LB",
  },
  {
    airportCode: "BEZ",
    airportName: "Beru",
    cityCode: "BEZ",
    cityName: "Beru",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "BFA",
    airportName: "Bahia Negra",
    cityCode: "BFA",
    cityName: "Bahia Negra",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "BFB",
    airportName: "Blue Fox Bay",
    cityCode: "BFB",
    cityName: "Blue Fox Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFC",
    airportName: "Bloomfield",
    cityCode: "BFC",
    cityName: "Bloomfield",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BFD",
    airportName: "Bradford",
    cityCode: "BFD",
    cityName: "Bradford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFE",
    airportName: "Bielefeld",
    cityCode: "BFE",
    cityName: "Bielefeld",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BFF",
    airportName: "Scotts Bluff County",
    cityCode: "BFF",
    cityName: "Scottsbluff",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFG",
    airportName: "Bullfrog Basin",
    cityCode: "BFG",
    cityName: "Bullfrog Basin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFH",
    airportName: "Bacacheri",
    cityCode: "BFH",
    cityName: "Curitiba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BFI",
    airportName: "Boeing Field/King County Intl. Arpt.",
    cityCode: "SEA",
    cityName: "Seattle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFJ",
    airportName: "Ba",
    cityCode: "BFJ",
    cityName: "Ba",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "BFK",
    airportName: "Buffalo Municipal Arpt.",
    cityCode: "DEN",
    cityName: "Denver",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFL",
    airportName: "Meadows Field",
    cityCode: "BFL",
    cityName: "Bakersfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFM",
    airportName: "Mob Aerospace",
    cityCode: "BFM",
    cityName: "Mobile",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFO",
    airportName: "Buffalo Range",
    cityCode: "BFO",
    cityName: "Buffalo Range",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "BFP",
    airportName: "Beaver Falls",
    cityCode: "BFP",
    cityName: "Beaver Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFQ",
    airportName: "Bahia Pinas",
    cityCode: "BFQ",
    cityName: "Bahia Pinas",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "BFR",
    airportName: "Virgil I Grissom Muni",
    cityCode: "BFR",
    cityName: "Bedford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFS",
    airportName: "Aldergrove Intl. Arpt.",
    cityCode: "BFS",
    cityName: "Belfast",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BFT",
    airportName: "County",
    cityCode: "BFT",
    cityName: "Beaufort",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BFU",
    airportName: "Bengbu",
    cityCode: "BFU",
    cityName: "Bengbu",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "BFV",
    airportName: "Buri Ram",
    cityCode: "BFV",
    cityName: "Buri Ram",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "BFX",
    airportName: "Bafoussam",
    cityCode: "BFX",
    cityName: "Bafoussam",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "BGA",
    airportName: "Palo Negro",
    cityCode: "BGA",
    cityName: "Bucaramanga",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "BGB",
    airportName: "Booue",
    cityCode: "BGB",
    cityName: "Booue",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "BGC",
    airportName: "Braganca",
    cityCode: "BGC",
    cityName: "Braganca",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "BGD",
    airportName: "Borger",
    cityCode: "BGD",
    cityName: "Borger",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BGE",
    airportName: "Decatur County",
    cityCode: "BGE",
    cityName: "Bainbridge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BGF",
    airportName: "Bangui",
    cityCode: "BGF",
    cityName: "Bangui",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BGG",
    airportName: "Bongouanou",
    cityCode: "BGG",
    cityName: "Bongouanou",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "BGH",
    airportName: "Abbaye",
    cityCode: "BGH",
    cityName: "Boghe",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "BGI",
    airportName: "Grantley Adams Intl.",
    cityCode: "BGI",
    cityName: "Bridgetown",
    countryName: "Barbados",
    countryCode: "BB",
  },
  {
    airportCode: "BGJ",
    airportName: "Borgarfjordur Eystri",
    cityCode: "BGJ",
    cityName: "Borgarfjordur Eystri",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "BGK",
    airportName: "Big Creek",
    cityCode: "BGK",
    cityName: "Big Creek",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "BGL",
    airportName: "Baglung",
    cityCode: "BGL",
    cityName: "Baglung",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BGM",
    airportName: "Greater Binghamton",
    cityCode: "BGM",
    cityName: "Binghamton, NY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BGN",
    airportName: "R.A.F.",
    cityCode: "BGN",
    cityName: "Brueggen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BGO",
    airportName: "Bergen Arpt., Flesland",
    cityCode: "BGO",
    cityName: "Bergen",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "BGP",
    airportName: "Bongo",
    cityCode: "BGP",
    cityName: "Bongo",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "BGQ",
    airportName: "Big Lake",
    cityCode: "BGQ",
    cityName: "Big Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BGR",
    airportName: "Bangor Intl. Arpt.",
    cityCode: "BGR",
    cityName: "Bangor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BGS",
    airportName: "Webb AFB",
    cityCode: "BGS",
    cityName: "Big Spring",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BGT",
    airportName: "Bagdad",
    cityCode: "BGT",
    cityName: "Bagdad, AZ",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BGU",
    airportName: "Bangassou",
    cityCode: "BGU",
    cityName: "Bangassou",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BGV",
    airportName: "Bento Goncalves",
    cityCode: "BGV",
    cityName: "Bento Goncalves",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BGW",
    airportName: "Al Rasheed",
    cityCode: "BGW",
    cityName: "Baghdad",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "BGX",
    airportName: "Arpt. Cmdt. Gustavo Kraemer",
    cityCode: "BGX",
    cityName: "Bage",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BGY",
    airportName: "Orio al Serio",
    cityCode: "MIL",
    cityName: "Milan",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "BGZ",
    airportName: "Braga",
    cityCode: "BGZ",
    cityName: "Braga",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "BHA",
    airportName: "Bahia De Caraquez",
    cityCode: "BHA",
    cityName: "Bahia De Caraquez",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "BHB",
    airportName: "Bar Harbor",
    cityCode: "BHB",
    cityName: "Bar Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BHC",
    airportName: "Bhurban Heliport",
    cityCode: "BHC",
    cityName: "Bhurban",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BHD",
    airportName: "George Best Belfast City",
    cityCode: "BHD",
    cityName: "Belfast",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BHE",
    airportName: "Woodbourne Air Station",
    cityCode: "BHE",
    cityName: "Blenheim",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "BHF",
    airportName: "Bahia Cupica",
    cityCode: "BHF",
    cityName: "Bahia Cupica",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "BHG",
    airportName: "Brus Laguna",
    cityCode: "BHG",
    cityName: "Brus Laguna",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "BHH",
    airportName: "Bisha",
    cityCode: "BHH",
    cityName: "Bisha",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "BHI",
    airportName: "Comandante",
    cityCode: "BHI",
    cityName: "Bahia Blanca",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "BHJ",
    airportName: "Rudra Mata",
    cityCode: "BHJ",
    cityName: "Bhuj",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BHK",
    airportName: "Bukhara",
    cityCode: "BHK",
    cityName: "Bukhara",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "BHL",
    airportName: "Bahia Angeles",
    cityCode: "BHL",
    cityName: "Bahia Angeles",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "BHM",
    airportName: "Birmingham",
    cityCode: "BHM",
    cityName: "Birmingham",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BHN",
    airportName: "Beihan",
    cityCode: "BHN",
    cityName: "Beihan",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "BHO",
    airportName: "Bhopal",
    cityCode: "BHO",
    cityName: "Bhopal",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BHP",
    airportName: "Bhojpur",
    cityCode: "BHP",
    cityName: "Bhojpur",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BHQ",
    airportName: "Broken Hill",
    cityCode: "BHQ",
    cityName: "Broken Hill",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BHR",
    airportName: "Bharatpur Arpt.",
    cityCode: "BHR",
    cityName: "Bharatpur",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BHS",
    airportName: "Raglan",
    cityCode: "BHS",
    cityName: "Bathurst",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BHT",
    airportName: "Brighton Downs",
    cityCode: "BHT",
    cityName: "Brighton Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BHU",
    airportName: "Bhavnagar",
    cityCode: "BHU",
    cityName: "Bhavnagar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BHV",
    airportName: "Bahawalpur",
    cityCode: "BHV",
    cityName: "Bahawalpur",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "BHW",
    airportName: "Bhagatanwala Apt",
    cityCode: "BHW",
    cityName: "Sargodha",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "BHX",
    airportName: "Birmingham Intl. Arpt.",
    cityCode: "BHX",
    cityName: "Birmingham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BHY",
    airportName: "Beihai",
    cityCode: "BHY",
    cityName: "Beihai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "BIA",
    airportName: "Poretta",
    cityCode: "BIA",
    cityName: "Bastia",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BIB",
    airportName: "Baidoa",
    cityCode: "BIB",
    cityName: "Baidoa",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "BIC",
    airportName: "Big Creek",
    cityCode: "BIC",
    cityName: "Big Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BID",
    airportName: "Block Island",
    cityCode: "BID",
    cityName: "Block Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BIE",
    airportName: "Beatrice",
    cityCode: "BIE",
    cityName: "Beatrice",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BIF",
    airportName: "Biggs Aaf",
    cityCode: "BIF",
    cityName: "El Paso",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BIG",
    airportName: "Intermediate Field",
    cityCode: "BIG",
    cityName: "Big Delta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BIH",
    airportName: "Bishop",
    cityCode: "BIH",
    cityName: "Bishop",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BII",
    airportName: "Enyu Airfield",
    cityCode: "BII",
    cityName: "Bikini Atoll",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "BIJ",
    airportName: "Biliau",
    cityCode: "BIJ",
    cityName: "Biliau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BIK",
    airportName: "Frans Kaisepo",
    cityCode: "BIK",
    cityName: "Biak",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BIL",
    airportName: "Billings",
    cityCode: "BIL",
    cityName: "Billings",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BIM",
    airportName: "Intl.",
    cityCode: "BIM",
    cityName: "Bimini",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "BIN",
    airportName: "Bamiyan",
    cityCode: "BIN",
    cityName: "Bamiyan",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "BIO",
    airportName: "Sondica",
    cityCode: "BIO",
    cityName: "Bilbao",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "BIP",
    airportName: "Bulimba",
    cityCode: "BIP",
    cityName: "Bulimba",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BIQ",
    airportName: "Biarritz Parme",
    cityCode: "BIQ",
    cityName: "Biarritz",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BIR",
    airportName: "Biratnagar",
    cityCode: "BIR",
    cityName: "Biratnagar",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BIS",
    airportName: "Bismarck",
    cityCode: "BIS",
    cityName: "Bismarck",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BIT",
    airportName: "Baitadi",
    cityCode: "BIT",
    cityName: "Baitadi",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BIU",
    airportName: "Bildudalur",
    cityCode: "BIU",
    cityName: "Bildudalur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "BIV",
    airportName: "Bria",
    cityCode: "BIV",
    cityName: "Bria",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BIW",
    airportName: "Billiluna",
    cityCode: "BIW",
    cityName: "Billiluna",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BIX",
    airportName: "Keesler AFB",
    cityCode: "BIX",
    cityName: "Biloxi",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BIY",
    airportName: "Bisho",
    cityCode: "BIY",
    cityName: "Bisho",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "BIZ",
    airportName: "Bimin",
    cityCode: "BIZ",
    cityName: "Bimin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BJA",
    airportName: "Bejaia",
    cityCode: "BJA",
    cityName: "Bejaia",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "BJB",
    airportName: "Bojnord",
    cityCode: "BJB",
    cityName: "Bojnord",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "BJC",
    airportName: "Jeffco",
    cityCode: "BJC",
    cityName: "Broomfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BJD",
    airportName: "Bakkafjordur",
    cityCode: "BJD",
    cityName: "Bakkafjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "BJF",
    airportName: "Batsfjord",
    cityCode: "BJF",
    cityName: "Batsfjord",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "BJG",
    airportName: "Bolaang",
    cityCode: "BJG",
    cityName: "Bolaang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BJH",
    airportName: "Bajhang",
    cityCode: "BJH",
    cityName: "Bajhang",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BJI",
    airportName: "Bemidji",
    cityCode: "BJI",
    cityName: "Bemidji",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BJJ",
    airportName: "Wayne County",
    cityCode: "BJJ",
    cityName: "Wooster",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BJK",
    airportName: "Benjina",
    cityCode: "BJK",
    cityName: "Benjina",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BJL",
    airportName: "Yundum Intl.",
    cityCode: "BJL",
    cityName: "Banjul",
    countryName: "Gambia",
    countryCode: "GM",
  },
  {
    airportCode: "BJM",
    airportName: "Intl.",
    cityCode: "BJM",
    cityName: "Bujumbura",
    countryName: "Burundi",
    countryCode: "BI",
  },
  {
    airportCode: "BJN",
    airportName: "Bajone",
    cityCode: "BJN",
    cityName: "Bajone",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "BJO",
    airportName: "Bermejo",
    cityCode: "BJO",
    cityName: "Bermejo",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "BJP",
    airportName: "Braganca Paulista",
    cityCode: "BJP",
    cityName: "Braganca Paulista",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BJR",
    airportName: "Bahar Dar",
    cityCode: "BJR",
    cityName: "Bahar Dar",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "BJT",
    airportName: "Bentota River",
    cityCode: "BJT",
    cityName: "Bentota River",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "BJU",
    airportName: "Bajura Arpt",
    cityCode: "BJU",
    cityName: "Bajura",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BJV",
    airportName: "Milas",
    cityCode: "BJV",
    cityName: "Milas",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "BJW",
    airportName: "Bajawa",
    cityCode: "BJW",
    cityName: "Bajawa",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BJX",
    airportName: "Del BajÃ­o Intl.",
    cityCode: "BJX",
    cityName: "LeÃ³n",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "BJY",
    airportName: "Batajnica",
    cityCode: "BJY",
    cityName: "Belgrade",
    countryName: "Serbia",
    countryCode: "RS",
  },
  {
    airportCode: "BJZ",
    airportName: "Talaveral La Real",
    cityCode: "BJZ",
    cityName: "Badajoz",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "BKA",
    airportName: "Bykovo",
    cityCode: "MOW",
    cityName: "Moscow",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "BKB",
    airportName: "Bikaner",
    cityCode: "BKB",
    cityName: "Bikaner",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BKC",
    airportName: "Buckland",
    cityCode: "BKC",
    cityName: "Buckland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKD",
    airportName: "Stephens County",
    cityCode: "BKD",
    cityName: "Breckenridge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKE",
    airportName: "Baker",
    cityCode: "BKE",
    cityName: "Baker",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKF",
    airportName: "Lake Brooks SPB",
    cityCode: "BKF",
    cityName: "Katmai National Park",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKH",
    airportName: "Barking Sands PMRF",
    cityCode: "BKH",
    cityName: "Kekaha",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKI",
    airportName: "Kota-Kinabalu Intl. Arpt.",
    cityCode: "BKI",
    cityName: "Kota-Kinabalu",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "BKJ",
    airportName: "Boke",
    cityCode: "BKJ",
    cityName: "Boke",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "BKK",
    airportName: "Suvarnabhumi Intl.",
    cityCode: "BKK",
    cityName: "Bangkok",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "BKL",
    airportName: "Burke Lakefront",
    cityCode: "BKL",
    cityName: "Cleveland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKM",
    airportName: "Bakalalan",
    cityCode: "BKM",
    cityName: "Bakalalan",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "BKN",
    airportName: "Birni Nkoni",
    cityCode: "BKN",
    cityName: "Birni Nkoni",
    countryName: "Niger",
    countryCode: "NE",
  },
  {
    airportCode: "BKO",
    airportName: "Bamako",
    cityCode: "BKO",
    cityName: "Bamako",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "BKP",
    airportName: "Barkly Downs",
    cityCode: "BKP",
    cityName: "Barkly Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BKQ",
    airportName: "Blackall",
    cityCode: "BKQ",
    cityName: "Blackall",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BKR",
    airportName: "Bokoro",
    cityCode: "BKR",
    cityName: "Bokoro",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "BKS",
    airportName: "Padangkemiling",
    cityCode: "BKS",
    cityName: "Bengkulu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BKT",
    airportName: "Blackstone AAF",
    cityCode: "BKT",
    cityName: "Blackstone",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKU",
    airportName: "Betioky",
    cityCode: "BKU",
    cityName: "Betioky",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "BKW",
    airportName: "Beckley",
    cityCode: "BKW",
    cityName: "Beckley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKX",
    airportName: "Brookings",
    cityCode: "BKX",
    cityName: "Brookings",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BKY",
    airportName: "Kavumu",
    cityCode: "BKY",
    cityName: "Bukavu",
    countryName: "Congo",
    countryCode: "CD",
  },
  {
    airportCode: "BKZ",
    airportName: "Bukoba",
    cityCode: "BKZ",
    cityName: "Bukoba",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "BLA",
    airportName: "Gen J A Anzoategui",
    cityCode: "BLA",
    cityName: "Barcelona",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "BLB",
    airportName: "Balboa",
    cityCode: "BLB",
    cityName: "Balboa",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "BLC",
    airportName: "Bamenda",
    cityCode: "BLC",
    cityName: "Bali",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "BLD",
    airportName: "Boulder City",
    cityCode: "LAS",
    cityName: "Las Vegas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BLE",
    airportName: "Dala Arpt.",
    cityCode: "BLE",
    cityName: "Borlange",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "BLF",
    airportName: "Princeton",
    cityCode: "BLF",
    cityName: "Princeton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BLG",
    airportName: "Belaga",
    cityCode: "BLG",
    cityName: "Belaga",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "BLH",
    airportName: "Blythe",
    cityCode: "BLH",
    cityName: "Blythe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BLI",
    airportName: "Bellingham",
    cityCode: "BLI",
    cityName: "Bellingham",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BLJ",
    airportName: "Batna",
    cityCode: "BLJ",
    cityName: "Batna",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "BLK",
    airportName: "Blackpool Arpt.",
    cityCode: "BLK",
    cityName: "Blackpool",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BLL",
    airportName: "Billund",
    cityCode: "BLL",
    cityName: "Billund",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "BLM",
    airportName: "Monmouth Executive Arpt.",
    cityCode: "BLM",
    cityName: "Belmar - Farmingdale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BLN",
    airportName: "Benalla",
    cityCode: "BLN",
    cityName: "Benalla",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BLO",
    airportName: "Blonduos",
    cityCode: "BLO",
    cityName: "Blonduos",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "BLP",
    airportName: "Bellavista",
    cityCode: "BLP",
    cityName: "Bellavista",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "BLQ",
    airportName: "Guglielmo Marconi",
    cityCode: "BLQ",
    cityName: "Bologna",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "BLR",
    airportName: "Bengaluru Nadaprabhu Kempegowda International Airport code.",
    cityCode: "BLR",
    cityName: "Bangalore",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BLS",
    airportName: "Bollon",
    cityCode: "BLS",
    cityName: "Bollon",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BLT",
    airportName: "Blackwater",
    cityCode: "BLT",
    cityName: "Blackwater",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BLU",
    airportName: "Blue Canyon",
    cityCode: "BLU",
    cityName: "Blue Canyon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BLV",
    airportName: "Belleville",
    cityCode: "BLV",
    cityName: "Belleville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BLW",
    airportName: "Bellows Field",
    cityCode: "BLW",
    cityName: "Waimanalo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BLX",
    airportName: "Belluno",
    cityCode: "BLX",
    cityName: "Belluno",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "BLY",
    airportName: "Belmullet",
    cityCode: "BLY",
    cityName: "Belmullet",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "BLZ",
    airportName: "Chileka",
    cityCode: "BLZ",
    cityName: "Blantyre",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "BMA",
    airportName: "Bromma",
    cityCode: "STO",
    cityName: "Stockholm",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "BMB",
    airportName: "Bumba",
    cityCode: "BMB",
    cityName: "Bumba",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BMC",
    airportName: "Brigham City",
    cityCode: "BMC",
    cityName: "Brigham City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BMD",
    airportName: "Belo",
    cityCode: "BMD",
    cityName: "Belo",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "BME",
    airportName: "Broome",
    cityCode: "BME",
    cityName: "Broome, Western Australia",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BMF",
    airportName: "Bakouma",
    cityCode: "BMF",
    cityName: "Bakouma",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BMG",
    airportName: "Bloomington",
    cityCode: "BMG",
    cityName: "Bloomington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BMH",
    airportName: "Bomai",
    cityCode: "BMH",
    cityName: "Bomai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BMI",
    airportName: "Central Illinois Regional Arpt.",
    cityCode: "BMI",
    cityName: "Bloomington/Normal, IL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BMJ",
    airportName: "Baramita",
    cityCode: "BMJ",
    cityName: "Baramita",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "BMK",
    airportName: "Borkum",
    cityCode: "BMK",
    cityName: "Borkum",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BML",
    airportName: "Berlin Municipal Arpt.",
    cityCode: "BML",
    cityName: "Berlin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BMM",
    airportName: "Bitam",
    cityCode: "BMM",
    cityName: "Bitam",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "BMN",
    airportName: "Bamerny",
    cityCode: "BMN",
    cityName: "Bamerny",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "BMO",
    airportName: "Bhamo",
    cityCode: "BMO",
    cityName: "Bhamo",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "BMP",
    airportName: "Brampton Island",
    cityCode: "BMP",
    cityName: "Brampton Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BMQ",
    airportName: "Bamburi",
    cityCode: "BMQ",
    cityName: "Bamburi",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "BMR",
    airportName: "Baltrum",
    cityCode: "BMR",
    cityName: "Baltrum Island",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BMS",
    airportName: "Brumado",
    cityCode: "BMS",
    cityName: "Brumado",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BMT",
    airportName: "Beaumont Muni",
    cityCode: "BMT",
    cityName: "Beaumont",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BMU",
    airportName: "Bima",
    cityCode: "BMU",
    cityName: "Bima",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BMV",
    airportName: "Phung-Duc",
    cityCode: "BMV",
    cityName: "Banmethuot",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "BMW",
    airportName: "Bordj Badji Mokhtar",
    cityCode: "BMW",
    cityName: "Bordj Badji Mokhtar",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "BMX",
    airportName: "Big Mountain",
    cityCode: "BMX",
    cityName: "Big Mountain",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BMY",
    airportName: "Belep Island",
    cityCode: "BMY",
    cityName: "Belep Island",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "BMZ",
    airportName: "Bamu",
    cityCode: "BMZ",
    cityName: "Bamu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BNA",
    airportName: "Nashville Intl.",
    cityCode: "BNA",
    cityName: "Nashville, TN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BNB",
    airportName: "Boende",
    cityCode: "BNB",
    cityName: "Boende",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BNC",
    airportName: "Beni",
    cityCode: "BNC",
    cityName: "Beni",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BND",
    airportName: "Bandar Abbas",
    cityCode: "BND",
    cityName: "Bandar Abbas",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "BNE",
    airportName: "Brisbane Intl.",
    cityCode: "BNE",
    cityName: "Brisbane",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BNF",
    airportName: "Warm Spring Bay SPB",
    cityCode: "BNF",
    cityName: "Baranof",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BNG",
    airportName: "Banning",
    cityCode: "BNG",
    cityName: "Banning",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BNH",
    airportName: "Barnes",
    cityCode: "BNH",
    cityName: "Hartford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BNI",
    airportName: "Benin City",
    cityCode: "BNI",
    cityName: "Benin City",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "BNK",
    airportName: "Ballina Byron Gateway",
    cityCode: "BNK",
    cityName: "Ballina",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BNL",
    airportName: "Barnwell Regional",
    cityCode: "BNL",
    cityName: "Barnwell, SC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BNM",
    airportName: "Bodinumu",
    cityCode: "BNM",
    cityName: "Bodinumu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BNN",
    airportName: "Bronnoy",
    cityCode: "BNN",
    cityName: "Bronnoysund",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "BNO",
    airportName: "Burns",
    cityCode: "BNO",
    cityName: "Burns",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BNP",
    airportName: "Bannu",
    cityCode: "BNP",
    cityName: "Bannu",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "BNQ",
    airportName: "Baganga",
    cityCode: "BNQ",
    cityName: "Baganga",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "BNR",
    airportName: "Banfora",
    cityCode: "BNR",
    cityName: "Banfora",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "BNS",
    airportName: "Barinas",
    cityCode: "BNS",
    cityName: "Barinas",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "BNT",
    airportName: "Bundi",
    cityCode: "BNT",
    cityName: "Bundi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BNU",
    airportName: "Blumenau",
    cityCode: "BNU",
    cityName: "Blumenau",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BNV",
    airportName: "Boana",
    cityCode: "BNV",
    cityName: "Boana",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BNW",
    airportName: "Boone",
    cityCode: "BNW",
    cityName: "Boone",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BNX",
    airportName: "Banja Luka",
    cityCode: "BNX",
    cityName: "Banja Luka",
    countryName: "Bosnia And Herzegovina",
    countryCode: "BA",
  },
  {
    airportCode: "BNY",
    airportName: "Bellona",
    cityCode: "BNY",
    cityName: "Bellona",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "BNZ",
    airportName: "Banz",
    cityCode: "BNZ",
    cityName: "Banz",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BOA",
    airportName: "Boma",
    cityCode: "BOA",
    cityName: "Boma",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BOB",
    airportName: "Motu-mute",
    cityCode: "BOB",
    cityName: "Bora Bora",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "BOC",
    airportName: "Bocas Del Toro",
    cityCode: "BOC",
    cityName: "Bocas Del Toro",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "BOD",
    airportName: "MÃ©rignac",
    cityCode: "BOD",
    cityName: "Bordeaux",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BOE",
    airportName: "Boundji",
    cityCode: "BOE",
    cityName: "Boundji",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "BOF",
    airportName: "Bolling AFB",
    cityCode: "BOF",
    cityName: "Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BOG",
    airportName: "Eldorado Intl.",
    cityCode: "BOG",
    cityName: "Bogota",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "BOH",
    airportName: "Bournemouth Intl.",
    cityCode: "BOH",
    cityName: "Bournemouth",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BOI",
    airportName: "Boise Air Terminal/Gowen Field",
    cityCode: "BOI",
    cityName: "Boise, ID",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BOJ",
    airportName: "Bourgas",
    cityCode: "BOJ",
    cityName: "Bourgas",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "BOK",
    airportName: "Brookings State",
    cityCode: "BOK",
    cityName: "Brookings",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BOL",
    airportName: "Bally Kelly",
    cityCode: "BOL",
    cityName: "Bally Kelly",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BOM",
    airportName: "Chhatrapati Shivaji Maharaj International Airport.",
    cityCode: "BOM",
    cityName: "Mumbai",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BON",
    airportName: "Flamingo Intl.",
    cityCode: "BON",
    cityName: "Kralendijk",
    countryName: "Netherlands Antilles",
    countryCode: "AN",
  },
  {
    airportCode: "BOO",
    airportName: "Bodo",
    cityCode: "BOO",
    cityName: "Bodo",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "BOP",
    airportName: "Bouar",
    cityCode: "BOP",
    cityName: "Bouar",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BOQ",
    airportName: "Boku",
    cityCode: "BOQ",
    cityName: "Boku",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BOR",
    airportName: "Fontaine",
    cityCode: "BOR",
    cityName: "Belfort",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BOS",
    airportName: "Logan Intl.",
    cityCode: "BOS",
    cityName: "Boston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BOU",
    airportName: "Bourges",
    cityCode: "BOU",
    cityName: "Bourges",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BOV",
    airportName: "Boang",
    cityCode: "BOV",
    cityName: "Boang",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BOW",
    airportName: "Bartow",
    cityCode: "BOW",
    cityName: "Bartow",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BOX",
    airportName: "Borroloola",
    cityCode: "BOX",
    cityName: "Borroloola",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BOY",
    airportName: "Borgo",
    cityCode: "BOY",
    cityName: "Bobo Dioulasso",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "BOZ",
    airportName: "Bozoum",
    cityCode: "BOZ",
    cityName: "Bozoum",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BPA",
    airportName: "Grumman",
    cityCode: "BPA",
    cityName: "Bethpage",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BPB",
    airportName: "Boridi",
    cityCode: "BPB",
    cityName: "Boridi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BPC",
    airportName: "Bamenda",
    cityCode: "BPC",
    cityName: "Bamenda",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "BPD",
    airportName: "Bapi",
    cityCode: "BPD",
    cityName: "Bapi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BPE",
    airportName: "Bagan",
    cityCode: "BPE",
    cityName: "Bagan",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "BPF",
    airportName: "Batuna Aerodrome",
    cityCode: "BPF",
    cityName: "Batuna",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "BPG",
    airportName: "Barra Do Garcas",
    cityCode: "BPG",
    cityName: "Barra Do Garcas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BPH",
    airportName: "Bislig",
    cityCode: "BPH",
    cityName: "Bislig",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "BPI",
    airportName: "Big Piney-marbleton",
    cityCode: "BPI",
    cityName: "Big Piney",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BPK",
    airportName: "Biangabip",
    cityCode: "BPK",
    cityName: "Biangabip",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BPN",
    airportName: "Sepingan",
    cityCode: "BPN",
    cityName: "Balikpapan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BPS",
    airportName: "Porto Seguro",
    cityCode: "BPS",
    cityName: "Porto Seguro",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BPT",
    airportName: "Jefferson County",
    cityCode: "BPT",
    cityName: "Beaumont",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BPU",
    airportName: "Beppu",
    cityCode: "BPU",
    cityName: "Beppu",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "BPX",
    airportName: "Bangda",
    cityCode: "BPX",
    cityName: "Bangda",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "BPY",
    airportName: "Besalampy",
    cityCode: "BPY",
    cityName: "Besalampy",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "BQA",
    airportName: "Baler",
    cityCode: "BQA",
    cityName: "Baler",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "BQB",
    airportName: "Bussellton",
    cityCode: "BQB",
    cityName: "Bussellton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BQE",
    airportName: "Bubaque",
    cityCode: "BQE",
    cityName: "Bubaque",
    countryName: "Guinea-Bissau",
    countryCode: "GW",
  },
  {
    airportCode: "BQI",
    airportName: "Bagani",
    cityCode: "BQI",
    cityName: "Bagani",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "BQK",
    airportName: "Glynco Jetport",
    cityCode: "BQK",
    cityName: "Brunswick",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BQL",
    airportName: "Boulia",
    cityCode: "BQL",
    cityName: "Boulia",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BQN",
    airportName: "Borinquen",
    cityCode: "BQN",
    cityName: "Aguadilla",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "BQO",
    airportName: "Bouna",
    cityCode: "BQO",
    cityName: "Bouna",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "BQQ",
    airportName: "Barra",
    cityCode: "BQQ",
    cityName: "Barra",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BQS",
    airportName: "Blagoveschensk",
    cityCode: "BQS",
    cityName: "Blagoveschensk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "BQT",
    airportName: "Brest",
    cityCode: "BQT",
    cityName: "Brest",
    countryName: "Belarus",
    countryCode: "BY",
  },
  {
    airportCode: "BQU",
    airportName: "J.F. Mitchell",
    cityCode: "BQU",
    cityName: "Bequia",
    countryName: "Saint Vincent And The Grenadines",
    countryCode: "SVG",
  },
  {
    airportCode: "BQV",
    airportName: "Bartlett SPB",
    cityCode: "BQV",
    cityName: "Gustavus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BQW",
    airportName: "Balgo Hills",
    cityCode: "BQW",
    cityName: "Balgo Hills",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BRA",
    airportName: "Barreiras",
    cityCode: "BRA",
    cityName: "Barreiras",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BRB",
    airportName: "Barreirinhas",
    cityCode: "BRB",
    cityName: "Barreirinhas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BRC",
    airportName: "Intl.",
    cityCode: "BRC",
    cityName: "San Carlos DeBariloche",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "BRD",
    airportName: "Brainerd Lakes Regional",
    cityCode: "BRD",
    cityName: "Brainerd, MN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BRE",
    airportName: "Bremen",
    cityCode: "BRE",
    cityName: "Bremen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BRF",
    airportName: "Bradford",
    cityCode: "BRF",
    cityName: "Bradford",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BRG",
    airportName: "Municipal",
    cityCode: "BRG",
    cityName: "Whitesburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BRH",
    airportName: "Brahman",
    cityCode: "BRH",
    cityName: "Brahman",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BRI",
    airportName: "Palese",
    cityCode: "BRI",
    cityName: "Bari",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "BRJ",
    airportName: "Bright",
    cityCode: "BRJ",
    cityName: "Bright",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BRK",
    airportName: "Bourke",
    cityCode: "BRK",
    cityName: "Bourke",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BRL",
    airportName: "Burlington",
    cityCode: "BRL",
    cityName: "Burlington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BRM",
    airportName: "Barquisimeto",
    cityCode: "BRM",
    cityName: "Barquisimeto",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "BRN",
    airportName: "Belp",
    cityCode: "BRN",
    cityName: "Berne",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "BRO",
    airportName: "South Padre Is. Intl",
    cityCode: "BRO",
    cityName: "Brownsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BRP",
    airportName: "Biaru",
    cityCode: "BRP",
    cityName: "Biaru",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BRQ",
    airportName: "Turany",
    cityCode: "BRQ",
    cityName: "Brno",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "BRR",
    airportName: "North Bay",
    cityCode: "BRR",
    cityName: "Barra",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BRS",
    airportName: "Bristol",
    cityCode: "BRS",
    cityName: "Bristol",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BRT",
    airportName: "Bathurst Island",
    cityCode: "BRT",
    cityName: "Bathurst Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BRU",
    airportName: "Brussels Arpt.",
    cityCode: "BRU",
    cityName: "Brussels",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "BRV",
    airportName: "Bremerhaven",
    cityCode: "BRV",
    cityName: "Bremerhaven",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BRW",
    airportName: "Wiley Post/W.Rogers M",
    cityCode: "BRW",
    cityName: "Barrow",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BRX",
    airportName: "Barahona",
    cityCode: "BRX",
    cityName: "Barahona",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "BRY",
    airportName: "Samuels Field",
    cityCode: "BRY",
    cityName: "Bardstown, KY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BRZ",
    airportName: "Borotou",
    cityCode: "BRZ",
    cityName: "Borotou",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "BSA",
    airportName: "Bossaso",
    cityCode: "BSA",
    cityName: "Bossaso",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "BSB",
    airportName: "Presidente Juscelino Kubitschek",
    cityCode: "BSB",
    cityName: "BrasÃ­lia, Distrito Federal",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BSC",
    airportName: "Bahia Solano",
    cityCode: "BSC",
    cityName: "Bahia Solano",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "BSD",
    airportName: "Baoshan",
    cityCode: "BSD",
    cityName: "Baoshan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "BSE",
    airportName: "Sematan",
    cityCode: "BSE",
    cityName: "Sematan",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "BSF",
    airportName: "Bradshaw AAF",
    cityCode: "BSF",
    cityName: "Pohakuloa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BSG",
    airportName: "Bata",
    cityCode: "BSG",
    cityName: "Bata",
    countryName: "Equatorial Guinea",
    countryCode: "GQ",
  },
  {
    airportCode: "BSH",
    airportName: "Brighton",
    cityCode: "BSH",
    cityName: "Brighton",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BSI",
    airportName: "Blairsville",
    cityCode: "BSI",
    cityName: "Blairsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BSJ",
    airportName: "Bairnsdale",
    cityCode: "BSJ",
    cityName: "Bairnsdale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BSK",
    airportName: "Biskra",
    cityCode: "BSK",
    cityName: "Biskra",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "BSL",
    airportName: "EuroArpt. Basel Mulhouse Freiburg",
    cityCode: "BSL",
    cityName: "Mulhouse/basel",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "GMV",
    airportName: "Monument Valley",
    cityCode: "GMV",
    cityName: "Monument Valley Gldngs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GMY",
    airportName: "Rheindahlen",
    cityCode: "GMY",
    cityName: "Rheindahlen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "GMZ",
    airportName: "La Gomera",
    cityCode: "GMZ",
    cityName: "San Sebas De La Gomera",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "GNA",
    airportName: "Grodna",
    cityCode: "GNA",
    cityName: "Grodna",
    countryName: "Belarus",
    countryCode: "BY",
  },
  {
    airportCode: "GNB",
    airportName: "Grenoble-isere Arpt.",
    cityCode: "GNB",
    cityName: "Grenoble",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "GND",
    airportName: "Point Salines Intl.",
    cityCode: "GND",
    cityName: "St. Georges",
    countryName: "Grenada",
    countryCode: "GD",
  },
  {
    airportCode: "GNE",
    airportName: "Ghent",
    cityCode: "GNE",
    cityName: "Ghent",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "GNG",
    airportName: "Gooding",
    cityCode: "GNG",
    cityName: "Gooding",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GNI",
    airportName: "Green Island",
    cityCode: "GNI",
    cityName: "Green Island",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "GNM",
    airportName: "Guanambi",
    cityCode: "GNM",
    cityName: "Guanambi",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GNN",
    airportName: "Ghinnir",
    cityCode: "GNN",
    cityName: "Ghinnir",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "GNR",
    airportName: "General Roca",
    cityCode: "GNR",
    cityName: "General Roca",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "GNS",
    airportName: "Binaka",
    cityCode: "GNS",
    cityName: "Gunungsitoli",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "GNT",
    airportName: "Milan",
    cityCode: "GNT",
    cityName: "Grants",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GNU",
    airportName: "Goodnews Bay",
    cityCode: "GNU",
    cityName: "Goodnews Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GNV",
    airportName: "Gainesville Regional",
    cityCode: "GNV",
    cityName: "Gainesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GNZ",
    airportName: "Ghanzi",
    cityCode: "GNZ",
    cityName: "Ghanzi",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "GOA",
    airportName: "Cristoforo Colombo",
    cityCode: "GOA",
    cityName: "Genova",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "GOB",
    airportName: "Goba",
    cityCode: "GOB",
    cityName: "Goba",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "GOC",
    airportName: "Gora",
    cityCode: "GOC",
    cityName: "Gora",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GOE",
    airportName: "Gonalia",
    cityCode: "GOE",
    cityName: "Gonalia",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GOF",
    airportName: "Goodfellow AFB",
    cityCode: "GOF",
    cityName: "San Angelo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GOG",
    airportName: "Gobabis",
    cityCode: "GOG",
    cityName: "Gobabis",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "GOH",
    airportName: "Nuuk",
    cityCode: "GOH",
    cityName: "Nuuk",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "GOI",
    airportName: "Dabolim",
    cityCode: "GOI",
    cityName: "Goa",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "GOJ",
    airportName: "Nizhniy Novgorod",
    cityCode: "GOJ",
    cityName: "Nizhniy Novgorod",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "GOK",
    airportName: "Guthrie",
    cityCode: "GOK",
    cityName: "Guthrie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GOL",
    airportName: "State",
    cityCode: "GOL",
    cityName: "Gold Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GOM",
    airportName: "Goma",
    cityCode: "GOM",
    cityName: "Goma",
    countryName: "Democratic Republic Of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "GON",
    airportName: "New London",
    cityCode: "GON",
    cityName: "New London",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GOO",
    airportName: "Goondiwindi",
    cityCode: "GOO",
    cityName: "Goondiwindi",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GOP",
    airportName: "Gorakhpur",
    cityCode: "GOP",
    cityName: "Gorakhpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "GOQ",
    airportName: "Golmud",
    cityCode: "GOQ",
    cityName: "Golmud",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "GOR",
    airportName: "Gore",
    cityCode: "GOR",
    cityName: "Gore",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "GOS",
    airportName: "Gosford",
    cityCode: "GOS",
    cityName: "Gosford",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GOT",
    airportName: "Landvetter",
    cityCode: "GOT",
    cityName: "Gothenburg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "GOU",
    airportName: "Garoua",
    cityCode: "GOU",
    cityName: "Garoua",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "GOV",
    airportName: "Nhulunbuy",
    cityCode: "GOV",
    cityName: "Gove",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GOY",
    airportName: "Amparai",
    cityCode: "GOY",
    cityName: "Gal Oya",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "GOZ",
    airportName: "Gorna Oriahovitsa",
    cityCode: "GOZ",
    cityName: "Gorna Oriahovitsa",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "GPA",
    airportName: "Araxos Arpt.",
    cityCode: "GPA",
    cityName: "Patras",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "GPB",
    airportName: "Tancredo Thomaz Faria",
    cityCode: "GPB",
    cityName: "Guarapuava",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GPI",
    airportName: "Guapi",
    cityCode: "GPI",
    cityName: "Guapi",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "GPL",
    airportName: "Guapiles",
    cityCode: "GPL",
    cityName: "Guapiles",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "GPN",
    airportName: "Garden Point",
    cityCode: "GPN",
    cityName: "Garden Point",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GPO",
    airportName: "General Pico",
    cityCode: "GPO",
    cityName: "General Pico",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "GPS",
    airportName: "Baltra",
    cityCode: "GPS",
    cityName: "Galapagos Is",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "GPT",
    airportName: "Gulfport-Biloxi Intl.",
    cityCode: "GPT",
    cityName: "Gulfport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GPZ",
    airportName: "Grand Rapids",
    cityCode: "GPZ",
    cityName: "Grand Rapids",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GQJ",
    airportName: "RAF Station",
    cityCode: "GQJ",
    cityName: "Machrihanish",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "GQQ",
    airportName: "Galion",
    cityCode: "GQQ",
    cityName: "Galion",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRA",
    airportName: "Gamarra",
    cityCode: "GRA",
    cityName: "Gamarra",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "GRB",
    airportName: "Austin-straubel Field",
    cityCode: "GRB",
    cityName: "Green Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRC",
    airportName: "Grand Cess",
    cityCode: "GRC",
    cityName: "Grand Cess",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "GRD",
    airportName: "Greenwood",
    cityCode: "GRD",
    cityName: "Greenwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRE",
    airportName: "Municipal",
    cityCode: "GRE",
    cityName: "Greenville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRF",
    airportName: "Gray Aaf",
    cityCode: "GRF",
    cityName: "Tacoma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRG",
    airportName: "Gardez",
    cityCode: "GRG",
    cityName: "Gardez",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "GRH",
    airportName: "Garuahi",
    cityCode: "GRH",
    cityName: "Garuahi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GRI",
    airportName: "Grand Island",
    cityCode: "GRI",
    cityName: "Grand Island, NE",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRJ",
    airportName: "George",
    cityCode: "GRJ",
    cityName: "George",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "GRK",
    airportName: "Gray Aaf",
    cityCode: "GRK",
    cityName: "Fort Hood, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRL",
    airportName: "Garasa",
    cityCode: "GRL",
    cityName: "Garasa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GRM",
    airportName: "Devils Track",
    cityCode: "GRM",
    cityName: "Grand Marais",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRN",
    airportName: "Gordon",
    cityCode: "GRN",
    cityName: "Gordon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRO",
    airportName: "Girona-Costa Brava",
    cityCode: "GRO",
    cityName: "Gerona",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "GRP",
    airportName: "Gurupi",
    cityCode: "GRP",
    cityName: "Gurupi",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GRQ",
    airportName: "Eelde",
    cityCode: "GRQ",
    cityName: "Groningen",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "GRR",
    airportName: "Kent County Intl",
    cityCode: "GRR",
    cityName: "Grand Rapids",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GRS",
    airportName: "Baccarini",
    cityCode: "GRS",
    cityName: "Grosseto",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "GRT",
    airportName: "Gujrat",
    cityCode: "GRT",
    cityName: "Gujrat",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "GRU",
    airportName: "Aeroporto Internacional Guarulhos",
    cityCode: "SAO",
    cityName: "Sao Paulo",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GRV",
    airportName: "Groznyj",
    cityCode: "GRV",
    cityName: "Groznyj",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "GRW",
    airportName: "Graciosa Island",
    cityCode: "GRW",
    cityName: "Graciosa Island",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "GRX",
    airportName: "Granada",
    cityCode: "GRX",
    cityName: "Granada",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "GRY",
    airportName: "Grimsey",
    cityCode: "GRY",
    cityName: "Grimsey",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "GRZ",
    airportName: "Thalerhof",
    cityCode: "GRZ",
    cityName: "Graz",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "GSA",
    airportName: "Long Pasia",
    cityCode: "GSA",
    cityName: "Long Pasia",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "GSC",
    airportName: "Gascoyne Junction",
    cityCode: "GSC",
    cityName: "Gascoyne Junction",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GSE",
    airportName: "Gothenburg City Arpt.",
    cityCode: "GOT",
    cityName: "Gothenburg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "GSH",
    airportName: "Goshen",
    cityCode: "GSH",
    cityName: "Goshen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GSI",
    airportName: "Guadalcanal",
    cityCode: "GSI",
    cityName: "Guadalcanal",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "GSN",
    airportName: "Mount Gunson",
    cityCode: "GSN",
    cityName: "Mount Gunson",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GSO",
    airportName: "Piedmont Triad Intl",
    cityCode: "GSO",
    cityName: "Greensboro, NC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GSP",
    airportName: "Greenville Spartanbur Int'l Arpt.",
    cityCode: "GSP",
    cityName: "Greenville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GSQ",
    airportName: "Shark Elowainat",
    cityCode: "GSQ",
    cityName: "Shark Elowainat",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "GSR",
    airportName: "Gardo",
    cityCode: "GSR",
    cityName: "Gardo",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "GSS",
    airportName: "Sabi Sabi",
    cityCode: "GSS",
    cityName: "Sabi Sabi",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "GST",
    airportName: "Gustavus Arpt",
    cityCode: "GST",
    cityName: "Gustavus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GSU",
    airportName: "Gedaref",
    cityCode: "GSU",
    cityName: "Gedaref",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "GSY",
    airportName: "Binbrook",
    cityCode: "GSY",
    cityName: "Grimsby",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "GTA",
    airportName: "Gatokae Aerodrom",
    cityCode: "GTA",
    cityName: "Gatokae",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "GTB",
    airportName: "Genting",
    cityCode: "GTB",
    cityName: "Genting",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "GTC",
    airportName: "Green Turtle",
    cityCode: "GTC",
    cityName: "Green Turtle",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "GTE",
    airportName: "Alyangula",
    cityCode: "GTE",
    cityName: "Groote Eylandt",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GTF",
    airportName: "Intl.",
    cityCode: "GTF",
    cityName: "Great Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GTG",
    airportName: "Municipal",
    cityCode: "GTG",
    cityName: "Grantsburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GTI",
    airportName: "Guettin",
    cityCode: "GTI",
    cityName: "Guettin",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "GTK",
    airportName: "Sungei Tekai",
    cityCode: "GTK",
    cityName: "Sungei Tekai",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "GTN",
    airportName: "Glentanner",
    cityCode: "GTN",
    cityName: "Mount Cook",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "GTO",
    airportName: "Tolotio",
    cityCode: "GTO",
    cityName: "Gorontalo",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "GTR",
    airportName: "Golden Triangle Reg.",
    cityCode: "GTR",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GTS",
    airportName: "Granites",
    cityCode: "GTS",
    cityName: "Granites",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GTT",
    airportName: "Georgetown",
    cityCode: "GTT",
    cityName: "Georgetown",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GTW",
    airportName: "Holesov",
    cityCode: "GTW",
    cityName: "Zlin",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "GTY",
    airportName: "Gettysburg",
    cityCode: "GTY",
    cityName: "Gettysburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GUA",
    airportName: "La Aurora",
    cityCode: "GUA",
    cityName: "Guatemala City",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "GUB",
    airportName: "Guerrero Negro",
    cityCode: "GUB",
    cityName: "Guerrero Negro",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "GUC",
    airportName: "Gunnison",
    cityCode: "GUC",
    cityName: "Gunnison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GUD",
    airportName: "Goundam",
    cityCode: "GUD",
    cityName: "Goundam",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "GUE",
    airportName: "Guriaso",
    cityCode: "GUE",
    cityName: "Guriaso",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GUF",
    airportName: "Edwards",
    cityCode: "GUF",
    cityName: "Gulf Shores",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GUG",
    airportName: "Guari",
    cityCode: "GUG",
    cityName: "Guari",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GUH",
    airportName: "Gunnedah",
    cityCode: "GUH",
    cityName: "Gunnedah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GUI",
    airportName: "Guiria",
    cityCode: "GUI",
    cityName: "Guiria",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "GUJ",
    airportName: "Guaratingueta",
    cityCode: "GUJ",
    cityName: "Guaratingueta",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GUL",
    airportName: "Goulburn",
    cityCode: "GUL",
    cityName: "Goulburn",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GUM",
    airportName: "Guam Intl.",
    cityCode: "GUM",
    cityName: "Agana",
    countryName: "Guam",
    countryCode: "GU",
  },
  {
    airportCode: "GUN",
    airportName: "Gunter AFB",
    cityCode: "GUN",
    cityName: "Montgomery",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GUO",
    airportName: "Gualaco",
    cityCode: "GUO",
    cityName: "Gualaco",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "GUP",
    airportName: "Senator Clark",
    cityCode: "GUP",
    cityName: "Gallup",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GUQ",
    airportName: "Guanare",
    cityCode: "GUQ",
    cityName: "Guanare",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "GUR",
    airportName: "Gurney",
    cityCode: "GUR",
    cityName: "Alotau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GUS",
    airportName: "Grissom Afb",
    cityCode: "GUS",
    cityName: "Peru, Indiana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GUT",
    airportName: "Guetersloh",
    cityCode: "GUT",
    cityName: "Guetersloh",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "GUU",
    airportName: "Grundarfjordur",
    cityCode: "GUU",
    cityName: "Grundarfjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "GUV",
    airportName: "Mougulu",
    cityCode: "GUV",
    cityName: "Mougulu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GUW",
    airportName: "Atyrau Intl.",
    cityCode: "GUW",
    cityName: "Atyrau",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "GUX",
    airportName: "Guna",
    cityCode: "GUX",
    cityName: "Guna",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "GUY",
    airportName: "Guymon",
    cityCode: "GUY",
    cityName: "Guymon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GUZ",
    airportName: "Guarapari",
    cityCode: "GUZ",
    cityName: "Guarapari",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GVA",
    airportName: "Geneve-cointrin",
    cityCode: "GVA",
    cityName: "Geneva",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "GVE",
    airportName: "Municipal",
    cityCode: "GVE",
    cityName: "Gordonsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GVI",
    airportName: "Green River",
    cityCode: "GVI",
    cityName: "Green River",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GVL",
    airportName: "Lee Gilmer Memorial",
    cityCode: "GVL",
    cityName: "Gainesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GVP",
    airportName: "Greenvale",
    cityCode: "GVP",
    cityName: "Greenvale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GVR",
    airportName: "Governador Valadares",
    cityCode: "GVR",
    cityName: "Governador Valadares",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GVT",
    airportName: "Majors Field",
    cityCode: "GVT",
    cityName: "Greenville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GVW",
    airportName: "Richards-Gebaur",
    cityCode: "GVW",
    cityName: "Grandview",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GVX",
    airportName: "Sandviken",
    cityCode: "GVX",
    cityName: "Gavle",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "GWA",
    airportName: "Gwa",
    cityCode: "GWA",
    cityName: "Gwa",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "GWD",
    airportName: "Gwadar",
    cityCode: "GWD",
    cityName: "Gwadar",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "GWE",
    airportName: "Gweru",
    cityCode: "GWE",
    cityName: "Gweru",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "GWL",
    airportName: "Gwalior",
    cityCode: "GWL",
    cityName: "Gwalior",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "GWN",
    airportName: "Gnarowein",
    cityCode: "GWN",
    cityName: "Gnarowein",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GWO",
    airportName: "Leflore",
    cityCode: "GWO",
    cityName: "Greenwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GWS",
    airportName: "Glenwood Springs",
    cityCode: "GWS",
    cityName: "Glenwood Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GWT",
    airportName: "Westerland - Sylt",
    cityCode: "GWT",
    cityName: "Westerland",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "GWV",
    airportName: "Glendale",
    cityCode: "GWV",
    cityName: "Glendale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GWW",
    airportName: "Raf Gatow",
    cityCode: "GWW",
    cityName: "Berlin",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "GWY",
    airportName: "Carnmore",
    cityCode: "GWY",
    cityName: "Galway",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "GXF",
    airportName: "Seiyun",
    cityCode: "GXF",
    cityName: "Seiyun",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "GXG",
    airportName: "Negage",
    cityCode: "GXG",
    cityName: "Negage",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "GXH",
    airportName: "NAF",
    cityCode: "GXH",
    cityName: "Mildenhall",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "GXQ",
    airportName: "Ten. Vidal",
    cityCode: "GXQ",
    cityName: "Coyhaique",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "GXX",
    airportName: "Yagoua",
    cityCode: "GXX",
    cityName: "Yagoua",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "GXY",
    airportName: "Weld County",
    cityCode: "GXY",
    cityName: "Greeley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GYA",
    airportName: "Guayaramerin",
    cityCode: "GYA",
    cityName: "Guayaramerin",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "GYD",
    airportName: "Heydar Aliyev Intl.",
    cityCode: "GYD",
    cityName: "Baku",
    countryName: "Azerbaijan",
    countryCode: "AZ",
  },
  {
    airportCode: "GYE",
    airportName: "Simon Bolivar",
    cityCode: "GYE",
    cityName: "Guayaquil",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "GYI",
    airportName: "Gisenyi",
    cityCode: "GYI",
    cityName: "Gisenyi",
    countryName: "Rwanda",
    countryCode: "RW",
  },
  {
    airportCode: "GYL",
    airportName: "Argyle",
    cityCode: "GYL",
    cityName: "Argyle",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GYM",
    airportName: "Gen Jose M Yanez",
    cityCode: "GYM",
    cityName: "Guaymas",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "GYN",
    airportName: "Santa Genoveva",
    cityCode: "GYN",
    cityName: "Goiania",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GYP",
    airportName: "Gympie",
    cityCode: "GYP",
    cityName: "Gympie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GYR",
    airportName: "Litchfield",
    cityCode: "GYR",
    cityName: "Goodyear",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GYS",
    airportName: "Guang Yuan",
    cityCode: "GYS",
    cityName: "Guang Yuan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "GYY",
    airportName: "Gary/Chicago Intl.",
    cityCode: "GYY",
    cityName: "Gary, IN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GZA",
    airportName: "Gaza - Yaser Arafat Intl. Arpt.",
    cityCode: "GZA",
    cityName: "Rafah",
    countryName: "Palestine",
    countryCode: "PS",
  },
  {
    airportCode: "GZI",
    airportName: "Ghazni",
    cityCode: "GZI",
    cityName: "Ghazni",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "GZM",
    airportName: "Gozo Heliport",
    cityCode: "GZM",
    cityName: "Gozo",
    countryName: "Malta",
    countryCode: "MT",
  },
  {
    airportCode: "GZO",
    airportName: "Gizo",
    cityCode: "GZO",
    cityName: "Gizo",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "GZT",
    airportName: "Gaziantep",
    cityCode: "GZT",
    cityName: "Gaziantep",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "GZW",
    airportName: "Ghazvin",
    cityCode: "GZW",
    cityName: "Ghazvin",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "HAA",
    airportName: "Hasvik",
    cityCode: "HAA",
    cityName: "Hasvik",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "HAB",
    airportName: "Marion County",
    cityCode: "HAB",
    cityName: "Hamilton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HAC",
    airportName: "Hachijo Jima",
    cityCode: "HAC",
    cityName: "Hachijo Jima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HAD",
    airportName: "Halmstad",
    cityCode: "HAD",
    cityName: "Halmstad",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "HAE",
    airportName: "Havasupai",
    cityCode: "HAE",
    cityName: "Havasupai",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HAF",
    airportName: "Half Moon",
    cityCode: "HAF",
    cityName: "Half Moon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HAH",
    airportName: "Prince Said Ibrahim In",
    cityCode: "HAH",
    cityName: "Moroni",
    countryName: "Comoros",
    countryCode: "KM",
  },
  {
    airportCode: "HAI",
    airportName: "Dr Haines",
    cityCode: "HAI",
    cityName: "Three Rivers",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HAJ",
    airportName: "Hanover Arpt",
    cityCode: "HAJ",
    cityName: "Hanover",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "HAK",
    airportName: "Haikou",
    cityCode: "HAK",
    cityName: "Haikou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HAL",
    airportName: "Halali",
    cityCode: "HAL",
    cityName: "Halali",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "HAM",
    airportName: "Hamburg Arpt.",
    cityCode: "HAM",
    cityName: "Hamburg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "HAN",
    airportName: "Noibai Intl.",
    cityCode: "HAN",
    cityName: "Hanoi",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "HAO",
    airportName: "Hamilton",
    cityCode: "HAO",
    cityName: "Hamilton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HAP",
    airportName: "Long Island",
    cityCode: "HAP",
    cityName: "Long Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HAQ",
    airportName: "Hanimaadhoo",
    cityCode: "HAQ",
    cityName: "Hanimaadhoo",
    countryName: "Maldives",
    countryCode: "MV",
  },
  {
    airportCode: "HAR",
    airportName: "Capital City",
    cityCode: "HAR",
    cityName: "Harrisburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HAS",
    airportName: "Ha'il",
    cityCode: "HAS",
    cityName: "Hail",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "HAT",
    airportName: "Heathlands",
    cityCode: "HAT",
    cityName: "Heathlands",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HAU",
    airportName: "Haugesund",
    cityCode: "HAU",
    cityName: "Haugesund",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "HAV",
    airportName: "Jose Marti Intl",
    cityCode: "HAV",
    cityName: "Havana",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "HAW",
    airportName: "Haverfordwest",
    cityCode: "HAW",
    cityName: "Haverfordwest",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "HAX",
    airportName: "Hatbox Field",
    cityCode: "HAX",
    cityName: "Muskogee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HAY",
    airportName: "Haycock",
    cityCode: "HAY",
    cityName: "Haycock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HAZ",
    airportName: "Hatzfeldthaven",
    cityCode: "HAZ",
    cityName: "Hatzfeldthaven",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HBA",
    airportName: "Hobart",
    cityCode: "HBA",
    cityName: "Hobart",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HBB",
    airportName: "Industrial Airpark",
    cityCode: "HBB",
    cityName: "Hobbs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HBD",
    airportName: "Habi",
    cityCode: "HBD",
    cityName: "Habi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HBE",
    airportName: "Amiryah West",
    cityCode: "ALY",
    cityName: "Alexandria",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "HBG",
    airportName: "The Hattiesburg-Bobby L. Chain Mun.",
    cityCode: "HBG",
    cityName: "Hattiesburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HBH",
    airportName: "Hobart Bay",
    cityCode: "HBH",
    cityName: "Hobart Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HBI",
    airportName: "Harbour Island",
    cityCode: "HBI",
    cityName: "Harbour Island",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "HBL",
    airportName: "Babelegi Heliport",
    cityCode: "HBL",
    cityName: "Babelegi",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "HBN",
    airportName: "Flamingo",
    cityCode: "HBN",
    cityName: "Phu-bon",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "HBO",
    airportName: "Municipal",
    cityCode: "HBO",
    cityName: "Humboldt",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HBR",
    airportName: "Hobart",
    cityCode: "HBR",
    cityName: "Hobart, OK",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HBT",
    airportName: "Hafr Albatin",
    cityCode: "HBT",
    cityName: "Hafr Albatin",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "HBX",
    airportName: "Hubli",
    cityCode: "HBX",
    cityName: "Hubli",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "HCA",
    airportName: "Howard County",
    cityCode: "HCA",
    cityName: "Big Spring",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HCB",
    airportName: "Shoal Cove",
    cityCode: "HCB",
    cityName: "Shoal Cove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HCM",
    airportName: "Eil",
    cityCode: "HCM",
    cityName: "Eil",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "HCN",
    airportName: "Hengchun",
    cityCode: "HCN",
    cityName: "Hengchun",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "HCQ",
    airportName: "Halls Creek",
    cityCode: "HCQ",
    cityName: "Halls Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HCR",
    airportName: "Holy Cross",
    cityCode: "HCR",
    cityName: "Holy Cross",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HCS",
    airportName: "Randburg Heliport",
    cityCode: "HCS",
    cityName: "Johannesburg",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "HCW",
    airportName: "Cheraw",
    cityCode: "HCW",
    cityName: "Cheraw",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HDA",
    airportName: "Hidden Falls",
    cityCode: "HDA",
    cityName: "Hidden Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HDB",
    airportName: "Heidelberg",
    cityCode: "HDB",
    cityName: "Heidelberg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "HDD",
    airportName: "Hyderabad",
    cityCode: "HDD",
    cityName: "Hyderabad",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "HDE",
    airportName: "Brewster Field",
    cityCode: "HDE",
    cityName: "Holdrege",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HDF",
    airportName: "Heringsdorf",
    cityCode: "HDF",
    cityName: "Heringsdorf",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "HDH",
    airportName: "Dillingham Airfield",
    cityCode: "HDH",
    cityName: "Oahu",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HDM",
    airportName: "Hamadan",
    cityCode: "HDM",
    cityName: "Hamadan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "HDN",
    airportName: "Yampa Valley",
    cityCode: "HDN",
    cityName: "Hayden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HDR",
    airportName: "Havadarya",
    cityCode: "HDR",
    cityName: "Havadarya",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "HDS",
    airportName: "Hoedspruit Arpt.",
    cityCode: "HDS",
    cityName: "Hoedspruit",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "HDY",
    airportName: "Hat Yai",
    cityCode: "HDY",
    cityName: "Hat Yai",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "HEA",
    airportName: "Herat",
    cityCode: "HEA",
    cityName: "Herat",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "HEB",
    airportName: "Henzada",
    cityCode: "HEB",
    cityName: "Henzada",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "HED",
    airportName: "Herendeen",
    cityCode: "HED",
    cityName: "Herendeen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HEE",
    airportName: "Thompson-Robbins",
    cityCode: "HEE",
    cityName: "Helena",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HEH",
    airportName: "Heho",
    cityCode: "HEH",
    cityName: "Heho",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "HEI",
    airportName: "Heide-Buesum",
    cityCode: "HEI",
    cityName: "Heide-Buesum",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "HEK",
    airportName: "Heihe",
    cityCode: "HEK",
    cityName: "Heihe",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HEL",
    airportName: "Helsinki-vantaa",
    cityCode: "HEL",
    cityName: "Vantaa",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "HEM",
    airportName: "Helsinki-malmi",
    cityCode: "HEM",
    cityName: "Helsinki",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "HEN",
    airportName: "Hendon",
    cityCode: "HEN",
    cityName: "Hendon",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "HEO",
    airportName: "Haelogo",
    cityCode: "HEO",
    cityName: "Haelogo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HER",
    airportName: "Nikos Kazantzakis Arpt.",
    cityCode: "HER",
    cityName: "Heraklion",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "HES",
    airportName: "State",
    cityCode: "HES",
    cityName: "Hermiston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HET",
    airportName: "Hohhot",
    cityCode: "HET",
    cityName: "Hohhot",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HEV",
    airportName: "Huelva",
    cityCode: "HEV",
    cityName: "Huelva",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "HEW",
    airportName: "Hellinikon",
    cityCode: "HEW",
    cityName: "Athens",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "HEX",
    airportName: "Santo Domingo Herrera",
    cityCode: "HEX",
    cityName: "Santo Domingo",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "HEY",
    airportName: "Hanchey Army Heliport",
    cityCode: "HEY",
    cityName: "Ozark",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HEZ",
    airportName: "Hardy-Anders",
    cityCode: "HEZ",
    cityName: "Natchez",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HFA",
    airportName: "Haifa",
    cityCode: "HFA",
    cityName: "Haifa",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "HFD",
    airportName: "Brainard",
    cityCode: "HFD",
    cityName: "Hartford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HFE",
    airportName: "Hefei ",
    cityCode: "HFE",
    cityName: "Luogang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HFF",
    airportName: "Mackall AAF",
    cityCode: "HFF",
    cityName: "Hoffman",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HFN",
    airportName: "Hornafjordur",
    cityCode: "HFN",
    cityName: "Hornafjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "HFS",
    airportName: "Hagfors",
    cityCode: "HFS",
    cityName: "Hagfors",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "HFT",
    airportName: "Hammerfest",
    cityCode: "HFT",
    cityName: "Hammerfest",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "HGA",
    airportName: "Hargeisa",
    cityCode: "HGA",
    cityName: "Hargeisa",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "HGD",
    airportName: "Hughenden",
    cityCode: "HGD",
    cityName: "Hughenden",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HGH",
    airportName: "Hangzhou",
    cityCode: "HGH",
    cityName: "Hangzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HGI",
    airportName: "Higlieg",
    cityCode: "HGI",
    cityName: "Higlieg",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "HGL",
    airportName: "Helgoland",
    cityCode: "HGL",
    cityName: "Helgoland",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "HGN",
    airportName: "Mae Hong Son",
    cityCode: "HGN",
    cityName: "Mae Hong Son",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "HGO",
    airportName: "Korhogo",
    cityCode: "HGO",
    cityName: "Korhogo",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "HGR",
    airportName: "Wash. County Regional",
    cityCode: "HGR",
    cityName: "Hagerstown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HGS",
    airportName: "Hastings",
    cityCode: "HGS",
    cityName: "Freetown",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "HGT",
    airportName: "Hunter AAF",
    cityCode: "HGT",
    cityName: "Jolon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HGU",
    airportName: "Kagamuga",
    cityCode: "HGU",
    cityName: "Mount Hagen",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HGZ",
    airportName: "Hogatza",
    cityCode: "HGZ",
    cityName: "Hogatza",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HHA",
    airportName: "Huanghua Intl.",
    cityCode: "HHA",
    cityName: "Changsha",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HHE",
    airportName: "Hachinohe",
    cityCode: "HHE",
    cityName: "Hachinohe",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HHH",
    airportName: "Hilton Head",
    cityCode: "HHH",
    cityName: "Hilton Head",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HHI",
    airportName: "Wheeler AFB",
    cityCode: "HHI",
    cityName: "Wahiawa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRA",
    airportName: "Frankfurt Intl. Arpt.",
    cityCode: "FRA",
    cityName: "Frankfurt",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "HHP",
    airportName: "H K Heliport",
    cityCode: "HHP",
    cityName: "Hong Kong",
    countryName: "Hong Kong",
    countryCode: "HK",
  },
  {
    airportCode: "HHQ",
    airportName: "Hua Hin Arpt.",
    cityCode: "HHQ",
    cityName: "Hua Hin",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "HHR",
    airportName: "Hawthorne",
    cityCode: "HHR",
    cityName: "Hawthorne",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HHZ",
    airportName: "Hikueru",
    cityCode: "HHZ",
    cityName: "Hikueru",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "HIB",
    airportName: "Chisholm",
    cityCode: "HIB",
    cityName: "Chisholm",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HIC",
    airportName: "Iscor Heliport",
    cityCode: "HIC",
    cityName: "Pretoria",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "HID",
    airportName: "Horn Island",
    cityCode: "HID",
    cityName: "Horn Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HIE",
    airportName: "Regional",
    cityCode: "HIE",
    cityName: "Whitefield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HIF",
    airportName: "Hill Afb",
    cityCode: "HIF",
    cityName: "Ogden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HIG",
    airportName: "Highbury",
    cityCode: "HIG",
    cityName: "Highbury",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HIH",
    airportName: "Hook Island",
    cityCode: "HIH",
    cityName: "Hook Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HII",
    airportName: "Municipal",
    cityCode: "HII",
    cityName: "Lake Havasu City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HIJ",
    airportName: "Intl.",
    cityCode: "HIJ",
    cityName: "Hiroshima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HIK",
    airportName: "Hickam AFB",
    cityCode: "HIK",
    cityName: "Honolulu",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HIM",
    airportName: "Hingurakgoda",
    cityCode: "HIM",
    cityName: "Hingurakgoda",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "HIN",
    airportName: "Sacheon",
    cityCode: "HIN",
    cityName: "Jinju",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "HIO",
    airportName: "Portland",
    cityCode: "HIO",
    cityName: "Hillsboro",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HIP",
    airportName: "Headingly",
    cityCode: "HIP",
    cityName: "Headingly",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HIR",
    airportName: "Henderson Intl",
    cityCode: "HIR",
    cityName: "Honiara",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "HIS",
    airportName: "Hayman Island",
    cityCode: "HIS",
    cityName: "Hayman Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HIT",
    airportName: "Hivaro",
    cityCode: "HIT",
    cityName: "Hivaro",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HIW",
    airportName: "Hiroshima West",
    cityCode: "HIW",
    cityName: "Hiroshima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HIX",
    airportName: "Hiva Oa",
    cityCode: "HIX",
    cityName: "Hiva Oa",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "HJR",
    airportName: "Khajuraho",
    cityCode: "HJR",
    cityName: "Khajuraho",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "HJT",
    airportName: "Khujirt",
    cityCode: "HJT",
    cityName: "Khujirt",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "HKA",
    airportName: "Municipal",
    cityCode: "HKA",
    cityName: "Blytheville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HKB",
    airportName: "Healy Lake",
    cityCode: "HKB",
    cityName: "Healy Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HKD",
    airportName: "Hakodate",
    cityCode: "HKD",
    cityName: "Hakodate",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HKG",
    airportName: "Hong Kong Intl.",
    cityCode: "HKG",
    cityName: "Hong Kong",
    countryName: "Hong Kong",
    countryCode: "HK",
  },
  {
    airportCode: "HKK",
    airportName: "Hokitika Arpt",
    cityCode: "HKK",
    cityName: "Hokitika",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "HKN",
    airportName: "Hoskins",
    cityCode: "HKN",
    cityName: "Hoskins",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HKS",
    airportName: "Hawkins Field",
    cityCode: "HKS",
    cityName: "Jackson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HKT",
    airportName: "Phuket Intl.",
    cityCode: "HKT",
    cityName: "Phuket",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "HKV",
    airportName: "Haskovo",
    cityCode: "HKV",
    cityName: "Haskovo",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "HKY",
    airportName: "Hickory",
    cityCode: "HKY",
    cityName: "Hickory",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HLA",
    airportName: "Lanseria",
    cityCode: "HLA",
    cityName: "Lanseria",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "HLB",
    airportName: "Hillenbrand",
    cityCode: "HLB",
    cityName: "Batesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HLC",
    airportName: "Hill City",
    cityCode: "HLC",
    cityName: "Hill City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HLD",
    airportName: "Hailar",
    cityCode: "HLD",
    cityName: "Hailar",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HLF",
    airportName: "Hultsfred - Vimmerby",
    cityCode: "HLF",
    cityName: "Hultsfred",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "HLG",
    airportName: "Ohio County",
    cityCode: "HLG",
    cityName: "Wheeling",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HLH",
    airportName: "Ulanhot",
    cityCode: "HLH",
    cityName: "Ulanhot",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HLI",
    airportName: "Hollister",
    cityCode: "HLI",
    cityName: "Hollister",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HLL",
    airportName: "Hillside",
    cityCode: "HLL",
    cityName: "Hillside",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HLM",
    airportName: "Park Township",
    cityCode: "HLM",
    cityName: "Holland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HLN",
    airportName: "Helena",
    cityCode: "HLN",
    cityName: "Helena",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HLP",
    airportName: "Halim Perdana Kusuma",
    cityCode: "HLP",
    cityName: "Jakarta",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "HLR",
    airportName: "Fort Hood AAF",
    cityCode: "HLR",
    cityName: "Killeen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HLS",
    airportName: "St Helens",
    cityCode: "HLS",
    cityName: "St Helens",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HLT",
    airportName: "Hamilton",
    cityCode: "HLT",
    cityName: "Hamilton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HLU",
    airportName: "Houailou",
    cityCode: "HLU",
    cityName: "Houailou",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "HLV",
    airportName: "Helenvale",
    cityCode: "HLV",
    cityName: "Helenvale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HLW",
    airportName: "Hluhluwe",
    cityCode: "HLW",
    cityName: "Hluhluwe",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "HLY",
    airportName: "Anglesey Arpt.",
    cityCode: "HLY",
    cityName: "Holyhead",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "HLZ",
    airportName: "Hamilton",
    cityCode: "HLZ",
    cityName: "Hamilton",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "HMA",
    airportName: "Khanty-Mansiysk",
    cityCode: "HMA",
    cityName: "Khanty-mansiysk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "HME",
    airportName: "Oued Irara Apt",
    cityCode: "HME",
    cityName: "Hassi Messaoud",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "HMG",
    airportName: "Hermannsburg",
    cityCode: "HMG",
    cityName: "Hermannsburg",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HMI",
    airportName: "Hami",
    cityCode: "HMI",
    cityName: "Hami",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HMJ",
    airportName: "Khmelnitskiy",
    cityCode: "HMJ",
    cityName: "Khmelnitskiy",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "HMN",
    airportName: "Holloman AFB",
    cityCode: "HMN",
    cityName: "Alamogordo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HMO",
    airportName: "Gen Pesqueira Garcia",
    cityCode: "HMO",
    cityName: "Hermosillo",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "HMR",
    airportName: "Hamar Arpt",
    cityCode: "HMR",
    cityName: "Hamar",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "HMS",
    airportName: "Homeshore",
    cityCode: "HMS",
    cityName: "Homeshore AK",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HMT",
    airportName: "Ryan Field",
    cityCode: "HMT",
    cityName: "Hemet",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HMV",
    airportName: "Hemavan",
    cityCode: "HMV",
    cityName: "Hemavan",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "HNA",
    airportName: "Hanamaki",
    cityCode: "HNA",
    cityName: "Hanamaki",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HNB",
    airportName: "Municipal",
    cityCode: "HNB",
    cityName: "Huntingburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HNC",
    airportName: "Hatteras",
    cityCode: "HNC",
    cityName: "Hatteras",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HND",
    airportName: "Tokyo Haneda Intl.",
    cityCode: "TYO",
    cityName: "Tokyo",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HNE",
    airportName: "Tahneta Pass",
    cityCode: "HNE",
    cityName: "Tahneta Pass Lodge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HNG",
    airportName: "Hienghene",
    cityCode: "HNG",
    cityName: "Hienghene",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "HNH",
    airportName: "Hoonah",
    cityCode: "HNH",
    cityName: "Hoonah",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HNI",
    airportName: "Heiweni",
    cityCode: "HNI",
    cityName: "Heiweni",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HNK",
    airportName: "Hinchinbrook Island",
    cityCode: "HNK",
    cityName: "Hinchinbrook Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HNL",
    airportName: "Honolulu Intl.",
    cityCode: "HNL",
    cityName: "Honolulu",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HNM",
    airportName: "Hana",
    cityCode: "HNM",
    cityName: "Hana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HNN",
    airportName: "Honinabi",
    cityCode: "HNN",
    cityName: "Honinabi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HNO",
    airportName: "Hercegnovi",
    cityCode: "HNO",
    cityName: "Hercegnovi",
    countryName: "Serbia and Montenegro",
    countryCode: "CS",
  },
  {
    airportCode: "HNS",
    airportName: "Municipal",
    cityCode: "HNS",
    cityName: "Haines",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HNX",
    airportName: "Hanna",
    cityCode: "HNX",
    cityName: "Hanna",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "HNY",
    airportName: "Hengyang",
    cityCode: "HNY",
    cityName: "Hengyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HOA",
    airportName: "Hola",
    cityCode: "HOA",
    cityName: "Hola",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "HOB",
    airportName: "Lea County",
    cityCode: "HOB",
    cityName: "Hobbs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HOC",
    airportName: "Komako",
    cityCode: "HOC",
    cityName: "Komako",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HOD",
    airportName: "Hodeidah Arpt",
    cityCode: "HOD",
    cityName: "Hodeidah",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "HOE",
    airportName: "Houeisay",
    cityCode: "HOE",
    cityName: "Houeisay",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "HOF",
    airportName: "Alahsa",
    cityCode: "HOF",
    cityName: "Alahsa",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "HOG",
    airportName: "Frank Pais",
    cityCode: "HOG",
    cityName: "Holguin",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "HOH",
    airportName: "Hohenems",
    cityCode: "HOH",
    cityName: "Hohenems",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "HOI",
    airportName: "Hao Island",
    cityCode: "HOI",
    cityName: "Hao Island",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "HOK",
    airportName: "Hooker Creek",
    cityCode: "HOK",
    cityName: "Hooker Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HOL",
    airportName: "Holikachu",
    cityCode: "HOL",
    cityName: "Holikachu",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HOM",
    airportName: "Homer",
    cityCode: "HOM",
    cityName: "Homer",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HON",
    airportName: "Howes",
    cityCode: "HON",
    cityName: "Huron",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HOO",
    airportName: "Nhon Co",
    cityCode: "HOO",
    cityName: "Quanduc",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "HOP",
    airportName: "Campbell AAF",
    cityCode: "HOP",
    cityName: "Hopkinsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HOQ",
    airportName: "Hof",
    cityCode: "HOQ",
    cityName: "Hof",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "HOR",
    airportName: "Horta",
    cityCode: "HOR",
    cityName: "Horta",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "HOT",
    airportName: "Memorial Field",
    cityCode: "HOT",
    cityName: "Hot Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HOU",
    airportName: "William P Hobby",
    cityCode: "HOU",
    cityName: "Houston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HOV",
    airportName: "Hovden",
    cityCode: "HOV",
    cityName: "Orsta-Volda",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "HOW",
    airportName: "Howard Afb",
    cityCode: "HOW",
    cityName: "Fort Kobbe",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "HOX",
    airportName: "Homalin",
    cityCode: "HOX",
    cityName: "Homalin",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "HOY",
    airportName: "Hoy Island",
    cityCode: "HOY",
    cityName: "Hoy Island",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "HPB",
    airportName: "Hooper Bay",
    cityCode: "HPB",
    cityName: "Hooper Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HPE",
    airportName: "Hope Vale",
    cityCode: "HPE",
    cityName: "Hope Vale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HPH",
    airportName: "Catbi",
    cityCode: "HPH",
    cityName: "Haiphong",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "HPN",
    airportName: "Westchester County Apt",
    cityCode: "NYC",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HPP",
    airportName: "Poipet",
    cityCode: "HPP",
    cityName: "Poipet",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "HPR",
    airportName: "Central Hpr",
    cityCode: "HPR",
    cityName: "Pretoria",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "HPT",
    airportName: "Municipal",
    cityCode: "HPT",
    cityName: "Hampton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HPV",
    airportName: "Princeville",
    cityCode: "HPV",
    cityName: "Kauai Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HPY",
    airportName: "Baytown",
    cityCode: "HPY",
    cityName: "Baytown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HQM",
    airportName: "Bowerman",
    cityCode: "HQM",
    cityName: "Hoquiam",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HRA",
    airportName: "Mansehra",
    cityCode: "HRA",
    cityName: "Mansehra",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "HRB",
    airportName: "Harbin",
    cityCode: "HRB",
    cityName: "Harbin",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HRC",
    airportName: "Zhairem",
    cityCode: "HRC",
    cityName: "Zhairem",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "HRE",
    airportName: "Harare",
    cityCode: "HRE",
    cityName: "Harare",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "HRG",
    airportName: "Hurghada",
    cityCode: "HRG",
    cityName: "Hurghada",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "HRJ",
    airportName: "Chaurjhari",
    cityCode: "HRJ",
    cityName: "Chaurjhari",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "HRK",
    airportName: "Kharkov",
    cityCode: "HRK",
    cityName: "Kharkov",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "HRL",
    airportName: "Valley Intl.",
    cityCode: "HRL",
    cityName: "Harlingen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HRN",
    airportName: "Heliport",
    cityCode: "HRN",
    cityName: "Heron Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HRO",
    airportName: "Boone County",
    cityCode: "HRO",
    cityName: "Harrison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HRR",
    airportName: "Herrera",
    cityCode: "HRR",
    cityName: "Herrera",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "HRS",
    airportName: "Harrismith Arpt.",
    cityCode: "HRS",
    cityName: "Harrismith",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "HRT",
    airportName: "Linton-On-Ouse",
    cityCode: "HRT",
    cityName: "Harrogate",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "HRY",
    airportName: "Henbury",
    cityCode: "HRY",
    cityName: "Henbury",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HRZ",
    airportName: "Horizontina",
    cityCode: "HRZ",
    cityName: "Horizontina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "HSB",
    airportName: "Raleigh",
    cityCode: "HSB",
    cityName: "Harrisburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HSC",
    airportName: "Shaoguan",
    cityCode: "HSC",
    cityName: "Shaoguan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HSG",
    airportName: "Saga",
    cityCode: "HSG",
    cityName: "Saga",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HSH",
    airportName: "Henderson Sky Harbor",
    cityCode: "LAS",
    cityName: "Las Vegas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HSI",
    airportName: "Hastings",
    cityCode: "HSI",
    cityName: "Hastings",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HSL",
    airportName: "Huslia",
    cityCode: "HSL",
    cityName: "Huslia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HSM",
    airportName: "Horsham",
    cityCode: "HSM",
    cityName: "Horsham",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HSN",
    airportName: "Zhoushan",
    cityCode: "HSN",
    cityName: "Zhoushan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HSP",
    airportName: "Ingalls Field",
    cityCode: "HSP",
    cityName: "Hot Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HSS",
    airportName: "Hissar",
    cityCode: "HSS",
    cityName: "Hissar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "HST",
    airportName: "AFB",
    cityCode: "HST",
    cityName: "Homestead",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HSV",
    airportName: "Huntsville Intl. - Carl T. Jones Field",
    cityCode: "HSV",
    cityName: "Huntsville, AL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HSZ",
    airportName: "Hsinchu",
    cityCode: "HSZ",
    cityName: "Hsinchu",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "HTA",
    airportName: "Chita",
    cityCode: "HTA",
    cityName: "Chita",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "HTB",
    airportName: "Terre-de-Bas",
    cityCode: "HTB",
    cityName: "Terre-de-Bas",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "HTF",
    airportName: "Hatfield",
    cityCode: "HTF",
    cityName: "Hatfield",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "HTG",
    airportName: "Hatanga",
    cityCode: "HTG",
    cityName: "Hatanga",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "HTH",
    airportName: "Hawthorne",
    cityCode: "HTH",
    cityName: "Hawthorne",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HTI",
    airportName: "Hamilton Island",
    cityCode: "HTI",
    cityName: "Hamilton Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HTL",
    airportName: "Roscommon County",
    cityCode: "HTL",
    cityName: "Houghton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HTM",
    airportName: "Khatgal",
    cityCode: "HTM",
    cityName: "Khatgal",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "HTN",
    airportName: "Hotan",
    cityCode: "HTN",
    cityName: "Hotan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HTO",
    airportName: "East Hampton",
    cityCode: "HTO",
    cityName: "East Hampton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HTR",
    airportName: "Hateruma",
    cityCode: "HTR",
    cityName: "Hateruma",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "HTS",
    airportName: "Tri-State/Milton",
    cityCode: "HTS",
    cityName: "Huntington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HTU",
    airportName: "Hopetoun",
    cityCode: "HTU",
    cityName: "Hopetoun",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HTW",
    airportName: "Lawrence County Airpark",
    cityCode: "HTW",
    cityName: "Chesapeake/Huntington Wv",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HTZ",
    airportName: "Hato Corozal",
    cityCode: "HTZ",
    cityName: "Hato Corozal",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "HUB",
    airportName: "Humbert River",
    cityCode: "HUB",
    cityName: "Humbert River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HUC",
    airportName: "Humacao Arpt",
    cityCode: "HUC",
    cityName: "Humacao",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "HUD",
    airportName: "Humboldt",
    cityCode: "HUD",
    cityName: "Humboldt",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HUE",
    airportName: "Humera",
    cityCode: "HUE",
    cityName: "Humera",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "HUF",
    airportName: "Hulman Field",
    cityCode: "HUF",
    cityName: "Terre Haute",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HUG",
    airportName: "Huehuetenango",
    cityCode: "HUG",
    cityName: "Huehuetenango",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "HUH",
    airportName: "Huahine",
    cityCode: "HUH",
    cityName: "Huahine",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "HUI",
    airportName: "Phu Bai",
    cityCode: "HUI",
    cityName: "Hue",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "HUJ",
    airportName: "Hugo",
    cityCode: "HUJ",
    cityName: "Hugo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HUK",
    airportName: "Hukuntsi",
    cityCode: "HUK",
    cityName: "Hukuntsi",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "HUL",
    airportName: "Intl.",
    cityCode: "HUL",
    cityName: "Houlton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HUM",
    airportName: "Terrebonne",
    cityCode: "HUM",
    cityName: "Houma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HUN",
    airportName: "Hualien",
    cityCode: "HUN",
    cityName: "Hualien",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "HUQ",
    airportName: "Houn",
    cityCode: "HUQ",
    cityName: "Houn",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "HUS",
    airportName: "Municipal",
    cityCode: "HUS",
    cityName: "Hughes",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HUT",
    airportName: "Hutchinson",
    cityCode: "HUT",
    cityName: "Hutchinson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HUU",
    airportName: "Huanuco",
    cityCode: "HUU",
    cityName: "Huanuco",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "HUV",
    airportName: "Hudiksvall",
    cityCode: "HUV",
    cityName: "Hudiksvall",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "HUX",
    airportName: "Huatulco",
    cityCode: "HUX",
    cityName: "Huatulco",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "HUY",
    airportName: "Humberside Intl.",
    cityCode: "HUY",
    cityName: "Humberside",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "HUZ",
    airportName: "Huizhou",
    cityCode: "HUZ",
    cityName: "Huizhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HVA",
    airportName: "Analalava",
    cityCode: "HVA",
    cityName: "Analalava",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "HVB",
    airportName: "Hervey Bay",
    cityCode: "HVB",
    cityName: "Hervey Bay",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HVD",
    airportName: "Khovd",
    cityCode: "HVD",
    cityName: "Khovd",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "HVE",
    airportName: "Intermediate",
    cityCode: "HVE",
    cityName: "Hanksville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HVG",
    airportName: "Valan",
    cityCode: "HVG",
    cityName: "Honningsvag",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "HVK",
    airportName: "Holmavik",
    cityCode: "HVK",
    cityName: "Holmavik",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "HVM",
    airportName: "Hvammstangi",
    cityCode: "HVM",
    cityName: "Hvammstangi",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "HVN",
    airportName: "New Haven",
    cityCode: "HVN",
    cityName: "New Haven",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HVR",
    airportName: "City County",
    cityCode: "HVR",
    cityName: "Havre",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HVS",
    airportName: "Municipal",
    cityCode: "HVS",
    cityName: "Hartsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HWA",
    airportName: "Hawabango",
    cityCode: "HWA",
    cityName: "Hawabango",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HWD",
    airportName: "Air Terminal",
    cityCode: "HWD",
    cityName: "Hayward",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HWI",
    airportName: "SPB",
    cityCode: "HWI",
    cityName: "Hawk Inlet",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HWK",
    airportName: "Wilpena Pound",
    cityCode: "HWK",
    cityName: "Hawker",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HWN",
    airportName: "Hwange Nat Park",
    cityCode: "HWN",
    cityName: "Hwange Nat Park",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "HWO",
    airportName: "North Perry",
    cityCode: "HWO",
    cityName: "Hollywood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HXX",
    airportName: "Hay",
    cityCode: "HXX",
    cityName: "Hay",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "HYA",
    airportName: "Barnstable",
    cityCode: "HYA",
    cityName: "Hyannis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HYC",
    airportName: "High Wycombe",
    cityCode: "HYC",
    cityName: "High Wycombe",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "HYD",
    airportName: "Hyderabad Arpt.",
    cityCode: "HYD",
    cityName: "Hyderabad",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "HYF",
    airportName: "Hayfields",
    cityCode: "HYF",
    cityName: "Hayfields",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "HYG",
    airportName: "SPB",
    cityCode: "HYG",
    cityName: "Hydaburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HYL",
    airportName: "SPB",
    cityCode: "HYL",
    cityName: "Hollis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HYN",
    airportName: "Huangyan",
    cityCode: "HYN",
    cityName: "Huangyan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HYR",
    airportName: "Municipal",
    cityCode: "HYR",
    cityName: "Hayward",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HYS",
    airportName: "Municipal",
    cityCode: "HYS",
    cityName: "Hays",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HZB",
    airportName: "Merville/Calonne",
    cityCode: "HZB",
    cityName: "Hazebrouck",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "HZG",
    airportName: "Hanzhong",
    cityCode: "HZG",
    cityName: "Hanzhong",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HZH",
    airportName: "Liping",
    cityCode: "HZH",
    cityName: "Liping City",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "HZK",
    airportName: "Husavik",
    cityCode: "HZK",
    cityName: "Husavik",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "HZL",
    airportName: "Hazleton",
    cityCode: "HZL",
    cityName: "Hazleton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HZV",
    airportName: "Hazyview",
    cityCode: "HZV",
    cityName: "Hazyview",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "IAA",
    airportName: "Igarka",
    cityCode: "IAA",
    cityName: "Igarka",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "IAD",
    airportName: "Washington Dulles Intl.",
    cityCode: "WAS",
    cityName: "Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IAG",
    airportName: "Niagara Falls Intl.",
    cityCode: "IAG",
    cityName: "Niagara Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IAH",
    airportName: "George Bush Intercontinental",
    cityCode: "HOU",
    cityName: "Houston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IAM",
    airportName: "In Amenas",
    cityCode: "IAM",
    cityName: "In Amenas",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "IAN",
    airportName: "Bob Barker Memorial",
    cityCode: "IAN",
    cityName: "Kiana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IAQ",
    airportName: "Bahregan",
    cityCode: "IAQ",
    cityName: "Bahregan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "IAR",
    airportName: "Yaroslavl",
    cityCode: "IAR",
    cityName: "Yaroslavl",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "IAS",
    airportName: "Iasi",
    cityCode: "IAS",
    cityName: "Iasi",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "IAU",
    airportName: "Iaura",
    cityCode: "IAU",
    cityName: "Iaura",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IBA",
    airportName: "Ibadan",
    cityCode: "IBA",
    cityName: "Ibadan",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "IBE",
    airportName: "Ibague",
    cityCode: "IBE",
    cityName: "Ibague",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "IBI",
    airportName: "Iboki",
    cityCode: "IBI",
    cityName: "Iboki",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IBL",
    airportName: "Indigo Bay Lodge",
    cityCode: "IBL",
    cityName: "Indigo Bay Lodge",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "IBO",
    airportName: "Ibo",
    cityCode: "IBO",
    cityName: "Ibo",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "IBP",
    airportName: "Iberia",
    cityCode: "IBP",
    cityName: "Iberia",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "IBZ",
    airportName: "Ibiza",
    cityCode: "IBZ",
    cityName: "Ibiza",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "ICA",
    airportName: "Icabaru",
    cityCode: "ICA",
    cityName: "Icabaru",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "ICI",
    airportName: "Cicia",
    cityCode: "ICI",
    cityName: "Cicia",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "ICK",
    airportName: "Nieuw Nickerie",
    cityCode: "ICK",
    cityName: "Nieuw Nickerie",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "ICL",
    airportName: "Municipal",
    cityCode: "ICL",
    cityName: "Clarinda",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ICN",
    airportName: "Incheon Intl.",
    cityCode: "SEL",
    cityName: "Seoul",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "ICO",
    airportName: "Sicogon Island",
    cityCode: "ICO",
    cityName: "Sicogon Island",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "ICR",
    airportName: "Nicaro",
    cityCode: "ICR",
    cityName: "Nicaro",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "ICT",
    airportName: "Mid-Continent",
    cityCode: "ICT",
    cityName: "Wichita",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ICY",
    airportName: "Icy Bay",
    cityCode: "ICY",
    cityName: "Icy Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IDA",
    airportName: "Fanning Field",
    cityCode: "IDA",
    cityName: "Idaho Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IDB",
    airportName: "Idre",
    cityCode: "IDB",
    cityName: "Idre",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "IDC",
    airportName: "Ila Da Chilonzuene",
    cityCode: "IDC",
    cityName: "Ila Da Chilonzuene",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "IDF",
    airportName: "Idiofa",
    cityCode: "IDF",
    cityName: "Idiofa",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "IDG",
    airportName: "Municipal",
    cityCode: "IDG",
    cityName: "Ida Grove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IDI",
    airportName: "ndiana County Arpt.",
    cityCode: "IDI",
    cityName: "Indiana, PA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IDK",
    airportName: "Indulkana",
    cityCode: "IDK",
    cityName: "Indulkana",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "IDN",
    airportName: "Indagen",
    cityCode: "IDN",
    cityName: "Indagen",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IDO",
    airportName: "Rio",
    cityCode: "IDO",
    cityName: "Santa Isabel Do Morro",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IDP",
    airportName: "Independence",
    cityCode: "IDP",
    cityName: "Independence",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IDR",
    airportName: "Devi Ahilyabai Holkar",
    cityCode: "IDR",
    cityName: "Indore",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IEG",
    airportName: "Babimost",
    cityCode: "IEG",
    cityName: "Zielona Gora",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "IEJ",
    airportName: "Iejima",
    cityCode: "IEJ",
    cityName: "Iejima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "IEV",
    airportName: "Zhulhany",
    cityCode: "IEV",
    cityName: "Kiev",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "IFA",
    airportName: "Iowa Falls",
    cityCode: "IFA",
    cityName: "Iowa Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IFF",
    airportName: "Iffley",
    cityCode: "IFF",
    cityName: "Iffley",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "IFJ",
    airportName: "Isafjordur",
    cityCode: "IFJ",
    cityName: "Isafjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "IFL",
    airportName: "Innisfail",
    cityCode: "IFL",
    cityName: "Innisfail",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "IFN",
    airportName: "Isfahan Intl.",
    cityCode: "IFN",
    cityName: "Isfahan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "IFO",
    airportName: "Ivano-Frankovsk",
    cityCode: "IFO",
    cityName: "Ivano-Frankovsk",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "IFP",
    airportName: "Laughlin Bullhead Intl",
    cityCode: "IFP",
    cityName: "Bullhead City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IGA",
    airportName: "Inagua",
    cityCode: "IGA",
    cityName: "Inagua",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "TMZ",
    airportName: "Thames",
    cityCode: "TMZ",
    cityName: "Thames",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "TNA",
    airportName: "Jinan",
    cityCode: "TNA",
    cityName: "Jinan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TNB",
    airportName: "Tanah Grogot",
    cityCode: "TNB",
    cityName: "Tanah Grogot",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TNC",
    airportName: "Tin City Afs",
    cityCode: "TNC",
    cityName: "Tin City, AK",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TND",
    airportName: "Trinidad",
    cityCode: "TND",
    cityName: "Trinidad",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "TNE",
    airportName: "Tanegashima",
    cityCode: "TNE",
    cityName: "Tanegashima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TNF",
    airportName: "Toussus-le-noble",
    cityCode: "TNF",
    cityName: "Toussus-Le-Noble",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "TNG",
    airportName: "Boukhalef",
    cityCode: "TNG",
    cityName: "Tangier",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "TNH",
    airportName: "Tonghua Liuhe",
    cityCode: "TNH",
    cityName: "Tonghua",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TNI",
    airportName: "Satna",
    cityCode: "TNI",
    cityName: "Satna",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "TNJ",
    airportName: "Kidjang",
    cityCode: "TNJ",
    cityName: "Tanjung Pinang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TNK",
    airportName: "Tununak",
    cityCode: "TNK",
    cityName: "Tununak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TNL",
    airportName: "Ternopol",
    cityCode: "TNL",
    cityName: "Ternopol",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "TNM",
    airportName: "Teniente R. Marsh Martin",
    cityCode: "TNM",
    cityName: "Teniente R. Marsh Martin",
    countryName: "Antarctica",
    countryCode: "AQ",
  },
  {
    airportCode: "TNN",
    airportName: "Tainan",
    cityCode: "TNN",
    cityName: "Tainan",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "TNO",
    airportName: "Tamarindo",
    cityCode: "TNO",
    cityName: "Tamarindo",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "TNP",
    airportName: "Twentynine Palms",
    cityCode: "TNP",
    cityName: "Twentynine Palms",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TNQ",
    airportName: "Teraina",
    cityCode: "TNQ",
    cityName: "Teraina",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "TNR",
    airportName: "Antananarivo",
    cityCode: "TNR",
    cityName: "Antananarivo",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "TNT",
    airportName: "Dade Collier",
    cityCode: "TNT",
    cityName: "Miami",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TNU",
    airportName: "Municipal",
    cityCode: "TNU",
    cityName: "Newton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TNV",
    airportName: "Tabuaeran",
    cityCode: "TNV",
    cityName: "Tabuaeran",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "TNX",
    airportName: "Stung Treng",
    cityCode: "TNX",
    cityName: "Stung Treng",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "TOA",
    airportName: "Torrance Arpt.",
    cityCode: "TOA",
    cityName: "Torrance",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TOB",
    airportName: "Tobruk",
    cityCode: "TOB",
    cityName: "Tobruk",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "TOC",
    airportName: "Toccoa",
    cityCode: "TOC",
    cityName: "Toccoa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TOD",
    airportName: "Tioman",
    cityCode: "TOD",
    cityName: "Tioman",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "TOE",
    airportName: "Tozeur",
    cityCode: "TOE",
    cityName: "Tozeur",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "TOF",
    airportName: "Tomsk",
    cityCode: "TOF",
    cityName: "Tomsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "TOG",
    airportName: "Togiak Village",
    cityCode: "TOG",
    cityName: "Togiak Village",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TOH",
    airportName: "Torres Airstrip",
    cityCode: "TOH",
    cityName: "Torres",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "TOI",
    airportName: "Municipal",
    cityCode: "TOI",
    cityName: "Troy",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TOJ",
    airportName: "Torrejon Afb",
    cityCode: "TOJ",
    cityName: "Madrid",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "TOK",
    airportName: "Torokina",
    cityCode: "TOK",
    cityName: "Torokina",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TOL",
    airportName: "Toledo Express",
    cityCode: "TOL",
    cityName: "Toledo, OH",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TOM",
    airportName: "Tombouctou",
    cityCode: "TOM",
    cityName: "Tombouctou",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "TON",
    airportName: "Tonu",
    cityCode: "TON",
    cityName: "Tonu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TOO",
    airportName: "San Vito",
    cityCode: "TOO",
    cityName: "San Vito",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "TOP",
    airportName: "Philip Billard",
    cityCode: "TOP",
    cityName: "Topeka",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TOQ",
    airportName: "Barriles",
    cityCode: "TOQ",
    cityName: "Tocopilla",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "TOR",
    airportName: "Municipal",
    cityCode: "TOR",
    cityName: "Torrington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TOS",
    airportName: "Tromso/langnes",
    cityCode: "TOS",
    cityName: "Tromso",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "TOU",
    airportName: "Touho",
    cityCode: "TOU",
    cityName: "Touho",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "TOV",
    airportName: "West End SPB",
    cityCode: "TOV",
    cityName: "Tortola",
    countryName: "Virgin Islands",
    countryCode: "VG",
  },
  {
    airportCode: "TOW",
    airportName: "Toledo",
    cityCode: "TOW",
    cityName: "Toledo",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TOX",
    airportName: "Tobolsk",
    cityCode: "TOX",
    cityName: "Tobolsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "TOY",
    airportName: "Toyama",
    cityCode: "TOY",
    cityName: "Toyama",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TOZ",
    airportName: "Touba",
    cityCode: "TOZ",
    cityName: "Touba",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "TPA",
    airportName: "Tampa Intl.",
    cityCode: "TPA",
    cityName: "Tampa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TPE",
    airportName: "Taiwan Taoyuan Intl.",
    cityCode: "TPE",
    cityName: "Taipei",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "TPG",
    airportName: "Taiping",
    cityCode: "TPG",
    cityName: "Taiping",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "TPH",
    airportName: "Tonopah Arpt",
    cityCode: "TPH",
    cityName: "Tonopah",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TPI",
    airportName: "Tapini",
    cityCode: "TPI",
    cityName: "Tapini",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TPJ",
    airportName: "Taplejung Suketar",
    cityCode: "TPJ",
    cityName: "Taplejung",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "TPK",
    airportName: "Tapaktuan",
    cityCode: "TPK",
    cityName: "Tapaktuan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TPL",
    airportName: "Draughon-miller Central Texas Regional",
    cityCode: "TPL",
    cityName: "Temple, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TPN",
    airportName: "Tiputini",
    cityCode: "TPN",
    cityName: "Tiputini",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "TPO",
    airportName: "Tanalian Point",
    cityCode: "TPO",
    cityName: "Tanalian Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TPP",
    airportName: "Tarapoto",
    cityCode: "TPP",
    cityName: "Tarapoto",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "TPQ",
    airportName: "Tepic",
    cityCode: "TPQ",
    cityName: "Tepic",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TPR",
    airportName: "Tom Price",
    cityCode: "TPR",
    cityName: "Tom Price",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TPS",
    airportName: "Birgi",
    cityCode: "TPS",
    cityName: "Trapani",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "TPT",
    airportName: "Tapeta",
    cityCode: "TPT",
    cityName: "Tapeta",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "TPU",
    airportName: "Tikapur",
    cityCode: "TPU",
    cityName: "Tikapur",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "TPX",
    airportName: "Tupai",
    cityCode: "TPX",
    cityName: "Tupai",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "TQN",
    airportName: "Taluqan",
    cityCode: "TQN",
    cityName: "Taluqan",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "TQR",
    airportName: "San Domino Island",
    cityCode: "TQR",
    cityName: "San Domino Island",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "TRA",
    airportName: "Tarama",
    cityCode: "TRA",
    cityName: "Taramajima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TRB",
    airportName: "Gonzalo",
    cityCode: "TRB",
    cityName: "Turbo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TRC",
    airportName: "Francisco Sarabia",
    cityCode: "TRC",
    cityName: "Torreon",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TRD",
    airportName: "VÃ¦rnes",
    cityCode: "TRD",
    cityName: "Trondheim",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "TRE",
    airportName: "Tiree",
    cityCode: "TRE",
    cityName: "Tiree",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "TRF",
    airportName: "Sandefjord",
    cityCode: "OSL",
    cityName: "Oslo",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "TRG",
    airportName: "Tauranga City",
    cityCode: "TRG",
    cityName: "Tauranga",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "TRH",
    airportName: "Trona",
    cityCode: "TRH",
    cityName: "Trona",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TRI",
    airportName: "Tri-cities Regional",
    cityCode: "TRI",
    cityName: "Blountville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TRJ",
    airportName: "Tarakbits",
    cityCode: "TRJ",
    cityName: "Tarakbits",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TRK",
    airportName: "Juwata",
    cityCode: "TRK",
    cityName: "Tarakan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TRL",
    airportName: "Terrell Field",
    cityCode: "TRL",
    cityName: "Terrell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TRM",
    airportName: "Thermal",
    cityCode: "TRM",
    cityName: "Thermal",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TRN",
    airportName: "Sandro Pertini",
    cityCode: "TRN",
    cityName: "Turin",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "TRO",
    airportName: "Taree",
    cityCode: "TRO",
    cityName: "Taree",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TRP",
    airportName: "Coast Guard Heliport",
    cityCode: "TRP",
    cityName: "Tree Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TRQ",
    airportName: "Tarauaca",
    cityCode: "TRQ",
    cityName: "Tarauaca",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TRR",
    airportName: "China Bay",
    cityCode: "TRR",
    cityName: "Trincomalee",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "TRS",
    airportName: "Ronchi Dei Legionari",
    cityCode: "TRS",
    cityName: "Trieste",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "TRT",
    airportName: "Tremonton",
    cityCode: "TRT",
    cityName: "Tremonton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TRU",
    airportName: "Trujillo",
    cityCode: "TRU",
    cityName: "Trujillo",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "TRV",
    airportName: "Trivandrum International Airport.",
    cityCode: "TRV",
    cityName: "Trivandrum",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "TRW",
    airportName: "Bonriki",
    cityCode: "TRW",
    cityName: "Tarawa",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "TRX",
    airportName: "Memorial",
    cityCode: "TRX",
    cityName: "Trenton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TRY",
    airportName: "Tororo",
    cityCode: "TRY",
    cityName: "Tororo",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "TRZ",
    airportName: "Civil",
    cityCode: "TRZ",
    cityName: "Tiruchirapally",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "TSA",
    airportName: "Taipei Songshan",
    cityCode: "TPE",
    cityName: "Taipei",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "TSD",
    airportName: "Tshipise",
    cityCode: "TSD",
    cityName: "Tshipise",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "TSE",
    airportName: "Astana",
    cityCode: "TSE",
    cityName: "Astana",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "TSF",
    airportName: "S. Angelo",
    cityCode: "TSF",
    cityName: "Treviso",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "TSG",
    airportName: "Intermediate",
    cityCode: "TSG",
    cityName: "Tanacross",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TSH",
    airportName: "Tshikapa",
    cityCode: "TSH",
    cityName: "Tshikapa",
    countryName: "Democratic Republic of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "TSJ",
    airportName: "Tsushima",
    cityCode: "TSJ",
    cityName: "Tsushima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TSL",
    airportName: "Tamuin",
    cityCode: "TSL",
    cityName: "Tamuin",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TSN",
    airportName: "Tianjin Binhai Intl.",
    cityCode: "TSN",
    cityName: "Tianjin",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TSO",
    airportName: "Tresco",
    cityCode: "TSO",
    cityName: "Isles Of Scilly",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "TSP",
    airportName: "Kern County",
    cityCode: "TSP",
    cityName: "Tehachapi, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TSQ",
    airportName: "Torres",
    cityCode: "TSQ",
    cityName: "Torres",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TSR",
    airportName: "Timisoara",
    cityCode: "TSR",
    cityName: "Timisoara",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "TSS",
    airportName: "East 34th St Heliport",
    cityCode: "NYC",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TST",
    airportName: "Trang",
    cityCode: "TST",
    cityName: "Trang",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "TSU",
    airportName: "Tabiteuea South",
    cityCode: "TSU",
    cityName: "Tabiteuea South",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "TSV",
    airportName: "Townsville Intl.",
    cityCode: "TSV",
    cityName: "Townsville",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TTA",
    airportName: "Tan Tan",
    cityCode: "TTA",
    cityName: "Tan Tan",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "TTB",
    airportName: "Arbatax",
    cityCode: "TTB",
    cityName: "Tortoli",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "TTC",
    airportName: "Taltal",
    cityCode: "TTC",
    cityName: "Taltal",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "TTD",
    airportName: "Troutdale",
    cityCode: "TTD",
    cityName: "Troutdale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TTE",
    airportName: "Babullah",
    cityCode: "TTE",
    cityName: "Ternate",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TTG",
    airportName: "Tartagal",
    cityCode: "TTG",
    cityName: "Tartagal",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "TTH",
    airportName: "Thumrait",
    cityCode: "TTH",
    cityName: "Thumrait",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "TTI",
    airportName: "Tetiaroa Is",
    cityCode: "TTI",
    cityName: "Tetiaroa Is",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "TTJ",
    airportName: "Tottori",
    cityCode: "TTJ",
    cityName: "Tottori",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TTL",
    airportName: "Turtle Island",
    cityCode: "TTL",
    cityName: "Turtle Island",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "TTM",
    airportName: "Tablon De Tamara",
    cityCode: "TTM",
    cityName: "Tablon De Tamara",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TTN",
    airportName: "Trenton Mercer Arpt.",
    cityCode: "TTN",
    cityName: "Trenton, NJ",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TTO",
    airportName: "Municipal",
    cityCode: "TTO",
    cityName: "Britton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TTQ",
    airportName: "Tortuquero",
    cityCode: "TTQ",
    cityName: "Tortuquero",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "TTR",
    airportName: "Tana Toraja",
    cityCode: "TTR",
    cityName: "Tana Toraja",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TTS",
    airportName: "Tsaratanana",
    cityCode: "TTS",
    cityName: "Tsaratanana",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "TTT",
    airportName: "Taitung",
    cityCode: "TTT",
    cityName: "Taitung",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "TTU",
    airportName: "Sania Ramel",
    cityCode: "TTU",
    cityName: "Tetuan",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "TUA",
    airportName: "Tulcan",
    cityCode: "TUA",
    cityName: "Tulcan",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "TUB",
    airportName: "Tubuai",
    cityCode: "TUB",
    cityName: "Tubuai",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "TUC",
    airportName: "Benj Matienzo",
    cityCode: "TUC",
    cityName: "Tucuman",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "TUD",
    airportName: "Tambacounda",
    cityCode: "TUD",
    cityName: "Tambacounda",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "TUE",
    airportName: "Tupile",
    cityCode: "TUE",
    cityName: "Tupile",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "TUF",
    airportName: "St Symphorien",
    cityCode: "TUF",
    cityName: "Tours",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "TUG",
    airportName: "Tuguegarao",
    cityCode: "TUG",
    cityName: "Tuguegarao",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "TUH",
    airportName: "Arnold AFS",
    cityCode: "TUH",
    cityName: "Tullahoma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TUI",
    airportName: "Turaif",
    cityCode: "TUI",
    cityName: "Turaif",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "TUJ",
    airportName: "Tum",
    cityCode: "TUJ",
    cityName: "Tum",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "TUK",
    airportName: "Turbat",
    cityCode: "TUK",
    cityName: "Turbat",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "TUL",
    airportName: "Tulsa Intl.",
    cityCode: "TUL",
    cityName: "Tulsa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TUM",
    airportName: "Tumut",
    cityCode: "TUM",
    cityName: "Tumut",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TUN",
    airportName: "Carthage",
    cityCode: "TUN",
    cityName: "Tunis",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "TUO",
    airportName: "Taupo",
    cityCode: "TUO",
    cityName: "Taupo",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "TUP",
    airportName: "Lemons Municipal",
    cityCode: "TUP",
    cityName: "Tupelo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TUQ",
    airportName: "Tougan",
    cityCode: "TUQ",
    cityName: "Tougan",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "TUR",
    airportName: "Tucurui",
    cityCode: "TUR",
    cityName: "Tucurui",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TUS",
    airportName: "Tucson Intl. Arpt.",
    cityCode: "TUS",
    cityName: "Tucson, AZ",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TUT",
    airportName: "Tauta",
    cityCode: "TUT",
    cityName: "Tauta",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TUU",
    airportName: "Tabuk",
    cityCode: "TUU",
    cityName: "Tabuk",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "TUV",
    airportName: "Tucupita",
    cityCode: "TUV",
    cityName: "Tucupita",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "TUW",
    airportName: "Tubala",
    cityCode: "TUW",
    cityName: "Tubala",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "TUX",
    airportName: "Tumbler Ridge",
    cityCode: "TUX",
    cityName: "Tumbler Ridge",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "TUY",
    airportName: "Tulum",
    cityCode: "TUY",
    cityName: "Tulum",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TUZ",
    airportName: "Tucuma",
    cityCode: "TUZ",
    cityName: "Tucuma",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TVA",
    airportName: "Morafenobe",
    cityCode: "TVA",
    cityName: "Morafenobe",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "TVC",
    airportName: "Cherry Capital Arpt.",
    cityCode: "TVC",
    cityName: "Traverse City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TVF",
    airportName: "Regional",
    cityCode: "TVF",
    cityName: "Thief River Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TVI",
    airportName: "Municipal",
    cityCode: "TVI",
    cityName: "Thomasville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TVL",
    airportName: "South Lake Tahoe",
    cityCode: "TVL",
    cityName: "South Lake Tahoe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TVU",
    airportName: "Matei",
    cityCode: "TVU",
    cityName: "Taveuni",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "TVY",
    airportName: "Dawe",
    cityCode: "TVY",
    cityName: "Dawe",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "TWA",
    airportName: "Twin Hills",
    cityCode: "TWA",
    cityName: "Twin Hills",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TWB",
    airportName: "Toowoomba",
    cityCode: "TWB",
    cityName: "Toowoomba",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TWD",
    airportName: "Port Townsend",
    cityCode: "TWD",
    cityName: "Port Townsend",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TWE",
    airportName: "Taylor",
    cityCode: "TWE",
    cityName: "Taylor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TWF",
    airportName: "Joslin Field - Magic Valley Regional",
    cityCode: "TWF",
    cityName: "Twin Falls, ID",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TWH",
    airportName: "Two Harbors",
    cityCode: "TWH",
    cityName: "Catalina Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TWN",
    airportName: "Tewantin",
    cityCode: "TWN",
    cityName: "Tewantin",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TWP",
    airportName: "Torwood",
    cityCode: "TWP",
    cityName: "Torwood",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TWT",
    airportName: "Tawitawi",
    cityCode: "TWT",
    cityName: "Tawitawi",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "TWU",
    airportName: "Tawau",
    cityCode: "TWU",
    cityName: "Tawau",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "TWY",
    airportName: "Tawa",
    cityCode: "TWY",
    cityName: "Tawa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TWZ",
    airportName: "Pukaki/Twizel",
    cityCode: "TWZ",
    cityName: "Mount Cook",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "TXF",
    airportName: "Teixeira de Freitas",
    cityCode: "TXF",
    cityName: "Teixeira de Freitas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TXG",
    airportName: "Taichung",
    cityCode: "TXG",
    cityName: "Taichung",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "TXL",
    airportName: "Berlin Tegel Airport",
    cityCode: "BER",
    cityName: "Berlin",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "TXM",
    airportName: "Teminabuan",
    cityCode: "TXM",
    cityName: "Teminabuan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TXN",
    airportName: "Tunxi",
    cityCode: "TXN",
    cityName: "Tunxi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TXR",
    airportName: "Tanbar",
    cityCode: "TXR",
    cityName: "Tanbar",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TXU",
    airportName: "Tabou",
    cityCode: "TXU",
    cityName: "Tabou",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "TYA",
    airportName: "Tula",
    cityCode: "TYA",
    cityName: "Tula",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "TYB",
    airportName: "Tibooburra",
    cityCode: "TYB",
    cityName: "Tibooburra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TYD",
    airportName: "Tynda",
    cityCode: "TYD",
    cityName: "Tynda",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "TYF",
    airportName: "Torsby Arpt.",
    cityCode: "TYF",
    cityName: "Torsby",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "TYG",
    airportName: "Thylungra",
    cityCode: "TYG",
    cityName: "Thylungra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TYL",
    airportName: "Talara",
    cityCode: "TYL",
    cityName: "Talara",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "TYM",
    airportName: "Staniel Cay",
    cityCode: "TYM",
    cityName: "Staniel Cay",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "TYN",
    airportName: "Taiyuan",
    cityCode: "TYN",
    cityName: "Taiyuan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TYP",
    airportName: "Tobermorey",
    cityCode: "TYP",
    cityName: "Tobermorey",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TYR",
    airportName: "Tyler Pounds Regional Arpt.",
    cityCode: "TYR",
    cityName: "Tyler",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TYS",
    airportName: "Mc Ghee Tyson",
    cityCode: "TYS",
    cityName: "Knoxville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TYT",
    airportName: "Treinta-y-Tres",
    cityCode: "TYT",
    cityName: "Treinta-y-Tres",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "TYZ",
    airportName: "Taylor",
    cityCode: "TYZ",
    cityName: "Taylor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TZA",
    airportName: "Municipal",
    cityCode: "TZA",
    cityName: "Belize City",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "TZL",
    airportName: "Tuzla Intl.",
    cityCode: "TZL",
    cityName: "Tuzla",
    countryName: "Bosnia And Herzegovina",
    countryCode: "BA",
  },
  {
    airportCode: "TZM",
    airportName: "Tizimin",
    cityCode: "TZM",
    cityName: "Tizimin",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TZN",
    airportName: "South Andros",
    cityCode: "TZN",
    cityName: "South Andros",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "TZX",
    airportName: "Trabzon",
    cityCode: "TZX",
    cityName: "Trabzon",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "UAB",
    airportName: "Adana-Incirlik Airbase",
    cityCode: "UAB",
    cityName: "Adana",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "UAC",
    airportName: "San Luis Rio Colorado",
    cityCode: "UAC",
    cityName: "San Luis Rio Colorado",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "UAE",
    airportName: "Mount Aue",
    cityCode: "UAE",
    cityName: "Mount Aue",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "UAH",
    airportName: "Ua Huka",
    cityCode: "UAH",
    cityName: "Ua Huka",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "UAI",
    airportName: "Suai",
    cityCode: "UAI",
    cityName: "Suai",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "UAK",
    airportName: "Narsarsuaq",
    cityCode: "UAK",
    cityName: "Narsarsuaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "UAL",
    airportName: "Luau",
    cityCode: "UAL",
    cityName: "Luau",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "UAM",
    airportName: "Andersen AFB",
    cityCode: "UAM",
    cityName: "Andersen, Mariana Island",
    countryName: "Guam",
    countryCode: "GU",
  },
  {
    airportCode: "UAP",
    airportName: "Ua Pou",
    cityCode: "UAP",
    cityName: "Ua Pou",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "UAQ",
    airportName: "San Juan",
    cityCode: "UAQ",
    cityName: "San Juan",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "UAS",
    airportName: "Samburu",
    cityCode: "UAS",
    cityName: "Samburu",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "UAX",
    airportName: "Uaxactun",
    cityCode: "UAX",
    cityName: "Uaxactun",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "UBA",
    airportName: "Uberaba",
    cityCode: "UBA",
    cityName: "Uberaba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "UBB",
    airportName: "Mabuiag Island",
    cityCode: "UBB",
    cityName: "Mabuiag Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "UBI",
    airportName: "Buin",
    cityCode: "UBI",
    cityName: "Buin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "UBJ",
    airportName: "Ube",
    cityCode: "UBJ",
    cityName: "Ube",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "UBP",
    airportName: "Muang Ubon",
    cityCode: "UBP",
    cityName: "Ubon Ratchathni",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "UBR",
    airportName: "Ubrub",
    cityCode: "UBR",
    cityName: "Ubrub",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "UBS",
    airportName: "Lowndes County",
    cityCode: "UBS",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UBT",
    airportName: "Ubatuba",
    cityCode: "UBT",
    cityName: "Ubatuba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "UBU",
    airportName: "Kalumburu",
    cityCode: "UBU",
    cityName: "Kalumburu",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "UCA",
    airportName: "Oneida County",
    cityCode: "UCA",
    cityName: "Utica",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UCE",
    airportName: "Eunice",
    cityCode: "UCE",
    cityName: "Eunice",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UCK",
    airportName: "Lutsk",
    cityCode: "UCK",
    cityName: "Lutsk",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "UCN",
    airportName: "Buchanan",
    cityCode: "UCN",
    cityName: "Buchanan",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "UCT",
    airportName: "Ukhta",
    cityCode: "UCT",
    cityName: "Ukhta",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "UCY",
    airportName: "Everett-Stewart",
    cityCode: "UCY",
    cityName: "Union City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UDD",
    airportName: "Bermuda Dunes",
    cityCode: "UDD",
    cityName: "Palm Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UDE",
    airportName: "Volkel",
    cityCode: "UDE",
    cityName: "Uden",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "UDI",
    airportName: "Eduardo Gomes",
    cityCode: "UDI",
    cityName: "Uberlandia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "UDJ",
    airportName: "Uzhgorod",
    cityCode: "UDJ",
    cityName: "Uzhgorod",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "UDN",
    airportName: "Airfield",
    cityCode: "UDN",
    cityName: "Udine",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "UDO",
    airportName: "Udomxay",
    cityCode: "UDO",
    cityName: "Udomxay",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "UDR",
    airportName: "Dabok",
    cityCode: "UDR",
    cityName: "Udaipur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "UEE",
    airportName: "Queenstown",
    cityCode: "UEE",
    cityName: "Queenstown",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "UEL",
    airportName: "Quelimane",
    cityCode: "UEL",
    cityName: "Quelimane",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "UEO",
    airportName: "Kumejima",
    cityCode: "UEO",
    cityName: "Kumejima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "UER",
    airportName: "Puertollano",
    cityCode: "UER",
    cityName: "Puertollano",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "UES",
    airportName: "Waukesha",
    cityCode: "UES",
    cityName: "Waukesha",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UET",
    airportName: "Quetta",
    cityCode: "UET",
    cityName: "Quetta",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "UFA",
    airportName: "Ufa",
    cityCode: "UFA",
    cityName: "Ufa",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "UGA",
    airportName: "Bulgan",
    cityCode: "UGA",
    cityName: "Bulgan",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "UGB",
    airportName: "Ugashik Bay",
    cityCode: "UGB",
    cityName: "Pilot Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UGC",
    airportName: "Urgench",
    cityCode: "UGC",
    cityName: "Urgench",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "UGI",
    airportName: "Uganik",
    cityCode: "UGI",
    cityName: "Uganik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UGN",
    airportName: "Memorial",
    cityCode: "UGN",
    cityName: "Waukegan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UGO",
    airportName: "Uige",
    cityCode: "UGO",
    cityName: "Uige",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "UGU",
    airportName: "Zugapa",
    cityCode: "UGU",
    cityName: "Zugapa",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "UHE",
    airportName: "Kunovice",
    cityCode: "UHE",
    cityName: "Uherske Hradiste",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "UHF",
    airportName: "RAF Station",
    cityCode: "UHF",
    cityName: "Upper Heyford",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "UIB",
    airportName: "Quibdo",
    cityCode: "UIB",
    cityName: "Quibdo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "UIH",
    airportName: "Qui Nhon",
    cityCode: "UIH",
    cityName: "Qui Nhon",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "UII",
    airportName: "Utila",
    cityCode: "UII",
    cityName: "Utila",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "UIK",
    airportName: "Ust-Ilimsk",
    cityCode: "UIK",
    cityName: "Ust-Ilimsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "UIL",
    airportName: "Quillayute State",
    cityCode: "UIL",
    cityName: "Quillayute",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UIN",
    airportName: "Municipal",
    cityCode: "UIN",
    cityName: "Quincy",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UIO",
    airportName: "Mariscal Sucre",
    cityCode: "UIO",
    cityName: "Quito",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "UIP",
    airportName: "Pluguffan",
    cityCode: "UIP",
    cityName: "Quimper",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "UIQ",
    airportName: "Quine Hill",
    cityCode: "UIQ",
    cityName: "Quine Hill",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "UIR",
    airportName: "Quirindi",
    cityCode: "UIR",
    cityName: "Quirindi",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "UIT",
    airportName: "Jaluit Island",
    cityCode: "UIT",
    cityName: "Jaluit Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "UIZ",
    airportName: "Berz-Macomb",
    cityCode: "UIZ",
    cityName: "Utica",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UJE",
    airportName: "Ujae Island",
    cityCode: "UJE",
    cityName: "Ujae Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "UKA",
    airportName: "Ukunda",
    cityCode: "UKA",
    cityName: "Ukunda",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "UKB",
    airportName: "Kobe",
    cityCode: "OSA",
    cityName: "Osaka",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "UKI",
    airportName: "Ukiah",
    cityCode: "UKI",
    cityName: "Ukiah",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UKK",
    airportName: "Ust-kamenogorsk",
    cityCode: "UKK",
    cityName: "Ust-kamenogorsk",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "UKN",
    airportName: "Municipal",
    cityCode: "UKN",
    cityName: "Waukon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UKR",
    airportName: "Mukeiras",
    cityCode: "UKR",
    cityName: "Mukeiras",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "UKS",
    airportName: "Belbek",
    cityCode: "UKS",
    cityName: "Sevastopol",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "UKT",
    airportName: "Upper Bucks",
    cityCode: "UKT",
    cityName: "Quakertown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UKU",
    airportName: "Nuku",
    cityCode: "UKU",
    cityName: "Nuku",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "UKX",
    airportName: "Ust-Kut",
    cityCode: "UKX",
    cityName: "Ust-Kut",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "UKY",
    airportName: "Kansai",
    cityCode: "UKY",
    cityName: "Kyoto",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "ULA",
    airportName: "San Julian",
    cityCode: "ULA",
    cityName: "San Julian",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "ULB",
    airportName: "Ulei",
    cityCode: "ULB",
    cityName: "Ulei",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "ULC",
    airportName: "Los Cerrillos",
    cityCode: "ULC",
    cityName: "Santiago",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ULD",
    airportName: "Ulundi",
    cityCode: "ULD",
    cityName: "Ulundi",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "ULE",
    airportName: "Sule",
    cityCode: "ULE",
    cityName: "Sule",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ULG",
    airportName: "Ulgit",
    cityCode: "ULG",
    cityName: "Ulgit",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "ULI",
    airportName: "Ulithi",
    cityCode: "ULI",
    cityName: "Ulithi",
    countryName: "Micronesia",
    countryCode: "FM",
  },
  {
    airportCode: "ULL",
    airportName: "Mull",
    cityCode: "ULL",
    cityName: "Mull",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ULM",
    airportName: "New Ulm",
    cityCode: "ULM",
    cityName: "New Ulm",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ULN",
    airportName: "Chinggis Khaan Intl.",
    cityCode: "ULN",
    cityName: "Ulaanbaatar",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "ULO",
    airportName: "Ulaangom",
    cityCode: "ULO",
    cityName: "Ulaangom",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "ULP",
    airportName: "Quilpie",
    cityCode: "ULP",
    cityName: "Quilpie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ULQ",
    airportName: "Farfan",
    cityCode: "ULQ",
    cityName: "Tulua",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ULS",
    airportName: "Mulatos",
    cityCode: "ULS",
    cityName: "Mulatos",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ULU",
    airportName: "Gulu",
    cityCode: "ULU",
    cityName: "Gulu",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "ULX",
    airportName: "Ulusaba",
    cityCode: "ULX",
    cityName: "Ulusaba",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "ULY",
    airportName: "Vostochny",
    cityCode: "ULY",
    cityName: "Ulyanovsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ULZ",
    airportName: "Uliastai",
    cityCode: "ULZ",
    cityName: "Uliastai",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "UMA",
    airportName: "Punta De Maisi",
    cityCode: "UMA",
    cityName: "Punta De Maisi",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "UMB",
    airportName: "North Shore",
    cityCode: "UMB",
    cityName: "Umnak Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UMC",
    airportName: "Umba",
    cityCode: "UMC",
    cityName: "Umba",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "UMD",
    airportName: "Uummannaq",
    cityCode: "UMD",
    cityName: "Uummannaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "UME",
    airportName: "Umea",
    cityCode: "UME",
    cityName: "Umea",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "UMI",
    airportName: "Quincemil",
    cityCode: "UMI",
    cityName: "Quincemil",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "UMM",
    airportName: "Summit",
    cityCode: "UMM",
    cityName: "Summit",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UMR",
    airportName: "Woomera",
    cityCode: "UMR",
    cityName: "Woomera",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "UMT",
    airportName: "Umiat",
    cityCode: "UMT",
    cityName: "Umiat",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UMU",
    airportName: "Ernesto Geisel",
    cityCode: "UMU",
    cityName: "Umuarama",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "UMY",
    airportName: "Sumy",
    cityCode: "UMY",
    cityName: "Sumy",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "UNA",
    airportName: "Una",
    cityCode: "UNA",
    cityName: "Una",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "UNC",
    airportName: "Unguia",
    cityCode: "UNC",
    cityName: "Unguia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "UND",
    airportName: "Kunduz",
    cityCode: "UND",
    cityName: "Kunduz",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "UNE",
    airportName: "Qachas Nek",
    cityCode: "UNE",
    cityName: "Qachas Nek",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "UNG",
    airportName: "Kiunga",
    cityCode: "UNG",
    cityName: "Kiunga",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "UNI",
    airportName: "Union Island",
    cityCode: "UNI",
    cityName: "Union Island",
    countryName: "Saint Vincent and the Grenadines",
    countryCode: "VC",
  },
  {
    airportCode: "UNK",
    airportName: "Unalakleet",
    cityCode: "UNK",
    cityName: "Unalakleet",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UNN",
    airportName: "Ranong",
    cityCode: "UNN",
    cityName: "Ranong",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "UNR",
    airportName: "Underkhaan",
    cityCode: "UNR",
    cityName: "Underkhaan",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "UNS",
    airportName: "Umnak",
    cityCode: "UNS",
    cityName: "Umnak Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UNT",
    airportName: "Baltasound",
    cityCode: "UNT",
    cityName: "Unst Shetland Is",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "UNU",
    airportName: "Dodge County",
    cityCode: "UNU",
    cityName: "Juneau, WI",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UOL",
    airportName: "Buol",
    cityCode: "UOL",
    cityName: "Buol",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "UON",
    airportName: "Muong Sai",
    cityCode: "UON",
    cityName: "Muong Sai",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "UOS",
    airportName: "Franklin County",
    cityCode: "UOS",
    cityName: "Sewanee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UOX",
    airportName: "University-Oxford",
    cityCode: "UOX",
    cityName: "Oxford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UPA",
    airportName: "Punta Alegre",
    cityCode: "UPA",
    cityName: "Punta Alegre",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "UPB",
    airportName: "Playa Baracoa",
    cityCode: "UPB",
    cityName: "Havana",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "UPC",
    airportName: "Puerto La Cruz",
    cityCode: "UPC",
    cityName: "Puerto La Cruz",
    countryName: "Venezuela",
    countryCode: "VZ",
  },
  {
    airportCode: "UPF",
    airportName: "Pforheim",
    cityCode: "UPF",
    cityName: "Pforheim",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "UPG",
    airportName: "Hasanudin",
    cityCode: "UPG",
    cityName: "Ujung Pandang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "UPL",
    airportName: "Upala",
    cityCode: "UPL",
    cityName: "Upala",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "UPN",
    airportName: "Uruapan",
    cityCode: "UPN",
    cityName: "Uruapan",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "UPP",
    airportName: "Upolu Point",
    cityCode: "UPP",
    cityName: "Upolu Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UPR",
    airportName: "Upiara",
    cityCode: "UPR",
    cityName: "Upiara",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "UPV",
    airportName: "Upavon",
    cityCode: "UPV",
    cityName: "Upavon",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "UQE",
    airportName: "Queen",
    cityCode: "UQE",
    cityName: "Queen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "URA",
    airportName: "Uralsk / Podstepnyy",
    cityCode: "URA",
    cityName: "Uralsk",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "URB",
    airportName: "Ernesto Pochler",
    cityCode: "URB",
    cityName: "Urubupunga",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "URC",
    airportName: "Urumqi",
    cityCode: "URC",
    cityName: "Urumqi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "URD",
    airportName: "Burg Feuerstein",
    cityCode: "URD",
    cityName: "Burg Feuerstein",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "URE",
    airportName: "Kuressaare",
    cityCode: "URE",
    cityName: "Kuressaare",
    countryName: "Estonia",
    countryCode: "EE",
  },
  {
    airportCode: "URG",
    airportName: "Ruben Berta",
    cityCode: "URG",
    cityName: "Uruguaiana",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "URI",
    airportName: "Uribe",
    cityCode: "URI",
    cityName: "Uribe",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "URJ",
    airportName: "Uraj",
    cityCode: "URJ",
    cityName: "Uraj",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "URM",
    airportName: "Uriman",
    cityCode: "URM",
    cityName: "Uriman",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "URO",
    airportName: "Boos",
    cityCode: "URO",
    cityName: "Rouen",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "URR",
    airportName: "Urrao",
    cityCode: "URR",
    cityName: "Urrao",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "URS",
    airportName: "Kursk",
    cityCode: "URS",
    cityName: "Kursk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "URT",
    airportName: "Surat Thani",
    cityCode: "URT",
    cityName: "Surat Thani",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "URY",
    airportName: "Gurayat",
    cityCode: "URY",
    cityName: "Gurayat",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "URZ",
    airportName: "Uruzgan",
    cityCode: "URZ",
    cityName: "Uruzgan",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "USH",
    airportName: "Islas Malvinas",
    cityCode: "USH",
    cityName: "Ushuaia",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "USI",
    airportName: "Mabaruma",
    cityCode: "USI",
    cityName: "Mabaruma",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "USK",
    airportName: "Usinsk",
    cityCode: "USK",
    cityName: "Usinsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "USL",
    airportName: "Useless Loop",
    cityCode: "USL",
    cityName: "Useless Loop",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "USM",
    airportName: "Koh Samui",
    cityCode: "USM",
    cityName: "Koh Samui",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "USN",
    airportName: "Ulsan",
    cityCode: "USN",
    cityName: "Ulsan",
    countryName: "Reublic of Korea",
    countryCode: "KR",
  },
  {
    airportCode: "USO",
    airportName: "Usino",
    cityCode: "USO",
    cityName: "Usino",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "USQ",
    airportName: "Usak",
    cityCode: "USQ",
    cityName: "Usak",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "USS",
    airportName: "Sancti Spiritus",
    cityCode: "USS",
    cityName: "Sancti Spiritus",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "UST",
    airportName: "St Augustine",
    cityCode: "UST",
    cityName: "St Augustine",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "USU",
    airportName: "Puerto Princessa",
    cityCode: "USU",
    cityName: "Busuanga",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "UTA",
    airportName: "Mutare",
    cityCode: "UTA",
    cityName: "Mutare",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "UTB",
    airportName: "Muttaburra",
    cityCode: "UTB",
    cityName: "Muttaburra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "UTC",
    airportName: "Soesterberg",
    cityCode: "UTC",
    cityName: "Utrecht",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "UTD",
    airportName: "Nutwood Downs",
    cityCode: "UTD",
    cityName: "Nutwood Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "UTE",
    airportName: "Butterworth",
    cityCode: "UTE",
    cityName: "Butterworth",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "UTG",
    airportName: "Quthing",
    cityCode: "UTG",
    cityName: "Quthing",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "UTH",
    airportName: "Udon Thani",
    cityCode: "UTH",
    cityName: "Udon Thani",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "UTI",
    airportName: "Utti",
    cityCode: "UTI",
    cityName: "Valkeala",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "UTK",
    airportName: "Utirik Island",
    cityCode: "UTK",
    cityName: "Utirik Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "UTL",
    airportName: "Torremolinos",
    cityCode: "UTL",
    cityName: "Torremolinos",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "UTN",
    airportName: "Upington",
    cityCode: "UTN",
    cityName: "Upington",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "UTO",
    airportName: "Indian Mountain AFS",
    cityCode: "UTO",
    cityName: "Utopia Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UTP",
    airportName: "Utapao",
    cityCode: "UTP",
    cityName: "Utapao",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "UTR",
    airportName: "Uttaradit",
    cityCode: "UTR",
    cityName: "Uttaradit",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "UTT",
    airportName: "Umtata",
    cityCode: "UTT",
    cityName: "Umtata",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "UTU",
    airportName: "Ustupo",
    cityCode: "UTU",
    cityName: "Ustupo",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "UTW",
    airportName: "Queenstown",
    cityCode: "UTW",
    cityName: "Queenstown",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "UUA",
    airportName: "Bugulma",
    cityCode: "UUA",
    cityName: "Bugulma",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "UUD",
    airportName: "Ulan-ude, Mukhino",
    cityCode: "UUD",
    cityName: "Ulan-ude",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "UUK",
    airportName: "Kuparuk",
    cityCode: "UUK",
    cityName: "Kuparuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UUN",
    airportName: "Baruun-Urt",
    cityCode: "UUN",
    cityName: "Baruun-Urt",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "UUS",
    airportName: "Yuzhno-Sakhalinsk",
    cityCode: "UUS",
    cityName: "Yuzhno-Sakhalinsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "UUU",
    airportName: "Manumu",
    cityCode: "UUU",
    cityName: "Manumu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "UVA",
    airportName: "Garner Fld",
    cityCode: "UVA",
    cityName: "Uvalde",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UVE",
    airportName: "Ouvea",
    cityCode: "UVE",
    cityName: "Ouvea",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "UVF",
    airportName: "Hewanorra",
    cityCode: "UVF",
    cityName: "St Lucia",
    countryName: "Saint Lucia",
    countryCode: "LC",
  },
  {
    airportCode: "UVL",
    airportName: "Kharga",
    cityCode: "UVL",
    cityName: "Kharga",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "UVO",
    airportName: "Uvol",
    cityCode: "UVO",
    cityName: "Uvol",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "UWA",
    airportName: "Ware",
    cityCode: "UWA",
    cityName: "Ware",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "UYL",
    airportName: "Nyala",
    cityCode: "UYL",
    cityName: "Nyala",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "UYN",
    airportName: "Yulin",
    cityCode: "UYN",
    cityName: "Yulin",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "UZC",
    airportName: "Ponikve",
    cityCode: "UZC",
    cityName: "Uzice",
    countryName: "Serbia",
    countryCode: "CS",
  },
  {
    airportCode: "UZH",
    airportName: "Unayzah",
    cityCode: "UZH",
    cityName: "Unayzah",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "UZU",
    airportName: "Curuzu Cuatia",
    cityCode: "UZU",
    cityName: "Curuzu Cuatia",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "VAA",
    airportName: "Vaasa",
    cityCode: "VAA",
    cityName: "Vaasa",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "VAB",
    airportName: "Yavarate",
    cityCode: "VAB",
    cityName: "Yavarate",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "VAC",
    airportName: "Varrelbusch",
    cityCode: "VAC",
    cityName: "Varrelbusch",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "VAD",
    airportName: "Moody AFB",
    cityCode: "VAD",
    cityName: "Valdosta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VAF",
    airportName: "Chabeuil",
    cityCode: "VAF",
    cityName: "Valence",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "VAG",
    airportName: "Maj. Brig. Trompowsky",
    cityCode: "VAG",
    cityName: "Varginha",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "VAH",
    airportName: "Vallegrande",
    cityCode: "VAH",
    cityName: "Vallegrande",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "VAI",
    airportName: "Vanimo",
    cityCode: "VAI",
    cityName: "Vanimo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "VAK",
    airportName: "Chevak",
    cityCode: "VAK",
    cityName: "Chevak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VAL",
    airportName: "Valenca",
    cityCode: "VAL",
    cityName: "Valenca",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "VAN",
    airportName: "Ferit Melen Arpt.",
    cityCode: "VAN",
    cityName: "Van",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "VAP",
    airportName: "Valparaiso",
    cityCode: "VAP",
    cityName: "Valparaiso",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "VAR",
    airportName: "Varna",
    cityCode: "VAR",
    cityName: "Varna",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "VAS",
    airportName: "Sivas",
    cityCode: "VAS",
    cityName: "Sivas",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "VAT",
    airportName: "Vatomandry",
    cityCode: "VAT",
    cityName: "Vatomandry",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "VAU",
    airportName: "Vatukoula",
    cityCode: "VAU",
    cityName: "Vatukoula",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "VAW",
    airportName: "Vardoe",
    cityCode: "VAW",
    cityName: "Vardoe",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "VBS",
    airportName: "Brescia Montichiari",
    cityCode: "VBS",
    cityName: "Brescia",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "VBV",
    airportName: "Vanuabalavu",
    cityCode: "VBV",
    cityName: "Vanuabalavu",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "VBY",
    airportName: "Visby",
    cityCode: "VBY",
    cityName: "Visby",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "VCA",
    airportName: "Can Tho",
    cityCode: "VCA",
    cityName: "Can Tho",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "VCB",
    airportName: "View Cove",
    cityCode: "VCB",
    cityName: "View Cove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VCC",
    airportName: "Limbe",
    cityCode: "VCC",
    cityName: "Limbe",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "VCD",
    airportName: "Victoria River Downs",
    cityCode: "VCD",
    cityName: "Victoria River Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "VCE",
    airportName: "Marco Polo",
    cityCode: "VCE",
    cityName: "Venice",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "VCF",
    airportName: "Valcheta",
    cityCode: "VCF",
    cityName: "Valcheta",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "VCH",
    airportName: "Vichadero",
    cityCode: "VCH",
    cityName: "Vichadero",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "VCL",
    airportName: "Chulai",
    cityCode: "VCL",
    cityName: "Tamky",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "VCP",
    airportName: "Viracopos",
    cityCode: "SAO",
    cityName: "Sao Paulo",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "VCR",
    airportName: "Carora",
    cityCode: "VCR",
    cityName: "Carora",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "VCS",
    airportName: "Coong",
    cityCode: "VCS",
    cityName: "Con Dao",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "VCT",
    airportName: "County-Foster",
    cityCode: "VCT",
    cityName: "Victoria",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VCV",
    airportName: "Southern California Logistics",
    cityCode: "VCV",
    cityName: "Victorville, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VDA",
    airportName: "Ovda",
    cityCode: "VDA",
    cityName: "Eilat",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "VDB",
    airportName: "Valdres",
    cityCode: "VDB",
    cityName: "Fagernes",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "VDC",
    airportName: "Vitoria Da Conquista",
    cityCode: "VDC",
    cityName: "Vitoria Da Conquista",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "VDD",
    airportName: "Vienna Danubepier Hov",
    cityCode: "VDD",
    cityName: "Vienna",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "VDE",
    airportName: "Hierro",
    cityCode: "VDE",
    cityName: "Valverde",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "VDI",
    airportName: "Municipal",
    cityCode: "VDI",
    cityName: "Vidalia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VDM",
    airportName: "Viedma",
    cityCode: "VDM",
    cityName: "Viedma",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "VDP",
    airportName: "Valle De Pascua",
    cityCode: "VDP",
    cityName: "Valle De Pascua",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "VDR",
    airportName: "Villa Dolores",
    cityCode: "VDR",
    cityName: "Villa Dolores",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "VDS",
    airportName: "Vadso",
    cityCode: "VDS",
    cityName: "Vadso",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "VDZ",
    airportName: "Municipal",
    cityCode: "VDZ",
    cityName: "Valdez",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VEE",
    airportName: "Venetie",
    cityCode: "VEE",
    cityName: "Venetie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VEG",
    airportName: "Maikwak",
    cityCode: "VEG",
    cityName: "Maikwak",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "VEJ",
    airportName: "Vejle",
    cityCode: "VEJ",
    cityName: "Vejle",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "VEL",
    airportName: "Vernal",
    cityCode: "VEL",
    cityName: "Vernal, UT",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VER",
    airportName: "Las Bajadas",
    cityCode: "VER",
    cityName: "Veracruz",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "VEV",
    airportName: "Barakoma",
    cityCode: "VEV",
    cityName: "Barakoma",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "VEY",
    airportName: "Vestmannaeyjar",
    cityCode: "VEY",
    cityName: "Vestmannaeyjar",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "VFA",
    airportName: "Victoria Falls",
    cityCode: "VFA",
    cityName: "Victoria Falls",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "VGA",
    airportName: "Vijayawada",
    cityCode: "VGA",
    cityName: "Vijayawada",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "VGD",
    airportName: "Vologda",
    cityCode: "VGD",
    cityName: "Vologda",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VGG",
    airportName: "Vangrieng",
    cityCode: "VGG",
    cityName: "Vangrieng",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "VGO",
    airportName: "Vigo",
    cityCode: "VGO",
    cityName: "Vigo",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "VGS",
    airportName: "General Villegas",
    cityCode: "VGS",
    cityName: "General Villegas",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "VGT",
    airportName: "North Air Terminal",
    cityCode: "VGT",
    cityName: "Las Vegas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VGZ",
    airportName: "Villagarzon",
    cityCode: "VGZ",
    cityName: "Villagarzon",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "VHC",
    airportName: "Saurimo",
    cityCode: "VHC",
    cityName: "Saurimo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "VHM",
    airportName: "Vilhelmina",
    cityCode: "VHM",
    cityName: "Vilhelmina",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "VHN",
    airportName: "Culberson County",
    cityCode: "VHN",
    cityName: "Van Horn",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VHY",
    airportName: "Charmeil",
    cityCode: "VHY",
    cityName: "Vichy",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "VHZ",
    airportName: "Vahitahi",
    cityCode: "VHZ",
    cityName: "Vahitahi",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "VIA",
    airportName: "Vienna",
    cityCode: "VIA",
    cityName: "Videira",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "VIB",
    airportName: "Villa Constitucion",
    cityCode: "VIB",
    cityName: "Villa Constitucion",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "VIC",
    airportName: "Vicenza",
    cityCode: "VIC",
    cityName: "Vicenza",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "VID",
    airportName: "Vidin",
    cityCode: "VID",
    cityName: "Vidin",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "VIE",
    airportName: "Schwechat Intl.",
    cityCode: "VIE",
    cityName: "Vienna",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "VIG",
    airportName: "El Vigia",
    cityCode: "VIG",
    cityName: "El Vigia",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "VIH",
    airportName: "Rolla National",
    cityCode: "VIH",
    cityName: "Vichy",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VII",
    airportName: "Vinh City",
    cityCode: "VII",
    cityName: "Vinh City",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "VIJ",
    airportName: "Virgin Gorda",
    cityCode: "VIJ",
    cityName: "Spanish Town/Virgin Gorda",
    countryName: "British Virgin Islands",
    countryCode: "VG",
  },
  {
    airportCode: "VIK",
    airportName: "Airstrip",
    cityCode: "VIK",
    cityName: "Kavik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VIL",
    airportName: "Dakhla",
    cityCode: "VIL",
    cityName: "Dakhla",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "VIN",
    airportName: "Vinnica",
    cityCode: "VIN",
    cityName: "Vinnica",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "VIQ",
    airportName: "Viqueque",
    cityCode: "VIQ",
    cityName: "Viqueque",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "VIR",
    airportName: "Virginia",
    cityCode: "VIR",
    cityName: "Durban",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "VIS",
    airportName: "Visalia",
    cityCode: "VIS",
    cityName: "Visalia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VIT",
    airportName: "Vitoria.",
    cityCode: "VIT",
    cityName: "Vitoria.",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "VIU",
    airportName: "Viru Harbour Airstrip",
    cityCode: "VIU",
    cityName: "Viru",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "VIV",
    airportName: "Vivigani",
    cityCode: "VIV",
    cityName: "Vivigani",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "VIX",
    airportName: "Eurico Sales",
    cityCode: "VIX",
    cityName: "Vitoria",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "VJB",
    airportName: "Xai Xai",
    cityCode: "VJB",
    cityName: "Xai Xai",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "VJI",
    airportName: "Virginia Highlands",
    cityCode: "VJI",
    cityName: "Abingdon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VJQ",
    airportName: "Gurue",
    cityCode: "VJQ",
    cityName: "Gurue",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "VKG",
    airportName: "Rach Gia",
    cityCode: "VKG",
    cityName: "Rach Gia",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "VKO",
    airportName: "Vnukovo",
    cityCode: "MOW",
    cityName: "Moscow",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VKT",
    airportName: "Vorkuta",
    cityCode: "VKT",
    cityName: "Vorkuta",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VKW",
    airportName: "West Kavik",
    cityCode: "VKW",
    cityName: "West Kavik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VLA",
    airportName: "Vandalia",
    cityCode: "VLA",
    cityName: "Vandalia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VLC",
    airportName: "Valencia",
    cityCode: "VLC",
    cityName: "Manises",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "VLD",
    airportName: "Regional",
    cityCode: "VLD",
    cityName: "Valdosta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VLE",
    airportName: "J t Robidoux",
    cityCode: "VLE",
    cityName: "Valle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VLG",
    airportName: "Villa Gesell",
    cityCode: "VLG",
    cityName: "Villa Gesell",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "VLI",
    airportName: "Bauerfield",
    cityCode: "VLI",
    cityName: "Port Vila",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "VLK",
    airportName: "Volgodonsk",
    cityCode: "VLK",
    cityName: "Volgodonsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VLL",
    airportName: "Valladolid",
    cityCode: "VLL",
    cityName: "Valladolid",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "VLM",
    airportName: "Villamontes",
    cityCode: "VLM",
    cityName: "Villamontes",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "VLN",
    airportName: "Valencia",
    cityCode: "VLN",
    cityName: "Valencia",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "VLO",
    airportName: "Stolport",
    cityCode: "VLO",
    cityName: "Vallejo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VLR",
    airportName: "Vallenar",
    cityCode: "VLR",
    cityName: "Vallenar",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "VLS",
    airportName: "Valesdir",
    cityCode: "VLS",
    cityName: "Valesdir",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "VLU",
    airportName: "Velikiye Luki",
    cityCode: "VLU",
    cityName: "Velikiye Luki",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VLV",
    airportName: "Carvajal",
    cityCode: "VLV",
    cityName: "Valera",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "VME",
    airportName: "Villa Mercedes",
    cityCode: "VME",
    cityName: "Villa Mercedes",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "VMI",
    airportName: "INC",
    cityCode: "VMI",
    cityName: "Vallemi",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "VMU",
    airportName: "Baimuru",
    cityCode: "VMU",
    cityName: "Baimuru",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "VNA",
    airportName: "Saravane",
    cityCode: "VNA",
    cityName: "Saravane",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "VNC",
    airportName: "Venice Municipal",
    cityCode: "VNC",
    cityName: "Venice, FL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VND",
    airportName: "Vangaindrano",
    cityCode: "VND",
    cityName: "Vangaindrano",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "VNE",
    airportName: "Meucon",
    cityCode: "VNE",
    cityName: "Vannes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "VNG",
    airportName: "Viengxay",
    cityCode: "VNG",
    cityName: "Viengxay",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "VNO",
    airportName: "Vilnius",
    cityCode: "VNO",
    cityName: "Vilnius",
    countryName: "Lithuania",
    countryCode: "LT",
  },
  {
    airportCode: "VNR",
    airportName: "Vanrook",
    cityCode: "VNR",
    cityName: "Vanrook",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "VNS",
    airportName: "Varanasi",
    cityCode: "VNS",
    cityName: "Varanasi",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "VNX",
    airportName: "Vilanculos",
    cityCode: "VNX",
    cityName: "Vilanculos",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "VNY",
    airportName: "Van Nuys",
    cityCode: "VNY",
    cityName: "Los Angeles, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VOG",
    airportName: "Volgograd",
    cityCode: "VOG",
    cityName: "Volgograd",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VOH",
    airportName: "Vohemar",
    cityCode: "VOH",
    cityName: "Vohemar",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "VOI",
    airportName: "Voinjama",
    cityCode: "VOI",
    cityName: "Voinjama",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "VOK",
    airportName: "Volk Field",
    cityCode: "VOK",
    cityName: "Camp Douglas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VOL",
    airportName: "Nea Anchialos",
    cityCode: "VOL",
    cityName: "Volos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "VOT",
    airportName: "Votuporanga",
    cityCode: "VOT",
    cityName: "Votuporanga",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "VOZ",
    airportName: "Chertovitskoye",
    cityCode: "VOZ",
    cityName: "Voronezh",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VPE",
    airportName: "Ongiva",
    cityCode: "VPE",
    cityName: "Ongiva",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "VPN",
    airportName: "Vopnafjordur",
    cityCode: "VPN",
    cityName: "Vopnafjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "VPS",
    airportName: "Eglin AFB",
    cityCode: "VPS",
    cityName: "Valparaiso, FL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VPZ",
    airportName: "Porter County",
    cityCode: "VPZ",
    cityName: "Valparaiso",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VQQ",
    airportName: "Cecil Field",
    cityCode: "VQQ",
    cityName: "Jacksonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VQS",
    airportName: "Vieques",
    cityCode: "VQS",
    cityName: "Vieques",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "VRA",
    airportName: "Juan Gualberto Gomez",
    cityCode: "VRA",
    cityName: "Varadero",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "VRB",
    airportName: "Municipal",
    cityCode: "VRB",
    cityName: "Vero Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VRC",
    airportName: "Virac",
    cityCode: "VRC",
    cityName: "Virac",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "VRE",
    airportName: "Vredendal",
    cityCode: "VRE",
    cityName: "Vredendal",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "VRK",
    airportName: "Varkaus",
    cityCode: "VRK",
    cityName: "Joroinen",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "VRL",
    airportName: "Vila Real",
    cityCode: "VRL",
    cityName: "Vila Real",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "VRN",
    airportName: "Valerio Catullo",
    cityCode: "VRN",
    cityName: "Verona",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "VRO",
    airportName: "Kawama",
    cityCode: "VRO",
    cityName: "Matanzas",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "VRS",
    airportName: "Versailles",
    cityCode: "VRS",
    cityName: "Versailles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VRU",
    airportName: "Vryburg",
    cityCode: "VRU",
    cityName: "Vryburg",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "VRY",
    airportName: "Stolport",
    cityCode: "VRY",
    cityName: "Vaeroy",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "VSA",
    airportName: "Capitan Carlos Rovirosa",
    cityCode: "VSA",
    cityName: "Villahermosa",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "VSE",
    airportName: "Viseu",
    cityCode: "VSE",
    cityName: "Viseu",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "VSF",
    airportName: "State",
    cityCode: "VSF",
    cityName: "Springfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VSG",
    airportName: "Lugansk",
    cityCode: "VSG",
    cityName: "Lugansk",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "VSO",
    airportName: "Phuoclong",
    cityCode: "VSO",
    cityName: "Phuoclong",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "VST",
    airportName: "Vasteras/Hasslo",
    cityCode: "VST",
    cityName: "Stockholm",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "VTA",
    airportName: "Victoria",
    cityCode: "VTA",
    cityName: "Victoria",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "VTB",
    airportName: "Vitebsk",
    cityCode: "VTB",
    cityName: "Vitebsk",
    countryName: "Belarus",
    countryCode: "BY",
  },
  {
    airportCode: "VTE",
    airportName: "Wattay",
    cityCode: "VTE",
    cityName: "Vientiane",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "VTF",
    airportName: "Vatulele",
    cityCode: "VTF",
    cityName: "Vatulele",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "VTG",
    airportName: "Vung Tau",
    cityCode: "VTG",
    cityName: "Vung Tau",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "VTL",
    airportName: "Vittel",
    cityCode: "VTL",
    cityName: "Vittel",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "VTN",
    airportName: "Miller Field",
    cityCode: "VTN",
    cityName: "Valentine",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "VTU",
    airportName: "Las Tunas",
    cityCode: "VTU",
    cityName: "Las Tunas",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "VTZ",
    airportName: "Vishakhapatnam",
    cityCode: "VTZ",
    cityName: "Vishakhapatnam",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "VUP",
    airportName: "Valledupar",
    cityCode: "VUP",
    cityName: "Valledupar",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "VUS",
    airportName: "Velikij Ustyug",
    cityCode: "VUS",
    cityName: "Velikij Ustyug",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VVB",
    airportName: "Mahanoro",
    cityCode: "VVB",
    cityName: "Mahanoro",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "VVC",
    airportName: "La Vanguardia",
    cityCode: "VVC",
    cityName: "Villavicencio",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "VVI",
    airportName: "Viru Viru Intl",
    cityCode: "VVI",
    cityName: "Santa Cruz",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "VVK",
    airportName: "Vastervik",
    cityCode: "VVK",
    cityName: "Vastervik",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "VVO",
    airportName: "Vladivostok",
    cityCode: "VVO",
    cityName: "Vladivostok",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "VVZ",
    airportName: "Illizi",
    cityCode: "VVZ",
    cityName: "Illizi",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "VXC",
    airportName: "Lichinga",
    cityCode: "VXC",
    cityName: "Lichinga",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "VXE",
    airportName: "San Pedro",
    cityCode: "VXE",
    cityName: "Sao Vicente",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "VXO",
    airportName: "Smaland Arpt.",
    cityCode: "VXO",
    cityName: "VÃ¤xjÃ¶",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "VYD",
    airportName: "Vryheid",
    cityCode: "VYD",
    cityName: "Vryheid",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "VYS",
    airportName: "Illinois Valley Regnl",
    cityCode: "VYS",
    cityName: "Peru",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WAA",
    airportName: "Wales",
    cityCode: "WAA",
    cityName: "Wales",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WAB",
    airportName: "Wabag",
    cityCode: "WAB",
    cityName: "Wabag",
    countryName: "Papua New Guinea",
    countryCode: "PNG",
  },
  {
    airportCode: "WAC",
    airportName: "Waca",
    cityCode: "WAC",
    cityName: "Waca",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "WAD",
    airportName: "Andriamena",
    cityCode: "WAD",
    cityName: "Andriamena",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WAE",
    airportName: "Wadi Ad Dawasir",
    cityCode: "WAE",
    cityName: "Wadi Ad Dawasir",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "WAF",
    airportName: "Wana",
    cityCode: "WAF",
    cityName: "Wana",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "WAG",
    airportName: "Wanganui",
    cityCode: "WAG",
    cityName: "Wanganui",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WAH",
    airportName: "Wahpeton",
    cityCode: "WAH",
    cityName: "Wahpeton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WAI",
    airportName: "Antsohihy",
    cityCode: "WAI",
    cityName: "Antsohihy",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WAJ",
    airportName: "Wawoi Falls",
    cityCode: "WAJ",
    cityName: "Wawoi Falls",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WAK",
    airportName: "Ankazoabo",
    cityCode: "WAK",
    cityName: "Ankazoabo",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WAL",
    airportName: "Wallops Flight Center",
    cityCode: "WAL",
    cityName: "Chincoteague",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WAM",
    airportName: "Ambatondrazaka",
    cityCode: "WAM",
    cityName: "Ambatondrazaka",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WAN",
    airportName: "Waverney",
    cityCode: "WAN",
    cityName: "Waverney",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WAO",
    airportName: "Wabo",
    cityCode: "WAO",
    cityName: "Wabo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WAP",
    airportName: "Alto Palena",
    cityCode: "WAP",
    cityName: "Alto Palena",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "WAQ",
    airportName: "Antsalova",
    cityCode: "WAQ",
    cityName: "Antsalova",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WAR",
    airportName: "Waris",
    cityCode: "WAR",
    cityName: "Waris",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "WAT",
    airportName: "Waterford",
    cityCode: "WAT",
    cityName: "Waterford",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "WAU",
    airportName: "Wauchope",
    cityCode: "WAU",
    cityName: "Wauchope",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WAV",
    airportName: "Kalkgurung",
    cityCode: "WAV",
    cityName: "Wave Hill",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WAW",
    airportName: "Frederic Chopin",
    cityCode: "WAW",
    cityName: "Warsaw",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "WAX",
    airportName: "Zwara",
    cityCode: "WAX",
    cityName: "Zwara",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WAY",
    airportName: "Greene County",
    cityCode: "WAY",
    cityName: "Waynesburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WAZ",
    airportName: "Warwick",
    cityCode: "WAZ",
    cityName: "Warwick",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WBA",
    airportName: "Wahai",
    cityCode: "WBA",
    cityName: "Wahai",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WBB",
    airportName: "Stebbins",
    cityCode: "WBB",
    cityName: "Stebbins",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WBD",
    airportName: "Befandriana",
    cityCode: "WBD",
    cityName: "Befandriana",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WBE",
    airportName: "Bealanana",
    cityCode: "WBE",
    cityName: "Bealanana",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WBG",
    airportName: "Schleswig-jagel",
    cityCode: "WBG",
    cityName: "Schleswig-jagel",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "WBI",
    airportName: "Broker Inn",
    cityCode: "WBI",
    cityName: "Boulder",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WBM",
    airportName: "Wapenamanda",
    cityCode: "WBM",
    cityName: "Wapenamanda",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WBN",
    airportName: "Cummings Park",
    cityCode: "WBN",
    cityName: "Woburn",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WBO",
    airportName: "Beroroha",
    cityCode: "WBO",
    cityName: "Beroroha",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WBQ",
    airportName: "Beaver",
    cityCode: "WBQ",
    cityName: "Beaver",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WBR",
    airportName: "Big Rapids",
    cityCode: "WBR",
    cityName: "Big Rapids",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WBU",
    airportName: "Municipal",
    cityCode: "WBU",
    cityName: "Boulder",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WBW",
    airportName: "Wilkes-Barre/Wyoming Valley",
    cityCode: "WBW",
    cityName: "Wilkes-Barre, PA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WCA",
    airportName: "Gamboa",
    cityCode: "WCA",
    cityName: "Castro",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "WCH",
    airportName: "Chaiten",
    cityCode: "WCH",
    cityName: "Chaiten",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "WCR",
    airportName: "Chandalar",
    cityCode: "WCR",
    cityName: "Chandalar",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WDA",
    airportName: "Wadi Ain",
    cityCode: "WDA",
    cityName: "Wadi Ain",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "WDB",
    airportName: "Deep Bay",
    cityCode: "WDB",
    cityName: "Deep Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WDG",
    airportName: "Enid Woodring Mun.",
    cityCode: "WDG",
    cityName: "Enid",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WDH",
    airportName: "Hosea Kutako Intl.",
    cityCode: "WDH",
    cityName: "Windhoek",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "WDI",
    airportName: "Wondai",
    cityCode: "WDI",
    cityName: "Wondai",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WDR",
    airportName: "Winder",
    cityCode: "WDR",
    cityName: "Winder",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WDY",
    airportName: "Kodiak Fss",
    cityCode: "WDY",
    cityName: "Kodiak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WEA",
    airportName: "Parker County",
    cityCode: "WEA",
    cityName: "Weatherford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WED",
    airportName: "Wedau",
    cityCode: "WED",
    cityName: "Wedau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WEF",
    airportName: "Weifang",
    cityCode: "WEF",
    cityName: "Weifang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WEH",
    airportName: "Weihai",
    cityCode: "WEH",
    cityName: "Weihai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WEI",
    airportName: "Weipa",
    cityCode: "WEI",
    cityName: "Weipa",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WEL",
    airportName: "Welkom",
    cityCode: "WEL",
    cityName: "Welkom",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "WEM",
    airportName: "West Malling",
    cityCode: "WEM",
    cityName: "West Malling",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WEP",
    airportName: "Weam",
    cityCode: "WEP",
    cityName: "Weam",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WES",
    airportName: "Weasua",
    cityCode: "WES",
    cityName: "Weasua",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "WET",
    airportName: "Wagethe",
    cityCode: "WET",
    cityName: "Wagethe",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "WEW",
    airportName: "Wee Waa",
    cityCode: "WEW",
    cityName: "Wee Waa",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WEX",
    airportName: "Castlebridge",
    cityCode: "WEX",
    cityName: "Wexford",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "WFB",
    airportName: "Waterfront SPB",
    cityCode: "WFB",
    cityName: "Ketchikan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WFD",
    airportName: "Woodford",
    cityCode: "WFD",
    cityName: "Woodford",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WFI",
    airportName: "Fianarantsoa",
    cityCode: "WFI",
    cityName: "Fianarantsoa",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WFK",
    airportName: "Frenchville",
    cityCode: "WFK",
    cityName: "Frenchville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WGA",
    airportName: "Forrest Hill",
    cityCode: "WGA",
    cityName: "Wagga Wagga",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WGB",
    airportName: "Bahawalnagar",
    cityCode: "WGB",
    cityName: "Bahawalnagar",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "WGC",
    airportName: "Warangal",
    cityCode: "WGC",
    cityName: "Warangal",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "WGE",
    airportName: "Walgett",
    cityCode: "WGE",
    cityName: "Walgett",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WGF",
    airportName: "Off Line Point",
    cityCode: "WGF",
    cityName: "Manama",
    countryName: "Bahrain",
    countryCode: "BH",
  },
  {
    airportCode: "WGN",
    airportName: "Waitangi",
    cityCode: "WGN",
    cityName: "Waitangi",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WGO",
    airportName: "Municipal",
    cityCode: "WGO",
    cityName: "Winchester",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WGP",
    airportName: "Waingapu",
    cityCode: "WGP",
    cityName: "Waingapu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "WGT",
    airportName: "Wangaratta",
    cityCode: "WGT",
    cityName: "Wangaratta",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WGU",
    airportName: "Wagau",
    cityCode: "WGU",
    cityName: "Wagau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WGY",
    airportName: "Wagny",
    cityCode: "WGY",
    cityName: "Wagny",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "WGZ",
    airportName: "Off Line Point",
    cityCode: "WGZ",
    cityName: "Manly",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WHD",
    airportName: "SPB",
    cityCode: "WHD",
    cityName: "Hyder",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WHF",
    airportName: "Wadi Halfa",
    cityCode: "WHF",
    cityName: "Wadi Halfa",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "WHH",
    airportName: "Hiltons Har H",
    cityCode: "WHH",
    cityName: "Boulder",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WHK",
    airportName: "Whakatane",
    cityCode: "WHK",
    cityName: "Whakatane",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WHL",
    airportName: "Welshpool",
    cityCode: "WHL",
    cityName: "Welshpool",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WHO",
    airportName: "Franz Josef",
    cityCode: "WHO",
    cityName: "Franz Josef",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WHP",
    airportName: "Whiteman",
    cityCode: "WHP",
    cityName: "Los Angeles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WHS",
    airportName: "Whalsay",
    cityCode: "WHS",
    cityName: "Whalsay",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WHT",
    airportName: "Wharton",
    cityCode: "WHT",
    cityName: "Wharton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WHU",
    airportName: "Wuhu",
    cityCode: "WHU",
    cityName: "Wuhu",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WIC",
    airportName: "Wick",
    cityCode: "WIC",
    cityName: "Wick",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WID",
    airportName: "Wildenrath",
    cityCode: "WID",
    cityName: "Wildenrath",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "WIE",
    airportName: "Air Base",
    cityCode: "WIE",
    cityName: "Wiesbaden",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "WIK",
    airportName: "Surfdale",
    cityCode: "WIK",
    cityName: "Surfdale",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WIL",
    airportName: "Wilson",
    cityCode: "WIL",
    cityName: "Nairobi",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "WIN",
    airportName: "Winton",
    cityCode: "WIN",
    cityName: "Winton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WIO",
    airportName: "Wilcannia",
    cityCode: "WIO",
    cityName: "Wilcannia",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WIT",
    airportName: "Wittenoom",
    cityCode: "WIT",
    cityName: "Wittenoom",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WIU",
    airportName: "Witu",
    cityCode: "WIU",
    cityName: "Witu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WJA",
    airportName: "Woja",
    cityCode: "WJA",
    cityName: "Woja",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "WJF",
    airportName: "William J Fox",
    cityCode: "WJF",
    cityName: "Lancaster",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WJR",
    airportName: "Wajir",
    cityCode: "WJR",
    cityName: "Wajir",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "WJU",
    airportName: "WonJu",
    cityCode: "WJU",
    cityName: "WonJu",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "WKA",
    airportName: "Wanaka",
    cityCode: "WKA",
    cityName: "Wanaka",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WKB",
    airportName: "Warracknabeal",
    cityCode: "WKB",
    cityName: "Warracknabeal",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WKF",
    airportName: "Waterkloof AFB",
    cityCode: "WKF",
    cityName: "Waterkloof",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "WKI",
    airportName: "Hwange",
    cityCode: "WKI",
    cityName: "Hwange",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "WKJ",
    airportName: "Hokkaido",
    cityCode: "WKJ",
    cityName: "Wakkanai",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "WKK",
    airportName: "Aleknagik",
    cityCode: "WKK",
    cityName: "Aleknagik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WKL",
    airportName: "Waikoloa Arpt.",
    cityCode: "WKL",
    cityName: "Waikoloa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WKN",
    airportName: "Wakunai",
    cityCode: "WKN",
    cityName: "Wakunai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WLA",
    airportName: "Wallal",
    cityCode: "WLA",
    cityName: "Wallal",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WLB",
    airportName: "Labouchere Bay",
    cityCode: "WLB",
    cityName: "Labouchere Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WLC",
    airportName: "Walcha",
    cityCode: "WLC",
    cityName: "Walcha",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WLD",
    airportName: "Arkansas City",
    cityCode: "WLD",
    cityName: "Winfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WLG",
    airportName: "Wellington Intl.",
    cityCode: "WLG",
    cityName: "Wellington",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WLH",
    airportName: "Walaha",
    cityCode: "WLH",
    cityName: "Walaha",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "WLK",
    airportName: "Selawik",
    cityCode: "WLK",
    cityName: "Selawik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WLL",
    airportName: "Wollogorang",
    cityCode: "WLL",
    cityName: "Wollogorang",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WLM",
    airportName: "Waltham",
    cityCode: "WLM",
    cityName: "Waltham",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WLN",
    airportName: "Little Naukati",
    cityCode: "WLN",
    cityName: "Little Naukati",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WLO",
    airportName: "Waterloo",
    cityCode: "WLO",
    cityName: "Waterloo",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WLR",
    airportName: "Loring",
    cityCode: "WLR",
    cityName: "Loring",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WLS",
    airportName: "Wallis Island",
    cityCode: "WLS",
    cityName: "Wallis Island",
    countryName: "Wallis and Futuna Islands",
    countryCode: "WF",
  },
  {
    airportCode: "WLW",
    airportName: "Glenn County",
    cityCode: "WLW",
    cityName: "Willows",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WMA",
    airportName: "Mandritsara",
    cityCode: "WMA",
    cityName: "Mandritsara",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WMB",
    airportName: "Warrnambool",
    cityCode: "WMB",
    cityName: "Warrnambool",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WMC",
    airportName: "Winnemucca",
    cityCode: "WMC",
    cityName: "Winnemucca",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WMD",
    airportName: "Mandabe",
    cityCode: "WMD",
    cityName: "Mandabe",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WME",
    airportName: "Mount Keith",
    cityCode: "WME",
    cityName: "Mount Keith",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WMH",
    airportName: "Mountain Home",
    cityCode: "WMH",
    cityName: "Mountain Home",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WMK",
    airportName: "SPB",
    cityCode: "WMK",
    cityName: "Meyers Chuck",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WML",
    airportName: "Malaimbandy",
    cityCode: "WML",
    cityName: "Malaimbandy",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WMN",
    airportName: "Maroantsetra",
    cityCode: "WMN",
    cityName: "Maroantsetra",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WMO",
    airportName: "White Mountain",
    cityCode: "WMO",
    cityName: "White Mountain",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WMP",
    airportName: "Mampikony",
    cityCode: "WMP",
    cityName: "Mampikony",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WMR",
    airportName: "Mananara",
    cityCode: "WMR",
    cityName: "Mananara",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WMV",
    airportName: "Madirovalo",
    cityCode: "WMV",
    cityName: "Madirovalo",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WMX",
    airportName: "Wamena",
    cityCode: "WMX",
    cityName: "Wamena",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "WNA",
    airportName: "Napakiak SPB",
    cityCode: "WNA",
    cityName: "Napakiak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WND",
    airportName: "Windarra",
    cityCode: "WND",
    cityName: "Windarra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WNE",
    airportName: "Wora Na Ye",
    cityCode: "WNE",
    cityName: "Wora Na Ye",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "WNN",
    airportName: "Wunnummin Lake",
    cityCode: "WNN",
    cityName: "Wunnummin Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "WNP",
    airportName: "Naga",
    cityCode: "WNP",
    cityName: "Naga",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "WNR",
    airportName: "Windorah",
    cityCode: "WNR",
    cityName: "Windorah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WNS",
    airportName: "Nawabshah",
    cityCode: "WNS",
    cityName: "Nawabshah",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "WNU",
    airportName: "Wanuma",
    cityCode: "WNU",
    cityName: "Wanuma",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WNZ",
    airportName: "Wenzhou",
    cityCode: "WNZ",
    cityName: "Wenzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WOA",
    airportName: "Wonenara",
    cityCode: "WOA",
    cityName: "Wonenara",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WOB",
    airportName: "Woodbridge RAF",
    cityCode: "WOB",
    cityName: "Suttonheath",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WOD",
    airportName: "Wood River",
    cityCode: "WOD",
    cityName: "Wood River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WOE",
    airportName: "Woensdrecht",
    cityCode: "WOE",
    cityName: "Woensdrecht",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "WOG",
    airportName: "Woodgreen",
    cityCode: "WOG",
    cityName: "Woodgreen",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WOI",
    airportName: "Wologissi",
    cityCode: "WOI",
    cityName: "Wologissi",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "WOK",
    airportName: "Wonken",
    cityCode: "WOK",
    cityName: "Wonken",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "WOL",
    airportName: "Wollongong",
    cityCode: "WOL",
    cityName: "Wollongong",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WON",
    airportName: "Wondoola",
    cityCode: "WON",
    cityName: "Wondoola",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WOO",
    airportName: "Woodchopper",
    cityCode: "WOO",
    cityName: "Woodchopper",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WOR",
    airportName: "Moramba",
    cityCode: "WOR",
    cityName: "Ankorefo",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WOT",
    airportName: "Wonan",
    cityCode: "WOT",
    cityName: "Wonan",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "WOW",
    airportName: "Willow",
    cityCode: "WOW",
    cityName: "Willow",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WPA",
    airportName: "Puerto Aisen",
    cityCode: "WPA",
    cityName: "Puerto Aisen",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "WPB",
    airportName: "Port Berge",
    cityCode: "WPB",
    cityName: "Port Berge",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WPC",
    airportName: "Pincher Creek",
    cityCode: "WPC",
    cityName: "Pincher Creek",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "WPK",
    airportName: "Wrotham Park",
    cityCode: "WPK",
    cityName: "Wrotham Park",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WPL",
    airportName: "Powell Lake",
    cityCode: "WPL",
    cityName: "Powell Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "WPM",
    airportName: "Wipim",
    cityCode: "WPM",
    cityName: "Wipim",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WPO",
    airportName: "North Fork Valley",
    cityCode: "WPO",
    cityName: "Paonia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WPR",
    airportName: "Porvenir",
    cityCode: "WPR",
    cityName: "Porvenir",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "WPU",
    airportName: "Puerto Williams",
    cityCode: "WPU",
    cityName: "Puerto Williams",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "WRA",
    airportName: "Warder",
    cityCode: "WRA",
    cityName: "Warder",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "WRB",
    airportName: "Robins AFB",
    cityCode: "WRB",
    cityName: "Macon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WRE",
    airportName: "Whangarei",
    cityCode: "WRE",
    cityName: "Whangarei",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WRG",
    airportName: "Wrangell SPB",
    cityCode: "WRG",
    cityName: "Wrangell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WRI",
    airportName: "Mc Guire Afb",
    cityCode: "WRI",
    cityName: "Wrightstown, New Jersey",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WRL",
    airportName: "Worland",
    cityCode: "WRL",
    cityName: "Worland, WY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WRO",
    airportName: "Copernicus Arpt.",
    cityCode: "WRO",
    cityName: "Wroclaw",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "WRW",
    airportName: "Warrawagine",
    cityCode: "WRW",
    cityName: "Warrawagine",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WRY",
    airportName: "Westray",
    cityCode: "WRY",
    cityName: "Westray",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WRZ",
    airportName: "Wirawila",
    cityCode: "WRZ",
    cityName: "Wirawila",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "WSA",
    airportName: "Wasua",
    cityCode: "WSA",
    cityName: "Wasua",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WSB",
    airportName: "SPB",
    cityCode: "WSB",
    cityName: "Steamboat Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSD",
    airportName: "Condron AAF",
    cityCode: "WSD",
    cityName: "White Sands",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSF",
    airportName: "Sarichef",
    cityCode: "WSF",
    cityName: "Sarichef",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSG",
    airportName: "County",
    cityCode: "WSG",
    cityName: "Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSH",
    airportName: "Brookhaven",
    cityCode: "WSH",
    cityName: "Shirley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSJ",
    airportName: "San Juan SPB",
    cityCode: "WSJ",
    cityName: "San Juan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSM",
    airportName: "Wiseman",
    cityCode: "WSM",
    cityName: "Wiseman",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSN",
    airportName: "South Naknek",
    cityCode: "WSN",
    cityName: "South Naknek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSO",
    airportName: "Washabo",
    cityCode: "WSO",
    cityName: "Washabo",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "WSP",
    airportName: "Waspam",
    cityCode: "WSP",
    cityName: "Waspam",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "WSR",
    airportName: "Wasior",
    cityCode: "WSR",
    cityName: "Wasior",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "WST",
    airportName: "Westerly State",
    cityCode: "WST",
    cityName: "Westerly",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSU",
    airportName: "Wasu",
    cityCode: "WSU",
    cityName: "Wasu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WSX",
    airportName: "Westsound",
    cityCode: "WSX",
    cityName: "Westsound",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WSY",
    airportName: "Whitsunday Airstrip",
    cityCode: "WSY",
    cityName: "Airlie Beach",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WSZ",
    airportName: "Westport",
    cityCode: "WSZ",
    cityName: "Westport",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WTA",
    airportName: "Tambohorano",
    cityCode: "WTA",
    cityName: "Tambohorano",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WTC",
    airportName: "World Trade Center",
    cityCode: "WTC",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WTD",
    airportName: "West End",
    cityCode: "WTD",
    cityName: "West End",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "WTE",
    airportName: "Wotje Island",
    cityCode: "WTE",
    cityName: "Wotje Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "WTK",
    airportName: "Noatak",
    cityCode: "WTK",
    cityName: "Noatak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WTL",
    airportName: "Tuntutuliak",
    cityCode: "WTL",
    cityName: "Tuntutuliak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WTN",
    airportName: "RAF Station",
    cityCode: "WTN",
    cityName: "Waddington",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WTO",
    airportName: "Wotho Island",
    cityCode: "WTO",
    cityName: "Wotho Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "WTP",
    airportName: "Woitape",
    cityCode: "WTP",
    cityName: "Woitape",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WTR",
    airportName: "White River",
    cityCode: "WTR",
    cityName: "White River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WTT",
    airportName: "Wantoat",
    cityCode: "WTT",
    cityName: "Wantoat",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WTZ",
    airportName: "Whitianga",
    cityCode: "WTZ",
    cityName: "Whitianga",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "WUD",
    airportName: "Wudinna",
    cityCode: "WUD",
    cityName: "Wudinna",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WUG",
    airportName: "Wau",
    cityCode: "WUG",
    cityName: "Wau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WUH",
    airportName: "Tianhe Intl.",
    cityCode: "WUH",
    cityName: "Wuhan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WUK",
    airportName: "Off Line Point",
    cityCode: "WUK",
    cityName: "Hammersmith",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WUN",
    airportName: "Wiluna",
    cityCode: "WUN",
    cityName: "Wiluna",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WUU",
    airportName: "Wau",
    cityCode: "WUU",
    cityName: "Wau",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "WUV",
    airportName: "Wuvulu Is",
    cityCode: "WUV",
    cityName: "Wuvulu Is",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WUX",
    airportName: "Wuxi",
    cityCode: "WUX",
    cityName: "Wuxi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WUZ",
    airportName: "Changzhoudao",
    cityCode: "WUZ",
    cityName: "Wuzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WVB",
    airportName: "Rooikop",
    cityCode: "WVB",
    cityName: "Walvis Bay",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "WVI",
    airportName: "Watsonville",
    cityCode: "WVI",
    cityName: "Watsonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WVK",
    airportName: "Manakara",
    cityCode: "WVK",
    cityName: "Manakara",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "WVL",
    airportName: "Robert Lafleur",
    cityCode: "WVL",
    cityName: "Waterville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WVN",
    airportName: "Wilhelmshaven",
    cityCode: "WVN",
    cityName: "Wilhelmshaven",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "WWA",
    airportName: "Wasilla",
    cityCode: "WWA",
    cityName: "Wasilla",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WWD",
    airportName: "Cape May County",
    cityCode: "WWD",
    cityName: "Wildwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WWG",
    airportName: "Al Koude",
    cityCode: "WWG",
    cityName: "Al Koude",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "WWK",
    airportName: "Boram",
    cityCode: "WWK",
    cityName: "Wewak",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "WWP",
    airportName: "Whale Pass",
    cityCode: "WWP",
    cityName: "Whale Pass",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WWR",
    airportName: "West Woodward",
    cityCode: "WWR",
    cityName: "Woodward",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WWS",
    airportName: "USAF Heliport",
    cityCode: "WWS",
    cityName: "Wildwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WWT",
    airportName: "Newtok",
    cityCode: "WWT",
    cityName: "Newtok",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WWY",
    airportName: "West Wyalong",
    cityCode: "WWY",
    cityName: "West Wyalong",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WXF",
    airportName: "Weathersfield Raf",
    cityCode: "WXF",
    cityName: "Braintree",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "WXN",
    airportName: "Wanxian",
    cityCode: "WXN",
    cityName: "Wanxian",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "WYA",
    airportName: "Whyalla",
    cityCode: "WYA",
    cityName: "Whyalla",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WYB",
    airportName: "SPB",
    cityCode: "WYB",
    cityName: "Yes Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WYE",
    airportName: "Yengema",
    cityCode: "WYE",
    cityName: "Yengema",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "WYN",
    airportName: "Wyndham",
    cityCode: "WYN",
    cityName: "Wyndham",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "WYS",
    airportName: "Yellowstone",
    cityCode: "WYS",
    cityName: "West Yellowstone",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "WZY",
    airportName: "Paradise Island Seaplane Base",
    cityCode: "WZY",
    cityName: "Nassau",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "XAL",
    airportName: "Alamos",
    cityCode: "XAL",
    cityName: "Alamos",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "XAP",
    airportName: "Aeroporto Municipal Chapeco",
    cityCode: "XAP",
    cityName: "Chapeco",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "XAR",
    airportName: "Aribinda",
    cityCode: "XAR",
    cityName: "Aribinda",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XAU",
    airportName: "Saul",
    cityCode: "XAU",
    cityName: "Saul",
    countryName: "French Guiana",
    countryCode: "GF",
  },
  {
    airportCode: "XAY",
    airportName: "Xayabury",
    cityCode: "XAY",
    cityName: "Xayabury",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "XBB",
    airportName: "Blubber Bay",
    cityCode: "XBB",
    cityName: "Blubber Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XBE",
    airportName: "Bearskin Lake",
    cityCode: "XBE",
    cityName: "Bearskin Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XBG",
    airportName: "Bogande",
    cityCode: "XBG",
    cityName: "Bogande",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XBJ",
    airportName: "Birjand",
    cityCode: "XBJ",
    cityName: "Birjand",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "XBL",
    airportName: "Buno Bedelle",
    cityCode: "XBL",
    cityName: "Buno Bedelle",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "XBN",
    airportName: "Biniguni",
    cityCode: "XBN",
    cityName: "Biniguni",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "XBO",
    airportName: "Boulsa",
    cityCode: "XBO",
    cityName: "Boulsa",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XBR",
    airportName: "Brockville",
    cityCode: "XBR",
    cityName: "Brockville",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XBW",
    airportName: "Killineq",
    cityCode: "XBW",
    cityName: "Killineq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XCL",
    airportName: "Cluff Lake",
    cityCode: "XCL",
    cityName: "Cluff Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XCM",
    airportName: "Chatham",
    cityCode: "XCM",
    cityName: "Chatham",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XCN",
    airportName: "Coron Arpt.",
    cityCode: "XCN",
    cityName: "Coron",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "XCO",
    airportName: "Colac",
    cityCode: "XCO",
    cityName: "Colac",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XCR",
    airportName: "Vatry Intl.",
    cityCode: "XCR",
    cityName: "Chalons Sur Marne",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XDB",
    airportName: "Lille Europe Rail Svc",
    cityCode: "XDB",
    cityName: "Lille",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XDE",
    airportName: "Diebougou",
    cityCode: "XDE",
    cityName: "Diebougou",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XDJ",
    airportName: "Djibo",
    cityCode: "XDJ",
    cityName: "Djibo",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XDV",
    airportName: "Pr. George Rail Stn",
    cityCode: "XDV",
    cityName: "Prince George",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XDW",
    airportName: "Pr. Rupert Rail Stn",
    cityCode: "XDW",
    cityName: "Prince Rupert",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XDY",
    airportName: "Sudbury Jct Rail Stn",
    cityCode: "XDY",
    cityName: "Sudbury",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XEA",
    airportName: "Vancouver Rail Stn.",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XEN",
    airportName: "Xingcheng",
    cityCode: "XEN",
    cityName: "Xingcheng",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XES",
    airportName: "Municipal",
    cityCode: "XES",
    cityName: "Lake Geneva",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XEZ",
    airportName: "Sodertalje S Railway",
    cityCode: "XEZ",
    cityName: "Sodertalje",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XFA",
    airportName: "Lille Flanders Rail Sv",
    cityCode: "XFA",
    cityName: "Lille",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XFN",
    airportName: "Xiangfan",
    cityCode: "XFN",
    cityName: "Xiangfan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XFR",
    airportName: "Malmo South Railway",
    cityCode: "XFR",
    cityName: "Malmo",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XFW",
    airportName: "Finkenwerder",
    cityCode: "XFW",
    cityName: "Hamburg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "XFY",
    airportName: "Sainte-Foy Rail Svce.",
    cityCode: "XFY",
    cityName: "Quebec",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XFZ",
    airportName: "Charny",
    cityCode: "XFZ",
    cityName: "Quebec",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XGA",
    airportName: "Gaoua",
    cityCode: "XGA",
    cityName: "Gaoua",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XGG",
    airportName: "Gorom-Gorom",
    cityCode: "XGG",
    cityName: "Gorom-Gorom",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XGL",
    airportName: "Granville Lake",
    cityCode: "XGL",
    cityName: "Granville Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XGN",
    airportName: "Xangongo",
    cityCode: "XGN",
    cityName: "Xangongo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "XGR",
    airportName: "Kangiqsualujjuaq",
    cityCode: "XGR",
    cityName: "Kangiqsualujjuaq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XGZ",
    airportName: "Railway Station",
    cityCode: "XGZ",
    cityName: "Bregenz",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "XHN",
    airportName: "Guillemins, Raiway Stn",
    cityCode: "XHN",
    cityName: "Liege",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "XHW",
    airportName: "Alice Springs Railway",
    cityCode: "XHW",
    cityName: "Alice Springs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XHX",
    airportName: "Adelaide Keswick Rail",
    cityCode: "XHX",
    cityName: "Adelaide",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XIC",
    airportName: "Xichang",
    cityCode: "XIC",
    cityName: "Xichang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XIE",
    airportName: "Xienglom",
    cityCode: "XIE",
    cityName: "Xienglom",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "XIH",
    airportName: "Khan Al Baghdadi",
    cityCode: "XIH",
    cityName: "Khan Al Baghdadi",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "XIJ",
    airportName: "Ahmed Al Jaber",
    cityCode: "XIJ",
    cityName: "Ahmed Al Jaber",
    countryName: "Kuwait",
    countryCode: "KW",
  },
  {
    airportCode: "XIK",
    airportName: "Central Station",
    cityCode: "XIK",
    cityName: "Milan",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "XIL",
    airportName: "Xilinhot",
    cityCode: "XIL",
    cityName: "Xilinhot",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XIN",
    airportName: "Xingning",
    cityCode: "XIN",
    cityName: "Xingning",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XIQ",
    airportName: "Harbour",
    cityCode: "XIQ",
    cityName: "Ilimanaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "XIX",
    airportName: "Porta Nuova Rail",
    cityCode: "XIX",
    cityName: "Verona",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "XIY",
    airportName: "Xianyang",
    cityCode: "XIY",
    cityName: "Xi An",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XJD",
    airportName: "Al Udeid AB",
    cityCode: "XJD",
    cityName: "Al Udeid",
    countryName: "Qatar",
    countryCode: "QA",
  },
  {
    airportCode: "XJE",
    airportName: "Rialway Service",
    cityCode: "XJE",
    cityName: "Vojens",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "XJG",
    airportName: "Darwin Railway",
    cityCode: "XJG",
    cityName: "Darwin",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XJM",
    airportName: "Mangla",
    cityCode: "XJM",
    cityName: "Mangla",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "XJS",
    airportName: "Kitzbuehl Railway",
    cityCode: "XJS",
    cityName: "Kitzbuehl",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "XKA",
    airportName: "Kantchari",
    cityCode: "XKA",
    cityName: "Kantchari",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XKC",
    airportName: "Sandnes Rail St.",
    cityCode: "XKC",
    cityName: "Sandnes",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "XKH",
    airportName: "Xieng Khouang",
    cityCode: "XKH",
    cityName: "Xieng Khouang",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "XKO",
    airportName: "Kemano",
    cityCode: "XKO",
    cityName: "Kemano",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XKS",
    airportName: "Kasabonika",
    cityCode: "XKS",
    cityName: "Kasabonika",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XKY",
    airportName: "Kaya",
    cityCode: "XKY",
    cityName: "Kaya",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "NNT",
    airportName: "Nan",
    cityCode: "NNT",
    cityName: "Nan",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "NNU",
    airportName: "Nanuque",
    cityCode: "NNU",
    cityName: "Nanuque",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "NNX",
    airportName: "Nunukan",
    cityCode: "NNX",
    cityName: "Nunukan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NNY",
    airportName: "Nanyang",
    cityCode: "NNY",
    cityName: "Nanyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NOA",
    airportName: "Nowra",
    cityCode: "NOA",
    cityName: "Nowra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NOB",
    airportName: "Nosara Beach",
    cityCode: "NOB",
    cityName: "Nosara Beach",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "NOC",
    airportName: "Ireland West Arpt. Knock",
    cityCode: "NOC",
    cityName: "Knock",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "NOD",
    airportName: "Norden",
    cityCode: "NOD",
    cityName: "Norden",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "NOE",
    airportName: "Norddeich",
    cityCode: "NOE",
    cityName: "Norddeich",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "NOG",
    airportName: "Nogales",
    cityCode: "NOG",
    cityName: "Nogales",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "NOH",
    airportName: "Chicago NAS",
    cityCode: "NOH",
    cityName: "Chicago",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NOI",
    airportName: "Novorossijsk",
    cityCode: "NOI",
    cityName: "Novorossijsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NOK",
    airportName: "Nova Xavantina",
    cityCode: "NOK",
    cityName: "Nova Xavantina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "NOL",
    airportName: "Nakolik River",
    cityCode: "NOL",
    cityName: "Nakolik River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NOM",
    airportName: "Nomad River",
    cityCode: "NOM",
    cityName: "Nomad River",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NON",
    airportName: "Nonouti",
    cityCode: "NON",
    cityName: "Rotima",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "NOO",
    airportName: "Naoro",
    cityCode: "NOO",
    cityName: "Naoro",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NOR",
    airportName: "Nordfjordur",
    cityCode: "NOR",
    cityName: "Nordfjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "NOS",
    airportName: "Fascene",
    cityCode: "NOS",
    cityName: "Nossi-Be",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "NOT",
    airportName: "Novato",
    cityCode: "NOT",
    cityName: "Novato",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NOU",
    airportName: "Tontouta",
    cityCode: "NOU",
    cityName: "Noumea",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "NOV",
    airportName: "Huambo",
    cityCode: "NOV",
    cityName: "Huambo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "NOZ",
    airportName: "Novokuznetsk",
    cityCode: "NOZ",
    cityName: "Novokuznetsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NPE",
    airportName: "Hawkes Bay",
    cityCode: "NPE",
    cityName: "Napier/Hastings",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "NPG",
    airportName: "Nipa",
    cityCode: "NPG",
    cityName: "Nipa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NPH",
    airportName: "Nephi",
    cityCode: "NPH",
    cityName: "Nephi",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NPL",
    airportName: "New Plymouth",
    cityCode: "NPL",
    cityName: "New Plymouth",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "NPO",
    airportName: "Nangapinoh",
    cityCode: "NPO",
    cityName: "Nangapinoh",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NPP",
    airportName: "Napperby",
    cityCode: "NPP",
    cityName: "Napperby",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NPT",
    airportName: "State",
    cityCode: "NPT",
    cityName: "Newport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NPU",
    airportName: "San Pedro Uraba",
    cityCode: "NPU",
    cityName: "San Pedro Uraba",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "NQA",
    airportName: "Millington Regional Jetport",
    cityCode: "NQA",
    cityName: "Millington, TN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NQI",
    airportName: "NAS",
    cityCode: "NQI",
    cityName: "Kingsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NQL",
    airportName: "Niquelandia",
    cityCode: "NQL",
    cityName: "Niquelandia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "NQN",
    airportName: "Neuquen",
    cityCode: "NQN",
    cityName: "Neuquen",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "NQT",
    airportName: "Nottingham Arpt.",
    cityCode: "NQT",
    cityName: "Nottingham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "NQU",
    airportName: "Nuqui",
    cityCode: "NQU",
    cityName: "Nuqui",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "NQY",
    airportName: "Newquay Cornwall Arpt.",
    cityCode: "NQY",
    cityName: "Newquay",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "NRA",
    airportName: "Narrandera",
    cityCode: "NRA",
    cityName: "Narrandera",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NRB",
    airportName: "Ns",
    cityCode: "NRB",
    cityName: "Mayport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NRC",
    airportName: "Aux Field",
    cityCode: "NRC",
    cityName: "Crows Landing",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NRD",
    airportName: "Norderney",
    cityCode: "NRD",
    cityName: "Norderney",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "NRG",
    airportName: "Narrogin",
    cityCode: "NRG",
    cityName: "Narrogin",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NRI",
    airportName: "Shangri-la",
    cityCode: "NRI",
    cityName: "Shangri-la",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NRK",
    airportName: "Kungsangen",
    cityCode: "NRK",
    cityName: "Norrkoping",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "NRL",
    airportName: "North Ronaldsay",
    cityCode: "NRL",
    cityName: "North Ronaldsay",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "NRM",
    airportName: "Nara",
    cityCode: "NRM",
    cityName: "Nara",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "NRN",
    airportName: "Weeze",
    cityCode: "NRN",
    cityName: "Dusseldorf",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "NRR",
    airportName: "Roosevelt NAS",
    cityCode: "NRR",
    cityName: "Roosevelt Roads",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "NRS",
    airportName: "NAS",
    cityCode: "NRS",
    cityName: "Imperial Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NRT",
    airportName: "New Tokyo Intl. Arpt.",
    cityCode: "TYO",
    cityName: "Tokyo",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "NRV",
    airportName: "USCG Shore Station",
    cityCode: "NRV",
    cityName: "Guam",
    countryName: "Guam",
    countryCode: "GU",
  },
  {
    airportCode: "NRY",
    airportName: "Newry",
    cityCode: "NRY",
    cityName: "Newry",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NSA",
    airportName: "Noosa",
    cityCode: "NSA",
    cityName: "Noosa",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NSB",
    airportName: "North SPB",
    cityCode: "NSB",
    cityName: "Bimini",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "NSE",
    airportName: "Whiting Field NAS",
    cityCode: "NSE",
    cityName: "Milton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NSF",
    airportName: "Andrews NAF",
    cityCode: "NSF",
    cityName: "Camp Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NSH",
    airportName: "Now Shahr",
    cityCode: "NSH",
    cityName: "Now Shahr",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "NSI",
    airportName: "Nsimalen Intl.",
    cityCode: "NSI",
    cityName: "Yaounde",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "NSM",
    airportName: "Norseman",
    cityCode: "NSM",
    cityName: "Norseman",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NSN",
    airportName: "Nelson",
    cityCode: "NSN",
    cityName: "Nelson",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "NSO",
    airportName: "Scone",
    cityCode: "NSO",
    cityName: "Scone",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NSP",
    airportName: "NAF",
    cityCode: "NSP",
    cityName: "Sangley Point",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "NST",
    airportName: "Nakhon Si Thammarat",
    cityCode: "NST",
    cityName: "Nakhon Si Thammarat",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "NSV",
    airportName: "Noosaville",
    cityCode: "NSV",
    cityName: "Noosaville",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NSX",
    airportName: "Hovercraft/Launch Pt",
    cityCode: "NSX",
    cityName: "N. Sound/Virgin Gorda",
    countryName: "Virgin Islands",
    countryCode: "VG",
  },
  {
    airportCode: "NSY",
    airportName: "Sigonella NAF",
    cityCode: "NSY",
    cityName: "Tokat",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "NTA",
    airportName: "Natadola",
    cityCode: "NTA",
    cityName: "Natadola",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "NTB",
    airportName: "Notodden",
    cityCode: "NTB",
    cityName: "Notodden",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "NTC",
    airportName: "Santa Carolina",
    cityCode: "NTC",
    cityName: "Santa Carolina",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "NTD",
    airportName: "Point Mugu NAS",
    cityCode: "NTD",
    cityName: "Port Hueneme, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NTE",
    airportName: "Nantes Atlantique",
    cityCode: "NTE",
    cityName: "Nantes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "NTG",
    airportName: "Nantong",
    cityCode: "NTG",
    cityName: "Nantong",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NTI",
    airportName: "Bintuni",
    cityCode: "NTI",
    cityName: "Bintuni",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NTJ",
    airportName: "Manti-Ephraim",
    cityCode: "NTJ",
    cityName: "Manti",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NTL",
    airportName: "Williamtown",
    cityCode: "NTL",
    cityName: "Newcastle",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NTM",
    airportName: "Miracema Do Norte",
    cityCode: "NTM",
    cityName: "Miracema Do Norte",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "NTN",
    airportName: "Normanton",
    cityCode: "NTN",
    cityName: "Normanton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NTO",
    airportName: "Santo Antao",
    cityCode: "NTO",
    cityName: "Santo Antao",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "NTQ",
    airportName: "Noto Arpt.",
    cityCode: "NTQ",
    cityName: "Wajima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "NTR",
    airportName: "Tuxtla Gutierrez",
    cityCode: "NTR",
    cityName: "Tuxtla Gutierrez",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "NTT",
    airportName: "Kuini Lavenia",
    cityCode: "NTT",
    cityName: "Niuatoputapu",
    countryName: "Tonga",
    countryCode: "TO",
  },
  {
    airportCode: "NTU",
    airportName: "NAS",
    cityCode: "NTU",
    cityName: "Oceana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NTX",
    airportName: "Natuna Ranai",
    cityCode: "NTX",
    cityName: "Natuna Ranai",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NTY",
    airportName: "Pilanesberg",
    cityCode: "NTY",
    cityName: "Sun City",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "NUB",
    airportName: "Numbulwar",
    cityCode: "NUB",
    cityName: "Numbulwar",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NUD",
    airportName: "En Nahud",
    cityCode: "NUD",
    cityName: "En Nahud",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "NUE",
    airportName: "Nuremberg",
    cityCode: "NUE",
    cityName: "Nuremberg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "NUF",
    airportName: "Castlereigh Reservoir",
    cityCode: "NUF",
    cityName: "Hatton",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "NUG",
    airportName: "Nuguria",
    cityCode: "NUG",
    cityName: "Nuguria",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NUH",
    airportName: "Nunchia",
    cityCode: "NUH",
    cityName: "Nunchia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "NUI",
    airportName: "Nuiqsut",
    cityCode: "NUI",
    cityName: "Nuiqsut",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NUJ",
    airportName: "Nojeh",
    cityCode: "NUJ",
    cityName: "Nojeh",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "NUK",
    airportName: "Nukutavake",
    cityCode: "NUK",
    cityName: "Nukutavake",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "NUL",
    airportName: "Nulato",
    cityCode: "NUL",
    cityName: "Nulato",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NUP",
    airportName: "Nunapitchuk",
    cityCode: "NUP",
    cityName: "Nunapitchuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NUQ",
    airportName: "Moffett Field",
    cityCode: "NUQ",
    cityName: "Mountain View",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NUR",
    airportName: "Nullarbor",
    cityCode: "NUR",
    cityName: "Nullarbor",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NUS",
    airportName: "Norsup",
    cityCode: "NUS",
    cityName: "Norsup",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "NUT",
    airportName: "Nutuve",
    cityCode: "NUT",
    cityName: "Nutuve",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NUU",
    airportName: "Nakuru",
    cityCode: "NUU",
    cityName: "Nakuru",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "NUW",
    airportName: "Whidbey Island NAS",
    cityCode: "NUW",
    cityName: "Oak Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NUX",
    airportName: "Novy Urengoy",
    cityCode: "NUX",
    cityName: "Novy Urengoy",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NVA",
    airportName: "La Marguita",
    cityCode: "NVA",
    cityName: "Neiva",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "NVD",
    airportName: "Nevada",
    cityCode: "NVD",
    cityName: "Nevada",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NVG",
    airportName: "Nueva Guinea",
    cityCode: "NVG",
    cityName: "Nueva Guinea",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "NVI",
    airportName: "Navoi",
    cityCode: "NVI",
    cityName: "Navoi",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "NVK",
    airportName: "Framnes",
    cityCode: "NVK",
    cityName: "Narvik",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "NVP",
    airportName: "Novo Aripuana",
    cityCode: "NVP",
    cityName: "Aripuana",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "NVR",
    airportName: "Yerievo",
    cityCode: "NVR",
    cityName: "Velikiy Novgorod",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NVS",
    airportName: "Nevers",
    cityCode: "NVS",
    cityName: "Nevers",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "NVT",
    airportName: "Navegantes",
    cityCode: "NVT",
    cityName: "Navegantes",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "NVY",
    airportName: "Neyveli",
    cityCode: "NVY",
    cityName: "Neyveli",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "NWA",
    airportName: "Moheli",
    cityCode: "NWA",
    cityName: "Moheli",
    countryName: "Comoros",
    countryCode: "KM",
  },
  {
    airportCode: "NWH",
    airportName: "Parlin Field",
    cityCode: "NWH",
    cityName: "Newport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NWI",
    airportName: "Norwich Intl. Arpt.",
    cityCode: "NWI",
    cityName: "Norwich",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "NWP",
    airportName: "NS",
    cityCode: "NWP",
    cityName: "Argentia",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "NWS",
    airportName: "Pier 11/Wall St. SPB",
    cityCode: "NWS",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NWT",
    airportName: "Nowata",
    cityCode: "NWT",
    cityName: "Nowata",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NWU",
    airportName: "NAS",
    cityCode: "NWU",
    cityName: "Bermuda",
    countryName: "Bermuda",
    countryCode: "BM",
  },
  {
    airportCode: "NXX",
    airportName: "Willow Grove NAS",
    cityCode: "NXX",
    cityName: "Willow Grove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NYE",
    airportName: "Nyeri",
    cityCode: "NYE",
    cityName: "Nyeri",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "NYG",
    airportName: "Quantico Nas",
    cityCode: "NYG",
    cityName: "Quantico",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NYI",
    airportName: "Sunyani",
    cityCode: "NYI",
    cityName: "Sunyani",
    countryName: "Ghana",
    countryCode: "GH",
  },
  {
    airportCode: "NYK",
    airportName: "Nanyuki",
    cityCode: "NYK",
    cityName: "Nanyuki",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "NYM",
    airportName: "Nadym",
    cityCode: "NYM",
    cityName: "Nadym",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NYN",
    airportName: "Nyngan",
    cityCode: "NYN",
    cityName: "Nyngan",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NYO",
    airportName: "Skavsta",
    cityCode: "STO",
    cityName: "Stockholm",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "NYU",
    airportName: "Nyaung-u",
    cityCode: "NYU",
    cityName: "Nyaung-u",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "NZE",
    airportName: "Nzerekore",
    cityCode: "NZE",
    cityName: "Nzerekore",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "NZH",
    airportName: "Manzhouli",
    cityCode: "NZH",
    cityName: "Manzhouli",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NZO",
    airportName: "Nzoia",
    cityCode: "NZO",
    cityName: "Nzoia",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "NZW",
    airportName: "South Weymouth",
    cityCode: "NZW",
    cityName: "South Weymouth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NZY",
    airportName: "North Island Nas",
    cityCode: "NZY",
    cityName: "San Diego",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OAG",
    airportName: "Springhill",
    cityCode: "OAG",
    cityName: "Orange",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OAJ",
    airportName: "Albert J Ellis",
    cityCode: "OAJ",
    cityName: "Jacksonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OAK",
    airportName: "Oakland Intl. Arpt.",
    cityCode: "SFO",
    cityName: "San Francisco",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OAL",
    airportName: "Cacoal",
    cityCode: "OAL",
    cityName: "Cacoal",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "OAM",
    airportName: "Oamaru",
    cityCode: "OAM",
    cityName: "Oamaru",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "OAN",
    airportName: "Olanchito",
    cityCode: "OAN",
    cityName: "Olanchito",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "OAR",
    airportName: "Marina Municipal",
    cityCode: "OAR",
    cityName: "Marina, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OAX",
    airportName: "Xoxocotlan",
    cityCode: "OAX",
    cityName: "Oaxaca",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "OBA",
    airportName: "Oban",
    cityCode: "OBA",
    cityName: "Oban",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OBC",
    airportName: "Obock",
    cityCode: "OBC",
    cityName: "Obock",
    countryName: "Djibouti",
    countryCode: "DJ",
  },
  {
    airportCode: "OBD",
    airportName: "Obano",
    cityCode: "OBD",
    cityName: "Obano",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "OBE",
    airportName: "County",
    cityCode: "OBE",
    cityName: "Okeechobee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OBF",
    airportName: "Oberpfaffenhofen",
    cityCode: "OBF",
    cityName: "Oberpfaffenhofen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "OBI",
    airportName: "Obidos",
    cityCode: "OBI",
    cityName: "Obidos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "OBM",
    airportName: "Morobe",
    cityCode: "OBM",
    cityName: "Morobe",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OBN",
    airportName: "Oban Arpt.",
    cityCode: "OBN",
    cityName: "Oban",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "OBO",
    airportName: "Obihiro",
    cityCode: "OBO",
    cityName: "Obihiro",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OBS",
    airportName: "Vals-Lanas",
    cityCode: "OBS",
    cityName: "Aubenas",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "OBT",
    airportName: "Oakland/Coliseum Stat",
    cityCode: "OBT",
    cityName: "Oakland, MD",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OBU",
    airportName: "Kobuk/Wien",
    cityCode: "OBU",
    cityName: "Kobuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OBX",
    airportName: "Obo",
    cityCode: "OBX",
    cityName: "Obo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OBY",
    airportName: "Ittoqqortoormiit",
    cityCode: "OBY",
    cityName: "Ittoqqortoormiit",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "OCA",
    airportName: "Ocean Reef",
    cityCode: "OCA",
    cityName: "Ocean Reef",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OCC",
    airportName: "Coca",
    cityCode: "OCC",
    cityName: "Coca",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "OCE",
    airportName: "Municipal",
    cityCode: "OCE",
    cityName: "Ocean City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OCF",
    airportName: "Taylor Field",
    cityCode: "OCF",
    cityName: "Ocala",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OCH",
    airportName: "A L Mangham Jr. Regional",
    cityCode: "OCH",
    cityName: "Nacogdoches, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OCI",
    airportName: "Oceanic",
    cityCode: "OCI",
    cityName: "Oceanic",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OCJ",
    airportName: "Boscobel",
    cityCode: "OCJ",
    cityName: "Ocho Rios",
    countryName: "Jamaica",
    countryCode: "JM",
  },
  {
    airportCode: "OCN",
    airportName: "Municipal",
    cityCode: "OCN",
    cityName: "Oceanside",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OCV",
    airportName: "Aguasclaras",
    cityCode: "OCV",
    cityName: "Ocana",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "OCW",
    airportName: "Warren Field",
    cityCode: "OCW",
    cityName: "Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ODA",
    airportName: "Ouadda",
    cityCode: "ODA",
    cityName: "Ouadda",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "ODB",
    airportName: "Cordoba",
    cityCode: "ODB",
    cityName: "Cordoba",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "ODD",
    airportName: "Oodnadatta",
    cityCode: "ODD",
    cityName: "Oodnadatta",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ODE",
    airportName: "Beldringe",
    cityCode: "ODE",
    cityName: "Odense",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "ODH",
    airportName: "RAF Station",
    cityCode: "ODH",
    cityName: "Odiham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ODJ",
    airportName: "Ouanda Djalle",
    cityCode: "ODJ",
    cityName: "Ouanda Djalle",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "ODL",
    airportName: "Cordillo Downs",
    cityCode: "ODL",
    cityName: "Cordillo Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ODN",
    airportName: "Long Seridan",
    cityCode: "ODN",
    cityName: "Long Seridan",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "ODR",
    airportName: "Ord River",
    cityCode: "ODR",
    cityName: "Ord River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ODS",
    airportName: "Odessa Intl.",
    cityCode: "ODS",
    cityName: "Odessa",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "ODW",
    airportName: "Oak Harbor",
    cityCode: "ODW",
    cityName: "Oak Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ODY",
    airportName: "Oudomxai",
    cityCode: "ODY",
    cityName: "Oudomxay",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "OEA",
    airportName: "Oneal",
    cityCode: "OEA",
    cityName: "Vincennes",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OEC",
    airportName: "Ocussi",
    cityCode: "OEC",
    cityName: "Ocussi",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "OEL",
    airportName: "Orel",
    cityCode: "OEL",
    cityName: "Orel",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "OEO",
    airportName: "Municipal",
    cityCode: "OEO",
    cityName: "Osceola",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OER",
    airportName: "Ornskoldsvik",
    cityCode: "OER",
    cityName: "Ornskoldsvik",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "OES",
    airportName: "San Antonio Oeste",
    cityCode: "OES",
    cityName: "San Antonio Oeste",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "OFI",
    airportName: "Ouango Fitini",
    cityCode: "OFI",
    cityName: "Ouango Fitini",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "OFJ",
    airportName: "Olafsfjordur",
    cityCode: "OFJ",
    cityName: "Olafsfjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "OFK",
    airportName: "Stefan Field",
    cityCode: "OFK",
    cityName: "Norfolk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OFU",
    airportName: "Ofu",
    cityCode: "OFU",
    cityName: "Ofu",
    countryName: "American Samoa",
    countryCode: "AS",
  },
  {
    airportCode: "OGA",
    airportName: "Searle Field",
    cityCode: "OGA",
    cityName: "Ogallala",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OGB",
    airportName: "Municipal",
    cityCode: "OGB",
    cityName: "Orangeburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OGD",
    airportName: "Municipal",
    cityCode: "OGD",
    cityName: "Ogden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OGE",
    airportName: "Ogeranang",
    cityCode: "OGE",
    cityName: "Ogeranang",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OGG",
    airportName: "Kahului",
    cityCode: "OGG",
    cityName: "Maui, HI",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OGL",
    airportName: "Ogle",
    cityCode: "OGL",
    cityName: "Ogle",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "OGN",
    airportName: "Yonaguni Jima",
    cityCode: "OGN",
    cityName: "Yonaguni Jima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OGO",
    airportName: "Abengourou",
    cityCode: "OGO",
    cityName: "Abengourou",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "OGR",
    airportName: "Bongor",
    cityCode: "OGR",
    cityName: "Bongor",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "OGS",
    airportName: "Ogdensburg",
    cityCode: "OGS",
    cityName: "Ogdensburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OGV",
    airportName: "Ongava Game Reserve",
    cityCode: "OGV",
    cityName: "Ongava Game Reserve",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "OGX",
    airportName: "Ain Beida",
    cityCode: "OGX",
    cityName: "Ouargla",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "OGZ",
    airportName: "Vladikavkaz",
    cityCode: "OGZ",
    cityName: "Vladikavkaz",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "OHA",
    airportName: "Royal Air Force Base",
    cityCode: "OHA",
    cityName: "Ohakea",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "OHC",
    airportName: "AFS",
    cityCode: "OHC",
    cityName: "Northeast Cape",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OHD",
    airportName: "Ohrid",
    cityCode: "OHD",
    cityName: "Ohrid",
    countryName: "Macedonia",
    countryCode: "MK",
  },
  {
    airportCode: "OHI",
    airportName: "Oshakati",
    cityCode: "OHI",
    cityName: "Oshakati",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "OHO",
    airportName: "Okhotsk",
    cityCode: "OHO",
    cityName: "Okhotsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "OHP",
    airportName: "Heliport",
    cityCode: "OHP",
    cityName: "Oban",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "OHR",
    airportName: "Wyk Auf Foehr",
    cityCode: "OHR",
    cityName: "Wyk Auf Foehr",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "OHT",
    airportName: "Kohat",
    cityCode: "OHT",
    cityName: "Kohat",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "OIA",
    airportName: "Ourilandia",
    cityCode: "OIA",
    cityName: "Ourilandia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "OIC",
    airportName: "Eaton",
    cityCode: "OIC",
    cityName: "Norwich",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OIL",
    airportName: "Splane Memorial",
    cityCode: "OIL",
    cityName: "Oil City, PA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OIM",
    airportName: "Oshima",
    cityCode: "OIM",
    cityName: "Oshima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OIR",
    airportName: "Okushiri",
    cityCode: "OIR",
    cityName: "Okushiri",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OIT",
    airportName: "Oita",
    cityCode: "OIT",
    cityName: "Oita",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OJC",
    airportName: "Johnson Executive",
    cityCode: "OJC",
    cityName: "Kansas City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OKA",
    airportName: "Naha",
    cityCode: "OKA",
    cityName: "Okinawa",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OKB",
    airportName: "Fraser Island",
    cityCode: "OKB",
    cityName: "Orchid Beach",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OKC",
    airportName: "Will Rogers World Arpt.",
    cityCode: "OKC",
    cityName: "Oklahoma City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OKD",
    airportName: "Okadama",
    cityCode: "SPK",
    cityName: "Sapporo",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OKE",
    airportName: "Okino Erabu",
    cityCode: "OKE",
    cityName: "Okino Erabu",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OKF",
    airportName: "Okaukuejo",
    cityCode: "OKF",
    cityName: "Okaukuejo",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "OKG",
    airportName: "Okoyo",
    cityCode: "OKG",
    cityName: "Okoyo",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "OKH",
    airportName: "Cottesmor RAF",
    cityCode: "OKH",
    cityName: "Oakham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "OKI",
    airportName: "Oki Island",
    cityCode: "OKI",
    cityName: "Oki Island",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OKJ",
    airportName: "Okayama",
    cityCode: "OKJ",
    cityName: "Okayama",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OKK",
    airportName: "Kokomo",
    cityCode: "OKK",
    cityName: "Kokomo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OKL",
    airportName: "Oksibil",
    cityCode: "OKL",
    cityName: "Oksibil",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "OKM",
    airportName: "Okmulgee",
    cityCode: "OKM",
    cityName: "Okmulgee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OKN",
    airportName: "Okondja",
    cityCode: "OKN",
    cityName: "Okondja",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "OKO",
    airportName: "Yokota Afb",
    cityCode: "TYO",
    cityName: "Tokyo",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OKP",
    airportName: "Oksapmin",
    cityCode: "OKP",
    cityName: "Oksapmin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OKQ",
    airportName: "Okaba",
    cityCode: "OKQ",
    cityName: "Okaba",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "OKR",
    airportName: "Yorke Island",
    cityCode: "OKR",
    cityName: "Yorke Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OKS",
    airportName: "Oshkosh",
    cityCode: "OKS",
    cityName: "Oshkosh",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OKT",
    airportName: "Oktiabrskij",
    cityCode: "OKT",
    cityName: "Oktiabrskij",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "OKU",
    airportName: "Mokuti Lodge",
    cityCode: "OKU",
    cityName: "Mokuti Lodge",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "OKV",
    airportName: "Okao",
    cityCode: "OKV",
    cityName: "Okao",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OKY",
    airportName: "Oakey",
    cityCode: "OKY",
    cityName: "Oakey",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OLA",
    airportName: "Orland",
    cityCode: "OLA",
    cityName: "Orland",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "OLB",
    airportName: "Costa Smeralda",
    cityCode: "OLB",
    cityName: "Olbia",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "OLD",
    airportName: "Old Town",
    cityCode: "OLD",
    cityName: "Old Town",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OLE",
    airportName: "Olean Airport",
    cityCode: "OLE",
    cityName: "Olean",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OLF",
    airportName: "Intl.",
    cityCode: "OLF",
    cityName: "Wolf Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OLH",
    airportName: "Old Harbor SPB",
    cityCode: "OLH",
    cityName: "Old Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OLI",
    airportName: "Rif",
    cityCode: "OLI",
    cityName: "Olafsvik",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "OLJ",
    airportName: "Olpoi",
    cityCode: "OLJ",
    cityName: "Olpoi",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "OLK",
    airportName: "Fuerte Olimpo",
    cityCode: "OLK",
    cityName: "Fuerte Olimpo",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "OLM",
    airportName: "Olympia",
    cityCode: "OLM",
    cityName: "Olympia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OLN",
    airportName: "Colonia Sarmiento",
    cityCode: "OLN",
    cityName: "Colonia Sarmiento",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "OLO",
    airportName: "Olomouc",
    cityCode: "OLO",
    cityName: "Olomouc",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "OLP",
    airportName: "Olympic Dam",
    cityCode: "OLP",
    cityName: "Olympic Dam",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OLQ",
    airportName: "Olsobip",
    cityCode: "OLQ",
    cityName: "Olsobip",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OLS",
    airportName: "Intl.",
    cityCode: "OLS",
    cityName: "Nogales",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OLU",
    airportName: "Columbus Municipal",
    cityCode: "OLU",
    cityName: "Columbus, NE",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OLV",
    airportName: "Olive Branch Arpt",
    cityCode: "OLV",
    cityName: "Olive Branch",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OLY",
    airportName: "Olney-Noble",
    cityCode: "OLY",
    cityName: "Olney",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OMA",
    airportName: "Eppley Airfield",
    cityCode: "OMA",
    cityName: "Omaha",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OMB",
    airportName: "Omboue",
    cityCode: "OMB",
    cityName: "Omboue",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "OMC",
    airportName: "Ormoc",
    cityCode: "OMC",
    cityName: "Ormoc",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "OMD",
    airportName: "Oranjemund",
    cityCode: "OMD",
    cityName: "Oranjemund",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "OME",
    airportName: "Nome",
    cityCode: "OME",
    cityName: "Nome",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OMF",
    airportName: "King Hussein",
    cityCode: "OMF",
    cityName: "Mafraq",
    countryName: "Jordan",
    countryCode: "JO",
  },
  {
    airportCode: "OMG",
    airportName: "Omega",
    cityCode: "OMG",
    cityName: "Omega",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "OMH",
    airportName: "Urmieh",
    cityCode: "OMH",
    cityName: "Urmieh",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "OMI",
    airportName: "Omidieh",
    cityCode: "OMI",
    cityName: "Omidieh",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "OMJ",
    airportName: "Omura",
    cityCode: "OMJ",
    cityName: "Omura",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "OMK",
    airportName: "Municipal",
    cityCode: "OMK",
    cityName: "Omak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OML",
    airportName: "Omkalai",
    cityCode: "OML",
    cityName: "Omkalai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OMM",
    airportName: "Marmul",
    cityCode: "OMM",
    cityName: "Marmul",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "OMN",
    airportName: "Osmanabad",
    cityCode: "OMN",
    cityName: "Osmanabad",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "OMO",
    airportName: "Mostar",
    cityCode: "OMO",
    cityName: "Mostar",
    countryName: "Bosnia And Herzegovina",
    countryCode: "BA",
  },
  {
    airportCode: "OMR",
    airportName: "Oradea",
    cityCode: "OMR",
    cityName: "Oradea",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "OMS",
    airportName: "Omsk",
    cityCode: "OMS",
    cityName: "Omsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "OMY",
    airportName: "Oddor Meanche",
    cityCode: "OMY",
    cityName: "Oddor Meanche",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "ONA",
    airportName: "Municipal",
    cityCode: "ONA",
    cityName: "Winona",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ONB",
    airportName: "Ononge",
    cityCode: "ONB",
    cityName: "Ononge",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OND",
    airportName: "Ondangwa",
    cityCode: "OND",
    cityName: "Ondangwa",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "ONE",
    airportName: "Onepusu",
    cityCode: "ONE",
    cityName: "Onepusu",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "ONG",
    airportName: "Mornington",
    cityCode: "ONG",
    cityName: "Mornington",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ONH",
    airportName: "Albert S. Nader Regional Airport",
    cityCode: "ONH",
    cityName: "Oneonta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ONI",
    airportName: "Moanamani",
    cityCode: "ONI",
    cityName: "Moanamani",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ONJ",
    airportName: "Odate Noshiro",
    cityCode: "ONJ",
    cityName: "Odate Noshiro",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "ONL",
    airportName: "Municipal",
    cityCode: "ONL",
    cityName: "Oneill",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ONM",
    airportName: "Socorro",
    cityCode: "ONM",
    cityName: "Socorro",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ONN",
    airportName: "Onion Bay",
    cityCode: "ONN",
    cityName: "Onion Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ONO",
    airportName: "Ontario Municipal Arpt.",
    cityCode: "ONO",
    cityName: "Ontario",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ONP",
    airportName: "Newport",
    cityCode: "ONP",
    cityName: "Newport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ONQ",
    airportName: "Zonguldak",
    cityCode: "ONQ",
    cityName: "Zonguldak",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ONR",
    airportName: "Monkira",
    cityCode: "ONR",
    cityName: "Monkira",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ONS",
    airportName: "Onslow",
    cityCode: "ONS",
    cityName: "Onslow",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ONT",
    airportName: "Ontario Intl.",
    cityCode: "LAX",
    cityName: "Los Angeles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ONU",
    airportName: "Ono I Lau",
    cityCode: "ONU",
    cityName: "Ono I Lau",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "ONX",
    airportName: "Enrique Adolfo Jimenez",
    cityCode: "ONX",
    cityName: "Colon",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "ONY",
    airportName: "Olney",
    cityCode: "ONY",
    cityName: "Olney",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OOA",
    airportName: "Municipal",
    cityCode: "OOA",
    cityName: "Oskaloosa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OOL",
    airportName: "Gold Coast",
    cityCode: "OOL",
    cityName: "Gold Coast",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OOM",
    airportName: "Cooma",
    cityCode: "OOM",
    cityName: "Cooma",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OOR",
    airportName: "Mooraberree",
    cityCode: "OOR",
    cityName: "Mooraberree",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OOT",
    airportName: "Onotoa",
    cityCode: "OOT",
    cityName: "Onotoa",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "OPA",
    airportName: "Kopasker",
    cityCode: "OPA",
    cityName: "Kopasker",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "OPB",
    airportName: "Open Bay",
    cityCode: "OPB",
    cityName: "Open Bay",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OPF",
    airportName: "Opa Locka",
    cityCode: "OPF",
    cityName: "Miami",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OPI",
    airportName: "Oenpelli",
    cityCode: "OPI",
    cityName: "Oenpelli",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OPL",
    airportName: "St Landry Parish",
    cityCode: "OPL",
    cityName: "Opelousas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OPO",
    airportName: "Francisco SÃ¡ Carneiro",
    cityCode: "OPO",
    cityName: "Porto",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "OPS",
    airportName: "Sinop",
    cityCode: "OPS",
    cityName: "Sinop",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "OPU",
    airportName: "Balimo",
    cityCode: "OPU",
    cityName: "Balimo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OPW",
    airportName: "Opuwa",
    cityCode: "OPW",
    cityName: "Opuwa",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "ORA",
    airportName: "Oran",
    cityCode: "ORA",
    cityName: "Oran",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "ORB",
    airportName: "Orebro-bofors",
    cityCode: "ORB",
    cityName: "Orebro",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "ORC",
    airportName: "Orocue",
    cityCode: "ORC",
    cityName: "Orocue",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ORD",
    airportName: "Chicago O'hare Intl.",
    cityCode: "CHI",
    cityName: "Chicago",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ORE",
    airportName: "Orleans",
    cityCode: "ORE",
    cityName: "Orleans",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "ORF",
    airportName: "Norfolk Intl. Arpt.",
    cityCode: "ORF",
    cityName: "Norfolk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ORG",
    airportName: "Zorg En Hoop",
    cityCode: "ORG",
    cityName: "Paramaribo",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "ORH",
    airportName: "Worcester Regional Arpt.",
    cityCode: "ORH",
    cityName: "Worcester",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ORI",
    airportName: "Port Lions SPB",
    cityCode: "ORI",
    cityName: "Port Lions",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ORJ",
    airportName: "Orinduik",
    cityCode: "ORJ",
    cityName: "Orinduik",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "ORK",
    airportName: "Cork",
    cityCode: "ORK",
    cityName: "Cork",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "ORL",
    airportName: "Orlando Executive",
    cityCode: "ORL",
    cityName: "Orlando",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ORM",
    airportName: "Northampton",
    cityCode: "ORM",
    cityName: "Northampton",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ORN",
    airportName: "Es Senia",
    cityCode: "ORN",
    cityName: "Oran",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "ORO",
    airportName: "Yoro",
    cityCode: "ORO",
    cityName: "Yoro",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "ORP",
    airportName: "Orapa",
    cityCode: "ORP",
    cityName: "Orapa",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "ORQ",
    airportName: "Heliport",
    cityCode: "ORQ",
    cityName: "Norwalk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ORR",
    airportName: "Yorketown",
    cityCode: "ORR",
    cityName: "Yorketown",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ORS",
    airportName: "Waterport",
    cityCode: "ORS",
    cityName: "Orpheus Island Resort",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ORT",
    airportName: "Northway",
    cityCode: "ORT",
    cityName: "Northway",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ORU",
    airportName: "Oruro",
    cityCode: "ORU",
    cityName: "Oruro",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "ORV",
    airportName: "Curtis Memorial",
    cityCode: "ORV",
    cityName: "Noorvik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ORW",
    airportName: "Ormara",
    cityCode: "ORW",
    cityName: "Ormara",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "ORX",
    airportName: "Oriximina",
    cityCode: "ORX",
    cityName: "Oriximina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ORY",
    airportName: "Orly",
    cityCode: "PAR",
    cityName: "Paris",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "ORZ",
    airportName: "Orange Walk",
    cityCode: "ORZ",
    cityName: "Orange Walk",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "OSB",
    airportName: "Osage Beach",
    cityCode: "OSB",
    cityName: "Osage Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OSC",
    airportName: "Wurtsmith AFB",
    cityCode: "OSC",
    cityName: "Oscoda",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OSD",
    airportName: "Ostersund",
    cityCode: "OSD",
    cityName: "Ostersund",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "OSE",
    airportName: "Omora",
    cityCode: "OSE",
    cityName: "Omora",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OSG",
    airportName: "Ossima",
    cityCode: "OSG",
    cityName: "Ossima",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OSH",
    airportName: "Wittman Field",
    cityCode: "OSH",
    cityName: "Oshkosh",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OSI",
    airportName: "Osijek",
    cityCode: "OSI",
    cityName: "Osijek",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "OSK",
    airportName: "Oskarshamn",
    cityCode: "OSK",
    cityName: "Oskarshamn",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "OSL",
    airportName: "Oslo Arpt., Gardermoen",
    cityCode: "OSL",
    cityName: "Oslo",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "OSM",
    airportName: "Mosul",
    cityCode: "OSM",
    cityName: "Mosul",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "OSN",
    airportName: "Osan AB",
    cityCode: "OSN",
    cityName: "Osan",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "OSO",
    airportName: "Sulaimaniyah Intl.",
    cityCode: "OSO",
    cityName: "Sulaimaniyah",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "OSP",
    airportName: "Redzikowo",
    cityCode: "OSP",
    cityName: "Slupsk",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "OSR",
    airportName: "Mosnov",
    cityCode: "OSR",
    cityName: "Ostrava",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "OSS",
    airportName: "Osh Intl. Arpt.",
    cityCode: "OSS",
    cityName: "Osh",
    countryName: "Kyrgyzstan",
    countryCode: "KG",
  },
  {
    airportCode: "OST",
    airportName: "Ostend/Bruges Intl. Arpt.",
    cityCode: "OST",
    cityName: "Ostend",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "OSU",
    airportName: "Ohio State University",
    cityCode: "OSU",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OSW",
    airportName: "Orsk",
    cityCode: "OSW",
    cityName: "Orsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "OSX",
    airportName: "Attala County",
    cityCode: "OSX",
    cityName: "Kosciusko",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OSY",
    airportName: "Namsos",
    cityCode: "OSY",
    cityName: "Namsos",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "OSZ",
    airportName: "Koszalin",
    cityCode: "OSZ",
    cityName: "Koszalin",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "OTA",
    airportName: "Mota",
    cityCode: "OTA",
    cityName: "Mota",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "OTC",
    airportName: "Bol",
    cityCode: "OTC",
    cityName: "Bol",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "OTD",
    airportName: "Contadora",
    cityCode: "OTD",
    cityName: "Contadora",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "OTG",
    airportName: "Worthington",
    cityCode: "OTG",
    cityName: "Worthington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OTH",
    airportName: "North Bend",
    cityCode: "OTH",
    cityName: "North Bend",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OTI",
    airportName: "Morotai Island",
    cityCode: "OTI",
    cityName: "Pitu, Morotai Island",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "OTJ",
    airportName: "Otjiwarongo",
    cityCode: "OTJ",
    cityName: "Otjiwarongo",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "OTL",
    airportName: "Boutilimit",
    cityCode: "OTL",
    cityName: "Boutilimit",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "OTM",
    airportName: "Industrial",
    cityCode: "OTM",
    cityName: "Ottumwa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NOJ",
    airportName: "Nojabrxsk",
    cityCode: "NOJ",
    cityName: "Nojabrxsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "OTN",
    airportName: "Green",
    cityCode: "OTN",
    cityName: "Oaktown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OTO",
    airportName: "Vor",
    cityCode: "OTO",
    cityName: "Otto",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OTP",
    airportName: "Henri Coanda Intl.",
    cityCode: "BUH",
    cityName: "Bucharest",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "OTR",
    airportName: "Coto 47",
    cityCode: "OTR",
    cityName: "Coto 47",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "OTS",
    airportName: "Anacortes",
    cityCode: "OTS",
    cityName: "Anacortes",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OTU",
    airportName: "Otu",
    cityCode: "OTU",
    cityName: "Otu",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "OTV",
    airportName: "Ontong Java",
    cityCode: "OTV",
    cityName: "Ontong Java",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "OTY",
    airportName: "Oria",
    cityCode: "OTY",
    cityName: "Oria",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "OTZ",
    airportName: "Kotzebue",
    cityCode: "OTZ",
    cityName: "Kotzebue",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OUA",
    airportName: "Ouagadougou",
    cityCode: "OUA",
    cityName: "Ouagadougou",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "OUD",
    airportName: "Les Angades",
    cityCode: "OUD",
    cityName: "Oujda",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "OUE",
    airportName: "Ouesso",
    cityCode: "OUE",
    cityName: "Ouesso",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "OUG",
    airportName: "Ouahigouya",
    cityCode: "OUG",
    cityName: "Ouahigouya",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "OUH",
    airportName: "Oudtshoorn",
    cityCode: "OUH",
    cityName: "Oudtshoorn",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "OUI",
    airportName: "Ban Houei",
    cityCode: "OUI",
    cityName: "Ban Houei",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "OUL",
    airportName: "Oulu",
    cityCode: "OUL",
    cityName: "Oulu",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "OUM",
    airportName: "Oum Hadjer",
    cityCode: "OUM",
    cityName: "Oum Hadjer",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "OUN",
    airportName: "Max Westheimer",
    cityCode: "OUN",
    cityName: "Norman",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OUR",
    airportName: "Batouri",
    cityCode: "OUR",
    cityName: "Batouri",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "OUS",
    airportName: "Ourinhos",
    cityCode: "OUS",
    cityName: "Ourinhos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "OUT",
    airportName: "Bousso",
    cityCode: "OUT",
    cityName: "Bousso",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "OUU",
    airportName: "Ouanga",
    cityCode: "OUU",
    cityName: "Ouanga",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "OUZ",
    airportName: "Zouerate",
    cityCode: "OUZ",
    cityName: "Zouerate",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "OVA",
    airportName: "Bekily",
    cityCode: "OVA",
    cityName: "Bekily",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "OVB",
    airportName: "Tolmachevo",
    cityCode: "OVB",
    cityName: "Novosibirsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "OVD",
    airportName: "Asturias",
    cityCode: "OVD",
    cityName: "Oviedo/Aviles",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "OVE",
    airportName: "Oroville",
    cityCode: "OVE",
    cityName: "Oroville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OVG",
    airportName: "Overberg",
    cityCode: "OVG",
    cityName: "Overberg",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "OVL",
    airportName: "Ovalle",
    cityCode: "OVL",
    cityName: "Ovalle",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "OVR",
    airportName: "Olavarria",
    cityCode: "OVR",
    cityName: "Olavarria",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "OWA",
    airportName: "Owatonna",
    cityCode: "OWA",
    cityName: "Owatonna",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OWB",
    airportName: "Daviess County",
    cityCode: "OWB",
    cityName: "Owensboro",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OWD",
    airportName: "Memorial",
    cityCode: "OWD",
    cityName: "Norwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OWE",
    airportName: "Owendo",
    cityCode: "OWE",
    cityName: "Owendo",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "OWK",
    airportName: "Central Maine Arpt. of Norridgewock",
    cityCode: "OWK",
    cityName: "Norridgewock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OXB",
    airportName: "Osvaldo Vieira Intl.",
    cityCode: "OXB",
    cityName: "Bissau",
    countryName: "Guinea-bissau",
    countryCode: "GW",
  },
  {
    airportCode: "OXC",
    airportName: "Waterbury-oxford",
    cityCode: "OXC",
    cityName: "Oxford, CT",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OXD",
    airportName: "Miami University",
    cityCode: "OXD",
    cityName: "Oxford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OXF",
    airportName: "Oxford",
    cityCode: "OXF",
    cityName: "Oxford",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "OXO",
    airportName: "Orientos",
    cityCode: "OXO",
    cityName: "Orientos",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OXR",
    airportName: "Ventura",
    cityCode: "OXR",
    cityName: "Ventura",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OXY",
    airportName: "Morney",
    cityCode: "OXY",
    cityName: "Morney",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OYA",
    airportName: "Goya",
    cityCode: "OYA",
    cityName: "Goya",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "OYE",
    airportName: "Oyem",
    cityCode: "OYE",
    cityName: "Oyem",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "OYG",
    airportName: "Moyo",
    cityCode: "OYG",
    cityName: "Moyo",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "OYK",
    airportName: "Oiapoque",
    cityCode: "OYK",
    cityName: "Oiapoque",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "OYL",
    airportName: "Moyale",
    cityCode: "OYL",
    cityName: "Moyale",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "OYN",
    airportName: "Ouyen",
    cityCode: "OYN",
    cityName: "Ouyen",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "OYP",
    airportName: "St-Georges de lOyapock",
    cityCode: "OYP",
    cityName: "St Georges de lOyapock",
    countryName: "French Guiana",
    countryCode: "GF",
  },
  {
    airportCode: "OYS",
    airportName: "Yosemite Ntl Park",
    cityCode: "OYS",
    cityName: "Yosemite Ntl Park",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OZA",
    airportName: "Ozona Municipal",
    cityCode: "OZA",
    cityName: "Ozona",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OZC",
    airportName: "Labo",
    cityCode: "OZC",
    cityName: "Ozamis City",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "OZH",
    airportName: "Zaporozhye",
    cityCode: "OZH",
    cityName: "Zaporozhye",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "OZI",
    airportName: "Bobadilla",
    cityCode: "OZI",
    cityName: "Bobadilla",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "OZP",
    airportName: "Moron",
    cityCode: "OZP",
    cityName: "Moron",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "OZR",
    airportName: "Cairns AAF",
    cityCode: "OZR",
    cityName: "Ozark",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OZU",
    airportName: "Montilla",
    cityCode: "OZU",
    cityName: "Montilla",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "OZZ",
    airportName: "Ouarzazate",
    cityCode: "OZZ",
    cityName: "Ouarzazate",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "PAA",
    airportName: "Pa-an",
    cityCode: "PAA",
    cityName: "Pa-an",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "PAB",
    airportName: "Bilaspur",
    cityCode: "PAB",
    cityName: "Bilaspur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "PAC",
    airportName: "Paitilla-Marcos A. Gelabert",
    cityCode: "PAC",
    cityName: "Panama City",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "PAD",
    airportName: "Paderborn/lippstadt",
    cityCode: "PAD",
    cityName: "Paderborn",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "PAE",
    airportName: "Snohomish County",
    cityCode: "PAE",
    cityName: "Everett",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PAF",
    airportName: "Pakuba",
    cityCode: "PAF",
    cityName: "Pakuba",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "PAG",
    airportName: "Pagadian",
    cityCode: "PAG",
    cityName: "Pagadian",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "PAH",
    airportName: "Barkley Regional",
    cityCode: "PAH",
    cityName: "Paducah, KY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PAI",
    airportName: "Pailin",
    cityCode: "PAI",
    cityName: "Pailin",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "PAJ",
    airportName: "Para Chinar",
    cityCode: "PAJ",
    cityName: "Para Chinar",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "PAK",
    airportName: "Port Allen",
    cityCode: "PAK",
    cityName: "Hanapepe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PAL",
    airportName: "Palanquero",
    cityCode: "PAL",
    cityName: "Palanquero",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PAM",
    airportName: "Tyndall AFB",
    cityCode: "PAM",
    cityName: "Panama City, FL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PAN",
    airportName: "Pattani",
    cityCode: "PAN",
    cityName: "Pattani",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PAO",
    airportName: "Palo Alto",
    cityCode: "PAO",
    cityName: "Palo Alto",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PAP",
    airportName: "Toussaint Louverture Intl.",
    cityCode: "PAP",
    cityName: "Port Au Prince",
    countryName: "Haiti",
    countryCode: "HT",
  },
  {
    airportCode: "PAQ",
    airportName: "Municipal",
    cityCode: "PAQ",
    cityName: "Palmer",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PAS",
    airportName: "Paros",
    cityCode: "PAS",
    cityName: "Paros",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "PAT",
    airportName: "Patna",
    cityCode: "PAT",
    cityName: "Patna",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "PAU",
    airportName: "Pauk",
    cityCode: "PAU",
    cityName: "Pauk",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "PAV",
    airportName: "Paulo Afonso",
    cityCode: "PAV",
    cityName: "Paulo Afonso",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PAW",
    airportName: "Pambwa",
    cityCode: "PAW",
    cityName: "Pambwa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PAX",
    airportName: "Port De Paix",
    cityCode: "PAX",
    cityName: "Port De Paix",
    countryName: "Haiti",
    countryCode: "HT",
  },
  {
    airportCode: "PAY",
    airportName: "Pamol",
    cityCode: "PAY",
    cityName: "Pamol",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "PAZ",
    airportName: "Tajin",
    cityCode: "PAZ",
    cityName: "Poza Rica",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PBA",
    airportName: "Point Barrow",
    cityCode: "PBA",
    cityName: "Barrow",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PBB",
    airportName: "Paranaiba",
    cityCode: "PBB",
    cityName: "Paranaiba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PBC",
    airportName: "Hermanos Serdan Intl.",
    cityCode: "PBC",
    cityName: "Puebla Atlixco",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PBD",
    airportName: "Porbandar",
    cityCode: "PBD",
    cityName: "Porbandar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "PBE",
    airportName: "Puerto Berrio",
    cityCode: "PBE",
    cityName: "Puerto Berrio",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PBF",
    airportName: "Grider Field",
    cityCode: "PBF",
    cityName: "Pine Bluff",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PBG",
    airportName: "Plattsburgh Intl.",
    cityCode: "PBG",
    cityName: "Plattsburgh",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PBH",
    airportName: "Paro",
    cityCode: "PBH",
    cityName: "Paro",
    countryName: "Bhutan",
    countryCode: "BT",
  },
  {
    airportCode: "PBI",
    airportName: "Palm Beach Intl.",
    cityCode: "MIA",
    cityName: "Miami",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PBJ",
    airportName: "Paama",
    cityCode: "PBJ",
    cityName: "Paama",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "PBK",
    airportName: "Pack Creek",
    cityCode: "PBK",
    cityName: "Pack Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PBL",
    airportName: "Puerto Cabello",
    cityCode: "PBL",
    cityName: "Puerto Cabello",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "PBM",
    airportName: "Zanderij Intl",
    cityCode: "PBM",
    cityName: "Paramaribo",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "PBN",
    airportName: "Porto Amboim",
    cityCode: "PBN",
    cityName: "Porto Amboim",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "PBO",
    airportName: "Paraburdoo",
    cityCode: "PBO",
    cityName: "Paraburdoo",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PBP",
    airportName: "Punta Islita",
    cityCode: "PBP",
    cityName: "Punta Islita",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "PBQ",
    airportName: "Pimenta Bueno",
    cityCode: "PBQ",
    cityName: "Pimenta Bueno",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PBR",
    airportName: "Puerto Barrios",
    cityCode: "PBR",
    cityName: "Puerto Barrios",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "PBS",
    airportName: "Patong Beach",
    cityCode: "PBS",
    cityName: "Patong Beach",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PBT",
    airportName: "Puerto Leda",
    cityCode: "PBT",
    cityName: "Puerto Leda",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "PBU",
    airportName: "Putao",
    cityCode: "PBU",
    cityName: "Putao",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "PBV",
    airportName: "Porto Dos Gauchos",
    cityCode: "PBV",
    cityName: "Porto Dos Gauchos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PBW",
    airportName: "Palibelo",
    cityCode: "PBW",
    cityName: "Palibelo",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PBX",
    airportName: "Porto Alegre Do Norte",
    cityCode: "PBX",
    cityName: "Porto Alegre Do Norte",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PBY",
    airportName: "Hamilton/Proserpine",
    cityCode: "PBY",
    cityName: "Peppers Palm Bay",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PBZ",
    airportName: "Plettenberg Bay",
    cityCode: "PBZ",
    cityName: "Plettenberg Bay",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "PCA",
    airportName: "Portage Creek",
    cityCode: "PCA",
    cityName: "Portage Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PCB",
    airportName: "Pondok Cabe",
    cityCode: "PCB",
    cityName: "Jakarta",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PCC",
    airportName: "Puerto Rico",
    cityCode: "PCC",
    cityName: "Puerto Rico",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PCD",
    airportName: "Municipal",
    cityCode: "PCD",
    cityName: "Prairie Du Chien",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PCE",
    airportName: "Painter Creek",
    cityCode: "PCE",
    cityName: "Painter Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PCG",
    airportName: "Paso Caballos",
    cityCode: "PCG",
    cityName: "Paso Caballos",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "PCH",
    airportName: "Palacios",
    cityCode: "PCH",
    cityName: "Palacios",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "PCJ",
    airportName: "Puerto La Victoria",
    cityCode: "PCJ",
    cityName: "Puerto La Victoria",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "PCK",
    airportName: "Porcupine Creek",
    cityCode: "PCK",
    cityName: "Porcupine Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PCL",
    airportName: "Captain Rolden Intl. Arpt.",
    cityCode: "PCL",
    cityName: "Pucallpa",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "PCM",
    airportName: "Playa Del Carmen",
    cityCode: "PCM",
    cityName: "Playa Del Carmen",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PCN",
    airportName: "Koromiko",
    cityCode: "PCN",
    cityName: "Picton",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "PCO",
    airportName: "Punta Colorada",
    cityCode: "PCO",
    cityName: "Punta Colorada",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PCP",
    airportName: "Principe",
    cityCode: "PCP",
    cityName: "Principe",
    countryName: "Sao Tome and Principe",
    countryCode: "ST",
  },
  {
    airportCode: "PCQ",
    airportName: "Bounneua",
    cityCode: "PCQ",
    cityName: "Phongsaly",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "PCR",
    airportName: "Puerto Carreno",
    cityCode: "PCR",
    cityName: "Puerto Carreno",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PCS",
    airportName: "Picos",
    cityCode: "PCS",
    cityName: "Picos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PCT",
    airportName: "Princeton",
    cityCode: "PCT",
    cityName: "Princeton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PCU",
    airportName: "Pearl River County",
    cityCode: "PCU",
    cityName: "Picayune",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PCV",
    airportName: "Punta Chivato",
    cityCode: "PCV",
    cityName: "Punta Chivato",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PDA",
    airportName: "Puerto Inirida",
    cityCode: "PDA",
    cityName: "Puerto Inirida",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PDB",
    airportName: "Pedro Bay",
    cityCode: "PDB",
    cityName: "Pedro Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PDC",
    airportName: "Mueo",
    cityCode: "PDC",
    cityName: "Mueo",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "PDD",
    airportName: "Ponta de Ouro",
    cityCode: "PDD",
    cityName: "Ponta de Ouro",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "PDE",
    airportName: "Pandie Pandie",
    cityCode: "PDE",
    cityName: "Pandie Pandie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PDF",
    airportName: "Prado",
    cityCode: "PDF",
    cityName: "Prado",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PDG",
    airportName: "Minangkabau Intl. Arpt.",
    cityCode: "PDG",
    cityName: "Padang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PDI",
    airportName: "Pindiu",
    cityCode: "PDI",
    cityName: "Pindiu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PDK",
    airportName: "Dekalb-Peachtree",
    cityCode: "PDK",
    cityName: "Atlanta, GA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PDL",
    airportName: "Nordela",
    cityCode: "PDL",
    cityName: "Ponta Delgada, Azores",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "PDN",
    airportName: "Parndana",
    cityCode: "PDN",
    cityName: "Parndana",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PDO",
    airportName: "Pendopo",
    cityCode: "PDO",
    cityName: "Pendopo",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PDP",
    airportName: "Capitan Corbeta CA Curbelo Intl. Arpt.",
    cityCode: "PDP",
    cityName: "Maldonado",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "PDS",
    airportName: "Piedras Negras",
    cityCode: "PDS",
    cityName: "Piedras Negras",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PDT",
    airportName: "Pendleton",
    cityCode: "PDT",
    cityName: "Pendleton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PDU",
    airportName: "Paysandu",
    cityCode: "PDU",
    cityName: "Paysandu",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "PDV",
    airportName: "Plovdiv",
    cityCode: "PDV",
    cityName: "Plovdiv",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "PDX",
    airportName: "Portland Intl.",
    cityCode: "PDX",
    cityName: "Portland, OR",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PDZ",
    airportName: "Pedernales",
    cityCode: "PDZ",
    cityName: "Pedernales",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "PEA",
    airportName: "Penneshaw",
    cityCode: "PEA",
    cityName: "Penneshaw",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PEB",
    airportName: "Pebane",
    cityCode: "PEB",
    cityName: "Pebane",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "PEC",
    airportName: "Pelican SPB",
    cityCode: "PEC",
    cityName: "Pelican",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PED",
    airportName: "Pardubice",
    cityCode: "PED",
    cityName: "Pardubice",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "PEE",
    airportName: "Perm",
    cityCode: "PEE",
    cityName: "Perm",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PEF",
    airportName: "Peenemuende",
    cityCode: "PEF",
    cityName: "Peenemuende",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "PEG",
    airportName: "Sant Egidio",
    cityCode: "PEG",
    cityName: "Perugia",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "PEH",
    airportName: "Pehuajo",
    cityCode: "PEH",
    cityName: "Pehuajo",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "PEI",
    airportName: "Matecana",
    cityCode: "PEI",
    cityName: "Pereira",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PEJ",
    airportName: "Peschiei",
    cityCode: "PEJ",
    cityName: "Peschiei",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "PEK",
    airportName: "Beijing Capital Int.",
    cityCode: "BJS",
    cityName: "Beijing",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "PEL",
    airportName: "Pelaneng",
    cityCode: "PEL",
    cityName: "Pelaneng",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "PEM",
    airportName: "Puerto Maldonado",
    cityCode: "PEM",
    cityName: "Puerto Maldonado",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "PEN",
    airportName: "Penang Intl.",
    cityCode: "PEN",
    cityName: "Penang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "PEP",
    airportName: "Peppimenarti",
    cityCode: "PEP",
    cityName: "Peppimenarti",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PEQ",
    airportName: "Pecos City",
    cityCode: "PEQ",
    cityName: "Pecos City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PER",
    airportName: "Perth Intl.",
    cityCode: "PER",
    cityName: "Perth",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PES",
    airportName: "Petrozavodsk",
    cityCode: "PES",
    cityName: "Petrozavodsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PET",
    airportName: "Federal",
    cityCode: "PET",
    cityName: "Pelotas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PEU",
    airportName: "Puerto Lempira",
    cityCode: "PEU",
    cityName: "Puerto Lempira",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "PEW",
    airportName: "Peshawar",
    cityCode: "PEW",
    cityName: "Peshawar",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "PEX",
    airportName: "Pechora",
    cityCode: "PEX",
    cityName: "Pechora",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PEY",
    airportName: "Penong",
    cityCode: "PEY",
    cityName: "Penong",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PEZ",
    airportName: "Penza",
    cityCode: "PEZ",
    cityName: "Penza",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PFA",
    airportName: "Paf Warren",
    cityCode: "PFA",
    cityName: "Paf Warren",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PFB",
    airportName: "Passo Fundo",
    cityCode: "PFB",
    cityName: "Passo Fundo",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PFC",
    airportName: "Pacific City State",
    cityCode: "PFC",
    cityName: "Pacific City, OH",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PFD",
    airportName: "Port Frederick",
    cityCode: "PFD",
    cityName: "Port Frederick",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PFJ",
    airportName: "Patreksfjordur",
    cityCode: "PFJ",
    cityName: "Patreksfjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "PFN",
    airportName: "Bay County",
    cityCode: "PFN",
    cityName: "Panama City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PFO",
    airportName: "Intl.",
    cityCode: "PFO",
    cityName: "Paphos",
    countryName: "Cyprus",
    countryCode: "CY",
  },
  {
    airportCode: "PFR",
    airportName: "Ilebo",
    cityCode: "PFR",
    cityName: "Ilebo",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "PGA",
    airportName: "Page",
    cityCode: "PGA",
    cityName: "Page",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PGB",
    airportName: "Pangoa",
    cityCode: "PGB",
    cityName: "Pangoa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PGC",
    airportName: "Grant County",
    cityCode: "PGC",
    cityName: "Petersburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PGD",
    airportName: "Charlotte County",
    cityCode: "PGD",
    cityName: "Punta Gorda",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PGE",
    airportName: "Yegepa",
    cityCode: "PGE",
    cityName: "Yegepa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PGF",
    airportName: "Llabanere",
    cityCode: "PGF",
    cityName: "Perpignan",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "PGG",
    airportName: "Progresso",
    cityCode: "PGG",
    cityName: "Sao Paulo",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PGH",
    airportName: "Pantnagar",
    cityCode: "PGH",
    cityName: "Pantnagar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "PGI",
    airportName: "Chitato",
    cityCode: "PGI",
    cityName: "Chitato",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "PGK",
    airportName: "Pangkalpinang",
    cityCode: "PGK",
    cityName: "Pangkalpinang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PGM",
    airportName: "Port Graham",
    cityCode: "PGM",
    cityName: "Port Graham",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PGN",
    airportName: "Pangia",
    cityCode: "PGN",
    cityName: "Pangia",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PGP",
    airportName: "Porto Alegre",
    cityCode: "PGP",
    cityName: "Porto Alegre",
    countryName: "Sao Tome and Principe",
    countryCode: "ST",
  },
  {
    airportCode: "PGR",
    airportName: "Municipal",
    cityCode: "PGR",
    cityName: "Paragould",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PGS",
    airportName: "Peach Springs",
    cityCode: "PGS",
    cityName: "Peach Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PGV",
    airportName: "Pitt-Greenville",
    cityCode: "PGV",
    cityName: "Greenville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PGX",
    airportName: "Bassillac",
    cityCode: "PGX",
    cityName: "Perigueux",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "PHA",
    airportName: "Phan Rang",
    cityCode: "PHA",
    cityName: "Phan Rang",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "PHB",
    airportName: "Santos Dumont",
    cityCode: "PHB",
    cityName: "Parnaiba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PHC",
    airportName: "Port Harcourt",
    cityCode: "PHC",
    cityName: "Port Harcourt",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "PHD",
    airportName: "Harry Clever",
    cityCode: "PHD",
    cityName: "New Philadelphia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHE",
    airportName: "Port Hedland",
    cityCode: "PHE",
    cityName: "Port Hedland",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PHF",
    airportName: "Newport News/williamsb",
    cityCode: "PHF",
    cityName: "Newport News",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHG",
    airportName: "Port Harcourt City",
    cityCode: "PHG",
    cityName: "Port Harcourt",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "PHH",
    airportName: "Phan Thiet",
    cityCode: "PHH",
    cityName: "Phan Thiet",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "PHI",
    airportName: "Pinheiro",
    cityCode: "PHI",
    cityName: "Pinheiro",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PHJ",
    airportName: "Port Hunter",
    cityCode: "PHJ",
    cityName: "Port Hunter",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PHK",
    airportName: "Palm Beach Co Glades",
    cityCode: "PHK",
    cityName: "Pahokee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHL",
    airportName: "Philadelphia Intl.",
    cityCode: "PHL",
    cityName: "Philadelphia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHM",
    airportName: "Boeblingen",
    cityCode: "PHM",
    cityName: "Boeblingen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "PHN",
    airportName: "St Clair County Intl",
    cityCode: "PHN",
    cityName: "Port Huron",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHO",
    airportName: "Point Hope",
    cityCode: "PHO",
    cityName: "Point Hope",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHP",
    airportName: "Philip",
    cityCode: "PHP",
    cityName: "Philip",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHR",
    airportName: "Pacific Harbor",
    cityCode: "PHR",
    cityName: "Pacific Harbor",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "PHS",
    airportName: "Phitsanulok",
    cityCode: "PHS",
    cityName: "Phitsanulok",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PHT",
    airportName: "Henry County",
    cityCode: "PHT",
    cityName: "Paris",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHU",
    airportName: "Phu Vinh",
    cityCode: "PHU",
    cityName: "Phu Vinh",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "PHW",
    airportName: "Cape Town",
    cityCode: "PHW",
    cityName: "Phalaborwa",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "PHX",
    airportName: "Sky Harbor Intl",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PHY",
    airportName: "Phetchabun",
    cityCode: "PHY",
    cityName: "Phetchabun",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PHZ",
    airportName: "Phi Phi Island",
    cityCode: "PHZ",
    cityName: "Phi Phi Island",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PIA",
    airportName: "Greater Peoria",
    cityCode: "PIA",
    cityName: "Peoria, IL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PIB",
    airportName: "Hattiesburg-Laurel Regional",
    cityCode: "PIB",
    cityName: "Hattiesburg/Laurel, MS",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PIC",
    airportName: "Pine Cay",
    cityCode: "PIC",
    cityName: "Pine Cay",
    countryName: "Turks and Caicos Islands",
    countryCode: "TC",
  },
  {
    airportCode: "PID",
    airportName: "Paradise Island",
    cityCode: "PID",
    cityName: "Nassau",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "PIE",
    airportName: "St. Petersburg-Clearwater Intl.",
    cityCode: "PIE",
    cityName: "Clearwater",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PIF",
    airportName: "Pingtung",
    cityCode: "PIF",
    cityName: "Pingtung",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "PIG",
    airportName: "Pitinga",
    cityCode: "PIG",
    cityName: "Pitinga",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PIH",
    airportName: "Pocatello",
    cityCode: "PIH",
    cityName: "Pocatello",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PII",
    airportName: "Phillips Field",
    cityCode: "PII",
    cityName: "Fairbanks",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PIK",
    airportName: "Prestwick",
    cityCode: "GLA",
    cityName: "Glasgow",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "PIL",
    airportName: "Pilar",
    cityCode: "PIL",
    cityName: "Pilar",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "PIM",
    airportName: "Garden Harris County",
    cityCode: "PIM",
    cityName: "Pine Mountain",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PIN",
    airportName: "Parintins",
    cityCode: "PIN",
    cityName: "Parintins",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PIO",
    airportName: "Pisco",
    cityCode: "PIO",
    cityName: "Pisco",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "PIP",
    airportName: "Pilot Point",
    cityCode: "PIP",
    cityName: "Pilot Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PIQ",
    airportName: "Pipillipai",
    cityCode: "PIQ",
    cityName: "Pipillipai",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "PIR",
    airportName: "Pierre",
    cityCode: "PIR",
    cityName: "Pierre",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PIS",
    airportName: "Biard",
    cityCode: "PIS",
    cityName: "Poitiers",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "PIT",
    airportName: "Pittsburgh Intl.",
    cityCode: "PIT",
    cityName: "Pittsburgh, PA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PIU",
    airportName: "Piura",
    cityCode: "PIU",
    cityName: "Piura",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "PIV",
    airportName: "Pirapora",
    cityCode: "PIV",
    cityName: "Pirapora",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PIW",
    airportName: "Pikwitonei",
    cityCode: "PIW",
    cityName: "Pikwitonei",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "PIX",
    airportName: "Pico Island",
    cityCode: "PIX",
    cityName: "Pico Island",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "PIZ",
    airportName: "Dew Station",
    cityCode: "PIZ",
    cityName: "Point Lay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PJA",
    airportName: "Pajala",
    cityCode: "PJA",
    cityName: "Pajala",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "PJB",
    airportName: "Payson",
    cityCode: "PJB",
    cityName: "Payson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PJC",
    airportName: "Pedro Juan Caballero",
    cityCode: "PJC",
    cityName: "Pedro Juan Caballero",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "PJG",
    airportName: "Panjgur",
    cityCode: "PJG",
    cityName: "Panjgur",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "PJM",
    airportName: "Puerto Jimenez",
    cityCode: "PJM",
    cityName: "Puerto Jimenez",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "PJS",
    airportName: "Port San Juan",
    cityCode: "PJS",
    cityName: "Port San Juan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PJZ",
    airportName: "Puerto Vallarta",
    cityCode: "PJZ",
    cityName: "Puerto Juarez",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PKA",
    airportName: "SPB",
    cityCode: "PKA",
    cityName: "Napaskiak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PKB",
    airportName: "Wood County",
    cityCode: "PKB",
    cityName: "Parkersburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PKC",
    airportName: "Petropavlovsk-Kamchats",
    cityCode: "PKC",
    cityName: "Petropavlovsk-Kamchats",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PKD",
    airportName: "Park Rapids",
    cityCode: "PKD",
    cityName: "Park Rapids",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PKE",
    airportName: "Parkes",
    cityCode: "PKE",
    cityName: "Parkes",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PKF",
    airportName: "Park Falls",
    cityCode: "PKF",
    cityName: "Park Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PKH",
    airportName: "Alexion",
    cityCode: "PKH",
    cityName: "Porto Kheli",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "PKJ",
    airportName: "Playa Grande",
    cityCode: "PKJ",
    cityName: "Playa Grande",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "PKK",
    airportName: "Pakokku",
    cityCode: "PKK",
    cityName: "Pakokku",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "PKL",
    airportName: "Pakatoa Island",
    cityCode: "PKL",
    cityName: "Pakatoa Island",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "PKM",
    airportName: "Port Kaituma",
    cityCode: "PKM",
    cityName: "Port Kaituma",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "PKN",
    airportName: "Pangkalanbuun",
    cityCode: "PKN",
    cityName: "Pangkalanbuun",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PKO",
    airportName: "Parakou",
    cityCode: "PKO",
    cityName: "Parakou",
    countryName: "Benin",
    countryCode: "BJ",
  },
  {
    airportCode: "PKP",
    airportName: "Puka Puka",
    cityCode: "PKP",
    cityName: "Puka Puka",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "PKR",
    airportName: "Pokhara",
    cityCode: "PKR",
    cityName: "Pokhara",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "PKS",
    airportName: "Paksane",
    cityCode: "PKS",
    cityName: "Paksane",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "PKT",
    airportName: "Port Keats",
    cityCode: "PKT",
    cityName: "Port Keats",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PKU",
    airportName: "Sultan Syarif Kasim Ii",
    cityCode: "PKU",
    cityName: "Pekanbaru",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PKV",
    airportName: "Pskov",
    cityCode: "PKV",
    cityName: "Pskov",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PKW",
    airportName: "Selebi-Phikwe",
    cityCode: "PKW",
    cityName: "Selebi-Phikwe",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "PKY",
    airportName: "Palangkaraya",
    cityCode: "PKY",
    cityName: "Palangkaraya",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PKZ",
    airportName: "Pakse",
    cityCode: "PKZ",
    cityName: "Pakse",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "PLA",
    airportName: "Planadas",
    cityCode: "PLA",
    cityName: "Planadas",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PLB",
    airportName: "Clinton County",
    cityCode: "PLB",
    cityName: "Plattsburgh",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PLC",
    airportName: "Planeta Rica",
    cityCode: "PLC",
    cityName: "Planeta Rica",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PLD",
    airportName: "Playa Samara",
    cityCode: "PLD",
    cityName: "Playa Samara",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "PLF",
    airportName: "Pala",
    cityCode: "PLF",
    cityName: "Pala",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "PLG",
    airportName: "La Plagne",
    cityCode: "PLG",
    cityName: "La Plagne",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "PLH",
    airportName: "Plymouth",
    cityCode: "PLH",
    cityName: "Plymouth",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "PLI",
    airportName: "Palm Island",
    cityCode: "PLI",
    cityName: "Palm Island",
    countryName: "Saint Vincent and the Grenadines",
    countryCode: "VC",
  },
  {
    airportCode: "PLJ",
    airportName: "Placencia",
    cityCode: "PLJ",
    cityName: "Placencia",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "PLK",
    airportName: "M Graham Clark",
    cityCode: "PLK",
    cityName: "Branson/point Lookout",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PLL",
    airportName: "Ponta Pelada",
    cityCode: "PLL",
    cityName: "Manaus",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PLM",
    airportName: "Mahmud Badaruddin Ii",
    cityCode: "PLM",
    cityName: "Palembang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PLN",
    airportName: "Emmet County",
    cityCode: "PLN",
    cityName: "Pellston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PLO",
    airportName: "Port Lincoln",
    cityCode: "PLO",
    cityName: "Port Lincoln",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PLP",
    airportName: "La Palma",
    cityCode: "PLP",
    cityName: "La Palma",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "PLQ",
    airportName: "Palanga Intl.",
    cityCode: "PLQ",
    cityName: "Klaipeda/Palanga",
    countryName: "Lithuania",
    countryCode: "LT",
  },
  {
    airportCode: "PLR",
    airportName: "St Clair County",
    cityCode: "PLR",
    cityName: "Pell City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PLS",
    airportName: "Intl.",
    cityCode: "PLS",
    cityName: "Providenciales",
    countryName: "Turks and Caicos Islands",
    countryCode: "TC",
  },
  {
    airportCode: "PLT",
    airportName: "Plato",
    cityCode: "PLT",
    cityName: "Plato",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PLU",
    airportName: "Pampulha Domestic",
    cityCode: "BHZ",
    cityName: "Belo Horizonte",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PLV",
    airportName: "Poltava",
    cityCode: "PLV",
    cityName: "Poltava",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "PLW",
    airportName: "Mutiara",
    cityCode: "PLW",
    cityName: "Palu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PLX",
    airportName: "Semipalatinsk",
    cityCode: "PLX",
    cityName: "Semipalatinsk",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "PLY",
    airportName: "Plymouth",
    cityCode: "PLY",
    cityName: "Plymouth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PLZ",
    airportName: "Port Elizabeth",
    cityCode: "PLZ",
    cityName: "Port Elizabeth",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "PMA",
    airportName: "Wawi",
    cityCode: "PMA",
    cityName: "Pemba",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "PMB",
    airportName: "Intermediate",
    cityCode: "PMB",
    cityName: "Pembina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PMC",
    airportName: "Tepual",
    cityCode: "PMC",
    cityName: "Puerto Montt",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "PMD",
    airportName: "La Palmdale Regional Arpt.",
    cityCode: "PMD",
    cityName: "Palmdale, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PME",
    airportName: "Portsmouth",
    cityCode: "PME",
    cityName: "Portsmouth",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "PMF",
    airportName: "Giuseppe Verdi",
    cityCode: "PMF",
    cityName: "Parma",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "PMG",
    airportName: "Intl.",
    cityCode: "PMG",
    cityName: "Ponta Pora",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PMH",
    airportName: "Greater Portsmouth Regional",
    cityCode: "PMH",
    cityName: "Portsmouth, OH",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PMI",
    airportName: "Son Sant Joan Arpt.",
    cityCode: "PMI",
    cityName: "Palma Mallorca",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "PMK",
    airportName: "Palm Island",
    cityCode: "PMK",
    cityName: "Palm Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PML",
    airportName: "AFS",
    cityCode: "PML",
    cityName: "Port Moller",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PMM",
    airportName: "Phanom Sarakham",
    cityCode: "PMM",
    cityName: "Phanom Sarakham",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PMN",
    airportName: "Pumani",
    cityCode: "PMN",
    cityName: "Pumani",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PMO",
    airportName: "Punta Raisi",
    cityCode: "PMO",
    cityName: "Palermo",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "PMP",
    airportName: "Pimaga",
    cityCode: "PMP",
    cityName: "Pimaga",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PMQ",
    airportName: "Perito Moreno",
    cityCode: "PMQ",
    cityName: "Perito Moreno",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "PMR",
    airportName: "Palmerston North",
    cityCode: "PMR",
    cityName: "Palmerston North",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "PMS",
    airportName: "Palmyra",
    cityCode: "PMS",
    cityName: "Palmyra",
    countryName: "Syria",
    countryCode: "SY",
  },
  {
    airportCode: "PMT",
    airportName: "Paramakotoi",
    cityCode: "PMT",
    cityName: "Paramakotoi",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "PMU",
    airportName: "SPB",
    cityCode: "PMU",
    cityName: "Paimiut",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PMV",
    airportName: "Del Caribe Intl.",
    cityCode: "PMV",
    cityName: "Porlamar",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "PMW",
    airportName: "Palmas",
    cityCode: "PMW",
    cityName: "Palmas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PMX",
    airportName: "Metropolitan",
    cityCode: "PMX",
    cityName: "Palmer",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PMY",
    airportName: "El Tehuelche",
    cityCode: "PMY",
    cityName: "Puerto Madryn",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "PMZ",
    airportName: "Palmar Sur",
    cityCode: "PMZ",
    cityName: "Palmar",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "PNA",
    airportName: "Pamplona",
    cityCode: "PNA",
    cityName: "Pamplona",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "PNB",
    airportName: "Porto Nacional",
    cityCode: "PNB",
    cityName: "Porto Nacional",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PNC",
    airportName: "Ponca City",
    cityCode: "PNC",
    cityName: "Ponca City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PND",
    airportName: "Punta Gorda",
    cityCode: "PND",
    cityName: "Punta Gorda",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "PNE",
    airportName: "North Philadelphia",
    cityCode: "PNE",
    cityName: "Philadelphia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PNG",
    airportName: "Municipal",
    cityCode: "PNG",
    cityName: "Paranagua",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PNH",
    airportName: "Phnom Penh Intl.",
    cityCode: "PNH",
    cityName: "Phnom Penh",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "PNI",
    airportName: "Pohnpei",
    cityCode: "PNI",
    cityName: "Pohnpei",
    countryName: "Micronesia",
    countryCode: "FM",
  },
  {
    airportCode: "PNJ",
    airportName: "Peng Lai/Sha He Kou",
    cityCode: "PNJ",
    cityName: "Peng Lai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "PNK",
    airportName: "Supadio",
    cityCode: "PNK",
    cityName: "Pontianak",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PNL",
    airportName: "Pantelleria",
    cityCode: "PNL",
    cityName: "Pantelleria",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "PNN",
    airportName: "Princeton",
    cityCode: "PNN",
    cityName: "Princeton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PNO",
    airportName: "Pinotepa Nacional",
    cityCode: "PNO",
    cityName: "Pinotepa Nacional",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PNP",
    airportName: "Girua",
    cityCode: "PNP",
    cityName: "Popondetta",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PNQ",
    airportName: "Lohegaon",
    cityCode: "PNQ",
    cityName: "Pune",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "PNR",
    airportName: "Pointe Noire",
    cityCode: "PNR",
    cityName: "Pointe Noire",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "PNS",
    airportName: "Regional",
    cityCode: "PNS",
    cityName: "Pensacola",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PNT",
    airportName: "Teniente J. Gallardo",
    cityCode: "PNT",
    cityName: "Puerto Natales",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "PNU",
    airportName: "Panguitch",
    cityCode: "PNU",
    cityName: "Panguitch",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PNV",
    airportName: "Panevezys",
    cityCode: "PNV",
    cityName: "Panevezys",
    countryName: "Lithuania",
    countryCode: "LT",
  },
  {
    airportCode: "PNX",
    airportName: "Grayson County",
    cityCode: "PNX",
    cityName: "Sherman-Denison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PNY",
    airportName: "Pondicherry",
    cityCode: "PNY",
    cityName: "Pondicherry",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "PNZ",
    airportName: "Internacional",
    cityCode: "PNZ",
    cityName: "Petrolina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "POA",
    airportName: "Salgado Filho",
    cityCode: "POA",
    cityName: "Porto Alegre",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "POB",
    airportName: "Pope AFB",
    cityCode: "POB",
    cityName: "Fayetteville, NC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "POC",
    airportName: "Brackett Field",
    cityCode: "POC",
    cityName: "La Verne",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "POD",
    airportName: "Podor",
    cityCode: "POD",
    cityName: "Podor",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "POE",
    airportName: "Polk AAF",
    cityCode: "POE",
    cityName: "Fort Polk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "POF",
    airportName: "Earl Fields Memorial",
    cityCode: "POF",
    cityName: "Poplar Bluff",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "POG",
    airportName: "Port Gentil",
    cityCode: "POG",
    cityName: "Port Gentil",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "POH",
    airportName: "Municipal",
    cityCode: "POH",
    cityName: "Pocahontas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "POI",
    airportName: "Potosi",
    cityCode: "POI",
    cityName: "Potosi",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "POJ",
    airportName: "Patos De Minas",
    cityCode: "POJ",
    cityName: "Patos De Minas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "POL",
    airportName: "Porto Amelia",
    cityCode: "POL",
    cityName: "Pemba",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "POM",
    airportName: "Jackson Fld",
    cityCode: "POM",
    cityName: "Port Moresby",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PON",
    airportName: "Poptun",
    cityCode: "PON",
    cityName: "Poptun",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "POO",
    airportName: "Pocos De Caldas",
    cityCode: "POO",
    cityName: "Pocos De Caldas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "POP",
    airportName: "La Union",
    cityCode: "POP",
    cityName: "Puerto Plata",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "POQ",
    airportName: "Polk Inlet",
    cityCode: "POQ",
    cityName: "Polk Inlet",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "POR",
    airportName: "Pori",
    cityCode: "POR",
    cityName: "Pori",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "POS",
    airportName: "Piarco Intl.",
    cityCode: "POS",
    cityName: "Port Of Spain",
    countryName: "Trinidad And Tobago",
    countryCode: "TT",
  },
  {
    airportCode: "POT",
    airportName: "Ken Jones",
    cityCode: "POT",
    cityName: "Port Antonio",
    countryName: "Jamaica",
    countryCode: "JM",
  },
  {
    airportCode: "POU",
    airportName: "Hudson Valley Regional Airport",
    cityCode: "POU",
    cityName: "Poughkeepsie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "POV",
    airportName: "Presov",
    cityCode: "POV",
    cityName: "Presov",
    countryName: "Slovak Republic",
    countryCode: "SK",
  },
  {
    airportCode: "POW",
    airportName: "Portoroz",
    cityCode: "POW",
    cityName: "Portoroz",
    countryName: "Slovenia",
    countryCode: "SI",
  },
  {
    airportCode: "POX",
    airportName: "Paris Cergy Pontoise",
    cityCode: "POX",
    cityName: "Paris",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "POY",
    airportName: "Powell",
    cityCode: "POY",
    cityName: "Powell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "POZ",
    airportName: "Lawica",
    cityCode: "POZ",
    cityName: "Poznan",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "PPA",
    airportName: "Perry Lefors Field",
    cityCode: "PPA",
    cityName: "Pampa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PPB",
    airportName: "Adhemar De Barros",
    cityCode: "PPB",
    cityName: "Presidente Prudente",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PPC",
    airportName: "Prospect Creek",
    cityCode: "PPC",
    cityName: "Prospect Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PPD",
    airportName: "Palmas Del Mar",
    cityCode: "PPD",
    cityName: "Humacao",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "PPE",
    airportName: "Puerto Penasco",
    cityCode: "PPE",
    cityName: "Puerto Penasco",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PPF",
    airportName: "Tri-City",
    cityCode: "PPF",
    cityName: "Parsons",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PPG",
    airportName: "Intl.",
    cityCode: "PPG",
    cityName: "Pago Pago",
    countryName: "American Samoa",
    countryCode: "AS",
  },
  {
    airportCode: "PPH",
    airportName: "Peraitepuy",
    cityCode: "PPH",
    cityName: "Peraitepuy",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "PPI",
    airportName: "Port Pirie",
    cityCode: "PPI",
    cityName: "Port Pirie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PPJ",
    airportName: "Pulau Panjang",
    cityCode: "PPJ",
    cityName: "Pulau Panjang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PPK",
    airportName: "Petropavlovsk",
    cityCode: "PPK",
    cityName: "Petropavlovsk",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "PPL",
    airportName: "Phaplu",
    cityCode: "PPL",
    cityName: "Phaplu",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "PPM",
    airportName: "Pompano Beach",
    cityCode: "PPM",
    cityName: "Pompano Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PPN",
    airportName: "Machangara",
    cityCode: "PPN",
    cityName: "Popayan",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PPO",
    airportName: "Powell Point",
    cityCode: "PPO",
    cityName: "Powell Point",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "PPP",
    airportName: "Whitsunday Coast",
    cityCode: "PPP",
    cityName: "Proserpine",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PPQ",
    airportName: "Paraparaumu",
    cityCode: "PPQ",
    cityName: "Paraparaumu",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "PPR",
    airportName: "Pasir Pangarayan",
    cityCode: "PPR",
    cityName: "Pasir Pangarayan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PPS",
    airportName: "Puerto Princesa",
    cityCode: "PPS",
    cityName: "Puerto Princesa",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "PPT",
    airportName: "Faa'a",
    cityCode: "PPT",
    cityName: "Papeete",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "PPU",
    airportName: "Papun",
    cityCode: "PPU",
    cityName: "Papun",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "PPV",
    airportName: "Port Protection",
    cityCode: "PPV",
    cityName: "Port Protection",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PPW",
    airportName: "Papa Westray",
    cityCode: "PPW",
    cityName: "Papa Westray",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "PPX",
    airportName: "Param",
    cityCode: "PPX",
    cityName: "Param",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PPY",
    airportName: "Pouso Alegre",
    cityCode: "PPY",
    cityName: "Pouso Alegre",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PPZ",
    airportName: "Puerto Paez",
    cityCode: "PPZ",
    cityName: "Puerto Paez",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "PQC",
    airportName: "Duong Dong",
    cityCode: "PQC",
    cityName: "Phu Quoc",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "PQI",
    airportName: "Municipal",
    cityCode: "PQI",
    cityName: "Presque Isle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PQM",
    airportName: "Palenque",
    cityCode: "PQM",
    cityName: "Palenque",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PQQ",
    airportName: "Port Macquarie",
    cityCode: "PQQ",
    cityName: "Port Macquarie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PQS",
    airportName: "Pilot Station",
    cityCode: "PQS",
    cityName: "Pilot Station",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PQT",
    airportName: "Heliport",
    cityCode: "PQT",
    cityName: "Qeqertaq",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "PRA",
    airportName: "Parana",
    cityCode: "PRA",
    cityName: "Parana",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "PRB",
    airportName: "Paso Robles",
    cityCode: "PRB",
    cityName: "Paso Robles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PRC",
    airportName: "Prescott",
    cityCode: "PRC",
    cityName: "Prescott",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PRD",
    airportName: "Pardoo",
    cityCode: "PRD",
    cityName: "Pardoo",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PRE",
    airportName: "Pore",
    cityCode: "PRE",
    cityName: "Pore",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PRF",
    airportName: "Port Johnson",
    cityCode: "PRF",
    cityName: "Port Johnson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PRG",
    airportName: "Prague - Ruzyne Intl.",
    cityCode: "PRG",
    cityName: "Prague",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "PRH",
    airportName: "Phrae",
    cityCode: "PRH",
    cityName: "Phrae",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PRI",
    airportName: "Praslin Island",
    cityCode: "PRI",
    cityName: "Praslin Island",
    countryName: "Seychelles",
    countryCode: "SC",
  },
  {
    airportCode: "PRJ",
    airportName: "Capri",
    cityCode: "PRJ",
    cityName: "Capri",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "PRK",
    airportName: "Prieska",
    cityCode: "PRK",
    cityName: "Prieska",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "PRL",
    airportName: "Port Oceanic",
    cityCode: "PRL",
    cityName: "Port Oceanic",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PRM",
    airportName: "Portimao",
    cityCode: "PRM",
    cityName: "Portimao",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "PRN",
    airportName: "Prishtina Intl. Arpt.",
    cityCode: "PRN",
    cityName: "Prishtina",
    countryName: "Kosovo",
    countryCode: "XK",
  },
  {
    airportCode: "PRO",
    airportName: "Municipal",
    cityCode: "PRO",
    cityName: "Perry",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PRP",
    airportName: "Propriano",
    cityCode: "PRP",
    cityName: "Propriano",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "PRQ",
    airportName: "Pres. Roque Saenz Pena",
    cityCode: "PRQ",
    cityName: "Pres. Roque Saenz Pena",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "PRR",
    airportName: "Paruima",
    cityCode: "PRR",
    cityName: "Paruima",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "PRS",
    airportName: "Parasi",
    cityCode: "PRS",
    cityName: "Parasi",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "PRT",
    airportName: "Coast Guard Heliport",
    cityCode: "PRT",
    cityName: "Point Retreat",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PRU",
    airportName: "Prome",
    cityCode: "PRU",
    cityName: "Prome",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "PRV",
    airportName: "Prerov",
    cityCode: "PRV",
    cityName: "Prerov",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "PRW",
    airportName: "Prentice",
    cityCode: "PRW",
    cityName: "Prentice",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PRX",
    airportName: "Cox Field",
    cityCode: "PRX",
    cityName: "Paris",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PRY",
    airportName: "Wonderboom Arpt.",
    cityCode: "PRY",
    cityName: "Pretoria",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "PRZ",
    airportName: "Prineville",
    cityCode: "PRZ",
    cityName: "Prineville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSA",
    airportName: "Galileo Galilei",
    cityCode: "PSA",
    cityName: "Pisa",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "PSB",
    airportName: "Bellefonte",
    cityCode: "PSB",
    cityName: "Bellefonte",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSC",
    airportName: "Tri-cities",
    cityCode: "PSC",
    cityName: "Pasco, WA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSD",
    airportName: "Port Said",
    cityCode: "PSD",
    cityName: "Port Said",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "PSE",
    airportName: "Mercedita",
    cityCode: "PSE",
    cityName: "Ponce",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "PSF",
    airportName: "Pittsfield",
    cityCode: "PSF",
    cityName: "Pittsfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSG",
    airportName: "Petersburg James A Johnson",
    cityCode: "PSG",
    cityName: "Petersburg, AK",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSH",
    airportName: "St Peter",
    cityCode: "PSH",
    cityName: "St Peter",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "PSI",
    airportName: "Pasni",
    cityCode: "PSI",
    cityName: "Pasni",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "PSJ",
    airportName: "Poso",
    cityCode: "PSJ",
    cityName: "Poso",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PSK",
    airportName: "New River Valley",
    cityCode: "PSK",
    cityName: "Dublin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSL",
    airportName: "Perth",
    cityCode: "PSL",
    cityName: "Perth",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "PSM",
    airportName: "Pease Intl.",
    cityCode: "PSM",
    cityName: "Portsmouth, NH",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSN",
    airportName: "Palestine Municipal Arpt.",
    cityCode: "PSN",
    cityName: "Palestine, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSO",
    airportName: "Cano",
    cityCode: "PSO",
    cityName: "Pasto",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PSP",
    airportName: "Municipal",
    cityCode: "PSP",
    cityName: "Palm Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSQ",
    airportName: "SPB",
    cityCode: "PSQ",
    cityName: "Philadelphia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSR",
    airportName: "Liberi",
    cityCode: "PSR",
    cityName: "Pescara",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "PSS",
    airportName: "Posadas",
    cityCode: "PSS",
    cityName: "Posadas",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "PST",
    airportName: "Preston",
    cityCode: "PST",
    cityName: "Preston",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "PSU",
    airportName: "Putussibau",
    cityCode: "PSU",
    cityName: "Putussibau",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PSW",
    airportName: "Passos",
    cityCode: "PSW",
    cityName: "Passos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PSX",
    airportName: "Palacios",
    cityCode: "PSX",
    cityName: "Palacios",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PSY",
    airportName: "Port Stanley",
    cityCode: "PSY",
    cityName: "Port Stanley",
    countryName: "Falkland Islands",
    countryCode: "FK",
  },
  {
    airportCode: "PSZ",
    airportName: "Puerto Suarez",
    cityCode: "PSZ",
    cityName: "Puerto Suarez",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "PTA",
    airportName: "Port Alsworth",
    cityCode: "PTA",
    cityName: "Port Alsworth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTB",
    airportName: "Municipal",
    cityCode: "PTB",
    cityName: "Petersburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTC",
    airportName: "Port Alice",
    cityCode: "PTC",
    cityName: "Port Alice",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTD",
    airportName: "Port Alexander",
    cityCode: "PTD",
    cityName: "Port Alexander",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTE",
    airportName: "Port Stephens",
    cityCode: "PTE",
    cityName: "Port Stephens",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PTF",
    airportName: "Malololailai",
    cityCode: "PTF",
    cityName: "Malololailai",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "PTG",
    airportName: "Polokwane",
    cityCode: "PTG",
    cityName: "Polokwane",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "PTH",
    airportName: "Port Heiden",
    cityCode: "PTH",
    cityName: "Port Heiden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTI",
    airportName: "Port Douglas",
    cityCode: "PTI",
    cityName: "Port Douglas",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PTJ",
    airportName: "Portland",
    cityCode: "PTJ",
    cityName: "Portland",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PTK",
    airportName: "Pontiac",
    cityCode: "PTK",
    cityName: "Pontiac",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTL",
    airportName: "Port Armstrong",
    cityCode: "PTL",
    cityName: "Port Armstrong",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTM",
    airportName: "Palmarito",
    cityCode: "PTM",
    cityName: "Palmarito",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "PTN",
    airportName: "Williams Memorial",
    cityCode: "PTN",
    cityName: "Patterson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTO",
    airportName: "Municipal",
    cityCode: "PTO",
    cityName: "Pato Branco",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PTP",
    airportName: "Le Raizet",
    cityCode: "PTP",
    cityName: "Pointe-a-pitre",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "PTQ",
    airportName: "Porto de Moz",
    cityCode: "PTQ",
    cityName: "Porto de Moz",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PTR",
    airportName: "Pleasant Harbour",
    cityCode: "PTR",
    cityName: "Pleasant Harbour",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTS",
    airportName: "Municipal",
    cityCode: "PTS",
    cityName: "Pittsburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTT",
    airportName: "Pratt",
    cityCode: "PTT",
    cityName: "Pratt",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTU",
    airportName: "Platinum",
    cityCode: "PTU",
    cityName: "Platinum",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTV",
    airportName: "Porterville",
    cityCode: "PTV",
    cityName: "Porterville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTW",
    airportName: "Pottstown/Limerick",
    cityCode: "PTW",
    cityName: "Pottstown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PTX",
    airportName: "Pitalito",
    cityCode: "PTX",
    cityName: "Pitalito",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PTY",
    airportName: "Tocumen Intl.",
    cityCode: "PTY",
    cityName: "Panama City",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "PTZ",
    airportName: "Pastaza",
    cityCode: "PTZ",
    cityName: "Pastaza",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "PUA",
    airportName: "Puas",
    cityCode: "PUA",
    cityName: "Puas",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PUB",
    airportName: "Memorial",
    cityCode: "PUB",
    cityName: "Pueblo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PUC",
    airportName: "Carbon County",
    cityCode: "PUC",
    cityName: "Price",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PUD",
    airportName: "Puerto Deseado",
    cityCode: "PUD",
    cityName: "Puerto Deseado",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "PUE",
    airportName: "Puerto Obaldia",
    cityCode: "PUE",
    cityName: "Puerto Obaldia",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "PUF",
    airportName: "Pau-PyrÃ©nÃ©es",
    cityCode: "PUF",
    cityName: "Pau",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "PUG",
    airportName: "Port Augusta",
    cityCode: "PUG",
    cityName: "Port Augusta",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "PUH",
    airportName: "Pochutla",
    cityCode: "PUH",
    cityName: "Pochutla",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PUI",
    airportName: "Pureni",
    cityCode: "PUI",
    cityName: "Pureni",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "PUJ",
    airportName: "Punta Cana Intl.",
    cityCode: "PUJ",
    cityName: "Higuey",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "PUK",
    airportName: "Pukarua",
    cityCode: "PUK",
    cityName: "Pukarua",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "PUL",
    airportName: "Poulsbo",
    cityCode: "PUL",
    cityName: "Poulsbo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PUM",
    airportName: "Pomala",
    cityCode: "PUM",
    cityName: "Pomala",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PUN",
    airportName: "Punia",
    cityCode: "PUN",
    cityName: "Punia",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "PUO",
    airportName: "Prudhoe Bay",
    cityCode: "PUO",
    cityName: "Prudhoe Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PUP",
    airportName: "Po",
    cityCode: "PUP",
    cityName: "Po",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "PUQ",
    airportName: "Pres Ibanez",
    cityCode: "PUQ",
    cityName: "Punta Arenas",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "PUR",
    airportName: "Puerto Rico",
    cityCode: "PUR",
    cityName: "Puerto Rico",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "PUS",
    airportName: "Gimhae",
    cityCode: "PUS",
    cityName: "Busan",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "PUT",
    airportName: "Puttaprathe",
    cityCode: "PUT",
    cityName: "Puttaparthi",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "PUU",
    airportName: "Puerto Asis",
    cityCode: "PUU",
    cityName: "Puerto Asis",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PUW",
    airportName: "Pullman-Moscow Regional Arpt.",
    cityCode: "PUW",
    cityName: "Pullman",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PUX",
    airportName: "Puerto Varas",
    cityCode: "PUX",
    cityName: "Puerto Varas",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "PUY",
    airportName: "Pula",
    cityCode: "PUY",
    cityName: "Pula",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "PUZ",
    airportName: "Puerto Cabezas",
    cityCode: "PUZ",
    cityName: "Puerto Cabezas",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "PVA",
    airportName: "Providencia",
    cityCode: "PVA",
    cityName: "Providencia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PVC",
    airportName: "Provincetown",
    cityCode: "PVC",
    cityName: "Provincetown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PVD",
    airportName: "T. F. Green Arpt.",
    cityCode: "PVD",
    cityName: "Providence",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PVE",
    airportName: "El Porvenir",
    cityCode: "PVE",
    cityName: "El Porvenir",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "PVF",
    airportName: "Placerville",
    cityCode: "PVF",
    cityName: "Placerville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PVG",
    airportName: "Pu Dong",
    cityCode: "SHA",
    cityName: "Shanghai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "PVH",
    airportName: "Governador Jorge Teixeira",
    cityCode: "PVH",
    cityName: "Porto Velho",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PVI",
    airportName: "Edu Chaves",
    cityCode: "PVI",
    cityName: "Paranavai",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "PVK",
    airportName: "Aktion",
    cityCode: "PVK",
    cityName: "Preveza/Lefkas",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "PVN",
    airportName: "Pleven",
    cityCode: "PVN",
    cityName: "Pleven",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "PVO",
    airportName: "Reales Tamarindos",
    cityCode: "PVO",
    cityName: "Portoviejo",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "PVR",
    airportName: "Licenciado Gustavo DÃ­az Ordaz Intl.",
    cityCode: "PVR",
    cityName: "Puerto Vallarta",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PVS",
    airportName: "Provideniya",
    cityCode: "PVS",
    cityName: "Provideniya",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PVU",
    airportName: "Provo",
    cityCode: "PVU",
    cityName: "Provo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PVW",
    airportName: "Hale County",
    cityCode: "PVW",
    cityName: "Plainview",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PVX",
    airportName: "Provedenia",
    cityCode: "PVX",
    cityName: "Provedenia",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PVY",
    airportName: "Pope Vanoy",
    cityCode: "PVY",
    cityName: "Pope Vanoy",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PVZ",
    airportName: "Casement",
    cityCode: "PVZ",
    cityName: "Painesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PWA",
    airportName: "Wiley Post",
    cityCode: "PWA",
    cityName: "Oklahoma City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PWD",
    airportName: "Sherwood",
    cityCode: "PWD",
    cityName: "Plentywood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PWE",
    airportName: "Pevek",
    cityCode: "PWE",
    cityName: "Pevek",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PWI",
    airportName: "Beles",
    cityCode: "PWI",
    cityName: "Pawi",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "PWK",
    airportName: "Pal-Waukee",
    cityCode: "PWK",
    cityName: "Chicago",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PWL",
    airportName: "Purwokerto",
    cityCode: "PWL",
    cityName: "Purwokerto",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "PWM",
    airportName: "Intl Jetport",
    cityCode: "PWM",
    cityName: "Portland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PWN",
    airportName: "Pitts Town",
    cityCode: "PWN",
    cityName: "Pitts Town",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "PWO",
    airportName: "Pweto",
    cityCode: "PWO",
    cityName: "Pweto",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "PWQ",
    airportName: "Pavlodar",
    cityCode: "PWQ",
    cityName: "Pavlodar",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "PWR",
    airportName: "Port Walter",
    cityCode: "PWR",
    cityName: "Port Walter",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PWT",
    airportName: "Bremerton",
    cityCode: "PWT",
    cityName: "Bremerton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PXL",
    airportName: "Polacca",
    cityCode: "PXL",
    cityName: "Polacca",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PXM",
    airportName: "Puerto Escondido",
    cityCode: "PXM",
    cityName: "Puerto Escondido",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "PXO",
    airportName: "Porto Santo",
    cityCode: "PXO",
    cityName: "Porto Santo",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "PXR",
    airportName: "Surin",
    cityCode: "PXR",
    cityName: "Surin",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PXS",
    airportName: "Puerto De Santa Maria",
    cityCode: "PXS",
    cityName: "Puerto De Santa Maria",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "PXU",
    airportName: "Pleiku",
    cityCode: "PXU",
    cityName: "Pleiku",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "PYA",
    airportName: "Puerto Boyaca",
    cityCode: "PYA",
    cityName: "Puerto Boyaca",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PYB",
    airportName: "Jeypore",
    cityCode: "PYB",
    cityName: "Jeypore",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "PYC",
    airportName: "Playon Chico",
    cityCode: "PYC",
    cityName: "Playon Chico",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "PYE",
    airportName: "Penrhyn Island",
    cityCode: "PYE",
    cityName: "Penrhyn Island",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "PYH",
    airportName: "Puerto Ayacucho",
    cityCode: "PYH",
    cityName: "Puerto Ayacucho",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "PYJ",
    airportName: "Polyarnyj",
    cityCode: "PYJ",
    cityName: "Polyarnyj",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "PYL",
    airportName: "Perry SPB",
    cityCode: "PYL",
    cityName: "Perry Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PYM",
    airportName: "Plymouth",
    cityCode: "PYM",
    cityName: "Plymouth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "PYN",
    airportName: "Payan",
    cityCode: "PYN",
    cityName: "Payan",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PYO",
    airportName: "Putumayo",
    cityCode: "PYO",
    cityName: "Putumayo",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "PYR",
    airportName: "Andravida",
    cityCode: "PYR",
    cityName: "Pyrgos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "PYV",
    airportName: "Yaviza",
    cityCode: "PYV",
    cityName: "Yaviza",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "PYX",
    airportName: "Pattaya",
    cityCode: "PYX",
    cityName: "Pattaya",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "PZA",
    airportName: "Casanare",
    cityCode: "PZA",
    cityName: "Paz De Ariporo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "PZB",
    airportName: "Pietermaritzburg Oribi",
    cityCode: "PZB",
    cityName: "Pietermaritzburg",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "PZE",
    airportName: "Penzance",
    cityCode: "PZE",
    cityName: "Penzance",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "PZH",
    airportName: "Zhob",
    cityCode: "PZH",
    cityName: "Zhob",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "PZK",
    airportName: "Puka Puka Is/Attol",
    cityCode: "PZK",
    cityName: "Puka Puka Is/Attol",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "PZL",
    airportName: "Zulu Inyala",
    cityCode: "PZL",
    cityName: "Phinda",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "PZO",
    airportName: "Puerto Ordaz",
    cityCode: "PZO",
    cityName: "Puerto Ordaz",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "PZU",
    airportName: "Port Sudan",
    cityCode: "PZU",
    cityName: "Port Sudan",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "PZY",
    airportName: "Piestany",
    cityCode: "PZY",
    cityName: "Piestany",
    countryName: "Slovak Republic",
    countryCode: "SK",
  },
  {
    airportCode: "QBC",
    airportName: "Bella Coola",
    cityCode: "QBC",
    cityName: "Bella Coola, BC",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "QCE",
    airportName: "Van Service",
    cityCode: "QCE",
    cityName: "Copper Mountain",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "QCU",
    airportName: "Heliport",
    cityCode: "QCU",
    cityName: "Akunnaaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "QDM",
    airportName: "Shek Mum",
    cityCode: "QDM",
    cityName: "Shek Mum",
    countryName: "Hong Kong",
    countryCode: "HK",
  },
  {
    airportCode: "QDU",
    airportName: "Station",
    cityCode: "QDU",
    cityName: "Dusseldorf",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "QFI",
    airportName: "Heliport",
    cityCode: "QFI",
    cityName: "Iginniarfik",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "QFK",
    airportName: "Harbour",
    cityCode: "QFK",
    cityName: "Selje",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "QFQ",
    airportName: "Harbour",
    cityCode: "QFQ",
    cityName: "Maloy",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "QGQ",
    airportName: "Heliport",
    cityCode: "QGQ",
    cityName: "Attu",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "QGV",
    airportName: "Neu Isenburg",
    cityCode: "QGV",
    cityName: "Frankfurt",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "QGY",
    airportName: "Gyor-Per",
    cityCode: "QGY",
    cityName: "Per",
    countryName: "Hungary",
    countryCode: "HU",
  },
  {
    airportCode: "QJI",
    airportName: "Heliport",
    cityCode: "QJI",
    cityName: "Ikamiut",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "QJV",
    airportName: "Limousine Service",
    cityCode: "QJV",
    cityName: "Skagen Z7",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "QKB",
    airportName: "Van Service",
    cityCode: "QKB",
    cityName: "Breckenridge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "QLX",
    airportName: "Lauterach",
    cityCode: "QLX",
    cityName: "Lauterach",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "QMK",
    airportName: "Heliport",
    cityCode: "QMK",
    cityName: "Niaqornaarsuk",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "QMM",
    airportName: "Marina Di Massa",
    cityCode: "QMM",
    cityName: "Marina Di Massa",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "QMQ",
    airportName: "Murzuq",
    cityCode: "QMQ",
    cityName: "Murzuq",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "QMV",
    airportName: "Montvale",
    cityCode: "QMV",
    cityName: "Montvale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "QNY",
    airportName: "Marine Air Terminal",
    cityCode: "QNY",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "QOF",
    airportName: "Darnitsa Bus Station",
    cityCode: "IEV",
    cityName: "Kiev",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "QOH",
    airportName: "Hotel Rus Bus Station",
    cityCode: "IEV",
    cityName: "Kiev",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "QPG",
    airportName: "Paya Lebar",
    cityCode: "SIN",
    cityName: "Singapore",
    countryName: "Singapore",
    countryCode: "SG",
  },
  {
    airportCode: "QPJ",
    airportName: "Pecs-Pogany",
    cityCode: "QPJ",
    cityName: "Pecs",
    countryName: "Hungary",
    countryCode: "HU",
  },
  {
    airportCode: "QPW",
    airportName: "Heliport",
    cityCode: "QPW",
    cityName: "Kangaatsiaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "QQS",
    airportName: "Railway Station",
    cityCode: "QQS",
    cityName: "Saint Pancras Intl",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "QRA",
    airportName: "Rand/Germiston",
    cityCode: "QRA",
    cityName: "Johannesburg",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "QRO",
    airportName: "Queretaro",
    cityCode: "QRO",
    cityName: "Queretaro",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "QRW",
    airportName: "Warri",
    cityCode: "QRW",
    cityName: "Warri",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "QRY",
    airportName: "Heliport",
    cityCode: "QRY",
    cityName: "Ikerasaarsuk",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "QSF",
    airportName: "Setif",
    cityCode: "QSF",
    cityName: "Setif",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "QSG",
    airportName: "Sonderborg",
    cityCode: "QSG",
    cityName: "Sonderborg",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "QUB",
    airportName: "Ubari",
    cityCode: "QUB",
    cityName: "Ubari",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "QUF",
    airportName: "Pirita Harbour",
    cityCode: "QUF",
    cityName: "Tallinn",
    countryName: "Estonia",
    countryCode: "EE",
  },
  {
    airportCode: "QUP",
    airportName: "Heliport",
    cityCode: "QUP",
    cityName: "Saqqaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "QVL",
    airportName: "Victoria Island",
    cityCode: "QVL",
    cityName: "Victoria Island",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "QVY",
    airportName: "Bus Station",
    cityCode: "QVY",
    cityName: "Kouvola",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "QWF",
    airportName: "Fort Collins Bus Servi",
    cityCode: "QWF",
    cityName: "Fort Collins/Loveland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "QWG",
    airportName: "Wilgrove Air Park",
    cityCode: "QWG",
    cityName: "Charlotte, NC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "QWP",
    airportName: "Van Service",
    cityCode: "QWP",
    cityName: "Winter Park",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "QYW",
    airportName: "Vieux Port",
    cityCode: "QYW",
    cityName: "Cannes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "RAA",
    airportName: "Rakanda",
    cityCode: "RAA",
    cityName: "Rakanda",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RAB",
    airportName: "Tokua",
    cityCode: "RAB",
    cityName: "Rabaul",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RAC",
    airportName: "Horlick",
    cityCode: "RAC",
    cityName: "Racine",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RAD",
    airportName: "Road Town",
    cityCode: "RAD",
    cityName: "Tortola",
    countryName: "Virgin Islands",
    countryCode: "VG",
  },
  {
    airportCode: "RAE",
    airportName: "Arar",
    cityCode: "RAE",
    cityName: "Arar",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "RAG",
    airportName: "Raglan",
    cityCode: "RAG",
    cityName: "Raglan",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "RAH",
    airportName: "Rafha",
    cityCode: "RAH",
    cityName: "Rafha",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "RAI",
    airportName: "Francisco Mendes",
    cityCode: "RAI",
    cityName: "Praia",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "RAJ",
    airportName: "Civil",
    cityCode: "RAJ",
    cityName: "Rajkot",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RAK",
    airportName: "Menara",
    cityCode: "RAK",
    cityName: "Marrakech",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "RAL",
    airportName: "Riverside Municipal",
    cityCode: "RAL",
    cityName: "Riverside",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RAM",
    airportName: "Ramingining",
    cityCode: "RAM",
    cityName: "Ramingining",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RAN",
    airportName: "La Spreta",
    cityCode: "RAN",
    cityName: "Ravenna",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "RAO",
    airportName: "Leite Lopes",
    cityCode: "RAO",
    cityName: "Ribeirao Preto",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RAP",
    airportName: "Regional",
    cityCode: "RAP",
    cityName: "Rapid City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RAQ",
    airportName: "Sugimanuru",
    cityCode: "RAQ",
    cityName: "Raha",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "RAR",
    airportName: "Rarotonga",
    cityCode: "RAR",
    cityName: "Rarotonga",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "RAS",
    airportName: "Rasht",
    cityCode: "RAS",
    cityName: "Rasht",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "RAT",
    airportName: "Raduzhnyi",
    cityCode: "RAT",
    cityName: "Raduzhnyi",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "RAU",
    airportName: "Rangpur",
    cityCode: "RAU",
    cityName: "Rangpur",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "RAV",
    airportName: "Cravo Norte",
    cityCode: "RAV",
    cityName: "Cravo Norte",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "RAW",
    airportName: "Arawa",
    cityCode: "RAW",
    cityName: "Arawa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RAX",
    airportName: "Oram",
    cityCode: "RAX",
    cityName: "Oram",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RAY",
    airportName: "Heliport",
    cityCode: "RAY",
    cityName: "Rothesay",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "RAZ",
    airportName: "Rawala Kot",
    cityCode: "RAZ",
    cityName: "Rawala Kot",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "RBA",
    airportName: "Sale",
    cityCode: "RBA",
    cityName: "Rabat",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "RBB",
    airportName: "Borba",
    cityCode: "RBB",
    cityName: "Borba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RBC",
    airportName: "Robinvale",
    cityCode: "RBC",
    cityName: "Robinvale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RBD",
    airportName: "Redbird",
    cityCode: "RBD",
    cityName: "Dallas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RBE",
    airportName: "Ratanakiri",
    cityCode: "RBE",
    cityName: "Ratanakiri",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "RBF",
    airportName: "Big Bear City Arpt",
    cityCode: "RBF",
    cityName: "Big Bear",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RBG",
    airportName: "Municipal",
    cityCode: "RBG",
    cityName: "Roseburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RBH",
    airportName: "Brooks Lodge",
    cityCode: "RBH",
    cityName: "Brooks Lodge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RBI",
    airportName: "Rabi",
    cityCode: "RBI",
    cityName: "Rabi",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "RBJ",
    airportName: "Rebun",
    cityCode: "RBJ",
    cityName: "Rebun",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "RBK",
    airportName: "French Valley",
    cityCode: "RBK",
    cityName: "Rancho",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RBL",
    airportName: "Red Bluff Fss",
    cityCode: "RBL",
    cityName: "Red Bluff",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RBM",
    airportName: "Wallmuhle",
    cityCode: "RBM",
    cityName: "Straubing",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "RBN",
    airportName: "Fort Jefferson",
    cityCode: "RBN",
    cityName: "Fort Jefferson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RBO",
    airportName: "Robore",
    cityCode: "RBO",
    cityName: "Robore",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "RBP",
    airportName: "Rabaraba",
    cityCode: "RBP",
    cityName: "Rabaraba",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RBQ",
    airportName: "Rurrenabaque",
    cityCode: "RBQ",
    cityName: "Rurrenabaque",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "RBR",
    airportName: "Pres. Medici",
    cityCode: "RBR",
    cityName: "Rio Branco",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RBS",
    airportName: "Orbost",
    cityCode: "RBS",
    cityName: "Orbost",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RBT",
    airportName: "Marsabit",
    cityCode: "RBT",
    cityName: "Marsabit",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "RBU",
    airportName: "Roebourne",
    cityCode: "RBU",
    cityName: "Roebourne",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RBV",
    airportName: "Ramata",
    cityCode: "RBV",
    cityName: "Ramata",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "RBW",
    airportName: "Municipal",
    cityCode: "RBW",
    cityName: "Walterboro",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RBY",
    airportName: "Ruby",
    cityCode: "RBY",
    cityName: "Ruby",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RCA",
    airportName: "Ellsworth Afb",
    cityCode: "RCA",
    cityName: "Rapid City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RCB",
    airportName: "Richards Bay",
    cityCode: "RCB",
    cityName: "Richards Bay",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "RCE",
    airportName: "Roche Harbor",
    cityCode: "RCE",
    cityName: "Roche Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RCH",
    airportName: "Riohacha",
    cityCode: "RCH",
    cityName: "Riohacha",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "RCK",
    airportName: "Coffield",
    cityCode: "RCK",
    cityName: "Rockdale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RCL",
    airportName: "Redcliffe",
    cityCode: "RCL",
    cityName: "Redcliffe",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "RCM",
    airportName: "Richmond",
    cityCode: "RCM",
    cityName: "Richmond",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RCN",
    airportName: "American River",
    cityCode: "RCN",
    cityName: "American River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RCO",
    airportName: "Saint Agnant",
    cityCode: "RCO",
    cityName: "Rochefort",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "RCP",
    airportName: "Cinder River",
    cityCode: "RCP",
    cityName: "Cinder River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RCQ",
    airportName: "Reconquista",
    cityCode: "RCQ",
    cityName: "Reconquista",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RCR",
    airportName: "Fulton County",
    cityCode: "RCR",
    cityName: "Rochester",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RCS",
    airportName: "Rochester",
    cityCode: "RCS",
    cityName: "Rochester",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "RCT",
    airportName: "Miller Field",
    cityCode: "RCT",
    cityName: "Reed City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RCU",
    airportName: "Rio Cuarto",
    cityCode: "RCU",
    cityName: "Rio Cuarto",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RCY",
    airportName: "Rum Cay",
    cityCode: "RCY",
    cityName: "Rum Cay",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "RDA",
    airportName: "Rockhampton Downs",
    cityCode: "RDA",
    cityName: "Rockhampton Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RDB",
    airportName: "Red Dog",
    cityCode: "RDB",
    cityName: "Red Dog",
    countryName: "Alaska",
    countryCode: "US",
  },
  {
    airportCode: "RDC",
    airportName: "Redencao",
    cityCode: "RDC",
    cityName: "Redencao",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RDD",
    airportName: "Redding",
    cityCode: "RDD",
    cityName: "Redding",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RDE",
    airportName: "Merdey",
    cityCode: "RDE",
    cityName: "Merdey",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "RDG",
    airportName: "Municipal/Spaatz Fld",
    cityCode: "RDG",
    cityName: "Reading",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RDM",
    airportName: "Roberts Field",
    cityCode: "RDM",
    cityName: "Redmond",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RDN",
    airportName: "LTS Pulau Redang",
    cityCode: "RDN",
    cityName: "Redang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "RDR",
    airportName: "Grand Forks AFB",
    cityCode: "RDR",
    cityName: "Red River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RDT",
    airportName: "Richard Toll",
    cityCode: "RDT",
    cityName: "Richard Toll",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "RDU",
    airportName: "Raleigh-durham Intl. Arpt.",
    cityCode: "RDU",
    cityName: "Raleigh/Durham, NC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RDV",
    airportName: "Red Devil",
    cityCode: "RDV",
    cityName: "Red Devil",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RDZ",
    airportName: "Marcillac",
    cityCode: "RDZ",
    cityName: "Rodez",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "REA",
    airportName: "Reao",
    cityCode: "REA",
    cityName: "Reao",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "REB",
    airportName: "Rechlin",
    cityCode: "REB",
    cityName: "Rechlin",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "REC",
    airportName: "Guararapes Intl",
    cityCode: "REC",
    cityName: "Recife",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RED",
    airportName: "Mifflin County",
    cityCode: "RED",
    cityName: "Reedsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "REE",
    airportName: "Reese AFB",
    cityCode: "REE",
    cityName: "Lubbock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "REG",
    airportName: "Tito Menniti",
    cityCode: "REG",
    cityName: "Reggio Calabria",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "REH",
    airportName: "Rehoboth Beach",
    cityCode: "REH",
    cityName: "Rehoboth Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "REI",
    airportName: "Regina",
    cityCode: "REI",
    cityName: "Regina",
    countryName: "French Guiana",
    countryCode: "GF",
  },
  {
    airportCode: "REL",
    airportName: "Almirante Zar",
    cityCode: "REL",
    cityName: "Trelew",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "REN",
    airportName: "Orenburg",
    cityCode: "REN",
    cityName: "Orenburg",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "REO",
    airportName: "State",
    cityCode: "REO",
    cityName: "Rome",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "REP",
    airportName: "Siem Reap",
    cityCode: "REP",
    cityName: "Siem Reap",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "RER",
    airportName: "Base Aerea Del Sur",
    cityCode: "RER",
    cityName: "Retalhuleu",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "RES",
    airportName: "Resistencia",
    cityCode: "RES",
    cityName: "Resistencia",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RET",
    airportName: "Stolport",
    cityCode: "RET",
    cityName: "Rost",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "REU",
    airportName: "Reus",
    cityCode: "REU",
    cityName: "Reus",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "REW",
    airportName: "Rewa",
    cityCode: "REW",
    cityName: "Rewa",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "REX",
    airportName: "Gen Lucio Blanco",
    cityCode: "REX",
    cityName: "Reynosa",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "REY",
    airportName: "Reyes",
    cityCode: "REY",
    cityName: "Reyes",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "RFA",
    airportName: "Rafai",
    cityCode: "RFA",
    cityName: "Rafai",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "RFD",
    airportName: "Greater Rockford Arpt.",
    cityCode: "CHI",
    cityName: "Chicago",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RFG",
    airportName: "Rooke Field",
    cityCode: "RFG",
    cityName: "Refugio",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RFK",
    airportName: "Rollang Field",
    cityCode: "RFK",
    cityName: "Anguilla",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RFN",
    airportName: "Raufarhofn",
    cityCode: "RFN",
    cityName: "Raufarhofn",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "RFP",
    airportName: "Raiatea",
    cityCode: "RFP",
    cityName: "Raiatea",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "RFR",
    airportName: "Rio Frio",
    cityCode: "RFR",
    cityName: "Rio Frio",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "RGA",
    airportName: "Rio Grande",
    cityCode: "RGA",
    cityName: "Rio Grande",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RGE",
    airportName: "Porgera",
    cityCode: "RGE",
    cityName: "Porgera",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RGH",
    airportName: "Balurghat",
    cityCode: "RGH",
    cityName: "Balurghat",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RGI",
    airportName: "Rangiroa",
    cityCode: "RGI",
    cityName: "Rangiroa",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "RGL",
    airportName: "Internacional",
    cityCode: "RGL",
    cityName: "Rio Gallegos",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RGN",
    airportName: "Mingaladon",
    cityCode: "RGN",
    cityName: "Yangon",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "RGR",
    airportName: "Ranger Municipal",
    cityCode: "RGR",
    cityName: "Ranger",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RGT",
    airportName: "Japura",
    cityCode: "RGT",
    cityName: "Rengat",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "RHA",
    airportName: "Reykholar",
    cityCode: "RHA",
    cityName: "Reykholar",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "RHD",
    airportName: "Rio Hondo",
    cityCode: "RHD",
    cityName: "Rio Hondo",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RHE",
    airportName: "Reims",
    cityCode: "RHE",
    cityName: "Reims",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "RHG",
    airportName: "Ruhengeri",
    cityCode: "RHG",
    cityName: "Ruhengeri",
    countryName: "Rwanda",
    countryCode: "RW",
  },
  {
    airportCode: "RHI",
    airportName: "Oneida County",
    cityCode: "RHI",
    cityName: "Rhinelander",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RHL",
    airportName: "Roy Hill",
    cityCode: "RHL",
    cityName: "Roy Hill",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RHO",
    airportName: "Diagoras",
    cityCode: "RHO",
    cityName: "Rhodes",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "RHP",
    airportName: "Ramechhap",
    cityCode: "RHP",
    cityName: "Ramechhap",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "RHV",
    airportName: "Reid-hillview",
    cityCode: "RHV",
    cityName: "San Jose",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RIA",
    airportName: "Base Aerea De Santa Maria",
    cityCode: "RIA",
    cityName: "Santa Maria, Rs",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RIB",
    airportName: "Gen Buech",
    cityCode: "RIB",
    cityName: "Riberalta",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "RIC",
    airportName: "Richmond Intl. Arpt.",
    cityCode: "RIC",
    cityName: "Richmond, VA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RID",
    airportName: "Richmond Municipal Arpt.",
    cityCode: "RID",
    cityName: "Richmond, IN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RIE",
    airportName: "Rice Lake",
    cityCode: "RIE",
    cityName: "Rice Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RIF",
    airportName: "Reynolds",
    cityCode: "RIF",
    cityName: "Richfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RIG",
    airportName: "Rio Grande",
    cityCode: "RIG",
    cityName: "Rio Grande",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RIJ",
    airportName: "Rioja",
    cityCode: "RIJ",
    cityName: "Rioja",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "RIL",
    airportName: "Garfield County",
    cityCode: "RIL",
    cityName: "Rifle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RIM",
    airportName: "Rodriguez De Mendoza",
    cityCode: "RIM",
    cityName: "Rodriguez De Mendoza",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "RIN",
    airportName: "Ringi Cove",
    cityCode: "RIN",
    cityName: "Ringi Cove",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "RIR",
    airportName: "Riverside Fla-Bob",
    cityCode: "RIR",
    cityName: "Riverside",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RIS",
    airportName: "Rishiri",
    cityCode: "RIS",
    cityName: "Rishiri",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "RIT",
    airportName: "Rio Tigre",
    cityCode: "RIT",
    cityName: "Rio Tigre",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "RIV",
    airportName: "March ARB",
    cityCode: "RIV",
    cityName: "Riverside",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RIW",
    airportName: "Riverton Regional",
    cityCode: "RIW",
    cityName: "Riverton, WY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RIX",
    airportName: "Riga Intl.",
    cityCode: "RIX",
    cityName: "Riga",
    countryName: "Latvia",
    countryCode: "LV",
  },
  {
    airportCode: "RIY",
    airportName: "Riyan Mukalla",
    cityCode: "RIY",
    cityName: "Riyan Mukalla",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "RIZ",
    airportName: "Rio Alzucar",
    cityCode: "RIZ",
    cityName: "Rio Alzucar",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "RJA",
    airportName: "Rajahmundry",
    cityCode: "RJA",
    cityName: "Rajahmundry",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RJB",
    airportName: "Rajbiraj",
    cityCode: "RJB",
    cityName: "Rajbiraj",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "RJI",
    airportName: "Rajouri",
    cityCode: "RJI",
    cityName: "Rajouri",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RJK",
    airportName: "Rijeka",
    cityCode: "RJK",
    cityName: "Rijeka",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "RJL",
    airportName: "Agoncillo",
    cityCode: "RJL",
    cityName: "Logrono",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "RJN",
    airportName: "Rafsanjan",
    cityCode: "RJN",
    cityName: "Rafsanjan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "RKC",
    airportName: "Yreka",
    cityCode: "RKC",
    cityName: "Yreka",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RKD",
    airportName: "Knox County Regional",
    cityCode: "RKD",
    cityName: "Rockland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RKE",
    airportName: "Roskilde Arpt.",
    cityCode: "CPH",
    cityName: "Copenhagen",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "RKH",
    airportName: "Rock Hill",
    cityCode: "RKH",
    cityName: "Rock Hill",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RKI",
    airportName: "Rokot",
    cityCode: "RKI",
    cityName: "Rokot",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "RKO",
    airportName: "Sipora",
    cityCode: "RKO",
    cityName: "Sipora",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "RKP",
    airportName: "Aransas County",
    cityCode: "RKP",
    cityName: "Rockport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RKR",
    airportName: "Robert S Kerr",
    cityCode: "RKR",
    cityName: "Poteau",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RKS",
    airportName: "Sweetwater County",
    cityCode: "RKS",
    cityName: "Rock Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RKT",
    airportName: "Ras Al Khaimah",
    cityCode: "RKT",
    cityName: "Ras Al Khaimah",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "RKU",
    airportName: "Kairuku",
    cityCode: "RKU",
    cityName: "Yule Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RKV",
    airportName: "Keflavik",
    cityCode: "REK",
    cityName: "Reykjavik",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "RKW",
    airportName: "Municipal",
    cityCode: "RKW",
    cityName: "Rockwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RKY",
    airportName: "Rokeby",
    cityCode: "RKY",
    cityName: "Rokeby",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RLA",
    airportName: "National",
    cityCode: "RLA",
    cityName: "Rolla",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RLD",
    airportName: "Richland",
    cityCode: "RLD",
    cityName: "Richland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RLG",
    airportName: "Laage",
    cityCode: "RLG",
    cityName: "Rostock-laage",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "RLO",
    airportName: "Valle Del Conlara",
    cityCode: "RLO",
    cityName: "Merlo",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RLP",
    airportName: "Rosella Plains",
    cityCode: "RLP",
    cityName: "Rosella Plains",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RLT",
    airportName: "Arlit",
    cityCode: "RLT",
    cityName: "Arlit",
    countryName: "Niger",
    countryCode: "NE",
  },
  {
    airportCode: "RLU",
    airportName: "Bornite Upper",
    cityCode: "RLU",
    cityName: "Bornite",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RMA",
    airportName: "Roma",
    cityCode: "RMA",
    cityName: "Roma",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RMB",
    airportName: "Buraimi",
    cityCode: "RMB",
    cityName: "Buraimi",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "RMC",
    airportName: "Machesney",
    cityCode: "RMC",
    cityName: "Rockford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RMD",
    airportName: "Ramagundam",
    cityCode: "RMD",
    cityName: "Ramagundam",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RME",
    airportName: "Griffiss AFB",
    cityCode: "RME",
    cityName: "Rome, NY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RMF",
    airportName: "Marsa Alam Intl",
    cityCode: "RMF",
    cityName: "Marsa Alam",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "RMG",
    airportName: "Richard B Russell",
    cityCode: "RMG",
    cityName: "Rome",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RMI",
    airportName: "Miramare",
    cityCode: "RMI",
    cityName: "Rimini",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "RMK",
    airportName: "Renmark",
    cityCode: "RMK",
    cityName: "Renmark",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RMN",
    airportName: "Rumginae",
    cityCode: "RMN",
    cityName: "Rumginae",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RMP",
    airportName: "Rampart",
    cityCode: "RMP",
    cityName: "Rampart",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RMQ",
    airportName: "Chingchuankang",
    cityCode: "RMQ",
    cityName: "Taichung",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "RMS",
    airportName: "Ramstein",
    cityCode: "RMS",
    cityName: "Ramstein",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "RNA",
    airportName: "Ulawa Arpt.",
    cityCode: "RNA",
    cityName: "Arona",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "RNB",
    airportName: "Kallinge",
    cityCode: "RNB",
    cityName: "Ronneby",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "RNC",
    airportName: "Warren County",
    cityCode: "RNC",
    cityName: "Mcminnville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RND",
    airportName: "Randolph AFB",
    cityCode: "RND",
    cityName: "San Antonio",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RNE",
    airportName: "Renaison",
    cityCode: "RNE",
    cityName: "Roanne",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "RNG",
    airportName: "Rangely",
    cityCode: "RNG",
    cityName: "Rangely",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RNH",
    airportName: "Municipal",
    cityCode: "RNH",
    cityName: "New Richmond",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RNI",
    airportName: "Corn Island",
    cityCode: "RNI",
    cityName: "Corn Island",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "RNJ",
    airportName: "Yoronjima",
    cityCode: "RNJ",
    cityName: "Yoronjima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "RNL",
    airportName: "Rennell",
    cityCode: "RNL",
    cityName: "Rennell",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "RNN",
    airportName: "Bornholm",
    cityCode: "RNN",
    cityName: "Ronne",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "RNO",
    airportName: "Reno-Tahoe Intl.",
    cityCode: "RNO",
    cityName: "Reno, NV",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RNP",
    airportName: "Rongelap Island",
    cityCode: "RNP",
    cityName: "Rongelap Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "RNR",
    airportName: "Robinson River",
    cityCode: "RNR",
    cityName: "Robinson River",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RNS",
    airportName: "St Jacques",
    cityCode: "RNS",
    cityName: "Rennes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "RNT",
    airportName: "Renton",
    cityCode: "RNT",
    cityName: "Renton, WA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RNU",
    airportName: "Ranau",
    cityCode: "RNU",
    cityName: "Ranau",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "RNZ",
    airportName: "Rensselaer",
    cityCode: "RNZ",
    cityName: "Rensselaer",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ROA",
    airportName: "Roanoke Regional Arpt.",
    cityCode: "ROA",
    cityName: "Roanoke",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ROB",
    airportName: "Roberts Intl",
    cityCode: "ROB",
    cityName: "Monrovia",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "ROC",
    airportName: "Greater Rochester Intl.",
    cityCode: "ROC",
    cityName: "Rochester, NY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ROD",
    airportName: "Robertson",
    cityCode: "ROD",
    cityName: "Robertson",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "ROG",
    airportName: "Rogers",
    cityCode: "ROG",
    cityName: "Rogers",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ROH",
    airportName: "Robinhood",
    cityCode: "ROH",
    cityName: "Robinhood",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ROI",
    airportName: "Roi Et Arpt.",
    cityCode: "ROI",
    cityName: "Roi Et",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "ROK",
    airportName: "Rockhampton",
    cityCode: "ROK",
    cityName: "Rockhampton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ROL",
    airportName: "Roosevelt",
    cityCode: "ROL",
    cityName: "Roosevelt",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RON",
    airportName: "Rondon",
    cityCode: "RON",
    cityName: "Rondon",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ROO",
    airportName: "Rondonopolis",
    cityCode: "ROO",
    cityName: "Rondonopolis",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ROP",
    airportName: "Rota",
    cityCode: "ROP",
    cityName: "Rota",
    countryName: "Northern Mariana Islands",
    countryCode: "MP",
  },
  {
    airportCode: "ROR",
    airportName: "Babelthuap/Koror",
    cityCode: "ROR",
    cityName: "Koror",
    countryName: "Palau",
    countryCode: "PW",
  },
  {
    airportCode: "ROS",
    airportName: "Fisherton",
    cityCode: "ROS",
    cityName: "Rosario",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "ROT",
    airportName: "Rotorua",
    cityCode: "ROT",
    cityName: "Rotorua",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "ROU",
    airportName: "Rousse",
    cityCode: "ROU",
    cityName: "Rousse",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "ROV",
    airportName: "Rostov",
    cityCode: "ROV",
    cityName: "Rostov",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ROW",
    airportName: "Industrial",
    cityCode: "ROW",
    cityName: "Roswell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ROY",
    airportName: "Rio Mayo",
    cityCode: "ROY",
    cityName: "Rio Mayo",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RPA",
    airportName: "Rolpa",
    cityCode: "RPA",
    cityName: "Rolpa",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "RPB",
    airportName: "Roper Bar",
    cityCode: "RPB",
    cityName: "Roper Bar",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RPM",
    airportName: "Ngukurr",
    cityCode: "RPM",
    cityName: "Ngukurr",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RPN",
    airportName: "Rosh Pina",
    cityCode: "RPN",
    cityName: "Rosh Pina",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "RPR",
    airportName: "Swami Vivekananda Airports",
    cityCode: "RPR",
    cityName: "Raipur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RPV",
    airportName: "Roper Valley",
    cityCode: "RPV",
    cityName: "Roper Valley",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RPX",
    airportName: "Roundup",
    cityCode: "RPX",
    cityName: "Roundup",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RRA",
    airportName: "Ronda",
    cityCode: "RRA",
    cityName: "Ronda",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "RRE",
    airportName: "Marree",
    cityCode: "RRE",
    cityName: "Marree",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RRG",
    airportName: "Rodrigues Is",
    cityCode: "RRG",
    cityName: "Rodrigues Is",
    countryName: "Mauritius",
    countryCode: "MU",
  },
  {
    airportCode: "RRI",
    airportName: "Barora",
    cityCode: "RRI",
    cityName: "Barora",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "RRK",
    airportName: "Rourkela",
    cityCode: "RRK",
    cityName: "Rourkela",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RRL",
    airportName: "Municipal",
    cityCode: "RRL",
    cityName: "Merrill",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RRM",
    airportName: "Marromeu",
    cityCode: "RRM",
    cityName: "Marromeu",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "RRN",
    airportName: "Serra Norte",
    cityCode: "RRN",
    cityName: "Serra Norte",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RRO",
    airportName: "Sorrento",
    cityCode: "RRO",
    cityName: "Naples",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "RRS",
    airportName: "Roros",
    cityCode: "RRS",
    cityName: "Roros",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "RRT",
    airportName: "Warroad",
    cityCode: "RRT",
    cityName: "Warroad",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RRV",
    airportName: "Robinson River",
    cityCode: "RRV",
    cityName: "Robinson River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RSA",
    airportName: "Santa Rosa",
    cityCode: "RSA",
    cityName: "Santa Rosa",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RSB",
    airportName: "Roseberth",
    cityCode: "RSB",
    cityName: "Roseberth",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RSD",
    airportName: "S Eleuthera",
    cityCode: "RSD",
    cityName: "Rock Sound",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "RSE",
    airportName: "Rose Bay",
    cityCode: "SYD",
    cityName: "Sydney",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RSG",
    airportName: "Serra Pelada",
    cityCode: "RSG",
    cityName: "Serra Pelada",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RSH",
    airportName: "Russian SPB",
    cityCode: "RSH",
    cityName: "Russian Mission",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RSI",
    airportName: "Rio Sidra",
    cityCode: "RSI",
    cityName: "Rio Sidra",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "RSJ",
    airportName: "Rosario SPB",
    cityCode: "RSJ",
    cityName: "Rosario",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RSK",
    airportName: "Ransiki",
    cityCode: "RSK",
    cityName: "Ransiki",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "RSL",
    airportName: "Russell",
    cityCode: "RSL",
    cityName: "Russell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RSN",
    airportName: "Ruston",
    cityCode: "RSN",
    cityName: "Ruston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RSP",
    airportName: "Raspberry Strait",
    cityCode: "RSP",
    cityName: "Raspberry Strait",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RSS",
    airportName: "Roseires",
    cityCode: "RSS",
    cityName: "Roseires",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "RST",
    airportName: "Intl.",
    cityCode: "RST",
    cityName: "Rochester",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RSU",
    airportName: "Yeosu",
    cityCode: "RSU",
    cityName: "Yeosu",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "RSW",
    airportName: "Southwest Florida Reg",
    cityCode: "RSW",
    cityName: "Fort Myers",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RSX",
    airportName: "Rouses Point",
    cityCode: "RSX",
    cityName: "Rouses Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RTA",
    airportName: "Rotuma Island",
    cityCode: "RTA",
    cityName: "Rotuma Island",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "RTB",
    airportName: "Roatan",
    cityCode: "RTB",
    cityName: "Roatan",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "RTC",
    airportName: "Ratnagiri",
    cityCode: "RTC",
    cityName: "Ratnagiri",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RTD",
    airportName: "Rotunda",
    cityCode: "RTD",
    cityName: "Rotunda",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RTE",
    airportName: "Marguerite Bay",
    cityCode: "RTE",
    cityName: "Marguerite Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RTG",
    airportName: "Ruteng",
    cityCode: "RTG",
    cityName: "Ruteng",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "RTI",
    airportName: "Roti",
    cityCode: "RTI",
    cityName: "Roti",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "RTL",
    airportName: "Spirit Lake",
    cityCode: "RTL",
    cityName: "Spirit Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RTM",
    airportName: "Rotterdam Zestienhoven",
    cityCode: "RTM",
    cityName: "Rotterdam",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "RTN",
    airportName: "Crews Field",
    cityCode: "RTN",
    cityName: "Raton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RTP",
    airportName: "Rutland Plains",
    cityCode: "RTP",
    cityName: "Rutland Plains",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RTS",
    airportName: "Rottnest Island",
    cityCode: "RTS",
    cityName: "Rottnest Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RTW",
    airportName: "Saratov",
    cityCode: "RTW",
    cityName: "Saratov",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "RTY",
    airportName: "Merty",
    cityCode: "RTY",
    cityName: "Merty",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RUA",
    airportName: "Arua",
    cityCode: "RUA",
    cityName: "Arua",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "RUG",
    airportName: "Rugao",
    cityCode: "RUG",
    cityName: "Rugao",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "RUH",
    airportName: "King Khaled Intl",
    cityCode: "RUH",
    cityName: "Riyadh",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "RUI",
    airportName: "Municipal",
    cityCode: "RUI",
    cityName: "Ruidoso",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RUK",
    airportName: "Rukumkot",
    cityCode: "RUK",
    cityName: "Rukumkot",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "RUM",
    airportName: "Rumjatar",
    cityCode: "RUM",
    cityName: "Rumjatar",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "RUN",
    airportName: "Gillot",
    cityCode: "RUN",
    cityName: "St Denis de la Reunion",
    countryName: "Reunion",
    countryCode: "RE",
  },
  {
    airportCode: "RUP",
    airportName: "Rupsi",
    cityCode: "RUP",
    cityName: "Rupsi",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "RUR",
    airportName: "Rurutu",
    cityCode: "RUR",
    cityName: "Rurutu",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "RUS",
    airportName: "Marau Sound",
    cityCode: "RUS",
    cityName: "Marau Sound",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "RUT",
    airportName: "Rutland",
    cityCode: "RUT",
    cityName: "Rutland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RUU",
    airportName: "Ruti",
    cityCode: "RUU",
    cityName: "Ruti",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "RUV",
    airportName: "Rubelsanto",
    cityCode: "RUV",
    cityName: "Rubelsanto",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "RUY",
    airportName: "Copan",
    cityCode: "RUY",
    cityName: "Copan",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "RVA",
    airportName: "Farafangana",
    cityCode: "RVA",
    cityName: "Farafangana",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "RVC",
    airportName: "Rivercess",
    cityCode: "RVC",
    cityName: "Rivercess",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "RVD",
    airportName: "Rio Verde",
    cityCode: "RVD",
    cityName: "Rio Verde",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RVE",
    airportName: "Los Colonizadores",
    cityCode: "RVE",
    cityName: "Saravena",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "RVH",
    airportName: "Rzhevka",
    cityCode: "RVH",
    cityName: "St Petersburg",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "RVK",
    airportName: "Ryumsjoen",
    cityCode: "RVK",
    cityName: "Roervik",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "RVN",
    airportName: "Rovaniemi",
    cityCode: "RVN",
    cityName: "Rovaniemi",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "RVO",
    airportName: "Reivilo",
    cityCode: "RVO",
    cityName: "Reivilo",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "RVR",
    airportName: "Green River",
    cityCode: "RVR",
    cityName: "Green River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RVT",
    airportName: "Ravensthorpe",
    cityCode: "RVT",
    cityName: "Ravensthorpe",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "RVV",
    airportName: "Raivavae",
    cityCode: "RVV",
    cityName: "Rairua",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "RVY",
    airportName: "Rivera",
    cityCode: "RVY",
    cityName: "Rivera",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "RWB",
    airportName: "Rowan Bay",
    cityCode: "RWB",
    cityName: "Rowan Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RWF",
    airportName: "Redwood Falls Muni",
    cityCode: "RWF",
    cityName: "Redwood Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RWI",
    airportName: "Rocky Mount-wilson",
    cityCode: "RWI",
    cityName: "Rocky Mount,",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RWL",
    airportName: "Rawlins",
    cityCode: "RWL",
    cityName: "Rawlins",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RWN",
    airportName: "Rovno",
    cityCode: "RWN",
    cityName: "Rovno",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "RWS",
    airportName: "Sumare",
    cityCode: "RWS",
    cityName: "Sumare",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "RXA",
    airportName: "Raudha",
    cityCode: "RXA",
    cityName: "Raudha",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "RXS",
    airportName: "Roxas City",
    cityCode: "RXS",
    cityName: "Roxas City",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "RYB",
    airportName: "Rybinsk",
    cityCode: "RYB",
    cityName: "Rybinsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "RYK",
    airportName: "Rahim Yar Khan",
    cityCode: "RYK",
    cityName: "Rahim Yar Khan",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "RYN",
    airportName: "Medis",
    cityCode: "RYN",
    cityName: "Royan",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "RYO",
    airportName: "Rio Turbio",
    cityCode: "RYO",
    cityName: "Rio Turbio",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RZA",
    airportName: "Santa Cruz",
    cityCode: "RZA",
    cityName: "Santa Cruz",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "RZE",
    airportName: "Jasionka",
    cityCode: "RZE",
    cityName: "Rzeszow",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "RZH",
    airportName: "Quartz Hill",
    cityCode: "RZH",
    cityName: "Lancaster",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "RZN",
    airportName: "Ryazan",
    cityCode: "RZN",
    cityName: "Ryazan",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "RZP",
    airportName: "CLR Arpt.",
    cityCode: "RZP",
    cityName: "Taytay Sandoval",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "RZR",
    airportName: "Ramsar",
    cityCode: "RZR",
    cityName: "Ramsar",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "RZS",
    airportName: "Sawan",
    cityCode: "RZS",
    cityName: "Sawan",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "RZZ",
    airportName: "Halifax County",
    cityCode: "RZZ",
    cityName: "Roanoke Rapids",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAA",
    airportName: "Shively Field",
    cityCode: "SAA",
    cityName: "Saratoga",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAB",
    airportName: "J. Yrausquin",
    cityCode: "SAB",
    cityName: "Saba Island",
    countryName: "Netherlands Antilles",
    countryCode: "AN",
  },
  {
    airportCode: "SAC",
    airportName: "Executive",
    cityCode: "SAC",
    cityName: "Sacramento",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAD",
    airportName: "Safford",
    cityCode: "SAD",
    cityName: "Safford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAE",
    airportName: "Sangir",
    cityCode: "SAE",
    cityName: "Sangir",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SAF",
    airportName: "Santa Fe",
    cityCode: "SAF",
    cityName: "Santa Fe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAG",
    airportName: "Sagwon",
    cityCode: "SAG",
    cityName: "Sagwon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAH",
    airportName: "El Rahaba Arpt.",
    cityCode: "SAH",
    cityName: "Sanaa",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "SAJ",
    airportName: "Sirajganj",
    cityCode: "SAJ",
    cityName: "Sirajganj",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "SAK",
    airportName: "Saudarkrokur",
    cityCode: "SAK",
    cityName: "Saudarkrokur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "SAL",
    airportName: "El Salvador Intl.",
    cityCode: "SAL",
    cityName: "San Salvador",
    countryName: "El Salvador",
    countryCode: "SV",
  },
  {
    airportCode: "SAM",
    airportName: "Salamo",
    cityCode: "SAM",
    cityName: "Salamo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SAN",
    airportName: "San Diego Intl. Arpt.",
    cityCode: "SAN",
    cityName: "San Diego",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAP",
    airportName: "Ramon Villeda Morales Intl.",
    cityCode: "SAP",
    cityName: "San Pedro Sula",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "SAQ",
    airportName: "San Andros",
    cityCode: "SAQ",
    cityName: "San Andros",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "SAR",
    airportName: "Sparta Community",
    cityCode: "SAR",
    cityName: "Sparta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAS",
    airportName: "Salton City",
    cityCode: "SAS",
    cityName: "Salton City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAT",
    airportName: "San Antonio Intl.",
    cityCode: "SAT",
    cityName: "San Antonio",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAU",
    airportName: "Sawu",
    cityCode: "SAU",
    cityName: "Sawu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SAV",
    airportName: "Savannah/Hilton Head",
    cityCode: "SAV",
    cityName: "Savannah",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SAW",
    airportName: "Sabiha Gokcen",
    cityCode: "IST",
    cityName: "Istanbul",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "SAX",
    airportName: "Sambu",
    cityCode: "SAX",
    cityName: "Sambu",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "SAY",
    airportName: "Siena",
    cityCode: "SAY",
    cityName: "Siena",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "SAZ",
    airportName: "Sasstown",
    cityCode: "SAZ",
    cityName: "Sasstown",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "SBA",
    airportName: "Municipal",
    cityCode: "SBA",
    cityName: "Santa Barbara, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBB",
    airportName: "Santa Barbara Ba",
    cityCode: "SBB",
    cityName: "Santa Barbara de Barinas",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SBC",
    airportName: "Selbang",
    cityCode: "SBC",
    cityName: "Selbang",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SBD",
    airportName: "Norton Afb",
    cityCode: "SBD",
    cityName: "San Bernardino",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBE",
    airportName: "Suabi",
    cityCode: "SBE",
    cityName: "Suabi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SBF",
    airportName: "Sardeh Band",
    cityCode: "SBF",
    cityName: "Sardeh Band",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "SBG",
    airportName: "Narita",
    cityCode: "SBG",
    cityName: "Sabang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SBH",
    airportName: "St Barthelemy",
    cityCode: "SBH",
    cityName: "St Barthelemy",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "SBI",
    airportName: "Sambailo",
    cityCode: "SBI",
    cityName: "Koundara",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "SBJ",
    airportName: "Sao Mateus",
    cityCode: "SBJ",
    cityName: "Sao Mateus",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SBK",
    airportName: "Saint Brieuc-Armor",
    cityCode: "SBK",
    cityName: "Tremuson",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "SBL",
    airportName: "Yacuma",
    cityCode: "SBL",
    cityName: "Santa Ana",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SBM",
    airportName: "Memorial",
    cityCode: "SBM",
    cityName: "Sheboygan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBN",
    airportName: "South Bend Regional",
    cityCode: "SBN",
    cityName: "South Bend",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBO",
    airportName: "Salina",
    cityCode: "SBO",
    cityName: "Salina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBP",
    airportName: "San Luis County Regional Arpt.",
    cityCode: "SBP",
    cityName: "San Luis Obispo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBQ",
    airportName: "Sibi",
    cityCode: "SBQ",
    cityName: "Sibi",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SBR",
    airportName: "Saibai Island",
    cityCode: "SBR",
    cityName: "Saibai Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SBS",
    airportName: "Steamboat Springs",
    cityCode: "SBS",
    cityName: "Steamboat Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBT",
    airportName: "Tri-City",
    cityCode: "SBT",
    cityName: "San Bernardino",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBU",
    airportName: "Springbok",
    cityCode: "SBU",
    cityName: "Springbok",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "SBV",
    airportName: "Sabah",
    cityCode: "SBV",
    cityName: "Sabah",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SBW",
    airportName: "Sibu",
    cityCode: "SBW",
    cityName: "Sibu",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SBX",
    airportName: "Shelby",
    cityCode: "SBX",
    cityName: "Shelby",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBY",
    airportName: "Wicomico Regional",
    cityCode: "SBY",
    cityName: "Salisbury-Ocean City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SBZ",
    airportName: "Sibiu",
    cityCode: "SBZ",
    cityName: "Sibiu",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "SCA",
    airportName: "Santa Catalina",
    cityCode: "SCA",
    cityName: "Santa Catalina",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SCB",
    airportName: "State",
    cityCode: "SCB",
    cityName: "Scribner",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SCC",
    airportName: "Prudhoe Bay/Deadhorse",
    cityCode: "SCC",
    cityName: "Prudhoe Bay/Deadhorse",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SCD",
    airportName: "Sulaco",
    cityCode: "SCD",
    cityName: "Sulaco",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "SCE",
    airportName: "University Park",
    cityCode: "SCE",
    cityName: "State College",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SCF",
    airportName: "Scottsdale Municipal",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SCG",
    airportName: "Spring Creek",
    cityCode: "SCG",
    cityName: "Spring Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SCH",
    airportName: "Schenectady County",
    cityCode: "SCH",
    cityName: "Schenectady, NY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SCI",
    airportName: "San Cristobal",
    cityCode: "SCI",
    cityName: "San Cristobal",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SCJ",
    airportName: "Smith Cove",
    cityCode: "SCJ",
    cityName: "Smith Cove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SCK",
    airportName: "Stockton Metropolitan Arpt.",
    cityCode: "SCK",
    cityName: "Stockton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SCL",
    airportName: "Arturo Merino Benitez",
    cityCode: "SCL",
    cityName: "Santiago",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "SCM",
    airportName: "SPB",
    cityCode: "SCM",
    cityName: "Scammon Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SCN",
    airportName: "Ensheim",
    cityCode: "SCN",
    cityName: "Saarbruecken",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "SCO",
    airportName: "Shevchenko",
    cityCode: "SCO",
    cityName: "Aktau",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "SCP",
    airportName: "St Crepin",
    cityCode: "SCP",
    cityName: "St Crepin",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "SCQ",
    airportName: "Santiago De Compostela",
    cityCode: "SCQ",
    cityName: "Santiago De Compostela",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "SCS",
    airportName: "Scatsta",
    cityCode: "SCS",
    cityName: "Shetland Islands",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SCT",
    airportName: "Socotra",
    cityCode: "SCT",
    cityName: "Socotra",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "SCU",
    airportName: "Antonio Maceo",
    cityCode: "SCU",
    cityName: "Santiago",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "SCV",
    airportName: "Salcea",
    cityCode: "SCV",
    cityName: "Suceava",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "SCW",
    airportName: "Syktyvkar",
    cityCode: "SCW",
    cityName: "Syktyvkar",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "SCX",
    airportName: "Salina Cruz",
    cityCode: "SCX",
    cityName: "Salina Cruz",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "SCY",
    airportName: "San Cristobal",
    cityCode: "SCY",
    cityName: "Puerto Baquerizo Moreno",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "SCZ",
    airportName: "Santa Cruz Is",
    cityCode: "SCZ",
    cityName: "Santa Cruz Is",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "SDA",
    airportName: "Baghdad Intl.",
    cityCode: "SDA",
    cityName: "Baghdad",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "SDB",
    airportName: "Langebaanweg",
    cityCode: "SDB",
    cityName: "Saldanha Bay",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "SDC",
    airportName: "Sandcreek",
    cityCode: "SDC",
    cityName: "Sandcreek",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "SDD",
    airportName: "Lubango",
    cityCode: "SDD",
    cityName: "Lubango",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "SDE",
    airportName: "Santiago Del Estero",
    cityCode: "SDE",
    cityName: "Santiago Del Estero",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "SDF",
    airportName: "Louisville Intl.",
    cityCode: "SDF",
    cityName: "Louisville, KY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SDG",
    airportName: "Sanandaj",
    cityCode: "SDG",
    cityName: "Sanandaj",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "SDH",
    airportName: "Santa Rosa Copan",
    cityCode: "SDH",
    cityName: "Santa Rosa Copan",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "SDI",
    airportName: "Saidor",
    cityCode: "SDI",
    cityName: "Saidor",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SDJ",
    airportName: "Sendai",
    cityCode: "SDJ",
    cityName: "Sendai",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "SDK",
    airportName: "Sandakan",
    cityCode: "SDK",
    cityName: "Sandakan",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SDL",
    airportName: "Sundsvall/harnosand",
    cityCode: "SDL",
    cityName: "Sundsvall",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "SDM",
    airportName: "Brown Field Municipal",
    cityCode: "SDM",
    cityName: "San Diego",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SDN",
    airportName: "Sandane",
    cityCode: "SDN",
    cityName: "Sandane",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SDO",
    airportName: "Ryotsu Sado Is",
    cityCode: "SDO",
    cityName: "Ryotsu Sado Is",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "SDP",
    airportName: "Municipal",
    cityCode: "SDP",
    cityName: "Sand Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SDQ",
    airportName: "Las Americas",
    cityCode: "SDQ",
    cityName: "Santo Domingo",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "SDR",
    airportName: "Santander",
    cityCode: "SDR",
    cityName: "Santander",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "SDS",
    airportName: "Sado Shima",
    cityCode: "SDS",
    cityName: "Sado Shima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "SDT",
    airportName: "Saidu Sharif",
    cityCode: "SDT",
    cityName: "Saidu Sharif",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SDU",
    airportName: "Santos Dumont",
    cityCode: "RIO",
    cityName: "Rio De Janeiro",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SDV",
    airportName: "Sde Dov",
    cityCode: "TLV",
    cityName: "Tel Aviv",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "SDW",
    airportName: "Sandwip",
    cityCode: "SDW",
    cityName: "Sandwip",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "SDX",
    airportName: "Sedona",
    cityCode: "SDX",
    cityName: "Sedona",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SDY",
    airportName: "Richland Municipal",
    cityCode: "SDY",
    cityName: "Sidney",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SEA",
    airportName: "Seattle-Tacoma Intl.",
    cityCode: "SEA",
    cityName: "Seattle, WA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SEB",
    airportName: "Sebha",
    cityCode: "SEB",
    cityName: "Sebha",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "SEC",
    airportName: "Serre Chevalier",
    cityCode: "SEC",
    cityName: "Serre Chevalier",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "SEE",
    airportName: "Gillespie Field",
    cityCode: "SEE",
    cityName: "San Diego",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SEF",
    airportName: "Sebring Regional Arpt.",
    cityCode: "SEF",
    cityName: "Sebring, Fl",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SEG",
    airportName: "Penn Valley",
    cityCode: "SEG",
    cityName: "Selinsgrove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SEH",
    airportName: "Senggeh",
    cityCode: "SEH",
    cityName: "Senggeh",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SEI",
    airportName: "Senhor Do Bonfim",
    cityCode: "SEI",
    cityName: "Senhor Do Bonfim",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SEJ",
    airportName: "Seydisfjordur",
    cityCode: "SEJ",
    cityName: "Seydisfjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "SEK",
    airportName: "Er Errachidia",
    cityCode: "SEK",
    cityName: "Ksar Es Souk",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "SEM",
    airportName: "Craig AFB",
    cityCode: "SEM",
    cityName: "Selma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SEN",
    airportName: "London Southend",
    cityCode: "LON",
    cityName: "London",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SEO",
    airportName: "Seguela",
    cityCode: "SEO",
    cityName: "Seguela",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "SEP",
    airportName: "Clark Field",
    cityCode: "SEP",
    cityName: "Stephenville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SEQ",
    airportName: "Sungai Pakning",
    cityCode: "SEQ",
    cityName: "Sungai Pakning",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SER",
    airportName: "Freeman Municipal",
    cityCode: "SER",
    cityName: "Seymour",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SES",
    airportName: "Selfield",
    cityCode: "SES",
    cityName: "Selma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SET",
    airportName: "San Esteban",
    cityCode: "SET",
    cityName: "San Esteban",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "SEU",
    airportName: "Seronera",
    cityCode: "SEU",
    cityName: "Seronera",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "SEV",
    airportName: "Severodoneck",
    cityCode: "SEV",
    cityName: "Severodoneck",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "SEW",
    airportName: "Siwa",
    cityCode: "SEW",
    cityName: "Siwa",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "SEX",
    airportName: "Sembach",
    cityCode: "SEX",
    cityName: "Sembach",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "SEY",
    airportName: "Selibaby",
    cityCode: "SEY",
    cityName: "Selibaby",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "SEZ",
    airportName: "Seychelles Intl. Arpt.",
    cityCode: "SEZ",
    cityName: "Mahe Island",
    countryName: "Seychelles",
    countryCode: "SC",
  },
  {
    airportCode: "SFA",
    airportName: "Sfax Thyna",
    cityCode: "SFA",
    cityName: "Sfax",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "SFB",
    airportName: "Orlando Sanford Intl",
    cityCode: "ORL",
    cityName: "Sanford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SFC",
    airportName: "St Francois",
    cityCode: "SFC",
    cityName: "St Francois",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "SFD",
    airportName: "Las Flecheras",
    cityCode: "SFD",
    cityName: "San Fernando De Apure",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SFE",
    airportName: "San Fernando",
    cityCode: "SFE",
    cityName: "San Fernando",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "SFF",
    airportName: "Felts Field",
    cityCode: "SFF",
    cityName: "Spokane",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SFG",
    airportName: "Esperance",
    cityCode: "SFG",
    cityName: "St Martin",
    countryName: "Netherlands Antilles",
    countryCode: "GP",
  },
  {
    airportCode: "SFH",
    airportName: "San Felipe",
    cityCode: "SFH",
    cityName: "San Felipe",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "SFI",
    airportName: "Safi",
    cityCode: "SFI",
    cityName: "Safi",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "SFJ",
    airportName: "Kangerlussuaq",
    cityCode: "SFJ",
    cityName: "Kangerlussuaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "SFL",
    airportName: "Sao Filipe",
    cityCode: "SFL",
    cityName: "Sao Filipe",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "SFM",
    airportName: "Sanford",
    cityCode: "SFM",
    cityName: "Sanford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SFN",
    airportName: "Santa Fe",
    cityCode: "SFN",
    cityName: "Santa Fe",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "SFO",
    airportName: "San Francisco Intl.",
    cityCode: "SFO",
    cityName: "San Francisco",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SFP",
    airportName: "Surfers Paradise",
    cityCode: "SFP",
    cityName: "Surfers Paradise",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SFQ",
    airportName: "Sanliurfa",
    cityCode: "SFQ",
    cityName: "Sanliurfa",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "SFR",
    airportName: "San Fernando",
    cityCode: "SFR",
    cityName: "San Fernando, CA",
    countryName: "Columbia",
    countryCode: "COL",
  },
  {
    airportCode: "SFT",
    airportName: "Skelleftea",
    cityCode: "SFT",
    cityName: "Skelleftea",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "SFU",
    airportName: "Safia",
    cityCode: "SFU",
    cityName: "Safia",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SFV",
    airportName: "Santa Fe Do Sul",
    cityCode: "SFV",
    cityName: "Santa Fe Do Sul",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SFW",
    airportName: "Santa Fe",
    cityCode: "SFW",
    cityName: "Santa Fe",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "SFX",
    airportName: "San Felix",
    cityCode: "SFX",
    cityName: "San Felix",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SFZ",
    airportName: "North Central",
    cityCode: "SFZ",
    cityName: "Pawtucket, RI",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SGA",
    airportName: "Sheghnan",
    cityCode: "SGA",
    cityName: "Sheghnan",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "SGB",
    airportName: "Singaua",
    cityCode: "SGB",
    cityName: "Singaua",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SGC",
    airportName: "Surgut",
    cityCode: "SGC",
    cityName: "Surgut",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "SGD",
    airportName: "Sonderborg",
    cityCode: "SGD",
    cityName: "Sonderborg",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "SGE",
    airportName: "Siegerland Arpt.",
    cityCode: "SGE",
    cityName: "Siegen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "SGF",
    airportName: "Springfield-Branson Rg",
    cityCode: "SGF",
    cityName: "Springfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SGG",
    airportName: "Simanggang",
    cityCode: "SGG",
    cityName: "Simanggang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SGH",
    airportName: "Springfield",
    cityCode: "SGH",
    cityName: "Springfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SGI",
    airportName: "Sargodha Apt",
    cityCode: "SGI",
    cityName: "Sargodha",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SGJ",
    airportName: "Sagarai",
    cityCode: "SGJ",
    cityName: "Sagarai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SGK",
    airportName: "Sangapi",
    cityCode: "SGK",
    cityName: "Sangapi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SGL",
    airportName: "Sangley Point NAS",
    cityCode: "MNL",
    cityName: "Manila",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "SGM",
    airportName: "San Ignacio",
    cityCode: "SGM",
    cityName: "San Ignacio",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "SGN",
    airportName: "Tan Son Nhat Intl.",
    cityCode: "SGN",
    cityName: "Ho Chi Minh City",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "SGO",
    airportName: "St George",
    cityCode: "SGO",
    cityName: "St George",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SGP",
    airportName: "Shay Gap",
    cityCode: "SGP",
    cityName: "Shay Gap",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SGQ",
    airportName: "Sanggata",
    cityCode: "SGQ",
    cityName: "Sanggata",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SGR",
    airportName: "Sugar Land Regional",
    cityCode: "SGR",
    cityName: "Houston, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SGS",
    airportName: "Sanga Sanga",
    cityCode: "SGS",
    cityName: "Sanga Sanga",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "SGT",
    airportName: "Stuttgart Municipal",
    cityCode: "SGT",
    cityName: "Stuttgart, AR",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SGU",
    airportName: "Municipal",
    cityCode: "SGU",
    cityName: "Saint George",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SGV",
    airportName: "Sierra Grande",
    cityCode: "SGV",
    cityName: "Sierra Grande",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "SGW",
    airportName: "Saginaw Bay",
    cityCode: "SGW",
    cityName: "Saginaw Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SGX",
    airportName: "Songea",
    cityCode: "SGX",
    cityName: "Songea",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "SGY",
    airportName: "Municipal",
    cityCode: "SGY",
    cityName: "Skagway",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SGZ",
    airportName: "Songkhla",
    cityCode: "SGZ",
    cityName: "Songkhla",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "SHA",
    airportName: "Hongqiao",
    cityCode: "SHA",
    cityName: "Shanghai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SHB",
    airportName: "Nakashibetsu",
    cityCode: "SHB",
    cityName: "Nakashibetsu",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "SHC",
    airportName: "Indaselassie",
    cityCode: "SHC",
    cityName: "Indaselassie",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "SHD",
    airportName: "Shenandoah Valley",
    cityCode: "SHD",
    cityName: "Staunton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SHE",
    airportName: "Shenyang",
    cityCode: "SHE",
    cityName: "Shenyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SHF",
    airportName: "Shanhaiguan",
    cityCode: "SHF",
    cityName: "Shanhaiguan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SHG",
    airportName: "Shungnak",
    cityCode: "SHG",
    cityName: "Shungnak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SHH",
    airportName: "Shishmaref",
    cityCode: "SHH",
    cityName: "Shishmaref",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SHI",
    airportName: "Shimojishima",
    cityCode: "SHI",
    cityName: "Shimojishima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "SHJ",
    airportName: "Sharjah",
    cityCode: "SHJ",
    cityName: "Sharjah",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "SHK",
    airportName: "Sehonghong",
    cityCode: "SHK",
    cityName: "Sehonghong",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "SHL",
    airportName: "Barapani",
    cityCode: "SHL",
    cityName: "Shillong",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "SHM",
    airportName: "Shirahama",
    cityCode: "SHM",
    cityName: "Shirahama",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "SHN",
    airportName: "Sanderson Field",
    cityCode: "SHN",
    cityName: "Shelton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SHO",
    airportName: "King Mswati III International Airport",
    cityCode: "SHO",
    cityName: "Manzini",
    countryName: "Swaziland",
    countryCode: "SZ",
  },
  {
    airportCode: "SHP",
    airportName: "Qinhuangdao",
    cityCode: "SHP",
    cityName: "Qinhuangdao",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SHQ",
    airportName: "Southport",
    cityCode: "SHQ",
    cityName: "Southport",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SHR",
    airportName: "Sheridan",
    cityCode: "SHR",
    cityName: "Sheridan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SHS",
    airportName: "Shashi",
    cityCode: "SHS",
    cityName: "Shashi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SHT",
    airportName: "Shepparton",
    cityCode: "SHT",
    cityName: "Shepparton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SHU",
    airportName: "Smith Point",
    cityCode: "SHU",
    cityName: "Smith Point",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SHV",
    airportName: "Regional",
    cityCode: "SHV",
    cityName: "Shreveport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SHW",
    airportName: "Sharurah",
    cityCode: "SHW",
    cityName: "Sharurah",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "SHX",
    airportName: "Shageluk",
    cityCode: "SHX",
    cityName: "Shageluk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SHY",
    airportName: "Shinyanga",
    cityCode: "SHY",
    cityName: "Shinyanga",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "SHZ",
    airportName: "Seshutes",
    cityCode: "SHZ",
    cityName: "Seshutes",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "SIA",
    airportName: "Xiguan",
    cityCode: "SIA",
    cityName: "Xi An",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SIB",
    airportName: "Sibiti",
    cityCode: "SIB",
    cityName: "Sibiti",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "SIC",
    airportName: "Sinop Arpt.",
    cityCode: "SIC",
    cityName: "Sinop",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "SID",
    airportName: "Amilcar Cabral Intl",
    cityCode: "SID",
    cityName: "Sal",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "SIE",
    airportName: "Sines",
    cityCode: "SIE",
    cityName: "Sines",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "SIF",
    airportName: "Simara",
    cityCode: "SIF",
    cityName: "Simara",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "SIG",
    airportName: "Isla Grande",
    cityCode: "SIG",
    cityName: "San Juan",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "SIH",
    airportName: "Silgadi Doti",
    cityCode: "SIH",
    cityName: "Silgadi Doti",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "SII",
    airportName: "Sidi Ifni",
    cityCode: "SII",
    cityName: "Sidi Ifni",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "SIJ",
    airportName: "Siglufjordur",
    cityCode: "SIJ",
    cityName: "Siglufjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "SIK",
    airportName: "Memorial",
    cityCode: "SIK",
    cityName: "Sikeston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SIL",
    airportName: "Sila",
    cityCode: "SIL",
    cityName: "Sila",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SIM",
    airportName: "Simbai",
    cityCode: "SIM",
    cityName: "Simbai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SIN",
    airportName: "Singapore Changi Airport",
    cityCode: "SIN",
    cityName: "Singapore",
    countryName: "Singapore",
    countryCode: "SG",
  },
  {
    airportCode: "SIO",
    airportName: "Smithton",
    cityCode: "SIO",
    cityName: "Smithton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SIP",
    airportName: "Simferopol",
    cityCode: "SIP",
    cityName: "Simferopol",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "SIQ",
    airportName: "Dabo",
    cityCode: "SIQ",
    cityName: "Singkep",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SIR",
    airportName: "Sion",
    cityCode: "SIR",
    cityName: "Sion",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "SIS",
    airportName: "Sishen",
    cityCode: "SIS",
    cityName: "Sishen",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "SIT",
    airportName: "Sitka",
    cityCode: "SIT",
    cityName: "Sitka",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SIU",
    airportName: "Siuna",
    cityCode: "SIU",
    cityName: "Siuna",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "SIV",
    airportName: "County",
    cityCode: "SIV",
    cityName: "Sullivan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SIX",
    airportName: "Singleton",
    cityCode: "SIX",
    cityName: "Singleton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SIY",
    airportName: "Siskiyou County",
    cityCode: "SIY",
    cityName: "Montague",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SIZ",
    airportName: "Sissano",
    cityCode: "SIZ",
    cityName: "Sissano",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SJA",
    airportName: "San Juan",
    cityCode: "SJA",
    cityName: "San Juan",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "SJB",
    airportName: "San Joaquin",
    cityCode: "SJB",
    cityName: "San Joaquin",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SJC",
    airportName: "Mineta San Jose Intl. Arpt.",
    cityCode: "SJC",
    cityName: "San Jose",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SJD",
    airportName: "Los Cabos",
    cityCode: "SJD",
    cityName: "San Jose Del Cabo",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "SJE",
    airportName: "San Jose Del Gua",
    cityCode: "SJE",
    cityName: "San Jose Del Gua",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SJF",
    airportName: "St John Island",
    cityCode: "SJF",
    cityName: "St John Island",
    countryName: "Virgin Islands",
    countryCode: "VI",
  },
  {
    airportCode: "SJG",
    airportName: "San Pedro Jagua",
    cityCode: "SJG",
    cityName: "San Pedro Jagua",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SJH",
    airportName: "San Juan Del Cesar",
    cityCode: "SJH",
    cityName: "San Juan Del Cesar",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SJI",
    airportName: "Mcguire Fld",
    cityCode: "SJI",
    cityName: "San Jose",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "SJJ",
    airportName: "Sarajevo",
    cityCode: "SJJ",
    cityName: "Sarajevo",
    countryName: "Bosnia And Herzegovina",
    countryCode: "BA",
  },
  {
    airportCode: "SJK",
    airportName: "Sao Jose Dos Campos",
    cityCode: "SJK",
    cityName: "Sao Jose Dos Campos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SJL",
    airportName: "Da Cachoeira",
    cityCode: "SJL",
    cityName: "Sao Gabriel",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SJM",
    airportName: "San Juan",
    cityCode: "SJM",
    cityName: "San Juan",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "SJN",
    airportName: "St Johns",
    cityCode: "SJN",
    cityName: "St Johns",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SJO",
    airportName: "Juan Santamaria Intl",
    cityCode: "SJO",
    cityName: "San Jose",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "SJP",
    airportName: "Sao Jose Do Rio Preto",
    cityCode: "SJP",
    cityName: "Sao Jose Do Rio Preto",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SJQ",
    airportName: "Sesheke",
    cityCode: "SJQ",
    cityName: "Sesheke",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "SJR",
    airportName: "San Juan D Ur",
    cityCode: "SJR",
    cityName: "San Juan D Ur",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SJS",
    airportName: "San Jose",
    cityCode: "SJS",
    cityName: "San Jose",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SJT",
    airportName: "Mathis Fld",
    cityCode: "SJT",
    cityName: "San Angelo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SJU",
    airportName: "Luis Munoz Marin Intl",
    cityCode: "SJU",
    cityName: "San Juan",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "SJV",
    airportName: "San Javier",
    cityCode: "SJV",
    cityName: "San Javier",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SJW",
    airportName: "Daguocun",
    cityCode: "SJW",
    cityName: "Shijiazhuang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SJX",
    airportName: "Sartaneja",
    cityCode: "SJX",
    cityName: "Sartaneja",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "SJY",
    airportName: "Ilmajoki",
    cityCode: "SJY",
    cityName: "Seinajoki",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "SJZ",
    airportName: "Sao Jorge Island",
    cityCode: "SJZ",
    cityName: "Sao Jorge Island",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "SKB",
    airportName: "Robert L Bradshaw Intl.",
    cityCode: "SKB",
    cityName: "Basseterre, St. Kitts Island",
    countryName: "Saint Kitts And Nevis",
    countryCode: "KN",
  },
  {
    airportCode: "SKC",
    airportName: "Suki",
    cityCode: "SKC",
    cityName: "Suki",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SKD",
    airportName: "Samarkand",
    cityCode: "SKD",
    cityName: "Samarkand",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "SKE",
    airportName: "Skien",
    cityCode: "SKE",
    cityName: "Skien",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SKF",
    airportName: "Kelly AFB",
    cityCode: "SKF",
    cityName: "San Antonio",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SKG",
    airportName: "Macedonia Intl.",
    cityCode: "SKG",
    cityName: "Thessaloniki",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "SKH",
    airportName: "Kadmandu",
    cityCode: "SKH",
    cityName: "Surkhet",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "SKI",
    airportName: "Skikda",
    cityCode: "SKI",
    cityName: "Skikda",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "SKJ",
    airportName: "Sitkinak Cgs",
    cityCode: "SKJ",
    cityName: "Sitkinak Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SKK",
    airportName: "Shaktoolik",
    cityCode: "SKK",
    cityName: "Shaktoolik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SKL",
    airportName: "Broadford",
    cityCode: "SKL",
    cityName: "Isle Of Skye",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SKM",
    airportName: "Skeldon",
    cityCode: "SKM",
    cityName: "Skeldon",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "SKN",
    airportName: "Skagen",
    cityCode: "SKN",
    cityName: "Stokmarknes",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SKO",
    airportName: "Sokoto",
    cityCode: "SKO",
    cityName: "Sokoto",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "SKP",
    airportName: "Petrovec",
    cityCode: "SKP",
    cityName: "Skopje",
    countryName: "Former Yugoslav Republic Of Macedonia",
    countryCode: "MK",
  },
  {
    airportCode: "SKQ",
    airportName: "Sekakes",
    cityCode: "SKQ",
    cityName: "Sekakes",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "SKR",
    airportName: "Shakiso",
    cityCode: "SKR",
    cityName: "Shakiso",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "SKS",
    airportName: "Vojens",
    cityCode: "SKS",
    cityName: "Vojens",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "SKT",
    airportName: "Sialkot Intl.",
    cityCode: "SKT",
    cityName: "Sialkot",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SKU",
    airportName: "Skiros",
    cityCode: "SKU",
    cityName: "Skiros",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "SKV",
    airportName: "Mount Sinai",
    cityCode: "SKV",
    cityName: "Santa Katarina",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "SKW",
    airportName: "Intermediate",
    cityCode: "SKW",
    cityName: "Skwentna",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SKX",
    airportName: "Saransk",
    cityCode: "SKX",
    cityName: "Saransk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "SKY",
    airportName: "Griffing Sandusky",
    cityCode: "SKY",
    cityName: "Sandusky",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SKZ",
    airportName: "Sukkur",
    cityCode: "SKZ",
    cityName: "Sukkur",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SLA",
    airportName: "Gen Belgrano",
    cityCode: "SLA",
    cityName: "Salta",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "SLB",
    airportName: "Municipal",
    cityCode: "SLB",
    cityName: "Storm Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLC",
    airportName: "Salt Lake City Intl.",
    cityCode: "SLC",
    cityName: "Salt Lake City, UT",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLD",
    airportName: "Sliac",
    cityCode: "SLD",
    cityName: "Sliac",
    countryName: "Slovakia",
    countryCode: "SK",
  },
  {
    airportCode: "SLE",
    airportName: "Mcnary Field",
    cityCode: "SLE",
    cityName: "Salem",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLF",
    airportName: "Sulayel",
    cityCode: "SLF",
    cityName: "Sulayel",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "SLG",
    airportName: "Smith Field",
    cityCode: "SLG",
    cityName: "Siloam Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLH",
    airportName: "Sola",
    cityCode: "SLH",
    cityName: "Sola",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "SLI",
    airportName: "Solwezi",
    cityCode: "SLI",
    cityName: "Solwezi",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "SLJ",
    airportName: "Stellar Air Park",
    cityCode: "SLJ",
    cityName: "Chandler",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLK",
    airportName: "Adirondack",
    cityCode: "SLK",
    cityName: "Saranac Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLL",
    airportName: "Salalah",
    cityCode: "SLL",
    cityName: "Salalah",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "SLM",
    airportName: "Matacan",
    cityCode: "SLM",
    cityName: "Salamanca",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "SLN",
    airportName: "Salina",
    cityCode: "SLN",
    cityName: "Salina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLO",
    airportName: "Leckrone",
    cityCode: "SLO",
    cityName: "Salem",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLP",
    airportName: "San Luis Potosi",
    cityCode: "SLP",
    cityName: "San Luis Potosi",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "SLQ",
    airportName: "Sleetmute",
    cityCode: "SLQ",
    cityName: "Sleetmute",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLR",
    airportName: "Sulphur Springs",
    cityCode: "SLR",
    cityName: "Sulphur Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLS",
    airportName: "Silistra",
    cityCode: "SLS",
    cityName: "Silistra",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "SLT",
    airportName: "Salida",
    cityCode: "SLT",
    cityName: "Salida",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SLU",
    airportName: "George F L Charles",
    cityCode: "SLU",
    cityName: "Castries",
    countryName: "Saint Lucia",
    countryCode: "LC",
  },
  {
    airportCode: "SLV",
    airportName: "Simla",
    cityCode: "SLV",
    cityName: "Simla",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "SLW",
    airportName: "Saltillo",
    cityCode: "SLW",
    cityName: "Saltillo",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "SLX",
    airportName: "Salt Cay",
    cityCode: "SLX",
    cityName: "Salt Cay",
    countryName: "Turks and Caicos Islands",
    countryCode: "TC",
  },
  {
    airportCode: "SLY",
    airportName: "Salekhard",
    cityCode: "SLY",
    cityName: "Salekhard",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "SLZ",
    airportName: "Marechal Cunha Machado",
    cityCode: "SLZ",
    cityName: "SÃ£o Luis",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SMA",
    airportName: "Vila Do Porto",
    cityCode: "SMA",
    cityName: "Santa Maria",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "SMB",
    airportName: "Cerro Sombrero",
    cityCode: "SMB",
    cityName: "Cerro Sombrero",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "SMC",
    airportName: "Santa Maria",
    cityCode: "SMC",
    cityName: "Santa Maria",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SMD",
    airportName: "Smith Field",
    cityCode: "SMD",
    cityName: "Fort Wayne, IN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SME",
    airportName: "Pulaski County",
    cityCode: "SME",
    cityName: "Somerset",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SMF",
    airportName: "Sacramento Intl.",
    cityCode: "SMF",
    cityName: "Sacramento, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SMG",
    airportName: "Santa Maria",
    cityCode: "SMG",
    cityName: "Santa Maria",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "SMH",
    airportName: "Sapmanga",
    cityCode: "SMH",
    cityName: "Sapmanga",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SMI",
    airportName: "Samos",
    cityCode: "SMI",
    cityName: "Samos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "SMJ",
    airportName: "Sim",
    cityCode: "SMJ",
    cityName: "Sim",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SMK",
    airportName: "St Michael",
    cityCode: "SMK",
    cityName: "St Michael",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SML",
    airportName: "Estate Airstrip",
    cityCode: "SML",
    cityName: "Stella Maris",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "SMM",
    airportName: "Semporna",
    cityCode: "SMM",
    cityName: "Semporna",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SMN",
    airportName: "Salmon",
    cityCode: "SMN",
    cityName: "Salmon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SMO",
    airportName: "Santa Monica",
    cityCode: "SMO",
    cityName: "Santa Monica",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SMP",
    airportName: "Stockholm",
    cityCode: "SMP",
    cityName: "Stockholm",
    countryName: "Papua-New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SMQ",
    airportName: "Sampit",
    cityCode: "SMQ",
    cityName: "Sampit",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SMR",
    airportName: "Simon Bolivar",
    cityCode: "SMR",
    cityName: "Santa Marta",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SMS",
    airportName: "Sainte Marie",
    cityCode: "SMS",
    cityName: "Sainte Marie",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "SMT",
    airportName: "Sun Moon Lake",
    cityCode: "SMT",
    cityName: "Sun Moon Lake",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "SMU",
    airportName: "Sheep Mountain",
    cityCode: "SMU",
    cityName: "Sheep Mountain",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SMV",
    airportName: "Samedan",
    cityCode: "SMV",
    cityName: "St Moritz",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "SMW",
    airportName: "Smara",
    cityCode: "SMW",
    cityName: "Smara",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "SMX",
    airportName: "Public",
    cityCode: "SMX",
    cityName: "Santa Maria",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SMY",
    airportName: "Simenti",
    cityCode: "SMY",
    cityName: "Simenti",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "SMZ",
    airportName: "Stoelmans Eiland",
    cityCode: "SMZ",
    cityName: "Stoelmans Eiland",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "SNA",
    airportName: "John Wayne",
    cityCode: "SNA",
    cityName: "Santa Ana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SNB",
    airportName: "Snake Bay",
    cityCode: "SNB",
    cityName: "Snake Bay",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SNC",
    airportName: "Salinas",
    cityCode: "SNC",
    cityName: "Salinas",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "SND",
    airportName: "Seno",
    cityCode: "SND",
    cityName: "Seno",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "SNE",
    airportName: "Preguica",
    cityCode: "SNE",
    cityName: "Sao Nicolau",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "SNF",
    airportName: "San Felipe",
    cityCode: "SNF",
    cityName: "San Felipe",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SNG",
    airportName: "San Ignacio De Velasco",
    cityCode: "SNG",
    cityName: "San Ignacio De Velasco",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SNH",
    airportName: "Stanthorpe",
    cityCode: "SNH",
    cityName: "Stanthorpe",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SNI",
    airportName: "R.E. Murray",
    cityCode: "SNI",
    cityName: "Sinoe",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "SNJ",
    airportName: "San Julian",
    cityCode: "SNJ",
    cityName: "San Julian",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "SNK",
    airportName: "Winston Field",
    cityCode: "SNK",
    cityName: "Snyder",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SNL",
    airportName: "Municipal",
    cityCode: "SNL",
    cityName: "Shawnee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SNM",
    airportName: "San Ignacio De M",
    cityCode: "SNM",
    cityName: "San Ignacio De M",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SNN",
    airportName: "Shannon",
    cityCode: "SNN",
    cityName: "Shannon",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "SNO",
    airportName: "Sakon Nakhon",
    cityCode: "SNO",
    cityName: "Sakon Nakhon",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "SNP",
    airportName: "Saint Paul Island",
    cityCode: "SNP",
    cityName: "Saint Paul Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SNQ",
    airportName: "San Quintin",
    cityCode: "SNQ",
    cityName: "San Quintin",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "SNR",
    airportName: "Montoir",
    cityCode: "SNR",
    cityName: "St Nazaire",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "SNS",
    airportName: "Salinas",
    cityCode: "SNS",
    cityName: "Salinas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SNT",
    airportName: "Sabana De Torres",
    cityCode: "SNT",
    cityName: "Sabana De Torres",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SNU",
    airportName: "Abel Santa Maria",
    cityCode: "SNU",
    cityName: "Santa Clara",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "SNV",
    airportName: "Santa Elena",
    cityCode: "SNV",
    cityName: "Santa Elena",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SNW",
    airportName: "Thandwe",
    cityCode: "SNW",
    cityName: "Thandwe",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "SNX",
    airportName: "Sabana De Mar",
    cityCode: "SNX",
    cityName: "Sabana De Mar",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "SNY",
    airportName: "Sidney",
    cityCode: "SNY",
    cityName: "Sidney",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SNZ",
    airportName: "Santa Cruz",
    cityCode: "SNZ",
    cityName: "Santa Cruz",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SOA",
    airportName: "Soc Trang",
    cityCode: "SOA",
    cityName: "Soc Trang",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "SOB",
    airportName: "Saarmelleek/balaton",
    cityCode: "SOB",
    cityName: "Saarmelleek",
    countryName: "Hungary",
    countryCode: "HU",
  },
  {
    airportCode: "SOC",
    airportName: "Adi Sumarmo",
    cityCode: "SOC",
    cityName: "Solo City",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SOD",
    airportName: "Sorocaba",
    cityCode: "SOD",
    cityName: "Sorocaba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SOE",
    airportName: "Souanke",
    cityCode: "SOE",
    cityName: "Souanke",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "SOF",
    airportName: "Sofia",
    cityCode: "SOF",
    cityName: "Sofia",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "SOG",
    airportName: "Haukasen",
    cityCode: "SOG",
    cityName: "Sogndal",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SOH",
    airportName: "Solita",
    cityCode: "SOH",
    cityName: "Solita",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SOI",
    airportName: "South Molle Island",
    cityCode: "SOI",
    cityName: "South Molle Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SOJ",
    airportName: "Sorkjosen",
    cityCode: "SOJ",
    cityName: "Sorkjosen",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SOK",
    airportName: "Semongkong",
    cityCode: "SOK",
    cityName: "Semongkong",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "SOL",
    airportName: "Solomon",
    cityCode: "SOL",
    cityName: "Solomon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SOM",
    airportName: "Edmundo Barrios",
    cityCode: "SOM",
    cityName: "San Tome",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SON",
    airportName: "Pekoa",
    cityCode: "SON",
    cityName: "Espiritu Santo",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "SOO",
    airportName: "Soderhamn",
    cityCode: "SOO",
    cityName: "Soderhamn",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "SOP",
    airportName: "Pinehurst-S. Pines",
    cityCode: "SOP",
    cityName: "Southern Pines",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SOQ",
    airportName: "Jefman",
    cityCode: "SOQ",
    cityName: "Sorong",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SOR",
    airportName: "Al Thaurah",
    cityCode: "SOR",
    cityName: "Al Thaurah",
    countryName: "Syria",
    countryCode: "SY",
  },
  {
    airportCode: "SOT",
    airportName: "Sodankyla",
    cityCode: "SOT",
    cityName: "Sodankyla",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "SOU",
    airportName: "Southampton Arpt.",
    cityCode: "SOU",
    cityName: "Eastleigh near Southampton",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SOV",
    airportName: "Seldovia",
    cityCode: "SOV",
    cityName: "Seldovia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SOW",
    airportName: "Show Low",
    cityCode: "SOW",
    cityName: "Show Low, AZ",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SOX",
    airportName: "Alberto Lleras Camargo",
    cityCode: "SOX",
    cityName: "Sogamoso",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SOY",
    airportName: "Stronsay",
    cityCode: "SOY",
    cityName: "Stronsay",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SOZ",
    airportName: "Solenzara",
    cityCode: "SOZ",
    cityName: "Solenzara",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "SPA",
    airportName: "Spartanburg, Downtown Memorial",
    cityCode: "SPA",
    cityName: "Spartanburg, SC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SPC",
    airportName: "La Palma",
    cityCode: "SPC",
    cityName: "Santa Cruz De La Palma",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "SPD",
    airportName: "Saidpur Airport",
    cityCode: "SPD",
    cityName: "Saidpur",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "SPE",
    airportName: "Sepulot",
    cityCode: "SPE",
    cityName: "Sepulot",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SPF",
    airportName: "Black Hills",
    cityCode: "SPF",
    cityName: "Spearfish",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SPH",
    airportName: "Sopu",
    cityCode: "SPH",
    cityName: "Sopu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SPI",
    airportName: "Capital",
    cityCode: "SPI",
    cityName: "Springfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SPJ",
    airportName: "Eleftherios Venizelos",
    cityCode: "SPJ",
    cityName: "Athens",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "SPM",
    airportName: "Spangdahlem",
    cityCode: "SPM",
    cityName: "Spangdahlem",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "SPN",
    airportName: "Intl.",
    cityCode: "SPN",
    cityName: "Saipan",
    countryName: "Northern Mariana Islands",
    countryCode: "MP",
  },
  {
    airportCode: "SPO",
    airportName: "San Pablo",
    cityCode: "SPO",
    cityName: "Sevilla",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "SPP",
    airportName: "Menongue",
    cityCode: "SPP",
    cityName: "Menongue",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "SPQ",
    airportName: "Catalina SPB",
    cityCode: "SPQ",
    cityName: "San Pedro",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SPR",
    airportName: "San Pedro",
    cityCode: "SPR",
    cityName: "San Pedro",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "SPS",
    airportName: "Sheppard AFB",
    cityCode: "SPS",
    cityName: "Wichita Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SPT",
    airportName: "Sipitang",
    cityCode: "SPT",
    cityName: "Sipitang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SPU",
    airportName: "Split",
    cityCode: "SPU",
    cityName: "Split",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "SPV",
    airportName: "Sepik Plains",
    cityCode: "SPV",
    cityName: "Sepik Plains",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SPW",
    airportName: "Municipal",
    cityCode: "SPW",
    cityName: "Spencer",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SPY",
    airportName: "San Pedro",
    cityCode: "SPY",
    cityName: "San Pedro",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "SPZ",
    airportName: "Springdale Muni",
    cityCode: "SPZ",
    cityName: "Springdale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SQA",
    airportName: "Santa Ynez",
    cityCode: "SQA",
    cityName: "Santa Ynez",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SQB",
    airportName: "Santa Ana",
    cityCode: "SQB",
    cityName: "Santa Ana",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SQC",
    airportName: "Southern Cross",
    cityCode: "SQC",
    cityName: "Southern Cross",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SQD",
    airportName: "Sinop",
    cityCode: "SQD",
    cityName: "Sinop",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "SQE",
    airportName: "San Luis De Pale",
    cityCode: "SQE",
    cityName: "San Luis De Pale",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SQF",
    airportName: "Solano",
    cityCode: "SQF",
    cityName: "Solano",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SQG",
    airportName: "Sintang",
    cityCode: "SQG",
    cityName: "Sintang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SQI",
    airportName: "Whiteside County",
    cityCode: "SQI",
    cityName: "Sterling Rockfalls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SQK",
    airportName: "Sidi Barani",
    cityCode: "SQK",
    cityName: "Sidi Barani",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "SQL",
    airportName: "San Carlos",
    cityCode: "SQL",
    cityName: "San Carlos",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SQM",
    airportName: "SÃ£o Miguel de Aragao",
    cityCode: "SQM",
    cityName: "SÃ£o Miguel de Aragao",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SQN",
    airportName: "Sanana",
    cityCode: "SQN",
    cityName: "Sanana",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SQO",
    airportName: "Gunnarn",
    cityCode: "SQO",
    cityName: "Storuman",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "SQP",
    airportName: "Starcke",
    cityCode: "SQP",
    cityName: "Starcke",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SQQ",
    airportName: "Siauliai Intl.",
    cityCode: "SQQ",
    cityName: "Siauliai",
    countryName: "Lithuania",
    countryCode: "LT",
  },
  {
    airportCode: "SQR",
    airportName: "Inco Soroako Waws",
    cityCode: "SQR",
    cityName: "Soroako",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SQS",
    airportName: "Matthew Spain",
    cityCode: "SQS",
    cityName: "San Ignacio",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "SQT",
    airportName: "China Straits Airstrip",
    cityCode: "SQT",
    cityName: "Samarai Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SQV",
    airportName: "Sequim Valley Arpt",
    cityCode: "SQV",
    cityName: "Sequim",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SQW",
    airportName: "Skive Arpt.",
    cityCode: "SQW",
    cityName: "Skive",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "SQX",
    airportName: "Sao Miguel do Oeste",
    cityCode: "SQX",
    cityName: "Sao Miguel do Oeste",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SQY",
    airportName: "Sao Lourenco Do Sul",
    cityCode: "SQY",
    cityName: "Sao Lourenco Do Sul",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SQZ",
    airportName: "RAF Station",
    cityCode: "SQZ",
    cityName: "Scampton",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SRA",
    airportName: "Santa Rosa",
    cityCode: "SRA",
    cityName: "Santa Rosa",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SRB",
    airportName: "Santa Rosa",
    cityCode: "SRB",
    cityName: "Santa Rosa",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SRC",
    airportName: "Searcy",
    cityCode: "SRC",
    cityName: "Searcy",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SRD",
    airportName: "San Ramon",
    cityCode: "SRD",
    cityName: "San Ramon",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SRE",
    airportName: "Juana Azurduy de Padilla",
    cityCode: "SRE",
    cityName: "Sucre",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SRF",
    airportName: "Hamilton Field",
    cityCode: "SRF",
    cityName: "San Rafael",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SRG",
    airportName: "Achmad Yani",
    cityCode: "SRG",
    cityName: "Semarang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SRH",
    airportName: "Sarh",
    cityCode: "SRH",
    cityName: "Sarh",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "SRI",
    airportName: "Samarinda",
    cityCode: "SRI",
    cityName: "Samarinda",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SRJ",
    airportName: "Capitan G Q Guardia",
    cityCode: "SRJ",
    cityName: "San Borja",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SRK",
    airportName: "Sierra Leone",
    cityCode: "SRK",
    cityName: "Sierra Leone",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "SRL",
    airportName: "Santa Rosalia",
    cityCode: "SRL",
    cityName: "Santa Rosalia",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "SRM",
    airportName: "Sandringham",
    cityCode: "SRM",
    cityName: "Sandringham",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SRN",
    airportName: "Strahan",
    cityCode: "SRN",
    cityName: "Strahan",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SRO",
    airportName: "Santana Ramos",
    cityCode: "SRO",
    cityName: "Santana Ramos",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SRP",
    airportName: "Stord Arpt.",
    cityCode: "SRP",
    cityName: "Stord",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SRQ",
    airportName: "Sarasota-Bradenton Intl. Arpt.",
    cityCode: "SRQ",
    cityName: "Sarasota, FL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SRR",
    airportName: "Dunwich",
    cityCode: "SRR",
    cityName: "Stradbroke Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SRS",
    airportName: "Cartagena",
    cityCode: "SRS",
    cityName: "San Marcos",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SRT",
    airportName: "Soroti",
    cityCode: "SRT",
    cityName: "Soroti",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "SRU",
    airportName: "Skypark",
    cityCode: "SRU",
    cityName: "Santa Cruz",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SRV",
    airportName: "Stony River",
    cityCode: "SRV",
    cityName: "Stony River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SRW",
    airportName: "Rowan County",
    cityCode: "SRW",
    cityName: "Salisbury",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SRX",
    airportName: "Sert",
    cityCode: "SRX",
    cityName: "Sert",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "SRY",
    airportName: "Dashte Naz",
    cityCode: "SRY",
    cityName: "Sary",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "SRZ",
    airportName: "El Trompillo",
    cityCode: "SRZ",
    cityName: "Santa Cruz",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "SSA",
    airportName: "Luis Eduardo Magalhaes",
    cityCode: "SSA",
    cityName: "Salvador, Bahia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SSC",
    airportName: "Shaw AFB",
    cityCode: "SSC",
    cityName: "Sumter",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SSD",
    airportName: "San Felipe",
    cityCode: "SSD",
    cityName: "San Felipe",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SSE",
    airportName: "Sholapur",
    cityCode: "SSE",
    cityName: "Sholapur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "SSF",
    airportName: "Stinson Municipal",
    cityCode: "SSF",
    cityName: "San Antonio",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SSG",
    airportName: "Santa Isabel",
    cityCode: "SSG",
    cityName: "Malabo",
    countryName: "Equatorial Guinea",
    countryCode: "GQ",
  },
  {
    airportCode: "SSH",
    airportName: "Ophira Intl.",
    cityCode: "SSH",
    cityName: "Sharm El Sheikh",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "SSI",
    airportName: "Mckinnon",
    cityCode: "SSI",
    cityName: "Brunswick",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SSJ",
    airportName: "Stokka",
    cityCode: "SSJ",
    cityName: "Sandnessjoen",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SSK",
    airportName: "Sturt Creek",
    cityCode: "SSK",
    cityName: "Sturt Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SSL",
    airportName: "Santa Rosalia",
    cityCode: "SSL",
    cityName: "Santa Rosalia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SSO",
    airportName: "Sao Lourenco",
    cityCode: "SSO",
    cityName: "Sao Lourenco",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SSP",
    airportName: "Silver Plains",
    cityCode: "SSP",
    cityName: "Silver Plains",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SSQ",
    airportName: "La Sarre",
    cityCode: "SSQ",
    cityName: "La Sarre",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "SSR",
    airportName: "Sara",
    cityCode: "SSR",
    cityName: "Sara",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "SSS",
    airportName: "Siassi",
    cityCode: "SSS",
    cityName: "Siassi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SST",
    airportName: "Santa Teresita",
    cityCode: "SST",
    cityName: "Santa Teresita",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "SSU",
    airportName: "Greenbrier",
    cityCode: "SSU",
    cityName: "White Sulphur Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SSV",
    airportName: "Siasi",
    cityCode: "SSV",
    cityName: "Siasi",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "SSW",
    airportName: "Stuart Island",
    cityCode: "SSW",
    cityName: "Stuart Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SSX",
    airportName: "Samsun",
    cityCode: "SSX",
    cityName: "Samsun/carsamba",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "SSZ",
    airportName: "Santos",
    cityCode: "SSZ",
    cityName: "Santos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "STA",
    airportName: "Stauning Vestjyllands Arpt.",
    cityCode: "STA",
    cityName: "Skjern",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "STB",
    airportName: "L Delicias",
    cityCode: "STB",
    cityName: "Santa Barbara Ed",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "STC",
    airportName: "Municipal",
    cityCode: "STC",
    cityName: "Saint Cloud",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "STD",
    airportName: "Mayo Guerrero",
    cityCode: "STD",
    cityName: "Santo Domingo",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "STE",
    airportName: "Stevens Point",
    cityCode: "STE",
    cityName: "Stevens Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "STF",
    airportName: "Stephen Island",
    cityCode: "STF",
    cityName: "Stephen Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "STG",
    airportName: "St George Island",
    cityCode: "STG",
    cityName: "St George Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "STH",
    airportName: "Strathmore",
    cityCode: "STH",
    cityName: "Strathmore",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "STI",
    airportName: "Cibao Intl. Arpt.",
    cityCode: "STI",
    cityName: "Santiago",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "STJ",
    airportName: "Rosecrans Memorial",
    cityCode: "STJ",
    cityName: "St Joseph",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "STK",
    airportName: "Crosson Field",
    cityCode: "STK",
    cityName: "Sterling",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "STL",
    airportName: "Lambert-St. Louis Intl.",
    cityCode: "STL",
    cityName: "St Louis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "STM",
    airportName: "Eduardo Gomes",
    cityCode: "STM",
    cityName: "Santarem",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "STN",
    airportName: "London Stansted",
    cityCode: "LON",
    cityName: "London",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "STP",
    airportName: "Downtown",
    cityCode: "STP",
    cityName: "St Paul",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "STR",
    airportName: "Stuttgart Echterdingen",
    cityCode: "STR",
    cityName: "Stuttgart",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "STS",
    airportName: "Sonoma County",
    cityCode: "STS",
    cityName: "Santa Rosa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "STT",
    airportName: "Cyril E King Arpt.",
    cityCode: "STT",
    cityName: "Charlotte Amalie, St Thomas",
    countryName: "Virgin Islands, US",
    countryCode: "VI",
  },
  {
    airportCode: "STU",
    airportName: "Santa Cruz",
    cityCode: "STU",
    cityName: "Santa Cruz",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "STV",
    airportName: "Surat",
    cityCode: "STV",
    cityName: "Surat",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "STW",
    airportName: "Stavropol",
    cityCode: "STW",
    cityName: "Stavropol",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "STX",
    airportName: "Henry E Rohlsen",
    cityCode: "STX",
    cityName: "St Croix Island",
    countryName: "Virgin Islands",
    countryCode: "VI",
  },
  {
    airportCode: "STY",
    airportName: "Salto",
    cityCode: "STY",
    cityName: "Salto",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "STZ",
    airportName: "Confresa",
    cityCode: "STZ",
    cityName: "Santa Terezinha",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SUA",
    airportName: "Witham Field",
    cityCode: "SUA",
    cityName: "Stuart",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUB",
    airportName: "Juanda",
    cityCode: "SUB",
    cityName: "Surabaya",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SUC",
    airportName: "Schloredt",
    cityCode: "SUC",
    cityName: "Sundance",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUD",
    airportName: "Stroud",
    cityCode: "SUD",
    cityName: "Stroud",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUE",
    airportName: "Door County",
    cityCode: "SUE",
    cityName: "Sturgeon Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUF",
    airportName: "S Eufemia",
    cityCode: "SUF",
    cityName: "Lamezia-terme",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "SUG",
    airportName: "Surigao",
    cityCode: "SUG",
    cityName: "Surigao",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "SUH",
    airportName: "Sur",
    cityCode: "SUH",
    cityName: "Sur",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "SUI",
    airportName: "Babusheri",
    cityCode: "SUI",
    cityName: "Sukhumi",
    countryName: "Georgia",
    countryCode: "GE",
  },
  {
    airportCode: "SUJ",
    airportName: "Satu Mare",
    cityCode: "SUJ",
    cityName: "Satu Mare",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "SUK",
    airportName: "Samcheok",
    cityCode: "SUK",
    cityName: "Samcheok",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "SUL",
    airportName: "Sui",
    cityCode: "SUL",
    cityName: "Sui",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SUM",
    airportName: "Municipal",
    cityCode: "SUM",
    cityName: "Sumter",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUN",
    airportName: "Sun Valley",
    cityCode: "SUN",
    cityName: "Sun Valley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUO",
    airportName: "Sun River",
    cityCode: "SUO",
    cityName: "Sun River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUP",
    airportName: "Trunojoyo",
    cityCode: "SUP",
    cityName: "Sumenep",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SUQ",
    airportName: "Sucua",
    cityCode: "SUQ",
    cityName: "Sucua",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "SUR",
    airportName: "Summer Beaver",
    cityCode: "SUR",
    cityName: "Summer Beaver",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "SUS",
    airportName: "Spirit Of St Louis",
    cityCode: "SUS",
    cityName: "St Louis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUT",
    airportName: "Sumbawanga",
    cityCode: "SUT",
    cityName: "Sumbawanga",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "SUU",
    airportName: "Travis AFB",
    cityCode: "SUU",
    cityName: "Fairfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUV",
    airportName: "Nausori",
    cityCode: "SUV",
    cityName: "Suva",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "SUW",
    airportName: "Richard I Bong Arpt",
    cityCode: "SUW",
    cityName: "Superior",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUX",
    airportName: "Sioux Gateway",
    cityCode: "SUX",
    cityName: "Sioux City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SUY",
    airportName: "Sudureyri",
    cityCode: "SUY",
    cityName: "Sudureyri",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "SUZ",
    airportName: "Suria",
    cityCode: "SUZ",
    cityName: "Suria",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SVA",
    airportName: "Savoonga",
    cityCode: "SVA",
    cityName: "Savoonga",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SVB",
    airportName: "Sambava",
    cityCode: "SVB",
    cityName: "Sambava",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "SVC",
    airportName: "Grant County",
    cityCode: "SVC",
    cityName: "Silver City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SVD",
    airportName: "E. T. Joshua",
    cityCode: "SVD",
    cityName: "St Vincent",
    countryName: "Saint Vincent And The Grenadines",
    countryCode: "VC",
  },
  {
    airportCode: "SVE",
    airportName: "Susanville",
    cityCode: "SVE",
    cityName: "Susanville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SVF",
    airportName: "Save",
    cityCode: "SVF",
    cityName: "Save",
    countryName: "Benin",
    countryCode: "BJ",
  },
  {
    airportCode: "SVG",
    airportName: "Sola",
    cityCode: "SVG",
    cityName: "Stavanger",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SVH",
    airportName: "Municipal",
    cityCode: "SVH",
    cityName: "Statesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SVI",
    airportName: "San Vicente",
    cityCode: "SVI",
    cityName: "San Vicente",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "SVJ",
    airportName: "Helle",
    cityCode: "SVJ",
    cityName: "Svolvaer",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SVK",
    airportName: "Silver Creek",
    cityCode: "SVK",
    cityName: "Silver Creek",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "SVL",
    airportName: "Savonlinna",
    cityCode: "SVL",
    cityName: "Savonlinna",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "SVN",
    airportName: "Hunter AAF",
    cityCode: "SVN",
    cityName: "Savannah, GA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SVO",
    airportName: "Sheremetyevo",
    cityCode: "MOW",
    cityName: "Moscow",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "SVP",
    airportName: "Kuito",
    cityCode: "SVP",
    cityName: "Kuito",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "SVQ",
    airportName: "San Pablo",
    cityCode: "SVQ",
    cityName: "Sevilla",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "SVR",
    airportName: "Svay Rieng",
    cityCode: "SVR",
    cityName: "Svay Rieng",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "SVS",
    airportName: "Stevens Village",
    cityCode: "SVS",
    cityName: "Stevens Village",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SVT",
    airportName: "Savuti",
    cityCode: "SVT",
    cityName: "Savuti",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "SVU",
    airportName: "Savusavu",
    cityCode: "SVU",
    cityName: "Savusavu",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "SVV",
    airportName: "San Salvador De",
    cityCode: "SVV",
    cityName: "San Salvador De",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SVW",
    airportName: "Sparrevohn AFS",
    cityCode: "SVW",
    cityName: "Sparrevohn",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SVX",
    airportName: "Koltsovo Intl. Arpt.",
    cityCode: "SVX",
    cityName: "Ekaterinburg",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "SVY",
    airportName: "Savo",
    cityCode: "SVY",
    cityName: "Savo",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "SVZ",
    airportName: "San Antonio",
    cityCode: "SVZ",
    cityName: "San Antonio",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "SWA",
    airportName: "Shantou",
    cityCode: "SWA",
    cityName: "Shantou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SWB",
    airportName: "Shaw River",
    cityCode: "SWB",
    cityName: "Shaw River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SWC",
    airportName: "Stawell",
    cityCode: "SWC",
    cityName: "Stawell",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SWD",
    airportName: "Seward",
    cityCode: "SWD",
    cityName: "Seward",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SWE",
    airportName: "Siwea",
    cityCode: "SWE",
    cityName: "Siwea",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SWF",
    airportName: "Stewart",
    cityCode: "NYC",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SWG",
    airportName: "Satwag",
    cityCode: "SWG",
    cityName: "Satwag",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SWH",
    airportName: "Swan Hill",
    cityCode: "SWH",
    cityName: "Swan Hill",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SWI",
    airportName: "Gypsy",
    cityCode: "SWI",
    cityName: "Swindon",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SWJ",
    airportName: "South West Bay",
    cityCode: "SWJ",
    cityName: "South West Bay",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "SWK",
    airportName: "Segrate",
    cityCode: "SWK",
    cityName: "Milan",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "SWL",
    airportName: "Spanish Wells",
    cityCode: "SWL",
    cityName: "Spanish Wells",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "SWM",
    airportName: "Suia-Missu",
    cityCode: "SWM",
    cityName: "Suia-Missu",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SWN",
    airportName: "Sahiwal",
    cityCode: "SWN",
    cityName: "Sahiwal",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SWO",
    airportName: "Searcy Fld",
    cityCode: "SWO",
    cityName: "Stillwater",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SWP",
    airportName: "Swakopmund",
    cityCode: "SWP",
    cityName: "Swakopmund",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "SWQ",
    airportName: "Brang Bidji",
    cityCode: "SWQ",
    cityName: "Sumbawa",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SWS",
    airportName: "Fairwood Comm",
    cityCode: "SWS",
    cityName: "Swansea",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SWU",
    airportName: "Suwon",
    cityCode: "SWU",
    cityName: "Suwon",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "SWV",
    airportName: "Shikarpur",
    cityCode: "SWV",
    cityName: "Shikarpur",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SWW",
    airportName: "Sweetwater",
    cityCode: "SWW",
    cityName: "Sweetwater",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SWX",
    airportName: "Shakawe",
    cityCode: "SWX",
    cityName: "Shakawe",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "SWY",
    airportName: "Sitiawan",
    cityCode: "SWY",
    cityName: "Sitiawan",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SWZ",
    airportName: "Sydney West",
    cityCode: "SWZ",
    cityName: "Sydney",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SXA",
    airportName: "Sialum",
    cityCode: "SXA",
    cityName: "Sialum",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SXB",
    airportName: "Entzheim",
    cityCode: "SXB",
    cityName: "Strasbourg",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "SXC",
    airportName: "Avalo Vor/WP",
    cityCode: "SXC",
    cityName: "Catalina Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SXD",
    airportName: "Sophia Antipolis",
    cityCode: "SXD",
    cityName: "Sophia Antipolis",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "SXE",
    airportName: "Sale",
    cityCode: "SXE",
    cityName: "Sale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SXF",
    airportName: "Berlin Schonefeld Airport",
    cityCode: "BER",
    cityName: "Berlin",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "SXG",
    airportName: "Senanga",
    cityCode: "SXG",
    cityName: "Senanga",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "SXH",
    airportName: "Sehulea",
    cityCode: "SXH",
    cityName: "Sehulea",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SXI",
    airportName: "Sirri Island",
    cityCode: "SXI",
    cityName: "Sirri Island",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "SXJ",
    airportName: "Shanshan",
    cityCode: "SXJ",
    cityName: "Shanshan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SXK",
    airportName: "Saumlaki",
    cityCode: "SXK",
    cityName: "Saumlaki",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SXL",
    airportName: "Collooney",
    cityCode: "SXL",
    cityName: "Sligo",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "SXM",
    airportName: "Princess Juliana Intl.",
    cityCode: "SXM",
    cityName: "Philipsburg, St. Maarten",
    countryName: "Netherlands Antilles",
    countryCode: "AN",
  },
  {
    airportCode: "SXN",
    airportName: "Suapan",
    cityCode: "SXN",
    cityName: "Suapan",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "SXO",
    airportName: "Sao Felix Do Araguaia",
    cityCode: "SXO",
    cityName: "Sao Felix Do Araguaia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SXP",
    airportName: "Sheldon SPB",
    cityCode: "SXP",
    cityName: "Sheldon Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SXQ",
    airportName: "Soldotna",
    cityCode: "SXQ",
    cityName: "Soldotna",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SXR",
    airportName: "Srinagar",
    cityCode: "SXR",
    cityName: "Srinagar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "SXS",
    airportName: "Sahabat 16",
    cityCode: "SXS",
    cityName: "Sahabat 16",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SXT",
    airportName: "Taman Negara",
    cityCode: "SXT",
    cityName: "Taman Negara",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SXU",
    airportName: "Soddu",
    cityCode: "SXU",
    cityName: "Soddu",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "SXV",
    airportName: "Salem",
    cityCode: "SXV",
    cityName: "Salem",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "SXW",
    airportName: "Sauren",
    cityCode: "SXW",
    cityName: "Sauren",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "SXX",
    airportName: "Sao Felix Do Xingu",
    cityCode: "SXX",
    cityName: "Sao Felix Do Xingu",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "SXY",
    airportName: "Sidney",
    cityCode: "SXY",
    cityName: "Sidney",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SXZ",
    airportName: "Siirt",
    cityCode: "SXZ",
    cityName: "Siirt",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "SYA",
    airportName: "Shemya AFB",
    cityCode: "SYA",
    cityName: "Shemya",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SYB",
    airportName: "Seal Bay",
    cityCode: "SYB",
    cityName: "Seal Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SYC",
    airportName: "Shiringayoc",
    cityCode: "SYC",
    cityName: "Shiringayoc",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "SYD",
    airportName: "Kingsford Smith",
    cityCode: "SYD",
    cityName: "Sydney",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SYE",
    airportName: "Sadah",
    cityCode: "SYE",
    cityName: "Sadah",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "SYF",
    airportName: "Silva Bay",
    cityCode: "SYF",
    cityName: "Silva Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "SYG",
    airportName: "Spitsberg",
    cityCode: "SYG",
    cityName: "Svalbard",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "SYH",
    airportName: "Syangboche",
    cityCode: "SYH",
    cityName: "Syangboche",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "SYI",
    airportName: "Bomar Field",
    cityCode: "SYI",
    cityName: "Shelbyville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SYJ",
    airportName: "Sirjan",
    cityCode: "SYJ",
    cityName: "Sirjan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "SYK",
    airportName: "Stykkisholmur",
    cityCode: "SYK",
    cityName: "Stykkisholmur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "SYL",
    airportName: "Roberts AAF",
    cityCode: "SYL",
    cityName: "San Miguel",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SYM",
    airportName: "Simao",
    cityCode: "SYM",
    cityName: "Simao",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SYN",
    airportName: "Carleton",
    cityCode: "SYN",
    cityName: "Stanton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SYO",
    airportName: "Shonai",
    cityCode: "SYO",
    cityName: "Shonai",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "SYP",
    airportName: "Santiago",
    cityCode: "SYP",
    cityName: "Santiago",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "SYQ",
    airportName: "Tobias BolaÃ±os Intl.",
    cityCode: "SYQ",
    cityName: "San JosÃ©",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "SYR",
    airportName: "Syracuse Hancock Intl. Arpt.",
    cityCode: "SYR",
    cityName: "Syracuse",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SYS",
    airportName: "Yeosu",
    cityCode: "SYS",
    cityName: "Suncheon",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "SYT",
    airportName: "Charolais Bourgogne S.",
    cityCode: "SYT",
    cityName: "Saint Yan",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "SYU",
    airportName: "Warraber Island",
    cityCode: "SYU",
    cityName: "Sue Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "SYV",
    airportName: "Sylvester",
    cityCode: "SYV",
    cityName: "Sylvester",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SYW",
    airportName: "Sehwen Sharif",
    cityCode: "SYW",
    cityName: "Sehwen Sharif",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "SYX",
    airportName: "Fenghuang Intl.",
    cityCode: "SYX",
    cityName: "Sanya",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SYY",
    airportName: "Stornoway",
    cityCode: "SYY",
    cityName: "Stornoway",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SYZ",
    airportName: "Shiraz",
    cityCode: "SYZ",
    cityName: "Shiraz",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "SZA",
    airportName: "Soyo",
    cityCode: "SZA",
    cityName: "Soyo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "SZB",
    airportName: "Sultan Abdul Aziz Shah",
    cityCode: "KUL",
    cityName: "Kuala Lumpur",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "SZC",
    airportName: "Guanacaste",
    cityCode: "SZC",
    cityName: "Santa Cruz",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "SZD",
    airportName: "Sheffield City Arpt.",
    cityCode: "SZD",
    cityName: "Sheffield",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "SZE",
    airportName: "Semera Arpt.",
    cityCode: "SZE",
    cityName: "Semera",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "SZF",
    airportName: "Carsamba",
    cityCode: "SZF",
    cityName: "Samsun",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "SZG",
    airportName: "W. A. Mozart",
    cityCode: "SZG",
    cityName: "Salzburg",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "SZH",
    airportName: "Senipah",
    cityCode: "SZH",
    cityName: "Senipah",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "SZJ",
    airportName: "Siguanea",
    cityCode: "SZJ",
    cityName: "Siguanea",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "SZK",
    airportName: "Skukuza",
    cityCode: "SZK",
    cityName: "Skukuza",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "SZL",
    airportName: "Whiteman AFB",
    cityCode: "SZL",
    cityName: "Kansas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SZN",
    airportName: "Santa Cruz Island",
    cityCode: "SZN",
    cityName: "Santa Barbara",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SZP",
    airportName: "Oxnard",
    cityCode: "SZP",
    cityName: "Santa Paula",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "SZQ",
    airportName: "Saenz Pena",
    cityCode: "SZQ",
    cityName: "Saenz Pena",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "SZR",
    airportName: "Stara Zagora",
    cityCode: "SZR",
    cityName: "Stara Zagora",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "SZS",
    airportName: "Stewart Island",
    cityCode: "SZS",
    cityName: "Stewart Island",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "SZU",
    airportName: "Segou",
    cityCode: "SZU",
    cityName: "Segou",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "SZV",
    airportName: "Suzhou",
    cityCode: "SZV",
    cityName: "Suzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SZW",
    airportName: "Parchim Arpt.",
    cityCode: "SZW",
    cityName: "Schwerin",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "SZX",
    airportName: "Shenzhen",
    cityCode: "SZX",
    cityName: "Shenzhen",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "SZY",
    airportName: "Mazury",
    cityCode: "SZY",
    cityName: "Szymany",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "SZZ",
    airportName: "Goleniow",
    cityCode: "SZZ",
    cityName: "Szczecin",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "TAA",
    airportName: "Tarapaina",
    cityCode: "TAA",
    cityName: "Tarapaina",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "TAB",
    airportName: "Crown Point Arpt.",
    cityCode: "TAB",
    cityName: "Tobago",
    countryName: "Trinidad And Tobago",
    countryCode: "TT",
  },
  {
    airportCode: "TAC",
    airportName: "D.Z. Romualdez",
    cityCode: "TAC",
    cityName: "Tacloban",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "TAD",
    airportName: "Las Animas",
    cityCode: "TAD",
    cityName: "Trinidad",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TAE",
    airportName: "Daegu",
    cityCode: "TAE",
    cityName: "Daegu",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "TAF",
    airportName: "Tafaraoui",
    cityCode: "TAF",
    cityName: "Oran",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "TAG",
    airportName: "Tagbilaran",
    cityCode: "TAG",
    cityName: "Tagbilaran",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "TAH",
    airportName: "Tanna",
    cityCode: "TAH",
    cityName: "Tanna",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "TAI",
    airportName: "Al Janad",
    cityCode: "TAI",
    cityName: "Taiz",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "TAJ",
    airportName: "Tadji",
    cityCode: "TAJ",
    cityName: "Aitape",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TAK",
    airportName: "Takamatsu",
    cityCode: "TAK",
    cityName: "Takamatsu",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TAL",
    airportName: "Ralph Calhoun",
    cityCode: "TAL",
    cityName: "Tanana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TAM",
    airportName: "Gen F Javier Mina",
    cityCode: "TAM",
    cityName: "Tampico",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TAN",
    airportName: "Tangalooma",
    cityCode: "TAN",
    cityName: "Tangalooma",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TAO",
    airportName: "Qingdao",
    cityCode: "TAO",
    cityName: "Qingdao",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TAP",
    airportName: "Tapachula Intl.",
    cityCode: "TAP",
    cityName: "Tapachula",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TAQ",
    airportName: "Tarcoola",
    cityCode: "TAQ",
    cityName: "Tarcoola",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TAR",
    airportName: "M. A. Grottag",
    cityCode: "TAR",
    cityName: "Taranto",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "TAS",
    airportName: "Yuzhny",
    cityCode: "TAS",
    cityName: "Tashkent",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "TAT",
    airportName: "Poprad/tatry",
    cityCode: "TAT",
    cityName: "Poprad",
    countryName: "Slovak Republic",
    countryCode: "SK",
  },
  {
    airportCode: "TAU",
    airportName: "Tauramena",
    cityCode: "TAU",
    cityName: "Tauramena",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TAV",
    airportName: "Tau",
    cityCode: "TAV",
    cityName: "Tau",
    countryName: "American Samoa",
    countryCode: "AS",
  },
  {
    airportCode: "TAW",
    airportName: "Tacuarembo",
    cityCode: "TAW",
    cityName: "Tacuarembo",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "TAX",
    airportName: "Taliabu",
    cityCode: "TAX",
    cityName: "Taliabu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TAY",
    airportName: "Tartu",
    cityCode: "TAY",
    cityName: "Tartu",
    countryName: "Estonia",
    countryCode: "EE",
  },
  {
    airportCode: "TBA",
    airportName: "Tabibuga",
    cityCode: "TBA",
    cityName: "Tabibuga",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TBB",
    airportName: "Tuy Hoa",
    cityCode: "TBB",
    cityName: "Tuy Hoa",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "TBC",
    airportName: "Tuba City",
    cityCode: "TBC",
    cityName: "Tuba City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TBD",
    airportName: "Timbiqui",
    cityCode: "TBD",
    cityName: "Timbiqui",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TBE",
    airportName: "Timbunke",
    cityCode: "TBE",
    cityName: "Timbunke",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TBF",
    airportName: "Tabiteuea North",
    cityCode: "TBF",
    cityName: "Tabiteuea North",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "TBG",
    airportName: "Tabubil",
    cityCode: "TBG",
    cityName: "Tabubil",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TBH",
    airportName: "Romblon",
    cityCode: "TBH",
    cityName: "Tablas",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "TBI",
    airportName: "New Bright",
    cityCode: "TBI",
    cityName: "The Bight",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "TBJ",
    airportName: "Tabarka",
    cityCode: "TBJ",
    cityName: "Tabarka",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "TBK",
    airportName: "Timber Creek",
    cityCode: "TBK",
    cityName: "Timber Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TBL",
    airportName: "Tableland",
    cityCode: "TBL",
    cityName: "Tableland",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TBM",
    airportName: "Tumbang Samba",
    cityCode: "TBM",
    cityName: "Tumbang Samba",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TBN",
    airportName: "Forney AAF",
    cityCode: "TBN",
    cityName: "Fort Leonard Wood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TBO",
    airportName: "Tabora",
    cityCode: "TBO",
    cityName: "Tabora",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "TBP",
    airportName: "Tumbes",
    cityCode: "TBP",
    cityName: "Tumbes",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "TBQ",
    airportName: "Tarabo",
    cityCode: "TBQ",
    cityName: "Tarabo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TBR",
    airportName: "Statesboro-Bulloch County Arpt.",
    cityCode: "TBR",
    cityName: "Statesboro, GA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TBS",
    airportName: "Lochini",
    cityCode: "TBS",
    cityName: "Tbilisi",
    countryName: "Georgia",
    countryCode: "GE",
  },
  {
    airportCode: "TBT",
    airportName: "Internacional",
    cityCode: "TBT",
    cityName: "Tabatinga",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TBU",
    airportName: "Fua'amotu Intl.",
    cityCode: "TBU",
    cityName: "Nuku'alofa",
    countryName: "Tonga",
    countryCode: "TO",
  },
  {
    airportCode: "TBV",
    airportName: "Tabal",
    cityCode: "TBV",
    cityName: "Tabal",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "TBW",
    airportName: "Tambov",
    cityCode: "TBW",
    cityName: "Tambov",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "TBX",
    airportName: "Taabo",
    cityCode: "TBX",
    cityName: "Taabo",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "TBY",
    airportName: "Tsabong",
    cityCode: "TBY",
    cityName: "Tsabong",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "TBZ",
    airportName: "Tabriz",
    cityCode: "TBZ",
    cityName: "Tabriz",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "TCA",
    airportName: "Tennant Creek",
    cityCode: "TCA",
    cityName: "Tennant Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TCB",
    airportName: "Treasure Cay",
    cityCode: "TCB",
    cityName: "Treasure Cay, Ibaco Islands",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "TCC",
    airportName: "Tucumcari",
    cityCode: "TCC",
    cityName: "Tucumcari",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TCD",
    airportName: "Tarapaca",
    cityCode: "TCD",
    cityName: "Tarapaca",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TCE",
    airportName: "Tulcea",
    cityCode: "TCE",
    cityName: "Tulcea",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "TCF",
    airportName: "Tocoa",
    cityCode: "TCF",
    cityName: "Tocoa",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "TCG",
    airportName: "Tacheng",
    cityCode: "TCG",
    cityName: "Tacheng",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TCH",
    airportName: "Tchibanga",
    cityCode: "TCH",
    cityName: "Tchibanga",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "TCJ",
    airportName: "Torembi Arpt.",
    cityCode: "TCJ",
    cityName: "Torembi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TCK",
    airportName: "Tinboli Arpt.",
    cityCode: "TCK",
    cityName: "Tinboli",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TCL",
    airportName: "Van De Graaf",
    cityCode: "TCL",
    cityName: "Tuscaloosa, AL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TCM",
    airportName: "McChord AFB",
    cityCode: "TCM",
    cityName: "Tacoma, WA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TCN",
    airportName: "Tehuacan",
    cityCode: "TCN",
    cityName: "Tehuacan",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TCO",
    airportName: "La Florida",
    cityCode: "TCO",
    cityName: "Tumaco",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TCP",
    airportName: "Taba Intl.",
    cityCode: "TCP",
    cityName: "Taba",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "TCQ",
    airportName: "Tacna",
    cityCode: "TCQ",
    cityName: "Tacna",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "TCR",
    airportName: "Tuticorin",
    cityCode: "TCR",
    cityName: "Tuticorin",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "TCS",
    airportName: "Municipal",
    cityCode: "TCS",
    cityName: "Truth Or Consequences",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TCT",
    airportName: "Takotna",
    cityCode: "TCT",
    cityName: "Takotna",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TCU",
    airportName: "Thaba Nchu",
    cityCode: "TCU",
    cityName: "Thaba Nchu",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "TCV",
    airportName: "Tete",
    cityCode: "TCV",
    cityName: "Tete",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "TCW",
    airportName: "Tocumwal",
    cityCode: "TCW",
    cityName: "Tocumwal",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TCY",
    airportName: "Terrace Bay",
    cityCode: "TCY",
    cityName: "Terrace Bay",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "TDA",
    airportName: "Trinidad",
    cityCode: "TDA",
    cityName: "Trinidad",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TDB",
    airportName: "Tetabedi",
    cityCode: "TDB",
    cityName: "Tetabedi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TDD",
    airportName: "Trinidad",
    cityCode: "TDD",
    cityName: "Trinidad",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "TDG",
    airportName: "Tandag",
    cityCode: "TDG",
    cityName: "Tandag",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "TDJ",
    airportName: "Tadjoura",
    cityCode: "TDJ",
    cityName: "Tadjoura",
    countryName: "Djibouti",
    countryCode: "DJ",
  },
  {
    airportCode: "TDK",
    airportName: "Taldy-Kurgan",
    cityCode: "TDK",
    cityName: "Taldy-Kurgan",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "TDL",
    airportName: "Tandil",
    cityCode: "TDL",
    cityName: "Tandil",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "TDN",
    airportName: "Theda Station",
    cityCode: "TDN",
    cityName: "Theda",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TDO",
    airportName: "Winlock",
    cityCode: "TDO",
    cityName: "Toledo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TDR",
    airportName: "Theodore",
    cityCode: "TDR",
    cityName: "Theodore",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TDT",
    airportName: "Tanda Tula",
    cityCode: "TDT",
    cityName: "Tanda Tula",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "TDV",
    airportName: "Tanandava",
    cityCode: "TDV",
    cityName: "Tanandava",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "TDW",
    airportName: "Tradewind",
    cityCode: "TDW",
    cityName: "Amarillo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TDX",
    airportName: "Trat",
    cityCode: "TDX",
    cityName: "Trat",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "TDZ",
    airportName: "Toledo",
    cityCode: "TDZ",
    cityName: "Toledo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TEA",
    airportName: "Tela",
    cityCode: "TEA",
    cityName: "Tela",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "TEB",
    airportName: "Teterboro",
    cityCode: "TEB",
    cityName: "Teterboro",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TEC",
    airportName: "Telemaco Borba",
    cityCode: "TEC",
    cityName: "Telemaco Borba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TEF",
    airportName: "Telfer",
    cityCode: "TEF",
    cityName: "Telfer",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TEG",
    airportName: "Tenkodogo",
    cityCode: "TEG",
    cityName: "Tenkodogo",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "TEH",
    airportName: "Tetlin",
    cityCode: "TEH",
    cityName: "Tetlin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TEI",
    airportName: "Tezu",
    cityCode: "TEI",
    cityName: "Tezu",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "TEK",
    airportName: "Tatitlek",
    cityCode: "TEK",
    cityName: "Tatitlek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TEL",
    airportName: "Telupid",
    cityCode: "TEL",
    cityName: "Telupid",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "TEM",
    airportName: "Temora",
    cityCode: "TEM",
    cityName: "Temora",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TEN",
    airportName: "Tongren",
    cityCode: "TEN",
    cityName: "Tongren",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TEO",
    airportName: "Terapo",
    cityCode: "TEO",
    cityName: "Terapo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TEP",
    airportName: "Teptep",
    cityCode: "TEP",
    cityName: "Teptep",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TEQ",
    airportName: "Corlu",
    cityCode: "TEQ",
    cityName: "Tekirdag",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "TER",
    airportName: "Lajes Ab",
    cityCode: "TER",
    cityName: "Terceira Island",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "TES",
    airportName: "Tessenei",
    cityCode: "TES",
    cityName: "Tessenei",
    countryName: "Eritrea",
    countryCode: "ER",
  },
  {
    airportCode: "TET",
    airportName: "Matundo",
    cityCode: "TET",
    cityName: "Tete",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "TEU",
    airportName: "Manapouri",
    cityCode: "TEU",
    cityName: "Te Anau",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "TEW",
    airportName: "Tohid",
    cityCode: "TEW",
    cityName: "Tohid",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "TEX",
    airportName: "Telluride",
    cityCode: "TEX",
    cityName: "Telluride",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TEY",
    airportName: "Thingeyri",
    cityCode: "TEY",
    cityName: "Thingeyri",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "TEZ",
    airportName: "Salonibari",
    cityCode: "TEZ",
    cityName: "Tezpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "TFB",
    airportName: "Tifalmin",
    cityCode: "TFB",
    cityName: "Tifalmin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TFC",
    airportName: "Taormina Harbour",
    cityCode: "TFC",
    cityName: "Taormina",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "TFF",
    airportName: "Tefe",
    cityCode: "TFF",
    cityName: "Tefe",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TFI",
    airportName: "Tufi",
    cityCode: "TFI",
    cityName: "Tufi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TFL",
    airportName: "Teofilo Otoni",
    cityCode: "TFL",
    cityName: "Teofilo Otoni",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TFM",
    airportName: "Telefomin",
    cityCode: "TFM",
    cityName: "Telefomin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TFN",
    airportName: "Tenerife Norte",
    cityCode: "TCI",
    cityName: "Tenerife",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "TFR",
    airportName: "Ramadan",
    cityCode: "TFR",
    cityName: "Ramadan",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "TFS",
    airportName: "Sur Reina Sofia",
    cityCode: "TCI",
    cityName: "Tenerife",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "TFT",
    airportName: "Taftan",
    cityCode: "TFT",
    cityName: "Taftan",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "TFY",
    airportName: "Tarfaya",
    cityCode: "TFY",
    cityName: "Tarfaya",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "TGB",
    airportName: "Tagbita",
    cityCode: "TGB",
    cityName: "Tagbita",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "TGD",
    airportName: "Golubovci",
    cityCode: "TGD",
    cityName: "Podgorica",
    countryName: "Montenegro",
    countryCode: "ME",
  },
  {
    airportCode: "TGE",
    airportName: "Sharpe Field",
    cityCode: "TGE",
    cityName: "Tuskegee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TGF",
    airportName: "Tignes",
    cityCode: "TGF",
    cityName: "Tignes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "TGG",
    airportName: "Sultan Mahmood",
    cityCode: "TGG",
    cityName: "Kuala Terengganu",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "TGH",
    airportName: "Tongoa",
    cityCode: "TGH",
    cityName: "Tongoa",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "TGI",
    airportName: "Tingo Maria",
    cityCode: "TGI",
    cityName: "Tingo Maria",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "TGJ",
    airportName: "Tiga",
    cityCode: "TGJ",
    cityName: "Tiga",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "TGL",
    airportName: "Tagula",
    cityCode: "TGL",
    cityName: "Tagula",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TGM",
    airportName: "Transilvania",
    cityCode: "TGM",
    cityName: "Tirgu Mures",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "TGN",
    airportName: "La Trobe Regional",
    cityCode: "TGN",
    cityName: "Traralgon",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TGO",
    airportName: "Tongliao",
    cityCode: "TGO",
    cityName: "Tongliao",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "TGQ",
    airportName: "Tangara da Serra",
    cityCode: "TGQ",
    cityName: "Tangara da Serra",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TGR",
    airportName: "Touggourt",
    cityCode: "TGR",
    cityName: "Touggourt",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "TGS",
    airportName: "Chokwe",
    cityCode: "TGS",
    cityName: "Chokwe",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "TGT",
    airportName: "Tanga",
    cityCode: "TGT",
    cityName: "Tanga",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "TGU",
    airportName: "Toncontin",
    cityCode: "TGU",
    cityName: "Tegucigalpa",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "TGV",
    airportName: "Targovishte",
    cityCode: "TGV",
    cityName: "Targovishte",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "TGX",
    airportName: "Tingrela",
    cityCode: "TGX",
    cityName: "Tingrela",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "TGZ",
    airportName: "Llano San Juan",
    cityCode: "TGZ",
    cityName: "Tuxtla Gutierrez",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "THA",
    airportName: "Tullahoma/William Northern Field",
    cityCode: "THA",
    cityName: "Tullahoma, TN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "THB",
    airportName: "Thaba-Tseka",
    cityCode: "THB",
    cityName: "Thaba-Tseka",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "THC",
    airportName: "Tchien",
    cityCode: "THC",
    cityName: "Tchien",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "THE",
    airportName: "Senador PetrÃ´nio Portella",
    cityCode: "THE",
    cityName: "Teresina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "THF",
    airportName: "Tempelhof",
    cityCode: "THF",
    cityName: "Berlin",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "THG",
    airportName: "Thangool",
    cityCode: "THG",
    cityName: "Thangool",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "THH",
    airportName: "Taharoa",
    cityCode: "THH",
    cityName: "Taharoa",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "THI",
    airportName: "Tichitt",
    cityCode: "THI",
    cityName: "Tichitt",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "THK",
    airportName: "Thakhek",
    cityCode: "THK",
    cityName: "Thakhek",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "THL",
    airportName: "Tachilek",
    cityCode: "THL",
    cityName: "Tachilek",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "THM",
    airportName: "Thompsonfield",
    cityCode: "THM",
    cityName: "Thompsonfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "THN",
    airportName: "Trollhattan",
    cityCode: "THN",
    cityName: "Trollhattan",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "THP",
    airportName: "Hot Springs",
    cityCode: "THP",
    cityName: "Thermopolis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "THR",
    airportName: "Mehrabad/ghaleh Morghi",
    cityCode: "THR",
    cityName: "Tehran",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "THS",
    airportName: "Sukhothai",
    cityCode: "THS",
    cityName: "Sukhothai",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "THT",
    airportName: "Tamchakett",
    cityCode: "THT",
    cityName: "Tamchakett",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "THU",
    airportName: "Pituffik",
    cityCode: "THU",
    cityName: "Pituffik",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "THV",
    airportName: "York Arpt.",
    cityCode: "THV",
    cityName: "York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "THY",
    airportName: "Thohoyandou",
    cityCode: "THY",
    cityName: "Thohoyandou",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "THZ",
    airportName: "Tahoua",
    cityCode: "THZ",
    cityName: "Tahoua",
    countryName: "Niger",
    countryCode: "NE",
  },
  {
    airportCode: "TIA",
    airportName: "Rinas Mother Teresa",
    cityCode: "TIA",
    cityName: "Tirana",
    countryName: "Albania",
    countryCode: "AL",
  },
  {
    airportCode: "TIB",
    airportName: "Tibu",
    cityCode: "TIB",
    cityName: "Tibu",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TIC",
    airportName: "Tinak Island",
    cityCode: "TIC",
    cityName: "Tinak Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "TID",
    airportName: "Bouchekif Abde Al-hafidh Boussof",
    cityCode: "TID",
    cityName: "Tiaret",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "TIE",
    airportName: "Tippi",
    cityCode: "TIE",
    cityName: "Tippi",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "TIF",
    airportName: "Taif",
    cityCode: "TIF",
    cityName: "Taif",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "TIG",
    airportName: "Tingwon",
    cityCode: "TIG",
    cityName: "Tingwon",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TIH",
    airportName: "Tikehau Atoll",
    cityCode: "TIH",
    cityName: "Tikehau Atoll",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "TII",
    airportName: "Tirinkot",
    cityCode: "TII",
    cityName: "Tirinkot",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "TIJ",
    airportName: "General A. L. Rodriguez Intl",
    cityCode: "TIJ",
    cityName: "Tijuana",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TIK",
    airportName: "Tinker AFB",
    cityCode: "TIK",
    cityName: "Oklahoma City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TIL",
    airportName: "Inverlake",
    cityCode: "TIL",
    cityName: "Inverlake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "TIM",
    airportName: "Timika",
    cityCode: "TIM",
    cityName: "Tembagapura",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TIN",
    airportName: "Tindouf",
    cityCode: "TIN",
    cityName: "Tindouf",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "TIO",
    airportName: "Tilin",
    cityCode: "TIO",
    cityName: "Tilin",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "TIP",
    airportName: "Intl.",
    cityCode: "TIP",
    cityName: "Tripoli",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "TIQ",
    airportName: "Tinian",
    cityCode: "TIQ",
    cityName: "Tinian",
    countryName: "Northern Mariana Islands",
    countryCode: "MP",
  },
  {
    airportCode: "TIR",
    airportName: "Tirupati",
    cityCode: "TIR",
    cityName: "Tirupati",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "TIU",
    airportName: "Timaru",
    cityCode: "TIU",
    cityName: "Timaru",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "TIV",
    airportName: "Tivat",
    cityCode: "TIV",
    cityName: "Tivat",
    countryName: "Montenegro",
    countryCode: "ME",
  },
  {
    airportCode: "TIW",
    airportName: "Industrial",
    cityCode: "TIW",
    cityName: "Tacoma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TIY",
    airportName: "Tidjikja",
    cityCode: "TIY",
    cityName: "Tidjikja",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "TIZ",
    airportName: "Tari",
    cityCode: "TIZ",
    cityName: "Tari",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TJA",
    airportName: "Tarija",
    cityCode: "TJA",
    cityName: "Tarija",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "TJB",
    airportName: "Tanjung Balai",
    cityCode: "TJB",
    cityName: "Tanjung Balai",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TJC",
    airportName: "Ticantiki",
    cityCode: "TJC",
    cityName: "Ticantiki",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "TJG",
    airportName: "Tanjung Warukin",
    cityCode: "TJG",
    cityName: "Tanjung Warukin",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TJH",
    airportName: "Tajima",
    cityCode: "TJH",
    cityName: "Toyooka",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TJI",
    airportName: "Capiro",
    cityCode: "TJI",
    cityName: "Trujillo",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "TJK",
    airportName: "Tokat",
    cityCode: "TJK",
    cityName: "Tokat",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "TJM",
    airportName: "Roschino",
    cityCode: "TJM",
    cityName: "Tyumen",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "TJN",
    airportName: "Takume",
    cityCode: "TJN",
    cityName: "Takume",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "TJQ",
    airportName: "Bulutumbang",
    cityCode: "TJQ",
    cityName: "Tanjung Pandan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TJS",
    airportName: "Tanjung Selor",
    cityCode: "TJS",
    cityName: "Tanjung Selor",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TJV",
    airportName: "Thanjavur",
    cityCode: "TJV",
    cityName: "Thanjavur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "TKA",
    airportName: "Talkeetna",
    cityCode: "TKA",
    cityName: "Talkeetna",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TKB",
    airportName: "Tekadu",
    cityCode: "TKB",
    cityName: "Tekadu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TKC",
    airportName: "Tiko",
    cityCode: "TKC",
    cityName: "Tiko",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "TKD",
    airportName: "Takoradi",
    cityCode: "TKD",
    cityName: "Takoradi",
    countryName: "Ghana",
    countryCode: "GH",
  },
  {
    airportCode: "TKE",
    airportName: "Tenakee SPB",
    cityCode: "TKE",
    cityName: "Tenakee Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TKF",
    airportName: "Truckee-Tahoe",
    cityCode: "TKF",
    cityName: "Truckee, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TKG",
    airportName: "Branti",
    cityCode: "TKG",
    cityName: "Bandar Lampung",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TKH",
    airportName: "Nakhon Sawan",
    cityCode: "TKH",
    cityName: "Takhli",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "TKJ",
    airportName: "Tok",
    cityCode: "TKJ",
    cityName: "Tok",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TKK",
    airportName: "Truk",
    cityCode: "TKK",
    cityName: "Truk",
    countryName: "Micronesia",
    countryCode: "FM",
  },
  {
    airportCode: "TKL",
    airportName: "Taku SPB",
    cityCode: "TKL",
    cityName: "Taku Lodge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TKM",
    airportName: "El Peten",
    cityCode: "TKM",
    cityName: "Tikal",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "TKN",
    airportName: "Tokunoshima",
    cityCode: "TKN",
    cityName: "Tokunoshima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TKO",
    airportName: "Tlokoeng",
    cityCode: "TKO",
    cityName: "Tlokoeng",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "TKP",
    airportName: "Takapoto",
    cityCode: "TKP",
    cityName: "Takapoto",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "TKQ",
    airportName: "Kigoma",
    cityCode: "TKQ",
    cityName: "Kigoma",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "TKR",
    airportName: "Thakurgaon",
    cityCode: "TKR",
    cityName: "Thakurgaon",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "TKS",
    airportName: "Tokushima",
    cityCode: "TKS",
    cityName: "Tokushima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "TKT",
    airportName: "Tak",
    cityCode: "TKT",
    cityName: "Tak",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "TKU",
    airportName: "Turku",
    cityCode: "TKU",
    cityName: "Turku",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "TKV",
    airportName: "Tatakoto",
    cityCode: "TKV",
    cityName: "Tatakoto",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "TKW",
    airportName: "Tekin",
    cityCode: "TKW",
    cityName: "Tekin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TKX",
    airportName: "Takaroa",
    cityCode: "TKX",
    cityName: "Takaroa",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "TKY",
    airportName: "Turkey Creek",
    cityCode: "TKY",
    cityName: "Turkey Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TKZ",
    airportName: "Tokoroa",
    cityCode: "TKZ",
    cityName: "Tokoroa",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "TLA",
    airportName: "Teller",
    cityCode: "TLA",
    cityName: "Teller",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TLB",
    airportName: "Tarbela",
    cityCode: "TLB",
    cityName: "Tarbela",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "TLC",
    airportName: "Licenciado Adolfo Lopez Mateos Intl.",
    cityCode: "TLC",
    cityName: "Toluca",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "TLD",
    airportName: "Limpopo Valley",
    cityCode: "TLD",
    cityName: "Tuli Block",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "TLE",
    airportName: "Tulear",
    cityCode: "TLE",
    cityName: "Tulear",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "TLG",
    airportName: "Tulagi Island",
    cityCode: "TLG",
    cityName: "Tulagi Island",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "TLH",
    airportName: "Tallahassee Regional Arpt.",
    cityCode: "TLH",
    cityName: "Tallahassee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TLI",
    airportName: "Tolitoli",
    cityCode: "TLI",
    cityName: "Tolitoli",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TLJ",
    airportName: "Tatalina AFS",
    cityCode: "TLJ",
    cityName: "Tatalina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TLK",
    airportName: "Talknafjordur",
    cityCode: "TLK",
    cityName: "Talknafjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "TLL",
    airportName: "Ulemiste",
    cityCode: "TLL",
    cityName: "Tallinn",
    countryName: "Estonia",
    countryCode: "EE",
  },
  {
    airportCode: "TLM",
    airportName: "Zenata",
    cityCode: "TLM",
    cityName: "Tlemcen",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "TLN",
    airportName: "Le Palyvestre",
    cityCode: "TLN",
    cityName: "Hyeres",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "TLO",
    airportName: "Tol",
    cityCode: "TLO",
    cityName: "Tol",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TLP",
    airportName: "Tumolbil",
    cityCode: "TLP",
    cityName: "Tumolbil",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TLR",
    airportName: "Mefford Field",
    cityCode: "TLR",
    cityName: "Tulare, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TLS",
    airportName: "Blagnac",
    cityCode: "TLS",
    cityName: "Toulouse",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "TLT",
    airportName: "Tuluksak",
    cityCode: "TLT",
    cityName: "Tuluksak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TLU",
    airportName: "Tolu",
    cityCode: "TLU",
    cityName: "Tolu",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TLV",
    airportName: "Ben Gurion Intl",
    cityCode: "TLV",
    cityName: "Tel Aviv",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "TLW",
    airportName: "Talasea",
    cityCode: "TLW",
    cityName: "Talasea",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "TLX",
    airportName: "Talca",
    cityCode: "TLX",
    cityName: "Talca",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "TLZ",
    airportName: "Catalao",
    cityCode: "TLZ",
    cityName: "Catalao",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TMA",
    airportName: "Henry Tift Myers",
    cityCode: "TMA",
    cityName: "Tifton, GA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TMB",
    airportName: "Tamiami",
    cityCode: "TMB",
    cityName: "Miami",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "TMC",
    airportName: "Tjilik Riwut",
    cityCode: "TMC",
    cityName: "Tambolaka",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TMD",
    airportName: "Timbedra",
    cityCode: "TMD",
    cityName: "Timbedra",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "TME",
    airportName: "Tame",
    cityCode: "TME",
    cityName: "Tame",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "TMG",
    airportName: "Tomanggong",
    cityCode: "TMG",
    cityName: "Tomanggong",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "TMH",
    airportName: "Tanahmerah",
    cityCode: "TMH",
    cityName: "Tanahmerah",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "TMI",
    airportName: "Tumling Tar",
    cityCode: "TMI",
    cityName: "Tumling Tar",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "TMJ",
    airportName: "Termez",
    cityCode: "TMJ",
    cityName: "Termez",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "TML",
    airportName: "Tamale",
    cityCode: "TML",
    cityName: "Tamale",
    countryName: "Ghana",
    countryCode: "GH",
  },
  {
    airportCode: "TMM",
    airportName: "Tamatave",
    cityCode: "TMM",
    cityName: "Tamatave",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "TMN",
    airportName: "Tamana Island",
    cityCode: "TMN",
    cityName: "Tamana Island",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "TMO",
    airportName: "Tumeremo",
    cityCode: "TMO",
    cityName: "Tumeremo",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "TMP",
    airportName: "Tampere-pirkkala",
    cityCode: "TMP",
    cityName: "Tampere",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "TMQ",
    airportName: "Tambao",
    cityCode: "TMQ",
    cityName: "Tambao",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "TMR",
    airportName: "Aguemar",
    cityCode: "TMR",
    cityName: "Tamanrasset",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "TMS",
    airportName: "Sao Tome Is",
    cityCode: "TMS",
    cityName: "Sao Tome Is",
    countryName: "Sao Tome and Principe",
    countryCode: "ST",
  },
  {
    airportCode: "TMT",
    airportName: "Trombetas",
    cityCode: "TMT",
    cityName: "Trombetas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "TMU",
    airportName: "Tambor",
    cityCode: "TMU",
    cityName: "Tambor",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "TMW",
    airportName: "Tamworth Regional Arpt.",
    cityCode: "TMW",
    cityName: "Tamworth",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "TMX",
    airportName: "Timimoun",
    cityCode: "TMX",
    cityName: "Timimoun",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "TMY",
    airportName: "Tiom",
    cityCode: "TMY",
    cityName: "Tiom",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BSM",
    airportName: "Bishe-kola",
    cityCode: "BSM",
    cityName: "Bishe-kola",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "BSN",
    airportName: "Bossangoa",
    cityCode: "BSN",
    cityName: "Bossangoa",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BSO",
    airportName: "Basco",
    cityCode: "BSO",
    cityName: "Basco",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "BSP",
    airportName: "Bensbach",
    cityCode: "BSP",
    cityName: "Bensbach",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BSQ",
    airportName: "Bisbee Municipal Arpt",
    cityCode: "BSQ",
    cityName: "Bisbee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BSR",
    airportName: "Intl.",
    cityCode: "BSR",
    cityName: "Basra",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "BSS",
    airportName: "Balsas",
    cityCode: "BSS",
    cityName: "Balsas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BST",
    airportName: "Bost",
    cityCode: "BST",
    cityName: "Bost",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "BSU",
    airportName: "Basankusu",
    cityCode: "BSU",
    cityName: "Basankusu",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BSV",
    airportName: "Besakoa",
    cityCode: "BSV",
    cityName: "Besakoa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BSW",
    airportName: "Boswell Bay",
    cityCode: "BSW",
    cityName: "Boswell Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BSX",
    airportName: "Bassein",
    cityCode: "BSX",
    cityName: "Bassein",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "BSY",
    airportName: "Bardera",
    cityCode: "BSY",
    cityName: "Bardera",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "BSZ",
    airportName: "Bartletts",
    cityCode: "BSZ",
    cityName: "Bartletts",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTA",
    airportName: "YaoundÃ©",
    cityCode: "BTA",
    cityName: "YaoundÃ©",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "BTB",
    airportName: "Betou",
    cityCode: "BTB",
    cityName: "Betou",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "BTC",
    airportName: "Batticaloa",
    cityCode: "BTC",
    cityName: "Batticaloa",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "BTD",
    airportName: "Brunette Downs",
    cityCode: "BTD",
    cityName: "Brunette Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BTE",
    airportName: "Bonthe",
    cityCode: "BTE",
    cityName: "Bonthe",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "BTF",
    airportName: "Salt Lake Skypark",
    cityCode: "BTF",
    cityName: "Bountiful",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTG",
    airportName: "Batangafo",
    cityCode: "BTG",
    cityName: "Batangafo",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "BTH",
    airportName: "Hang Nadim",
    cityCode: "BTH",
    cityName: "Batam",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BTI",
    airportName: "Barter Island",
    cityCode: "BTI",
    cityName: "Barter Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTJ",
    airportName: "Sultan Lskandarmuda Arpt.",
    cityCode: "BTJ",
    cityName: "Banda Aceh",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BTK",
    airportName: "Bratsk",
    cityCode: "BTK",
    cityName: "Bratsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "BTL",
    airportName: "WK Kellogg Regional",
    cityCode: "BTL",
    cityName: "Battle Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTM",
    airportName: "Bert Mooney Arpt.",
    cityCode: "BTM",
    cityName: "Butte,",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTN",
    airportName: "Bennettsville",
    cityCode: "BTN",
    cityName: "Bennettsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTO",
    airportName: "Botopasie",
    cityCode: "BTO",
    cityName: "Botopasie",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "BTP",
    airportName: "Butler County - Kenny Scholter Field",
    cityCode: "BTP",
    cityName: "Butler",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTQ",
    airportName: "Butare",
    cityCode: "BTQ",
    cityName: "Butare",
    countryName: "Rwanda",
    countryCode: "RW",
  },
  {
    airportCode: "BTR",
    airportName: "Ryan",
    cityCode: "BTR",
    cityName: "Baton Rouge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTS",
    airportName: "M. R. Å tefÃ¡nika",
    cityCode: "BTS",
    cityName: "Bratislava",
    countryName: "Slovakia",
    countryCode: "SK",
  },
  {
    airportCode: "BTT",
    airportName: "Bettles",
    cityCode: "BTT",
    cityName: "Bettles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTU",
    airportName: "Bintulu",
    cityCode: "BTU",
    cityName: "Bintulu",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "BTV",
    airportName: "Burlington Intl.",
    cityCode: "BTV",
    cityName: "Burlington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTW",
    airportName: "Batu Licin",
    cityCode: "BTW",
    cityName: "Batu Licin",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BTX",
    airportName: "Betoota",
    cityCode: "BTX",
    cityName: "Betoota",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BTY",
    airportName: "Beatty",
    cityCode: "BTY",
    cityName: "Beatty",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BTZ",
    airportName: "Bursa Arpt.",
    cityCode: "BTZ",
    cityName: "Bursa",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "BUA",
    airportName: "Buka",
    cityCode: "BUA",
    cityName: "Buka",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BUB",
    airportName: "Municipal",
    cityCode: "BUB",
    cityName: "Burwell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BUC",
    airportName: "Burketown",
    cityCode: "BUC",
    cityName: "Burketown",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BUD",
    airportName: "Ferihegy",
    cityCode: "BUD",
    cityName: "Budapest",
    countryName: "Hungary",
    countryCode: "HU",
  },
  {
    airportCode: "BUF",
    airportName: "Buffalo Niagara Intl.",
    cityCode: "BUF",
    cityName: "Buffalo, NY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BUG",
    airportName: "Gen V Deslandes",
    cityCode: "BUG",
    cityName: "Benguela",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "BUI",
    airportName: "Bokondini",
    cityCode: "BUI",
    cityName: "Bokondini",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BUJ",
    airportName: "Ain Eddis",
    cityCode: "BUJ",
    cityName: "Boussaada",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "BUK",
    airportName: "Albuq",
    cityCode: "BUK",
    cityName: "Albuq",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "BUL",
    airportName: "Bulolo",
    cityCode: "BUL",
    cityName: "Bulolo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BUM",
    airportName: "Butler",
    cityCode: "BUM",
    cityName: "Butler",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BUN",
    airportName: "Buenaventura",
    cityCode: "BUN",
    cityName: "Buenaventura",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "BUO",
    airportName: "Burao",
    cityCode: "BUO",
    cityName: "Burao",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "BUP",
    airportName: "Bhatinda",
    cityCode: "BUP",
    cityName: "Bhatinda",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "BUQ",
    airportName: "Bulawayo",
    cityCode: "BUQ",
    cityName: "Bulawayo",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "BUR",
    airportName: "Bob Hope",
    cityCode: "LAX",
    cityName: "Los Angeles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BUS",
    airportName: "Batum",
    cityCode: "BUS",
    cityName: "Batumi",
    countryName: "Georgia",
    countryCode: "GE",
  },
  {
    airportCode: "BUT",
    airportName: "Burtonwood",
    cityCode: "BUT",
    cityName: "Burtonwood",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BUU",
    airportName: "Buyo",
    cityCode: "BUU",
    cityName: "Buyo",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "BUV",
    airportName: "Bella Union",
    cityCode: "BUV",
    cityName: "Bella Union",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "BUW",
    airportName: "Baubau",
    cityCode: "BUW",
    cityName: "Baubau",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BUX",
    airportName: "Bunia",
    cityCode: "BUX",
    cityName: "Bunia",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BUY",
    airportName: "Bunbury",
    cityCode: "BUY",
    cityName: "Bunbury",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BUZ",
    airportName: "Bushehr",
    cityCode: "BUZ",
    cityName: "Bushehr",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "BVA",
    airportName: "Beauvais-Tille",
    cityCode: "PAR",
    cityName: "Paris",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BVB",
    airportName: "Boa Vista",
    cityCode: "BVB",
    cityName: "Boa Vista",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BVC",
    airportName: "Rabil",
    cityCode: "BVC",
    cityName: "Boa Vista",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "BVD",
    airportName: "Sea Port",
    cityCode: "BVD",
    cityName: "Beaver Inlet",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BVE",
    airportName: "Laroche",
    cityCode: "BVE",
    cityName: "Brive-La-Gaillarde",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BVF",
    airportName: "Dama",
    cityCode: "BVF",
    cityName: "Bua",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "BVG",
    airportName: "Berlevag",
    cityCode: "BVG",
    cityName: "Berlevag",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "BVH",
    airportName: "Vilhena",
    cityCode: "BVH",
    cityName: "Vilhena",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BVI",
    airportName: "Birdsville",
    cityCode: "BVI",
    cityName: "Birdsville, Queensland",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BVM",
    airportName: "Belmonte",
    cityCode: "BVM",
    cityName: "Belmonte",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BVO",
    airportName: "Bartlesville",
    cityCode: "BVO",
    cityName: "Bartlesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BVP",
    airportName: "Bolovip",
    cityCode: "BVP",
    cityName: "Bolovip",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BVR",
    airportName: "Esperadinha",
    cityCode: "BVR",
    cityName: "Brava",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "BVS",
    airportName: "Breves",
    cityCode: "BVS",
    cityName: "Breves",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BVW",
    airportName: "Batavia Downs",
    cityCode: "BVW",
    cityName: "Batavia Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BVX",
    airportName: "Municipal",
    cityCode: "BVX",
    cityName: "Batesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BVY",
    airportName: "Beverly Municiple Arpt.",
    cityCode: "BVY",
    cityName: "Beverly",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BVZ",
    airportName: "Beverley Springs",
    cityCode: "BVZ",
    cityName: "Beverley Springs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BWA",
    airportName: "Bhairawa",
    cityCode: "BWA",
    cityName: "Bhairawa",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "BWB",
    airportName: "Barrow Island",
    cityCode: "BWB",
    cityName: "Barrow Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BWC",
    airportName: "Brawley",
    cityCode: "BWC",
    cityName: "Brawley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BWD",
    airportName: "Brownwood",
    cityCode: "BWD",
    cityName: "Brownwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BWE",
    airportName: "Braunschweig",
    cityCode: "BWE",
    cityName: "Braunschweig",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BWF",
    airportName: "Walney Island",
    cityCode: "BWF",
    cityName: "Barrow-In-Furness",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BWG",
    airportName: "Warren County",
    cityCode: "BWG",
    cityName: "Bowling Green",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BWH",
    airportName: "Butterworth",
    cityCode: "BWH",
    cityName: "Butterworth",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "BWI",
    airportName: "Thurgood Marshall",
    cityCode: "WAS",
    cityName: "Baltimore/Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BWJ",
    airportName: "Bawan",
    cityCode: "BWJ",
    cityName: "Bawan",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BWK",
    airportName: "Bol",
    cityCode: "BWK",
    cityName: "Bol",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "BWL",
    airportName: "Blackwell",
    cityCode: "BWL",
    cityName: "Blackwell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BWM",
    airportName: "Bowman",
    cityCode: "BWM",
    cityName: "Bowman",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BWN",
    airportName: "Bandar Seri Begwan Intl. Arpt.",
    cityCode: "BWN",
    cityName: "Bandar Seri Begawan",
    countryName: "Brunei Darussalam",
    countryCode: "BN",
  },
  {
    airportCode: "BWO",
    airportName: "Balakovo",
    cityCode: "BWO",
    cityName: "Balakovo",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "BWP",
    airportName: "Bewani",
    cityCode: "BWP",
    cityName: "Bewani",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "BWQ",
    airportName: "Brewarrina",
    cityCode: "BWQ",
    cityName: "Brewarrina",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BWS",
    airportName: "Blaine",
    cityCode: "BWS",
    cityName: "Blaine",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BWT",
    airportName: "Burnie Wynyard",
    cityCode: "BWT",
    cityName: "Burnie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BWU",
    airportName: "Sydney Bankstown",
    cityCode: "SYD",
    cityName: "Sydney",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BWY",
    airportName: "Bentwaters St",
    cityCode: "BWY",
    cityName: "Woodbridge",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "BXA",
    airportName: "George R Carr",
    cityCode: "BXA",
    cityName: "Bogalusa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BXB",
    airportName: "Babo",
    cityCode: "BXB",
    cityName: "Babo",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BXC",
    airportName: "Boxborough",
    cityCode: "BXC",
    cityName: "Boxborough",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BXD",
    airportName: "Bade",
    cityCode: "BXD",
    cityName: "Bade",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BXE",
    airportName: "Bakel",
    cityCode: "BXE",
    cityName: "Bakel",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "BXF",
    airportName: "Belburn",
    cityCode: "BXF",
    cityName: "Belburn",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BXG",
    airportName: "Bendigo",
    cityCode: "BXG",
    cityName: "Bendigo",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BXH",
    airportName: "Balhash",
    cityCode: "BXH",
    cityName: "Balhash",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "BXI",
    airportName: "Boundiali",
    cityCode: "BXI",
    cityName: "Boundiali",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "BXJ",
    airportName: "Burundai",
    cityCode: "BXJ",
    cityName: "Burundai",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "BXK",
    airportName: "Buckeye Municipal",
    cityCode: "BXK",
    cityName: "Buckeye",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BXL",
    airportName: "Blue Lagoon",
    cityCode: "BXL",
    cityName: "Blue Lagoon",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "BXM",
    airportName: "Batom",
    cityCode: "BXM",
    cityName: "Batom",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BXN",
    airportName: "Imsik Arpt.",
    cityCode: "BJV",
    cityName: "Milas",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "BXO",
    airportName: "Buochs",
    cityCode: "BXO",
    cityName: "Buochs",
    countryName: "Guinea-Bissau",
    countryCode: "GW",
  },
  {
    airportCode: "BXR",
    airportName: "Bam",
    cityCode: "BXR",
    cityName: "Bam",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "BXS",
    airportName: "Borrego Springs",
    cityCode: "BXS",
    cityName: "Borrego Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BXT",
    airportName: "Bontang",
    cityCode: "BXT",
    cityName: "Bontang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BXU",
    airportName: "Butuan",
    cityCode: "BXU",
    cityName: "Butuan",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "BXV",
    airportName: "Breiddalsvik",
    cityCode: "BXV",
    cityName: "Breiddalsvik",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "BXX",
    airportName: "Borama Intl.",
    cityCode: "BXX",
    cityName: "Borama",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "BYA",
    airportName: "Boundary",
    cityCode: "BYA",
    cityName: "Boundary",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BYB",
    airportName: "Dibaa",
    cityCode: "BYB",
    cityName: "Dibaa",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "BYC",
    airportName: "Yacuiba",
    cityCode: "BYC",
    cityName: "Yacuiba",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "BYD",
    airportName: "Beidah",
    cityCode: "BYD",
    cityName: "Beidah",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "BYG",
    airportName: "Municipal",
    cityCode: "BYG",
    cityName: "Buffalo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BYH",
    airportName: "Blytheville AFB",
    cityCode: "BYH",
    cityName: "Blytheville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BYI",
    airportName: "Rupert",
    cityCode: "BYI",
    cityName: "Rupert",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BYK",
    airportName: "Bouake",
    cityCode: "BYK",
    cityName: "Bouake",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "BYL",
    airportName: "Bella Yella",
    cityCode: "BYL",
    cityName: "Bella Yella",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "BYM",
    airportName: "C.M. de Cespedes",
    cityCode: "BYM",
    cityName: "Bayamo",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "BYN",
    airportName: "Bayankhongor",
    cityCode: "BYN",
    cityName: "Bayankhongor",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "BYO",
    airportName: "Bonito Arpt.",
    cityCode: "BYO",
    cityName: "Bonito",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BYQ",
    airportName: "Bunyu",
    cityCode: "BYQ",
    cityName: "Bunyu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "BYS",
    airportName: "Bicycle Lake AAF",
    cityCode: "BYS",
    cityName: "Fort Irwin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BYT",
    airportName: "Bantry",
    cityCode: "BYT",
    cityName: "Bantry",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "BYU",
    airportName: "Bindlacher-Berg",
    cityCode: "BYU",
    cityName: "Bayreuth",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BYW",
    airportName: "Blakely Island",
    cityCode: "BYW",
    cityName: "Blakely Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BYX",
    airportName: "Baniyala",
    cityCode: "BYX",
    cityName: "Baniyala",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BZA",
    airportName: "San Pedro",
    cityCode: "BZA",
    cityName: "Bonanza",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "BZB",
    airportName: "Bazaruto Island",
    cityCode: "BZB",
    cityName: "Bazaruto Island",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "BZC",
    airportName: "Buzios",
    cityCode: "BZC",
    cityName: "Buzios",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "BZD",
    airportName: "Balranald",
    cityCode: "BZD",
    cityName: "Balranald",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BZE",
    airportName: "Philip S.W.Goldson Int",
    cityCode: "BZE",
    cityName: "Belize City",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "BZG",
    airportName: "Bydgoszcz I. J. Paderewski Arpt.",
    cityCode: "BZG",
    cityName: "Bydgoszcz",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "BZH",
    airportName: "Bumi Hills",
    cityCode: "BZH",
    cityName: "Bumi Hills",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "BZI",
    airportName: "Balikesir",
    cityCode: "BZI",
    cityName: "Balikesir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "BZK",
    airportName: "Briansk",
    cityCode: "BZK",
    cityName: "Briansk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "BZL",
    airportName: "Barisal",
    cityCode: "BZL",
    cityName: "Barisal",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "BZM",
    airportName: "Woensdrecht",
    cityCode: "BZM",
    cityName: "Bergen Op Zoom",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "BZN",
    airportName: "Gallatin Field",
    cityCode: "BZN",
    cityName: "Bozeman",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BZO",
    airportName: "Bolzano",
    cityCode: "BZO",
    cityName: "Bolzano",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "BZP",
    airportName: "Bizant",
    cityCode: "BZP",
    cityName: "Bizant",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "BZR",
    airportName: "Beziers Vias",
    cityCode: "BZR",
    cityName: "Beziers",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "BZS",
    airportName: "Buzzards Pt S",
    cityCode: "WAS",
    cityName: "Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BZT",
    airportName: "Hinkles Ferry",
    cityCode: "BZT",
    cityName: "Brazoria",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "BZU",
    airportName: "Buta",
    cityCode: "BZU",
    cityName: "Buta",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "BZV",
    airportName: "Maya Maya",
    cityCode: "BZV",
    cityName: "Brazzaville",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "BZY",
    airportName: "Beltsy",
    cityCode: "BZY",
    cityName: "Beltsy",
    countryName: "Moldova",
    countryCode: "MD",
  },
  {
    airportCode: "BZZ",
    airportName: "Raf Brize Norton",
    cityCode: "BZZ",
    cityName: "Oxford",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CAA",
    airportName: "Catacamas",
    cityCode: "CAA",
    cityName: "Catacamas",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "CAB",
    airportName: "Cabinda",
    cityCode: "CAB",
    cityName: "Cabinda",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "CAC",
    airportName: "Cascavel",
    cityCode: "CAC",
    cityName: "Cascavel",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CAD",
    airportName: "Cadillac",
    cityCode: "CAD",
    cityName: "Cadillac",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CAE",
    airportName: "Metropolitan Arpt.",
    cityCode: "CAE",
    cityName: "Columbia, SC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CAF",
    airportName: "Carauari",
    cityCode: "CAF",
    cityName: "Carauari",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CAG",
    airportName: "Elmas",
    cityCode: "CAG",
    cityName: "Cagliari",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "CAH",
    airportName: "Ca Mau",
    cityCode: "CAH",
    cityName: "Ca Mau",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "CAI",
    airportName: "Cairo Intl.",
    cityCode: "CAI",
    cityName: "Cairo",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "CAJ",
    airportName: "Canaima",
    cityCode: "CAJ",
    cityName: "Canaima",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CAK",
    airportName: "Akron/canton Regional",
    cityCode: "CAK",
    cityName: "Akron/Canton, OH",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CAL",
    airportName: "Machrihanish",
    cityCode: "CAL",
    cityName: "Campbeltown",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CAM",
    airportName: "Camiri",
    cityCode: "CAM",
    cityName: "Camiri",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "CAN",
    airportName: "New Baiyun",
    cityCode: "CAN",
    cityName: "Guangzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CAO",
    airportName: "Clayton",
    cityCode: "CAO",
    cityName: "Clayton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CAP",
    airportName: "Cap Haitien",
    cityCode: "CAP",
    cityName: "Cap Haitien",
    countryName: "Haiti",
    countryCode: "HT",
  },
  {
    airportCode: "CAQ",
    airportName: "Caucasia",
    cityCode: "CAQ",
    cityName: "Caucasia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CAR",
    airportName: "Municipal",
    cityCode: "CAR",
    cityName: "Caribou",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CAS",
    airportName: "Anfa",
    cityCode: "CAS",
    cityName: "Casablanca",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "CAT",
    airportName: "New Bight",
    cityCode: "CAT",
    cityName: "Cat Island",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "CAU",
    airportName: "Caruaru",
    cityCode: "CAU",
    cityName: "Caruaru",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CAV",
    airportName: "Cazombo",
    cityCode: "CAV",
    cityName: "Cazombo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "CAW",
    airportName: "Bartolomeu Lisandro",
    cityCode: "CAW",
    cityName: "Campos",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CAX",
    airportName: "Carlisle",
    cityCode: "CAX",
    cityName: "Carlisle",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CAY",
    airportName: "Rochambeau",
    cityCode: "CAY",
    cityName: "Cayenne",
    countryName: "French Guiana",
    countryCode: "GF",
  },
  {
    airportCode: "CAZ",
    airportName: "Cobar",
    cityCode: "CAZ",
    cityName: "Cobar",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CBA",
    airportName: "Corner Bay",
    cityCode: "CBA",
    cityName: "Corner Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CBB",
    airportName: "J Wilsterman",
    cityCode: "CBB",
    cityName: "Cochabamba",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "CBC",
    airportName: "Cherrabun",
    cityCode: "CBC",
    cityName: "Cherrabun",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CBD",
    airportName: "Car Nicobar",
    cityCode: "CBD",
    cityName: "Car Nicobar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "CBE",
    airportName: "Wiley Ford",
    cityCode: "CBE",
    cityName: "Cumberland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CBF",
    airportName: "Municipal",
    cityCode: "CBF",
    cityName: "Council Bluffs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CBG",
    airportName: "Cambridge",
    cityCode: "CBG",
    cityName: "Cambridge",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CBH",
    airportName: "Leger",
    cityCode: "CBH",
    cityName: "Bechar",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "CBI",
    airportName: "Cape Barren Island",
    cityCode: "CBI",
    cityName: "Cape Barren Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CBJ",
    airportName: "Cabo Rojo",
    cityCode: "CBJ",
    cityName: "Cabo Rojo",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "CBK",
    airportName: "Municipal",
    cityCode: "CBK",
    cityName: "Colby",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CBL",
    airportName: "Ciudad Bolivar",
    cityCode: "CBL",
    cityName: "Ciudad Bolivar",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CBM",
    airportName: "Columbus AFB",
    cityCode: "CBM",
    cityName: "Columbus, MS",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CBN",
    airportName: "Penggung",
    cityCode: "CBN",
    cityName: "Cirebon",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "CBO",
    airportName: "Awang",
    cityCode: "CBO",
    cityName: "Cotabato",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CBP",
    airportName: "Coimbra",
    cityCode: "CBP",
    cityName: "Coimbra",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "CBQ",
    airportName: "Calabar",
    cityCode: "CBQ",
    cityName: "Calabar",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "CBR",
    airportName: "Canberra",
    cityCode: "CBR",
    cityName: "Canberra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CBS",
    airportName: "Oro Negro",
    cityCode: "CBS",
    cityName: "Cabimas",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CBT",
    airportName: "Catumbela",
    cityCode: "CBT",
    cityName: "Catumbela",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "CBV",
    airportName: "Coban",
    cityCode: "CBV",
    cityName: "Coban",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "CBW",
    airportName: "Campo Mourao",
    cityCode: "CBW",
    cityName: "Campo Mourao",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CBX",
    airportName: "Condobolin",
    cityCode: "CBX",
    cityName: "Condobolin",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CBY",
    airportName: "Canobie",
    cityCode: "CBY",
    cityName: "Canobie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CBZ",
    airportName: "Cabin Creek",
    cityCode: "CBZ",
    cityName: "Cabin Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CCA",
    airportName: "Chaffee AFB",
    cityCode: "CCA",
    cityName: "Fort Chaffee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CCB",
    airportName: "Cable Arpt.",
    cityCode: "CCB",
    cityName: "Upland, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CCC",
    airportName: "Cayo Coco",
    cityCode: "CCC",
    cityName: "Cayo Coco",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "CCF",
    airportName: "Salvaza",
    cityCode: "CCF",
    cityName: "Carcassonne",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CCG",
    airportName: "Crane County Arpt",
    cityCode: "CCG",
    cityName: "Crane",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CCH",
    airportName: "Chile Chico",
    cityCode: "CCH",
    cityName: "Chile Chico",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "CCI",
    airportName: "Concordia",
    cityCode: "CCI",
    cityName: "Concordia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CCJ",
    airportName: "Kozhikode Arpt.",
    cityCode: "CCJ",
    cityName: "Kozhikode",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "CCK",
    airportName: "Cocos Islands",
    cityCode: "CCK",
    cityName: "Cocos Islands",
    countryName: "Cocos",
    countryCode: "CC",
  },
  {
    airportCode: "CCL",
    airportName: "Chinchilla",
    cityCode: "CCL",
    cityName: "Chinchilla",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CCM",
    airportName: "Criciuma",
    cityCode: "CCM",
    cityName: "Criciuma",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CCN",
    airportName: "Chakcharan",
    cityCode: "CCN",
    cityName: "Chakcharan",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "CCO",
    airportName: "Carimagua",
    cityCode: "CCO",
    cityName: "Carimagua",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CCP",
    airportName: "Carriel Sur",
    cityCode: "CCP",
    cityName: "Concepcion",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "CCQ",
    airportName: "Cachoeira",
    cityCode: "CCQ",
    cityName: "Cachoeira",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CCR",
    airportName: "Buchanan Fld",
    cityCode: "CCR",
    cityName: "Concord",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CCS",
    airportName: "Simon Bolivar Intl. Arpt.",
    cityCode: "CCS",
    cityName: "Caracas",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CCT",
    airportName: "Colonia Catriel",
    cityCode: "CCT",
    cityName: "Colonia Catriel",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CCU",
    airportName: "Netaji Subhash Chandra Bose International Airport",
    cityCode: "CCU",
    cityName: "Kolkata",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "CCV",
    airportName: "Craig Cove",
    cityCode: "CCV",
    cityName: "Craig Cove",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "CCW",
    airportName: "Cowell",
    cityCode: "CCW",
    cityName: "Cowell",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CCX",
    airportName: "Caceres",
    cityCode: "CCX",
    cityName: "Caceres",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CCY",
    airportName: "Municipal",
    cityCode: "CCY",
    cityName: "Charles City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CCZ",
    airportName: "Chub Cay",
    cityCode: "CCZ",
    cityName: "Chub Cay",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "CDA",
    airportName: "Cooinda",
    cityCode: "CDA",
    cityName: "Cooinda",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CDB",
    airportName: "Cold Bay",
    cityCode: "CDB",
    cityName: "Cold Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDC",
    airportName: "Cedar City",
    cityCode: "CDC",
    cityName: "Cedar City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDD",
    airportName: "Cauquira",
    cityCode: "CDD",
    cityName: "Cauquira",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "CDE",
    airportName: "Caledonia",
    cityCode: "CDE",
    cityName: "Caledonia",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "CDG",
    airportName: "Charles  De Gaulle",
    cityCode: "PAR",
    cityName: "Paris",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CDH",
    airportName: "Harrell Fld",
    cityCode: "CDH",
    cityName: "Camden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDI",
    airportName: "Cachoeiro Itapemirim",
    cityCode: "CDI",
    cityName: "Cachoeiro Itapemirim",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CDJ",
    airportName: "Conceicao Do Araguaia",
    cityCode: "CDJ",
    cityName: "Conceicao Do Araguaia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CDK",
    airportName: "Lewis",
    cityCode: "CDK",
    cityName: "Cedar Key",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDL",
    airportName: "Candle",
    cityCode: "CDL",
    cityName: "Candle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDN",
    airportName: "Woodward Fld",
    cityCode: "CDN",
    cityName: "Camden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDO",
    airportName: "Cradock",
    cityCode: "CDO",
    cityName: "Cradock",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "CDP",
    airportName: "Cuddapah",
    cityCode: "CDP",
    cityName: "Cuddapah",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "CDQ",
    airportName: "Croydon",
    cityCode: "CDQ",
    cityName: "Croydon",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CDR",
    airportName: "Chadron",
    cityCode: "CDR",
    cityName: "Chadron",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDS",
    airportName: "Childress",
    cityCode: "CDS",
    cityName: "Childress",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDU",
    airportName: "Camden",
    cityCode: "CDU",
    cityName: "Camden",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CDV",
    airportName: "Mudhole Smith",
    cityCode: "CDV",
    cityName: "Cordova",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDW",
    airportName: "Caldwell Wright",
    cityCode: "CDW",
    cityName: "Caldwell, NJ",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CDY",
    airportName: "Cagayan De Sulu",
    cityCode: "CDY",
    cityName: "Cagayan De Sulu",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CDZ",
    airportName: "Cadiz",
    cityCode: "CDZ",
    cityName: "Cadiz",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "CEA",
    airportName: "Cessna Aircraft Field",
    cityCode: "CEA",
    cityName: "Wichita",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CEB",
    airportName: "Mactan-Cebu Intl.",
    cityCode: "CEB",
    cityName: "Cebu",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CEC",
    airportName: "Mc Namara Fld",
    cityCode: "CEC",
    cityName: "Crescent City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CED",
    airportName: "Ceduna",
    cityCode: "CED",
    cityName: "Ceduna",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CEE",
    airportName: "Cherepovets",
    cityCode: "CEE",
    cityName: "Cherepovets",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "CEF",
    airportName: "Westover Metro Arpt",
    cityCode: "CEF",
    cityName: "Springfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CEG",
    airportName: "Chester",
    cityCode: "CEG",
    cityName: "Chester",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CEH",
    airportName: "Chelinda",
    cityCode: "CEH",
    cityName: "Chelinda",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "CEI",
    airportName: "Chiang Rai",
    cityCode: "CEI",
    cityName: "Chiang Rai",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "CEJ",
    airportName: "Chernigov",
    cityCode: "CEJ",
    cityName: "Chernigov",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "CEK",
    airportName: "Chelyabinsk",
    cityCode: "CEK",
    cityName: "Chelyabinsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "CEL",
    airportName: "Cape Eleuthera",
    cityCode: "CEL",
    cityName: "Cape Eleuthera",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "CEM",
    airportName: "Central",
    cityCode: "CEM",
    cityName: "Central",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CEN",
    airportName: "Ciudad Obregon",
    cityCode: "CEN",
    cityName: "Ciudad Obregon",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CEO",
    airportName: "Waco Kungo",
    cityCode: "CEO",
    cityName: "Waco Kungo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "CEP",
    airportName: "Concepcion",
    cityCode: "CEP",
    cityName: "Concepcion",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "CEQ",
    airportName: "Mandelieu",
    cityCode: "CEQ",
    cityName: "Cannes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CER",
    airportName: "Maupertus",
    cityCode: "CER",
    cityName: "Cherbourg",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CES",
    airportName: "Cessnock",
    cityCode: "CES",
    cityName: "Cessnock",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CET",
    airportName: "Le Pontreau",
    cityCode: "CET",
    cityName: "Cholet",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CEU",
    airportName: "Oconee County",
    cityCode: "CEU",
    cityName: "Clemson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CEV",
    airportName: "Mettle Field",
    cityCode: "CEV",
    cityName: "Connersville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CEW",
    airportName: "Bob Sikes",
    cityCode: "CEW",
    cityName: "Crestview",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CEX",
    airportName: "Chena Hot Springs",
    cityCode: "CEX",
    cityName: "Chena Hot Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CEY",
    airportName: "Calloway County",
    cityCode: "CEY",
    cityName: "Murray",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CEZ",
    airportName: "Montezuma County",
    cityCode: "CEZ",
    cityName: "Cortez",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CFA",
    airportName: "Coffee Point",
    cityCode: "CFA",
    cityName: "Coffee Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CFB",
    airportName: "Cabo Frio",
    cityCode: "CFB",
    cityName: "Cabo Frio",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CFC",
    airportName: "Cacador",
    cityCode: "CFC",
    cityName: "Cacador",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CFD",
    airportName: "Coulter Field",
    cityCode: "CFD",
    cityName: "Bryan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CFE",
    airportName: "Aulnat",
    cityCode: "CFE",
    cityName: "Clermont-Ferrand",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CFF",
    airportName: "Cafunfo",
    cityCode: "CFF",
    cityName: "Cafunfo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "CFG",
    airportName: "Cienfuegos",
    cityCode: "CFG",
    cityName: "Cienfuegos",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "CFH",
    airportName: "Clifton Hills",
    cityCode: "CFH",
    cityName: "Clifton Hills",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CFI",
    airportName: "Camfield",
    cityCode: "CFI",
    cityName: "Camfield",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CFK",
    airportName: "Chefornak",
    cityCode: "CFK",
    cityName: "Chefornak, AK",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CFN",
    airportName: "Donegal",
    cityCode: "CFN",
    cityName: "Carrickfinn",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "CFO",
    airportName: "Confreza",
    cityCode: "CFO",
    cityName: "Confreza",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CFP",
    airportName: "Carpentaria Downs",
    cityCode: "CFP",
    cityName: "Carpentaria Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CFQ",
    airportName: "Creston Valley Arpt.",
    cityCode: "CFQ",
    cityName: "Creston",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "CFR",
    airportName: "Carpiquet",
    cityCode: "CFR",
    cityName: "Caen",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CFS",
    airportName: "Coffs Harbour",
    cityCode: "CFS",
    cityName: "Coffs Harbour",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CFT",
    airportName: "Morenci",
    cityCode: "CFT",
    cityName: "Clifton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CFU",
    airportName: "Ioannis Kapodistrias",
    cityCode: "CFU",
    cityName: "Kerkyra",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "CFV",
    airportName: "Municipal",
    cityCode: "CFV",
    cityName: "Coffeyville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CGA",
    airportName: "Craig SPB",
    cityCode: "CGA",
    cityName: "Craig",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CGB",
    airportName: "Intl. Airpt. Marechal Rondon",
    cityCode: "CGB",
    cityName: "Cuiaba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CGC",
    airportName: "Cape Gloucester",
    cityCode: "CGC",
    cityName: "Cape Gloucester",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "CGD",
    airportName: "Changde",
    cityCode: "CGD",
    cityName: "Changde",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CGE",
    airportName: "Cambridge",
    cityCode: "CGE",
    cityName: "Cambridge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CGF",
    airportName: "Cuyahoga County",
    cityCode: "CGF",
    cityName: "Cleveland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CGH",
    airportName: "Congonhas",
    cityCode: "SAO",
    cityName: "Sao Paulo",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CGI",
    airportName: "Cape Girardeau",
    cityCode: "CGI",
    cityName: "Cape Girardeau",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CGJ",
    airportName: "Chingola",
    cityCode: "CGJ",
    cityName: "Chingola",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "CGK",
    airportName: "Soekarno-Hatta Intl.",
    cityCode: "CGK",
    cityName: "Jakarta",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "CGM",
    airportName: "Mambajao",
    cityCode: "CGM",
    cityName: "Camiguin",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CGN",
    airportName: "Cologne/bonn",
    cityCode: "CGN",
    cityName: "Cologne",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "CGO",
    airportName: "Zhengzhou",
    cityCode: "CGO",
    cityName: "Zhengzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CGP",
    airportName: "Shah Amanat International Airport",
    cityCode: "CGP",
    cityName: "Chittagong",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "CGQ",
    airportName: "Changchun",
    cityCode: "CGQ",
    cityName: "Changchun",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CGR",
    airportName: "Intl. Arpt. Campo Grande",
    cityCode: "CGR",
    cityName: "Campo Grande",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CGS",
    airportName: "College Park",
    cityCode: "CGS",
    cityName: "College Park",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CGT",
    airportName: "Chinguitti",
    cityCode: "CGT",
    cityName: "Chinguitti",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "CGU",
    airportName: "Ciudad Guayana",
    cityCode: "CGU",
    cityName: "Ciudad Guayana",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CGV",
    airportName: "Caiguna",
    cityCode: "CGV",
    cityName: "Caiguna",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CGX",
    airportName: "Merrill C Meigs",
    cityCode: "CGX",
    cityName: "Chicago",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CGY",
    airportName: "Cagayan De Oro Domestic Arpt.",
    cityCode: "CGY",
    cityName: "Cagayan De Oro",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CGZ",
    airportName: "Municipal",
    cityCode: "CGZ",
    cityName: "Casa Grande",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHA",
    airportName: "Lovell Field",
    cityCode: "CHA",
    cityName: "Chattanooga,TN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHB",
    airportName: "Chilas",
    cityCode: "CHB",
    cityName: "Chilas",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "CHC",
    airportName: "Christchurch Intl.",
    cityCode: "CHC",
    cityName: "Christchurch",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "CHD",
    airportName: "Williams Gateway Arpt.",
    cityCode: "CHD",
    cityName: "Mesa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHE",
    airportName: "Reenroe",
    cityCode: "CHE",
    cityName: "Caherciveen",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "CHF",
    airportName: "Jinhae",
    cityCode: "CHF",
    cityName: "Jinhae",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "CHG",
    airportName: "Chaoyang Arpt.",
    cityCode: "CHG",
    cityName: "Chaoyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CHH",
    airportName: "Chachapoyas",
    cityCode: "CHH",
    cityName: "Chachapoyas",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "CHI",
    airportName: "Chicago FSS",
    cityCode: "CHI",
    cityName: "Chicago",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHJ",
    airportName: "Chipinge",
    cityCode: "CHJ",
    cityName: "Chipinge",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "CHK",
    airportName: "Municipal",
    cityCode: "CHK",
    cityName: "Chickasha",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHL",
    airportName: "Challis",
    cityCode: "CHL",
    cityName: "Challis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHM",
    airportName: "Chimbote",
    cityCode: "CHM",
    cityName: "Chimbote",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "CHN",
    airportName: "Jeonju",
    cityCode: "CHN",
    cityName: "Jeonju",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "CHO",
    airportName: "Albemarle",
    cityCode: "CHO",
    cityName: "Charlottesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHP",
    airportName: "Circle Hot Springs",
    cityCode: "CHP",
    cityName: "Circle Hot Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHQ",
    airportName: "Souda",
    cityCode: "CHQ",
    cityName: "Chania",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "CHR",
    airportName: "Chateauroux",
    cityCode: "CHR",
    cityName: "Chateauroux",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CHS",
    airportName: "Charleston, AFB Municipal",
    cityCode: "CHS",
    cityName: "Charleston, SC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHT",
    airportName: "Karewa",
    cityCode: "CHT",
    cityName: "Chatham Island",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "CHU",
    airportName: "Chuathbaluk",
    cityCode: "CHU",
    cityName: "Chuathbaluk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CHV",
    airportName: "Chaves",
    cityCode: "CHV",
    cityName: "Chaves",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "CHW",
    airportName: "Jiuhuang",
    cityCode: "CHW",
    cityName: "Jiuquan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CHX",
    airportName: "Changuinola",
    cityCode: "CHX",
    cityName: "Changuinola",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "CHY",
    airportName: "Choiseul Bay",
    cityCode: "CHY",
    cityName: "Choiseul Bay",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "CHZ",
    airportName: "State",
    cityCode: "CHZ",
    cityName: "Chiloquin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIA",
    airportName: "Ciampino",
    cityCode: "ROM",
    cityName: "Rome",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "CIB",
    airportName: "Ap In The Sky",
    cityCode: "CIB",
    cityName: "Catalina Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIC",
    airportName: "Chico",
    cityCode: "CIC",
    cityName: "Chico",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CID",
    airportName: "Cedar Rapids",
    cityCode: "CID",
    cityName: "Cedar Rapids",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIE",
    airportName: "Collie",
    cityCode: "CIE",
    cityName: "Collie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CIF",
    airportName: "Chifeng",
    cityCode: "CIF",
    cityName: "Chifeng",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CIG",
    airportName: "Craig-Moffat",
    cityCode: "CIG",
    cityName: "Craig",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIH",
    airportName: "Changzhi",
    cityCode: "CIH",
    cityName: "Changzhi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CIJ",
    airportName: "E. Beltram",
    cityCode: "CIJ",
    cityName: "Cobija",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "CIK",
    airportName: "Chalkyitsik",
    cityCode: "CIK",
    cityName: "Chalkyitsik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIL",
    airportName: "Melsing Creek",
    cityCode: "CIL",
    cityName: "Council",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIM",
    airportName: "Cimitarra",
    cityCode: "CIM",
    cityName: "Cimitarra",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CIN",
    airportName: "Carroll",
    cityCode: "CIN",
    cityName: "Carroll",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIO",
    airportName: "MCAL Lopez",
    cityCode: "CIO",
    cityName: "Concepcion",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "CIP",
    airportName: "Chipata",
    cityCode: "CIP",
    cityName: "Chipata",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "CIQ",
    airportName: "Chiquimula",
    cityCode: "CIQ",
    cityName: "Chiquimula",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "CIR",
    airportName: "Cairo",
    cityCode: "CIR",
    cityName: "Cairo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIS",
    airportName: "Canton Island",
    cityCode: "CIS",
    cityName: "Canton Island",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "CIT",
    airportName: "Shimkent",
    cityCode: "CIT",
    cityName: "Shimkent",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "CIU",
    airportName: "Chippewa County",
    cityCode: "CIU",
    cityName: "Sault Ste Marie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIV",
    airportName: "Chomley",
    cityCode: "CIV",
    cityName: "Chomley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CIW",
    airportName: "Canouan Island",
    cityCode: "CIW",
    cityName: "Canouan Island",
    countryName: "Saint Vincent and the Grenadines",
    countryCode: "VC",
  },
  {
    airportCode: "CIX",
    airportName: "Cornel Ruiz",
    cityCode: "CIX",
    cityName: "Chiclayo",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "CIY",
    airportName: "Comiso",
    cityCode: "CIY",
    cityName: "Comiso",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "CIZ",
    airportName: "Coari",
    cityCode: "CIZ",
    cityName: "Coari",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CJA",
    airportName: "Cajamarca",
    cityCode: "CJA",
    cityName: "Cajamarca",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "CJB",
    airportName: "Peelamedu",
    cityCode: "CJB",
    cityName: "Coimbatore",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "CJC",
    airportName: "El Loa",
    cityCode: "CJC",
    cityName: "Calama",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "CJD",
    airportName: "Candilejas",
    cityCode: "CJD",
    cityName: "Candilejas",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CJH",
    airportName: "Chilko Lake",
    cityCode: "CJH",
    cityName: "Chilko Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "CJI",
    airportName: "Ciudad Real - Don Quijote Arpt.",
    cityCode: "CJI",
    cityName: "Ciudad Real",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "CJJ",
    airportName: "Cheongju",
    cityCode: "CJJ",
    cityName: "Cheongju",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "CJL",
    airportName: "Chitral",
    cityCode: "CJL",
    cityName: "Chitral",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "CJM",
    airportName: "Chumphon Arpt.",
    cityCode: "CJM",
    cityName: "Chumphon",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "CJN",
    airportName: "Gillespie Field",
    cityCode: "CJN",
    cityName: "El Cajon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CJS",
    airportName: "Intl Abraham Gonzalez",
    cityCode: "CJS",
    cityName: "Ciudad Juarez",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CJT",
    airportName: "Copalar",
    cityCode: "CJT",
    cityName: "Comitan",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CJU",
    airportName: "Jeju Arpt.",
    cityCode: "CJU",
    cityName: "Jeju",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "CKA",
    airportName: "Kegelman Af",
    cityCode: "CKA",
    cityName: "Cherokee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKB",
    airportName: "Benedum",
    cityCode: "CKB",
    cityName: "Clarksburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKC",
    airportName: "Cherkassy",
    cityCode: "CKC",
    cityName: "Cherkassy",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "CKD",
    airportName: "Crooked Creek",
    cityCode: "CKD",
    cityName: "Crooked Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKE",
    airportName: "Clear Lake",
    cityCode: "CKE",
    cityName: "Clear Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKG",
    airportName: "Chongqing Jiangbei Intl.",
    cityCode: "CKG",
    cityName: "Chongqing",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CKH",
    airportName: "Chokurdah",
    cityCode: "CKH",
    cityName: "Chokurdah",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "CKI",
    airportName: "Croker Island",
    cityCode: "CKI",
    cityName: "Croker Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CKK",
    airportName: "Cherokee",
    cityCode: "CKK",
    cityName: "Cherokee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKL",
    airportName: "Chkalovsky",
    cityCode: "CKL",
    cityName: "Chkalovsky",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "CKM",
    airportName: "Fletcher Field",
    cityCode: "CKM",
    cityName: "Clarksdale, MS",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKN",
    airportName: "Municipal",
    cityCode: "CKN",
    cityName: "Crookston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKO",
    airportName: "Cornelio Procopio",
    cityCode: "CKO",
    cityName: "Cornelio Procopio",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CKR",
    airportName: "Crane Island",
    cityCode: "CKR",
    cityName: "Crane Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKS",
    airportName: "Carajas",
    cityCode: "CKS",
    cityName: "Carajas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CKT",
    airportName: "Sarakhs",
    cityCode: "CKT",
    cityName: "Sarakhs",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "CKU",
    airportName: "City",
    cityCode: "CKU",
    cityName: "Cordova",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKV",
    airportName: "Outlaw Field",
    cityCode: "CKV",
    cityName: "Clarksville, TN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKX",
    airportName: "Chicken",
    cityCode: "CKX",
    cityName: "Chicken",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CKY",
    airportName: "Conakry",
    cityCode: "CKY",
    cityName: "Conakry",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "CKZ",
    airportName: "Canakkale",
    cityCode: "CKZ",
    cityName: "Canakkale",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "CLA",
    airportName: "Comilla",
    cityCode: "CLA",
    cityName: "Comilla",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "CLB",
    airportName: "Castlebar",
    cityCode: "CLB",
    cityName: "Castlebar",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "CLC",
    airportName: "Metroport",
    cityCode: "CLC",
    cityName: "Clearlake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLD",
    airportName: "Mc Clellan-Palomar Arpt.",
    cityCode: "CLD",
    cityName: "Carlsbad, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLE",
    airportName: "Hopkins Intl.",
    cityCode: "CLE",
    cityName: "Cleveland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLG",
    airportName: "Coalinga",
    cityCode: "CLG",
    cityName: "Coalinga",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLH",
    airportName: "Coolah",
    cityCode: "CLH",
    cityName: "Coolah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CLI",
    airportName: "Clintonville",
    cityCode: "CLI",
    cityName: "Clintonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLJ",
    airportName: "Cluj Napoca Intl. Arpt.",
    cityCode: "CLJ",
    cityName: "Cluj-Napoca",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "CLK",
    airportName: "Municipal",
    cityCode: "CLK",
    cityName: "Clinton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLL",
    airportName: "Easterwood Field",
    cityCode: "CLL",
    cityName: "College Station, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLM",
    airportName: "Fairchild Intl",
    cityCode: "CLM",
    cityName: "Port Angeles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLN",
    airportName: "Carolina",
    cityCode: "CLN",
    cityName: "Carolina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CLO",
    airportName: "Alfonso B. Aragon",
    cityCode: "CLO",
    cityName: "Cali",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CLP",
    airportName: "Clarks Point",
    cityCode: "CLP",
    cityName: "Clarks Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLQ",
    airportName: "Colima",
    cityCode: "CLQ",
    cityName: "Colima",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CLR",
    airportName: "Calipatria",
    cityCode: "CLR",
    cityName: "Calipatria",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLS",
    airportName: "Centralia",
    cityCode: "CLS",
    cityName: "Chehalis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLT",
    airportName: "Charlotte Douglas",
    cityCode: "CLT",
    cityName: "Charlotte",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLU",
    airportName: "Columbus Municipal",
    cityCode: "CLU",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLV",
    airportName: "Caldas Novas",
    cityCode: "CLV",
    cityName: "Caldas Novas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CLW",
    airportName: "Executive",
    cityCode: "CLW",
    cityName: "Clearwater",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CLX",
    airportName: "Clorinda",
    cityCode: "CLX",
    cityName: "Clorinda",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CLY",
    airportName: "Ste Catherine",
    cityCode: "CLY",
    cityName: "Calvi",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CLZ",
    airportName: "Calabozo",
    cityCode: "CLZ",
    cityName: "Calabozo",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CMA",
    airportName: "Cunnamulla",
    cityCode: "CMA",
    cityName: "Cunnamulla",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CMB",
    airportName: "Bandaranayake",
    cityCode: "CMB",
    cityName: "Colombo",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "CMC",
    airportName: "Camocim",
    cityCode: "CMC",
    cityName: "Camocim",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CMD",
    airportName: "Cootamundra",
    cityCode: "CMD",
    cityName: "Cootamundra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CME",
    airportName: "Ciudad Del Carmen",
    cityCode: "CME",
    cityName: "Ciudad Del Carmen",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CMF",
    airportName: "Chambery",
    cityCode: "CMF",
    cityName: "Chambery",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CMG",
    airportName: "Intl. Arpt. Corumba",
    cityCode: "CMG",
    cityName: "Corumba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CMH",
    airportName: "Port Columbus Intl",
    cityCode: "CMH",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CMI",
    airportName: "University Of Illinois Willard",
    cityCode: "CMI",
    cityName: "Champaign",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CMJ",
    airportName: "Chi Mei",
    cityCode: "CMJ",
    cityName: "Chi Mei",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "CMK",
    airportName: "Club Makokola",
    cityCode: "CMK",
    cityName: "Club Makokola",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "CML",
    airportName: "Camooweal",
    cityCode: "CML",
    cityName: "Camooweal",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CMM",
    airportName: "Carmelita",
    cityCode: "CMM",
    cityName: "Carmelita",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "CMN",
    airportName: "Mohamed V",
    cityCode: "CMN",
    cityName: "Casablanca",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "CMO",
    airportName: "Obbia",
    cityCode: "CMO",
    cityName: "Obbia",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "CMP",
    airportName: "Campo Alegre",
    cityCode: "CMP",
    cityName: "Santana Do Araguaia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CMQ",
    airportName: "Clermont",
    cityCode: "CMQ",
    cityName: "Clermont",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CMR",
    airportName: "Colmar-houssen",
    cityCode: "CMR",
    cityName: "Colmar",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CMS",
    airportName: "Scusciuban",
    cityCode: "CMS",
    cityName: "Scusciuban",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "CMT",
    airportName: "Cameta",
    cityCode: "CMT",
    cityName: "Cameta",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CMU",
    airportName: "Chimbu",
    cityCode: "CMU",
    cityName: "Kundiawa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "CMV",
    airportName: "Coromandel",
    cityCode: "CMV",
    cityName: "Coromandel",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "CMW",
    airportName: "Ign Agramonte Intl",
    cityCode: "CMW",
    cityName: "Camaguey",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "CMX",
    airportName: "Houghton County",
    cityCode: "CMX",
    cityName: "Hancock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CMY",
    airportName: "Fort Mccoy",
    cityCode: "CMY",
    cityName: "Sparta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CMZ",
    airportName: "Caia",
    cityCode: "CMZ",
    cityName: "Caia",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "CNA",
    airportName: "Cananea",
    cityCode: "CNA",
    cityName: "Cananea",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CNB",
    airportName: "Coonamble",
    cityCode: "CNB",
    cityName: "Coonamble",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CNC",
    airportName: "Coconut Island",
    cityCode: "CNC",
    cityName: "Coconut Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CND",
    airportName: "Kogalniceanu",
    cityCode: "CND",
    cityName: "Constanta",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "CNE",
    airportName: "Canon City",
    cityCode: "CNE",
    cityName: "Canon City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CNF",
    airportName: "Tancredo Neves Intl.",
    cityCode: "BHZ",
    cityName: "Belo Horizonte",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CNG",
    airportName: "Parvaud",
    cityCode: "CNG",
    cityName: "Cognac",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CNH",
    airportName: "Municipal",
    cityCode: "CNH",
    cityName: "Claremont",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CNI",
    airportName: "Changhai",
    cityCode: "CNI",
    cityName: "Changhai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CNJ",
    airportName: "Cloncurry",
    cityCode: "CNJ",
    cityName: "Cloncurry",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CNK",
    airportName: "Blosser Municipal",
    cityCode: "CNK",
    cityName: "Concordia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CNL",
    airportName: "Sindal",
    cityCode: "CNL",
    cityName: "Sindal",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "CNM",
    airportName: "Carlsbad",
    cityCode: "CNM",
    cityName: "Carlsbad",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CNO",
    airportName: "Chino",
    cityCode: "CNO",
    cityName: "Chino",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CNP",
    airportName: "Neerlerit Inaat",
    cityCode: "CNP",
    cityName: "Neerlerit Inaat",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "CNQ",
    airportName: "Camba Punta",
    cityCode: "CNQ",
    cityName: "Corrientes",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CNR",
    airportName: "Chanaral",
    cityCode: "CNR",
    cityName: "Chanaral",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "CNS",
    airportName: "Cairns",
    cityCode: "CNS",
    cityName: "Cairns",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CNT",
    airportName: "Charata",
    cityCode: "CNT",
    cityName: "Charata",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CNU",
    airportName: "Martin Johnson",
    cityCode: "CNU",
    cityName: "Chanute",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CNV",
    airportName: "Canavieiras",
    cityCode: "CNV",
    cityName: "Canavieiras",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CNW",
    airportName: "TSTC Waco Arpt.",
    cityCode: "CNW",
    cityName: "Waco, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CNX",
    airportName: "Chiang Mai Intl.",
    cityCode: "CNX",
    cityName: "Chiang Mai",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "CNY",
    airportName: "Canyonlands Field",
    cityCode: "CNY",
    cityName: "Moab",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CNZ",
    airportName: "Cangamba",
    cityCode: "CNZ",
    cityName: "Cangamba",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "COA",
    airportName: "Columbia",
    cityCode: "COA",
    cityName: "Columbia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "COB",
    airportName: "Coolibah",
    cityCode: "COB",
    cityName: "Coolibah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "COC",
    airportName: "Concordia",
    cityCode: "COC",
    cityName: "Concordia",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "COD",
    airportName: "Yellowstone Regional",
    cityCode: "COD",
    cityName: "Cody/Yellowstone",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "COG",
    airportName: "Mandinga",
    cityCode: "COG",
    cityName: "Condoto",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "COH",
    airportName: "Cooch Behar",
    cityCode: "COH",
    cityName: "Cooch Behar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "COI",
    airportName: "Merritt Island",
    cityCode: "COI",
    cityName: "Cocoa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "COJ",
    airportName: "Coonabarabran",
    cityCode: "COJ",
    cityName: "Coonabarabran",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "COK",
    airportName: "Cochin Intl.",
    cityCode: "COK",
    cityName: "Kochi",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "COL",
    airportName: "Isle Of Coll",
    cityCode: "COL",
    cityName: "Isle Of Coll",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "COM",
    airportName: "Coleman",
    cityCode: "COM",
    cityName: "Coleman",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CON",
    airportName: "Concord",
    cityCode: "CON",
    cityName: "Concord",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "COO",
    airportName: "Cotonou",
    cityCode: "COO",
    cityName: "Cotonou",
    countryName: "Benin",
    countryCode: "BJ",
  },
  {
    airportCode: "COP",
    airportName: "Cooperstown - Westville Arpt.",
    cityCode: "COP",
    cityName: "Cooperstown, NY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "COQ",
    airportName: "Choibalsan",
    cityCode: "COQ",
    cityName: "Choibalsan",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "COR",
    airportName: "Pajas Blancas",
    cityCode: "COR",
    cityName: "Cordoba",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "COS",
    airportName: "Colorado Springs",
    cityCode: "COS",
    cityName: "Colorado Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "COT",
    airportName: "Cotulla",
    cityCode: "COT",
    cityName: "Cotulla",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "COU",
    airportName: "Columbia Regional",
    cityCode: "COU",
    cityName: "Columbia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "COV",
    airportName: "Covilha",
    cityCode: "COV",
    cityName: "Covilha",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "COW",
    airportName: "Coquimbo",
    cityCode: "COW",
    cityName: "Coquimbo",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "COX",
    airportName: "Congo Town",
    cityCode: "COX",
    cityName: "Congo Town",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "COY",
    airportName: "Coolawanyah",
    cityCode: "COY",
    cityName: "Coolawanyah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "COZ",
    airportName: "Constanza",
    cityCode: "COZ",
    cityName: "Constanza",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "CPA",
    airportName: "A. Tubman",
    cityCode: "CPA",
    cityName: "Cape Palmas",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "CPB",
    airportName: "Capurgana",
    cityCode: "CPB",
    cityName: "Capurgana",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CPC",
    airportName: "Chapelco",
    cityCode: "CPC",
    cityName: "San Martin DeLos Andes",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CPD",
    airportName: "Coober Pedy",
    cityCode: "CPD",
    cityName: "Coober Pedy",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CPE",
    airportName: "Campeche Intl.",
    cityCode: "CPE",
    cityName: "Campeche",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CPF",
    airportName: "Cepu",
    cityCode: "CPF",
    cityName: "Cepu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "CPG",
    airportName: "Carmen De Patagones",
    cityCode: "CPG",
    cityName: "Carmen De Patagones",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CPH",
    airportName: "Kastrup",
    cityCode: "CPH",
    cityName: "Copenhagen",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "CPI",
    airportName: "Cape Orford",
    cityCode: "CPI",
    cityName: "Cape Orford",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "CPL",
    airportName: "Chaparral",
    cityCode: "CPL",
    cityName: "Chaparral",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CPM",
    airportName: "Compton",
    cityCode: "CPM",
    cityName: "Compton, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CPN",
    airportName: "Cape Rodney",
    cityCode: "CPN",
    cityName: "Cape Rodney",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "CPO",
    airportName: "Chamonate",
    cityCode: "CPO",
    cityName: "Copiapo",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "CPQ",
    airportName: "Intl.",
    cityCode: "CPQ",
    cityName: "Campinas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CPR",
    airportName: "Natrona County Intl. Arpt.",
    cityCode: "CPR",
    cityName: "Casper, WY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CPS",
    airportName: "St. Louis Downtown Arpt.",
    cityCode: "CPS",
    cityName: "St Louis, Il",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CPT",
    airportName: "Cape Town Intl.",
    cityCode: "CPT",
    cityName: "Cape Town",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "CPU",
    airportName: "Cururupu",
    cityCode: "CPU",
    cityName: "Cururupu",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CPV",
    airportName: "Joao Suassuna",
    cityCode: "CPV",
    cityName: "Campina Grande",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CPX",
    airportName: "Culebra",
    cityCode: "CPX",
    cityName: "Culebra",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "CQA",
    airportName: "Canarana Arpt.",
    cityCode: "CQA",
    cityName: "Canarana",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CQD",
    airportName: "Shahre-kord",
    cityCode: "CQD",
    cityName: "Shahre-kord",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "CQF",
    airportName: "Calais",
    cityCode: "CQF",
    cityName: "Calais",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CQP",
    airportName: "Cape Flattery",
    cityCode: "CQP",
    cityName: "Cape Flattery",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CQT",
    airportName: "Caquetania",
    cityCode: "CQT",
    cityName: "Caquetania",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CRA",
    airportName: "Craiova",
    cityCode: "CRA",
    cityName: "Craiova",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "CRB",
    airportName: "Collarenebri",
    cityCode: "CRB",
    cityName: "Collarenebri",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CRC",
    airportName: "Cartago",
    cityCode: "CRC",
    cityName: "Cartago",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CRD",
    airportName: "Comodoro Rivadavia",
    cityCode: "CRD",
    cityName: "Comodoro Rivadavia",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CRE",
    airportName: "Grand Strand Arpt.",
    cityCode: "CRE",
    cityName: "Myrtle Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CRF",
    airportName: "Carnot",
    cityCode: "CRF",
    cityName: "Carnot",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "CRG",
    airportName: "Craig Municipal",
    cityCode: "CRG",
    cityName: "Jacksonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CRH",
    airportName: "Cherribah",
    cityCode: "CRH",
    cityName: "Cherribah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CRI",
    airportName: "Crooked Island",
    cityCode: "CRI",
    cityName: "Crooked Island",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "CRJ",
    airportName: "Coorabie",
    cityCode: "CRJ",
    cityName: "Coorabie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CRK",
    airportName: "Luzon Island Clark Field Airport",
    cityCode: "CRK",
    cityName: "Luzon",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CRL",
    airportName: "Charleroi Brussels South",
    cityCode: "BRU",
    cityName: "Brussels",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "CRM",
    airportName: "National",
    cityCode: "CRM",
    cityName: "Catarman",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CRN",
    airportName: "Cromarty",
    cityCode: "CRN",
    cityName: "Cromarty",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CRO",
    airportName: "Corcoran",
    cityCode: "CRO",
    cityName: "Corcoran",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CRP",
    airportName: "Corpus Christi Intl. Arpt.",
    cityCode: "CRP",
    cityName: "Corpus Christi, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CRQ",
    airportName: "Caravelas",
    cityCode: "CRQ",
    cityName: "Caravelas",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CRR",
    airportName: "Ceres",
    cityCode: "CRR",
    cityName: "Ceres",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CRS",
    airportName: "Corsicana",
    cityCode: "CRS",
    cityName: "Corsicana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CRT",
    airportName: "Municipal",
    cityCode: "CRT",
    cityName: "Crossett",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CRU",
    airportName: "Carriacou Is",
    cityCode: "CRU",
    cityName: "Carriacou Is",
    countryName: "Grenada",
    countryCode: "GD",
  },
  {
    airportCode: "CRV",
    airportName: "Crotone",
    cityCode: "CRV",
    cityName: "Crotone",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "CRW",
    airportName: "Yeager",
    cityCode: "CRW",
    cityName: "Charleston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CRX",
    airportName: "Roscoe Turner",
    cityCode: "CRX",
    cityName: "Corinth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CRY",
    airportName: "Carlton Hill",
    cityCode: "CRY",
    cityName: "Carlton Hill",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CRZ",
    airportName: "Turkmenabad",
    cityCode: "CRZ",
    cityName: "Turkmenabad",
    countryName: "Turkmenistan",
    countryCode: "TM",
  },
  {
    airportCode: "CSA",
    airportName: "Isle Of Colonsay",
    cityCode: "CSA",
    cityName: "Isle Of Colonsay",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CSB",
    airportName: "Caransebes",
    cityCode: "CSB",
    cityName: "Caransebes",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "CSC",
    airportName: "Canas",
    cityCode: "CSC",
    cityName: "Canas",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "CSD",
    airportName: "Cresswell Downs",
    cityCode: "CSD",
    cityName: "Cresswell Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CSF",
    airportName: "Creil",
    cityCode: "CSF",
    cityName: "Creil",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CSH",
    airportName: "Solovky",
    cityCode: "CSH",
    cityName: "Solovetsky",
    countryName: "Russia",
    countryCode: "RUS",
  },
  {
    airportCode: "CSJ",
    airportName: "Cape St Jacques",
    cityCode: "CSJ",
    cityName: "Cape St Jacques",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "CSK",
    airportName: "Cap Skirring",
    cityCode: "CSK",
    cityName: "Cap Skirring",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "CSM",
    airportName: "Sherman",
    cityCode: "CSM",
    cityName: "Clinton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CSN",
    airportName: "Carson City",
    cityCode: "CSN",
    cityName: "Carson City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CSP",
    airportName: "Coast Guard Heliport",
    cityCode: "CSP",
    cityName: "Cape Spencer",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CSQ",
    airportName: "Municipal",
    cityCode: "CSQ",
    cityName: "Creston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CSR",
    airportName: "Casuarito",
    cityCode: "CSR",
    cityName: "Casuarito",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CSS",
    airportName: "Cassilandia",
    cityCode: "CSS",
    cityName: "Cassilandia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CST",
    airportName: "Castaway",
    cityCode: "CST",
    cityName: "Castaway",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "CSU",
    airportName: "Santa Cruz Do Sul",
    cityCode: "CSU",
    cityName: "Santa Cruz Do Sul",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CSV",
    airportName: "Memorial",
    cityCode: "CSV",
    cityName: "Crossville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CSW",
    airportName: "Colorado do Oeste",
    cityCode: "CSW",
    cityName: "Colorado do Oeste",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CSX",
    airportName: "Changsha",
    cityCode: "CSX",
    cityName: "Changsha",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CSY",
    airportName: "Cheboksary",
    cityCode: "CSY",
    cityName: "Cheboksary",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "CSZ",
    airportName: "Brigadier Hector Ruiz",
    cityCode: "CSZ",
    cityName: "Coronel Suarez",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CTA",
    airportName: "Fontanarossa",
    cityCode: "CTA",
    cityName: "Catania",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "CTB",
    airportName: "Cut Bank",
    cityCode: "CTB",
    cityName: "Cut Bank",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CTC",
    airportName: "Catamarca",
    cityCode: "CTC",
    cityName: "Catamarca",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CTD",
    airportName: "Chitre",
    cityCode: "CTD",
    cityName: "Chitre",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "CTE",
    airportName: "Carti",
    cityCode: "CTE",
    cityName: "Carti",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "CTF",
    airportName: "Coatepeque Arpt.",
    cityCode: "CTF",
    cityName: "Coatepeque",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "CTG",
    airportName: "Rafael Nunez",
    cityCode: "CTG",
    cityName: "Cartagena",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CTH",
    airportName: "Chestercounty Carlson",
    cityCode: "CTH",
    cityName: "Coatesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CTI",
    airportName: "Cuito Cuanavale",
    cityCode: "CTI",
    cityName: "Cuito Cuanavale",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "CTK",
    airportName: "Canton",
    cityCode: "CTK",
    cityName: "Canton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CTL",
    airportName: "Charleville",
    cityCode: "CTL",
    cityName: "Charleville",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CTM",
    airportName: "Chetumal",
    cityCode: "CTM",
    cityName: "Chetumal",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CTN",
    airportName: "Cooktown",
    cityCode: "CTN",
    cityName: "Cooktown",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CTO",
    airportName: "Peconic River",
    cityCode: "CTO",
    cityName: "Calverton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CTP",
    airportName: "Carutapera",
    cityCode: "CTP",
    cityName: "Carutapera",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CTQ",
    airportName: "Do Palmar",
    cityCode: "CTQ",
    cityName: "Santa Vitoria",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CTR",
    airportName: "Cattle Creek",
    cityCode: "CTR",
    cityName: "Cattle Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CTS",
    airportName: "New Chitose Arpt.",
    cityCode: "SPK",
    cityName: "Sapporo",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "CTT",
    airportName: "Le Castellet",
    cityCode: "CTT",
    cityName: "Le Castellet",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CTU",
    airportName: "Shuangliu",
    cityCode: "CTU",
    cityName: "Chengdu",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CTW",
    airportName: "Cottonwood",
    cityCode: "CTW",
    cityName: "Cottonwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CTX",
    airportName: "Cortland",
    cityCode: "CTX",
    cityName: "Cortland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CTY",
    airportName: "Cross City",
    cityCode: "CTY",
    cityName: "Cross City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CTZ",
    airportName: "Sampson County",
    cityCode: "CTZ",
    cityName: "Clinton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CUA",
    airportName: "Ciudad Constitucion",
    cityCode: "CUA",
    cityName: "Ciudad Constitucion",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CUB",
    airportName: "Owens Field",
    cityCode: "CUB",
    cityName: "Columbia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CUC",
    airportName: "Camilo Dazo",
    cityCode: "CUC",
    cityName: "Cucuta",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CUD",
    airportName: "Caloundra",
    cityCode: "CUD",
    cityName: "Caloundra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CUE",
    airportName: "Cuenca",
    cityCode: "CUE",
    cityName: "Cuenca",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "CUF",
    airportName: "Levaldigi",
    cityCode: "CUF",
    cityName: "Cuneo",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "CUG",
    airportName: "Cudal",
    cityCode: "CUG",
    cityName: "Cudal",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CUH",
    airportName: "Municipal",
    cityCode: "CUH",
    cityName: "Cushing",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CUI",
    airportName: "Currillo",
    cityCode: "CUI",
    cityName: "Currillo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CUJ",
    airportName: "Culion",
    cityCode: "CUJ",
    cityName: "Culion",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CUK",
    airportName: "Caye Caulker",
    cityCode: "CUK",
    cityName: "Caye Caulker",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "CUL",
    airportName: "Fedl De Bachigualato",
    cityCode: "CUL",
    cityName: "Culiacan",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CUM",
    airportName: "Cumana",
    cityCode: "CUM",
    cityName: "Cumana",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CUN",
    airportName: "CancÃºn Intl.",
    cityCode: "CUN",
    cityName: "Cancun",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CUO",
    airportName: "Caruru",
    cityCode: "CUO",
    cityName: "Caruru",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CUP",
    airportName: "Carupano",
    cityCode: "CUP",
    cityName: "Carupano",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CUQ",
    airportName: "Coen",
    cityCode: "CUQ",
    cityName: "Coen",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CUR",
    airportName: "Hato Intl. Arpt.",
    cityCode: "CUR",
    cityName: "Curacao",
    countryName: "Netherlands Antilles",
    countryCode: "AN",
  },
  {
    airportCode: "CUS",
    airportName: "Municipal",
    cityCode: "CUS",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CUT",
    airportName: "Cutral",
    cityCode: "CUT",
    cityName: "Cutral",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CUU",
    airportName: "Gen Fierro Villalobos",
    cityCode: "CUU",
    cityName: "Chihuahua",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CUV",
    airportName: "Casigua",
    cityCode: "CUV",
    cityName: "Casigua",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CUW",
    airportName: "Cube Cove",
    cityCode: "CUW",
    cityName: "Cube Cove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CUY",
    airportName: "Cue",
    cityCode: "CUY",
    cityName: "Cue",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CUZ",
    airportName: "Velazco Astete",
    cityCode: "CUZ",
    cityName: "Cuzco",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "CVB",
    airportName: "Chungribu",
    cityCode: "CVB",
    cityName: "Chungribu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "CVC",
    airportName: "Cleve",
    cityCode: "CVC",
    cityName: "Cleve",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CVE",
    airportName: "Covenas",
    cityCode: "CVE",
    cityName: "Covenas",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CVF",
    airportName: "Courchevel",
    cityCode: "CVF",
    cityName: "Courchevel",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "CVG",
    airportName: "Cincinnati/northern Kentucky",
    cityCode: "CVG",
    cityName: "Cincinnati/northern Kentucky",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CVH",
    airportName: "Caviahue",
    cityCode: "CVH",
    cityName: "Caviahue",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CVI",
    airportName: "Caleta Olivia",
    cityCode: "CVI",
    cityName: "Caleta Olivia",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "CVJ",
    airportName: "General Mariano Matamoros Arpt.",
    cityCode: "CVJ",
    cityName: "Cuernavaca",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CVL",
    airportName: "Cape Vogel",
    cityCode: "CVL",
    cityName: "Cape Vogel",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "CVM",
    airportName: "Ciudad Victoria",
    cityCode: "CVM",
    cityName: "Ciudad Victoria",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CVN",
    airportName: "Municipal",
    cityCode: "CVN",
    cityName: "Clovis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CVO",
    airportName: "Albany",
    cityCode: "CVO",
    cityName: "Albany",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CVQ",
    airportName: "Carnarvon",
    cityCode: "CVQ",
    cityName: "Carnarvon",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CVR",
    airportName: "Hughes",
    cityCode: "CVR",
    cityName: "Culver City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CVS",
    airportName: "Cannon AFB",
    cityCode: "CVS",
    cityName: "Clovis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CVT",
    airportName: "West Midlands Intl.",
    cityCode: "CVT",
    cityName: "Coventry",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CVU",
    airportName: "Corvo Island",
    cityCode: "CVU",
    cityName: "Corvo Island",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "CWA",
    airportName: "Central Wisconsin",
    cityCode: "CWA",
    cityName: "Wausau",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CWB",
    airportName: "Afonso Pena Intl. Arpt.",
    cityCode: "CWB",
    cityName: "Curitiba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CWC",
    airportName: "Chernovtsy",
    cityCode: "CWC",
    cityName: "Chernovtsy",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "CWG",
    airportName: "Callaway Gardens",
    cityCode: "CWG",
    cityName: "Callaway Gardens",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CWI",
    airportName: "Clinton",
    cityCode: "CWI",
    cityName: "Clinton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CWL",
    airportName: "Cardiff",
    cityCode: "CWL",
    cityName: "Cardiff",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "CWO",
    airportName: "Ft Wolter AAF",
    cityCode: "CWO",
    cityName: "Mineral Wells",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CWP",
    airportName: "Campbellpore",
    cityCode: "CWP",
    cityName: "Campbellpore",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "CWR",
    airportName: "Cowarie",
    cityCode: "CWR",
    cityName: "Cowarie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CWT",
    airportName: "Cowra",
    cityCode: "CWT",
    cityName: "Cowra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CWW",
    airportName: "Corowa",
    cityCode: "CWW",
    cityName: "Corowa",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CXA",
    airportName: "Caicara Del Orinoco",
    cityCode: "CXA",
    cityName: "Caicara Del Orinoco",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CXC",
    airportName: "Chitina Arpt",
    cityCode: "CXC",
    cityName: "Chitina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CXF",
    airportName: "Coldfoot",
    cityCode: "CXF",
    cityName: "Coldfoot",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CXH",
    airportName: "Coal Harbour",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "CXI",
    airportName: "Cassidy Intl. Arpt.",
    cityCode: "CXI",
    cityName: "Christmas Island",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "CXJ",
    airportName: "Campo Dos Bugres",
    cityCode: "CXJ",
    cityName: "Caxias Do Sul",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CXL",
    airportName: "Intl.",
    cityCode: "CXL",
    cityName: "Calexico",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CXN",
    airportName: "Candala",
    cityCode: "CXN",
    cityName: "Candala",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "CXO",
    airportName: "Montgomery Co",
    cityCode: "CXO",
    cityName: "Conroe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CXP",
    airportName: "Tunggul Wulung",
    cityCode: "CXP",
    cityName: "Cilacap",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "CXQ",
    airportName: "Christmas Creek",
    cityCode: "CXQ",
    cityName: "Christmas Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CXR",
    airportName: "Nha Trang",
    cityCode: "CXR",
    cityName: "Nha Trang",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "CXT",
    airportName: "Charters Towers",
    cityCode: "CXT",
    cityName: "Charters Towers",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CXY",
    airportName: "Cat Cays",
    cityCode: "CXY",
    cityName: "Cat Cays",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "CYA",
    airportName: "Les Cayes",
    cityCode: "CYA",
    cityName: "Les Cayes",
    countryName: "Haiti",
    countryCode: "HT",
  },
  {
    airportCode: "CYB",
    airportName: "Gerrard-Smith",
    cityCode: "CYB",
    cityName: "Cayman Brac Is",
    countryName: "Cayman Islands",
    countryCode: "KY",
  },
  {
    airportCode: "CYC",
    airportName: "Caye Chapel",
    cityCode: "CYC",
    cityName: "Caye Chapel",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "CYE",
    airportName: "Crystal Lake",
    cityCode: "CYE",
    cityName: "Crystal Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CYF",
    airportName: "Chefornak SPB",
    cityCode: "CYF",
    cityName: "Chefornak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CYG",
    airportName: "Corryong",
    cityCode: "CYG",
    cityName: "Corryong",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CYI",
    airportName: "Chiayi",
    cityCode: "CYI",
    cityName: "Chiayi",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "CYL",
    airportName: "Coyoles",
    cityCode: "CYL",
    cityName: "Coyoles",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "CYM",
    airportName: "Chatham SPB",
    cityCode: "CYM",
    cityName: "Chatham",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CYO",
    airportName: "Cayo Largo Del Sur",
    cityCode: "CYO",
    cityName: "Cayo Largo Del Sur",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "CYP",
    airportName: "Calbayog",
    cityCode: "CYP",
    cityName: "Calbayog",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CYR",
    airportName: "Laguna De Los Patos",
    cityCode: "CYR",
    cityName: "Colonia",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "CYS",
    airportName: "Cheyenne",
    cityCode: "CYS",
    cityName: "Cheyenne",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CYT",
    airportName: "Intermediate",
    cityCode: "CYT",
    cityName: "Yakataga",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CYU",
    airportName: "Cuyo",
    cityCode: "CYU",
    cityName: "Cuyo",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CYX",
    airportName: "Cherskiy",
    cityCode: "CYX",
    cityName: "Cherskiy",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "CYZ",
    airportName: "Cauayan",
    cityCode: "CYZ",
    cityName: "Cauayan",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "CZA",
    airportName: "Chichen Itza",
    cityCode: "CZA",
    cityName: "Chichen Itza",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CZB",
    airportName: "Carlos Ruhl",
    cityCode: "CZB",
    cityName: "Cruz Alta",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CZC",
    airportName: "Copper Centre",
    cityCode: "CZC",
    cityName: "Copper Centre",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CZE",
    airportName: "Coro",
    cityCode: "CZE",
    cityName: "Coro",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "CZF",
    airportName: "Cape Romanzof",
    cityCode: "CZF",
    cityName: "Cape Romanzof",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CZH",
    airportName: "Corozal",
    cityCode: "CZH",
    cityName: "Corozal",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "CZJ",
    airportName: "Corazon De Jesus",
    cityCode: "CZJ",
    cityName: "Corazon De Jesus",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "CZK",
    airportName: "Cascade Locks/Stevens",
    cityCode: "CZK",
    cityName: "Cascade Locks",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CZL",
    airportName: "Ain El Bey",
    cityCode: "CZL",
    cityName: "Constantine",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "CZM",
    airportName: "Cozumel",
    cityCode: "CZM",
    cityName: "Cozumel",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "CZN",
    airportName: "Chisana Field",
    cityCode: "CZN",
    cityName: "Chisana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CZO",
    airportName: "Chistochina",
    cityCode: "CZO",
    cityName: "Chistochina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CZP",
    airportName: "Cape Pole",
    cityCode: "CZP",
    cityName: "Cape Pole",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CZS",
    airportName: "Intl. Airpt. Cruzeiro Do Sul",
    cityCode: "CZS",
    cityName: "Cruzeiro Do Sul",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "CZT",
    airportName: "Carrizo Springs",
    cityCode: "CZT",
    cityName: "Carrizo Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "CZU",
    airportName: "Corozal",
    cityCode: "CZU",
    cityName: "Corozal",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "CZW",
    airportName: "Czestochowa",
    cityCode: "CZW",
    cityName: "Czestochowa",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "CZX",
    airportName: "Changzhou",
    cityCode: "CZX",
    cityName: "Changzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "CZY",
    airportName: "Cluny",
    cityCode: "CZY",
    cityName: "Cluny",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "CZZ",
    airportName: "Campo",
    cityCode: "CZZ",
    cityName: "Campo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DAA",
    airportName: "Davison AAF",
    cityCode: "DAA",
    cityName: "Fort Belvoir",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DAB",
    airportName: "Regional",
    cityCode: "DAB",
    cityName: "Daytona Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DAC",
    airportName: "Hazrat Shahjalal International Airport",
    cityCode: "DAC",
    cityName: "Dhaka",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "DAD",
    airportName: "Da Nang",
    cityCode: "DAD",
    cityName: "Da Nang",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "DAE",
    airportName: "Daparizo",
    cityCode: "DAE",
    cityName: "Daparizo",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DAF",
    airportName: "Daup",
    cityCode: "DAF",
    cityName: "Daup",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DAG",
    airportName: "Barstow-Daggett",
    cityCode: "DAG",
    cityName: "Daggett",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DAH",
    airportName: "Dathina",
    cityCode: "DAH",
    cityName: "Dathina",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "DAI",
    airportName: "Darjeeling",
    cityCode: "DAI",
    cityName: "Darjeeling",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DAJ",
    airportName: "Dauan Island",
    cityCode: "DAJ",
    cityName: "Dauan Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DAK",
    airportName: "Dakhla",
    cityCode: "DAK",
    cityName: "Dakhla Oasis",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "DAL",
    airportName: "Love Field",
    cityCode: "DFW",
    cityName: "Dallas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DAM",
    airportName: "Intl.",
    cityCode: "DAM",
    cityName: "Damascus",
    countryName: "Syria",
    countryCode: "SY",
  },
  {
    airportCode: "DAN",
    airportName: "Municipal",
    cityCode: "DAN",
    cityName: "Danville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DAO",
    airportName: "Dabo",
    cityCode: "DAO",
    cityName: "Dabo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DAP",
    airportName: "Darchula",
    cityCode: "DAP",
    cityName: "Darchula",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "DAR",
    airportName: "Dar Es Salaam Intl.A pt.",
    cityCode: "DAR",
    cityName: "Dar Es Salaam",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "DAS",
    airportName: "Great Bear Lake",
    cityCode: "DAS",
    cityName: "Great Bear Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "DAU",
    airportName: "Daru",
    cityCode: "DAU",
    cityName: "Daru",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DAV",
    airportName: "Enrique Malek",
    cityCode: "DAV",
    cityName: "David",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "DAX",
    airportName: "Daxian",
    cityCode: "DAX",
    cityName: "Daxian",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DAY",
    airportName: "James Cox Dayton Intl",
    cityCode: "DAY",
    cityName: "Dayton, Oh",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DAZ",
    airportName: "Darwaz",
    cityCode: "DAZ",
    cityName: "Darwaz",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "DBA",
    airportName: "Dalbandin",
    cityCode: "DBA",
    cityName: "Dalbandin",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "DBB",
    airportName: "Alalamain Intl.",
    cityCode: "DBB",
    cityName: "Dabaa City",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "DBD",
    airportName: "Dhanbad",
    cityCode: "DBD",
    cityName: "Dhanbad",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DBM",
    airportName: "Debra Marcos",
    cityCode: "DBM",
    cityName: "Debra Marcos",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "DBN",
    airportName: "Municipal",
    cityCode: "DBN",
    cityName: "Dublin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DBO",
    airportName: "Dubbo",
    cityCode: "DBO",
    cityName: "Dubbo",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DBP",
    airportName: "Debepare",
    cityCode: "DBP",
    cityName: "Debepare",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DBQ",
    airportName: "Municipal",
    cityCode: "DBQ",
    cityName: "Dubuque",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DBS",
    airportName: "Dubois",
    cityCode: "DBS",
    cityName: "Dubois",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DBT",
    airportName: "Debra Tabor",
    cityCode: "DBT",
    cityName: "Debra Tabor",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "DBU",
    airportName: "Dambulu Oya Tank",
    cityCode: "DBU",
    cityName: "Dambula",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "DBV",
    airportName: "Dubrovnik",
    cityCode: "DBV",
    cityName: "Dubrovnik",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "DBY",
    airportName: "Dalby",
    cityCode: "DBY",
    cityName: "Dalby",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DCA",
    airportName: "Ronald Reagan Washington National Arpt.",
    cityCode: "WAS",
    cityName: "Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DCF",
    airportName: "Cane Field",
    cityCode: "DCF",
    cityName: "Roseau",
    countryName: "Dominica",
    countryCode: "DM",
  },
  {
    airportCode: "DCI",
    airportName: "Rafsu Decimomannu",
    cityCode: "DCI",
    cityName: "Decimomannu",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "DCK",
    airportName: "Dahl Creek Arpt",
    cityCode: "DCK",
    cityName: "Dahl Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DCM",
    airportName: "Mazamet",
    cityCode: "DCM",
    cityName: "Castres",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DCR",
    airportName: "Decatur Hi-Way",
    cityCode: "DCR",
    cityName: "Decatur",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DCT",
    airportName: "Duncan Town",
    cityCode: "DCT",
    cityName: "Duncan Town",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "DDC",
    airportName: "Dodge City Municipal",
    cityCode: "DDC",
    cityName: "Dodge City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DDG",
    airportName: "Dandong",
    cityCode: "DDG",
    cityName: "Dandong",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DDI",
    airportName: "Daydream Is",
    cityCode: "DDI",
    cityName: "Daydream Is",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DDM",
    airportName: "Dodoima",
    cityCode: "DDM",
    cityName: "Dodoima",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DDN",
    airportName: "Delta Downs",
    cityCode: "DDN",
    cityName: "Delta Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DDP",
    airportName: "Dorado Beach",
    cityCode: "DDP",
    cityName: "Dorado",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "DDU",
    airportName: "Dadu",
    cityCode: "DDU",
    cityName: "Dadu",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "DEA",
    airportName: "Dera Ghazi Khan",
    cityCode: "DEA",
    cityName: "Dera Ghazi Khan",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "DEB",
    airportName: "Debrecen",
    cityCode: "DEB",
    cityName: "Debrecen",
    countryName: "Hungary",
    countryCode: "HU",
  },
  {
    airportCode: "DEC",
    airportName: "Decatur Arpt",
    cityCode: "DEC",
    cityName: "Decatur",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DED",
    airportName: "Dehra Dun",
    cityCode: "DED",
    cityName: "Dehra Dun",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DEF",
    airportName: "Dezful",
    cityCode: "DEF",
    cityName: "Dezful",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "DEH",
    airportName: "Municipal",
    cityCode: "DEH",
    cityName: "Decorah",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DEI",
    airportName: "Denis Island",
    cityCode: "DEI",
    cityName: "Denis Island",
    countryName: "Seychelles",
    countryCode: "SC",
  },
  {
    airportCode: "DEL",
    airportName: "Indira Gandhi Intl",
    cityCode: "DEL",
    cityName: "New Delhi",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DEM",
    airportName: "Dembidollo",
    cityCode: "DEM",
    cityName: "Dembidollo",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "DEN",
    airportName: "Denver Intl.",
    cityCode: "DEN",
    cityName: "Denver",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DEO",
    airportName: "Hyatt Regency H/P",
    cityCode: "DEO",
    cityName: "Dearborn",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DEP",
    airportName: "Deparizo",
    cityCode: "DEP",
    cityName: "Deparizo",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DER",
    airportName: "Derim",
    cityCode: "DER",
    cityName: "Derim",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DES",
    airportName: "Desroches",
    cityCode: "DES",
    cityName: "Desroches",
    countryName: "Seychelles",
    countryCode: "SC",
  },
  {
    airportCode: "DET",
    airportName: "Detroit City",
    cityCode: "DTT",
    cityName: "Detroit",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DEZ",
    airportName: "Al Jafrah",
    cityCode: "DEZ",
    cityName: "Deirezzor",
    countryName: "Syria",
    countryCode: "SY",
  },
  {
    airportCode: "DFI",
    airportName: "Memorial",
    cityCode: "DFI",
    cityName: "Defiance",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DFP",
    airportName: "Drumduff",
    cityCode: "DFP",
    cityName: "Drumduff",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DFW",
    airportName: "Dallas/Fort Worth Intl.",
    cityCode: "DFW",
    cityName: "Dallas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DGA",
    airportName: "Dangriga",
    cityCode: "DGA",
    cityName: "Dangriga",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "DGB",
    airportName: "Danger Bay",
    cityCode: "DGB",
    cityName: "Danger Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DGC",
    airportName: "Degahbur",
    cityCode: "DGC",
    cityName: "Degahbur",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "DGD",
    airportName: "Dalgaranga",
    cityCode: "DGD",
    cityName: "Dalgaranga",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DGE",
    airportName: "Mudgee",
    cityCode: "DGE",
    cityName: "Mudgee",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DGF",
    airportName: "Bisbee-douglas Intl.",
    cityCode: "DGF",
    cityName: "Douglas",
    countryName: "Unites States",
    countryCode: "US",
  },
  {
    airportCode: "DGK",
    airportName: "Dugong",
    cityCode: "DGK",
    cityName: "Dugong",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "DGL",
    airportName: "Douglas Municipal",
    cityCode: "DGL",
    cityName: "Douglas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DGM",
    airportName: "Dongguan",
    cityCode: "DGM",
    cityName: "Dongguan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DGN",
    airportName: "NAF",
    cityCode: "DGN",
    cityName: "Dahlgren",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DGO",
    airportName: "Guadalupe Victoria",
    cityCode: "DGO",
    cityName: "Durango",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "DGP",
    airportName: "Daugavpils",
    cityCode: "DGP",
    cityName: "Daugavpils",
    countryName: "Latvia",
    countryCode: "LV",
  },
  {
    airportCode: "DGR",
    airportName: "Dargaville",
    cityCode: "DGR",
    cityName: "Dargaville",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "DGT",
    airportName: "Dumaguete",
    cityCode: "DGT",
    cityName: "Dumaguete",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "DGU",
    airportName: "Dedougou",
    cityCode: "DGU",
    cityName: "Dedougou",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "DGW",
    airportName: "Converse County",
    cityCode: "DGW",
    cityName: "Douglas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DHA",
    airportName: "Dhahran",
    cityCode: "DHA",
    cityName: "Dhahran",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "DHD",
    airportName: "Durham Downs",
    cityCode: "DHD",
    cityName: "Durham Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DHF",
    airportName: "Al Dhafra Military Apt",
    cityCode: "DHF",
    cityName: "Abu Dhabi",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "DHI",
    airportName: "Dhangarhi",
    cityCode: "DHI",
    cityName: "Dhangarhi",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "DHL",
    airportName: "Dhala",
    cityCode: "DHL",
    cityName: "Dhala",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "DHM",
    airportName: "Gaggal Arpt.",
    cityCode: "DHM",
    cityName: "Dharamsala",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DHN",
    airportName: "Dothan Arpt",
    cityCode: "DHN",
    cityName: "Dothan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DHR",
    airportName: "De Kooy/Den Helder Arpt.",
    cityCode: "DHR",
    cityName: "Den Helder",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "DHT",
    airportName: "Dalhart",
    cityCode: "DHT",
    cityName: "Dalhart",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DIB",
    airportName: "Dibrugarh",
    cityCode: "DIB",
    cityName: "Dibrugarh",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DIC",
    airportName: "Dili City Heliport",
    cityCode: "DIC",
    cityName: "Dili",
    countryName: "East Timor-Indonesia",
    countryCode: "TP",
  },
  {
    airportCode: "DIE",
    airportName: "Antsiranana/Arrachart",
    cityCode: "DIE",
    cityName: "Antsiranana",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "DIG",
    airportName: "Diqing",
    cityCode: "DIG",
    cityName: "Diqing",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DIJ",
    airportName: "Dijon",
    cityCode: "DIJ",
    cityName: "Dijon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DIK",
    airportName: "Dickinson",
    cityCode: "DIK",
    cityName: "Dickinson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DIL",
    airportName: "Presidente Nicolau Lobato Intl. Arpt.",
    cityCode: "DIL",
    cityName: "Dili",
    countryName: "East Timor-Indonesia",
    countryCode: "TP",
  },
  {
    airportCode: "DIM",
    airportName: "Dimbokro",
    cityCode: "DIM",
    cityName: "Dimbokro",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "DIN",
    airportName: "Dien Bien",
    cityCode: "DIN",
    cityName: "Dien Bien Phu",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "DIO",
    airportName: "Diomede Island",
    cityCode: "DIO",
    cityName: "Diomede Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DIP",
    airportName: "Diapaga",
    cityCode: "DIP",
    cityName: "Diapaga",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "DIQ",
    airportName: "Divinopolis",
    cityCode: "DIQ",
    cityName: "Divinopolis",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "DIR",
    airportName: "Aba Tenna D Yilma",
    cityCode: "DIR",
    cityName: "Dire Dawa",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "DIS",
    airportName: "Loubomo",
    cityCode: "DIS",
    cityName: "Loubomo",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "DIU",
    airportName: "Diu",
    cityCode: "DIU",
    cityName: "Diu",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DIV",
    airportName: "Divo",
    cityCode: "DIV",
    cityName: "Divo",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "DIW",
    airportName: "Mawella Lagoon",
    cityCode: "DIW",
    cityName: "Dickwella",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "DIY",
    airportName: "Diyarbakir",
    cityCode: "DIY",
    cityName: "Diyarbakir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "DJA",
    airportName: "Djougou",
    cityCode: "DJA",
    cityName: "Djougou",
    countryName: "Benin",
    countryCode: "BJ",
  },
  {
    airportCode: "DJB",
    airportName: "Sultan Taha Syarifudn",
    cityCode: "DJB",
    cityName: "Jambi",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "DJE",
    airportName: "Djerba-Zarzis",
    cityCode: "DJE",
    cityName: "Djerba",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "DJG",
    airportName: "Inedbirenne",
    cityCode: "DJG",
    cityName: "Djanet",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "DJJ",
    airportName: "Sentani",
    cityCode: "DJJ",
    cityName: "Jayapura",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "DJM",
    airportName: "Djambala",
    cityCode: "DJM",
    cityName: "Djambala",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "DJN",
    airportName: "Delta Junction",
    cityCode: "DJN",
    cityName: "Delta Junction",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DJO",
    airportName: "Daloa",
    cityCode: "DJO",
    cityName: "Daloa",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "DJU",
    airportName: "Djupivogur",
    cityCode: "DJU",
    cityName: "Djupivogur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "DKI",
    airportName: "Dunk Island",
    cityCode: "DKI",
    cityName: "Dunk Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DKK",
    airportName: "Dunkirk",
    cityCode: "DKK",
    cityName: "Dunkirk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DKR",
    airportName: "Dakar-Yoff-LÃ©opold SÃ©dar Senghor Intl.",
    cityCode: "DKR",
    cityName: "Dakar",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "DKS",
    airportName: "Dikson",
    cityCode: "DKS",
    cityName: "Dikson",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "DKV",
    airportName: "Docker River",
    cityCode: "DKV",
    cityName: "Docker River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DLA",
    airportName: "Douala",
    cityCode: "DLA",
    cityName: "Douala",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "DLB",
    airportName: "Dalbertis",
    cityCode: "DLB",
    cityName: "Dalbertis",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DLC",
    airportName: "Dalian",
    cityCode: "DLC",
    cityName: "Dalian",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DLD",
    airportName: "Dagali Arpt.",
    cityCode: "DLD",
    cityName: "Geilo",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "DLE",
    airportName: "Tavaux",
    cityCode: "DLE",
    cityName: "Dole",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DLF",
    airportName: "Laughlin Afb",
    cityCode: "DLF",
    cityName: "Del Rio",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DLG",
    airportName: "Municipal",
    cityCode: "DLG",
    cityName: "Dillingham",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DLH",
    airportName: "Duluth Intl.",
    cityCode: "DLH",
    cityName: "Duluth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DLI",
    airportName: "Lienkhang",
    cityCode: "DLI",
    cityName: "Dalat",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "DLK",
    airportName: "Dulkaninna",
    cityCode: "DLK",
    cityName: "Dulkaninna",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DLL",
    airportName: "Dillon",
    cityCode: "DLL",
    cityName: "Dillon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DLM",
    airportName: "Dalaman",
    cityCode: "DLM",
    cityName: "Dalaman",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "DLN",
    airportName: "Dillon",
    cityCode: "DLN",
    cityName: "Dillon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DLO",
    airportName: "Dolomi",
    cityCode: "DLO",
    cityName: "Dolomi",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DLP",
    airportName: "Disneyland Paris",
    cityCode: "DLP",
    cityName: "Disneyland Paris",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DLS",
    airportName: "Columbia Gorge Regional/The Dalles Municipal Arpt.",
    cityCode: "DLS",
    cityName: "The Dalles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DLU",
    airportName: "Dali",
    cityCode: "DLU",
    cityName: "Dali City",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DLV",
    airportName: "Delissaville",
    cityCode: "DLV",
    cityName: "Delissaville",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DLY",
    airportName: "Dillons Bay",
    cityCode: "DLY",
    cityName: "Dillons Bay",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "DLZ",
    airportName: "Dalanzadgad",
    cityCode: "DLZ",
    cityName: "Dalanzadgad",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "DMB",
    airportName: "Zhambyl",
    cityCode: "DMB",
    cityName: "Zhambyl",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "DMD",
    airportName: "Doomadgee",
    cityCode: "DMD",
    cityName: "Doomadgee",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DME",
    airportName: "Domodedovo",
    cityCode: "MOW",
    cityName: "Moscow",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "DMK",
    airportName: "Don Muang",
    cityCode: "BKK",
    cityName: "Bangkok",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "DMM",
    airportName: "King Fahd Intl. Arpt.",
    cityCode: "DMM",
    cityName: "Dammam",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "DMN",
    airportName: "Deming",
    cityCode: "DMN",
    cityName: "Deming",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DMO",
    airportName: "Sedalia",
    cityCode: "DMO",
    cityName: "Sedalia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DMR",
    airportName: "Dhamar",
    cityCode: "DMR",
    cityName: "Dhamar",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "DMT",
    airportName: "Diamantino",
    cityCode: "DMT",
    cityName: "Diamantino",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "DMU",
    airportName: "Dimapur",
    cityCode: "DMU",
    cityName: "Dimapur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "DNA",
    airportName: "Kadena AB",
    cityCode: "DNA",
    cityName: "Okinawa",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "DNB",
    airportName: "Dunbar",
    cityCode: "DNB",
    cityName: "Dunbar",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DNC",
    airportName: "Danane",
    cityCode: "DNC",
    cityName: "Danane",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "DND",
    airportName: "Dundee",
    cityCode: "DND",
    cityName: "Dundee",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "DNE",
    airportName: "Dallas North Arpt.",
    cityCode: "DFW",
    cityName: "Dallas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DNF",
    airportName: "Martuba",
    cityCode: "DNF",
    cityName: "Derna",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "DNG",
    airportName: "Doongan",
    cityCode: "DNG",
    cityName: "Doongan",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DNH",
    airportName: "Dunhuang",
    cityCode: "DNH",
    cityName: "Dunhuang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DNI",
    airportName: "Wad Medani",
    cityCode: "DNI",
    cityName: "Wad Medani",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "DNK",
    airportName: "Dnepropetrovsk",
    cityCode: "DNK",
    cityName: "Dnepropetrovsk",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "DNL",
    airportName: "Daniel Field",
    cityCode: "DNL",
    cityName: "Augusta, GA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DNM",
    airportName: "Denham",
    cityCode: "DNM",
    cityName: "Denham",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DNN",
    airportName: "Municipal",
    cityCode: "DNN",
    cityName: "Dalton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DNO",
    airportName: "Dianopolis",
    cityCode: "DNO",
    cityName: "Dianopolis",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "DNP",
    airportName: "Dang",
    cityCode: "DNP",
    cityName: "Dang",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "DNQ",
    airportName: "Deniliquin",
    cityCode: "DNQ",
    cityName: "Deniliquin",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DNR",
    airportName: "Pleurtuit",
    cityCode: "DNR",
    cityName: "Dinard",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DNS",
    airportName: "Municipal",
    cityCode: "DNS",
    cityName: "Denison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DNT",
    airportName: "Downtown Heliport",
    cityCode: "DNT",
    cityName: "Santa Ana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DNU",
    airportName: "Dinangat",
    cityCode: "DNU",
    cityName: "Dinangat",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DNV",
    airportName: "Vermilion County",
    cityCode: "DNV",
    cityName: "Danville, IL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DNX",
    airportName: "Galegu",
    cityCode: "DNX",
    cityName: "Dinder",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "DNZ",
    airportName: "Cardak",
    cityCode: "DNZ",
    cityName: "Denizli",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "DOA",
    airportName: "Doany",
    cityCode: "DOA",
    cityName: "Doany",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "DOC",
    airportName: "Dornoch",
    cityCode: "DOC",
    cityName: "Dornoch",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "DOD",
    airportName: "Dodoma",
    cityCode: "DOD",
    cityName: "Dodoma",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "DOE",
    airportName: "Djoemoe",
    cityCode: "DOE",
    cityName: "Djoemoe",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "DOF",
    airportName: "Dora Bay",
    cityCode: "DOF",
    cityName: "Dora Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DOG",
    airportName: "Dongola",
    cityCode: "DOG",
    cityName: "Dongola",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "DOH",
    airportName: "Doha Hamad",
    cityCode: "DOH",
    cityName: "Doha",
    countryName: "Qatar",
    countryCode: "QA",
  },
  {
    airportCode: "DOI",
    airportName: "Doini",
    cityCode: "DOI",
    cityName: "Doini",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DOK",
    airportName: "Donetsk Intl. Arpt.",
    cityCode: "DOK",
    cityName: "Donetsk",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "DOL",
    airportName: "St Gatien",
    cityCode: "DOL",
    cityName: "Deauville",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DOM",
    airportName: "Melville Hall",
    cityCode: "DOM",
    cityName: "Dominica",
    countryName: "Dominica",
    countryCode: "DM",
  },
  {
    airportCode: "DON",
    airportName: "Dos Lagunas",
    cityCode: "DON",
    cityName: "Dos Lagunas",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "DOO",
    airportName: "Dorobisoro",
    cityCode: "DOO",
    cityName: "Dorobisoro",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DOP",
    airportName: "Dolpa",
    cityCode: "DOP",
    cityName: "Dolpa",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "DOR",
    airportName: "Dori",
    cityCode: "DOR",
    cityName: "Dori",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "DOS",
    airportName: "Dios",
    cityCode: "DOS",
    cityName: "Dios",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DOU",
    airportName: "Dourados",
    cityCode: "DOU",
    cityName: "Dourados",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "DOV",
    airportName: "Dover AFB",
    cityCode: "DOV",
    cityName: "Dover-Cheswold, DE",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DOX",
    airportName: "Dongara",
    cityCode: "DOX",
    cityName: "Dongara",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DOY",
    airportName: "Dongying",
    cityCode: "DOY",
    cityName: "Dongying",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DPA",
    airportName: "Dupage County",
    cityCode: "DPA",
    cityName: "Chicago",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DPE",
    airportName: "Dieppe",
    cityCode: "DPE",
    cityName: "Dieppe",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DPG",
    airportName: "Michael AAF",
    cityCode: "DPG",
    cityName: "Dugway",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DPK",
    airportName: "Deer Park",
    cityCode: "DPK",
    cityName: "Deer Park",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DPL",
    airportName: "Dipolog",
    cityCode: "DPL",
    cityName: "Dipolog",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "DPO",
    airportName: "Devonport",
    cityCode: "DPO",
    cityName: "Devonport",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DPS",
    airportName: "Ngurah Rai Intl.",
    cityCode: "DPS",
    cityName: "Denpasar, Bali",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "DPU",
    airportName: "Dumpu",
    cityCode: "DPU",
    cityName: "Dumpu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "DRA",
    airportName: "Desert Rock",
    cityCode: "DRA",
    cityName: "Mercury",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DRB",
    airportName: "Derby",
    cityCode: "DRB",
    cityName: "Derby",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DRC",
    airportName: "Dirico",
    cityCode: "DRC",
    cityName: "Dirico",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "DRD",
    airportName: "Dorunda Station",
    cityCode: "DRD",
    cityName: "Dorunda Station",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DRE",
    airportName: "Drummond Island",
    cityCode: "DRE",
    cityName: "Drummond Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DRF",
    airportName: "Drift River",
    cityCode: "DRF",
    cityName: "Drift River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DRG",
    airportName: "Deering",
    cityCode: "DRG",
    cityName: "Deering",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DRH",
    airportName: "Dabra",
    cityCode: "DRH",
    cityName: "Dabra",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "DRI",
    airportName: "Beauregard Parish",
    cityCode: "DRI",
    cityName: "De Ridder",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DRM",
    airportName: "Drama",
    cityCode: "DRM",
    cityName: "Drama",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "DRN",
    airportName: "Dirranbandi",
    cityCode: "DRN",
    cityName: "Dirranbandi",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DRO",
    airportName: "La Plata",
    cityCode: "DRO",
    cityName: "Durango",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DRR",
    airportName: "Durrie",
    cityCode: "DRR",
    cityName: "Durrie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DRS",
    airportName: "Dresden Arpt",
    cityCode: "DRS",
    cityName: "Dresden",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "DRT",
    airportName: "Intl.",
    cityCode: "DRT",
    cityName: "Del Rio",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DRU",
    airportName: "Drummond",
    cityCode: "DRU",
    cityName: "Drummond",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DRW",
    airportName: "Darwin",
    cityCode: "DRW",
    cityName: "Darwin",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DSA",
    airportName: "Robin Hood",
    cityCode: "DSA",
    cityName: "Doncaster Sheffield",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "DSC",
    airportName: "Dschang",
    cityCode: "DSC",
    cityName: "Dschang",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "DSD",
    airportName: "La Desirade",
    cityCode: "DSD",
    cityName: "La Desirade",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "DSE",
    airportName: "Combolcha",
    cityCode: "DSE",
    cityName: "Dessie",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "DSI",
    airportName: "Destin-Fort Walton Beach Arpt.",
    cityCode: "DSI",
    cityName: "Destin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DSK",
    airportName: "Dera Ismail Khan",
    cityCode: "DSK",
    cityName: "Dera Ismail Khan",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "DSL",
    airportName: "Daru",
    cityCode: "DSL",
    cityName: "Daru",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "DSM",
    airportName: "Des Moines Intl.",
    cityCode: "DSM",
    cityName: "Des Moines, IA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DSN",
    airportName: "Dongsheng",
    cityCode: "DSN",
    cityName: "Dongsheng",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DTA",
    airportName: "Delta",
    cityCode: "DTA",
    cityName: "Delta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DTD",
    airportName: "Datadawai",
    cityCode: "DTD",
    cityName: "Datadawai",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "DTE",
    airportName: "Naga",
    cityCode: "DTE",
    cityName: "Daet",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "DTH",
    airportName: "Death Valley",
    cityCode: "DTH",
    cityName: "Death Valley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DTL",
    airportName: "Municipal",
    cityCode: "DTL",
    cityName: "Detroit Lakes",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DTM",
    airportName: "Dortmund",
    cityCode: "DTM",
    cityName: "Dortmund",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "DTN",
    airportName: "Shreveport Downtown",
    cityCode: "DTN",
    cityName: "Shreveport, LA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DTR",
    airportName: "Decatur Island",
    cityCode: "DTR",
    cityName: "Decatur Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DTW",
    airportName: "Detroit Metropolitan Wayne County",
    cityCode: "DTT",
    cityName: "Detroit",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DUA",
    airportName: "Eaker",
    cityCode: "DUA",
    cityName: "Durant",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DUB",
    airportName: "Dublin",
    cityCode: "DUB",
    cityName: "Dublin",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "DUC",
    airportName: "Halliburton Field",
    cityCode: "DUC",
    cityName: "Duncan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DUD",
    airportName: "Dunedin Intl.",
    cityCode: "DUD",
    cityName: "Dunedin",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "DUE",
    airportName: "Dundo",
    cityCode: "DUE",
    cityName: "Dundo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "DUF",
    airportName: "Pine Island Arpt.",
    cityCode: "DUF",
    cityName: "Corolla, NC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DUG",
    airportName: "Bisbee-Douglas Intl",
    cityCode: "DUG",
    cityName: "Douglas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DUJ",
    airportName: "Du Bois-Jefferson County",
    cityCode: "DUJ",
    cityName: "Du Bois",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DUK",
    airportName: "Dukuduk",
    cityCode: "DUK",
    cityName: "Dukuduk",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "DUM",
    airportName: "Pinang Kampai",
    cityCode: "DUM",
    cityName: "Dumai",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "DUN",
    airportName: "Dundas",
    cityCode: "DUN",
    cityName: "Dundas",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "DUQ",
    airportName: "Duncan/Quam",
    cityCode: "DUQ",
    cityName: "Duncan/Quam",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "DUR",
    airportName: "Durban Intl.",
    cityCode: "DUR",
    cityName: "Durban",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "DUS",
    airportName: "Dusseldorf",
    cityCode: "DUS",
    cityName: "Dusseldorf",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "DUT",
    airportName: "Emergency Field",
    cityCode: "DUT",
    cityName: "Dutch Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DVA",
    airportName: "Deva",
    cityCode: "DVA",
    cityName: "Deva",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "DVK",
    airportName: "Diavik",
    cityCode: "DVK",
    cityName: "Diavik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "DVL",
    airportName: "Devils Lake",
    cityCode: "DVL",
    cityName: "Devils Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DVN",
    airportName: "Davenport",
    cityCode: "DVN",
    cityName: "Davenport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DVO",
    airportName: "Francisco Bangoy Intl.",
    cityCode: "DVO",
    cityName: "Davao",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "DVP",
    airportName: "Davenport Downs",
    cityCode: "DVP",
    cityName: "Davenport Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DVR",
    airportName: "Daly River",
    cityCode: "DVR",
    cityName: "Daly River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DVT",
    airportName: "Phoenix Deer Valley Arpt.",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DWA",
    airportName: "Dwangwa",
    cityCode: "DWA",
    cityName: "Dwangwa",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "DWB",
    airportName: "Soalala",
    cityCode: "DWB",
    cityName: "Soalala",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "DWD",
    airportName: "Dawadmi",
    cityCode: "DWD",
    cityName: "Dawadmi",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "DWF",
    airportName: "Wright AFB",
    cityCode: "DWF",
    cityName: "Dayton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DWH",
    airportName: "David Wayne Hooks",
    cityCode: "HOU",
    cityName: "Houston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DWN",
    airportName: "Downtown Airpark",
    cityCode: "DWN",
    cityName: "Oklahoma City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DWS",
    airportName: "Walt Disney World",
    cityCode: "ORL",
    cityName: "Orlando",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DXA",
    airportName: "Deux Alpes",
    cityCode: "DXA",
    cityName: "Deux Alpes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DXB",
    airportName: "Dubai International Airport",
    cityCode: "DXB",
    cityName: "Dubai",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "DXD",
    airportName: "Dixie",
    cityCode: "DXD",
    cityName: "Dixie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DXR",
    airportName: "Danbury Municipal Arpt.",
    cityCode: "DXR",
    cityName: "Danbury, CT",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DYA",
    airportName: "Dysart",
    cityCode: "DYA",
    cityName: "Dysart",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DYG",
    airportName: "Dayong",
    cityCode: "DYG",
    cityName: "Dayong",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "DYL",
    airportName: "Doylestown",
    cityCode: "DYL",
    cityName: "Doylestown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DYM",
    airportName: "Diamantina Lakes",
    cityCode: "DYM",
    cityName: "Diamantina Lakes",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DYR",
    airportName: "Anadyr",
    cityCode: "DYR",
    cityName: "Anadyr",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "DYS",
    airportName: "Dyess AFB",
    cityCode: "DYS",
    cityName: "Abilene",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "DYU",
    airportName: "Dushanbe",
    cityCode: "DYU",
    cityName: "Dushanbe",
    countryName: "Tajikistan",
    countryCode: "TJ",
  },
  {
    airportCode: "DYW",
    airportName: "Daly Waters",
    cityCode: "DYW",
    cityName: "Daly Waters",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "DZA",
    airportName: "Dzaoudzi",
    cityCode: "DZA",
    cityName: "Dzaoudzi",
    countryName: "Comoros",
    countryCode: "KM",
  },
  {
    airportCode: "DZI",
    airportName: "Codazzi",
    cityCode: "DZI",
    cityName: "Codazzi",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "DZN",
    airportName: "Zhezhazgan",
    cityCode: "DZN",
    cityName: "Zhezkazgan",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "DZO",
    airportName: "Santa Bernardina",
    cityCode: "DZO",
    cityName: "Durazno",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "DZU",
    airportName: "Dazu",
    cityCode: "DZU",
    cityName: "Dazu",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "EAA",
    airportName: "Eagle",
    cityCode: "EAA",
    cityName: "Eagle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EAB",
    airportName: "Abbse",
    cityCode: "EAB",
    cityName: "Abbse",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "EAE",
    airportName: "Emae",
    cityCode: "EAE",
    cityName: "Emae",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "EAL",
    airportName: "Elenak",
    cityCode: "EAL",
    cityName: "Kwajalein Atoll",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "EAM",
    airportName: "Nejran",
    cityCode: "EAM",
    cityName: "Nejran",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "EAN",
    airportName: "Phifer Field",
    cityCode: "EAN",
    cityName: "Wheatland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EAR",
    airportName: "Kearney",
    cityCode: "EAR",
    cityName: "Kearney",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EAS",
    airportName: "Donostia - San Sebastian",
    cityCode: "EAS",
    cityName: "San Sebastian",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "EAT",
    airportName: "Pangborn Field",
    cityCode: "EAT",
    cityName: "Wenatchee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EAU",
    airportName: "Chippewa Valley Regional Arpt.",
    cityCode: "EAU",
    cityName: "Eau Claire, WI",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EBA",
    airportName: "Marina Di Campo",
    cityCode: "EBA",
    cityName: "Elba Island",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "EBB",
    airportName: "Entebbe",
    cityCode: "EBB",
    cityName: "Entebbe",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "EBD",
    airportName: "El Obeid",
    cityCode: "EBD",
    cityName: "El Obeid",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "EBG",
    airportName: "El Bagre",
    cityCode: "EBG",
    cityName: "El Bagre",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "EBJ",
    airportName: "Esbjerg",
    cityCode: "EBJ",
    cityName: "Esbjerg",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "EBL",
    airportName: "Erbil Intl.",
    cityCode: "EBL",
    cityName: "Erbil",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "EBM",
    airportName: "El Borma",
    cityCode: "EBM",
    cityName: "El Borma",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "EBN",
    airportName: "Ebadon",
    cityCode: "EBN",
    cityName: "Ebadon",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "EBR",
    airportName: "Downtown",
    cityCode: "EBR",
    cityName: "Baton Rouge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EBS",
    airportName: "Municipal",
    cityCode: "EBS",
    cityName: "Webster City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EBU",
    airportName: "Boutheon",
    cityCode: "EBU",
    cityName: "St Etienne",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "EBW",
    airportName: "Ebolowa",
    cityCode: "EBW",
    cityName: "Ebolowa",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "ECA",
    airportName: "Emmet County Arpt.",
    cityCode: "ECA",
    cityName: "East Tawas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ECG",
    airportName: "Elizabeth City",
    cityCode: "ECG",
    cityName: "Elizabeth City, NC",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ECH",
    airportName: "Echuca",
    cityCode: "ECH",
    cityName: "Echuca",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ECN",
    airportName: "Ercan",
    cityCode: "ECN",
    cityName: "Lefkosa",
    countryName: "Cyprus",
    countryCode: "CY",
  },
  {
    airportCode: "ECO",
    airportName: "El Encanto",
    cityCode: "ECO",
    cityName: "El Encanto",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ECR",
    airportName: "El Charco",
    cityCode: "ECR",
    cityName: "El Charco",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ECS",
    airportName: "Mondell",
    cityCode: "ECS",
    cityName: "Newcastle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EDA",
    airportName: "Edna Bay",
    cityCode: "EDA",
    cityName: "Edna Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EDB",
    airportName: "Eldebba",
    cityCode: "EDB",
    cityName: "Eldebba",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "EDD",
    airportName: "Erldunda",
    cityCode: "EDD",
    cityName: "Erldunda",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EDE",
    airportName: "Municipal",
    cityCode: "EDE",
    cityName: "Edenton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EDF",
    airportName: "Elmendorf Afb",
    cityCode: "EDF",
    cityName: "Anchorage",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EDG",
    airportName: "Weide AAF",
    cityCode: "EDG",
    cityName: "Edgewood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EDI",
    airportName: "Turnhouse",
    cityCode: "EDI",
    cityName: "Edinburgh",
    countryName: "Scotland, United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "EDK",
    airportName: "El Dorado",
    cityCode: "EDK",
    cityName: "El Dorado",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EDL",
    airportName: "Eldoret",
    cityCode: "EDL",
    cityName: "Eldoret",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "EDM",
    airportName: "Les Ajoncs",
    cityCode: "EDM",
    cityName: "La Roche",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "EDO",
    airportName: "Edremit/korfez",
    cityCode: "EDO",
    cityName: "Edremit/korfez",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "EDQ",
    airportName: "Erandique",
    cityCode: "EDQ",
    cityName: "Erandique",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "EDR",
    airportName: "Edward River",
    cityCode: "EDR",
    cityName: "Edward River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EDW",
    airportName: "Edwards AFB",
    cityCode: "EDW",
    cityName: "Edwards AFB",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EED",
    airportName: "Needles",
    cityCode: "EED",
    cityName: "Needles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EEK",
    airportName: "Eek",
    cityCode: "EEK",
    cityName: "Eek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EEN",
    airportName: "Dillant-Hopkins",
    cityCode: "EEN",
    cityName: "Keene",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EFB",
    airportName: "Eight Fathom Bight",
    cityCode: "EFB",
    cityName: "Eight Fathom Bight",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EFD",
    airportName: "Ellington Field",
    cityCode: "HOU",
    cityName: "Houston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EFG",
    airportName: "Efogi",
    cityCode: "EFG",
    cityName: "Efogi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "EFK",
    airportName: "Newport",
    cityCode: "EFK",
    cityName: "Newport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EFL",
    airportName: "Kefalonia Istland Intl. Arpt.",
    cityCode: "EFL",
    cityName: "Kefalonia",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "EFW",
    airportName: "Municipal",
    cityCode: "EFW",
    cityName: "Jefferson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EGA",
    airportName: "Engati",
    cityCode: "EGA",
    cityName: "Engati",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "EGC",
    airportName: "Roumanieres",
    cityCode: "EGC",
    cityName: "Bergerac",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "EGE",
    airportName: "Eagle County",
    cityCode: "EGE",
    cityName: "Vail/Eagle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EGI",
    airportName: "Duke Field",
    cityCode: "EGI",
    cityName: "Valparaiso",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EGL",
    airportName: "Neghelli",
    cityCode: "EGL",
    cityName: "Neghelli",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "EGM",
    airportName: "Sege",
    cityCode: "EGM",
    cityName: "Sege",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "EGN",
    airportName: "Geneina",
    cityCode: "EGN",
    cityName: "Geneina",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "EGO",
    airportName: "Belgorod",
    cityCode: "EGO",
    cityName: "Belgorod",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "EGP",
    airportName: "Maverick Co",
    cityCode: "EGP",
    cityName: "Eagle Pass",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EGS",
    airportName: "Egilsstadir",
    cityCode: "EGS",
    cityName: "Egilsstadir",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "EGV",
    airportName: "Eagle River",
    cityCode: "EGV",
    cityName: "Eagle River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EGX",
    airportName: "Egegik",
    cityCode: "EGX",
    cityName: "Egegik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EHL",
    airportName: "El Bolson",
    cityCode: "EHL",
    cityName: "El Bolson",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "EHM",
    airportName: "Cape Newenham",
    cityCode: "EHM",
    cityName: "Cape Newenham",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EHT",
    airportName: "Rentschler",
    cityCode: "EHT",
    cityName: "East Hartford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EIA",
    airportName: "Popondetta",
    cityCode: "EIA",
    cityName: "Eia",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "EIB",
    airportName: "Eisenach",
    cityCode: "EIB",
    cityName: "Eisenach",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "EIE",
    airportName: "Eniseysk",
    cityCode: "EIE",
    cityName: "Eniseysk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "EIH",
    airportName: "Einasleigh",
    cityCode: "EIH",
    cityName: "Einasleigh",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EIL",
    airportName: "Eielson AFB",
    cityCode: "EIL",
    cityName: "Fairbanks",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EIN",
    airportName: "Eindhoven",
    cityCode: "EIN",
    cityName: "Eindhoven",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "EIS",
    airportName: "Beef Island",
    cityCode: "EIS",
    cityName: "Beef Island",
    countryName: "British Virgin Islands",
    countryCode: "VG",
  },
  {
    airportCode: "EIY",
    airportName: "Ein Yahav",
    cityCode: "EIY",
    cityName: "Ein Yahav",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "EJA",
    airportName: "Variguies",
    cityCode: "EJA",
    cityName: "Barrancabermeja",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "EJH",
    airportName: "Wedjh",
    cityCode: "EJH",
    cityName: "Wedjh",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "EJT",
    airportName: "Enijet",
    cityCode: "EJT",
    cityName: "Mili Atoll",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "EKA",
    airportName: "Murray Field",
    cityCode: "EKA",
    cityName: "Eureka, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EKB",
    airportName: "Ekibastuz",
    cityCode: "EKB",
    cityName: "Ekibastuz",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "EKD",
    airportName: "Elkedra",
    cityCode: "EKD",
    cityName: "Elkedra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EKE",
    airportName: "Ekereku",
    cityCode: "EKE",
    cityName: "Ekereku",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "EKI",
    airportName: "Municipal",
    cityCode: "EKI",
    cityName: "Elkhart",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EKN",
    airportName: "Elkins",
    cityCode: "EKN",
    cityName: "Elkins",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EKO",
    airportName: "Elko Regional",
    cityCode: "EKO",
    cityName: "Elko, NV",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EKT",
    airportName: "Eskilstuna",
    cityCode: "EKT",
    cityName: "Eskilstuna",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "EKX",
    airportName: "Elizabethtown",
    cityCode: "EKX",
    cityName: "Elizabethtown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELA",
    airportName: "Eagle Lake",
    cityCode: "ELA",
    cityName: "Eagle Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELB",
    airportName: "San Bernado",
    cityCode: "ELB",
    cityName: "El Banco",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ELC",
    airportName: "Elcho Island",
    cityCode: "ELC",
    cityName: "Elcho Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ELD",
    airportName: "Goodwin Field",
    cityCode: "ELD",
    cityName: "El Dorado",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELE",
    airportName: "El Real",
    cityCode: "ELE",
    cityName: "El Real",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "ELF",
    airportName: "El Fasher",
    cityCode: "ELF",
    cityName: "El Fasher",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "ELG",
    airportName: "Djamet",
    cityCode: "ELG",
    cityName: "El Golea",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "ELH",
    airportName: "Intl.",
    cityCode: "ELH",
    cityName: "North Eleuthera",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "ELI",
    airportName: "Elim",
    cityCode: "ELI",
    cityName: "Elim",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELJ",
    airportName: "El Recreo",
    cityCode: "ELJ",
    cityName: "El Recreo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ELK",
    airportName: "Municipal",
    cityCode: "ELK",
    cityName: "Elk City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELL",
    airportName: "Ellisras",
    cityCode: "ELL",
    cityName: "Ellisras",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "ELM",
    airportName: "Elmira Corning Regional",
    cityCode: "ELM",
    cityName: "Corning, NY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELN",
    airportName: "Bowers Field",
    cityCode: "ELN",
    cityName: "Ellensburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELO",
    airportName: "Eldorado",
    cityCode: "ELO",
    cityName: "Eldorado",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "ELP",
    airportName: "El Paso Intl. Arpt.",
    cityCode: "ELP",
    cityName: "El Paso",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELQ",
    airportName: " Nayef bin Abdulaziz ",
    cityCode: "ELQ",
    cityName: "Buraidah",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "ELR",
    airportName: "Elelim",
    cityCode: "ELR",
    cityName: "Elelim",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ELS",
    airportName: "East London",
    cityCode: "ELS",
    cityName: "East London",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "ELT",
    airportName: "Tour Sinai City",
    cityCode: "ELT",
    cityName: "Tour Sinai City",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "ELU",
    airportName: "Guemar",
    cityCode: "ELU",
    cityName: "El Oued",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "ELV",
    airportName: "Elfin Cove SPB",
    cityCode: "ELV",
    cityName: "Elfin Cove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELW",
    airportName: "Ellamar",
    cityCode: "ELW",
    cityName: "Ellamar",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELX",
    airportName: "El Tigre",
    cityCode: "ELX",
    cityName: "El Tigre",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "ELY",
    airportName: "Yelland",
    cityCode: "ELY",
    cityName: "Ely",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ELZ",
    airportName: "Municipal",
    cityCode: "ELZ",
    cityName: "Wellsville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EMA",
    airportName: "East Midlands",
    cityCode: "EMA",
    cityName: "Derby",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "EMB",
    airportName: "Embarkadero",
    cityCode: "EMB",
    cityName: "San Francisco",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EMD",
    airportName: "Emerald",
    cityCode: "EMD",
    cityName: "Emerald",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EME",
    airportName: "Emden",
    cityCode: "EME",
    cityName: "Emden",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "EMG",
    airportName: "Empangeni",
    cityCode: "EMG",
    cityName: "Empangeni",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "EMI",
    airportName: "Emirau",
    cityCode: "EMI",
    cityName: "Emirau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "EMK",
    airportName: "Emmonak",
    cityCode: "EMK",
    cityName: "Emmonak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EML",
    airportName: "Emmen",
    cityCode: "EML",
    cityName: "Luzern",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "EMM",
    airportName: "Kemerer",
    cityCode: "EMM",
    cityName: "Kemerer",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EMN",
    airportName: "Nema",
    cityCode: "EMN",
    cityName: "Nema",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "EMO",
    airportName: "Emo",
    cityCode: "EMO",
    cityName: "Emo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "EMP",
    airportName: "Emporia",
    cityCode: "EMP",
    cityName: "Emporia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EMS",
    airportName: "Embessa",
    cityCode: "EMS",
    cityName: "Embessa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "EMT",
    airportName: "El Monte",
    cityCode: "EMT",
    cityName: "El Monte",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EMX",
    airportName: "El Maiten",
    cityCode: "EMX",
    cityName: "El Maiten",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "EMY",
    airportName: "El Minya",
    cityCode: "EMY",
    cityName: "El Minya",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "ENA",
    airportName: "Kenai",
    cityCode: "ENA",
    cityName: "Kenai",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ENB",
    airportName: "Eneabba West",
    cityCode: "ENB",
    cityName: "Eneabba West",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ENC",
    airportName: "Essey",
    cityCode: "ENC",
    cityName: "Nancy",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "END",
    airportName: "Vance Afb",
    cityCode: "END",
    cityName: "Enid",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ENE",
    airportName: "Ende",
    cityCode: "ENE",
    cityName: "Ende",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ENF",
    airportName: "Enontekio",
    cityCode: "ENF",
    cityName: "Enontekio",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "ENH",
    airportName: "Enshi",
    cityCode: "ENH",
    cityName: "Enshi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ENI",
    airportName: "El Nido",
    cityCode: "ENI",
    cityName: "El Nido",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "ENJ",
    airportName: "El Naranjo",
    cityCode: "ENJ",
    cityName: "El Naranjo",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "ENK",
    airportName: "Enniskillen St. Angelo",
    cityCode: "ENK",
    cityName: "Enniskillen",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ENL",
    airportName: "Municipal",
    cityCode: "ENL",
    cityName: "Centralia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ENN",
    airportName: "Municipal",
    cityCode: "ENN",
    cityName: "Nenana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ENO",
    airportName: "Teniente Prim Alarcon",
    cityCode: "ENO",
    cityName: "Encarnacion",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "ENQ",
    airportName: "Coronel E Soto Cano AB",
    cityCode: "ENQ",
    cityName: "Coronel E Soto Cano AB",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "ENS",
    airportName: "Twente",
    cityCode: "ENS",
    cityName: "Enschede",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "ENT",
    airportName: "Enewetak Island",
    cityCode: "ENT",
    cityName: "Enewetak Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "ENU",
    airportName: "Enugu",
    cityCode: "ENU",
    cityName: "Enugu",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "ENV",
    airportName: "Wendover",
    cityCode: "ENV",
    cityName: "Wendover",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ENW",
    airportName: "Kenosha Regional Arpt.",
    cityCode: "ENW",
    cityName: "Kenosha",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EOH",
    airportName: "Enrique Olaya Herrera Intl. Arpt.",
    cityCode: "EOH",
    cityName: "MedellÃ­n",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "EOI",
    airportName: "Eday",
    cityCode: "EOI",
    cityName: "Eday",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "EOK",
    airportName: "Keokuk",
    cityCode: "EOK",
    cityName: "Keokuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EOR",
    airportName: "El Dorado",
    cityCode: "EOR",
    cityName: "El Dorado",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "EOS",
    airportName: "Neosho",
    cityCode: "EOS",
    cityName: "Neosho",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EOZ",
    airportName: "Elorza",
    cityCode: "EOZ",
    cityName: "Elorza",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "EPA",
    airportName: "El Palomar",
    cityCode: "EPA",
    cityName: "El Palomar",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "EPG",
    airportName: "Browns",
    cityCode: "EPG",
    cityName: "Weeping Water",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EPH",
    airportName: "Ephrata",
    cityCode: "EPH",
    cityName: "Ephrata",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EPI",
    airportName: "Epi",
    cityCode: "EPI",
    cityName: "Epi",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "EPK",
    airportName: "Episkopi",
    cityCode: "EPK",
    cityName: "Episkopi",
    countryName: "Cyprus",
    countryCode: "CY",
  },
  {
    airportCode: "EPL",
    airportName: "Mirecourt",
    cityCode: "EPL",
    cityName: "Epinal",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "EPN",
    airportName: "Epena",
    cityCode: "EPN",
    cityName: "Epena",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "EPR",
    airportName: "Esperance",
    cityCode: "EPR",
    cityName: "Esperance",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EPS",
    airportName: "Arroyo Barril Intl.",
    cityCode: "EPS",
    cityName: "Samana",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "EPT",
    airportName: "Eliptamin",
    cityCode: "EPT",
    cityName: "Eliptamin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "EPU",
    airportName: "Parnu",
    cityCode: "EPU",
    cityName: "Parnu",
    countryName: "Estonia",
    countryCode: "EE",
  },
  {
    airportCode: "EQS",
    airportName: "Esquel",
    cityCode: "EQS",
    cityName: "Esquel",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "ERA",
    airportName: "Erigavo",
    cityCode: "ERA",
    cityName: "Erigavo",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "ERB",
    airportName: "Ernabella",
    cityCode: "ERB",
    cityName: "Ernabella",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ERC",
    airportName: "Erzincan",
    cityCode: "ERC",
    cityName: "Erzincan",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ERD",
    airportName: "Berdyansk",
    cityCode: "ERD",
    cityName: "Berdyansk",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "ERE",
    airportName: "Erave",
    cityCode: "ERE",
    cityName: "Erave",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ERF",
    airportName: "Erfurt",
    cityCode: "ERF",
    cityName: "Erfurt",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ERH",
    airportName: "Moulay Ali Cherif",
    cityCode: "ERH",
    cityName: "Er-Rachidia",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "ERI",
    airportName: "Intl.",
    cityCode: "ERI",
    cityName: "Erie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ERM",
    airportName: "Comandante Kraemer",
    cityCode: "ERM",
    cityName: "Erechim",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ERN",
    airportName: "Eirunepe",
    cityCode: "ERN",
    cityName: "Eirunepe",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ERO",
    airportName: "Coast Guard",
    cityCode: "ERO",
    cityName: "Eldred Rock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ERR",
    airportName: "Errol",
    cityCode: "ERR",
    cityName: "Errol",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ERS",
    airportName: "Eros",
    cityCode: "ERS",
    cityName: "Windhoek",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "ERT",
    airportName: "Erdenet",
    cityCode: "ERT",
    cityName: "Erdenet",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "ERU",
    airportName: "Erume",
    cityCode: "ERU",
    cityName: "Erume",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ERV",
    airportName: "Kerrville",
    cityCode: "ERV",
    cityName: "Kerrville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ERZ",
    airportName: "Budrum",
    cityCode: "ERZ",
    cityName: "Erzurum",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ESB",
    airportName: "Esenboga",
    cityCode: "ESB",
    cityName: "Ankara",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ESC",
    airportName: "Delta County Arpt",
    cityCode: "ESC",
    cityName: "Escanaba",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ESD",
    airportName: "Orcas Island",
    cityCode: "ESD",
    cityName: "Eastsound",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ESE",
    airportName: "Ensenada",
    cityCode: "ESE",
    cityName: "Ensenada",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "ESF",
    airportName: "Esler Field",
    cityCode: "ESF",
    cityName: "Alexandria",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ESG",
    airportName: "Mariscal Estigarribia",
    cityCode: "ESG",
    cityName: "Mariscal Estigarribia",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "ESH",
    airportName: "Shoreham",
    cityCode: "ESH",
    cityName: "Shoreham By Sea",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ESI",
    airportName: "Espinosa",
    cityCode: "ESI",
    cityName: "Espinosa",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ESK",
    airportName: "Eskisehir",
    cityCode: "ESK",
    cityName: "Eskisehir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ESL",
    airportName: "Elista",
    cityCode: "ESL",
    cityName: "Elista",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ESM",
    airportName: "Esmeraldas",
    cityCode: "ESM",
    cityName: "Esmeraldas",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "ESN",
    airportName: "Easton",
    cityCode: "ESN",
    cityName: "Easton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ESO",
    airportName: "Espanola",
    cityCode: "ESO",
    cityName: "Espanola",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ESP",
    airportName: "Birchwood-Pocono",
    cityCode: "ESP",
    cityName: "East Stroudsburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ESR",
    airportName: "El Salvador",
    cityCode: "ESR",
    cityName: "El Salvador",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ESS",
    airportName: "Essen",
    cityCode: "ESS",
    cityName: "Essen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "EST",
    airportName: "Municipal",
    cityCode: "EST",
    cityName: "Estherville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ESU",
    airportName: "Essaouira",
    cityCode: "ESU",
    cityName: "Essaouira",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "ESW",
    airportName: "State",
    cityCode: "ESW",
    cityName: "Easton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ETB",
    airportName: "West Bend",
    cityCode: "ETB",
    cityName: "West Bend",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ETD",
    airportName: "Etadunna",
    cityCode: "ETD",
    cityName: "Etadunna",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ETE",
    airportName: "Genda Wuha",
    cityCode: "ETE",
    cityName: "Genda Wuha",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "ETH",
    airportName: "Eilat",
    cityCode: "ETH",
    cityName: "Eilat",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "ETN",
    airportName: "Municipal",
    cityCode: "ETN",
    cityName: "Eastland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ETS",
    airportName: "Municipal",
    cityCode: "ETS",
    cityName: "Enterprise",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ETZ",
    airportName: "Metz-nancy-lorraine",
    cityCode: "ETZ",
    cityName: "Metz/nancy",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "EUA",
    airportName: "Kaufana",
    cityCode: "EUA",
    cityName: "Eua",
    countryName: "Tonga",
    countryCode: "TO",
  },
  {
    airportCode: "EUC",
    airportName: "Eucla",
    cityCode: "EUC",
    cityName: "Eucla",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EUE",
    airportName: "Eureka",
    cityCode: "EUE",
    cityName: "Eureka",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EUF",
    airportName: "Weedon Field",
    cityCode: "EUF",
    cityName: "Eufaula",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EUG",
    airportName: "Eugene",
    cityCode: "EUG",
    cityName: "Eugene",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EUM",
    airportName: "Neumuenster",
    cityCode: "EUM",
    cityName: "Neumuenster",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "EUN",
    airportName: "Hassan I",
    cityCode: "EUN",
    cityName: "Laayoune",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "EUO",
    airportName: "Paratebueno",
    cityCode: "EUO",
    cityName: "Paratebueno",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "EUQ",
    airportName: "Evelio Javier",
    cityCode: "EUQ",
    cityName: "Antique",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "EUX",
    airportName: "F D Roosevelt",
    cityCode: "EUX",
    cityName: "St Eustatius",
    countryName: "Netherlands Antilles",
    countryCode: "AN",
  },
  {
    airportCode: "EVA",
    airportName: "Landing Strip",
    cityCode: "EVA",
    cityName: "Evadale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EVD",
    airportName: "Eva Downs",
    cityCode: "EVD",
    cityName: "Eva Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EVE",
    airportName: "Evenes",
    cityCode: "EVE",
    cityName: "Harstad-narvik",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "EVG",
    airportName: "Sveg",
    cityCode: "EVG",
    cityName: "Sveg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "EVH",
    airportName: "Evans Head",
    cityCode: "EVH",
    cityName: "Evans Head",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EVM",
    airportName: "Eveleth",
    cityCode: "EVM",
    cityName: "Eveleth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EVN",
    airportName: "Zvartnots",
    cityCode: "EVN",
    cityName: "Yerevan",
    countryName: "Armenia",
    countryCode: "AM",
  },
  {
    airportCode: "EVV",
    airportName: "Evansville Regional Arpt.",
    cityCode: "EVV",
    cityName: "Evansville, IN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EVW",
    airportName: "Evanston",
    cityCode: "EVW",
    cityName: "Evanston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EVX",
    airportName: "Evreux",
    cityCode: "EVX",
    cityName: "Evreux",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "EWB",
    airportName: "New Bedford",
    cityCode: "EWB",
    cityName: "New Bedford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EWD",
    airportName: "Wildman Lake",
    cityCode: "EWD",
    cityName: "Wildman Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EWE",
    airportName: "Ewer",
    cityCode: "EWE",
    cityName: "Ewer",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "EWI",
    airportName: "Enarotali",
    cityCode: "EWI",
    cityName: "Enarotali",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "EWK",
    airportName: "City-County",
    cityCode: "EWK",
    cityName: "Newton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EWN",
    airportName: "Simmons Nott",
    cityCode: "EWN",
    cityName: "New Bern",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EWO",
    airportName: "Ewo",
    cityCode: "EWO",
    cityName: "Ewo",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "EWR",
    airportName: "Newark Liberty Intl.",
    cityCode: "NYC",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EWY",
    airportName: "Greenham RAF",
    cityCode: "EWY",
    cityName: "Newbury",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "EXI",
    airportName: "SPB",
    cityCode: "EXI",
    cityName: "Excursion Inlet",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EXM",
    airportName: "Exmouth Gulf",
    cityCode: "EXM",
    cityName: "Exmouth Gulf",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "EXT",
    airportName: "Exeter",
    cityCode: "EXT",
    cityName: "Exeter",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "EYL",
    airportName: "Yelimane",
    cityCode: "EYL",
    cityName: "Yelimane",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "EYP",
    airportName: "El Yopal",
    cityCode: "EYP",
    cityName: "El Yopal",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "EYR",
    airportName: "Yerington",
    cityCode: "EYR",
    cityName: "Yerington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EYS",
    airportName: "Eliye Springs",
    cityCode: "EYS",
    cityName: "Eliye Springs",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "EYW",
    airportName: "Intl.",
    cityCode: "EYW",
    cityName: "Key West",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "EZE",
    airportName: "Ezeiza Ministro Pistarini",
    cityCode: "BUE",
    cityName: "Buenos Aires",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "EZS",
    airportName: "Elazig",
    cityCode: "EZS",
    cityName: "Elazig",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "FAA",
    airportName: "Faranah",
    cityCode: "FAA",
    cityName: "Faranah",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "FAB",
    airportName: "Farnborough",
    cityCode: "FAB",
    cityName: "Farnborough",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FAC",
    airportName: "Faaite",
    cityCode: "FAC",
    cityName: "Faaite",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "FAE",
    airportName: "Vagar",
    cityCode: "FAE",
    cityName: "SorvÃ¡gur",
    countryName: "Faroe Islands",
    countryCode: "FO",
  },
  {
    airportCode: "FAF",
    airportName: "Felker AAF",
    cityCode: "FAF",
    cityName: "Fort Eustis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FAG",
    airportName: "Fagurholsmyri",
    cityCode: "FAG",
    cityName: "Fagurholsmyri",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "FAH",
    airportName: "Farah",
    cityCode: "FAH",
    cityName: "Farah",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "FAI",
    airportName: "Fairbanks Intl. Arpt.",
    cityCode: "FAI",
    cityName: "Fairbanks",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FAJ",
    airportName: "Diego Jimenez Torres",
    cityCode: "FAJ",
    cityName: "Fajardo",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "FAK",
    airportName: "False Island",
    cityCode: "FAK",
    cityName: "False Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FAL",
    airportName: "Falcon State",
    cityCode: "FAL",
    cityName: "Roma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FAM",
    airportName: "Regional",
    cityCode: "FAM",
    cityName: "Farmington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FAN",
    airportName: "Lista",
    cityCode: "FAN",
    cityName: "Farsund",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "FAO",
    airportName: "Faro",
    cityCode: "FAO",
    cityName: "Faro",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "FAQ",
    airportName: "Freida River",
    cityCode: "FAQ",
    cityName: "Freida River",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "FAR",
    airportName: "Hector Field",
    cityCode: "FAR",
    cityName: "Fargo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FAS",
    airportName: "Faskrudsfjordur",
    cityCode: "FAS",
    cityName: "Faskrudsfjordur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "FAT",
    airportName: "Fresno Air Terminal Arpt.",
    cityCode: "FAT",
    cityName: "Fresno",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FAV",
    airportName: "Fakarava",
    cityCode: "FAV",
    cityName: "Fakarava",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "FAY",
    airportName: "Municipal",
    cityCode: "FAY",
    cityName: "Fayetteville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FAZ",
    airportName: "Fasa",
    cityCode: "FAZ",
    cityName: "Fasa",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "FBD",
    airportName: "Faizabad",
    cityCode: "FBD",
    cityName: "Faizabad",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "FBE",
    airportName: "Francisco Beltrao",
    cityCode: "FBE",
    cityName: "Francisco Beltrao",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "FBG",
    airportName: "Simmons AAF",
    cityCode: "FBG",
    cityName: "Fort Bragg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FBK",
    airportName: "Ft Wainwright",
    cityCode: "FBK",
    cityName: "Fairbanks",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FBL",
    airportName: "Municipal",
    cityCode: "FBL",
    cityName: "Faribault",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FBM",
    airportName: "Luano",
    cityCode: "FBM",
    cityName: "Lubumbashi",
    countryName: "Democratic Republic Of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "FBR",
    airportName: "Fort Bridger",
    cityCode: "FBR",
    cityName: "Fort Bridger",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FBS",
    airportName: "Friday Harbor SPB",
    cityCode: "FBS",
    cityName: "Friday Harbor SPB",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FBY",
    airportName: "Municipal",
    cityCode: "FBY",
    cityName: "Fairbury",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FCA",
    airportName: "Glacier Park Intl.",
    cityCode: "FCA",
    cityName: "Kalispell, MT",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FCB",
    airportName: "Ficksburg Sentra Oes",
    cityCode: "FCB",
    cityName: "Ficksburg",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "FCH",
    airportName: "Fresno-Chandler",
    cityCode: "FCH",
    cityName: "Fresno",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FCM",
    airportName: "Flying Cloud",
    cityCode: "FCM",
    cityName: "Minneapolis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FCN",
    airportName: "Cuxhaven/Nordholz",
    cityCode: "FCN",
    cityName: "Cuxhaven",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FCO",
    airportName: "Leonardo da Vinci Intl.",
    cityCode: "ROM",
    cityName: "Rome",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "FCS",
    airportName: "Butts AAF",
    cityCode: "FCS",
    cityName: "Colorado Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FCT",
    airportName: "Firing Center AAF",
    cityCode: "FCT",
    cityName: "Yakima",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FCY",
    airportName: "Municipal",
    cityCode: "FCY",
    cityName: "Forrest City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FDE",
    airportName: "Bringeland",
    cityCode: "FDE",
    cityName: "Forde",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "FDF",
    airportName: "Lamentin",
    cityCode: "FDF",
    cityName: "Fort De France",
    countryName: "Martinique",
    countryCode: "MQ",
  },
  {
    airportCode: "FDH",
    airportName: "Friedrichshafen",
    cityCode: "FDH",
    cityName: "Friedrichshafen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FDK",
    airportName: "Frederick Municipal",
    cityCode: "FDK",
    cityName: "Frederick",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FDR",
    airportName: "Municipal",
    cityCode: "FDR",
    cityName: "Frederick",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FDU",
    airportName: "Bandundu",
    cityCode: "FDU",
    cityName: "Bandundu",
    countryName: "Democratic Republic Of The Congo",
    countryCode: "ZR",
  },
  {
    airportCode: "FDY",
    airportName: "Findlay",
    cityCode: "FDY",
    cityName: "Findlay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FEA",
    airportName: "Fetlar",
    cityCode: "FEA",
    cityName: "Fetlar",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FEB",
    airportName: "Sanfebagar",
    cityCode: "FEB",
    cityName: "Sanfebagar",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "FEC",
    airportName: "Feira De Santana",
    cityCode: "FEC",
    cityName: "Feira De Santana",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "FEG",
    airportName: "Fergana",
    cityCode: "FEG",
    cityName: "Fergana",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "FEJ",
    airportName: "Feijo",
    cityCode: "FEJ",
    cityName: "Feijo",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "FEK",
    airportName: "Ferkessedougou",
    cityCode: "FEK",
    cityName: "Ferkessedougou",
    countryName: "Cote d'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "FEL",
    airportName: "Fuerstenfeldbruck",
    cityCode: "FEL",
    cityName: "Fuerstenfeldbruck",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FEN",
    airportName: "Fernando De Noronha",
    cityCode: "FEN",
    cityName: "Fernando De Noronha",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "FEP",
    airportName: "Albertus",
    cityCode: "FEP",
    cityName: "Freeport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FER",
    airportName: "Fergusons Gulf",
    cityCode: "FER",
    cityName: "Fergusons Gulf",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "FES",
    airportName: "San Fernando",
    cityCode: "FES",
    cityName: "San Fernando",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "FET",
    airportName: "Municipal",
    cityCode: "FET",
    cityName: "Fremont",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FEW",
    airportName: "Warren AFB",
    cityCode: "FEW",
    cityName: "Cheyenne",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FEZ",
    airportName: "Sais",
    cityCode: "FEZ",
    cityName: "Fez",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "FFA",
    airportName: "First Flight",
    cityCode: "FFA",
    cityName: "Kill Devil Hills",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FFD",
    airportName: "RAF Station",
    cityCode: "FFD",
    cityName: "Fairford",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FFL",
    airportName: "Municipal",
    cityCode: "FFL",
    cityName: "Fairfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FFM",
    airportName: "Fergus Falls",
    cityCode: "FFM",
    cityName: "Fergus Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FFO",
    airportName: "Patterson Afb",
    cityCode: "FFO",
    cityName: "Dayton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FFT",
    airportName: "Capital City",
    cityCode: "FFT",
    cityName: "Frankfort, KY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FFU",
    airportName: "Futaleufu",
    cityCode: "FFU",
    cityName: "Futaleufu",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "FGD",
    airportName: "Fderik",
    cityCode: "FGD",
    cityName: "Fderik",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "FGI",
    airportName: "Fagali I",
    cityCode: "FGI",
    cityName: "Apia",
    countryName: "Samoa",
    countryCode: "WS",
  },
  {
    airportCode: "FGL",
    airportName: "Fox Glacier",
    cityCode: "FGL",
    cityName: "Fox Glacier",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "FGR",
    airportName: "Fuengirola",
    cityCode: "FGR",
    cityName: "Fuengirola",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "FGU",
    airportName: "Fangatau",
    cityCode: "FGU",
    cityName: "Fangatau",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "FHU",
    airportName: "Municipal",
    cityCode: "FHU",
    cityName: "Sierra Vista",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FHZ",
    airportName: "Fakahina",
    cityCode: "FHZ",
    cityName: "Fakahina",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "FIC",
    airportName: "Fire Cove",
    cityCode: "FIC",
    cityName: "Fire Cove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FID",
    airportName: "Elizabeth Field",
    cityCode: "FID",
    cityName: "Fishers Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FIE",
    airportName: "Fair Isle",
    cityCode: "FIE",
    cityName: "Fair Isle",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FIG",
    airportName: "Fria",
    cityCode: "FIG",
    cityName: "Fria",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "FIH",
    airportName: "N'Djili Airport",
    cityCode: "FIH",
    cityName: "Kinshasa",
    countryName: "Democratic Republic Of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "FIK",
    airportName: "Finke",
    cityCode: "FIK",
    cityName: "Finke",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FIL",
    airportName: "Municipal",
    cityCode: "FIL",
    cityName: "Fillmore",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FIN",
    airportName: "Finschhafen",
    cityCode: "FIN",
    cityName: "Finschhafen",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "FIV",
    airportName: "Five Finger",
    cityCode: "FIV",
    cityName: "Five Finger Coast Guard Heliport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FIZ",
    airportName: "Fitzroy Crossing",
    cityCode: "FIZ",
    cityName: "Fitzroy Crossing",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FJR",
    airportName: "Fujairah Intl",
    cityCode: "FJR",
    cityName: "Al-fujairah",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "FKB",
    airportName: "Soellingen",
    cityCode: "FKB",
    cityName: "Karlsruhe/Baden Baden",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FKH",
    airportName: "Sculthorp RAF",
    cityCode: "FKH",
    cityName: "Fakenham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FKI",
    airportName: "Kisangani",
    cityCode: "FKI",
    cityName: "Kisangani",
    countryName: "Democratic Republic of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "FKJ",
    airportName: "Fukui",
    cityCode: "FKJ",
    cityName: "Fukui",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "FKL",
    airportName: "Chess-Lambertin",
    cityCode: "FKL",
    cityName: "Franklin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FKN",
    airportName: "Municipal",
    cityCode: "FKN",
    cityName: "Franklin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FKQ",
    airportName: "Fak Fak",
    cityCode: "FKQ",
    cityName: "Fak Fak",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "FLA",
    airportName: "Capitolio",
    cityCode: "FLA",
    cityName: "Florencia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "FLB",
    airportName: "Cangapara",
    cityCode: "FLB",
    cityName: "Floriano",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "FLC",
    airportName: "Falls Creek",
    cityCode: "FLC",
    cityName: "Falls Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FLD",
    airportName: "Fond Du Lac",
    cityCode: "FLD",
    cityName: "Fond Du Lac",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLE",
    airportName: "Fort Lee AAF",
    cityCode: "FLE",
    cityName: "Petersburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLF",
    airportName: "Schaferhaus",
    cityCode: "FLF",
    cityName: "Flensburg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FLG",
    airportName: "Pulliam Field",
    cityCode: "FLG",
    cityName: "Grand Canyon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLH",
    airportName: "Flotta",
    cityCode: "FLH",
    cityName: "Flotta",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FLI",
    airportName: "Flateyri",
    cityCode: "FLI",
    cityName: "Flateyri",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "FLJ",
    airportName: "Falls Bay",
    cityCode: "FLJ",
    cityName: "Falls Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLL",
    airportName: "Fort Lauderdale/hollywood Intl.",
    cityCode: "FLL",
    cityName: "Fort Lauderdale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLM",
    airportName: "Filadelfia",
    cityCode: "FLM",
    cityName: "Filadelfia",
    countryName: "Paraguay",
    countryCode: "PY",
  },
  {
    airportCode: "FLN",
    airportName: "Hercilio Luz",
    cityCode: "FLN",
    cityName: "Florianopolis",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "FLO",
    airportName: "Florence",
    cityCode: "FLO",
    cityName: "Florence",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLP",
    airportName: "Flippin",
    cityCode: "FLP",
    cityName: "Flippin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLR",
    airportName: "Peretola",
    cityCode: "FLR",
    cityName: "Florence",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "FLS",
    airportName: "Flinders Island",
    cityCode: "FLS",
    cityName: "Flinders Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FLT",
    airportName: "Flat",
    cityCode: "FLT",
    cityName: "Flat",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLU",
    airportName: "Flushing",
    cityCode: "FLU",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLV",
    airportName: "Sherman AAF",
    cityCode: "FLV",
    cityName: "Fort Leavenworth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLW",
    airportName: "Santa Cruz",
    cityCode: "FLW",
    cityName: "Flores Island",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "FLX",
    airportName: "Municipal",
    cityCode: "FLX",
    cityName: "Fallon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FLY",
    airportName: "Finley",
    cityCode: "FLY",
    cityName: "Finley",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FMA",
    airportName: "El Pucu",
    cityCode: "FMA",
    cityName: "Formosa",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "FMC",
    airportName: "Five Mile",
    cityCode: "FMC",
    cityName: "Five Mile",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FME",
    airportName: "Tipton AAF",
    cityCode: "FME",
    cityName: "Fort Meade",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FMG",
    airportName: "Flamingo",
    cityCode: "FMG",
    cityName: "Flamingo",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "FMH",
    airportName: "Otis AFB",
    cityCode: "FMH",
    cityName: "Falmouth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FMI",
    airportName: "Kalemie",
    cityCode: "FMI",
    cityName: "Kalemie",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "FMM",
    airportName: "Memmingen-AllgÃ¤u",
    cityCode: "FMM",
    cityName: "Memmingen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FMN",
    airportName: "Municipal",
    cityCode: "FMN",
    cityName: "Farmington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FMO",
    airportName: "Greven",
    cityCode: "FMO",
    cityName: "Muenster/Osnabrueck",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FMS",
    airportName: "Municipal",
    cityCode: "FMS",
    cityName: "Fort Madison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FMY",
    airportName: "Page Field",
    cityCode: "FMY",
    cityName: "Fort Myers",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FNA",
    airportName: "Lungi Intl.",
    cityCode: "FNA",
    cityName: "Freetown",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "FNB",
    airportName: "Neubrandenburg",
    cityCode: "FNB",
    cityName: "Neubrandenburg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FNC",
    airportName: "Madeira",
    cityCode: "FNC",
    cityName: "Funchal",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "FNE",
    airportName: "Fane",
    cityCode: "FNE",
    cityName: "Fane",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "FNG",
    airportName: "Fada Ngourma",
    cityCode: "FNG",
    cityName: "Fada Ngourma",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "FNI",
    airportName: "Garons",
    cityCode: "FNI",
    cityName: "Nimes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "FNJ",
    airportName: "Sunan Intl.",
    cityCode: "FNJ",
    cityName: "Pyongyang",
    countryName: "Korea",
    countryCode: "KP",
  },
  {
    airportCode: "FNK",
    airportName: "Fin Creek",
    cityCode: "FNK",
    cityName: "Fin Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FNL",
    airportName: "Municipal Arpt.",
    cityCode: "FNL",
    cityName: "Fort Collins/Loveland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FNR",
    airportName: "SPB",
    cityCode: "FNR",
    cityName: "Funter Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FNT",
    airportName: "Bishop Intl. Arpt.",
    cityCode: "FNT",
    cityName: "Flint",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FOA",
    airportName: "Foula",
    cityCode: "FOA",
    cityName: "Foula",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FOB",
    airportName: "Fort Bragg",
    cityCode: "FOB",
    cityName: "Fort Bragg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FOC",
    airportName: "Fuzhou",
    cityCode: "FOC",
    cityName: "Fuzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "FOD",
    airportName: "Fort Dodge Regional",
    cityCode: "FOD",
    cityName: "Fort Dodge, IA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FOE",
    airportName: "Forbes Field",
    cityCode: "FOE",
    cityName: "Topeka, KS",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FOG",
    airportName: "Gino Lisa",
    cityCode: "FOG",
    cityName: "Foggia",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "FOK",
    airportName: "Francis S. Gabreski Airport",
    cityCode: "FOK",
    cityName: "Westhampton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FOM",
    airportName: "Foumban",
    cityCode: "FOM",
    cityName: "Foumban",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "FON",
    airportName: "Fortuna",
    cityCode: "FON",
    cityName: "Fortuna",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "FOO",
    airportName: "Numfoor",
    cityCode: "FOO",
    cityName: "Numfoor",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "FOR",
    airportName: "Pinto Martins",
    cityCode: "FOR",
    cityName: "Fortaleza",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "FOS",
    airportName: "Forrest",
    cityCode: "FOS",
    cityName: "Forrest",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FOT",
    airportName: "Forster",
    cityCode: "FOT",
    cityName: "Forster",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FOU",
    airportName: "Fougamou",
    cityCode: "FOU",
    cityName: "Fougamou",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "FOX",
    airportName: "Fox",
    cityCode: "FOX",
    cityName: "Fox",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FOY",
    airportName: "Foya",
    cityCode: "FOY",
    cityName: "Foya",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "FPO",
    airportName: "Grand Bahama Intl.",
    cityCode: "FPO",
    cityName: "Freeport",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "FPR",
    airportName: "St Lucie County",
    cityCode: "FPR",
    cityName: "Fort Pierce",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FPY",
    airportName: "Perry-Foley",
    cityCode: "FPY",
    cityName: "Perry",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRB",
    airportName: "Forbes",
    cityCode: "FRB",
    cityName: "Forbes",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FRC",
    airportName: "Franca",
    cityCode: "FRC",
    cityName: "Franca",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "FRD",
    airportName: "Friday Harbor",
    cityCode: "FRD",
    cityName: "Friday Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRE",
    airportName: "Fera Island",
    cityCode: "FRE",
    cityName: "Fera Island",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "FRF",
    airportName: "Rhein-Main AFB",
    cityCode: "FRF",
    cityName: "Frankfurt",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FRG",
    airportName: "Republic Field",
    cityCode: "FRG",
    cityName: "Farmingdale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRH",
    airportName: "Municipal",
    cityCode: "FRH",
    cityName: "French Lick",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRI",
    airportName: "Marshall AAF",
    cityCode: "FRI",
    cityName: "Fort Riley, KS",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRJ",
    airportName: "Frejus",
    cityCode: "FRJ",
    cityName: "Frejus",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "FRK",
    airportName: "Fregate Is",
    cityCode: "FRK",
    cityName: "Fregate Is",
    countryName: "Seychelles",
    countryCode: "SC",
  },
  {
    airportCode: "FRL",
    airportName: "Luigi Ridolfi",
    cityCode: "FRL",
    cityName: "Forli",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "FRM",
    airportName: "Fairmont",
    cityCode: "FRM",
    cityName: "Fairmont",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRN",
    airportName: "Bryant AAF",
    cityCode: "FRN",
    cityName: "Fort Richardson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRO",
    airportName: "Flora",
    cityCode: "FRO",
    cityName: "Floro",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "FRP",
    airportName: "Fresh Water Bay",
    cityCode: "FRP",
    cityName: "Fresh Water Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRQ",
    airportName: "Feramin",
    cityCode: "FRQ",
    cityName: "Feramin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "FRR",
    airportName: "Front Royal-Warren County",
    cityCode: "FRR",
    cityName: "Front Royal,",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRS",
    airportName: "Santa Elena",
    cityCode: "FRS",
    cityName: "Flores",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "FRT",
    airportName: "Frutillar",
    cityCode: "FRT",
    cityName: "Frutillar",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "FRU",
    airportName: "Manas",
    cityCode: "FRU",
    cityName: "Bishkek",
    countryName: "Kyrgyzstan",
    countryCode: "KG",
  },
  {
    airportCode: "FRW",
    airportName: "Francistown",
    cityCode: "FRW",
    cityName: "Francistown",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "FRY",
    airportName: "Fryeburg",
    cityCode: "FRY",
    cityName: "Fryeburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FRZ",
    airportName: "Fritzlar Airbase",
    cityCode: "FRZ",
    cityName: "Fritzlar",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "FSC",
    airportName: "Sud Corse",
    cityCode: "FSC",
    cityName: "Figari",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "FSD",
    airportName: "Joe Foss Field Arpt.",
    cityCode: "FSD",
    cityName: "Sioux Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FSI",
    airportName: "Henry Post AAF",
    cityCode: "FSI",
    cityName: "Fort Sill",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FSK",
    airportName: "Municipal",
    cityCode: "FSK",
    cityName: "Fort Scott",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FSL",
    airportName: "Fossil Downs",
    cityCode: "FSL",
    cityName: "Fossil Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FSM",
    airportName: "Regional",
    cityCode: "FSM",
    cityName: "Fort Smith",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FSN",
    airportName: "Haley AAF",
    cityCode: "FSN",
    cityName: "Fort Sheridan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FSP",
    airportName: "Saint-pierre Pointe-blanche",
    cityCode: "FSP",
    cityName: "St Pierre",
    countryName: "St. Pierre And Miquelon",
    countryCode: "PM",
  },
  {
    airportCode: "FSS",
    airportName: "Kinloss",
    cityCode: "FSS",
    cityName: "Forres",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FST",
    airportName: "Pecos County",
    cityCode: "FST",
    cityName: "Fort Stockton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FSU",
    airportName: "Fort Sumner",
    cityCode: "FSU",
    cityName: "Fort Sumner",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FTA",
    airportName: "Futuna Arpt.",
    cityCode: "FTA",
    cityName: "Futuna Island",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "FTE",
    airportName: "El Calafate",
    cityCode: "FTE",
    cityName: "El Calafate",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "FTI",
    airportName: "Fitiuta",
    cityCode: "FTI",
    cityName: "Fitiuta",
    countryName: "American Samoa",
    countryCode: "AS",
  },
  {
    airportCode: "FTK",
    airportName: "Godman AAF",
    cityCode: "FTK",
    cityName: "Fort Knox",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FTL",
    airportName: "Fortuna Ledge",
    cityCode: "FTL",
    cityName: "Fortuna Ledge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FTU",
    airportName: "Marillac",
    cityCode: "FTU",
    cityName: "Fort Dauphin",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "FTW",
    airportName: "Meacham Field",
    cityCode: "FTW",
    cityName: "Fort Worth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FTX",
    airportName: "Owando",
    cityCode: "FTX",
    cityName: "Owando",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "FTY",
    airportName: "Fulton County",
    cityCode: "FTY",
    cityName: "Atlanta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FUB",
    airportName: "Fulleborn",
    cityCode: "FUB",
    cityName: "Fulleborn",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "FUE",
    airportName: "Fuerteventura",
    cityCode: "FUE",
    cityName: "Puerto del Rosario",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "FUG",
    airportName: "Fuyang",
    cityCode: "FUG",
    cityName: "Fuyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "FUJ",
    airportName: "Fukue",
    cityCode: "FUJ",
    cityName: "Fukue",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "FUK",
    airportName: "Fukuoka",
    cityCode: "FUK",
    cityName: "Fukuoka",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "FUL",
    airportName: "Municipal",
    cityCode: "FUL",
    cityName: "Fullerton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FUM",
    airportName: "Fuma",
    cityCode: "FUM",
    cityName: "Fuma",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "FUN",
    airportName: "Intl.",
    cityCode: "FUN",
    cityName: "Funafuti Atol",
    countryName: "Tuvalu",
    countryCode: "TV",
  },
  {
    airportCode: "FUT",
    airportName: "Futuna Island",
    cityCode: "FUT",
    cityName: "Futuna Island",
    countryName: "Wallis and Futuna Islands",
    countryCode: "WF",
  },
  {
    airportCode: "FVL",
    airportName: "Flora Valey",
    cityCode: "FVL",
    cityName: "Flora Valley",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FVR",
    airportName: "Forrest River Arpt.",
    cityCode: "FVR",
    cityName: "Forrest River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "FWA",
    airportName: "Municipal/Baer Field",
    cityCode: "FWA",
    cityName: "Fort Wayne",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FWH",
    airportName: "Fort Worth NAS Jrb/Carswell Field",
    cityCode: "FWH",
    cityName: "Fort Worth, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FWL",
    airportName: "Farewell",
    cityCode: "FWL",
    cityName: "Farewell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FWM",
    airportName: "Heliport",
    cityCode: "FWM",
    cityName: "Fort William",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "FXE",
    airportName: "Executive",
    cityCode: "FXE",
    cityName: "Fort Lauderdale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FXM",
    airportName: "Flaxman Island",
    cityCode: "FXM",
    cityName: "Flaxman Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FXO",
    airportName: "Cuamba",
    cityCode: "FXO",
    cityName: "Cuamba",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "FXY",
    airportName: "Municipal",
    cityCode: "FXY",
    cityName: "Forest City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FYM",
    airportName: "Municipal",
    cityCode: "FYM",
    cityName: "Fayetteville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FYN",
    airportName: "Fuyun",
    cityCode: "FYN",
    cityName: "Fuyun",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "FYT",
    airportName: "Faya",
    cityCode: "FYT",
    cityName: "Faya",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "FYU",
    airportName: "Fort Yukon",
    cityCode: "FYU",
    cityName: "Fort Yukon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FYV",
    airportName: "Municipal",
    cityCode: "FYV",
    cityName: "Fayetteville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "FZO",
    airportName: "Filton",
    cityCode: "FZO",
    cityName: "Bristol",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "GAA",
    airportName: "Guamal",
    cityCode: "GAA",
    cityName: "Guamal",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "GAB",
    airportName: "Gabbs",
    cityCode: "GAB",
    cityName: "Gabbs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GAC",
    airportName: "Gracias",
    cityCode: "GAC",
    cityName: "Gracias",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "GAD",
    airportName: "Municipal",
    cityCode: "GAD",
    cityName: "Gadsden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GAE",
    airportName: "Gabes",
    cityCode: "GAE",
    cityName: "Gabes",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "GAF",
    airportName: "Gafsa",
    cityCode: "GAF",
    cityName: "Gafsa",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "GAG",
    airportName: "Gage",
    cityCode: "GAG",
    cityName: "Gage",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GAH",
    airportName: "Gayndah",
    cityCode: "GAH",
    cityName: "Gayndah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GAI",
    airportName: "Montgomery County",
    cityCode: "GAI",
    cityName: "Gaithersburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GAJ",
    airportName: "Junmachi",
    cityCode: "GAJ",
    cityName: "Yamagata",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "GAK",
    airportName: "Gakona",
    cityCode: "GAK",
    cityName: "Gakona",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GAL",
    airportName: "Galena",
    cityCode: "GAL",
    cityName: "Galena",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GAM",
    airportName: "Gambell",
    cityCode: "GAM",
    cityName: "Gambell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GAN",
    airportName: "Gan Arpt.",
    cityCode: "GAN",
    cityName: "Gan Island",
    countryName: "Maldives",
    countryCode: "MV",
  },
  {
    airportCode: "GAO",
    airportName: "Los Canos",
    cityCode: "GAO",
    cityName: "Guantanamo",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "GAP",
    airportName: "Gusap",
    cityCode: "GAP",
    cityName: "Gusap",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GAR",
    airportName: "Garaina",
    cityCode: "GAR",
    cityName: "Garaina",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GAS",
    airportName: "Garissa",
    cityCode: "GAS",
    cityName: "Garissa",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "GAT",
    airportName: "Tallard",
    cityCode: "GAT",
    cityName: "Gap",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "GAU",
    airportName: "Lokpriya Gopinath Bordoloi International",
    cityCode: "GAU",
    cityName: "Gawahati",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "GAV",
    airportName: "Gag Island",
    cityCode: "GAV",
    cityName: "Gag Island",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "GAW",
    airportName: "Gangaw",
    cityCode: "GAW",
    cityName: "Gangaw",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "GAX",
    airportName: "Gamba",
    cityCode: "GAX",
    cityName: "Gamba",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "GAY",
    airportName: "Gaya",
    cityCode: "GAY",
    cityName: "Gaya",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "GAZ",
    airportName: "Guasopa",
    cityCode: "GAZ",
    cityName: "Guasopa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GBA",
    airportName: "Big Bay",
    cityCode: "GBA",
    cityName: "Big Bay",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "GBB",
    airportName: "Gara Djebilet",
    cityCode: "GBB",
    cityName: "Gara Djebilet",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "GBC",
    airportName: "Gasuke",
    cityCode: "GBC",
    cityName: "Gasuke",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GBD",
    airportName: "Great Bend",
    cityCode: "GBD",
    cityName: "Great Bend",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GBE",
    airportName: "Sir Seretse Khama Intl.",
    cityCode: "GBE",
    cityName: "Gaborone",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "GBF",
    airportName: "Negarbo",
    cityCode: "GBF",
    cityName: "Negarbo",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GBG",
    airportName: "Galesburg",
    cityCode: "GBG",
    cityName: "Galesburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GBH",
    airportName: "Galbraith Lake",
    cityCode: "GBH",
    cityName: "Galbraith Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GBI",
    airportName: "Auxiliary Airfield",
    cityCode: "GBI",
    cityName: "Grand Bahama",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "GBJ",
    airportName: "Les Bases",
    cityCode: "GBJ",
    cityName: "Marie Galante",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "GBK",
    airportName: "Gbangbatok",
    cityCode: "GBK",
    cityName: "Gbangbatok",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "GBL",
    airportName: "Goulburn Island",
    cityCode: "GBL",
    cityName: "Goulburn Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GBM",
    airportName: "Garbaharey",
    cityCode: "GBM",
    cityName: "Garbaharey",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "GBN",
    airportName: "San Giovanni Rotondo",
    cityCode: "GBN",
    cityName: "San Giovanni Rotondo",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "GBO",
    airportName: "Baltimore Greenbelt T",
    cityCode: "GBO",
    cityName: "Baltimore",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GBP",
    airportName: "Gamboola",
    cityCode: "GBP",
    cityName: "Gamboola",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GBQ",
    airportName: "Muharraq",
    cityCode: "GBQ",
    cityName: "Muharraq",
    countryName: "Bahrain",
    countryCode: "BH",
  },
  {
    airportCode: "GBR",
    airportName: "Walter J. Koladza Arpt.",
    cityCode: "GBR",
    cityName: "Great Barrington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GBS",
    airportName: "Port Fitzroy",
    cityCode: "GBS",
    cityName: "Port Fitzroy",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "GBT",
    airportName: "Gorgan",
    cityCode: "GBT",
    cityName: "Gorgan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "GBU",
    airportName: "Khashm El Girba",
    cityCode: "GBU",
    cityName: "Khashm El Girba",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "GBV",
    airportName: "Gibb River",
    cityCode: "GBV",
    cityName: "Gibb River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GBZ",
    airportName: "Great Barrier Island",
    cityCode: "GBZ",
    cityName: "Great Barrier Island",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "GCA",
    airportName: "Guacamaya",
    cityCode: "GCA",
    cityName: "Guacamaya",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "GCC",
    airportName: "Campbell County",
    cityCode: "GCC",
    cityName: "Gillette",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GCI",
    airportName: "Guernsey",
    cityCode: "GCI",
    cityName: "Guernsey",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "GCJ",
    airportName: "Grand Central",
    cityCode: "GCJ",
    cityName: "Johannesburg",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "GCK",
    airportName: "Garden City Municipal Arpt.",
    cityCode: "GCK",
    cityName: "Garden City, KS",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GCM",
    airportName: "Owen Roberts Intl.",
    cityCode: "GCM",
    cityName: "Georgetown",
    countryName: "Cayman Islands",
    countryCode: "KY",
  },
  {
    airportCode: "GCN",
    airportName: "National Park",
    cityCode: "GCN",
    cityName: "Grand Canyon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GCV",
    airportName: "Gravatai",
    cityCode: "GCV",
    cityName: "Gravatai",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GCY",
    airportName: "Municipal",
    cityCode: "GCY",
    cityName: "Greenville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GDA",
    airportName: "Gounda",
    cityCode: "GDA",
    cityName: "Gounda",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "GDD",
    airportName: "Gordon Downs",
    cityCode: "GDD",
    cityName: "Gordon Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GDE",
    airportName: "Gode/Iddidole",
    cityCode: "GDE",
    cityName: "Gode/Iddidole",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "GDG",
    airportName: "Magdagachi",
    cityCode: "GDG",
    cityName: "Magdagachi",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "GDH",
    airportName: "Golden Horn Lodge SPB",
    cityCode: "GDH",
    cityName: "Golden Horn Lodge, Alaska",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GDI",
    airportName: "Gordil",
    cityCode: "GDI",
    cityName: "Gordil",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "GDJ",
    airportName: "Gandajika",
    cityCode: "GDJ",
    cityName: "Gandajika",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "GDL",
    airportName: "Don Miguel Hidalgo y Costilla Intl.",
    cityCode: "GDL",
    cityName: "Guadalajara",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "GDM",
    airportName: "Municipal",
    cityCode: "GDM",
    cityName: "Gardner",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GDN",
    airportName: "Lech Walesa",
    cityCode: "GDN",
    cityName: "Gdansk",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "GDO",
    airportName: "Vare Maria",
    cityCode: "GDO",
    cityName: "Guasdualito",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "GDP",
    airportName: "Guadalupe",
    cityCode: "GDP",
    cityName: "Guadalupe",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GDQ",
    airportName: "Gondar",
    cityCode: "GDQ",
    cityName: "Gondar",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "GDT",
    airportName: "Grand Turk Is",
    cityCode: "GDT",
    cityName: "Grand Turk Is",
    countryName: "Turks and Caicos Islands",
    countryCode: "TC",
  },
  {
    airportCode: "GDV",
    airportName: "Dawson Community",
    cityCode: "GDV",
    cityName: "Glendive",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GDW",
    airportName: "Gladwin",
    cityCode: "GDW",
    cityName: "Gladwin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GDX",
    airportName: "Magadan",
    cityCode: "GDX",
    cityName: "Magadan",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "GDZ",
    airportName: "Gelendzik",
    cityCode: "GDZ",
    cityName: "Gelendzik",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "GEA",
    airportName: "Magenta",
    cityCode: "GEA",
    cityName: "Noumea",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "GEB",
    airportName: "Gebe",
    cityCode: "GEB",
    cityName: "Gebe",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "GEC",
    airportName: "Gecitkale",
    cityCode: "GEC",
    cityName: "Gecitkale",
    countryName: "Cyprus",
    countryCode: "CY",
  },
  {
    airportCode: "GED",
    airportName: "Sussex County",
    cityCode: "GED",
    cityName: "Georgetown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GEE",
    airportName: "George Town",
    cityCode: "GEE",
    cityName: "George Town",
    countryName: "Tasmania",
    countryCode: "AU",
  },
  {
    airportCode: "GEF",
    airportName: "Geva Airstrip",
    cityCode: "GEF",
    cityName: "Geva",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "GEG",
    airportName: "Spokane Intl.",
    cityCode: "GEG",
    cityName: "Spokane",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GEI",
    airportName: "Green Islands",
    cityCode: "GEI",
    cityName: "Green Islands",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GEK",
    airportName: "Ganes Creek",
    cityCode: "GEK",
    cityName: "Ganes Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GEL",
    airportName: "Sepe Tiaraju",
    cityCode: "GEL",
    cityName: "Santo Angelo",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "HDG",
    airportName: "Handan",
    cityCode: "HDG",
    cityName: "Handan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "GEO",
    airportName: "Cheddi Jagan Intl.",
    cityCode: "GEO",
    cityName: "Georgetown",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "GER",
    airportName: "Rafael Cabrera",
    cityCode: "GER",
    cityName: "Nueva Gerona",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "GES",
    airportName: "General Santos Intl.",
    cityCode: "GES",
    cityName: "General Santos",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "GET",
    airportName: "Geraldton",
    cityCode: "GET",
    cityName: "Geraldton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GEV",
    airportName: "Gallivare",
    cityCode: "GEV",
    cityName: "Gallivare",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "GEW",
    airportName: "Gewoia",
    cityCode: "GEW",
    cityName: "Gewoia",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GEX",
    airportName: "Geelong",
    cityCode: "GEX",
    cityName: "Geelong",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GEY",
    airportName: "South Big Horn County",
    cityCode: "GEY",
    cityName: "Greybull",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GFA",
    airportName: "Malmstrom AFB",
    cityCode: "GFA",
    cityName: "Great Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GFB",
    airportName: "Togiak Fish",
    cityCode: "GFB",
    cityName: "Togiak Fish",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GFD",
    airportName: "Pope Field",
    cityCode: "GFD",
    cityName: "Greenfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GFE",
    airportName: "Grenfell",
    cityCode: "GFE",
    cityName: "Grenfell",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GFF",
    airportName: "Griffith",
    cityCode: "GFF",
    cityName: "Griffith",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GFK",
    airportName: "Grand Forks",
    cityCode: "GFK",
    cityName: "Grand Forks",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GFL",
    airportName: "Floyd Bennett Memorial Airport",
    cityCode: "GFL",
    cityName: "Glens Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GFN",
    airportName: "Grafton",
    cityCode: "GFN",
    cityName: "Grafton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GFO",
    airportName: "Bartica",
    cityCode: "GFO",
    cityName: "Bartica",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "GFR",
    airportName: "Granville",
    cityCode: "GFR",
    cityName: "Granville",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "GFY",
    airportName: "Grootfontein",
    cityCode: "GFY",
    cityName: "Grootfontein",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "GGC",
    airportName: "Lumbala",
    cityCode: "GGC",
    cityName: "Lumbala",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "GGD",
    airportName: "Gregory Downs",
    cityCode: "GGD",
    cityName: "Gregory Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GGE",
    airportName: "Georgetown",
    cityCode: "GGE",
    cityName: "Georgetown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GGG",
    airportName: "Kilgore",
    cityCode: "GGG",
    cityName: "Kilgore",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GGL",
    airportName: "Gilgal",
    cityCode: "GGL",
    cityName: "Gilgal",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "GGN",
    airportName: "Gagnoa",
    cityCode: "GGN",
    cityName: "Gagnoa",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "GGO",
    airportName: "Guiglo",
    cityCode: "GGO",
    cityName: "Guiglo",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "GGR",
    airportName: "Garoe",
    cityCode: "GGR",
    cityName: "Garoe",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "GGS",
    airportName: "Gobernador Gregores",
    cityCode: "GGS",
    cityName: "Gobernador Gregores",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "GGT",
    airportName: "Exuma Intl.",
    cityCode: "GGT",
    cityName: "George Town",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "GGW",
    airportName: "Intl.",
    cityCode: "GGW",
    cityName: "Glasgow",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GHA",
    airportName: "Noumerate",
    cityCode: "GHA",
    cityName: "Ghardaia",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "GHB",
    airportName: "Governors Harbour",
    cityCode: "GHB",
    cityName: "Governors Harbour",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "GHC",
    airportName: "Great Harbour",
    cityCode: "GHC",
    cityName: "Great Harbour",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "GHD",
    airportName: "Ghimbi",
    cityCode: "GHD",
    cityName: "Ghimbi",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "GHE",
    airportName: "Garachine",
    cityCode: "GHE",
    cityName: "Garachine",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "GHF",
    airportName: "Giebelstadt",
    cityCode: "GHF",
    cityName: "Giebelstadt",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "GHK",
    airportName: "Gush Katif",
    cityCode: "GHK",
    cityName: "Gush Katif",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "GHM",
    airportName: "Municipal",
    cityCode: "GHM",
    cityName: "Centerville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GHN",
    airportName: "Guanghan",
    cityCode: "GHN",
    cityName: "Guanghan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "GHT",
    airportName: "Ghat",
    cityCode: "GHT",
    cityName: "Ghat",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "GHU",
    airportName: "Gualeguaychu",
    cityCode: "GHU",
    cityName: "Gualeguaychu",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "GIB",
    airportName: "North Front",
    cityCode: "GIB",
    cityName: "Gibraltar",
    countryName: "Gibraltar",
    countryCode: "GI",
  },
  {
    airportCode: "GIC",
    airportName: "Boigu Island",
    cityCode: "GIC",
    cityName: "Boigu Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GID",
    airportName: "Gitega",
    cityCode: "GID",
    cityName: "Gitega",
    countryName: "Burundi",
    countryCode: "BI",
  },
  {
    airportCode: "GIF",
    airportName: "Gilbert Field",
    cityCode: "GIF",
    cityName: "Winter Haven",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GIG",
    airportName: "Galeao Antonio Carlos Jobim Intl. Airpt.",
    cityCode: "RIO",
    cityName: "Rio De Janeiro",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GII",
    airportName: "Siguiri",
    cityCode: "GII",
    cityName: "Siguiri",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "GIL",
    airportName: "Gilgit",
    cityCode: "GIL",
    cityName: "Gilgit",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "GIM",
    airportName: "Miele Mimbale",
    cityCode: "GIM",
    cityName: "Miele Mimbale",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "GIR",
    airportName: "Girardot",
    cityCode: "GIR",
    cityName: "Girardot",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "GIS",
    airportName: "Gisborne",
    cityCode: "GIS",
    cityName: "Gisborne",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "GIT",
    airportName: "Geita",
    cityCode: "GIT",
    cityName: "Geita",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "GIU",
    airportName: "Sigiriya Slaf Base",
    cityCode: "GIU",
    cityName: "Sigiriya",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "GIZ",
    airportName: "Jazan",
    cityCode: "GIZ",
    cityName: "Jazan",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "GJA",
    airportName: "Guanaja",
    cityCode: "GJA",
    cityName: "Guanaja",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "GJL",
    airportName: "Jijel",
    cityCode: "GJL",
    cityName: "Jijel",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "GJM",
    airportName: "Guajara-Mirim",
    cityCode: "GJM",
    cityName: "Guajara-Mirim",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GJR",
    airportName: "Gjogur",
    cityCode: "GJR",
    cityName: "Gjogur",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "GJT",
    airportName: "Walker Field",
    cityCode: "GJT",
    cityName: "Grand Junction",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GKA",
    airportName: "Goroka",
    cityCode: "GKA",
    cityName: "Goroka",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GKE",
    airportName: "Geilenkirchen",
    cityCode: "GKE",
    cityName: "Geilenkirchen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "GKH",
    airportName: "Gorkha",
    cityCode: "GKH",
    cityName: "Gorkha",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "GKL",
    airportName: "Great Keppel Island",
    cityCode: "GKL",
    cityName: "Great Keppel Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GKN",
    airportName: "Gulkana",
    cityCode: "GKN",
    cityName: "Gulkana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GKO",
    airportName: "Kongoboumba",
    cityCode: "GKO",
    cityName: "Kongoboumba",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "GKT",
    airportName: "Gatlinburg",
    cityCode: "GKT",
    cityName: "Gatlinburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ISU",
    airportName: "Sulaimaniyah International",
    cityCode: "ISU",
    cityName: "Sulaymaniyah",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "GLC",
    airportName: "Geladi",
    cityCode: "GLC",
    cityName: "Geladi",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "GLD",
    airportName: "Renner Field",
    cityCode: "GLD",
    cityName: "Goodland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GLE",
    airportName: "Municipal",
    cityCode: "GLE",
    cityName: "Gainesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GLF",
    airportName: "Golfito",
    cityCode: "GLF",
    cityName: "Golfito",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "GLG",
    airportName: "Glengyle",
    cityCode: "GLG",
    cityName: "Glengyle",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GLH",
    airportName: "Greenville",
    cityCode: "GLH",
    cityName: "Greenville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GLI",
    airportName: "Glen Innes",
    cityCode: "GLI",
    cityName: "Glen Innes",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GLK",
    airportName: "Galcaio",
    cityCode: "GLK",
    cityName: "Galcaio",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "GLL",
    airportName: "Klanten Arpt",
    cityCode: "GLL",
    cityName: "Gol",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "GLM",
    airportName: "Glenormiston",
    cityCode: "GLM",
    cityName: "Glenormiston",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GLN",
    airportName: "Goulimime",
    cityCode: "GLN",
    cityName: "Goulimime",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "GLO",
    airportName: "Gloucestershire",
    cityCode: "GLO",
    cityName: "Gloucester",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "GLP",
    airportName: "Gulgubip",
    cityCode: "GLP",
    cityName: "Gulgubip",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GLQ",
    airportName: "Glennallen",
    cityCode: "GLQ",
    cityName: "Glennallen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GLR",
    airportName: "Otsego County",
    cityCode: "GLR",
    cityName: "Gaylord",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GLS",
    airportName: "Scholes Field",
    cityCode: "GLS",
    cityName: "Galveston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GLT",
    airportName: "Gladstone",
    cityCode: "GLT",
    cityName: "Gladstone",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GLV",
    airportName: "Golovin",
    cityCode: "GLV",
    cityName: "Golovin",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GLW",
    airportName: "Municipal",
    cityCode: "GLW",
    cityName: "Glasgow",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GLX",
    airportName: "Galela",
    cityCode: "GLX",
    cityName: "Galela",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "GLY",
    airportName: "Goldsworthy",
    cityCode: "GLY",
    cityName: "Goldsworthy",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "GLZ",
    airportName: "Gilze-Rijen",
    cityCode: "GLZ",
    cityName: "Breda",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "GMA",
    airportName: "Gemena",
    cityCode: "GMA",
    cityName: "Gemena",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "GMB",
    airportName: "Gambela",
    cityCode: "GMB",
    cityName: "Gambela",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "GMC",
    airportName: "Guerima",
    cityCode: "GMC",
    cityName: "Guerima",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "GME",
    airportName: "Gomel",
    cityCode: "GME",
    cityName: "Gomel",
    countryName: "Belarus",
    countryCode: "BY",
  },
  {
    airportCode: "GMI",
    airportName: "Gasmata Island",
    cityCode: "GMI",
    cityName: "Gasmata Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "GMM",
    airportName: "Gamboma",
    cityCode: "GMM",
    cityName: "Gamboma",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "GMN",
    airportName: "Greymouth",
    cityCode: "GMN",
    cityName: "Greymouth",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "GMP",
    airportName: "Gimpo Intl.",
    cityCode: "SEL",
    cityName: "Seoul",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "GMR",
    airportName: "Gambier Is",
    cityCode: "GMR",
    cityName: "Gambier Is",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "GMS",
    airportName: "Guimaraes",
    cityCode: "GMS",
    cityName: "Guimaraes",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "GMT",
    airportName: "Granite Mountain",
    cityCode: "GMT",
    cityName: "Granite Mountain",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "GMU",
    airportName: "Greenville Downtown",
    cityCode: "GMU",
    cityName: "Greenville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XLB",
    airportName: "Lac Brochet",
    cityCode: "XLB",
    cityName: "Lac Brochet",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XLF",
    airportName: "Leaf Bay",
    cityCode: "XLF",
    cityName: "Leaf Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XLJ",
    airportName: "Quebec Stn. Rail Svce.",
    cityCode: "XLJ",
    cityName: "Quebec",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XLM",
    airportName: "St Lambert Rail Svce.",
    cityCode: "XLM",
    cityName: "Montreal",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XLO",
    airportName: "Long Xuyen",
    cityCode: "XLO",
    cityName: "Long Xuyen",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "XLS",
    airportName: "St Louis",
    cityCode: "XLS",
    cityName: "St Louis",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "XLU",
    airportName: "Leo",
    cityCode: "XLU",
    cityName: "Leo",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XLW",
    airportName: "Lemwerder",
    cityCode: "XLW",
    cityName: "Lemwerder",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "XMA",
    airportName: "Maramag",
    cityCode: "XMA",
    cityName: "Maramag",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "XMC",
    airportName: "Mallacoota",
    cityCode: "XMC",
    cityName: "Mallacoota",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XMD",
    airportName: "Madison",
    cityCode: "XMD",
    cityName: "Madison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XMG",
    airportName: "Mahendranagar",
    cityCode: "XMG",
    cityName: "Mahendranagar",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "XMH",
    airportName: "Manihi",
    cityCode: "XMH",
    cityName: "Manihi",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "XMI",
    airportName: "Masasi",
    cityCode: "XMI",
    cityName: "Masasi",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "XML",
    airportName: "Minlaton",
    cityCode: "XML",
    cityName: "Minlaton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XMN",
    airportName: "Xiamen",
    cityCode: "XMN",
    cityName: "Xiamen",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XMP",
    airportName: "Macmillan Pass",
    cityCode: "XMP",
    cityName: "Macmillan Pass",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XMS",
    airportName: "Macas",
    cityCode: "XMS",
    cityName: "Macas",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "XMY",
    airportName: "Yam Island",
    cityCode: "XMY",
    cityName: "Yam Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XNA",
    airportName: "Northwest Arkansas Regional",
    cityCode: "XNA",
    cityName: "Fayetteville, AR",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XNG",
    airportName: "Quang Ngai",
    cityCode: "XNG",
    cityName: "Quang Ngai",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "XNN",
    airportName: "Xining",
    cityCode: "XNN",
    cityName: "Xining",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XNT",
    airportName: "Xingtai",
    cityCode: "XNT",
    cityName: "Xingtai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XNU",
    airportName: "Nouna",
    cityCode: "XNU",
    cityName: "Nouna",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XOZ",
    airportName: "Doha Free Zone",
    cityCode: "XOZ",
    cityName: "Doha",
    countryName: "Qatar",
    countryCode: "QA",
  },
  {
    airportCode: "XPA",
    airportName: "Pama",
    cityCode: "XPA",
    cityName: "Pama",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XPG",
    airportName: "Railway Station",
    cityCode: "XPG",
    cityName: "Paris Gare D Nord",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XPJ",
    airportName: "Montpellier Railway",
    cityCode: "XPJ",
    cityName: "Montpellier",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XPK",
    airportName: "Pukatawagan",
    cityCode: "XPK",
    cityName: "Pukatawagan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XPL",
    airportName: "Coronel Enrique Soto Cano AB",
    cityCode: "XPL",
    cityName: "Comayagua",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "XPP",
    airportName: "Poplar River",
    cityCode: "XPP",
    cityName: "Poplar River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XPQ",
    airportName: "Ferry",
    cityCode: "XPQ",
    cityName: "Port Klang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "XPR",
    airportName: "Pine Ridge",
    cityCode: "XPR",
    cityName: "Pine Ridge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XPU",
    airportName: "West Kuparuk",
    cityCode: "XPU",
    cityName: "West Kuparuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XPZ",
    airportName: "Harbour",
    cityCode: "XPZ",
    cityName: "Saint Tropez",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XQC",
    airportName: "Balad",
    cityCode: "XQC",
    cityName: "Balad",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "XQH",
    airportName: "Railway Station",
    cityCode: "XQH",
    cityName: "Nottingham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "XQI",
    airportName: "Railway Station",
    cityCode: "XQI",
    cityName: "Nottingham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "XQP",
    airportName: "Quepos",
    cityCode: "XQP",
    cityName: "Pavones",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "XQU",
    airportName: "Qualicum",
    cityCode: "XQU",
    cityName: "Qualicum",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XQV",
    airportName: "Baqubah",
    cityCode: "XQV",
    cityName: "Baqubah",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "XRF",
    airportName: "Marseille Railway",
    cityCode: "XRF",
    cityName: "Marseille",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XRH",
    airportName: "Richmond AFB",
    cityCode: "XRH",
    cityName: "Richmond",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XRJ",
    airportName: "Termini Rail Stn.",
    cityCode: "ROM",
    cityName: "Rome",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "XRR",
    airportName: "Ross River",
    cityCode: "XRR",
    cityName: "Ross River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XRY",
    airportName: "Jerez De La Frontera",
    cityCode: "XRY",
    cityName: "Jerez De La Frontera",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "XSC",
    airportName: "Intl.",
    cityCode: "XSC",
    cityName: "South Caicos",
    countryName: "Turks and Caicos Islands",
    countryCode: "TC",
  },
  {
    airportCode: "XSD",
    airportName: "Test Range",
    cityCode: "XSD",
    cityName: "Tonopah",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XSE",
    airportName: "Sebba",
    cityCode: "XSE",
    cityName: "Sebba",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XSI",
    airportName: "South Indian Lake",
    cityCode: "XSI",
    cityName: "South Indian Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XSM",
    airportName: "St Marys",
    cityCode: "XSM",
    cityName: "St Marys",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XSO",
    airportName: "Siocon",
    cityCode: "XSO",
    cityName: "Siocon",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "XSP",
    airportName: "Seletar",
    cityCode: "SIN",
    cityName: "Singapore",
    countryName: "Singapore",
    countryCode: "SG",
  },
  {
    airportCode: "XTA",
    airportName: "Econ/Tech Dev. Area",
    cityCode: "XTA",
    cityName: "Tianjin",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XTF",
    airportName: "Port Free Trade Zone",
    cityCode: "XTF",
    cityName: "Tianjin",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XTG",
    airportName: "Thargomindah",
    cityCode: "XTG",
    cityName: "Thargomindah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XTL",
    airportName: "Tadoule Lake",
    cityCode: "XTL",
    cityName: "Tadoule Lake, MB",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "XTM",
    airportName: "Ramadi",
    cityCode: "XTM",
    cityName: "Ramadi",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "XTO",
    airportName: "Taroom",
    cityCode: "XTO",
    cityName: "Taroom",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XTR",
    airportName: "Tara",
    cityCode: "XTR",
    cityName: "Tara",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "XTV",
    airportName: "Tikrit",
    cityCode: "XTV",
    cityName: "Tikrit",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "XUV",
    airportName: "Storekvina Railway",
    cityCode: "XUV",
    cityName: "Storekvina",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "XUW",
    airportName: "Storen Railway",
    cityCode: "XUW",
    cityName: "Storen",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "XUZ",
    airportName: "Xuzhou",
    cityCode: "XUZ",
    cityName: "Xuzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XVL",
    airportName: "Vinh Long",
    cityCode: "XVL",
    cityName: "Vinh Long",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "XVQ",
    airportName: "Santa Lucia Rail",
    cityCode: "XVQ",
    cityName: "Venice",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "XVY",
    airportName: "Mestre Rail Stn.",
    cityCode: "ROM",
    cityName: "Rome",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "XWC",
    airportName: "Suedbahnhof Rail",
    cityCode: "XWC",
    cityName: "Vienna",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "XWK",
    airportName: "Karlskrona Rail Svc.",
    cityCode: "XWK",
    cityName: "Ronneby",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XWL",
    airportName: "Gothenburg Rail",
    cityCode: "XWL",
    cityName: "Gothenburg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XWP",
    airportName: "Hassleholm",
    cityCode: "XWP",
    cityName: "Hassleholm",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XWQ",
    airportName: "Enkoping",
    cityCode: "XWQ",
    cityName: "Enkoping",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XWS",
    airportName: "Railway Station",
    cityCode: "XWS",
    cityName: "Swindon",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "XWW",
    airportName: "Westbahnhof Rail",
    cityCode: "XWW",
    cityName: "Vienna",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "XWZ",
    airportName: "Nykoping",
    cityCode: "XWZ",
    cityName: "Nykoping",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XXJ",
    airportName: "Deli Railway",
    cityCode: "XXJ",
    cityName: "Budapest",
    countryName: "Hungary",
    countryCode: "HU",
  },
  {
    airportCode: "XXK",
    airportName: "Katrineholm",
    cityCode: "XXK",
    cityName: "Katrineholm",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XXU",
    airportName: "Hedemora",
    cityCode: "XXU",
    cityName: "Hedemora",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYA",
    airportName: "Yandina",
    cityCode: "XYA",
    cityName: "Yandina",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "XYB",
    airportName: "Borlange Railway Svc.",
    cityCode: "XYB",
    cityName: "Borlange/Falun",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYC",
    airportName: "Herrljunga",
    cityCode: "XYC",
    cityName: "Herrljunga",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYD",
    airportName: "Lyon Part-dieu Rail Sv",
    cityCode: "XYD",
    cityName: "Lyon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XYE",
    airportName: "Ye",
    cityCode: "XYE",
    cityName: "Ye",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "XYH",
    airportName: "Helsingborg Railway",
    cityCode: "XYH",
    cityName: "Angelholm/Helsingborg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYI",
    airportName: "Flen",
    cityCode: "XYI",
    cityName: "Flen",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYL",
    airportName: "Lyon Perrache Rail Svc",
    cityCode: "XYL",
    cityName: "Lyon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XYM",
    airportName: "Falkenberg Rail",
    cityCode: "XYM",
    cityName: "Falkenberg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYN",
    airportName: "Kristinehamn Rail",
    cityCode: "XYN",
    cityName: "Kristinehamn",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYO",
    airportName: "Karlshamn",
    cityCode: "XYO",
    cityName: "Karlshamn",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYQ",
    airportName: "Angelholm Railway Svc.",
    cityCode: "XYQ",
    cityName: "Angelholm/Helsingborg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYR",
    airportName: "Yellow River",
    cityCode: "XYR",
    cityName: "Yellow River",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "XYT",
    airportName: "Montaudran",
    cityCode: "XYT",
    cityName: "Toulouse",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XYU",
    airportName: "Solvesborg",
    cityCode: "XYU",
    cityName: "Solvesborg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XYV",
    airportName: "Lyon Satolas Rail Svc",
    cityCode: "XYV",
    cityName: "Lyon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XYX",
    airportName: "Sala",
    cityCode: "XYX",
    cityName: "Sala",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XZA",
    airportName: "Zabre",
    cityCode: "XZA",
    cityName: "Zabre",
    countryName: "Burkina Faso",
    countryCode: "BF",
  },
  {
    airportCode: "XZE",
    airportName: "Off line Point",
    cityCode: "XZE",
    cityName: "Rail",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "XZF",
    airportName: "Dammam Port",
    cityCode: "XZF",
    cityName: "Dammam",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "XZJ",
    airportName: "Off line Point",
    cityCode: "XZJ",
    cityName: "Rail",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "XZN",
    airportName: "Avignon Railway",
    cityCode: "XZN",
    cityName: "Avignon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "XZQ",
    airportName: "Pass Rail",
    cityCode: "XZQ",
    cityName: "Rail",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "XZU",
    airportName: "Off line Point",
    cityCode: "XZU",
    cityName: "Rail",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XZX",
    airportName: "Off line Point",
    cityCode: "XZX",
    cityName: "Rail",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "YAA",
    airportName: "Anahim Lake",
    cityCode: "YAA",
    cityName: "Anahim Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAB",
    airportName: "Arctic Bay",
    cityCode: "YAB",
    cityName: "Arctic Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAC",
    airportName: "Cat Lake",
    cityCode: "YAC",
    cityName: "Cat Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAE",
    airportName: "Alta Lake",
    cityCode: "YAE",
    cityName: "Alta Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAF",
    airportName: "Asbestos Hill",
    cityCode: "YAF",
    cityName: "Asbestos Hill",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAG",
    airportName: "Municipal",
    cityCode: "YAG",
    cityName: "Fort Frances",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAH",
    airportName: "Lagrande 4",
    cityCode: "YAH",
    cityName: "Lagrande 4",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAI",
    airportName: "Chillan",
    cityCode: "YAI",
    cityName: "Chillan",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "YAJ",
    airportName: "Lyall Harbour",
    cityCode: "YAJ",
    cityName: "Lyall Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAK",
    airportName: "Yakutat",
    cityCode: "YAK",
    cityName: "Yakutat",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "YAL",
    airportName: "Alert Bay",
    cityCode: "YAL",
    cityName: "Alert Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAM",
    airportName: "Sault Ste Marie",
    cityCode: "YAM",
    cityName: "Sault Ste Marie",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAN",
    airportName: "Yangambi",
    cityCode: "YAN",
    cityName: "Yangambi",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "YAO",
    airportName: "Yaounde Arpt.",
    cityCode: "YAO",
    cityName: "Yaounde",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "YAP",
    airportName: "Yap Intl.",
    cityCode: "YAP",
    cityName: "Yap, Caroline Islands",
    countryName: "Federated States of Micronesia",
    countryCode: "FM",
  },
  {
    airportCode: "YAQ",
    airportName: "Maple Bay",
    cityCode: "YAQ",
    cityName: "Maple Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAR",
    airportName: "Lagrande 3",
    cityCode: "YAR",
    cityName: "Lagrande 3",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAS",
    airportName: "Yasawa Island",
    cityCode: "YAS",
    cityName: "Yasawa Island",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "YAT",
    airportName: "Attawapiskat",
    cityCode: "YAT",
    cityName: "Attawapiskat",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAU",
    airportName: "Kattiniq/Donaldson",
    cityCode: "YAU",
    cityName: "Kattiniq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAV",
    airportName: "Miners Bay",
    cityCode: "YAV",
    cityName: "Miners Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAW",
    airportName: "Shearwater",
    cityCode: "YAW",
    cityName: "Halifax",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAX",
    airportName: "Angling Lake",
    cityCode: "YAX",
    cityName: "Angling Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAY",
    airportName: "St Anthony",
    cityCode: "YAY",
    cityName: "St Anthony",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YAZ",
    airportName: "Tofino Arpt.",
    cityCode: "YAZ",
    cityName: "Tofino, BC",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBA",
    airportName: "Banff",
    cityCode: "YBA",
    cityName: "Banff",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBB",
    airportName: "Kugaaruk",
    cityCode: "YBB",
    cityName: "Kugaaruk",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBC",
    airportName: "Baie Comeau",
    cityCode: "YBC",
    cityName: "Baie Comeau",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBD",
    airportName: "New Westminster",
    cityCode: "YBD",
    cityName: "New Westminster",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBE",
    airportName: "Uranium City",
    cityCode: "YBE",
    cityName: "Uranium City",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBF",
    airportName: "Bamfield",
    cityCode: "YBF",
    cityName: "Bamfield",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBG",
    airportName: "Bagotville",
    cityCode: "YBG",
    cityName: "Bagotville",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBH",
    airportName: "Bull Harbour",
    cityCode: "YBH",
    cityName: "Bull Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBI",
    airportName: "Black Tickle",
    cityCode: "YBI",
    cityName: "Black Tickle",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBJ",
    airportName: "Baie Johan Beetz",
    cityCode: "YBJ",
    cityName: "Baie Johan Beetz",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBK",
    airportName: "Baker Lake",
    cityCode: "YBK",
    cityName: "Baker Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBL",
    airportName: "Campbell River",
    cityCode: "YBL",
    cityName: "Campbell River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBM",
    airportName: "Bronson Creek",
    cityCode: "YBM",
    cityName: "Bronson Creek",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBN",
    airportName: "Borden",
    cityCode: "YBN",
    cityName: "Borden",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBP",
    airportName: "Yibin",
    cityCode: "YBP",
    cityName: "Yibin",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YBQ",
    airportName: "Telegraph Harbour",
    cityCode: "YBQ",
    cityName: "Telegraph Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBR",
    airportName: "Brandon",
    cityCode: "YBR",
    cityName: "Brandon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBS",
    airportName: "Musselwhite",
    cityCode: "YBS",
    cityName: "Opapamiska Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBT",
    airportName: "Brochet",
    cityCode: "YBT",
    cityName: "Brochet",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBV",
    airportName: "Berens River",
    cityCode: "YBV",
    cityName: "Berens River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBW",
    airportName: "Springbank",
    cityCode: "YBW",
    cityName: "Calgary",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBX",
    airportName: "Blanc Sablon",
    cityCode: "YBX",
    cityName: "Blanc Sablon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YBY",
    airportName: "Bonnyville",
    cityCode: "YBY",
    cityName: "Bonnyville, AB",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCA",
    airportName: "Courtenay",
    cityCode: "YCA",
    cityName: "Courtenay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCB",
    airportName: "Cambridge Bay",
    cityCode: "YCB",
    cityName: "Cambridge Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCC",
    airportName: "Regional",
    cityCode: "YCC",
    cityName: "Cornwall",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCD",
    airportName: "Nanaimo Arpt",
    cityCode: "YCD",
    cityName: "Nanaimo",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCF",
    airportName: "Cortes Bay",
    cityCode: "YCF",
    cityName: "Cortes Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCG",
    airportName: "Castlegar",
    cityCode: "YCG",
    cityName: "Castlegar",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCH",
    airportName: "Miramichi",
    cityCode: "YCH",
    cityName: "Miramichi",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCI",
    airportName: "Caribou Island",
    cityCode: "YCI",
    cityName: "Caribou Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCJ",
    airportName: "Cape St James",
    cityCode: "YCJ",
    cityName: "Cape St James",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCK",
    airportName: "Colville Lake",
    cityCode: "YCK",
    cityName: "Colville Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCL",
    airportName: "Charlo",
    cityCode: "YCL",
    cityName: "Charlo",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCM",
    airportName: "St Catharines",
    cityCode: "YCM",
    cityName: "St Catharines",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCN",
    airportName: "Cochrane",
    cityCode: "YCN",
    cityName: "Cochrane",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCO",
    airportName: "Kugluktuk",
    cityCode: "YCO",
    cityName: "Kugluktuk/Coppermine",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCP",
    airportName: "Co-op Point",
    cityCode: "YCP",
    cityName: "Co-op Point",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCQ",
    airportName: "Chetwynd",
    cityCode: "YCQ",
    cityName: "Chetwynd",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCR",
    airportName: "Cross Lake",
    cityCode: "YCR",
    cityName: "Cross Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCS",
    airportName: "Chesterfield Inlet",
    cityCode: "YCS",
    cityName: "Chesterfield Inlet",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCT",
    airportName: "Coronation",
    cityCode: "YCT",
    cityName: "Coronation",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCU",
    airportName: "Yun Cheng",
    cityCode: "YCU",
    cityName: "Yun Cheng",
    countryName: "China",
    countryCode: "CH",
  },
  {
    airportCode: "YCV",
    airportName: "Cartierville",
    cityCode: "YCV",
    cityName: "Cartierville",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCW",
    airportName: "Chilliwack Municipal Arpt.",
    cityCode: "YCW",
    cityName: "Chilliwack",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCX",
    airportName: "Gagetown",
    cityCode: "YCX",
    cityName: "Gagetown",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCY",
    airportName: "Clyde River",
    cityCode: "YCY",
    cityName: "Clyde River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YCZ",
    airportName: "Fairmont Hot Springs",
    cityCode: "YCZ",
    cityName: "Fairmont Hot Springs",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDA",
    airportName: "Dawson City",
    cityCode: "YDA",
    cityName: "Dawson City",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDB",
    airportName: "Burwash Landings",
    cityCode: "YDB",
    cityName: "Burwash Landings",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDC",
    airportName: "Industrial Arpt.",
    cityCode: "YDC",
    cityName: "Drayton Valley",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDE",
    airportName: "Paradise River",
    cityCode: "YDE",
    cityName: "Paradise River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDF",
    airportName: "Deer Lake",
    cityCode: "YDF",
    cityName: "Deer Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDG",
    airportName: "Digby",
    cityCode: "YDG",
    cityName: "Digby",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDH",
    airportName: "Daniels Harbour",
    cityCode: "YDH",
    cityName: "Daniels Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDI",
    airportName: "Davis Inlet",
    cityCode: "YDI",
    cityName: "Davis Inlet",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDJ",
    airportName: "Hatchet Lake",
    cityCode: "YDJ",
    cityName: "Hatchet Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDK",
    airportName: "Main Duck Island",
    cityCode: "YDK",
    cityName: "Main Duck Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDL",
    airportName: "Dease Lake",
    cityCode: "YDL",
    cityName: "Dease Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDN",
    airportName: "Dauphin",
    cityCode: "YDN",
    cityName: "Dauphin",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDO",
    airportName: "St Methode",
    cityCode: "YDO",
    cityName: "Dolbeau",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDP",
    airportName: "Nain",
    cityCode: "YDP",
    cityName: "Nain",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDQ",
    airportName: "Dawson Creek",
    cityCode: "YDQ",
    cityName: "Dawson Creek",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDR",
    airportName: "Broadview",
    cityCode: "YDR",
    cityName: "Broadview",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDS",
    airportName: "Desolation Sound",
    cityCode: "YDS",
    cityName: "Desolation Sound",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDT",
    airportName: "Boundary Bay",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDW",
    airportName: "Obre Lake",
    cityCode: "YDW",
    cityName: "Obre Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YDX",
    airportName: "Doc Creek",
    cityCode: "YDX",
    cityName: "Doc Creek",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEC",
    airportName: "Yecheon",
    cityCode: "YEC",
    cityName: "Yecheon",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "YED",
    airportName: "Namao Fld",
    cityCode: "YEA",
    cityName: "Edmonton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEG",
    airportName: "Edmonton Intl.",
    cityCode: "YEA",
    cityName: "Edmonton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEH",
    airportName: "Persian Gulf Intl.",
    cityCode: "YEH",
    cityName: "Assaluyeh",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "YEI",
    airportName: "Yenisehir",
    cityCode: "YEI",
    cityName: "Bursa",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "YEK",
    airportName: "Arviat",
    cityCode: "YEK",
    cityName: "Arviat",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEL",
    airportName: "Elliot Lake",
    cityCode: "YEL",
    cityName: "Elliot Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEM",
    airportName: "East Manitoulin",
    cityCode: "YEM",
    cityName: "Manitowaning",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEN",
    airportName: "Estevan",
    cityCode: "YEN",
    cityName: "Estevan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEO",
    airportName: "Yeovilton",
    cityCode: "YEO",
    cityName: "Yeovilton",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "YEP",
    airportName: "Estevan Point",
    cityCode: "YEP",
    cityName: "Estevan Point",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEQ",
    airportName: "Yenkis",
    cityCode: "YEQ",
    cityName: "Yenkis",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "YER",
    airportName: "Fort Severn",
    cityCode: "YER",
    cityName: "Fort Severn",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YET",
    airportName: "Edson",
    cityCode: "YET",
    cityName: "Edson",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEU",
    airportName: "Eureka",
    cityCode: "YEU",
    cityName: "Eureka",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEV",
    airportName: "Inuvik/mike Zubko",
    cityCode: "YEV",
    cityName: "Inuvik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YEY",
    airportName: "Amos",
    cityCode: "YEY",
    cityName: "Amos",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFA",
    airportName: "Fort Albany",
    cityCode: "YFA",
    cityName: "Fort Albany",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFB",
    airportName: "Iqaluit",
    cityCode: "YFB",
    cityName: "Iqaluit",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFC",
    airportName: "Fredericton",
    cityCode: "YFC",
    cityName: "Fredericton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFE",
    airportName: "Forestville",
    cityCode: "YFE",
    cityName: "Forestville",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFG",
    airportName: "Fontanges",
    cityCode: "YFG",
    cityName: "Fontanges",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFH",
    airportName: "Fort Hope",
    cityCode: "YFH",
    cityName: "Fort Hope",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFJ",
    airportName: "Snare Lake",
    cityCode: "YFJ",
    cityName: "Snare Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFL",
    airportName: "Fort Reliance",
    cityCode: "YFL",
    cityName: "Fort Reliance",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFO",
    airportName: "Flin Flon",
    cityCode: "YFO",
    cityName: "Flin Flon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFR",
    airportName: "Fort Resolution",
    cityCode: "YFR",
    cityName: "Fort Resolution",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFS",
    airportName: "Fort Simpson",
    cityCode: "YFS",
    cityName: "Fort Simpson",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YFX",
    airportName: "Fox Harbour",
    cityCode: "YFX",
    cityName: "Fox Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGA",
    airportName: "Gagnon",
    cityCode: "YGA",
    cityName: "Gagnon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGB",
    airportName: "Gillies Bay",
    cityCode: "YGB",
    cityName: "Gillies Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGC",
    airportName: "Grande Cache",
    cityCode: "YGC",
    cityName: "Grande Cache",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGE",
    airportName: "Gorge Harbor",
    cityCode: "YGE",
    cityName: "Gorge Harbor",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGG",
    airportName: "Ganges Harbor",
    cityCode: "YGG",
    cityName: "Ganges Harbor",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGH",
    airportName: "Fort Good Hope",
    cityCode: "YGH",
    cityName: "Fort Good Hope",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGJ",
    airportName: "Miho",
    cityCode: "YGJ",
    cityName: "Yonago",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "YGK",
    airportName: "Kingston",
    cityCode: "YGK",
    cityName: "Kingston",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGL",
    airportName: "La Grande",
    cityCode: "YGL",
    cityName: "La Grande",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGM",
    airportName: "Gimli",
    cityCode: "YGM",
    cityName: "Gimli",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGN",
    airportName: "Greenway Sound",
    cityCode: "YGN",
    cityName: "Greenway Sound",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGO",
    airportName: "Gods Narrows",
    cityCode: "YGO",
    cityName: "Gods Narrows",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGQ",
    airportName: "Geraldton",
    cityCode: "YGQ",
    cityName: "Geraldton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGR",
    airportName: "Iles De La Madeleine",
    cityCode: "YGR",
    cityName: "Iles De La Madeleine",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGS",
    airportName: "Germansen",
    cityCode: "YGS",
    cityName: "Germansen",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGT",
    airportName: "Igloolik",
    cityCode: "YGT",
    cityName: "Igloolik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGV",
    airportName: "Havre St Pierre",
    cityCode: "YGV",
    cityName: "Havre St Pierre",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGW",
    airportName: "Kuujjuarapik",
    cityCode: "YGW",
    cityName: "Kuujjuarapik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGX",
    airportName: "Gillam",
    cityCode: "YGX",
    cityName: "Gillam",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGY",
    airportName: "Deception",
    cityCode: "YGY",
    cityName: "Deception",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YGZ",
    airportName: "Grise Fiord",
    cityCode: "YGZ",
    cityName: "Grise Fiord",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHA",
    airportName: "Port Hope Simpson",
    cityCode: "YHA",
    cityName: "Port Hope Simpson",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHB",
    airportName: "Hudson Bay",
    cityCode: "YHB",
    cityName: "Hudson Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHC",
    airportName: "Hakai Pass",
    cityCode: "YHC",
    cityName: "Hakai Pass",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHD",
    airportName: "Municipal",
    cityCode: "YHD",
    cityName: "Dryden",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHE",
    airportName: "Hope",
    cityCode: "YHE",
    cityName: "blr",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHF",
    airportName: "Hearst",
    cityCode: "YHF",
    cityName: "Hearst",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHG",
    airportName: "Charlottetown",
    cityCode: "YHG",
    cityName: "Charlottetown",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHH",
    airportName: "Harbor SPB",
    cityCode: "YHH",
    cityName: "Campbell River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHI",
    airportName: "Holman",
    cityCode: "YHI",
    cityName: "Holman",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHK",
    airportName: "Gjoa Haven",
    cityCode: "YHK",
    cityName: "Gjoa Haven",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHM",
    airportName: "Hamilton",
    cityCode: "YTO",
    cityName: "Toronto",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHN",
    airportName: "Hornepayne",
    cityCode: "YHN",
    cityName: "Hornepayne",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHO",
    airportName: "Hopedale",
    cityCode: "YHO",
    cityName: "Hopedale",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHP",
    airportName: "Poplar Hill",
    cityCode: "YHP",
    cityName: "Poplar Hill",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHR",
    airportName: "Chevery",
    cityCode: "YHR",
    cityName: "Chevery",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHS",
    airportName: "Sechelt",
    cityCode: "YHS",
    cityName: "Sechelt",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHT",
    airportName: "Haines Junction",
    cityCode: "YHT",
    cityName: "Haines Junction",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHU",
    airportName: "St Hubert",
    cityCode: "YMQ",
    cityName: "Montreal",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHY",
    airportName: "Hay River",
    cityCode: "YHY",
    cityName: "Hay River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YHZ",
    airportName: "Halifax Intl.",
    cityCode: "YHZ",
    cityName: "Halifax, NS",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YIB",
    airportName: "Atikokan",
    cityCode: "YIB",
    cityName: "Atikokan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YIF",
    airportName: "Pakuashipi",
    cityCode: "YIF",
    cityName: "Pakuashipi",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YIG",
    airportName: "Big Bay Marina",
    cityCode: "YIG",
    cityName: "Big Bay Marina",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YIH",
    airportName: "Yichang",
    cityCode: "YIH",
    cityName: "Yichang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YIK",
    airportName: "Ivujivik",
    cityCode: "YIK",
    cityName: "Ivujivik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YIN",
    airportName: "Yining",
    cityCode: "YIN",
    cityName: "Yining",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YIO",
    airportName: "Pond Inlet",
    cityCode: "YIO",
    cityName: "Pond Inlet",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YIP",
    airportName: "Willow Run",
    cityCode: "YIP",
    cityName: "Detroit, MI",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "YIV",
    airportName: "Island Lk/Garden Hill",
    cityCode: "YIV",
    cityName: "Island Lk/Garden Hill",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YIW",
    airportName: "Yiwu",
    cityCode: "YIW",
    cityName: "Yiwu",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YJF",
    airportName: "Fort Liard",
    cityCode: "YJF",
    cityName: "Fort Liard",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YJN",
    airportName: "St Jean",
    cityCode: "YJN",
    cityName: "St Jean",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YJO",
    airportName: "Johnny Mountain",
    cityCode: "YJO",
    cityName: "Johnny Mountain",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YJP",
    airportName: "Jasper-hinton",
    cityCode: "YJP",
    cityName: "Jasper-hinton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YJT",
    airportName: "Stephenville",
    cityCode: "YJT",
    cityName: "Stephenville",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKA",
    airportName: "Kamloops",
    cityCode: "YKA",
    cityName: "Kamloops",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKC",
    airportName: "Collins Bay",
    cityCode: "YKC",
    cityName: "Collins Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKD",
    airportName: "Township Arpt.",
    cityCode: "YKD",
    cityName: "Kincardine",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKE",
    airportName: "Knee Lake",
    cityCode: "YKE",
    cityName: "Knee Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKF",
    airportName: "Kitchener-Waterloo Regional",
    cityCode: "YKF",
    cityName: "Kitchener/Waterloo",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKG",
    airportName: "Kangirsuk",
    cityCode: "YKG",
    cityName: "Kangirsuk",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKI",
    airportName: "Kennosao Lake",
    cityCode: "YKI",
    cityName: "Kennosao Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKJ",
    airportName: "Key Lake",
    cityCode: "YKJ",
    cityName: "Key Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKK",
    airportName: "Kitkatla",
    cityCode: "YKK",
    cityName: "Kitkatla",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKL",
    airportName: "Schefferville",
    cityCode: "YKL",
    cityName: "Schefferville",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKM",
    airportName: "Yakima Air Terminal",
    cityCode: "YKM",
    cityName: "Yakima",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "YKN",
    airportName: "Chan Gurney",
    cityCode: "YKN",
    cityName: "Yankton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "YKQ",
    airportName: "Waskaganish",
    cityCode: "YKQ",
    cityName: "Waskaganish",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKS",
    airportName: "Yakutsk",
    cityCode: "YKS",
    cityName: "Yakutsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "YKT",
    airportName: "Klemtu",
    cityCode: "YKT",
    cityName: "Klemtu",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKU",
    airportName: "Chisasibi",
    cityCode: "YKU",
    cityName: "Chisasibi",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKX",
    airportName: "Kirkland Lake",
    cityCode: "YKX",
    cityName: "Kirkland Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKY",
    airportName: "Kindersley",
    cityCode: "YKY",
    cityName: "Kindersley",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YKZ",
    airportName: "Buttonville Municipal",
    cityCode: "YTO",
    cityName: "Toronto",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLA",
    airportName: "Langara",
    cityCode: "YLA",
    cityName: "Langara",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLB",
    airportName: "Lac Biche",
    cityCode: "YLB",
    cityName: "Lac Biche",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLC",
    airportName: "Kimmirut",
    cityCode: "YLC",
    cityName: "Kimmirut/Lake Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLD",
    airportName: "Chapleau",
    cityCode: "YLD",
    cityName: "Chapleau",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLE",
    airportName: "Wha Ti",
    cityCode: "YLE",
    cityName: "Wha Ti/Lac La Martre",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLF",
    airportName: "LaForges",
    cityCode: "YLF",
    cityName: "LaForges",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLG",
    airportName: "Yalgoo",
    cityCode: "YLG",
    cityName: "Yalgoo",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "YLH",
    airportName: "Lansdowne House",
    cityCode: "YLH",
    cityName: "Lansdowne House",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLJ",
    airportName: "Meadow Lake",
    cityCode: "YLJ",
    cityName: "Meadow Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLL",
    airportName: "Lloydminster",
    cityCode: "YLL",
    cityName: "Lloydminster, AB",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLM",
    airportName: "Clinton Creek",
    cityCode: "YLM",
    cityName: "Clinton Creek",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLN",
    airportName: "Yilan",
    cityCode: "YLN",
    cityName: "Yilan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YLO",
    airportName: "Shilo",
    cityCode: "YLO",
    cityName: "Shilo",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLP",
    airportName: "Mingan",
    cityCode: "YLP",
    cityName: "Mingan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLQ",
    airportName: "La Tuque",
    cityCode: "YLQ",
    cityName: "La Tuque",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLR",
    airportName: "Leaf Rapids",
    cityCode: "YLR",
    cityName: "Leaf Rapids",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLS",
    airportName: "Lebel-Sur-Quevillon",
    cityCode: "YLS",
    cityName: "Lebel-Sur-Quevillon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLT",
    airportName: "Alert",
    cityCode: "YLT",
    cityName: "Alert",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLW",
    airportName: "Kelowna Arpt.",
    cityCode: "YLW",
    cityName: "Kelowna, BC",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLX",
    airportName: "Long Point",
    cityCode: "YLX",
    cityName: "Long Point",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMA",
    airportName: "Mayo",
    cityCode: "YMA",
    cityName: "Mayo",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMB",
    airportName: "Merritt",
    cityCode: "YMB",
    cityName: "Merritt",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMC",
    airportName: "Maricourt Airstrip",
    cityCode: "YMC",
    cityName: "Maricourt Airstrip",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YME",
    airportName: "Matane",
    cityCode: "YME",
    cityName: "Matane",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMF",
    airportName: "Montagne Harbor",
    cityCode: "YMF",
    cityName: "Montagne Harbor",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMG",
    airportName: "Manitouwadge",
    cityCode: "YMG",
    cityName: "Manitouwadge",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMI",
    airportName: "Minaki",
    cityCode: "YMI",
    cityName: "Minaki",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMJ",
    airportName: "Moose Jaw / Air Vice Marshal C. M. Mcewen Arpt.",
    cityCode: "YMJ",
    cityName: "Moose Jaw",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YML",
    airportName: "Charlevoix",
    cityCode: "YML",
    cityName: "Murray Bay, NT",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMM",
    airportName: "Fort Mcmurray",
    cityCode: "YMM",
    cityName: "Fort Mcmurray",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMN",
    airportName: "Makkovik",
    cityCode: "YMN",
    cityName: "Makkovik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMO",
    airportName: "Moosonee",
    cityCode: "YMO",
    cityName: "Moosonee, ON",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMP",
    airportName: "Port McNeil",
    cityCode: "YMP",
    cityName: "Port McNeil",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMR",
    airportName: "Merry Island",
    cityCode: "YMR",
    cityName: "Merry Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMS",
    airportName: "Yurimaguas",
    cityCode: "YMS",
    cityName: "Yurimaguas",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "YMT",
    airportName: "Chibougamau",
    cityCode: "YMT",
    cityName: "Chibougamau",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMW",
    airportName: "Maniwaki",
    cityCode: "YMW",
    cityName: "Maniwaki",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YMX",
    airportName: "Mirabel",
    cityCode: "YMQ",
    cityName: "Montreal",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNA",
    airportName: "Natashquan",
    cityCode: "YNA",
    cityName: "Natashquan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNB",
    airportName: "Yanbu",
    cityCode: "YNB",
    cityName: "Yanbu",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "YNC",
    airportName: "Wemindji",
    cityCode: "YNC",
    cityName: "Wemindji",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YND",
    airportName: "Executive Gatineau-Ottawa",
    cityCode: "YOW",
    cityName: "Ottawa",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNE",
    airportName: "Norway House",
    cityCode: "YNE",
    cityName: "Norway House",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNF",
    airportName: "Deer Lake/Stephenville",
    cityCode: "YNF",
    cityName: "Corner Brook",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNG",
    airportName: "Youngstown",
    cityCode: "YNG",
    cityName: "Youngstown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "YNI",
    airportName: "Nitchequon",
    cityCode: "YNI",
    cityName: "Nitchequon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNJ",
    airportName: "Yanji",
    cityCode: "YNJ",
    cityName: "Yanji",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YNK",
    airportName: "Nootka Sound",
    cityCode: "YNK",
    cityName: "Nootka Sound",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNL",
    airportName: "Points North Landing",
    cityCode: "YNL",
    cityName: "Points North Landing, Saskatchewan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNM",
    airportName: "Matagami",
    cityCode: "YNM",
    cityName: "Matagami",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNN",
    airportName: "Yandi",
    cityCode: "YNN",
    cityName: "Yandicoogina",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "YNO",
    airportName: "North Spirit Lake",
    cityCode: "YNO",
    cityName: "North Spirit Lake, ON",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNP",
    airportName: "Natuashish",
    cityCode: "YNP",
    cityName: "Natuashish",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNR",
    airportName: "Arnes",
    cityCode: "YNR",
    cityName: "Arnes, MB",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNS",
    airportName: "Nemiscau",
    cityCode: "YNS",
    cityName: "Nemiscau",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YNT",
    airportName: "Yantai",
    cityCode: "YNT",
    cityName: "Yantai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YNY",
    airportName: "Yangyang",
    cityCode: "YNY",
    cityName: "Yangyang",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "YNZ",
    airportName: "Yancheng",
    cityCode: "YNZ",
    cityName: "Yancheng",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YOA",
    airportName: "Ekati",
    cityCode: "YOA",
    cityName: "Ekati",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOC",
    airportName: "Old Crow",
    cityCode: "YOC",
    cityName: "Old Crow",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOD",
    airportName: "Cold Lake",
    cityCode: "YOD",
    cityName: "Cold Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOE",
    airportName: "Falher",
    cityCode: "YOE",
    cityName: "Falher",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOG",
    airportName: "Ogoki",
    cityCode: "YOG",
    cityName: "Ogoki",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOH",
    airportName: "Oxford House",
    cityCode: "YOH",
    cityName: "Oxford House",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOJ",
    airportName: "Footner Lake",
    cityCode: "YOJ",
    cityName: "High Level",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOK",
    airportName: "Yokohama",
    cityCode: "YOK",
    cityName: "Yokohama",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "YOL",
    airportName: "Yola",
    cityCode: "YOL",
    cityName: "Yola",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "YOO",
    airportName: "Oshawa",
    cityCode: "YOO",
    cityName: "Oshawa",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOP",
    airportName: "Rainbow Lake",
    cityCode: "YOP",
    cityName: "Rainbow Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOS",
    airportName: "Billy Bishop Regional",
    cityCode: "YOS",
    cityName: "Owen Sound",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOT",
    airportName: "Yotvata",
    cityCode: "YOT",
    cityName: "Yotvata",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "YOW",
    airportName: "Ottawa Macdonald-Cartier Intl.",
    cityCode: "YOW",
    cityName: "Ottawa",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YOY",
    airportName: "Valcartier",
    cityCode: "YOY",
    cityName: "Valcartier",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPA",
    airportName: "Glass Field",
    cityCode: "YPA",
    cityName: "Prince Albert, SK",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPB",
    airportName: "Port Alberni",
    cityCode: "YPB",
    cityName: "Port Alberni",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPC",
    airportName: "Paulatuk",
    cityCode: "YPC",
    cityName: "Paulatuk",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPD",
    airportName: "Parry Sound",
    cityCode: "YPD",
    cityName: "Parry Sound",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPE",
    airportName: "Peace River",
    cityCode: "YPE",
    cityName: "Peace River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPF",
    airportName: "Esquimalt",
    cityCode: "YPF",
    cityName: "Esquimalt",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPG",
    airportName: "Portage La Prairie",
    cityCode: "YPG",
    cityName: "Portage La Prairie",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPH",
    airportName: "Inukjuak",
    cityCode: "YPH",
    cityName: "Inukjuak",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPI",
    airportName: "Port Simpson",
    cityCode: "YPI",
    cityName: "Port Simpson",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPJ",
    airportName: "Aupaluk",
    cityCode: "YPJ",
    cityName: "Aupaluk",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPL",
    airportName: "Pickle Lake",
    cityCode: "YPL",
    cityName: "Pickle Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPM",
    airportName: "Pikangikum",
    cityCode: "YPM",
    cityName: "Pikangikum",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPN",
    airportName: "Port Menier",
    cityCode: "YPN",
    cityName: "Port Menier",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPO",
    airportName: "Peawanuck",
    cityCode: "YPO",
    cityName: "Peawanuck",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPP",
    airportName: "Pine Point",
    cityCode: "YPP",
    cityName: "Pine Point",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPQ",
    airportName: "Peterborough",
    cityCode: "YPQ",
    cityName: "Peterborough",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPR",
    airportName: "Digby Island",
    cityCode: "YPR",
    cityName: "Prince Rupert",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPS",
    airportName: "Port Hawkesbury",
    cityCode: "YPS",
    cityName: "Port Hawkesbury",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPT",
    airportName: "Pender Harbor",
    cityCode: "YPT",
    cityName: "Pender Harbor",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPW",
    airportName: "Powell River",
    cityCode: "YPW",
    cityName: "Powell River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPX",
    airportName: "Puvirnituq",
    cityCode: "YPX",
    cityName: "Povungnituk",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPY",
    airportName: "Fort Chipewyan",
    cityCode: "YPY",
    cityName: "Fort Chipewyan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YPZ",
    airportName: "Burns Lake",
    cityCode: "YPZ",
    cityName: "Burns Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQA",
    airportName: "Muskoka",
    cityCode: "YQA",
    cityName: "Muskoka",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQB",
    airportName: "Quebec",
    cityCode: "YQB",
    cityName: "Quebec City",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQC",
    airportName: "Quaqtaq",
    cityCode: "YQC",
    cityName: "Quaqtaq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQD",
    airportName: "The Pas",
    cityCode: "YQD",
    cityName: "The Pas",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQE",
    airportName: "Kimberley",
    cityCode: "YQE",
    cityName: "Kimberley",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQF",
    airportName: "Red Deer",
    cityCode: "YQF",
    cityName: "Red Deer",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQG",
    airportName: "Windsor",
    cityCode: "YQG",
    cityName: "Windsor",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQH",
    airportName: "Watson Lake",
    cityCode: "YQH",
    cityName: "Watson Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQI",
    airportName: "Yarmouth",
    cityCode: "YQI",
    cityName: "Yarmouth, NS",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQK",
    airportName: "Kenora",
    cityCode: "YQK",
    cityName: "Kenora",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQL",
    airportName: "Lethbridge",
    cityCode: "YQL",
    cityName: "Lethbridge",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQM",
    airportName: "Greater Moncton Intl. Arpt.",
    cityCode: "YQM",
    cityName: "Moncton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQN",
    airportName: "Nakina",
    cityCode: "YQN",
    cityName: "Nakina",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQQ",
    airportName: "Comox",
    cityCode: "YQQ",
    cityName: "Comox",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQR",
    airportName: "Regina",
    cityCode: "YQR",
    cityName: "Regina, Saskatchewan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQS",
    airportName: "Pembroke Area Mncpl",
    cityCode: "YQS",
    cityName: "St Thomas",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQT",
    airportName: "Thunder Bay",
    cityCode: "YQT",
    cityName: "Thunder Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQU",
    airportName: "Grande Prairie",
    cityCode: "YQU",
    cityName: "Grande Prairie",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQV",
    airportName: "Yorkton",
    cityCode: "YQV",
    cityName: "Yorkton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQW",
    airportName: "North Battleford",
    cityCode: "YQW",
    cityName: "North Battleford",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQX",
    airportName: "Gander Intl.",
    cityCode: "YQX",
    cityName: "Gander",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQY",
    airportName: "Sydney",
    cityCode: "YQY",
    cityName: "Sydney, NS",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YQZ",
    airportName: "Quesnel",
    cityCode: "YQZ",
    cityName: "Quesnel",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRA",
    airportName: "Rae Lakes",
    cityCode: "YRA",
    cityName: "Rae Lakes",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRB",
    airportName: "Resolute",
    cityCode: "YRB",
    cityName: "Resolute",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRD",
    airportName: "Dean River",
    cityCode: "YRD",
    cityName: "Dean River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRE",
    airportName: "Resolution Island",
    cityCode: "YRE",
    cityName: "Resolution Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRF",
    airportName: "Cartwright",
    cityCode: "YRF",
    cityName: "Cartwright",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRG",
    airportName: "Rigolet",
    cityCode: "YRG",
    cityName: "Rigolet",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRI",
    airportName: "Riviere Du Loup",
    cityCode: "YRI",
    cityName: "Riviere Du Loup",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRJ",
    airportName: "Roberval",
    cityCode: "YRJ",
    cityName: "Roberval",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRL",
    airportName: "Red Lake",
    cityCode: "YRL",
    cityName: "Red Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRM",
    airportName: "Rocky Mountain House",
    cityCode: "YRM",
    cityName: "Rocky Mountain House",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRN",
    airportName: "Rivers Inlet",
    cityCode: "YRN",
    cityName: "Owekeno Village",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRQ",
    airportName: "Trois-Rivieres",
    cityCode: "YRQ",
    cityName: "Trois-Rivieres",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRR",
    airportName: "Stuart Island",
    cityCode: "YRR",
    cityName: "Stuart Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRS",
    airportName: "Red Sucker Lake",
    cityCode: "YRS",
    cityName: "Red Sucker Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRT",
    airportName: "Nuuk",
    cityCode: "YRT",
    cityName: "Rankin Inlet, NT",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRV",
    airportName: "Revelstoke",
    cityCode: "YRV",
    cityName: "Revelstoke",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSA",
    airportName: "Sable Island",
    cityCode: "YSA",
    cityName: "Sable Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSB",
    airportName: "Sudbury",
    cityCode: "YSB",
    cityName: "Sudbury",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSC",
    airportName: "Sherbrooke",
    cityCode: "YSC",
    cityName: "Sherbrooke",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSD",
    airportName: "Suffield",
    cityCode: "YSD",
    cityName: "Suffield",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSE",
    airportName: "Squamish",
    cityCode: "YSE",
    cityName: "Squamish",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSF",
    airportName: "Stony Rapids",
    cityCode: "YSF",
    cityName: "Stony Rapids",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSG",
    airportName: "Lutselke",
    cityCode: "YSG",
    cityName: "Lutselke/Snowdrift",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSH",
    airportName: "Smith Falls",
    cityCode: "YSH",
    cityName: "Smith Falls",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSI",
    airportName: "Sans Souci",
    cityCode: "YSI",
    cityName: "Sans Souci",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSJ",
    airportName: "Saint John",
    cityCode: "YSJ",
    cityName: "Saint John",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSK",
    airportName: "Sanikiluaq",
    cityCode: "YSK",
    cityName: "Sanikiluaq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSL",
    airportName: "St Leonard",
    cityCode: "YSL",
    cityName: "St Leonard",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSM",
    airportName: "Fort Smith",
    cityCode: "YSM",
    cityName: "Fort Smith",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSN",
    airportName: "Salmon Arm",
    cityCode: "YSN",
    cityName: "Salmon Arm",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSO",
    airportName: "Postville",
    cityCode: "YSO",
    cityName: "Postville",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSP",
    airportName: "Marathon",
    cityCode: "YSP",
    cityName: "Marathon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSQ",
    airportName: "Spring Island",
    cityCode: "YSQ",
    cityName: "Spring Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSR",
    airportName: "Nanisivik",
    cityCode: "YSR",
    cityName: "Nanisivik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSS",
    airportName: "Slate Island",
    cityCode: "YSS",
    cityName: "Slate Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YST",
    airportName: "Ste Therese Point",
    cityCode: "YST",
    cityName: "Ste Therese Point",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSU",
    airportName: "Summerside",
    cityCode: "YSU",
    cityName: "Summerside",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSV",
    airportName: "Saglek",
    cityCode: "YSV",
    cityName: "Saglek",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSX",
    airportName: "Shearwater",
    cityCode: "YSX",
    cityName: "Shearwater",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSY",
    airportName: "Sachs Harbour",
    cityCode: "YSY",
    cityName: "Sachs Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YSZ",
    airportName: "Squirrel Cove",
    cityCode: "YSZ",
    cityName: "Squirrel Cove",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTA",
    airportName: "Pembroke And Area Apt",
    cityCode: "YTA",
    cityName: "Pembroke",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTB",
    airportName: "Hartley Bay",
    cityCode: "YTB",
    cityName: "Hartley Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTC",
    airportName: "Sturdee",
    cityCode: "YTC",
    cityName: "Sturdee",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTD",
    airportName: "Thicket Portage",
    cityCode: "YTD",
    cityName: "Thicket Portage",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTE",
    airportName: "Cape Dorset",
    cityCode: "YTE",
    cityName: "Cape Dorset",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTF",
    airportName: "Alma",
    cityCode: "YTF",
    cityName: "Alma",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTG",
    airportName: "Sullivan Bay",
    cityCode: "YTG",
    cityName: "Sullivan Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTH",
    airportName: "Thompson",
    cityCode: "YTH",
    cityName: "Thompson",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTI",
    airportName: "Triple Island",
    cityCode: "YTI",
    cityName: "Triple Island",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTJ",
    airportName: "Terrace Bay",
    cityCode: "YTJ",
    cityName: "Terrace Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTK",
    airportName: "Tulugak",
    cityCode: "YTK",
    cityName: "Tulugak",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTL",
    airportName: "Big Trout",
    cityCode: "YTL",
    cityName: "Big Trout",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTM",
    airportName: "La Macaza",
    cityCode: "YTM",
    cityName: "Mont Tremblant",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTN",
    airportName: "Riviere Au Tonnerre",
    cityCode: "YTN",
    cityName: "Riviere Au Tonnerre",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTP",
    airportName: "Seaplane Base",
    cityCode: "YTP",
    cityName: "Tofino",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTR",
    airportName: "Trenton",
    cityCode: "YTR",
    cityName: "Trenton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTS",
    airportName: "Timmins",
    cityCode: "YTS",
    cityName: "Timmins, ON",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTT",
    airportName: "Tisdale",
    cityCode: "YTT",
    cityName: "Tisdale, sk",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTX",
    airportName: "Telegraph Creek",
    cityCode: "YTX",
    cityName: "Telegraph Creek",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YTZ",
    airportName: "Toronto City Centre Arpt.",
    cityCode: "YTO",
    cityName: "Toronto",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YUA",
    airportName: "Yuanmou",
    cityCode: "YUA",
    cityName: "Yuanmou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "YUB",
    airportName: "Tuktoyaktuk",
    cityCode: "YUB",
    cityName: "Tuktoyaktuk",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YUD",
    airportName: "Umiujaq",
    cityCode: "YUD",
    cityName: "Umiujaq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YUE",
    airportName: "Yuendumu",
    cityCode: "YUE",
    cityName: "Yuendumu",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "YUL",
    airportName: "Pierre Elliott Trudeau International Airport",
    cityCode: "YMQ",
    cityName: "Montreal",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YUM",
    airportName: "Intl.",
    cityCode: "YUM",
    cityName: "Yuma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "YUT",
    airportName: "Repulse Bay",
    cityCode: "YUT",
    cityName: "Repulse Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YUX",
    airportName: "Hall Beach",
    cityCode: "YUX",
    cityName: "Hall Beach",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YUY",
    airportName: "Rouyn-Noranda",
    cityCode: "YUY",
    cityName: "Rouyn-Noranda, QuÃ©bec",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVA",
    airportName: "Iconi",
    cityCode: "YVA",
    cityName: "Moroni",
    countryName: "Comoros",
    countryCode: "KM",
  },
  {
    airportCode: "YVB",
    airportName: "Bonaventure",
    cityCode: "YVB",
    cityName: "Bonaventure",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVC",
    airportName: "La Ronge",
    cityCode: "YVC",
    cityName: "La Ronge",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVD",
    airportName: "Yeva",
    cityCode: "YVD",
    cityName: "Yeva",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "YVE",
    airportName: "Vernon Regional Arpt.",
    cityCode: "YVE",
    cityName: "Vernon B. C.",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVG",
    airportName: "Vermilion",
    cityCode: "YVG",
    cityName: "Vermilion",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVM",
    airportName: "Qikiqtarjuaq",
    cityCode: "YVM",
    cityName: "Qikiqtarjuaq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVP",
    airportName: "Kuujjuaq",
    cityCode: "YVP",
    cityName: "Kuujjuaq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVQ",
    airportName: "Norman Wells",
    cityCode: "YVQ",
    cityName: "Norman Wells",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVT",
    airportName: "Buffalo Narrows",
    cityCode: "YVT",
    cityName: "Buffalo Narrows",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVV",
    airportName: "Wiarton/Keppel Arpt.",
    cityCode: "YVV",
    cityName: "Wiarton, ON",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVZ",
    airportName: "Deer Lake",
    cityCode: "YVZ",
    cityName: "Deer Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWA",
    airportName: "Petawawa",
    cityCode: "YWA",
    cityName: "Petawawa",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWB",
    airportName: "Kangiqsujuaq",
    cityCode: "YWB",
    cityName: "Kangiqsujuaq",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWF",
    airportName: "Dwtown Waterfront H/P",
    cityCode: "YWF",
    cityName: "Halifax",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWG",
    airportName: "James Armstrong Richardson Intl. Arpt.",
    cityCode: "YWG",
    cityName: "Winnipeg",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWH",
    airportName: "Victoria Inner Harbor",
    cityCode: "YYJ",
    cityName: "Victoria",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWJ",
    airportName: "Deline",
    cityCode: "YWJ",
    cityName: "Deline",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWK",
    airportName: "Wabush",
    cityCode: "YWK",
    cityName: "Wabush",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWL",
    airportName: "Williams Lake",
    cityCode: "YWL",
    cityName: "Williams Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWM",
    airportName: "Williams Harbour",
    cityCode: "YWM",
    cityName: "Williams Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWN",
    airportName: "Winisk",
    cityCode: "YWN",
    cityName: "Winisk",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWO",
    airportName: "Lupin",
    cityCode: "YWO",
    cityName: "Lupin",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWP",
    airportName: "Webequie",
    cityCode: "YWP",
    cityName: "Webequie",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWQ",
    airportName: "Chute-Des-Passes",
    cityCode: "YWQ",
    cityName: "Chute-Des-Passes",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWR",
    airportName: "White River",
    cityCode: "YWR",
    cityName: "White River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWS",
    airportName: "Whistler",
    cityCode: "YWS",
    cityName: "Whistler",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YWY",
    airportName: "Wrigley",
    cityCode: "YWY",
    cityName: "Wrigley",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXC",
    airportName: "Cranbrook",
    cityCode: "YXC",
    cityName: "Cranbrook",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXD",
    airportName: "Edmonton City Center Arpt.",
    cityCode: "YEA",
    cityName: "Edmonton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXE",
    airportName: "J.G. Diefenbaker Intl. Arpt.",
    cityCode: "YXE",
    cityName: "Saskatoon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXF",
    airportName: "Snake River",
    cityCode: "YXF",
    cityName: "Snake River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXH",
    airportName: "Medicine Hat",
    cityCode: "YXH",
    cityName: "Medicine Hat",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXI",
    airportName: "Killaloe",
    cityCode: "YXI",
    cityName: "Killaloe",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXJ",
    airportName: "Fort St John",
    cityCode: "YXJ",
    cityName: "Fort St John",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXK",
    airportName: "Rimouski",
    cityCode: "YXK",
    cityName: "Rimouski",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXL",
    airportName: "Sioux Lookout",
    cityCode: "YXL",
    cityName: "Sioux Lookout",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXN",
    airportName: "Whale Cove",
    cityCode: "YXN",
    cityName: "Whale Cove",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXP",
    airportName: "Pangnirtung",
    cityCode: "YXP",
    cityName: "Pangnirtung",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXQ",
    airportName: "Beaver Creek",
    cityCode: "YXQ",
    cityName: "Beaver Creek",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXR",
    airportName: "Earlton",
    cityCode: "YXR",
    cityName: "Earlton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXS",
    airportName: "Prince George",
    cityCode: "YXS",
    cityName: "Prince George",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXT",
    airportName: "Terrace",
    cityCode: "YXT",
    cityName: "Terrace, BC",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXU",
    airportName: "London Intl.",
    cityCode: "YXU",
    cityName: "London",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXY",
    airportName: "Whitehorse Intl.",
    cityCode: "YXY",
    cityName: "Whitehorse, Yukon",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YXZ",
    airportName: "Wawa Arpt.",
    cityCode: "YXZ",
    cityName: "Wawa",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYA",
    airportName: "Big Bay Yacht Club",
    cityCode: "YYA",
    cityName: "Big Bay Yacht Club",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYB",
    airportName: "North Bay",
    cityCode: "YYB",
    cityName: "North Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYC",
    airportName: "Calgary Intl. Arpt.",
    cityCode: "YYC",
    cityName: "Calgary, AB",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYD",
    airportName: "Smithers",
    cityCode: "YYD",
    cityName: "Smithers",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYE",
    airportName: "Fort Nelson",
    cityCode: "YYE",
    cityName: "Fort Nelson",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYF",
    airportName: "Penticton",
    cityCode: "YYF",
    cityName: "Penticton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYG",
    airportName: "Charlottetown",
    cityCode: "YYG",
    cityName: "Charlottetown",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYH",
    airportName: "Taloyoak",
    cityCode: "YYH",
    cityName: "Taloyoak",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYI",
    airportName: "Rivers",
    cityCode: "YYI",
    cityName: "Rivers",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYJ",
    airportName: "Victoria Intl.",
    cityCode: "YYJ",
    cityName: "Victoria",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYL",
    airportName: "Lynn Lake",
    cityCode: "YYL",
    cityName: "Lynn Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYM",
    airportName: "Cowley",
    cityCode: "YYM",
    cityName: "Cowley",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYN",
    airportName: "Swift Current",
    cityCode: "YYN",
    cityName: "Swift Current",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYQ",
    airportName: "Metropolitan Area",
    cityCode: "YYQ",
    cityName: "Churchill, Manitoba",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYR",
    airportName: "Goose Bay",
    cityCode: "YYR",
    cityName: "Goose Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYT",
    airportName: "St. JohnÂ´s Intl.",
    cityCode: "YYT",
    cityName: "St. John's, NL",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYU",
    airportName: "Kapuskasing",
    cityCode: "YYU",
    cityName: "Kapuskasing",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYW",
    airportName: "Armstrong",
    cityCode: "YYW",
    cityName: "Armstrong",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYY",
    airportName: "Mont Joli",
    cityCode: "YYY",
    cityName: "Mont Joli",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YYZ",
    airportName: "Lester B. Pearson Intl.",
    cityCode: "YTO",
    cityName: "Toronto",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZA",
    airportName: "Ashcroft",
    cityCode: "YZA",
    cityName: "Ashcroft",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZC",
    airportName: "Beatton River",
    cityCode: "YZC",
    cityName: "Beatton River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZD",
    airportName: "Downsview Arpt.",
    cityCode: "YZD",
    cityName: "Toronto",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZE",
    airportName: "Gore Bay",
    cityCode: "YZE",
    cityName: "Gore Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZF",
    airportName: "Yellowknife",
    cityCode: "YZF",
    cityName: "Yellowknife",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZG",
    airportName: "Salluit",
    cityCode: "YZG",
    cityName: "Salluit",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZH",
    airportName: "Slave Lake",
    cityCode: "YZH",
    cityName: "Slave Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZM",
    airportName: "Buchans",
    cityCode: "YZM",
    cityName: "Buchans",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZP",
    airportName: "Sandspit",
    cityCode: "YZP",
    cityName: "Sandspit",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZR",
    airportName: "Sarnia",
    cityCode: "YZR",
    cityName: "Sarnia",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZS",
    airportName: "Coral Harbour",
    cityCode: "YZS",
    cityName: "Coral Harbour",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZT",
    airportName: "Port Hardy",
    cityCode: "YZT",
    cityName: "Port Hardy",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZU",
    airportName: "Whitecourt",
    cityCode: "YZU",
    cityName: "Whitecourt",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZV",
    airportName: "Sept-Iles",
    cityCode: "YZV",
    cityName: "Sept-Iles",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZW",
    airportName: "Teslin",
    cityCode: "YZW",
    cityName: "Teslin",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YZX",
    airportName: "Greenwood",
    cityCode: "YZX",
    cityName: "Greenwood",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZAA",
    airportName: "Alice Arm",
    cityCode: "ZAA",
    cityName: "Alice Arm",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZAC",
    airportName: "York Landing",
    cityCode: "ZAC",
    cityName: "York Landing",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZAD",
    airportName: "Zadar",
    cityCode: "ZAD",
    cityName: "Zadar",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "ZAG",
    airportName: "Pleso",
    cityCode: "ZAG",
    cityName: "Zagreb",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "ZAH",
    airportName: "Zahedan",
    cityCode: "ZAH",
    cityName: "Zahedan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "ZAJ",
    airportName: "Zaranj",
    cityCode: "ZAJ",
    cityName: "Zaranj",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "ZAL",
    airportName: "Pichoy",
    cityCode: "ZAL",
    cityName: "Valdivia",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ZAM",
    airportName: "Zamboanga Intl.",
    cityCode: "ZAM",
    cityName: "Zamboanga",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "ZAO",
    airportName: "Laberandie",
    cityCode: "ZAO",
    cityName: "Cahors",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "ZAR",
    airportName: "Zaria",
    cityCode: "ZAR",
    cityName: "Zaria",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "ZAS",
    airportName: "Shunde Ferry",
    cityCode: "ZAS",
    cityName: "Shunde",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZAT",
    airportName: "Zhaotong",
    cityCode: "ZAT",
    cityName: "Zhaotong",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZAU",
    airportName: "Sachsen Railway Stn.",
    cityCode: "ZAU",
    cityName: "Aue",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZAX",
    airportName: "Pan Yu Ferry",
    cityCode: "ZAX",
    cityName: "Pan Yu",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZAY",
    airportName: "De Keyserlei Bus Stn.",
    cityCode: "ZAY",
    cityName: "Antwerp",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "ZAZ",
    airportName: "Zaragoza",
    cityCode: "ZAZ",
    cityName: "Zaragoza",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "ZBA",
    airportName: "Basel Bad - Railway",
    cityCode: "ZBA",
    cityName: "Basel/mulhouse",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "ZBC",
    airportName: "Colmore Row Bus Stn",
    cityCode: "ZBC",
    cityName: "Birmingham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ZBD",
    airportName: "Jiang Men Ferry",
    cityCode: "ZBD",
    cityName: "Jiang Men",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZBE",
    airportName: "Dolni Benesov",
    cityCode: "ZBE",
    cityName: "Zabreh",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "ZBF",
    airportName: "Bathurst",
    cityCode: "ZBF",
    cityName: "Bathurst",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZBK",
    airportName: "Zabljak",
    cityCode: "ZBK",
    cityName: "Zabljak",
    countryName: "Yugoslavia",
    countryCode: "YU",
  },
  {
    airportCode: "ZBL",
    airportName: "Biloela",
    cityCode: "ZBL",
    cityName: "Biloela",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ZBM",
    airportName: "Bromont",
    cityCode: "ZBM",
    cityName: "Bromont",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZBN",
    airportName: "Bus Station",
    cityCode: "ZBN",
    cityName: "Bozen",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZBO",
    airportName: "Bowen",
    cityCode: "ZBO",
    cityName: "Bowen",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ZBP",
    airportName: "Baltimore Rail",
    cityCode: "ZBP",
    cityName: "Baltimore",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZBR",
    airportName: "Chah-Bahar",
    cityCode: "ZBR",
    cityName: "Chah-Bahar",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "ZBY",
    airportName: "Sayaboury",
    cityCode: "ZBY",
    cityName: "Sayaboury",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "ZBZ",
    airportName: "Xin Hui Ferry",
    cityCode: "ZBZ",
    cityName: "Xin Hui",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZCA",
    airportName: "Tai Shan",
    cityCode: "ZCA",
    cityName: "Tai Shan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZCD",
    airportName: "Breitenau AAF",
    cityCode: "ZCD",
    cityName: "Bamberg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZCL",
    airportName: "La Calera",
    cityCode: "ZCL",
    cityName: "Zacatecas",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "ZCO",
    airportName: "Maquehue",
    cityCode: "ZCO",
    cityName: "Temuco",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ZCU",
    airportName: "She Kou Ferry",
    cityCode: "ZCU",
    cityName: "She Kou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZDM",
    airportName: "Ramallah Heliport",
    cityCode: "ZDM",
    cityName: "Ramallah",
    countryName: "Palestine",
    countryCode: "PS",
  },
  {
    airportCode: "ZDU",
    airportName: "ScotRail",
    cityCode: "ZDU",
    cityName: "Dundee",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ZEC",
    airportName: "Secunda",
    cityCode: "ZEC",
    cityName: "Secunda",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "ZEF",
    airportName: "Nanhai Ferry",
    cityCode: "ZEF",
    cityName: "Nanhai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZEG",
    airportName: "Senggo",
    cityCode: "ZEG",
    cityName: "Senggo",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ZEL",
    airportName: "Bella Bella",
    cityCode: "ZEL",
    cityName: "Bella Bella",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZEM",
    airportName: "East Main",
    cityCode: "ZEM",
    cityName: "East Main",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZEN",
    airportName: "Zenag",
    cityCode: "ZEN",
    cityName: "Zenag",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ZER",
    airportName: "Zero",
    cityCode: "ZER",
    cityName: "Zero",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "ZFA",
    airportName: "Faro",
    cityCode: "ZFA",
    cityName: "Faro",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZFB",
    airportName: "Old Fort Bay",
    cityCode: "ZFB",
    cityName: "Old Fort Bay",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZFC",
    airportName: "Bus Station",
    cityCode: "ZFC",
    cityName: "Bradford",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ZFD",
    airportName: "Fond Du Lac",
    cityCode: "ZFD",
    cityName: "Fond Du Lac",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZFJ",
    airportName: "Rennes",
    cityCode: "ZFJ",
    cityName: "Rennes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "ZFL",
    airportName: "South Trout Lake",
    cityCode: "ZFL",
    cityName: "South Trout Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZFM",
    airportName: "Fort Mcpherson",
    cityCode: "ZFM",
    cityName: "Fort Mcpherson",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZFN",
    airportName: "Tulita",
    cityCode: "ZFN",
    cityName: "Tulita/Fort Norman",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZFQ",
    airportName: "Gare de Bordeaux",
    cityCode: "ZFQ",
    cityName: "Bordeaux",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "ZFR",
    airportName: "Frankfurt/Oder Railway",
    cityCode: "ZFR",
    cityName: "Frankfurt/Oder",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZFU",
    airportName: "Aruja",
    cityCode: "ZFU",
    cityName: "Aruja",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ZFW",
    airportName: "Fairview",
    cityCode: "ZFW",
    cityName: "Fairview",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZFZ",
    airportName: "Buffalo Depew Rr",
    cityCode: "ZFZ",
    cityName: "Buffalo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZGA",
    airportName: "Gera Railway",
    cityCode: "ZGA",
    cityName: "Gera",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZGC",
    airportName: "Zhongchuan",
    cityCode: "ZGC",
    cityName: "Lanzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZGD",
    airportName: "New London Rail",
    cityCode: "ZGD",
    cityName: "Groton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZGE",
    airportName: "Goerlitz Railway",
    cityCode: "ZGE",
    cityName: "Goerlitz",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZGF",
    airportName: "Grand Forks",
    cityCode: "ZGF",
    cityName: "Grand Forks",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZGI",
    airportName: "Gods River",
    cityCode: "ZGI",
    cityName: "Gods River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZGL",
    airportName: "South Galway",
    cityCode: "ZGL",
    cityName: "South Galway",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ZGM",
    airportName: "Ngoma",
    cityCode: "ZGM",
    cityName: "Ngoma",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "ZGN",
    airportName: "Zhongshan Ferry Port",
    cityCode: "ZGN",
    cityName: "Zhongshan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZGO",
    airportName: "Gotha Railway",
    cityCode: "ZGO",
    cityName: "Gotha",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZGR",
    airportName: "Little Grand Rapids",
    cityCode: "ZGR",
    cityName: "Little Grand Rapids",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZGS",
    airportName: "Gethsemani",
    cityCode: "ZGS",
    cityName: "Gethsemani",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZGU",
    airportName: "Gaua",
    cityCode: "ZGU",
    cityName: "Gaua",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "ZGW",
    airportName: "Greifswald Railway",
    cityCode: "ZGW",
    cityName: "Greifswald",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZGY",
    airportName: "Kuching Port",
    cityCode: "ZGY",
    cityName: "Kuching",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "ZHA",
    airportName: "Zhanjiang",
    cityCode: "ZHA",
    cityName: "Zhanjiang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZHC",
    airportName: "N Philadelphia Rail St",
    cityCode: "ZHC",
    cityName: "Philadelphia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZHM",
    airportName: "Shamshernagar",
    cityCode: "ZHM",
    cityName: "Shamshernagar",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "ZHP",
    airportName: "High Prairie",
    cityCode: "ZHP",
    cityName: "High Prairie",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZHZ",
    airportName: "Halle Railway",
    cityCode: "ZHZ",
    cityName: "Halle",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZIC",
    airportName: "Victoria",
    cityCode: "ZIC",
    cityName: "Victoria",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ZIE",
    airportName: "Harbour",
    cityCode: "ZIE",
    cityName: "Vulcano",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZIF",
    airportName: "Fallowfield Railway",
    cityCode: "ZIF",
    cityName: "Ottawa",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZIG",
    airportName: "Ziguinchor",
    cityCode: "ZIG",
    cityName: "Ziguinchor",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "ZIH",
    airportName: "Internacional",
    cityCode: "ZIH",
    cityName: "Ixtapa/Zihuatanejo",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "ZIP",
    airportName: "Harbour",
    cityCode: "ZIP",
    cityName: "Lipari",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZIQ",
    airportName: "Harbour",
    cityCode: "ZIQ",
    cityName: "Salina",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZIU",
    airportName: "Railway",
    cityCode: "ZIU",
    cityName: "Aachen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZIV",
    airportName: "ScotRail",
    cityCode: "ZIV",
    cityName: "Inverness",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ZIY",
    airportName: "Lianhuashan Port",
    cityCode: "ZIY",
    cityName: "Lianhuashan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZJB",
    airportName: "Kuantan Port",
    cityCode: "ZJB",
    cityName: "Kuantan",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "ZJE",
    airportName: "Harbour",
    cityCode: "ZJE",
    cityName: "Panarea",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZJG",
    airportName: "Jenpeg",
    cityCode: "ZJG",
    cityName: "Jenpeg",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZJJ",
    airportName: "Harbour",
    cityCode: "ZJJ",
    cityName: "Procida",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZJK",
    airportName: "Off Line Point",
    cityCode: "ZJK",
    cityName: "Chek Lap Kok",
    countryName: "Hong Kong",
    countryCode: "HK",
  },
  {
    airportCode: "ZJN",
    airportName: "Swan River",
    cityCode: "ZJN",
    cityName: "Swan River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZJR",
    airportName: "Penang Port",
    cityCode: "ZJR",
    cityName: "Penang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "ZJS",
    airportName: "Jena Railway",
    cityCode: "ZJS",
    cityName: "Jena",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZJT",
    airportName: "Tanjung Pelepas Port",
    cityCode: "ZJT",
    cityName: "Tanjung Pelepas",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "ZJX",
    airportName: "Harbour",
    cityCode: "ZJX",
    cityName: "Stromboli",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZJY",
    airportName: "Harbour",
    cityCode: "ZJY",
    cityName: "Ponza",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZKB",
    airportName: "Kasaba Bay",
    cityCode: "ZKB",
    cityName: "Kasaba Bay",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "ZKE",
    airportName: "Kaschechewan",
    cityCode: "ZKE",
    cityName: "Kaschechewan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZKG",
    airportName: "Kegaska",
    cityCode: "ZKG",
    cityName: "Kegaska",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZKL",
    airportName: "Steenkool",
    cityCode: "ZKL",
    cityName: "Steenkool",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ZKM",
    airportName: "Sette Cama",
    cityCode: "ZKM",
    cityName: "Sette Cama",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "ZKP",
    airportName: "Kasompe",
    cityCode: "ZKP",
    cityName: "Kasompe",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "ZLG",
    airportName: "El Gouera",
    cityCode: "ZLG",
    cityName: "El Gouera",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "ZLO",
    airportName: "Manzanillo",
    cityCode: "ZLO",
    cityName: "Manzanillo",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "ZLT",
    airportName: "La Tabatiere",
    cityCode: "ZLT",
    cityName: "La Tabatiere",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZLW",
    airportName: "Pasir Gudang Port",
    cityCode: "ZLW",
    cityName: "Pasir Gudang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "ZMD",
    airportName: "Sena Madureira",
    cityCode: "ZMD",
    cityName: "Sena Madureira",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ZME",
    airportName: "Metropark Rail",
    cityCode: "ZME",
    cityName: "Newark",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZMF",
    airportName: "Beverello Harbor",
    cityCode: "ZMF",
    cityName: "Naples",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZMH",
    airportName: "108 Mile Ranch",
    cityCode: "ZMH",
    cityName: "108 Mile Ranch",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZMJ",
    airportName: "Mergellina Harbour",
    cityCode: "ZMJ",
    cityName: "Naples",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZMK",
    airportName: "Bintulu Port",
    cityCode: "ZMK",
    cityName: "Bintulu",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "ZML",
    airportName: "Milwaukee General Mitchell",
    cityCode: "ZML",
    cityName: "Milwaukee,",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZMM",
    airportName: "Zamora",
    cityCode: "ZMM",
    cityName: "Zamora",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "ZMO",
    airportName: "Bus Station",
    cityCode: "ZMO",
    cityName: "Modena",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZMP",
    airportName: "Manchester Bus Stn.",
    cityCode: "ZMP",
    cityName: "Manchester",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ZMR",
    airportName: "Bus Station",
    cityCode: "ZMR",
    cityName: "Meran",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ZMT",
    airportName: "Masset",
    cityCode: "ZMT",
    cityName: "Masset",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZMY",
    airportName: "Harbour",
    cityCode: "ZMY",
    cityName: "Huangpu",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZNA",
    airportName: "Harbour",
    cityCode: "ZNA",
    cityName: "Nanaimo",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZNC",
    airportName: "Nyac",
    cityCode: "ZNC",
    cityName: "Nyac",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZND",
    airportName: "Zinder",
    cityCode: "ZND",
    cityName: "Zinder",
    countryName: "Niger",
    countryCode: "NE",
  },
  {
    airportCode: "ZNE",
    airportName: "Newman",
    cityCode: "ZNE",
    cityName: "Newman",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ZNG",
    airportName: "Negginan",
    cityCode: "ZNG",
    cityName: "Negginan",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZNU",
    airportName: "Namu",
    cityCode: "ZNU",
    cityName: "Namu, BC",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZNZ",
    airportName: "Kisauni",
    cityCode: "ZNZ",
    cityName: "Zanzibar",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "ZOF",
    airportName: "Ocean Falls",
    cityCode: "ZOF",
    cityName: "Ocean Falls",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZOS",
    airportName: "Canal Balo",
    cityCode: "ZOS",
    cityName: "Osorno",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ZPB",
    airportName: "Sachigo Lake",
    cityCode: "ZPB",
    cityName: "Sachigo Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZPC",
    airportName: "Pucon",
    cityCode: "ZPC",
    cityName: "Pucon",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ZPH",
    airportName: "Zephyrhills",
    cityCode: "ZPH",
    cityName: "Zephyrhills",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZPO",
    airportName: "Pine House",
    cityCode: "ZPO",
    cityName: "Pine House",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZQN",
    airportName: "Queenstown",
    cityCode: "ZQN",
    cityName: "Queenstown",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "ZQS",
    airportName: "Queen Charlotte Is",
    cityCode: "ZQS",
    cityName: "Queen Charlotte Is",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZRC",
    airportName: "Bus Station",
    cityCode: "ZRC",
    cityName: "San Pedro de Alcantara",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "ZRD",
    airportName: "Richmond VA Rail",
    cityCode: "ZRD",
    cityName: "Richmond",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZRF",
    airportName: "Park & Ride Bus Svc",
    cityCode: "ZRF",
    cityName: "Rockford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZRH",
    airportName: "Zurich-Kloten",
    cityCode: "ZRH",
    cityName: "Zurich",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "ZRI",
    airportName: "Serui",
    cityCode: "ZRI",
    cityName: "Serui",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ZRJ",
    airportName: "Round Lake",
    cityCode: "ZRJ",
    cityName: "Round Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZRK",
    airportName: "VanGalder Bus Termina",
    cityCode: "ZRK",
    cityName: "Rockford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZRL",
    airportName: "Lancaster PA Rail",
    cityCode: "ZRL",
    cityName: "Lancaster",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZRM",
    airportName: "Sarmi",
    cityCode: "ZRM",
    cityName: "Sarmi",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ZRP",
    airportName: "Pennsylvania Railroad Station",
    cityCode: "ZRP",
    cityName: "Newark",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZRR",
    airportName: "Harbour",
    cityCode: "ZRR",
    cityName: "Fiumicino",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZRV",
    airportName: "Providence Rail",
    cityCode: "ZRV",
    cityName: "Providence",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZRZ",
    airportName: "New Carrolton RR",
    cityCode: "ZRZ",
    cityName: "Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZSA",
    airportName: "San Salvador",
    cityCode: "ZSA",
    cityName: "San Salvador",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "ZSE",
    airportName: "St Pierre dela Reunion",
    cityCode: "ZSE",
    cityName: "St Pierre dela Reunion",
    countryName: "Reunion",
    countryCode: "RE",
  },
  {
    airportCode: "ZSF",
    airportName: "Springfield MA RR",
    cityCode: "ZSF",
    cityName: "Springfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZSH",
    airportName: "Bus Station",
    cityCode: "ZSH",
    cityName: "Santa Fe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZSJ",
    airportName: "Sandy Lake",
    cityCode: "ZSJ",
    cityName: "Sandy Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZSL",
    airportName: "Bus Station",
    cityCode: "ZSL",
    cityName: "Sligo",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "ZSP",
    airportName: "St Paul",
    cityCode: "ZSP",
    cityName: "St Paul",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZSS",
    airportName: "Sassandra",
    cityCode: "ZSS",
    cityName: "Sassandra",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "ZST",
    airportName: "Stewart",
    cityCode: "ZST",
    cityName: "Stewart",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZSU",
    airportName: "Dessau Railway Stn.",
    cityCode: "ZSU",
    cityName: "Dessau",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZSW",
    airportName: "Seal Cove",
    cityCode: "ZSW",
    cityName: "Prince Rupert",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZSZ",
    airportName: "Railway Station",
    cityCode: "ZSZ",
    cityName: "Swakopmund",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "ZTA",
    airportName: "Tureira",
    cityCode: "ZTA",
    cityName: "Tureira",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "ZTB",
    airportName: "Tete-a-La Baleine",
    cityCode: "ZTB",
    cityName: "Tete-a-La Baleine",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZTD",
    airportName: "Schenectady Rail",
    cityCode: "ZTD",
    cityName: "Schenectady",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZTE",
    airportName: "Rochester NY Rail",
    cityCode: "ZTE",
    cityName: "Rochester",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZTF",
    airportName: "Stamford Rail STN",
    cityCode: "ZTF",
    cityName: "Westchester County",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZTH",
    airportName: "Zakinthos Intl. Arpt.",
    cityCode: "ZTH",
    cityName: "Zakinthos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "ZTI",
    airportName: "Humen Port",
    cityCode: "ZTI",
    cityName: "Humen",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZTJ",
    airportName: "Princeton JT Rail",
    cityCode: "ZTJ",
    cityName: "Princeton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZTL",
    airportName: "Bus Station",
    cityCode: "ZTL",
    cityName: "Telluride",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZTM",
    airportName: "Shamattawa",
    cityCode: "ZTM",
    cityName: "Shamattawa",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZTN",
    airportName: "Trenton Rail STN",
    cityCode: "ZTN",
    cityName: "Philadelphia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZTR",
    airportName: "Zhitomir Arpt.",
    cityCode: "ZTR",
    cityName: "Zhitomir",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "ZTT",
    airportName: "Cottbus Railway Stn.",
    cityCode: "ZTT",
    cityName: "Cottbus",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZTZ",
    airportName: "Chemnitz Railway Stn.",
    cityCode: "ZTZ",
    cityName: "Chemnitz",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZUA",
    airportName: "Utica NNY Rail",
    cityCode: "ZUA",
    cityName: "Utica",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZUC",
    airportName: "Ignace",
    cityCode: "ZUC",
    cityName: "Ignace",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZUD",
    airportName: "Ancud",
    cityCode: "ZUD",
    cityName: "Ancud",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ZUE",
    airportName: "Zuenoula",
    cityCode: "ZUE",
    cityName: "Zuenoula",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "ZUG",
    airportName: "Harrisburg Rail",
    cityCode: "ZUG",
    cityName: "Harrisburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZUL",
    airportName: "Zilfi",
    cityCode: "ZUL",
    cityName: "Zilfi",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "ZUM",
    airportName: "Churchill Falls",
    cityCode: "ZUM",
    cityName: "Churchill Falls",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZVA",
    airportName: "Miandrivazo",
    cityCode: "ZVA",
    cityName: "Miandrivazo",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "ZVE",
    airportName: "New Haven Rail",
    cityCode: "ZVE",
    cityName: "New Haven",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZVG",
    airportName: "Springvale",
    cityCode: "ZVG",
    cityName: "Springvale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ZVK",
    airportName: "Savannakhet",
    cityCode: "ZVK",
    cityName: "Savannakhet",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "ZVM",
    airportName: "Messe-BF Railway Servi",
    cityCode: "ZVM",
    cityName: "Hanover",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZVR",
    airportName: "Hanover Railway",
    cityCode: "ZVR",
    cityName: "Hanover",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "ZWA",
    airportName: "Andapa",
    cityCode: "ZWA",
    cityName: "Andapa",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "ZWB",
    airportName: "Williamsburg Rail",
    cityCode: "ZWB",
    cityName: "Hampton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZWH",
    airportName: "Railway Station",
    cityCode: "ZWH",
    cityName: "Windhoek",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "ZWI",
    airportName: "Wilmington Rail",
    cityCode: "ZWI",
    cityName: "Wilmington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZWL",
    airportName: "Wollaston Lake",
    cityCode: "ZWL",
    cityName: "Wollaston Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ZWR",
    airportName: "Kota Kinabalu Port",
    cityCode: "ZWR",
    cityName: "Kota Kinabalu",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "ZWW",
    airportName: "Newport News Rail",
    cityCode: "ZWW",
    cityName: "Hampton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZXP",
    airportName: "ScotRail",
    cityCode: "ZXP",
    cityName: "Perth",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ZXS",
    airportName: "Exchangest Railway Svc",
    cityCode: "ZXS",
    cityName: "Buffalo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZXT",
    airportName: "Heydar Aliyev",
    cityCode: "ZXT",
    cityName: "Baku",
    countryName: "Azerbaijan",
    countryCode: "AZ",
  },
  {
    airportCode: "ZYD",
    airportName: "Lisbon TP",
    cityCode: "ZYD",
    cityName: "Lisbon",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "ZYF",
    airportName: "Faro TP",
    cityCode: "ZYF",
    cityName: "Faro",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "ZYH",
    airportName: "Holland Spoor Rail Station",
    cityCode: "ZYH",
    cityName: "The Hague",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "ZYI",
    airportName: "Zunyi",
    cityCode: "ZYI",
    cityName: "Zunyi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZYK",
    airportName: "Shekou Port",
    cityCode: "ZYK",
    cityName: "Shekou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZYL",
    airportName: "Osmani Intl.",
    cityCode: "ZYL",
    cityName: "Sylhet",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "ZYZ",
    airportName: "Berchem Railway Stn.",
    cityCode: "ZYZ",
    cityName: "Antwerp",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "ZZU",
    airportName: "Mzuzu",
    cityCode: "ZZU",
    cityName: "Mzuzu",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "ZZV",
    airportName: "Zanesville",
    cityCode: "ZZV",
    cityName: "Zanesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZYR",
    airportName: "Brussels Midi",
    cityCode: "ZYR",
    cityName: "Brussels",
    countryName: "Belgium",
    countryCode: "",
  },
  {
    airportCode: "ZWE",
    airportName: "Antwerp, Central Railway Stn.",
    cityCode: "ZWE",
    cityName: "Antwerp",
    countryName: "Belgium",
    countryCode: "KL",
  },
  {
    airportCode: "QKL",
    airportName: "Koeln Hauptbahnhof ",
    cityCode: "QKL",
    cityName: "Koeln Hauptbahnhof ",
    countryName: "Cologne ",
    countryCode: "",
  },
  {
    airportCode: "GMO",
    airportName: "Gombe ",
    cityCode: "GMO",
    cityName: "Gombe ",
    countryName: "Nigeria",
    countryCode: "",
  },
  {
    airportCode: "BKG",
    airportName: "Branson",
    cityCode: "BKG",
    cityName: "Branson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XNB",
    airportName: "Dubai Chelsea Bus Station",
    cityCode: "DXB",
    cityName: "Dubai",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "ZVJ",
    airportName: "Abu Dhabi Bus Station",
    cityCode: "AUH",
    cityName: "Abu Dhabi",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "ZDH",
    airportName: "Basel/Mulhouse Railway Station",
    cityCode: "BSL",
    cityName: "Basel",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "ZEP",
    airportName: "Victoria Railway Station",
    cityCode: "ZEP",
    cityName: "London",
    countryName: "United Kingdom",
    countryCode: "UK",
  },
  {
    airportCode: "QJZ",
    airportName: "Nantes Railway Station",
    cityCode: "QJZ",
    cityName: "Nantes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "DQA",
    airportName: "Daqing Saertu",
    cityCode: "DQA",
    cityName: "Daqing",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "ZWS",
    airportName: "Stuttgart Hauptbahnhof",
    cityCode: "STR",
    cityName: "Stuttgart",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "YXX",
    airportName: "Abbotsford International Airport",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YVR",
    airportName: "Vancouver International Airport",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YLY",
    airportName: "Langley Regional Airport",
    cityCode: "YVR",
    cityName: "Vancouver",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "NYT",
    airportName: "Naypyidaw",
    cityCode: "NYT",
    cityName: "Naypyidaw",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "YJV",
    airportName: "Valencia Rail Station",
    cityCode: "YJV",
    cityName: "Valencia",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "XDS",
    airportName: "OTTAWA RR STATION",
    cityCode: "XDS",
    cityName: "OTTAWA",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "SEA",
    airportName: "Seattle–Tacoma International Airport",
    cityCode: "SEA",
    cityName: "Seattle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ZFV",
    airportName: "Philadelphia Rail Station",
    cityCode: "ZFV",
    cityName: "Philadelphia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "OSA",
    airportName: "Osaka Metropolitan Area",
    cityCode: "OSA",
    cityName: "Osaka",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "RYG",
    airportName: "Moss Airport, Rygge",
    cityCode: "OSL",
    cityName: "Oslo",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "HMB",
    airportName: "Sohag",
    cityCode: "HMB",
    cityName: "Sawhaj",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "ZUH",
    airportName: "Zhuhai",
    cityCode: "ZUH",
    cityName: "Zhuhai",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "VDH",
    airportName: "Dong Hoi Airport",
    cityCode: "VDH",
    cityName: "Dong Hoi",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "QKU",
    airportName: "Cologne Deutz Railway Airport",
    cityCode: "CGN",
    cityName: "Cologne",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "BHZ",
    airportName: "Belo Horizonte Metropolitian Area",
    cityCode: "BHZ",
    cityName: "Belo Horizonte",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "WUS",
    airportName: "Fujian Province Airport",
    cityCode: "WUS",
    cityName: "Wuyishan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "BUH",
    airportName: "Bucharest Metropolitan Area",
    cityCode: "BUH",
    cityName: "Bucharest",
    countryName: "Romania",
    countryCode: "RO",
  },
  {
    airportCode: "BUE",
    airportName: "Buenos Aires Metropolitan Area",
    cityCode: "BUE",
    cityName: "Buenos Aires",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "WUA",
    airportName: "WU HAI",
    cityCode: "WUA",
    cityName: "WU HAI",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "XQE",
    airportName: "Ebbsfleet Kent Station",
    cityCode: "XQE",
    cityName: "London",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "QDH",
    airportName: "Ashford International Rail Station",
    cityCode: "QDH",
    cityName: "Ashford",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "YEA",
    airportName: "Edmonton Metropolitan Area",
    cityCode: "YEA",
    cityName: "Edmonton",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "HHN",
    airportName: "Frankfurt-Hahn",
    cityCode: "FRA",
    cityName: "Hahn",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "DWC",
    airportName: "Al Maktoum International",
    cityCode: "DXB",
    cityName: "Dubai",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "FSZ",
    airportName: "Shizuoka Airport",
    cityCode: "FSZ",
    cityName: "Shizuoka",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "JKT",
    airportName: "Soekarno-hatta Intl",
    cityCode: "JKT",
    cityName: "Jakarta",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "YMQ",
    airportName: "Montreal Metropolitan Area",
    cityCode: "YMQ",
    cityName: "Montreal",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "YRO",
    airportName: "Rockcliffe St",
    cityCode: "YOW",
    cityName: "Ottawa",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "AZA",
    airportName: "Phoenix-Mesa Gateway Airport",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "REK",
    airportName: "Reykjavik Metropolitan Area",
    cityCode: "REK",
    cityName: "Reykjavik",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "SPK",
    airportName: "Metropolitan Area",
    cityCode: "SPK",
    cityName: "Sapporo",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "SEL",
    airportName: "Seoul Metropolitan Area",
    cityCode: "SEL",
    cityName: "Seoul",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "SSN",
    airportName: "Seoul Ab",
    cityCode: "SEL",
    cityName: "Seoul",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "IGB",
    airportName: "Ingeniero Jacobacci",
    cityCode: "IGB",
    cityName: "Ingeniero Jacobacci",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "IGE",
    airportName: "Iguela",
    cityCode: "IGE",
    cityName: "Iguela",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "IGG",
    airportName: "Igiugig",
    cityCode: "IGG",
    cityName: "Igiugig",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IGH",
    airportName: "Ingham",
    cityCode: "IGH",
    cityName: "Ingham",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "IGL",
    airportName: "Cigli Military Arpt",
    cityCode: "IGL",
    cityName: "Izmir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "IGM",
    airportName: "Kingman",
    cityCode: "IGM",
    cityName: "Kingman",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IGN",
    airportName: "Maria Cristina",
    cityCode: "IGN",
    cityName: "Iligan",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "IGO",
    airportName: "Chigorodo",
    cityCode: "IGO",
    cityName: "Chigorodo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "IGR",
    airportName: "Cataratas",
    cityCode: "IGR",
    cityName: "Iguazu",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "IGS",
    airportName: "Ingolstadt-manching",
    cityCode: "IGS",
    cityName: "Ingolstadt-manching",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "IGU",
    airportName: "Cataratas",
    cityCode: "IGU",
    cityName: "Iguassu Falls",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IHA",
    airportName: "Niihama",
    cityCode: "IHA",
    cityName: "Niihama",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "IHC",
    airportName: "Inhaca",
    cityCode: "IHC",
    cityName: "Inhaca",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "IHN",
    airportName: "Qishn",
    cityCode: "IHN",
    cityName: "Qishn",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "IHO",
    airportName: "Ihosy",
    cityCode: "IHO",
    cityName: "Ihosy",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "IHR",
    airportName: "Iran Shahr",
    cityCode: "IHR",
    cityName: "Iran Shahr",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "IHU",
    airportName: "Ihu",
    cityCode: "IHU",
    cityName: "Ihu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IIA",
    airportName: "Inishmaan",
    cityCode: "IIA",
    cityName: "Galloway",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "IIL",
    airportName: "Ilaam",
    cityCode: "IIL",
    cityName: "Ilaam",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "IIN",
    airportName: "Nishinoomote",
    cityCode: "IIN",
    cityName: "Nishinoomote",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "IIS",
    airportName: "Nissan Island",
    cityCode: "IIS",
    cityName: "Nissan Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IJK",
    airportName: "Izhevsk",
    cityCode: "IJK",
    cityName: "Izhevsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "IJU",
    airportName: "J. Batista Bos Filho Arpt.",
    cityCode: "IJU",
    cityName: "Ijui",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IJX",
    airportName: "Municipal",
    cityCode: "IJX",
    cityName: "Jacksonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IKA",
    airportName: "Imam Khomeini Airport",
    cityCode: "THR",
    cityName: "Tehran",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "IKB",
    airportName: "Wilkes County",
    cityCode: "IKB",
    cityName: "Wilkesboro",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IKI",
    airportName: "Iki",
    cityCode: "IKI",
    cityName: "Iki",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "IKK",
    airportName: "Greater Kankakee",
    cityCode: "IKK",
    cityName: "Kankakee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IKL",
    airportName: "Ikela",
    cityCode: "IKL",
    cityName: "Ikela",
    countryName: "Democratic Republic of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "IKO",
    airportName: "Nikolski AFS",
    cityCode: "IKO",
    cityName: "Nikolski",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IKP",
    airportName: "Inkerman",
    cityCode: "IKP",
    cityName: "Inkerman",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "IKS",
    airportName: "Tiksi",
    cityCode: "IKS",
    cityName: "Tiksi",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "IKT",
    airportName: "Irkutsk",
    cityCode: "IKT",
    cityName: "Irkutsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ILA",
    airportName: "Illaga",
    cityCode: "ILA",
    cityName: "Illaga",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "ILB",
    airportName: "Ilha Solteira",
    cityCode: "ILB",
    cityName: "Ilha Solteira",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ILE",
    airportName: "Municipal",
    cityCode: "ILE",
    cityName: "Killeen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ILF",
    airportName: "Ilford",
    cityCode: "ILF",
    cityName: "Ilford",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "ILG",
    airportName: "Greater Wilmington",
    cityCode: "ILG",
    cityName: "Wilmington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ILI",
    airportName: "Iliamna",
    cityCode: "ILI",
    cityName: "Iliamna",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ILK",
    airportName: "Ilaka",
    cityCode: "ILK",
    cityName: "Ilaka",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "ILL",
    airportName: "Willmar",
    cityCode: "ILL",
    cityName: "Willmar",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ILM",
    airportName: "Wilmington Intl.",
    cityCode: "ILM",
    cityName: "Wilmington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ILN",
    airportName: "Clinton Field",
    cityCode: "ILN",
    cityName: "Wilmington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ILO",
    airportName: "Iloilo Intl.",
    cityCode: "ILO",
    cityName: "Iloilo",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "ILP",
    airportName: "Ile Des Pins",
    cityCode: "ILP",
    cityName: "Ile Des Pins",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "ILQ",
    airportName: "Ilo",
    cityCode: "ILQ",
    cityName: "Ilo",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "ILR",
    airportName: "Ilorin",
    cityCode: "ILR",
    cityName: "Ilorin",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "ILU",
    airportName: "Kilaguni",
    cityCode: "ILU",
    cityName: "Kilaguni",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "ILX",
    airportName: "Ileg",
    cityCode: "ILX",
    cityName: "Ileg",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ILY",
    airportName: "Glenegedale",
    cityCode: "ILY",
    cityName: "Islay",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ILZ",
    airportName: "Zilina",
    cityCode: "ILZ",
    cityName: "Zilina",
    countryName: "Slovak Republic",
    countryCode: "SK",
  },
  {
    airportCode: "IMA",
    airportName: "Iamalele",
    cityCode: "IMA",
    cityName: "Iamalele",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IMB",
    airportName: "Imbaimadai",
    cityCode: "IMB",
    cityName: "Imbaimadai",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "IMD",
    airportName: "Imonda",
    cityCode: "IMD",
    cityName: "Imonda",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IMF",
    airportName: "Municipal",
    cityCode: "IMF",
    cityName: "Imphal",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IMG",
    airportName: "Inhaminga",
    cityCode: "IMG",
    cityName: "Inhaminga",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "IMI",
    airportName: "Ine Island",
    cityCode: "IMI",
    cityName: "Ine Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "IMK",
    airportName: "Simikot",
    cityCode: "IMK",
    cityName: "Simikot",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "IML",
    airportName: "Imperial",
    cityCode: "IML",
    cityName: "Imperial",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IMM",
    airportName: "Immokalee",
    cityCode: "IMM",
    cityName: "Immokalee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IMN",
    airportName: "Imane",
    cityCode: "IMN",
    cityName: "Imane",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IMO",
    airportName: "Zemio",
    cityCode: "IMO",
    cityName: "Zemio",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "IMP",
    airportName: "Imperatriz",
    cityCode: "IMP",
    cityName: "Imperatriz",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IMT",
    airportName: "Ford",
    cityCode: "IMT",
    cityName: "Iron Mountain",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IMZ",
    airportName: "Nimroz",
    cityCode: "IMZ",
    cityName: "Nimroz",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "INA",
    airportName: "Inta",
    cityCode: "INA",
    cityName: "Inta",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "INB",
    airportName: "Independence",
    cityCode: "INB",
    cityName: "Independence",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "INC",
    airportName: "Yinchuan",
    cityCode: "INC",
    cityName: "Yinchuan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "IND",
    airportName: "Indianapolis Intl.",
    cityCode: "IND",
    cityName: "Indianapolis, IN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "INE",
    airportName: "Chinde",
    cityCode: "INE",
    cityName: "Chinde",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "INF",
    airportName: "Newark Liberty Intl.",
    cityCode: "INF",
    cityName: "In Guezzam",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "ING",
    airportName: "Lago Argentino",
    cityCode: "ING",
    cityName: "Lago Argentino",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "INH",
    airportName: "Inhambane",
    cityCode: "INH",
    cityName: "Inhambane",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "INI",
    airportName: "Constantine The Great Intl.",
    cityCode: "INI",
    cityName: "Nis",
    countryName: "Serbia",
    countryCode: "RS",
  },
  {
    airportCode: "INJ",
    airportName: "Injune",
    cityCode: "INJ",
    cityName: "Injune",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "INK",
    airportName: "Wink",
    cityCode: "INK",
    cityName: "Wink",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "INL",
    airportName: "Falls Intl",
    cityCode: "INL",
    cityName: "Intl. Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "INM",
    airportName: "Innamincka",
    cityCode: "INM",
    cityName: "Innamincka",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "INN",
    airportName: "Innsbruck-kranebitten",
    cityCode: "INN",
    cityName: "Innsbruck",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "INO",
    airportName: "Inongo",
    cityCode: "INO",
    cityName: "Inongo",
    countryName: "Democratic Republic of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "INQ",
    airportName: "Inisheer",
    cityCode: "INQ",
    cityName: "Inisheer",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "INR",
    airportName: "Kincheloe AFB",
    cityCode: "INR",
    cityName: "Sault Ste Marie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "INS",
    airportName: "Af Aux",
    cityCode: "INS",
    cityName: "Indian Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "INT",
    airportName: "Smith-Reynolds",
    cityCode: "INT",
    cityName: "Winston Salem",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "INU",
    airportName: "Nauru Intl.",
    cityCode: "INU",
    cityName: "Nauru Island",
    countryName: "Nauru",
    countryCode: "NR",
  },
  {
    airportCode: "INV",
    airportName: "Inverness",
    cityCode: "INV",
    cityName: "Inverness",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "INW",
    airportName: "Winslow-Lindbergh",
    cityCode: "INW",
    cityName: "Winslow, AZ",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "INX",
    airportName: "Inanwatan",
    cityCode: "INX",
    cityName: "Inanwatan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "INY",
    airportName: "Inyati",
    cityCode: "INY",
    cityName: "Inyati",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "INZ",
    airportName: "In Salah",
    cityCode: "INZ",
    cityName: "In Salah",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "IOA",
    airportName: "Ioannina",
    cityCode: "IOA",
    cityName: "Ioannina",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "IOK",
    airportName: "Iokea",
    cityCode: "IOK",
    cityName: "Iokea",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IOM",
    airportName: "Ronaldsway",
    cityCode: "IOM",
    cityName: "Isle Of Man",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ION",
    airportName: "Impfondo",
    cityCode: "ION",
    cityName: "Impfondo",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "IOP",
    airportName: "Ioma",
    cityCode: "IOP",
    cityName: "Ioma",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IOR",
    airportName: "Kilronan",
    cityCode: "IOR",
    cityName: "Inishmore",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "IOS",
    airportName: "Eduardo Gomes",
    cityCode: "IOS",
    cityName: "Ilheus",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IOU",
    airportName: "Ile Ouen",
    cityCode: "IOU",
    cityName: "Ile Ouen",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "IOW",
    airportName: "Iowa City",
    cityCode: "IOW",
    cityName: "Iowa City, IA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IPA",
    airportName: "Ipota",
    cityCode: "IPA",
    cityName: "Ipota",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "IPC",
    airportName: "Mataveri Intl",
    cityCode: "IPC",
    cityName: "Easter Island",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "IPE",
    airportName: "Ipil",
    cityCode: "IPE",
    cityName: "Ipil",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "IPG",
    airportName: "Ipiranga",
    cityCode: "IPG",
    cityName: "Ipiranga",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IPH",
    airportName: "Sultan Azlan Shah Arpt.",
    cityCode: "IPH",
    cityName: "Ipoh",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "IPI",
    airportName: "San Luis",
    cityCode: "IPI",
    cityName: "Ipiales",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "IPL",
    airportName: "Imperial County",
    cityCode: "IPL",
    cityName: "Imperial",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IPN",
    airportName: "Usiminas",
    cityCode: "IPN",
    cityName: "Ipatinga",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IPT",
    airportName: "Lycoming County",
    cityCode: "IPT",
    cityName: "Williamsport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IPU",
    airportName: "Ipiau",
    cityCode: "IPU",
    cityName: "Ipiau",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IPW",
    airportName: "Ipswich",
    cityCode: "IPW",
    cityName: "Ipswich",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "IQM",
    airportName: "Qiemo",
    cityCode: "IQM",
    cityName: "Qiemo",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "IQN",
    airportName: "Qingyang",
    cityCode: "IQN",
    cityName: "Qingyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "IQQ",
    airportName: "Cavancha",
    cityCode: "IQQ",
    cityName: "Iquique",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "IQT",
    airportName: "C.F. Secada",
    cityCode: "IQT",
    cityName: "Iquitos",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "IRA",
    airportName: "Kirakira",
    cityCode: "IRA",
    cityName: "Kirakira",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "IRB",
    airportName: "Municipal",
    cityCode: "IRB",
    cityName: "Iraan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IRC",
    airportName: "Circle City",
    cityCode: "IRC",
    cityName: "Circle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IRD",
    airportName: "Ishurdi",
    cityCode: "IRD",
    cityName: "Ishurdi",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "IRE",
    airportName: "Irece",
    cityCode: "IRE",
    cityName: "Irece",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IRG",
    airportName: "Lockhart River",
    cityCode: "IRG",
    cityName: "Lockhart River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "IRI",
    airportName: "Nduli",
    cityCode: "IRI",
    cityName: "Iringa",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "IRJ",
    airportName: "Capitan Vicente Almando",
    cityCode: "IRJ",
    cityName: "La Rioja",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "IRK",
    airportName: "Municipal",
    cityCode: "IRK",
    cityName: "Kirksville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IRN",
    airportName: "Iriona",
    cityCode: "IRN",
    cityName: "Iriona",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "IRO",
    airportName: "Birao",
    cityCode: "IRO",
    cityName: "Birao",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "IRP",
    airportName: "Matari",
    cityCode: "IRP",
    cityName: "Isiro",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "IRS",
    airportName: "Kirsch Municipal",
    cityCode: "IRS",
    cityName: "Sturgis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ISA",
    airportName: "Mount Isa",
    cityCode: "ISA",
    cityName: "Mount Isa",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ISB",
    airportName: "Islamabad Intl.",
    cityCode: "ISB",
    cityName: "Islamabad",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "ISC",
    airportName: "St Marys",
    cityCode: "ISC",
    cityName: "Isles Of Scilly",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ISD",
    airportName: "Iscuande",
    cityCode: "ISD",
    cityName: "Iscuande",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "ISG",
    airportName: "Ishigaki",
    cityCode: "ISG",
    cityName: "Ishigaki",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "ISH",
    airportName: "Ischia",
    cityCode: "ISH",
    cityName: "Ischia",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ISI",
    airportName: "Isisford",
    cityCode: "ISI",
    cityName: "Isisford",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ISJ",
    airportName: "Isla Mujeres",
    cityCode: "ISJ",
    cityName: "Isla Mujeres",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "ISK",
    airportName: "Gandhinagar Arpt",
    cityCode: "ISK",
    cityName: "Nasik",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "ISL",
    airportName: "Isabel Pass",
    cityCode: "ISL",
    cityName: "Isabel Pass",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ISM",
    airportName: "Kissimmee Gateway",
    cityCode: "ISM",
    cityName: "Kissimmee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ISN",
    airportName: "Sloulin Field Intl",
    cityCode: "ISN",
    cityName: "Williston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ISO",
    airportName: "Stallings Field",
    cityCode: "ISO",
    cityName: "Kinston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ISP",
    airportName: "Long Island Mac Arthur",
    cityCode: "NYC",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ISS",
    airportName: "Wiscasset",
    cityCode: "ISS",
    cityName: "Wiscasset",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IST",
    airportName: "Ataturk",
    cityCode: "IST",
    cityName: "Istanbul",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ISW",
    airportName: "Alexander Field",
    cityCode: "ISW",
    cityName: "Wisconsin Rapids",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ISZ",
    airportName: "Cincinnati-Blue Ash Arpt.",
    cityCode: "ISZ",
    cityName: "Cincinnati, OH",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ITA",
    airportName: "Itacoatiara",
    cityCode: "ITA",
    cityName: "Itacoatiara",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ITB",
    airportName: "Itaituba",
    cityCode: "ITB",
    cityName: "Itaituba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ITE",
    airportName: "Itubera",
    cityCode: "ITE",
    cityName: "Itubera",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ITH",
    airportName: "Tompkins County",
    cityCode: "ITH",
    cityName: "Ithaca",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ITI",
    airportName: "Itambacuri",
    cityCode: "ITI",
    cityName: "Itambacuri",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ITK",
    airportName: "Itokama",
    cityCode: "ITK",
    cityName: "Itokama",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "ITM",
    airportName: "Itami",
    cityCode: "OSA",
    cityName: "Osaka",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "ITN",
    airportName: "Itabuna",
    cityCode: "ITN",
    cityName: "Itabuna",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ITO",
    airportName: "Hilo Intl.",
    cityCode: "ITO",
    cityName: "Hilo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ITP",
    airportName: "Itaperuna",
    cityCode: "ITP",
    cityName: "Itaperuna",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ITQ",
    airportName: "Itaqui",
    cityCode: "ITQ",
    cityName: "Itaqui",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ITR",
    airportName: "Hidroeletrica",
    cityCode: "ITR",
    cityName: "Itumbiara",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "IUE",
    airportName: "Hanan",
    cityCode: "IUE",
    cityName: "Niue Island",
    countryName: "Niue",
    countryCode: "NU",
  },
  {
    airportCode: "IUL",
    airportName: "Ilu",
    cityCode: "IUL",
    cityName: "Ilu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "IUM",
    airportName: "Summit Lake",
    cityCode: "IUM",
    cityName: "Summit Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "IUS",
    airportName: "Inus",
    cityCode: "IUS",
    cityName: "Inus",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "IVA",
    airportName: "Ambanja",
    cityCode: "IVA",
    cityName: "Ambanja",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "IVC",
    airportName: "Invercargill",
    cityCode: "IVC",
    cityName: "Invercargill",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "IVG",
    airportName: "Ivangrad",
    cityCode: "IVG",
    cityName: "Berane",
    countryName: "Montenegro",
    countryCode: "ME",
  },
  {
    airportCode: "IVH",
    airportName: "Ivishak",
    cityCode: "IVH",
    cityName: "Ivishak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IVL",
    airportName: "Ivalo",
    cityCode: "IVL",
    cityName: "Ivalo",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "IVO",
    airportName: "Chivolo",
    cityCode: "IVO",
    cityName: "Chivolo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "IVR",
    airportName: "Inverell",
    cityCode: "IVR",
    cityName: "Inverell",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "IVW",
    airportName: "Inverway",
    cityCode: "IVW",
    cityName: "Inverway",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "IWA",
    airportName: "Ivanova",
    cityCode: "IWA",
    cityName: "Ivanova",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "IWD",
    airportName: "Gogebic County",
    cityCode: "IWD",
    cityName: "Ironwood",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IWJ",
    airportName: "Iwami",
    cityCode: "IWJ",
    cityName: "Iwami",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "IWO",
    airportName: "Iwo Jima Airbase",
    cityCode: "IWO",
    cityName: "Iwo Jima Vol",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "IXA",
    airportName: "Maharaja Bir Bikram Manikya Kishore Airport.",
    cityCode: "IXA",
    cityName: "Agartala",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXB",
    airportName: "Bagdogra",
    cityCode: "IXB",
    cityName: "Bagdogra",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXC",
    airportName: "Shaheed Bhagat Singh International Airport",
    cityCode: "IXC",
    cityName: "Chandigarh",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXD",
    airportName: "Bamrauli",
    cityCode: "IXD",
    cityName: "Allahabad",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXE",
    airportName: "Bajpe",
    cityCode: "IXE",
    cityName: "Mangalore",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXG",
    airportName: "Sambre",
    cityCode: "IXG",
    cityName: "Belgaum",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXH",
    airportName: "Kailashahar",
    cityCode: "IXH",
    cityName: "Kailashahar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXI",
    airportName: "Lilabari",
    cityCode: "IXI",
    cityName: "Lilabari",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXJ",
    airportName: "Satwari",
    cityCode: "IXJ",
    cityName: "Jammu",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXK",
    airportName: "Keshod",
    cityCode: "IXK",
    cityName: "Keshod",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXL",
    airportName: "Bakula Rimpoche",
    cityCode: "IXL",
    cityName: "Leh",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXM",
    airportName: "Madurai",
    cityCode: "IXM",
    cityName: "Madurai",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXN",
    airportName: "Khowai",
    cityCode: "IXN",
    cityName: "Khowai",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXP",
    airportName: "Pathankot",
    cityCode: "IXP",
    cityName: "Pathankot",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXQ",
    airportName: "Kamalpur",
    cityCode: "IXQ",
    cityName: "Kamalpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXR",
    airportName: "Birsa Munda Intl.",
    cityCode: "IXR",
    cityName: "Ranchi",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXS",
    airportName: "Kumbhirgram",
    cityCode: "IXS",
    cityName: "Silchar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXT",
    airportName: "Pasighat",
    cityCode: "IXT",
    cityName: "Pasighat",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXU",
    airportName: "Chikkalthana",
    cityCode: "IXU",
    cityName: "Aurangabad",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXV",
    airportName: "Along",
    cityCode: "IXV",
    cityName: "Along",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXW",
    airportName: "Sonari",
    cityCode: "IXW",
    cityName: "Jamshedpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXY",
    airportName: "Kandla",
    cityCode: "IXY",
    cityName: "Kandla",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IXZ",
    airportName: "Port Blair",
    cityCode: "IXZ",
    cityName: "Port Blair",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "IYK",
    airportName: "Kern County",
    cityCode: "IYK",
    cityName: "Inyokern",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "IZO",
    airportName: "Izumo",
    cityCode: "IZO",
    cityName: "Izumo",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "IZT",
    airportName: "Ixtepec",
    cityCode: "IZT",
    cityName: "Ixtepec",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "JAA",
    airportName: "Jalalabad",
    cityCode: "JAA",
    cityName: "Jalalabad",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "JAB",
    airportName: "Jabiru",
    cityCode: "JAB",
    cityName: "Jabiru",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "JAC",
    airportName: "Jackson Hole",
    cityCode: "JAC",
    cityName: "Jackson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JAD",
    airportName: "Jandakot",
    cityCode: "JAD",
    cityName: "Perth",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "JAE",
    airportName: "Technology Park",
    cityCode: "JAE",
    cityName: "Atlanta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JAF",
    airportName: "Kankesanturai",
    cityCode: "JAF",
    cityName: "Jaffna",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "JAG",
    airportName: "Jacobabad",
    cityCode: "JAG",
    cityName: "Jacobabad",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "JAH",
    airportName: "Agora Helipad",
    cityCode: "JAH",
    cityName: "Aubagne",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "JAI",
    airportName: "Jaipur International Airport",
    cityCode: "JAI",
    cityName: "Jaipur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "JAJ",
    airportName: "Perimeter Mall",
    cityCode: "JAJ",
    cityName: "Atlanta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JAK",
    airportName: "Jacmel",
    cityCode: "JAK",
    cityName: "Jacmel",
    countryName: "Haiti",
    countryCode: "HT",
  },
  {
    airportCode: "JAL",
    airportName: "Jalapa",
    cityCode: "JAL",
    cityName: "Jalapa",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "JAM",
    airportName: "Jambol",
    cityCode: "JAM",
    cityName: "Jambol",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "JAN",
    airportName: "Jackson-evers",
    cityCode: "JAN",
    cityName: "Jackson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JAO",
    airportName: "Beaver Ruin",
    cityCode: "JAO",
    cityName: "Atlanta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JAP",
    airportName: "Punta Renes",
    cityCode: "JAP",
    cityName: "Punta Renes",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "JAQ",
    airportName: "Jacquinot Bay",
    cityCode: "JAQ",
    cityName: "Jacquinot Bay",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "JAR",
    airportName: "Jahrom",
    cityCode: "JAR",
    cityName: "Jahrom",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JAS",
    airportName: "County",
    cityCode: "JAS",
    cityName: "Jasper",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JAT",
    airportName: "Jabot",
    cityCode: "JAT",
    cityName: "Jabot",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "JAU",
    airportName: "Jauja",
    cityCode: "JAU",
    cityName: "Jauja",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "JAV",
    airportName: "Ilulissat",
    cityCode: "JAV",
    cityName: "Ilulissat",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JAX",
    airportName: "Jacksonville,",
    cityCode: "JAX",
    cityName: "Jacksonville, FL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JBC",
    airportName: "Boston City Heliport",
    cityCode: "JBC",
    cityName: "Boston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JBK",
    airportName: "Berkeley",
    cityCode: "JBK",
    cityName: "Berkeley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JBP",
    airportName: "Commerce Bus. Plaza",
    cityCode: "JBP",
    cityName: "Los Angeles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JBR",
    airportName: "Jonesboro",
    cityCode: "JBR",
    cityName: "Jonesboro",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JBS",
    airportName: "Hacienda Bus.Park H/P",
    cityCode: "JBS",
    cityName: "Pleasanton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JBT",
    airportName: "City Landing",
    cityCode: "JBT",
    cityName: "Bethel",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JCA",
    airportName: "Croisette Heliport",
    cityCode: "JCA",
    cityName: "Cannes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "JCB",
    airportName: "Joacaba",
    cityCode: "JCB",
    cityName: "Joacaba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JCC",
    airportName: "China Basin Heliport",
    cityCode: "JCC",
    cityName: "San Francisco",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JCE",
    airportName: "Convention Center H/P",
    cityCode: "JCE",
    cityName: "Oakland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JCH",
    airportName: "Qasigiannguit",
    cityCode: "JCH",
    cityName: "Qasigiannguit",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JCI",
    airportName: "New Century Aircenter Arpt.",
    cityCode: "JCI",
    cityName: "Olathe, KS",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JCJ",
    airportName: "Chuja Heliport",
    cityCode: "JCJ",
    cityName: "Jeju",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "JCK",
    airportName: "Julia Creek",
    cityCode: "JCK",
    cityName: "Julia Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "JCM",
    airportName: "Jacobina",
    cityCode: "JCM",
    cityName: "Jacobina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JCN",
    airportName: "Heliport",
    cityCode: "JCN",
    cityName: "Incheon",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "JCO",
    airportName: "Heliport",
    cityCode: "JCO",
    cityName: "Comino",
    countryName: "Malta",
    countryCode: "MT",
  },
  {
    airportCode: "JCR",
    airportName: "Jacareacanga",
    cityCode: "JCR",
    cityName: "Jacareacanga",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JCT",
    airportName: "Kimble County",
    cityCode: "JCT",
    cityName: "Junction",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JCU",
    airportName: "Ceuta Heliport",
    cityCode: "JCU",
    cityName: "Ceuta",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "JCY",
    airportName: "Johnson",
    cityCode: "JCY",
    cityName: "Johnson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JDA",
    airportName: "John Day",
    cityCode: "JDA",
    cityName: "John Day",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JDB",
    airportName: "Downtown Heliport",
    cityCode: "JDB",
    cityName: "Dallas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JDF",
    airportName: "Francisco De Assis",
    cityCode: "JDF",
    cityName: "Juiz De Fora",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JDH",
    airportName: "Jodhpur",
    cityCode: "JDH",
    cityName: "Jodhpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "JDM",
    airportName: "Downtown Heliport",
    cityCode: "JDM",
    cityName: "Miami",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JDN",
    airportName: "Jordan",
    cityCode: "JDN",
    cityName: "Jordan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JDO",
    airportName: "Regional Do Cariri",
    cityCode: "JDO",
    cityName: "Juazeiro Do Norte",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JDP",
    airportName: "Heliport De Paris",
    cityCode: "JDP",
    cityName: "Paris",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "JDT",
    airportName: "Downtown Heliport",
    cityCode: "JDT",
    cityName: "Minneapolis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JDY",
    airportName: "Heliport",
    cityCode: "JDY",
    cityName: "Downey",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JDZ",
    airportName: "Jingdezhen",
    cityCode: "JDZ",
    cityName: "Jingdezhen",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JED",
    airportName: "King Abdulaziz Intl.",
    cityCode: "JED",
    cityName: "Jeddah",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "JEE",
    airportName: "Jeremie",
    cityCode: "JEE",
    cityName: "Jeremie",
    countryName: "Haiti",
    countryCode: "HT",
  },
  {
    airportCode: "JEF",
    airportName: "Memorial",
    cityCode: "JEF",
    cityName: "Jefferson City, MO",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JEG",
    airportName: "Aasiaat",
    cityCode: "JEG",
    cityName: "Aasiaat",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JEJ",
    airportName: "Jeh",
    cityCode: "JEJ",
    cityName: "Jeh",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "JEM",
    airportName: "Heliport",
    cityCode: "JEM",
    cityName: "Emeryville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JEQ",
    airportName: "Jequie",
    cityCode: "JEQ",
    cityName: "Jequie",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JER",
    airportName: "Jersey",
    cityCode: "JER",
    cityName: "Jersey",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "JEV",
    airportName: "Heliport",
    cityCode: "JEV",
    cityName: "Evry",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "JFK",
    airportName: "John F Kennedy Intl",
    cityCode: "NYC",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JFM",
    airportName: "Heliport",
    cityCode: "JFM",
    cityName: "Fremantle",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "JFN",
    airportName: "Ashtabula",
    cityCode: "JFN",
    cityName: "Jefferson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JFR",
    airportName: "Frederikshab",
    cityCode: "JFR",
    cityName: "Paamiut",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JGA",
    airportName: "Govardhanpur",
    cityCode: "JGA",
    cityName: "Jamnagar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "JGB",
    airportName: "Jagdalpur",
    cityCode: "JGB",
    cityName: "Jagdalpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "JGC",
    airportName: "Heliport",
    cityCode: "JGC",
    cityName: "Grand Canyon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JGE",
    airportName: "Heliport",
    cityCode: "JGE",
    cityName: "Geoje",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "JGL",
    airportName: "Galleria Heliport",
    cityCode: "JGL",
    cityName: "Atlanta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JGN",
    airportName: "Jiayuguan",
    cityCode: "JGN",
    cityName: "Jiayuguan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JGO",
    airportName: "Qeqertarsuaq",
    cityCode: "JGO",
    cityName: "Qeqertarsuaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JGR",
    airportName: "Heliport",
    cityCode: "JGR",
    cityName: "Groennedal",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JGX",
    airportName: "Heliport",
    cityCode: "JGX",
    cityName: "Glendale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JHB",
    airportName: "Sultan Ismail Intl",
    cityCode: "JHB",
    cityName: "Johor Bahru",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "JHC",
    airportName: "Island Heliport",
    cityCode: "JHC",
    cityName: "Garden City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JHE",
    airportName: "Heliport",
    cityCode: "JHE",
    cityName: "Angelholm/Helsingborg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "JHG",
    airportName: "Gasa",
    cityCode: "JHG",
    cityName: "Jinghong",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JHM",
    airportName: "Kapalua West Maui",
    cityCode: "JHM",
    cityName: "Lahaina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JHQ",
    airportName: "Shute Harbour H/P",
    cityCode: "JHQ",
    cityName: "Shute Harbour",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "JHS",
    airportName: "Sisimiut",
    cityCode: "JHS",
    cityName: "Sisimiut",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JHW",
    airportName: "Jamestown",
    cityCode: "JHW",
    cityName: "Jamestown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JHY",
    airportName: "Hyatt Regency H/P",
    cityCode: "JHY",
    cityName: "Cambridge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JIA",
    airportName: "Juina",
    cityCode: "JIA",
    cityName: "Juina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JIB",
    airportName: "Ambouli",
    cityCode: "JIB",
    cityName: "Djibouti",
    countryName: "Djibouti",
    countryCode: "DJ",
  },
  {
    airportCode: "JID",
    airportName: "City Of Industry H/P",
    cityCode: "JID",
    cityName: "Los Angeles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JIJ",
    airportName: "Jigiga",
    cityCode: "JIJ",
    cityName: "Jijiga",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "JIK",
    airportName: "Ikaria",
    cityCode: "JIK",
    cityName: "Ikaria Island",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JIL",
    airportName: "Jilin",
    cityCode: "JIL",
    cityName: "Jilin",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JIM",
    airportName: "Jimma",
    cityCode: "JIM",
    cityName: "Jimma",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "JIN",
    airportName: "Jinja",
    cityCode: "JIN",
    cityName: "Jinja",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "JIO",
    airportName: "Intl Heliport",
    cityCode: "JIO",
    cityName: "Ontario",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JIP",
    airportName: "Jipijapa",
    cityCode: "JIP",
    cityName: "Jipijapa",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "JIR",
    airportName: "Jiri",
    cityCode: "JIR",
    cityName: "Jiri",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "JIU",
    airportName: "Jiujiang",
    cityCode: "JIU",
    cityName: "Jiujiang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JIW",
    airportName: "Jiwani",
    cityCode: "JIW",
    cityName: "Jiwani",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "JJI",
    airportName: "Juanjui",
    cityCode: "JJI",
    cityName: "Juanjui",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "JJN",
    airportName: "Jinjiang",
    cityCode: "JJN",
    cityName: "Jinjiang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JJU",
    airportName: "Heliport",
    cityCode: "JJU",
    cityName: "Qaqortoq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JKG",
    airportName: "Axamo",
    cityCode: "JKG",
    cityName: "Jonkoping",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "JKH",
    airportName: "Chios",
    cityCode: "JKH",
    cityName: "Chios",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JKR",
    airportName: "Janakpur",
    cityCode: "JKR",
    cityName: "Janakpur",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "JKV",
    airportName: "Cherokee County",
    cityCode: "JKV",
    cityName: "Jacksonville, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JLA",
    airportName: "Quartz Creek",
    cityCode: "JLA",
    cityName: "Cooper Lodge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JLB",
    airportName: "Heliport",
    cityCode: "JLB",
    cityName: "Long Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JLD",
    airportName: "Heliport",
    cityCode: "JLD",
    cityName: "Landskrona",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "JLH",
    airportName: "US Army Heliport",
    cityCode: "JLH",
    cityName: "Arlington Heights",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JLN",
    airportName: "Joplin Regional",
    cityCode: "JLN",
    cityName: "Joplin, MO",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JLO",
    airportName: "Jesolo",
    cityCode: "JLO",
    cityName: "Jesolo",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "JLP",
    airportName: "Juan Les Pins",
    cityCode: "JLP",
    cityName: "Juan Les Pins",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "JLR",
    airportName: "Jabalpur",
    cityCode: "JLR",
    cityName: "Jabalpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "JMB",
    airportName: "Jamba",
    cityCode: "JMB",
    cityName: "Jamba",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "JMC",
    airportName: "Marin County",
    cityCode: "JMC",
    cityName: "Sausalito, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JMD",
    airportName: "Market Centre H/P",
    cityCode: "JMD",
    cityName: "Dallas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JMH",
    airportName: "Marriott Heliport",
    cityCode: "JMH",
    cityName: "Schaumburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JMK",
    airportName: "Mikonos",
    cityCode: "JMK",
    cityName: "Mikonos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JMM",
    airportName: "Malmo Harbour Heliport",
    cityCode: "JMM",
    cityName: "Malmo",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "JMN",
    airportName: "Municipal Heliport",
    cityCode: "JMN",
    cityName: "Mankato",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JMO",
    airportName: "Jomsom",
    cityCode: "JMO",
    cityName: "Jomsom",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "JMS",
    airportName: "Jamestown",
    cityCode: "JMS",
    cityName: "Jamestown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JMU",
    airportName: "Jiamusi",
    cityCode: "JMU",
    cityName: "Jiamusi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JMY",
    airportName: "Mammy Yoko Heliport",
    cityCode: "JMY",
    cityName: "Freetown",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "JNA",
    airportName: "Januaria",
    cityCode: "JNA",
    cityName: "Januaria",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JNB",
    airportName: "Oliver Reginald Tambo Intl.",
    cityCode: "JNB",
    cityName: "Johannesburg",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "JNG",
    airportName: "Jining",
    cityCode: "JNG",
    cityName: "Jining",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JNH",
    airportName: "North Park Inn H/P",
    cityCode: "JNH",
    cityName: "Dallas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JNI",
    airportName: "Junin",
    cityCode: "JNI",
    cityName: "Junin",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "JNN",
    airportName: "Nanortalik",
    cityCode: "JNN",
    cityName: "Nanortalik",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JNP",
    airportName: "Heliport",
    cityCode: "JNP",
    cityName: "Newport Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JNS",
    airportName: "Heliport",
    cityCode: "JNS",
    cityName: "Narsaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JNU",
    airportName: "Boundary Bay",
    cityCode: "JNU",
    cityName: "Juneau",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JNX",
    airportName: "Naxos Arpt.",
    cityCode: "JNX",
    cityName: "Naxos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JNZ",
    airportName: "Jinzhou",
    cityCode: "JNZ",
    cityName: "Jinzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "JOC",
    airportName: "Centerport Heliport",
    cityCode: "JOC",
    cityName: "Santa Ana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JOE",
    airportName: "Joensuu",
    cityCode: "JOE",
    cityName: "Joensuu",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "JOG",
    airportName: "Adisutjipto",
    cityCode: "JOG",
    cityName: "Yogyakarta",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "JOH",
    airportName: "Port Saint Johns",
    cityCode: "JOH",
    cityName: "Port Saint Johns",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "JOI",
    airportName: "Lauro Carneiro De Loyola",
    cityCode: "JOI",
    cityName: "Joinville",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JOK",
    airportName: "Joshkar-Ola",
    cityCode: "JOK",
    cityName: "Joshkar-Ola",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "JOL",
    airportName: "Jolo",
    cityCode: "JOL",
    cityName: "Jolo",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "JOM",
    airportName: "Njombe",
    cityCode: "JOM",
    cityName: "Njombe",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "JON",
    airportName: "Johnston Island",
    cityCode: "JON",
    cityName: "Johnston Island",
    countryName: "Us Minor Outlying Islands",
    countryCode: "UM",
  },
  {
    airportCode: "JOP",
    airportName: "Josephstaal",
    cityCode: "JOP",
    cityName: "Josephstaal",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "JOR",
    airportName: "The City Heliport",
    cityCode: "JOR",
    cityName: "Orange",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JOS",
    airportName: "Jos",
    cityCode: "JOS",
    cityName: "Jos",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "JOT",
    airportName: "Municipal",
    cityCode: "JOT",
    cityName: "Joliet",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JPA",
    airportName: "Castro Pinto",
    cityCode: "JPA",
    cityName: "Joao Pessoa",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JPD",
    airportName: "Heliport",
    cityCode: "JPD",
    cityName: "Pasadena",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JPN",
    airportName: "Pentagon Army",
    cityCode: "JPN",
    cityName: "Washington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JPR",
    airportName: "Ji-Parana",
    cityCode: "JPR",
    cityName: "Ji-Parana",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JPU",
    airportName: "La Defense Heliport",
    cityCode: "JPU",
    cityName: "Paris",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "JQE",
    airportName: "Jaque",
    cityCode: "JQE",
    cityName: "Jaque",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "JRA",
    airportName: "West 30th St Heliport",
    cityCode: "JRA",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JRB",
    airportName: "Downtown Manhattan H/p",
    cityCode: "JRB",
    cityName: "New York, NY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JRC",
    airportName: "Municipal Heliport",
    cityCode: "JRC",
    cityName: "Rochester",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JRE",
    airportName: "East 60th Street H/P",
    cityCode: "JRE",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JRH",
    airportName: "Rowriah",
    cityCode: "JRH",
    cityName: "Jorhat",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "JRK",
    airportName: "Arsuk",
    cityCode: "JRK",
    cityName: "Arsuk",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JRN",
    airportName: "Juruena",
    cityCode: "JRN",
    cityName: "Juruena",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JRO",
    airportName: "Kilimanjaro",
    cityCode: "JRO",
    cityName: "Kilimanjaro",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "JRS",
    airportName: "Jerusalem",
    cityCode: "JRS",
    cityName: "Jerusalem",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "JSA",
    airportName: "Jaisalmer",
    cityCode: "JSA",
    cityName: "Jaisalmer",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "JSD",
    airportName: "Sikorsky Heliport",
    cityCode: "JSD",
    cityName: "Stratford, CT",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JSG",
    airportName: "San Rafael H/P",
    cityCode: "JSG",
    cityName: "San Rafael",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JSH",
    airportName: "Sitia",
    cityCode: "JSH",
    cityName: "Sitia",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JSI",
    airportName: "Skiathos",
    cityCode: "JSI",
    cityName: "Skiathos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JSK",
    airportName: "Municipal Heliport",
    cityCode: "JSK",
    cityName: "Saint Cloud",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JSL",
    airportName: "Steel Pier Hlpt",
    cityCode: "JSL",
    cityName: "Atlantic City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JSM",
    airportName: "Jose De San Martin",
    cityCode: "JSM",
    cityName: "Jose De San Martin",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "JSO",
    airportName: "Sodertalje Heliport",
    cityCode: "JSO",
    cityName: "Sodertalje",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "JSP",
    airportName: "Seogwipo Heliport",
    cityCode: "JSP",
    cityName: "Jeju",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "JSR",
    airportName: "Jessore",
    cityCode: "JSR",
    cityName: "Jessore",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "JSS",
    airportName: "Spetsai Island",
    cityCode: "JSS",
    cityName: "Spetsai Island",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JST",
    airportName: "Cambria County",
    cityCode: "JST",
    cityName: "Johnstown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JSU",
    airportName: "Heliport",
    cityCode: "JSU",
    cityName: "Maniitsoq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JSY",
    airportName: "Syros Island",
    cityCode: "JSY",
    cityName: "Syros Island",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JTI",
    airportName: "Jatai",
    cityCode: "JTI",
    cityName: "Jatai",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "JTR",
    airportName: "Santorini",
    cityCode: "JTR",
    cityName: "Santorini",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JTY",
    airportName: "Astypalaia",
    cityCode: "JTY",
    cityName: "Astypalaia Island",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "JUB",
    airportName: "Juba",
    cityCode: "JUB",
    cityName: "Juba",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "JUI",
    airportName: "Juist",
    cityCode: "JUI",
    cityName: "Juist",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "JUJ",
    airportName: "El Cadillal",
    cityCode: "JUJ",
    cityName: "Jujuy",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "JUL",
    airportName: "Juliaca",
    cityCode: "JUL",
    cityName: "Juliaca",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "JUM",
    airportName: "Jumla",
    cityCode: "JUM",
    cityName: "Jumla",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "JUN",
    airportName: "Jundah",
    cityCode: "JUN",
    cityName: "Jundah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "JUO",
    airportName: "Jurado",
    cityCode: "JUO",
    cityName: "Jurado",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "JUP",
    airportName: "Cable Heliport",
    cityCode: "JUP",
    cityName: "Upland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JUR",
    airportName: "Jurien Bay",
    cityCode: "JUR",
    cityName: "Jurien Bay",
    countryName: "Australia",
    countryCode: "AUS",
  },
  {
    airportCode: "JUT",
    airportName: "Juticalpa",
    cityCode: "JUT",
    cityName: "Juticalpa",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "JUV",
    airportName: "Heliport",
    cityCode: "JUV",
    cityName: "Upernavik",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "JVA",
    airportName: "Ankavandra",
    cityCode: "JVA",
    cityName: "Ankavandra",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "JVI",
    airportName: "Central Jersey Regional Arpt.",
    cityCode: "JVI",
    cityName: "Manville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JVL",
    airportName: "Rock County",
    cityCode: "JVL",
    cityName: "Janesville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JWA",
    airportName: "Jwaneng",
    cityCode: "JWA",
    cityName: "Jwaneng",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "JWN",
    airportName: "Zanjan",
    cityCode: "JWN",
    cityName: "Zanjan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "JXN",
    airportName: "Reynolds Municipal",
    cityCode: "JXN",
    cityName: "Jackson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "JYR",
    airportName: "Jiroft",
    cityCode: "JYR",
    cityName: "Jiroft",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "JYV",
    airportName: "Jyvaskyla",
    cityCode: "JYV",
    cityName: "Jyvaskyla",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "JZH",
    airportName: "Jiu Zhai Huang Long",
    cityCode: "JZH",
    cityName: "Song Pan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KAA",
    airportName: "Kasama",
    cityCode: "KAA",
    cityName: "Kasama",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "KAB",
    airportName: "Kariba",
    cityCode: "KAB",
    cityName: "Kariba",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "KAC",
    airportName: "Kameshli",
    cityCode: "KAC",
    cityName: "Kameshli",
    countryName: "Syria",
    countryCode: "SY",
  },
  {
    airportCode: "KAD",
    airportName: "Kaduna",
    cityCode: "KAD",
    cityName: "Kaduna",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "KAE",
    airportName: "SPB",
    cityCode: "KAE",
    cityName: "Kake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KAF",
    airportName: "Karato",
    cityCode: "KAF",
    cityName: "Karato",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KAG",
    airportName: "Gangneung",
    cityCode: "KAG",
    cityName: "Gangneung",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "KAH",
    airportName: "City Heliport",
    cityCode: "KAH",
    cityName: "Melbourne",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KAI",
    airportName: "Kaieteur",
    cityCode: "KAI",
    cityName: "Kaieteur",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "KAJ",
    airportName: "Kajaani",
    cityCode: "KAJ",
    cityName: "Kajaani",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KAK",
    airportName: "Kar",
    cityCode: "KAK",
    cityName: "Kar",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KAL",
    airportName: "Kaltag",
    cityCode: "KAL",
    cityName: "Kaltag",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KAM",
    airportName: "Kamaran Is",
    cityCode: "KAM",
    cityName: "Kamaran Is",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "KAN",
    airportName: "Aminu Kano Intl Apt",
    cityCode: "KAN",
    cityName: "Kano",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "KAO",
    airportName: "Kuusamo",
    cityCode: "KAO",
    cityName: "Kuusamo",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KAP",
    airportName: "Kapanga",
    cityCode: "KAP",
    cityName: "Kapanga",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KAQ",
    airportName: "Kamulai",
    cityCode: "KAQ",
    cityName: "Kamulai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KAR",
    airportName: "Kamarang",
    cityCode: "KAR",
    cityName: "Kamarang",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "KAS",
    airportName: "Karasburg",
    cityCode: "KAS",
    cityName: "Karasburg",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "KAT",
    airportName: "Kaitaia",
    cityCode: "KAT",
    cityName: "Kaitaia",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "KAU",
    airportName: "Kauhava",
    cityCode: "KAU",
    cityName: "Kauhava",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KAV",
    airportName: "Kavanayen",
    cityCode: "KAV",
    cityName: "Kavanayen",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "KAW",
    airportName: "Kawthaung",
    cityCode: "KAW",
    cityName: "Kawthaung",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "KAX",
    airportName: "Kalbarri",
    cityCode: "KAX",
    cityName: "Kalbarri",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KAY",
    airportName: "Wakaya Island",
    cityCode: "KAY",
    cityName: "Wakaya Island",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "KAZ",
    airportName: "Kau",
    cityCode: "KAZ",
    cityName: "Kau",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KBA",
    airportName: "Kabala",
    cityCode: "KBA",
    cityName: "Kabala",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "KBB",
    airportName: "Kirkimbie",
    cityCode: "KBB",
    cityName: "Kirkimbie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KBC",
    airportName: "Birch Creek",
    cityCode: "KBC",
    cityName: "Birch Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KBD",
    airportName: "Kimberley Downs",
    cityCode: "KBD",
    cityName: "Kimberley Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KBE",
    airportName: "Hot Springs SPB",
    cityCode: "KBE",
    cityName: "Bell Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KBF",
    airportName: "Karubaga",
    cityCode: "KBF",
    cityName: "Karubaga",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KBG",
    airportName: "Kabalega Falls",
    cityCode: "KBG",
    cityName: "Kabalega Falls",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "KBH",
    airportName: "Kalat",
    cityCode: "KBH",
    cityName: "Kalat",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "KBI",
    airportName: "Kribi",
    cityCode: "KBI",
    cityName: "Kribi",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "KBJ",
    airportName: "Kings Canyon",
    cityCode: "KBJ",
    cityName: "Kings Canyon",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KBK",
    airportName: "Klag Bay",
    cityCode: "KBK",
    cityName: "Klag Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KBL",
    airportName: "Khwaja Rawash",
    cityCode: "KBL",
    cityName: "Kabul",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "KBM",
    airportName: "Kabwum",
    cityCode: "KBM",
    cityName: "Kabwum",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KBN",
    airportName: "Kabinda",
    cityCode: "KBN",
    cityName: "Kabinda",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KBO",
    airportName: "Kabalo",
    cityCode: "KBO",
    cityName: "Kabalo",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KBP",
    airportName: "Borispol",
    cityCode: "IEV",
    cityName: "Kiev",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "KBR",
    airportName: "Sultan Ismail Petra",
    cityCode: "KBR",
    cityName: "Kota Bharu",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "KBS",
    airportName: "Bo",
    cityCode: "KBS",
    cityName: "Bo",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "KBT",
    airportName: "Kaben",
    cityCode: "KBT",
    cityName: "Kaben",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "KBU",
    airportName: "Kotabaru",
    cityCode: "KBU",
    cityName: "Kotabaru",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KBV",
    airportName: "Krabi",
    cityCode: "KBV",
    cityName: "Krabi",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "KBW",
    airportName: "Chignik Bay",
    cityCode: "KBW",
    cityName: "Chignik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KBX",
    airportName: "Kambuaya",
    cityCode: "KBX",
    cityName: "Kambuaya",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KBY",
    airportName: "Streaky Bay",
    cityCode: "KBY",
    cityName: "Streaky Bay",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KBZ",
    airportName: "Kaikoura",
    cityCode: "KBZ",
    cityName: "Kaikoura",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "KCA",
    airportName: "Kuqa",
    cityCode: "KCA",
    cityName: "Kuqa",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KCC",
    airportName: "Coffman Cove SPB",
    cityCode: "KCC",
    cityName: "Coffman Cove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KCD",
    airportName: "Kamur",
    cityCode: "KCD",
    cityName: "Kamur",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KCE",
    airportName: "Collinsville",
    cityCode: "KCE",
    cityName: "Collinsville",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KCF",
    airportName: "Kadanwari",
    cityCode: "KCF",
    cityName: "Kadanwari",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "KCG",
    airportName: "Fisheries",
    cityCode: "KCG",
    cityName: "Chignik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KCH",
    airportName: "Kuching",
    cityCode: "KCH",
    cityName: "Kuching",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "KCI",
    airportName: "Kon",
    cityCode: "KCI",
    cityName: "Kon",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KCJ",
    airportName: "Komaio",
    cityCode: "KCJ",
    cityName: "Komaio",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KCK",
    airportName: "Fairfax Municipal",
    cityCode: "KCK",
    cityName: "Kansas City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KCL",
    airportName: "Lagoon",
    cityCode: "KCL",
    cityName: "Chignik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KCM",
    airportName: "Kahramanmaras",
    cityCode: "KCM",
    cityName: "Kahramanmaras",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "KCN",
    airportName: "SPB",
    cityCode: "KCN",
    cityName: "Chernofski",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KCO",
    airportName: "Cengiz Topel",
    cityCode: "KCO",
    cityName: "Kocaeli",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "KCP",
    airportName: "Kamenets-podolskiy",
    cityCode: "KCP",
    cityName: "Kamenets-podolskiy",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "KCQ",
    airportName: "Chignik",
    cityCode: "KCQ",
    cityName: "Chignik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KCR",
    airportName: "Colorado Creek",
    cityCode: "KCR",
    cityName: "Colorado Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KCS",
    airportName: "Kings Creek Station",
    cityCode: "KCS",
    cityName: "Kings Creek Station",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KCT",
    airportName: "Koggala",
    cityCode: "KCT",
    cityName: "Koggala",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "KCU",
    airportName: "Masindi",
    cityCode: "KCU",
    cityName: "Masindi",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "KCZ",
    airportName: "Kochi",
    cityCode: "KCZ",
    cityName: "Kochi",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KDA",
    airportName: "Kolda",
    cityCode: "KDA",
    cityName: "Kolda",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "KDB",
    airportName: "Kambalda",
    cityCode: "KDB",
    cityName: "Kambalda",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KDC",
    airportName: "Kandi",
    cityCode: "KDC",
    cityName: "Kandi",
    countryName: "Benin",
    countryCode: "BJ",
  },
  {
    airportCode: "KDD",
    airportName: "Khuzdar",
    cityCode: "KDD",
    cityName: "Khuzdar",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "KDE",
    airportName: "Koroba",
    cityCode: "KDE",
    cityName: "Koroba",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KDF",
    airportName: "Kouba",
    cityCode: "KDF",
    cityName: "Kouba",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "KDG",
    airportName: "Kardjali",
    cityCode: "KDG",
    cityName: "Kardjali",
    countryName: "Bulgaria",
    countryCode: "BG",
  },
  {
    airportCode: "KDH",
    airportName: "Kandahar",
    cityCode: "KDH",
    cityName: "Kandahar",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "KDI",
    airportName: "Wolter Monginsidi",
    cityCode: "KDI",
    cityName: "Kendari",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KDK",
    airportName: "Municipal",
    cityCode: "KDK",
    cityName: "Kodiak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KDM",
    airportName: "Kaadedhdhoo",
    cityCode: "KDM",
    cityName: "Kaadedhdhoo",
    countryName: "Maldives",
    countryCode: "MV",
  },
  {
    airportCode: "KDN",
    airportName: "Ndende",
    cityCode: "KDN",
    cityName: "Ndende",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "KDO",
    airportName: "Kadhdhoo",
    cityCode: "KDO",
    cityName: "Kadhdhoo",
    countryName: "Maldives",
    countryCode: "MV",
  },
  {
    airportCode: "KDP",
    airportName: "Kandep",
    cityCode: "KDP",
    cityName: "Kandep",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KDQ",
    airportName: "Kamberatoro",
    cityCode: "KDQ",
    cityName: "Kamberatoro",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KDR",
    airportName: "Kandrian",
    cityCode: "KDR",
    cityName: "Kandrian",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KDS",
    airportName: "Kamaran Downs",
    cityCode: "KDS",
    cityName: "Kamaran Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KDT",
    airportName: "Kamphangsaen",
    cityCode: "KDT",
    cityName: "Kamphangsaen",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "KDU",
    airportName: "Skardu",
    cityCode: "KDU",
    cityName: "Skardu",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "KDV",
    airportName: "Kandavu",
    cityCode: "KDV",
    cityName: "Kandavu",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "KDW",
    airportName: "Vic. Resevour Kandy",
    cityCode: "KDW",
    cityName: "Kandy",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "KDY",
    airportName: "Mahaweli",
    cityCode: "KDY",
    cityName: "Mahaweli",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "KDZ",
    airportName: "Polgolla Reservoir",
    cityCode: "KDZ",
    cityName: "Katugastota",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "KEA",
    airportName: "Keisah",
    cityCode: "KEA",
    cityName: "Keisah",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KEB",
    airportName: "Nanwalek",
    cityCode: "KEB",
    cityName: "Nanwalek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KEC",
    airportName: "Kasenga",
    cityCode: "KEC",
    cityName: "Kasenga",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KED",
    airportName: "Kaedi",
    cityCode: "KED",
    cityName: "Kaedi",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "KEE",
    airportName: "Kelle",
    cityCode: "KEE",
    cityName: "Kelle",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "KEF",
    airportName: "Keflavik Intl.",
    cityCode: "REK",
    cityName: "Reykjavik",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "KEG",
    airportName: "Keglsugl",
    cityCode: "KEG",
    cityName: "Keglsugl",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KEH",
    airportName: "Kenmore Air Harbor",
    cityCode: "KEH",
    cityName: "Kenmore Air Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KEI",
    airportName: "Kepi",
    cityCode: "KEI",
    cityName: "Kepi",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KEJ",
    airportName: "Kemerovo",
    cityCode: "KEJ",
    cityName: "Kemerovo",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KEK",
    airportName: "Ekwok",
    cityCode: "KEK",
    cityName: "Ekwok",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KEL",
    airportName: "Kiel-Holtenau",
    cityCode: "KEL",
    cityName: "Kiel",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "KEM",
    airportName: "Kemi/Tornio",
    cityCode: "KEM",
    cityName: "Kemi/Tornio",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KEN",
    airportName: "Kenema",
    cityCode: "KEN",
    cityName: "Kenema",
    countryName: "Sierra Leone",
    countryCode: "SL",
  },
  {
    airportCode: "KEO",
    airportName: "Odienne",
    cityCode: "KEO",
    cityName: "Odienne",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "KEP",
    airportName: "Nepalganj",
    cityCode: "KEP",
    cityName: "Nepalganj",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "KEQ",
    airportName: "Kebar",
    cityCode: "KEQ",
    cityName: "Kebar",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KER",
    airportName: "Kerman",
    cityCode: "KER",
    cityName: "Kerman",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KES",
    airportName: "Kelsey",
    cityCode: "KES",
    cityName: "Kelsey",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "KET",
    airportName: "Keng Tung",
    cityCode: "KET",
    cityName: "Keng Tung",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "KEU",
    airportName: "Kelly Bar",
    cityCode: "KEU",
    cityName: "Kelly Bar",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KEV",
    airportName: "Halli",
    cityCode: "KEV",
    cityName: "Kuorevesi",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KEW",
    airportName: "Keewaywin",
    cityCode: "KEW",
    cityName: "Keewaywin",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "KEX",
    airportName: "Kanabea",
    cityCode: "KEX",
    cityName: "Kanabea",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KEY",
    airportName: "Kericho",
    cityCode: "KEY",
    cityName: "Kericho",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "KEZ",
    airportName: "Kelaniya River",
    cityCode: "KEZ",
    cityName: "Kelaniya",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "KFA",
    airportName: "Kiffa",
    cityCode: "KFA",
    cityName: "Kiffa",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "KFG",
    airportName: "Kalkurung",
    cityCode: "KFG",
    cityName: "Kalkurung",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KFP",
    airportName: "False Pass",
    cityCode: "KFP",
    cityName: "False Pass",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KFS",
    airportName: "Kastamonu",
    cityCode: "KFS",
    cityName: "Kastamonu",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "KGA",
    airportName: "Kananga",
    cityCode: "KGA",
    cityName: "Kananga",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KGB",
    airportName: "Konge",
    cityCode: "KGB",
    cityName: "Konge",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KGC",
    airportName: "Kingscote",
    cityCode: "KGC",
    cityName: "Kingscote",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KGD",
    airportName: "Kaliningrad Devau",
    cityCode: "KGD",
    cityName: "Kaliningrad",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KGE",
    airportName: "Kagau",
    cityCode: "KGE",
    cityName: "Kagau",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "KGF",
    airportName: "Karaganda",
    cityCode: "KGF",
    cityName: "Karaganda",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "KGG",
    airportName: "Kedougou",
    cityCode: "KGG",
    cityName: "Kedougou",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "KGH",
    airportName: "Yongai",
    cityCode: "KGH",
    cityName: "Yongai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KGI",
    airportName: "Kalgoorlie",
    cityCode: "KGI",
    cityName: "Kalgoorlie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KGJ",
    airportName: "Karonga",
    cityCode: "KGJ",
    cityName: "Karonga",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "KGK",
    airportName: "New Koliganek",
    cityCode: "KGK",
    cityName: "New Koliganek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KGL",
    airportName: "Gregoire Kayibanda",
    cityCode: "KGL",
    cityName: "Kigali",
    countryName: "Rwanda",
    countryCode: "RW",
  },
  {
    airportCode: "KGM",
    airportName: "Kungum",
    cityCode: "KGM",
    cityName: "Kungum",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KGN",
    airportName: "Kasongo Lunda",
    cityCode: "KGN",
    cityName: "Kasongo Lunda",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KGO",
    airportName: "Kirovograd",
    cityCode: "KGO",
    cityName: "Kirovograd",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "KGP",
    airportName: "Kogalym Intl.",
    cityCode: "KGP",
    cityName: "Kogalym",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KGR",
    airportName: "Kulgera",
    cityCode: "KGR",
    cityName: "Kulgera",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KGS",
    airportName: "Kos Island Intl. Arpt.",
    cityCode: "KGS",
    cityName: "Kos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "KGU",
    airportName: "Keningau",
    cityCode: "KGU",
    cityName: "Keningau",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "KGW",
    airportName: "Kagi",
    cityCode: "KGW",
    cityName: "Kagi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KGX",
    airportName: "Grayling",
    cityCode: "KGX",
    cityName: "Grayling",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KGY",
    airportName: "Kingaroy",
    cityCode: "KGY",
    cityName: "Kingaroy, Queensland",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KGZ",
    airportName: "Glacier Creek",
    cityCode: "KGZ",
    cityName: "Glacier Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KHA",
    airportName: "Khaneh",
    cityCode: "KHA",
    cityName: "Khaneh",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KHC",
    airportName: "Kerch",
    cityCode: "KHC",
    cityName: "Kerch",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "KHD",
    airportName: "Khorramabad",
    cityCode: "KHD",
    cityName: "Khorramabad",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KHE",
    airportName: "Kherson",
    cityCode: "KHE",
    cityName: "Kherson",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "KHG",
    airportName: "Kashi",
    cityCode: "KHG",
    cityName: "Kashi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KHH",
    airportName: "Kaoshiung Intl. Arpt.",
    cityCode: "KHH",
    cityName: "Kaohsiung",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "KHI",
    airportName: "Quaid-e-azam Intl",
    cityCode: "KHI",
    cityName: "Karachi",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "KHJ",
    airportName: "Kauhajoki",
    cityCode: "KHJ",
    cityName: "Kauhajoki",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KHK",
    airportName: "Khark Island",
    cityCode: "KHK",
    cityName: "Khark Island",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KHL",
    airportName: "Khulna",
    cityCode: "KHL",
    cityName: "Khulna",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "KHM",
    airportName: "Khamti",
    cityCode: "KHM",
    cityName: "Khamti",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "KHN",
    airportName: "Nanchang",
    cityCode: "KHN",
    cityName: "Nanchang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KHO",
    airportName: "Khoka Moya",
    cityCode: "KHO",
    cityName: "Khoka Moya",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "KHR",
    airportName: "Kharkhorin",
    cityCode: "KHR",
    cityName: "Kharkhorin",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "KHS",
    airportName: "Khasab",
    cityCode: "KHS",
    cityName: "Khasab",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "KHT",
    airportName: "Khost",
    cityCode: "KHT",
    cityName: "Khost",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "KHU",
    airportName: "Kremenchug",
    cityCode: "KHU",
    cityName: "Kremenchug",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "KHV",
    airportName: "Novyy",
    cityCode: "KHV",
    cityName: "Khabarovsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KHW",
    airportName: "Khwai River Lodge",
    cityCode: "KHW",
    cityName: "Khwai River Lodge",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "KHY",
    airportName: "Khoy",
    cityCode: "KHY",
    cityName: "Khoy",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KHZ",
    airportName: "Kauehi",
    cityCode: "KHZ",
    cityName: "Kauehi",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "KIA",
    airportName: "Kaiapit",
    cityCode: "KIA",
    cityName: "Kaiapit",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KIB",
    airportName: "SPB",
    cityCode: "KIB",
    cityName: "Ivanof Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KIC",
    airportName: "Mesa Del Rey",
    cityCode: "KIC",
    cityName: "King City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KID",
    airportName: "Kristianstad",
    cityCode: "KID",
    cityName: "Kristianstad",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "KIE",
    airportName: "Aropa",
    cityCode: "KIE",
    cityName: "Kieta",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KIF",
    airportName: "Kingfisher Lake",
    cityCode: "KIF",
    cityName: "Kingfisher Lake",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "KIG",
    airportName: "Koinghaas",
    cityCode: "KIG",
    cityName: "Koinghaas",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "KIH",
    airportName: "Kish Island",
    cityCode: "KIH",
    cityName: "Kish Island",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KII",
    airportName: "Kibuli",
    cityCode: "KII",
    cityName: "Kibuli",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KIJ",
    airportName: "Niigata",
    cityCode: "KIJ",
    cityName: "Niigata",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KIK",
    airportName: "Kirkuk",
    cityCode: "KIK",
    cityName: "Kirkuk",
    countryName: "Iraq",
    countryCode: "IQ",
  },
  {
    airportCode: "KIL",
    airportName: "Kilwa",
    cityCode: "KIL",
    cityName: "Kilwa",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KIM",
    airportName: "Kimberley",
    cityCode: "KIM",
    cityName: "Kimberley",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "KIN",
    airportName: "Norman Manley",
    cityCode: "KIN",
    cityName: "Kingston",
    countryName: "Jamaica",
    countryCode: "JM",
  },
  {
    airportCode: "KIO",
    airportName: "Kili Island",
    cityCode: "KIO",
    cityName: "Kili Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "KIP",
    airportName: "Kickapoo",
    cityCode: "KIP",
    cityName: "Wichita Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KIQ",
    airportName: "Kira",
    cityCode: "KIQ",
    cityName: "Kira",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KIR",
    airportName: "Kerry",
    cityCode: "KIR",
    cityName: "Farranfore",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "KIS",
    airportName: "Kisumu",
    cityCode: "KIS",
    cityName: "Kisumu",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "KIT",
    airportName: "Kithira",
    cityCode: "KIT",
    cityName: "Kithira",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "KIU",
    airportName: "Kiunga",
    cityCode: "KIU",
    cityName: "Kiunga",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "KIV",
    airportName: "Intl. Arpt. Chisinau",
    cityCode: "KIV",
    cityName: "Chisinau",
    countryName: "Moldova",
    countryCode: "MD",
  },
  {
    airportCode: "KIW",
    airportName: "Southdowns",
    cityCode: "KIW",
    cityName: "Kitwe",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "KIX",
    airportName: "Kansai Intl.",
    cityCode: "OSA",
    cityName: "Osaka",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KIY",
    airportName: "Kilwa",
    cityCode: "KIY",
    cityName: "Kilwa",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "KIZ",
    airportName: "Kikinonda",
    cityCode: "KIZ",
    cityName: "Kikinonda",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KJA",
    airportName: "Krasnojarsk",
    cityCode: "KJA",
    cityName: "Krasnojarsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KJK",
    airportName: "Kortrijk",
    cityCode: "KJK",
    cityName: "Kortrijk",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "KJU",
    airportName: "Kamiraba",
    cityCode: "KJU",
    cityName: "Kamiraba",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KKA",
    airportName: "Koyuk",
    cityCode: "KKA",
    cityName: "Koyuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KKB",
    airportName: "SPB",
    cityCode: "KKB",
    cityName: "Kitoi Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KKC",
    airportName: "Khon Kaen",
    cityCode: "KKC",
    cityName: "Khon Kaen",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "KKD",
    airportName: "Kokoda",
    cityCode: "KKD",
    cityName: "Kokoda",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KKE",
    airportName: "Kerikeri/Bay of Islands",
    cityCode: "KKE",
    cityName: "Kerikeri",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "KKG",
    airportName: "Konawaruk",
    cityCode: "KKG",
    cityName: "Konawaruk",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "KKH",
    airportName: "Kongiganak",
    cityCode: "KKH",
    cityName: "Kongiganak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KKI",
    airportName: "Spb",
    cityCode: "KKI",
    cityName: "Akiachak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KKJ",
    airportName: "Kita Kyushu",
    cityCode: "KKJ",
    cityName: "Kita Kyushu",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KKK",
    airportName: "Kalakaket AFS",
    cityCode: "KKK",
    cityName: "Kalakaket",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KKL",
    airportName: "Karluk Lake SPB",
    cityCode: "KKL",
    cityName: "Karluk Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KKM",
    airportName: "Lop Buri",
    cityCode: "KKM",
    cityName: "Lop Buri",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "KKN",
    airportName: "Hoeybuktmoen",
    cityCode: "KKN",
    cityName: "Kirkenes",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "KKO",
    airportName: "Kaikohe",
    cityCode: "KKO",
    cityName: "Kaikohe",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "KKP",
    airportName: "Koolburra",
    cityCode: "KKP",
    cityName: "Koolburra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KKR",
    airportName: "Kaukura Atoll",
    cityCode: "KKR",
    cityName: "Kaukura Atoll",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "KKT",
    airportName: "Kentland",
    cityCode: "KKT",
    cityName: "Kentland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KKU",
    airportName: "Ekuk",
    cityCode: "KKU",
    cityName: "Ekuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KKW",
    airportName: "Kikwit",
    cityCode: "KKW",
    cityName: "Kikwit",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KKX",
    airportName: "Kikaiga Shima",
    cityCode: "KKX",
    cityName: "Kikaiga Shima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KKY",
    airportName: "Kilkenny",
    cityCode: "KKY",
    cityName: "Kilkenny",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "KKZ",
    airportName: "Koh Kong",
    cityCode: "KKZ",
    cityName: "Koh Kong",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "KLB",
    airportName: "Kalabo",
    cityCode: "KLB",
    cityName: "Kalabo",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "KLC",
    airportName: "Kaolack",
    cityCode: "KLC",
    cityName: "Kaolack",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "KLD",
    airportName: "Migalovo",
    cityCode: "KLD",
    cityName: "Kalinin",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KLE",
    airportName: "Kaele",
    cityCode: "KLE",
    cityName: "Kaele",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "KLF",
    airportName: "Kaluga",
    cityCode: "KLF",
    cityName: "Kaluga",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KLG",
    airportName: "Municipal",
    cityCode: "KLG",
    cityName: "Kalskag",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KLH",
    airportName: "Kolhapur",
    cityCode: "KLH",
    cityName: "Kolhapur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "KLI",
    airportName: "Kota Koli",
    cityCode: "KLI",
    cityName: "Kota Koli",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KLK",
    airportName: "Kalokol",
    cityCode: "KLK",
    cityName: "Kalokol",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "KLL",
    airportName: "Levelock",
    cityCode: "KLL",
    cityName: "Levelock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KLM",
    airportName: "Kalaleh",
    cityCode: "KLM",
    cityName: "Kalaleh",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KLN",
    airportName: "Larsen SPB",
    cityCode: "KLN",
    cityName: "Larsen Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KLO",
    airportName: "Kalibo",
    cityCode: "KLO",
    cityName: "Kalibo",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "KLP",
    airportName: "Kelp Bay",
    cityCode: "KLP",
    cityName: "Kelp Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KLQ",
    airportName: "Keluang",
    cityCode: "KLQ",
    cityName: "Keluang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KLR",
    airportName: "Kalmar",
    cityCode: "KLR",
    cityName: "Kalmar",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "KLS",
    airportName: "Longview",
    cityCode: "KLS",
    cityName: "Kelso",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KLT",
    airportName: "Kaiserslautern",
    cityCode: "KLT",
    cityName: "Kaiserslautern",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "KLU",
    airportName: "Alpe Adria",
    cityCode: "KLU",
    cityName: "Klagenfurt",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "KLV",
    airportName: "Karlovy Vary",
    cityCode: "KLV",
    cityName: "Karlovy Vary",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "KLW",
    airportName: "Klawock",
    cityCode: "KLW",
    cityName: "Klawock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KLX",
    airportName: "Kalamata",
    cityCode: "KLX",
    cityName: "Kalamata",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "KLY",
    airportName: "Kalima",
    cityCode: "KLY",
    cityName: "Kalima",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KLZ",
    airportName: "Kleinzee",
    cityCode: "KLZ",
    cityName: "Kleinzee",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "KMA",
    airportName: "Kerema",
    cityCode: "KMA",
    cityName: "Kerema",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KMB",
    airportName: "Koinambe",
    cityCode: "KMB",
    cityName: "Koinambe",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KMC",
    airportName: "King Khalid Military",
    cityCode: "KMC",
    cityName: "King Khalid Mil. City",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "KMD",
    airportName: "Mandji",
    cityCode: "KMD",
    cityName: "Mandji",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "KME",
    airportName: "Kamembe",
    cityCode: "KME",
    cityName: "Kamembe",
    countryName: "Rwanda",
    countryCode: "RW",
  },
  {
    airportCode: "KMF",
    airportName: "Kamina",
    cityCode: "KMF",
    cityName: "Kamina",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KMG",
    airportName: "Kunming",
    cityCode: "KMG",
    cityName: "Kunming",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KMH",
    airportName: "Kuruman",
    cityCode: "KMH",
    cityName: "Kuruman",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "KMI",
    airportName: "Miyazaki",
    cityCode: "KMI",
    cityName: "Miyazaki",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KMJ",
    airportName: "Kumamoto",
    cityCode: "KMJ",
    cityName: "Kumamoto",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KMK",
    airportName: "Makabana",
    cityCode: "KMK",
    cityName: "Makabana",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "KML",
    airportName: "Kamileroi",
    cityCode: "KML",
    cityName: "Kamileroi",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KMM",
    airportName: "Kimam",
    cityCode: "KMM",
    cityName: "Kimam",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KMN",
    airportName: "Kamina",
    cityCode: "KMN",
    cityName: "Kamina",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KMO",
    airportName: "Manokotak SPB",
    cityCode: "KMO",
    cityName: "Manokotak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KMP",
    airportName: "J.G.H. Van Der Wath",
    cityCode: "KMP",
    cityName: "Keetmanshoop",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "KMQ",
    airportName: "Komatsu",
    cityCode: "KMQ",
    cityName: "Komatsu",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KMR",
    airportName: "Karimui",
    cityCode: "KMR",
    cityName: "Karimui",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KMS",
    airportName: "Kumasi",
    cityCode: "KMS",
    cityName: "Kumasi",
    countryName: "Ghana",
    countryCode: "GH",
  },
  {
    airportCode: "KMT",
    airportName: "Kampot",
    cityCode: "KMT",
    cityName: "Kampot",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "KMU",
    airportName: "Kismayu",
    cityCode: "KMU",
    cityName: "Kismayu",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "KMV",
    airportName: "Kalemyo",
    cityCode: "KMV",
    cityName: "Kalemyo",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "KMW",
    airportName: "Kostroma",
    cityCode: "KMW",
    cityName: "Kostroma",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KMX",
    airportName: "Khamis Mushait",
    cityCode: "KMX",
    cityName: "Khamis Mushait",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "KMY",
    airportName: "Moser Bay",
    cityCode: "KMY",
    cityName: "Moser Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KMZ",
    airportName: "Kaoma",
    cityCode: "KMZ",
    cityName: "Kaoma",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "KNA",
    airportName: "Vina del Mar",
    cityCode: "KNA",
    cityName: "Vina del Mar",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "KNB",
    airportName: "Kanab",
    cityCode: "KNB",
    cityName: "Kanab",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KND",
    airportName: "Kindu",
    cityCode: "KND",
    cityName: "Kindu",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KNE",
    airportName: "Kanainj",
    cityCode: "KNE",
    cityName: "Kanainj",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KNF",
    airportName: "Marham RAF",
    cityCode: "KNF",
    cityName: "Kings Lynn",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "KNG",
    airportName: "Kaimana",
    cityCode: "KNG",
    cityName: "Kaimana",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KNH",
    airportName: "Shang-Yi",
    cityCode: "KNH",
    cityName: "Kinmen",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "KNI",
    airportName: "Katanning",
    cityCode: "KNI",
    cityName: "Katanning",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KNJ",
    airportName: "Kindamba",
    cityCode: "KNJ",
    cityName: "Kindamba",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "KNK",
    airportName: "Kakhonak",
    cityCode: "KNK",
    cityName: "Kakhonak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KNL",
    airportName: "Kelanoa",
    cityCode: "KNL",
    cityName: "Kelanoa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KNM",
    airportName: "Kaniama",
    cityCode: "KNM",
    cityName: "Kaniama",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KNN",
    airportName: "Kankan",
    cityCode: "KNN",
    cityName: "Kankan",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "KNO",
    airportName: "Kuala Namu International Airport",
    cityCode: "KNO",
    cityName: "Kuala Namu",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KNP",
    airportName: "Capanda",
    cityCode: "KNP",
    cityName: "Capanda",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "KNQ",
    airportName: "Kone",
    cityCode: "KNQ",
    cityName: "Kone",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "KNR",
    airportName: "Jam",
    cityCode: "KNR",
    cityName: "Kangan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KNS",
    airportName: "King Island",
    cityCode: "KNS",
    cityName: "King Island, Tasmania",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KNT",
    airportName: "Municipal",
    cityCode: "KNT",
    cityName: "Kennett",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KNU",
    airportName: "Kanpur",
    cityCode: "KNU",
    cityName: "Kanpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "KNV",
    airportName: "Knights Inlet",
    cityCode: "KNV",
    cityName: "Knights Inlet",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "KNW",
    airportName: "New Stuyahok",
    cityCode: "KNW",
    cityName: "New Stuyahok",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KNX",
    airportName: "Kununurra",
    cityCode: "KNX",
    cityName: "Kununurra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KNY",
    airportName: "Kinoosao",
    cityCode: "KNY",
    cityName: "Kinoosao",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "KNZ",
    airportName: "Kenieba",
    cityCode: "KNZ",
    cityName: "Kenieba",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "KOA",
    airportName: "Kona Intl. Arpt.",
    cityCode: "KOA",
    cityName: "Kona, HI",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KOB",
    airportName: "Koutaba",
    cityCode: "KOB",
    cityName: "Koutaba",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "KOC",
    airportName: "Koumac",
    cityCode: "KOC",
    cityName: "Koumac",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "KOD",
    airportName: "Sabiha GÃ¶kÃ§en HavaalanÄ±",
    cityCode: "KOD",
    cityName: "Kotabangun",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KOE",
    airportName: "Eltari",
    cityCode: "KOE",
    cityName: "Kupang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KOF",
    airportName: "Komatipoort",
    cityCode: "KOF",
    cityName: "Komatipoort",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "KOG",
    airportName: "Khong",
    cityCode: "KOG",
    cityName: "Khong",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "KOH",
    airportName: "Koolatah",
    cityCode: "KOH",
    cityName: "Koolatah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KOI",
    airportName: "Orkney Island",
    cityCode: "KOI",
    cityName: "Orkney Island",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "KOJ",
    airportName: "Kagoshima",
    cityCode: "KOJ",
    cityName: "Kagoshima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KOK",
    airportName: "Kruunupyy",
    cityCode: "KOK",
    cityName: "Kokkola/Pietarsaari",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KOL",
    airportName: "Koumala",
    cityCode: "KOL",
    cityName: "Koumala",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "KOM",
    airportName: "Komo-Manda",
    cityCode: "KOM",
    cityName: "Komo-Manda",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KON",
    airportName: "Kontum",
    cityCode: "KON",
    cityName: "Kontum",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "KOO",
    airportName: "Kongolo",
    cityCode: "KOO",
    cityName: "Kongolo",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KOP",
    airportName: "Nakhon Phanom",
    cityCode: "KOP",
    cityName: "Nakhon Phanom",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "KOQ",
    airportName: "Koethen",
    cityCode: "KOQ",
    cityName: "Koethen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "KOR",
    airportName: "Kokoro",
    cityCode: "KOR",
    cityName: "Kokoro",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KOS",
    airportName: "Sihanoukville",
    cityCode: "KOS",
    cityName: "Sihanoukville",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "KOT",
    airportName: "Kotlik",
    cityCode: "KOT",
    cityName: "Kotlik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KOU",
    airportName: "Koulamoutou",
    cityCode: "KOU",
    cityName: "Koulamoutou",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "KOV",
    airportName: "Kokshetau",
    cityCode: "KOV",
    cityName: "Kokshetau",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "KOW",
    airportName: "Ganzhou",
    cityCode: "KOW",
    cityName: "Ganzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KOX",
    airportName: "Kokonao",
    cityCode: "KOX",
    cityName: "Kokonao",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KOY",
    airportName: "Olga Bay SPB",
    cityCode: "KOY",
    cityName: "Olga Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KOZ",
    airportName: "Ouzinkie SPB",
    cityCode: "KOZ",
    cityName: "Ouzinkie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPA",
    airportName: "Kopiago",
    cityCode: "KPA",
    cityName: "Kopiago",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KPB",
    airportName: "Point Baker SPB",
    cityCode: "KPB",
    cityName: "Point Baker",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPC",
    airportName: "Port Clarence",
    cityCode: "KPC",
    cityName: "Port Clarence",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPD",
    airportName: "King Of Prussia",
    cityCode: "KPD",
    cityName: "King Of Prussia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPE",
    airportName: "Yapsiei",
    cityCode: "KPE",
    cityName: "Yapsiei",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KPF",
    airportName: "Kondubol",
    cityCode: "KPF",
    cityName: "Kondubol",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KPG",
    airportName: "Kurupung",
    cityCode: "KPG",
    cityName: "Kurupung",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "KPH",
    airportName: "Pauloff Harbor SPB",
    cityCode: "KPH",
    cityName: "Pauloff Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPI",
    airportName: "Kapit",
    cityCode: "KPI",
    cityName: "Kapit",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "KPK",
    airportName: "Parks SPB",
    cityCode: "KPK",
    cityName: "Parks",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPL",
    airportName: "Kapal",
    cityCode: "KPL",
    cityName: "Kapal",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KPM",
    airportName: "Kompiam",
    cityCode: "KPM",
    cityName: "Kompiam",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KPN",
    airportName: "Kipnuk SPB",
    cityCode: "KPN",
    cityName: "Kipnuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPO",
    airportName: "Pohang",
    cityCode: "KPO",
    cityName: "Pohang",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "KPP",
    airportName: "Kalpowar",
    cityCode: "KPP",
    cityName: "Kalpowar",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KPR",
    airportName: "Port Williams SPB",
    cityCode: "KPR",
    cityName: "Port Williams",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPS",
    airportName: "Kempsey",
    cityCode: "KPS",
    cityName: "Kempsey",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KPT",
    airportName: "Jackpot",
    cityCode: "KPT",
    cityName: "Jackpot",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPV",
    airportName: "Perryville SPB",
    cityCode: "KPV",
    cityName: "Perryville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KPY",
    airportName: "Port Bailey SPB",
    cityCode: "KPY",
    cityName: "Port Bailey",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KQA",
    airportName: "Akutan",
    cityCode: "KQA",
    cityName: "Akutan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KQB",
    airportName: "Koonibba",
    cityCode: "KQB",
    cityName: "Koonibba",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KQL",
    airportName: "Kol",
    cityCode: "KQL",
    cityName: "Kol",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KRA",
    airportName: "Kerang",
    cityCode: "KRA",
    cityName: "Kerang",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KRB",
    airportName: "Karumba",
    cityCode: "KRB",
    cityName: "Karumba",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KRC",
    airportName: "Kerinci",
    cityCode: "KRC",
    cityName: "Kerinci",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KRD",
    airportName: "Kurundi",
    cityCode: "KRD",
    cityName: "Kurundi",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KRE",
    airportName: "Kirundo",
    cityCode: "KRE",
    cityName: "Kirundo",
    countryName: "Burundi",
    countryCode: "BI",
  },
  {
    airportCode: "KRF",
    airportName: "Kramfors",
    cityCode: "KRF",
    cityName: "Kramfors",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "KRG",
    airportName: "Karasabai",
    cityCode: "KRG",
    cityName: "Karasabai",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "KRI",
    airportName: "Kikori",
    cityCode: "KRI",
    cityName: "Kikori",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KRJ",
    airportName: "Karawari",
    cityCode: "KRJ",
    cityName: "Karawari",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KRK",
    airportName: "J. Paul Ii Balice Intl",
    cityCode: "KRK",
    cityName: "Krakow",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "KRL",
    airportName: "Korla",
    cityCode: "KRL",
    cityName: "Korla",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KRM",
    airportName: "Karanambo",
    cityCode: "KRM",
    cityName: "Karanambo",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "KRN",
    airportName: "Kiruna",
    cityCode: "KRN",
    cityName: "Kiruna",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "KRO",
    airportName: "Kurgan",
    cityCode: "KRO",
    cityName: "Kurgan",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KRP",
    airportName: "Karup",
    cityCode: "KRP",
    cityName: "Karup",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "KRQ",
    airportName: "Kramatorsk",
    cityCode: "KRQ",
    cityName: "Kramatorsk",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "KRR",
    airportName: "Pashkovsky",
    cityCode: "KRR",
    cityName: "Krasnodar",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KRS",
    airportName: "Kjevik",
    cityCode: "KRS",
    cityName: "Kristiansand",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "KRT",
    airportName: "Civil",
    cityCode: "KRT",
    cityName: "Khartoum",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "KRU",
    airportName: "Kerau",
    cityCode: "KRU",
    cityName: "Kerau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KRV",
    airportName: "Kerio Valley",
    cityCode: "KRV",
    cityName: "Kerio Valley",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "KRW",
    airportName: "Turkmanbashi",
    cityCode: "KRW",
    cityName: "Turkmenbashi",
    countryName: "Turkmenistan",
    countryCode: "TM",
  },
  {
    airportCode: "KRX",
    airportName: "Kar Kar",
    cityCode: "KRX",
    cityName: "Kar Kar",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KRY",
    airportName: "Karamay",
    cityCode: "KRY",
    cityName: "Karamay",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KRZ",
    airportName: "Kiri",
    cityCode: "KRZ",
    cityName: "Kiri",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "KSA",
    airportName: "Kosrae",
    cityCode: "KSA",
    cityName: "Kosrae",
    countryName: "Micronesia",
    countryCode: "FM",
  },
  {
    airportCode: "KSB",
    airportName: "Kasanombe",
    cityCode: "KSB",
    cityName: "Kasanombe",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KSC",
    airportName: "Barca",
    cityCode: "KSC",
    cityName: "Kosice",
    countryName: "Slovak Republic",
    countryCode: "SK",
  },
  {
    airportCode: "KSD",
    airportName: "Karlstad",
    cityCode: "KSD",
    cityName: "Karlstad",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "KSE",
    airportName: "Kasese",
    cityCode: "KSE",
    cityName: "Kasese",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "KSF",
    airportName: "Kassel-Calden",
    cityCode: "KSF",
    cityName: "Kassel",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "KSG",
    airportName: "Kisengan",
    cityCode: "KSG",
    cityName: "Kisengan",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KSH",
    airportName: "Kermanshah",
    cityCode: "KSH",
    cityName: "Kermanshah",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "KSI",
    airportName: "Kissidougou",
    cityCode: "KSI",
    cityName: "Kissidougou",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "KSJ",
    airportName: "Kasos Island",
    cityCode: "KSJ",
    cityName: "Kasos Island",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "KSK",
    airportName: "Karlskoga",
    cityCode: "KSK",
    cityName: "Karlskoga",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "KSL",
    airportName: "Kassala",
    cityCode: "KSL",
    cityName: "Kassala",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "KSM",
    airportName: "Saint Marys",
    cityCode: "KSM",
    cityName: "Saint Marys",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KSN",
    airportName: "Kostanay",
    cityCode: "KSN",
    cityName: "Kostanay",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "KSO",
    airportName: "Aristoteles Arpt.",
    cityCode: "KSO",
    cityName: "Kastoria",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "KSP",
    airportName: "Kosipe",
    cityCode: "KSP",
    cityName: "Kosipe",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KSQ",
    airportName: "Karshi",
    cityCode: "KSQ",
    cityName: "Karshi",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "KSR",
    airportName: "Federal No 1",
    cityCode: "KSR",
    cityName: "Sandy River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KSS",
    airportName: "Sikasso",
    cityCode: "KSS",
    cityName: "Sikasso",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "KST",
    airportName: "Kosti",
    cityCode: "KST",
    cityName: "Kosti",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "KSU",
    airportName: "Kvernberget",
    cityCode: "KSU",
    cityName: "Kristiansund",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "KSV",
    airportName: "Springvale",
    cityCode: "KSV",
    cityName: "Springvale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KSW",
    airportName: "Kiryat Shmona",
    cityCode: "KSW",
    cityName: "Kiryat Shmona",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "KSX",
    airportName: "Yasuru",
    cityCode: "KSX",
    cityName: "Yasuru",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KSY",
    airportName: "Kars",
    cityCode: "KSY",
    cityName: "Kars",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "KSZ",
    airportName: "Kotlas",
    cityCode: "KSZ",
    cityName: "Kotlas",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KTA",
    airportName: "Karratha",
    cityCode: "KTA",
    cityName: "Karratha",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KTB",
    airportName: "Thorne Bay",
    cityCode: "KTB",
    cityName: "Thorne Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KTC",
    airportName: "Katiola",
    cityCode: "KTC",
    cityName: "Katiola",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "KTD",
    airportName: "Kitadaito",
    cityCode: "KTD",
    cityName: "Kitadaito",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KTE",
    airportName: "Kerteh",
    cityCode: "KTE",
    cityName: "Kerteh",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "KTF",
    airportName: "Takaka",
    cityCode: "KTF",
    cityName: "Takaka",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "KTG",
    airportName: "Ketapang",
    cityCode: "KTG",
    cityName: "Ketapang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KTH",
    airportName: "SPB",
    cityCode: "KTH",
    cityName: "Tikchik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KTI",
    airportName: "Kratie",
    cityCode: "KTI",
    cityName: "Kratie",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "KTK",
    airportName: "Kanua",
    cityCode: "KTK",
    cityName: "Kanua",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KTL",
    airportName: "Kitale",
    cityCode: "KTL",
    cityName: "Kitale",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "KTM",
    airportName: "Tribhuvan",
    cityCode: "KTM",
    cityName: "Kathmandu",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "KTN",
    airportName: "Intl.",
    cityCode: "KTN",
    cityName: "Ketchikan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KTO",
    airportName: "Kato",
    cityCode: "KTO",
    cityName: "Kato",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "KTP",
    airportName: "Tinson",
    cityCode: "KTP",
    cityName: "Kingston",
    countryName: "Jamaica",
    countryCode: "JM",
  },
  {
    airportCode: "KTR",
    airportName: "Tindal",
    cityCode: "KTR",
    cityName: "Katherine",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KTS",
    airportName: "Brevig Mission",
    cityCode: "KTS",
    cityName: "Teller Mission",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KTT",
    airportName: "Kittila",
    cityCode: "KTT",
    cityName: "Kittila",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KTU",
    airportName: "Kota",
    cityCode: "KTU",
    cityName: "Kota",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "KTV",
    airportName: "Kamarata",
    cityCode: "KTV",
    cityName: "Kamarata",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "KTW",
    airportName: "Pyrzowice",
    cityCode: "KTW",
    cityName: "Katowice",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "KTX",
    airportName: "Koutiala",
    cityCode: "KTX",
    cityName: "Koutiala",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "KTY",
    airportName: "Katukurunda Slaf Base",
    cityCode: "KTY",
    cityName: "Katukurunda",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "KTZ",
    airportName: "Kwun Tong",
    cityCode: "KTZ",
    cityName: "Kwun Tong",
    countryName: "Hong Kong",
    countryCode: "HK",
  },
  {
    airportCode: "KUA",
    airportName: "Kuantan",
    cityCode: "KUA",
    cityName: "Kuantan",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "KUC",
    airportName: "Kuria",
    cityCode: "KUC",
    cityName: "Kuria",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "KUD",
    airportName: "Kudat",
    cityCode: "KUD",
    cityName: "Kudat",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "KUE",
    airportName: "Kukundu",
    cityCode: "KUE",
    cityName: "Kukundu",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "KUF",
    airportName: "Kurumoch",
    cityCode: "KUF",
    cityName: "Samara",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KUG",
    airportName: "Kubin Island",
    cityCode: "KUG",
    cityName: "Kubin Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KUH",
    airportName: "Kushiro",
    cityCode: "KUH",
    cityName: "Kushiro",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KUI",
    airportName: "Kawau Island",
    cityCode: "KUI",
    cityName: "Kawau Island",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "KUJ",
    airportName: "Kushimoto",
    cityCode: "KUJ",
    cityName: "Kushimoto",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KUK",
    airportName: "Kasigluk",
    cityCode: "KUK",
    cityName: "Kasigluk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KUL",
    airportName: "Kuala Lumpur Intl. Arpt.",
    cityCode: "KUL",
    cityName: "Kuala Lumpur",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "KUM",
    airportName: "Yakushima",
    cityCode: "KUM",
    cityName: "Yakushima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "KUN",
    airportName: "Kaunas",
    cityCode: "KUN",
    cityName: "Kaunas",
    countryName: "Lithuania",
    countryCode: "LT",
  },
  {
    airportCode: "KUO",
    airportName: "Kuopio",
    cityCode: "KUO",
    cityName: "Kuopio",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "KUP",
    airportName: "Kupiano",
    cityCode: "KUP",
    cityName: "Kupiano",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KUQ",
    airportName: "Kuri",
    cityCode: "KUQ",
    cityName: "Kuri",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KUR",
    airportName: "Kuran-O-Munjan",
    cityCode: "KUR",
    cityName: "Kuran-O-Munjan",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "KUS",
    airportName: "Kulusuk",
    cityCode: "KUS",
    cityName: "Kulusuk",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "KUT",
    airportName: "Kopitnari",
    cityCode: "KUT",
    cityName: "Kutaisi",
    countryName: "Georgia",
    countryCode: "GE",
  },
  {
    airportCode: "KUU",
    airportName: "Kullu Manali",
    cityCode: "KUU",
    cityName: "Bhuntar Kullu.",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "KUV",
    airportName: "Gunsan",
    cityCode: "KUV",
    cityName: "Gunsan",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "KUW",
    airportName: "Kugururok River",
    cityCode: "KUW",
    cityName: "Kugururok River",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KUX",
    airportName: "Kuyol",
    cityCode: "KUX",
    cityName: "Kuyol",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KUY",
    airportName: "Kamusi Arpt.",
    cityCode: "KUY",
    cityName: "Kamusi",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KVA",
    airportName: "Megas Alexandros Intl.",
    cityCode: "KVA",
    cityName: "Kavala",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "KVB",
    airportName: "Skovde",
    cityCode: "KVB",
    cityName: "Skovde",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "KVC",
    airportName: "King Cove",
    cityCode: "KVC",
    cityName: "King Cove",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KVD",
    airportName: "Gyandzha",
    cityCode: "KVD",
    cityName: "Gyandzha",
    countryName: "Azerbaijan",
    countryCode: "AZ",
  },
  {
    airportCode: "KVE",
    airportName: "Kitava",
    cityCode: "KVE",
    cityName: "Kitava",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KVG",
    airportName: "Kavieng",
    cityCode: "KVG",
    cityName: "Kavieng",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KVK",
    airportName: "Kirovsk",
    cityCode: "KVK",
    cityName: "Kirovsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KVL",
    airportName: "Kivalina",
    cityCode: "KVL",
    cityName: "Kivalina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KVU",
    airportName: "Korolevu",
    cityCode: "KVU",
    cityName: "Korolevu",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "KVX",
    airportName: "Kirov",
    cityCode: "KVX",
    cityName: "Kirov",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KWA",
    airportName: "Kwajalein",
    cityCode: "KWA",
    cityName: "Kwajalein",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "KWB",
    airportName: "Karimunjawa",
    cityCode: "KWB",
    cityName: "Karimunjawa",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "KWD",
    airportName: "Kawadjia",
    cityCode: "KWD",
    cityName: "Kawadjia",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "KWE",
    airportName: "Guiyang",
    cityCode: "KWE",
    cityName: "Guiyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KWF",
    airportName: "Waterfall SPB",
    cityCode: "KWF",
    cityName: "Waterfall",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KWG",
    airportName: "Krivoy Rog",
    cityCode: "KWG",
    cityName: "Krivoy Rog",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "KWH",
    airportName: "Khwahan",
    cityCode: "KWH",
    cityName: "Khwahan",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "KWI",
    airportName: "Kuwait Intl.",
    cityCode: "KWI",
    cityName: "Kuwait City",
    countryName: "Kuwait",
    countryCode: "KW",
  },
  {
    airportCode: "KWJ",
    airportName: "Gwangju",
    cityCode: "KWJ",
    cityName: "Gwangju",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "KWK",
    airportName: "Kwigillingok",
    cityCode: "KWK",
    cityName: "Kwigillingok",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KWL",
    airportName: "Guilin",
    cityCode: "KWL",
    cityName: "Guilin",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "KWM",
    airportName: "Kowanyama",
    cityCode: "KWM",
    cityName: "Kowanyama",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KWN",
    airportName: "Kwinhagak",
    cityCode: "KWN",
    cityName: "Quinhagak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KWO",
    airportName: "Kawito",
    cityCode: "KWO",
    cityName: "Kawito",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KWP",
    airportName: "Village SPB",
    cityCode: "KWP",
    cityName: "West Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KWR",
    airportName: "Kwai Harbour",
    cityCode: "KWR",
    cityName: "Kwai Harbour",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "KWS",
    airportName: "Kwailabesi Aerodrom",
    cityCode: "KWS",
    cityName: "Kwailabesi",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "KWT",
    airportName: "Kwethluk",
    cityCode: "KWT",
    cityName: "Kwethluk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KWV",
    airportName: "Kurwina",
    cityCode: "KWV",
    cityName: "Kurwina",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KWX",
    airportName: "Kiwai Island",
    cityCode: "KWX",
    cityName: "Kiwai Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KWY",
    airportName: "Kiwayu",
    cityCode: "KWY",
    cityName: "Kiwayu",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "KWZ",
    airportName: "Kolwezi",
    cityCode: "KWZ",
    cityName: "Kolwezi",
    countryName: "Democratic Republic Of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "KXA",
    airportName: "Kasaan SPB",
    cityCode: "KXA",
    cityName: "Kasaan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KXE",
    airportName: "Klerksdorp",
    cityCode: "KXE",
    cityName: "Klerksdorp",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "KXF",
    airportName: "Koro Island",
    cityCode: "KXF",
    cityName: "Koro Island",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "KXK",
    airportName: "Komsomolsk Na Amure",
    cityCode: "KXK",
    cityName: "Komsomolsk Na Amure",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KXR",
    airportName: "Karoola",
    cityCode: "KXR",
    cityName: "Karoola",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KXU",
    airportName: "Katiu",
    cityCode: "KXU",
    cityName: "Katiu",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "KYA",
    airportName: "Konya",
    cityCode: "KYA",
    cityName: "Konya",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "KYB",
    airportName: "Yangoonabie",
    cityCode: "KYB",
    cityName: "Yangoonabie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KYD",
    airportName: "Orchid Island",
    cityCode: "KYD",
    cityName: "Orchid Island",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "KYE",
    airportName: "Kleyate",
    cityCode: "KYE",
    cityName: "Tripoli",
    countryName: "Lebanon",
    countryCode: "LB",
  },
  {
    airportCode: "KYF",
    airportName: "Yeelirrie",
    cityCode: "KYF",
    cityName: "Yeelirrie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KYI",
    airportName: "Yalata Mission",
    cityCode: "KYI",
    cityName: "Yalata Mission",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "KYK",
    airportName: "Karluk",
    cityCode: "KYK",
    cityName: "Karluk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KYL",
    airportName: "Port Largo",
    cityCode: "KYL",
    cityName: "Key Largo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KYN",
    airportName: "Milton Keynes",
    cityCode: "KYN",
    cityName: "Milton Keynes",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "KYO",
    airportName: "Topp Of Tampa",
    cityCode: "KYO",
    cityName: "Tampa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KYP",
    airportName: "Kyaukpyu",
    cityCode: "KYP",
    cityName: "Kyaukpyu",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "KYS",
    airportName: "Kayes",
    cityCode: "KYS",
    cityName: "Kayes",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "KYT",
    airportName: "Kyauktaw",
    cityCode: "KYT",
    cityName: "Kyauktaw",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "KYU",
    airportName: "Koyukuk",
    cityCode: "KYU",
    cityName: "Koyukuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KYX",
    airportName: "Yalumet",
    cityCode: "KYX",
    cityName: "Yalumet",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KYZ",
    airportName: "Kyzyl",
    cityCode: "KYZ",
    cityName: "Kyzyl",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KZB",
    airportName: "Zachar Bay SPB",
    cityCode: "KZB",
    cityName: "Zachar Bay",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KZC",
    airportName: "Kompong-Chhna",
    cityCode: "KZC",
    cityName: "Kompong-Chhna",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "KZD",
    airportName: "Krakor",
    cityCode: "KZD",
    cityName: "Krakor",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "KZF",
    airportName: "Kaintiba",
    cityCode: "KZF",
    cityName: "Kaintiba",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "KZG",
    airportName: "Kitzingen",
    cityCode: "KZG",
    cityName: "Kitzingen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "KZH",
    airportName: "Kizhuyak",
    cityCode: "KZH",
    cityName: "Kizhuyak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "KZI",
    airportName: "Philippos Arpt.",
    cityCode: "KZI",
    cityName: "Kozani",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "KZK",
    airportName: "Kompong Thom",
    cityCode: "KZK",
    cityName: "Kompong Thom",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "KZN",
    airportName: "Kazan",
    cityCode: "KZN",
    cityName: "Kazan",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "KZO",
    airportName: "Kzyl-Orda",
    cityCode: "KZO",
    cityName: "Kzyl-Orda",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "KZS",
    airportName: "Kastelorizo",
    cityCode: "KZS",
    cityName: "Kastelorizo",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "LAA",
    airportName: "Lamar Field",
    cityCode: "LAA",
    cityName: "Lamar",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAB",
    airportName: "Lablab",
    cityCode: "LAB",
    cityName: "Lablab",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LAD",
    airportName: "4 de Fevereiro",
    cityCode: "LAD",
    cityName: "Luanda",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "LAE",
    airportName: "Nadzab",
    cityCode: "LAE",
    cityName: "Lae",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LAF",
    airportName: "Purdue University",
    cityCode: "LAF",
    cityName: "Lafayette",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAG",
    airportName: "La Guaira",
    cityCode: "LAG",
    cityName: "La Guaira",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "LAH",
    airportName: "Labuha",
    cityCode: "LAH",
    cityName: "Labuha",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LAI",
    airportName: "Servel",
    cityCode: "LAI",
    cityName: "Lannion",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LAJ",
    airportName: "Lages",
    cityCode: "LAJ",
    cityName: "Lages",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LAK",
    airportName: "Aklavik",
    cityCode: "LAK",
    cityName: "Aklavik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "LAL",
    airportName: "Municipal",
    cityCode: "LAL",
    cityName: "Lakeland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAM",
    airportName: "Los Alamos",
    cityCode: "LAM",
    cityName: "Los Alamos",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAN",
    airportName: "Capital City",
    cityCode: "LAN",
    cityName: "Lansing",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAO",
    airportName: "Laoag Intl. Arpt.",
    cityCode: "LAO",
    cityName: "Laoag",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "LAP",
    airportName: "General Manuel MÃ¡rquez De LeÃ³n Intl. Arpt.",
    cityCode: "LAP",
    cityName: "La Paz",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "LAQ",
    airportName: "La Braq",
    cityCode: "LAQ",
    cityName: "Beida",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "LAR",
    airportName: "General Brees Field",
    cityCode: "LAR",
    cityName: "Laramie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAS",
    airportName: "Mc Carran Intl",
    cityCode: "LAS",
    cityName: "Las Vegas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAT",
    airportName: "La Uribe",
    cityCode: "LAT",
    cityName: "La Uribe",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LAU",
    airportName: "Lamu",
    cityCode: "LAU",
    cityName: "Lamu",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "LAV",
    airportName: "Lalomalava",
    cityCode: "LAV",
    cityName: "Lalomalava",
    countryName: "Samoa",
    countryCode: "WS",
  },
  {
    airportCode: "LAW",
    airportName: "Municipal",
    cityCode: "LAW",
    cityName: "Lawton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAX",
    airportName: "Los Angeles Intl.",
    cityCode: "LAX",
    cityName: "Los Angeles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LAY",
    airportName: "Ladysmith",
    cityCode: "LAY",
    cityName: "Ladysmith",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "LAZ",
    airportName: "Bom Jesus Da Lapa",
    cityCode: "LAZ",
    cityName: "Bom Jesus Da Lapa",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LBA",
    airportName: "Leeds/bradford",
    cityCode: "LBA",
    cityName: "Leeds",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LBB",
    airportName: "Intl.",
    cityCode: "LBB",
    cityName: "Lubbock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LBC",
    airportName: "LÃ¼beck Blankensee",
    cityCode: "LBC",
    cityName: "LÃ¼beck",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "LBD",
    airportName: "Khudzhand",
    cityCode: "LBD",
    cityName: "Khudzhand",
    countryName: "Tajikistan",
    countryCode: "TJ",
  },
  {
    airportCode: "LBE",
    airportName: "Westmoreland County",
    cityCode: "LBE",
    cityName: "Latrobe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LBF",
    airportName: "Lee Bird Field",
    cityCode: "LBF",
    cityName: "North Platte",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LBG",
    airportName: "Le Bourget",
    cityCode: "PAR",
    cityName: "Paris",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LBH",
    airportName: "Palm Beach SPB",
    cityCode: "SYD",
    cityName: "Sydney",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LBI",
    airportName: "Le Sequestre",
    cityCode: "LBI",
    cityName: "Albi",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LBJ",
    airportName: "Mutiara",
    cityCode: "LBJ",
    cityName: "Labuan Bajo",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LBK",
    airportName: "Liboi",
    cityCode: "LBK",
    cityName: "Liboi",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "LBL",
    airportName: "Municipal",
    cityCode: "LBL",
    cityName: "Liberal",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LBM",
    airportName: "Luabo",
    cityCode: "LBM",
    cityName: "Luabo",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "LBN",
    airportName: "Lake Baringo",
    cityCode: "LBN",
    cityName: "Lake Baringo",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "LBO",
    airportName: "Lusambo",
    cityCode: "LBO",
    cityName: "Lusambo",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "LBQ",
    airportName: "Lambarene",
    cityCode: "LBQ",
    cityName: "Lambarene",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "LBR",
    airportName: "Labrea",
    cityCode: "LBR",
    cityName: "Labrea",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LBS",
    airportName: "Labasa",
    cityCode: "LBS",
    cityName: "Labasa",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "LBT",
    airportName: "Lumberton",
    cityCode: "LBT",
    cityName: "Lumberton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LBU",
    airportName: "Labuan",
    cityCode: "LBU",
    cityName: "Labuan",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LBV",
    airportName: "Libreville",
    cityCode: "LBV",
    cityName: "Libreville",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "LBW",
    airportName: "Long Bawan",
    cityCode: "LBW",
    cityName: "Long Bawan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LBX",
    airportName: "Lubang",
    cityCode: "LBX",
    cityName: "Lubang",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "LBY",
    airportName: "Montoir",
    cityCode: "LBY",
    cityName: "La Baule-Escoublac",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LBZ",
    airportName: "Lukapa",
    cityCode: "LBZ",
    cityName: "Lukapa",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "LCA",
    airportName: "Larnaca",
    cityCode: "LCA",
    cityName: "Larnaca",
    countryName: "Cyprus",
    countryCode: "CY",
  },
  {
    airportCode: "LCB",
    airportName: "Pontes e Lacerda",
    cityCode: "LCB",
    cityName: "Pontes e Lacerda",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LCC",
    airportName: "Galatina",
    cityCode: "LCC",
    cityName: "Lecce",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "LCD",
    airportName: "Louis Trichardt",
    cityCode: "LCD",
    cityName: "Louis Trichardt",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "LCE",
    airportName: "Goloson Intl.",
    cityCode: "LCE",
    cityName: "La Ceiba",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "LCF",
    airportName: "Las Vegas",
    cityCode: "LCF",
    cityName: "Rio Dulce",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "LCG",
    airportName: "La Coruna",
    cityCode: "LCG",
    cityName: "La Coruna",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "LCH",
    airportName: "Municipal",
    cityCode: "LCH",
    cityName: "Lake Charles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LCI",
    airportName: "Municipal",
    cityCode: "LCI",
    cityName: "Laconia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LCJ",
    airportName: "Lodz Lublinek",
    cityCode: "LCJ",
    cityName: "Lodz",
    countryName: "Poland",
    countryCode: "PL",
  },
  {
    airportCode: "LCK",
    airportName: "Rickenbacker",
    cityCode: "LCK",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LCL",
    airportName: "La Coloma",
    cityCode: "LCL",
    cityName: "La Coloma",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "LCM",
    airportName: "La Cumbre",
    cityCode: "LCM",
    cityName: "La Cumbre",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "LCN",
    airportName: "Balcanoona",
    cityCode: "LCN",
    cityName: "Balcanoona",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LCO",
    airportName: "Lague",
    cityCode: "LCO",
    cityName: "Lague",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "LCP",
    airportName: "Loncopue",
    cityCode: "LCP",
    cityName: "Loncopue",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "LCR",
    airportName: "La Chorrera",
    cityCode: "LCR",
    cityName: "La Chorrera",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LCS",
    airportName: "Las Canas",
    cityCode: "LCS",
    cityName: "Las Canas",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "LCV",
    airportName: "Lucca",
    cityCode: "LCV",
    cityName: "Lucca",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "LCX",
    airportName: "Liancheng",
    cityCode: "LCX",
    cityName: "Longyan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LCY",
    airportName: "London City Arpt.",
    cityCode: "LON",
    cityName: "London",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LDA",
    airportName: "Malda",
    cityCode: "LDA",
    cityName: "Malda",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "LDB",
    airportName: "Londrina",
    cityCode: "LDB",
    cityName: "Londrina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LDC",
    airportName: "Lindeman Island",
    cityCode: "LDC",
    cityName: "Lindeman Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LDE",
    airportName: "Tarbes Ossun Lourdes",
    cityCode: "LDE",
    cityName: "Lourdes/Tarbes",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LDH",
    airportName: "Lord Howe Island",
    cityCode: "LDH",
    cityName: "Lord Howe Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LDI",
    airportName: "Kikwetu",
    cityCode: "LDI",
    cityName: "Lindi",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "LDJ",
    airportName: "Linden",
    cityCode: "LDJ",
    cityName: "Linden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LDK",
    airportName: "Hovby",
    cityCode: "LDK",
    cityName: "Lidkoping",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "LDM",
    airportName: "Mason County",
    cityCode: "LDM",
    cityName: "Ludington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LDN",
    airportName: "Lamidanda",
    cityCode: "LDN",
    cityName: "Lamidanda",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "LDO",
    airportName: "Ladouanie",
    cityCode: "LDO",
    cityName: "Ladouanie",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "LDR",
    airportName: "Lodar",
    cityCode: "LDR",
    cityName: "Lodar",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "LDU",
    airportName: "Lahad Datu",
    cityCode: "LDU",
    cityName: "Lahad Datu",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LDW",
    airportName: "Lansdowne",
    cityCode: "LDW",
    cityName: "Lansdowne",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LDX",
    airportName: "St-Laurent du Maroni",
    cityCode: "LDX",
    cityName: "St Laurent du Maroni",
    countryName: "French Guiana",
    countryCode: "GF",
  },
  {
    airportCode: "LDY",
    airportName: "Eglinton",
    cityCode: "LDY",
    cityName: "Derry",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LDZ",
    airportName: "Londolozi",
    cityCode: "LDZ",
    cityName: "Londolozi",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "LEA",
    airportName: "Learmonth",
    cityCode: "LEA",
    cityName: "Learmonth",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LEB",
    airportName: "White River",
    cityCode: "LEB",
    cityName: "White River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LEC",
    airportName: "Chapada Diamantina",
    cityCode: "LEC",
    cityName: "Lencois",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LED",
    airportName: "Pulkovo",
    cityCode: "LED",
    cityName: "St Petersburg",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "LEE",
    airportName: "Leesburg",
    cityCode: "LEE",
    cityName: "Leesburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LEF",
    airportName: "Lebakeng",
    cityCode: "LEF",
    cityName: "Lebakeng",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "LEG",
    airportName: "Aleg",
    cityCode: "LEG",
    cityName: "Aleg",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "LEH",
    airportName: "Octeville",
    cityCode: "LEH",
    cityName: "Le Havre",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LEI",
    airportName: "Almeria",
    cityCode: "LEI",
    cityName: "Almeria",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "LEJ",
    airportName: "Leipzig-halle",
    cityCode: "LEJ",
    cityName: "Leipzig-halle",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "LEK",
    airportName: "Labe",
    cityCode: "LEK",
    cityName: "Labe",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "LEL",
    airportName: "Lake Evella",
    cityCode: "LEL",
    cityName: "Lake Evella",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LEM",
    airportName: "Lemmon",
    cityCode: "LEM",
    cityName: "Lemmon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LEN",
    airportName: "Leon",
    cityCode: "LEN",
    cityName: "Leon",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "LEO",
    airportName: "Leconi",
    cityCode: "LEO",
    cityName: "Leconi",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "LEP",
    airportName: "Leopoldina",
    cityCode: "LEP",
    cityName: "Leopoldina",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LEQ",
    airportName: "Lands End",
    cityCode: "LEQ",
    cityName: "Lands End",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LER",
    airportName: "Leinster",
    cityCode: "LER",
    cityName: "Leinster",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LES",
    airportName: "Lesobeng",
    cityCode: "LES",
    cityName: "Lesobeng",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "LET",
    airportName: "Gen. A.V. Cobo",
    cityCode: "LET",
    cityName: "Leticia",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LEU",
    airportName: "Aeroport De La Seu",
    cityCode: "LEU",
    cityName: "Seu De Urgel",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "LEV",
    airportName: "Levuka Airfield",
    cityCode: "LEV",
    cityName: "Bureta",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "LEW",
    airportName: "Auburn",
    cityCode: "LEW",
    cityName: "Lewiston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LEX",
    airportName: "Blue Grass",
    cityCode: "LEX",
    cityName: "Lexington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LEY",
    airportName: "Lelystad",
    cityCode: "LEY",
    cityName: "Lelystad",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "LEZ",
    airportName: "La Esperanza",
    cityCode: "LEZ",
    cityName: "La Esperanza",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "LFB",
    airportName: "Lumbo",
    cityCode: "LFB",
    cityName: "Lumbo",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "LFI",
    airportName: "Langley AFB",
    cityCode: "LFI",
    cityName: "Hampton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LFK",
    airportName: "Lufkin Angelina County",
    cityCode: "LFK",
    cityName: "Lufkin, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LFM",
    airportName: "Lamerd",
    cityCode: "LFM",
    cityName: "Lamerd",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "LFN",
    airportName: "Franklin",
    cityCode: "LFN",
    cityName: "Louisburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LFO",
    airportName: "Kelafo",
    cityCode: "LFO",
    cityName: "Kelafo/Callaf",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "LFP",
    airportName: "Lakefield",
    cityCode: "LFP",
    cityName: "Lakefield",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LFR",
    airportName: "La Fria",
    cityCode: "LFR",
    cityName: "La Fria",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "LFT",
    airportName: "Lafayette Regional",
    cityCode: "LFT",
    cityName: "Lafayette, LA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LFW",
    airportName: "Lome",
    cityCode: "LFW",
    cityName: "Lome",
    countryName: "Togo",
    countryCode: "TG",
  },
  {
    airportCode: "LGA",
    airportName: "La Guardia",
    cityCode: "NYC",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LGB",
    airportName: "Long Beach Municipal",
    cityCode: "LAX",
    cityName: "Los Angeles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LGC",
    airportName: "Calloway",
    cityCode: "LGC",
    cityName: "La Grange",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LGD",
    airportName: "La Grande",
    cityCode: "LGD",
    cityName: "La Grande",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LGE",
    airportName: "Lake Gregory",
    cityCode: "LGE",
    cityName: "Lake Gregory",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LGF",
    airportName: "Laguna AAF",
    cityCode: "LGF",
    cityName: "Yuma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LGG",
    airportName: "Liege Arpt.",
    cityCode: "LGG",
    cityName: "Liege",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "LGH",
    airportName: "Leigh Creek",
    cityCode: "LGH",
    cityName: "Leigh Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LGI",
    airportName: "Deadmans Cay",
    cityCode: "LGI",
    cityName: "Long Island",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "LGK",
    airportName: "Langakawi Intl",
    cityCode: "LGK",
    cityName: "Langkawi",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LGL",
    airportName: "Long Lellang",
    cityCode: "LGL",
    cityName: "Long Lellang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LGM",
    airportName: "Laiagam",
    cityCode: "LGM",
    cityName: "Laiagam",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LGN",
    airportName: "Linga Linga",
    cityCode: "LGN",
    cityName: "Linga Linga",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LGO",
    airportName: "Langeoog",
    cityCode: "LGO",
    cityName: "Langeoog",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "LGP",
    airportName: "Legazpi",
    cityCode: "LGP",
    cityName: "Legazpi",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "LGQ",
    airportName: "Lago Agrio",
    cityCode: "LGQ",
    cityName: "Lago Agrio",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "LGR",
    airportName: "Cochrane",
    cityCode: "LGR",
    cityName: "Cochrane",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "LGS",
    airportName: "Malargue",
    cityCode: "LGS",
    cityName: "Malargue",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "LGT",
    airportName: "Las Gaviotas",
    cityCode: "LGT",
    cityName: "Las Gaviotas",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LGU",
    airportName: "Cache",
    cityCode: "LGU",
    cityName: "Logan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LGW",
    airportName: "London Gatwick",
    cityCode: "LON",
    cityName: "London",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LGX",
    airportName: "Lugh Ganane",
    cityCode: "LGX",
    cityName: "Lugh Ganane",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "LGY",
    airportName: "Lagunillas",
    cityCode: "LGY",
    cityName: "Lagunillas",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "LGZ",
    airportName: "Leguizamo",
    cityCode: "LGZ",
    cityName: "Leguizamo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LHA",
    airportName: "Black Forest",
    cityCode: "LHA",
    cityName: "Lahr",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "LHB",
    airportName: "Lost Harbor Sea Port",
    cityCode: "LHB",
    cityName: "Lost Harbor",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LHE",
    airportName: "Alama Iqbal Intl.",
    cityCode: "LHE",
    cityName: "Lahore",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "LHG",
    airportName: "Lightning Ridge",
    cityCode: "LHG",
    cityName: "Lightning Ridge",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LHI",
    airportName: "Lereh",
    cityCode: "LHI",
    cityName: "Lereh",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LHK",
    airportName: "Guanghua",
    cityCode: "LHK",
    cityName: "Guanghua",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LHM",
    airportName: "Lincoln Regional Arpt.",
    cityCode: "LHM",
    cityName: "Lincoln, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LHN",
    airportName: "Lishan",
    cityCode: "LHN",
    cityName: "Lishan",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "LHP",
    airportName: "Lehu",
    cityCode: "LHP",
    cityName: "Lehu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LHR",
    airportName: "London Heathrow",
    cityCode: "LON",
    cityName: "London",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LHS",
    airportName: "Las Heras",
    cityCode: "LHS",
    cityName: "Las Heras",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "LHU",
    airportName: "Lianshulu",
    cityCode: "LHU",
    cityName: "Caprivi",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "LHV",
    airportName: "W T Piper Memorial",
    cityCode: "LHV",
    cityName: "Lock Haven",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LHW",
    airportName: "Lanzhou Arpt.",
    cityCode: "LHW",
    cityName: "Lanzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LIA",
    airportName: "Liangping",
    cityCode: "LIA",
    cityName: "Liangping",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LIB",
    airportName: "Limbunya",
    cityCode: "LIB",
    cityName: "Limbunya",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LIC",
    airportName: "Municipal",
    cityCode: "LIC",
    cityName: "Limon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LID",
    airportName: "Valkenburg",
    cityCode: "LID",
    cityName: "Leiden",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "LIE",
    airportName: "Libenge",
    cityCode: "LIE",
    cityName: "Libenge",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "LIF",
    airportName: "Lifou",
    cityCode: "LIF",
    cityName: "Lifou",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "LIG",
    airportName: "Bellegarde",
    cityCode: "LIG",
    cityName: "Limoges",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LIH",
    airportName: "Lihue",
    cityCode: "LIH",
    cityName: "Kauai Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LII",
    airportName: "Mulia",
    cityCode: "LII",
    cityName: "Mulia",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LIJ",
    airportName: "Long Island",
    cityCode: "LIJ",
    cityName: "Long Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LIK",
    airportName: "Likiep Island",
    cityCode: "LIK",
    cityName: "Likiep Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "LIL",
    airportName: "Lesquin",
    cityCode: "LIL",
    cityName: "Lille",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LIM",
    airportName: "Jorge Chavez Intl.",
    cityCode: "LIM",
    cityName: "Lima",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "LIN",
    airportName: "Linate",
    cityCode: "MIL",
    cityName: "Milan",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "LIO",
    airportName: "Limon",
    cityCode: "LIO",
    cityName: "Limon",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "LIP",
    airportName: "Lins",
    cityCode: "LIP",
    cityName: "Lins",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LIQ",
    airportName: "Lisala",
    cityCode: "LIQ",
    cityName: "Lisala",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "LIR",
    airportName: "Daniel Oduber Intl.",
    cityCode: "LIR",
    cityName: "Liberia",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "LIS",
    airportName: "Portela",
    cityCode: "LIS",
    cityName: "Lisbon",
    countryName: "Portugal",
    countryCode: "PT",
  },
  {
    airportCode: "LIT",
    airportName: "Adams Field Arpt.",
    cityCode: "LIT",
    cityName: "Little Rock, AR",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LIV",
    airportName: "Livengood",
    cityCode: "LIV",
    cityName: "Livengood, AK",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LIW",
    airportName: "Loikaw",
    cityCode: "LIW",
    cityName: "Loikaw",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "LIX",
    airportName: "Likoma",
    cityCode: "LIX",
    cityName: "Likoma Island",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "LIZ",
    airportName: "Loring AFB",
    cityCode: "LIZ",
    cityName: "Limestone",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LJA",
    airportName: "Lodja",
    cityCode: "LJA",
    cityName: "Lodja",
    countryName: "Democratic Republic of Congo",
    countryCode: "DRC",
  },
  {
    airportCode: "LJG",
    airportName: "Lijiang",
    cityCode: "LJG",
    cityName: "Lijiang City",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LJN",
    airportName: "Brazoria County",
    cityCode: "LJN",
    cityName: "Lake Jackson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LJU",
    airportName: "Brnik",
    cityCode: "LJU",
    cityName: "Ljubljana",
    countryName: "Slovenia",
    countryCode: "SI",
  },
  {
    airportCode: "LKA",
    airportName: "Larantuka",
    cityCode: "LKA",
    cityName: "Larantuka",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LKB",
    airportName: "Lakeba",
    cityCode: "LKB",
    cityName: "Lakeba",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "LKC",
    airportName: "Lekana",
    cityCode: "LKC",
    cityName: "Lekana",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "LKD",
    airportName: "Lakeland Downs",
    cityCode: "LKD",
    cityName: "Lakeland Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LKE",
    airportName: "Kenmore Air Harbor SPB",
    cityCode: "SEA",
    cityName: "Seattle",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LKG",
    airportName: "Lokichoggio",
    cityCode: "LKG",
    cityName: "Lokichoggio",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "LKH",
    airportName: "Long Akah",
    cityCode: "LKH",
    cityName: "Long Akah",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LKI",
    airportName: "Lakeside USAF",
    cityCode: "LKI",
    cityName: "Duluth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LKK",
    airportName: "Kulik Lake",
    cityCode: "LKK",
    cityName: "Kulik Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LKL",
    airportName: "Banak",
    cityCode: "LKL",
    cityName: "Lakselv",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "LKN",
    airportName: "Leknes",
    cityCode: "LKN",
    cityName: "Leknes",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "LKO",
    airportName: "Amausi",
    cityCode: "LKO",
    cityName: "Lucknow",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "LKP",
    airportName: "Lake Placid",
    cityCode: "LKP",
    cityName: "Lake Placid",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LKR",
    airportName: "Las Khoreh",
    cityCode: "LKR",
    cityName: "Las Khoreh",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "LKS",
    airportName: "Lakeside",
    cityCode: "LKS",
    cityName: "Lakeside",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LKT",
    airportName: "Lakota",
    cityCode: "LKT",
    cityName: "Lakota",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "LKU",
    airportName: "Lake Rudolf",
    cityCode: "LKU",
    cityName: "Lake Rudolf",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "LKV",
    airportName: "Lake County",
    cityCode: "LKV",
    cityName: "Lakeview",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LKY",
    airportName: "Lake Manyara",
    cityCode: "LKY",
    cityName: "Lake Manyara",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "LKZ",
    airportName: "Lakenheath RAF",
    cityCode: "LKZ",
    cityName: "Brandon",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LLA",
    airportName: "Kallax",
    cityCode: "LLA",
    cityName: "Lulea",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "LLE",
    airportName: "Malelane",
    cityCode: "LLE",
    cityName: "Malelane",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "LLF",
    airportName: "LingLing",
    cityCode: "LLF",
    cityName: "LingLing",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LLG",
    airportName: "Chillagoe",
    cityCode: "LLG",
    cityName: "Chillagoe",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LLH",
    airportName: "Las Limas",
    cityCode: "LLH",
    cityName: "Las Limas",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "LLI",
    airportName: "Lalibela",
    cityCode: "LLI",
    cityName: "Lalibela",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "LLL",
    airportName: "Lissadell",
    cityCode: "LLL",
    cityName: "Lissadell",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LLM",
    airportName: "Long Lama",
    cityCode: "LLM",
    cityName: "Long Lama",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LLN",
    airportName: "Kelila",
    cityCode: "LLN",
    cityName: "Kelila",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LLP",
    airportName: "Linda Downs",
    cityCode: "LLP",
    cityName: "Linda Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LLS",
    airportName: "Las Lomitas",
    cityCode: "LLS",
    cityName: "Las Lomitas",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "LLT",
    airportName: "Lilydale Arpt.",
    cityCode: "LLT",
    cityName: "Lilydale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LLW",
    airportName: "Kumuzu Intl. Arpt.",
    cityCode: "LLW",
    cityName: "Lilongwe",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "LLX",
    airportName: "Lyndonville",
    cityCode: "LLX",
    cityName: "Lyndonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LLY",
    airportName: "Burlington County",
    cityCode: "LLY",
    cityName: "Mount Holly",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LMA",
    airportName: "Lake Minchumina",
    cityCode: "LMA",
    cityName: "Lake Minchumina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LMB",
    airportName: "Salima",
    cityCode: "LMB",
    cityName: "Salima",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "LMC",
    airportName: "Lamacarena",
    cityCode: "LMC",
    cityName: "Lamacarena",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LMD",
    airportName: "Los Menucos",
    cityCode: "LMD",
    cityName: "Los Menucos",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "LME",
    airportName: "Arnage",
    cityCode: "LME",
    cityName: "Le Mans",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LMG",
    airportName: "Lamassa",
    cityCode: "LMG",
    cityName: "Lamassa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LMH",
    airportName: "Limon",
    cityCode: "LMH",
    cityName: "Limon",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "LMI",
    airportName: "Lumi",
    cityCode: "LMI",
    cityName: "Lumi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LML",
    airportName: "Lae Island",
    cityCode: "LML",
    cityName: "Lae Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "LMM",
    airportName: "Federal",
    cityCode: "LMM",
    cityName: "Los Mochis",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "LMN",
    airportName: "Limbang",
    cityCode: "LMN",
    cityName: "Limbang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LMO",
    airportName: "RAF Station",
    cityCode: "LMO",
    cityName: "Lossiemouth",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LMP",
    airportName: "Lampedusa",
    cityCode: "LMP",
    cityName: "Lampedusa",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "LMQ",
    airportName: "Marsa Brega",
    cityCode: "LMQ",
    cityName: "Marsa Brega",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "LMR",
    airportName: "Lime Acres",
    cityCode: "LMR",
    cityName: "Lime Acres",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "LMS",
    airportName: "Winston County",
    cityCode: "LMS",
    cityName: "Louisville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LMT",
    airportName: "Kingsley Field",
    cityCode: "LMT",
    cityName: "Klamath Falls",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LMX",
    airportName: "Lopez De Micay",
    cityCode: "LMX",
    cityName: "Lopez De Micay",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LMY",
    airportName: "Lake Murray",
    cityCode: "LMY",
    cityName: "Lake Murray",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LMZ",
    airportName: "Palma",
    cityCode: "LMZ",
    cityName: "Palma",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "LNA",
    airportName: "Palm Beach County Park",
    cityCode: "LNA",
    cityName: "West Palm Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNB",
    airportName: "Lamen Bay",
    cityCode: "LNB",
    cityName: "Lamen Bay",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "LNC",
    airportName: "Lengbati",
    cityCode: "LNC",
    cityName: "Lengbati",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LND",
    airportName: "Hunt Field",
    cityCode: "LND",
    cityName: "Lander",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNE",
    airportName: "Lonorore",
    cityCode: "LNE",
    cityName: "Lonorore",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "LNF",
    airportName: "Munbil",
    cityCode: "LNF",
    cityName: "Munbil",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LNG",
    airportName: "Lese",
    cityCode: "LNG",
    cityName: "Lese",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LNH",
    airportName: "Lake Nash",
    cityCode: "LNH",
    cityName: "Lake Nash",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LNI",
    airportName: "Dew Station",
    cityCode: "LNI",
    cityName: "Lonely",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNJ",
    airportName: "Lincang",
    cityCode: "LNJ",
    cityName: "Lincang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LNK",
    airportName: "Lincoln",
    cityCode: "LNK",
    cityName: "Lincoln, NE",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNM",
    airportName: "Langimar",
    cityCode: "LNM",
    cityName: "Langimar",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LNN",
    airportName: "Lost Nation",
    cityCode: "LNN",
    cityName: "Willoughby",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNO",
    airportName: "Leonora",
    cityCode: "LNO",
    cityName: "Leonora",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LNP",
    airportName: "Wise",
    cityCode: "LNP",
    cityName: "Wise",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNQ",
    airportName: "Loani",
    cityCode: "LNQ",
    cityName: "Loani",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LNR",
    airportName: "Tri County",
    cityCode: "LNR",
    cityName: "Lone Rock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNS",
    airportName: "Lancaster",
    cityCode: "LNS",
    cityName: "Lancaster",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNV",
    airportName: "Lihir Island",
    cityCode: "LNV",
    cityName: "Lihir Island",
    countryName: "Papua New Guinea",
    countryCode: "PNG",
  },
  {
    airportCode: "LNX",
    airportName: "Smolensk",
    cityCode: "LNX",
    cityName: "Smolensk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "LNY",
    airportName: "Lanai City",
    cityCode: "LNY",
    cityName: "Lanai City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LNZ",
    airportName: "Blue Danube",
    cityCode: "LNZ",
    cityName: "Linz",
    countryName: "Austria",
    countryCode: "AT",
  },
  {
    airportCode: "LOA",
    airportName: "Lorraine",
    cityCode: "LOA",
    cityName: "Lorraine",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LOC",
    airportName: "Lock",
    cityCode: "LOC",
    cityName: "Lock",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LOD",
    airportName: "Longana",
    cityCode: "LOD",
    cityName: "Longana",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "LOE",
    airportName: "Loei",
    cityCode: "LOE",
    cityName: "Loei",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "LOF",
    airportName: "Loen",
    cityCode: "LOF",
    cityName: "Loen",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "LOG",
    airportName: "Longview",
    cityCode: "LOG",
    cityName: "Longview",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LOH",
    airportName: "Camilo Ponce EnrÃ­quez Arpt.",
    cityCode: "LOH",
    cityName: "Catamayo, Loja",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "LOI",
    airportName: "Helmuth Baungartem",
    cityCode: "LOI",
    cityName: "Lontras",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LOK",
    airportName: "Lodwar",
    cityCode: "LOK",
    cityName: "Lodwar",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "LOL",
    airportName: "Derby Field",
    cityCode: "LOL",
    cityName: "Lovelock",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LOM",
    airportName: "Francisco P. V. Y R.",
    cityCode: "LOM",
    cityName: "Lagos De Moreno",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "LOQ",
    airportName: "Lobatse",
    cityCode: "LOQ",
    cityName: "Lobatse",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "LOR",
    airportName: "Lowe Army Heliport",
    cityCode: "LOR",
    cityName: "Ozark",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LOS",
    airportName: "Murtala Muhammed",
    cityCode: "LOS",
    cityName: "Lagos",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "LOT",
    airportName: "Lewis Lockport",
    cityCode: "LOT",
    cityName: "Lockport",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LOU",
    airportName: "Bowman Field Arpt.",
    cityCode: "LOU",
    cityName: "Louisville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LOV",
    airportName: "Monclova",
    cityCode: "LOV",
    cityName: "Monclova",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "LOY",
    airportName: "Loyangalani",
    cityCode: "LOY",
    cityName: "Loyangalani",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "LOZ",
    airportName: "Corbin-London",
    cityCode: "LOZ",
    cityName: "London",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LPA",
    airportName: "Gran Canaria",
    cityCode: "LPA",
    cityName: "Las Palmas",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "LPB",
    airportName: "El Alto",
    cityCode: "LPB",
    cityName: "La Paz",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "LPC",
    airportName: "Lompoc Arpt.",
    cityCode: "LPC",
    cityName: "Lompoc",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LPD",
    airportName: "La Pedrera",
    cityCode: "LPD",
    cityName: "La Pedrera",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LPE",
    airportName: "La Primavera",
    cityCode: "LPE",
    cityName: "La Primavera",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LPG",
    airportName: "La Plata",
    cityCode: "LPG",
    cityName: "La Plata",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "LPH",
    airportName: "Heliport",
    cityCode: "LPH",
    cityName: "Lochgilphead",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LPI",
    airportName: "Linkoping",
    cityCode: "LPI",
    cityName: "Linkoping",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "LPJ",
    airportName: "Pijiguaos",
    cityCode: "LPJ",
    cityName: "Pijiguaos",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "LPK",
    airportName: "Lipetsk",
    cityCode: "LPK",
    cityName: "Lipetsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "LPL",
    airportName: "Liverpool John Lennon",
    cityCode: "LPL",
    cityName: "Liverpool",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LPM",
    airportName: "Lamap",
    cityCode: "LPM",
    cityName: "Lamap",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "LPN",
    airportName: "Leron Plains",
    cityCode: "LPN",
    cityName: "Leron Plains",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LPO",
    airportName: "Municipal",
    cityCode: "LPO",
    cityName: "Laporte",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LPP",
    airportName: "Lappeenranta",
    cityCode: "LPP",
    cityName: "Lappeenranta",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "LPQ",
    airportName: "Luang Prabang",
    cityCode: "LPQ",
    cityName: "Luang Prabang",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "LPS",
    airportName: "Lopez Island",
    cityCode: "LPS",
    cityName: "Lopez Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LPT",
    airportName: "Lampang",
    cityCode: "LPT",
    cityName: "Lampang",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "LPU",
    airportName: "Long Apung",
    cityCode: "LPU",
    cityName: "Long Apung",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LPW",
    airportName: "Little Port Walter",
    cityCode: "LPW",
    cityName: "Little Port Walter",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LPX",
    airportName: "Liepaya",
    cityCode: "LPX",
    cityName: "Liepaya",
    countryName: "Latvia",
    countryCode: "LV",
  },
  {
    airportCode: "LPY",
    airportName: "Loudes",
    cityCode: "LPY",
    cityName: "Le Puy",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LQK",
    airportName: "Pickens",
    cityCode: "LQK",
    cityName: "Pickens",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LQM",
    airportName: "Puerto Leguizamo",
    cityCode: "LQM",
    cityName: "Puerto Leguizamo",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LQN",
    airportName: "Qala Nau",
    cityCode: "LQN",
    cityName: "Qala Nau",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "LRA",
    airportName: "Larisa",
    cityCode: "LRA",
    cityName: "Larisa",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "LRB",
    airportName: "Leribe",
    cityCode: "LRB",
    cityName: "Leribe",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "LRC",
    airportName: "R.A.F.",
    cityCode: "LRC",
    cityName: "Laarbruch",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "LRD",
    airportName: "Intl.",
    cityCode: "LRD",
    cityName: "Laredo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LRE",
    airportName: "Longreach",
    cityCode: "LRE",
    cityName: "Longreach",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LRF",
    airportName: "Little Rock Afb",
    cityCode: "LRF",
    cityName: "Jacksonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LRG",
    airportName: "Lora Lai",
    cityCode: "LRG",
    cityName: "Lora Lai",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "LRH",
    airportName: "Laleu",
    cityCode: "LRH",
    cityName: "La Rochelle",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LRI",
    airportName: "Lorica",
    cityCode: "LRI",
    cityName: "Lorica",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "LRJ",
    airportName: "Municipal",
    cityCode: "LRJ",
    cityName: "Lemars",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LRK",
    airportName: "Coast Guard",
    cityCode: "LRK",
    cityName: "Lincoln Rock, AK",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LRL",
    airportName: "Niamtougou",
    cityCode: "LRL",
    cityName: "Niamtougou",
    countryName: "Togo",
    countryCode: "TG",
  },
  {
    airportCode: "LRM",
    airportName: "Casa de Campo Intl.",
    cityCode: "LRM",
    cityName: "La Romana",
    countryName: "Dominican Republic",
    countryCode: "DO",
  },
  {
    airportCode: "LRO",
    airportName: "Sharpe AAF",
    cityCode: "LRO",
    cityName: "Lathrop",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LRQ",
    airportName: "Laurie River",
    cityCode: "LRQ",
    cityName: "Laurie River",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "LRR",
    airportName: "Lar A/P",
    cityCode: "LRR",
    cityName: "Lar",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "LRS",
    airportName: "Leros",
    cityCode: "LRS",
    cityName: "Leros",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "LRT",
    airportName: "Lann Bihoue",
    cityCode: "LRT",
    cityName: "Lorient",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LRU",
    airportName: "Municipal",
    cityCode: "LRU",
    cityName: "Las Cruces, NM",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LRV",
    airportName: "Los Roques",
    cityCode: "LRV",
    cityName: "Los Roques",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "LSA",
    airportName: "Losuia",
    cityCode: "LSA",
    cityName: "Losuia",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LSB",
    airportName: "Lordsburg",
    cityCode: "LSB",
    cityName: "Lordsburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LSC",
    airportName: "La Florida",
    cityCode: "LSC",
    cityName: "La Serena",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "LSD",
    airportName: "Blue Grass Station Army Heliport",
    cityCode: "LSD",
    cityName: "Lexington, KY",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LSE",
    airportName: "Municipal",
    cityCode: "LSE",
    cityName: "La Crosse",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LSF",
    airportName: "Lawson Aaf",
    cityCode: "LSF",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LSH",
    airportName: "Lashio",
    cityCode: "LSH",
    cityName: "Lashio",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "LSI",
    airportName: "Sumburgh",
    cityCode: "LSI",
    cityName: "Shetland Islands",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LSJ",
    airportName: "Long Island",
    cityCode: "LSJ",
    cityName: "Long Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LSL",
    airportName: "Los Chiles",
    cityCode: "LSL",
    cityName: "Los Chiles",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "LSM",
    airportName: "Lawas",
    cityCode: "LSM",
    cityName: "Long Semado",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LSN",
    airportName: "Los Banos",
    cityCode: "LSN",
    cityName: "Los Banos",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LSO",
    airportName: "Talmont",
    cityCode: "LSO",
    cityName: "Les Sables",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LSP",
    airportName: "Josefa Camejo",
    cityCode: "LSP",
    cityName: "Las Piedras",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "LSQ",
    airportName: "Los Angeles",
    cityCode: "LSQ",
    cityName: "Los Angeles",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "LSR",
    airportName: "Lost River",
    cityCode: "LSR",
    cityName: "Lost River",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LSS",
    airportName: "Terre-de-Haut",
    cityCode: "LSS",
    cityName: "Terre-de-Haut",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "LST",
    airportName: "Launceston",
    cityCode: "LST",
    cityName: "Launceston",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LSU",
    airportName: "Long Sukang",
    cityCode: "LSU",
    cityName: "Long Sukang",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LSV",
    airportName: "Nellis AFB",
    cityCode: "LSV",
    cityName: "Las Vegas, NV",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LSW",
    airportName: "Lhoksumawe",
    cityCode: "LSW",
    cityName: "Lhoksumawe",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LSX",
    airportName: "Lhok Sukon",
    cityCode: "LSX",
    cityName: "Lhok Sukon",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LSY",
    airportName: "Lismore",
    cityCode: "LSY",
    cityName: "Lismore",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LSZ",
    airportName: "Losinj Arpt",
    cityCode: "LSZ",
    cityName: "Mali Losinj",
    countryName: "Croatia",
    countryCode: "HR",
  },
  {
    airportCode: "LTA",
    airportName: "Letaba",
    cityCode: "LTA",
    cityName: "Tzaneen",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "LTB",
    airportName: "Latrobe",
    cityCode: "LTB",
    cityName: "Latrobe",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LTC",
    airportName: "Lai",
    cityCode: "LTC",
    cityName: "Lai",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "LTD",
    airportName: "Ghadames",
    cityCode: "LTD",
    cityName: "Ghadames",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "LTF",
    airportName: "Leitre",
    cityCode: "LTF",
    cityName: "Leitre",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LTG",
    airportName: "Langtang",
    cityCode: "LTG",
    cityName: "Langtang",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "LTH",
    airportName: "Lathrop Wells",
    cityCode: "LTH",
    cityName: "Lathrop Wells",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LTI",
    airportName: "Altai",
    cityCode: "LTI",
    cityName: "Altai",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "LTK",
    airportName: "Latakia",
    cityCode: "LTK",
    cityName: "Latakia",
    countryName: "Syria",
    countryCode: "SY",
  },
  {
    airportCode: "LTL",
    airportName: "Lastourville",
    cityCode: "LTL",
    cityName: "Lastourville",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "LTM",
    airportName: "Lethem",
    cityCode: "LTM",
    cityName: "Lethem",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "LTN",
    airportName: "London Luton",
    cityCode: "LON",
    cityName: "London",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LTO",
    airportName: "Loreto",
    cityCode: "LTO",
    cityName: "Loreto",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "LTP",
    airportName: "Lyndhurst",
    cityCode: "LTP",
    cityName: "Lyndhurst",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LTQ",
    airportName: "Le Touquet",
    cityCode: "LTQ",
    cityName: "Le Touquet",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LTR",
    airportName: "Letterkenny",
    cityCode: "LTR",
    cityName: "Letterkenny",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "LTS",
    airportName: "Altus AFB",
    cityCode: "LTS",
    cityName: "Altus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LTT",
    airportName: "La Mole",
    cityCode: "LTT",
    cityName: "Saint Tropez",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LTV",
    airportName: "Lotusvale",
    cityCode: "LTV",
    cityName: "Lotusvale",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LTW",
    airportName: "St Marys County",
    cityCode: "LTW",
    cityName: "Leonardtown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LTX",
    airportName: "Cotopaxi Intl.",
    cityCode: "LTX",
    cityName: "Latacunga",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "LUA",
    airportName: "Lukla",
    cityCode: "LUA",
    cityName: "Lukla",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "LUB",
    airportName: "Lumid Pau",
    cityCode: "LUB",
    cityName: "Lumid Pau",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "LUC",
    airportName: "Laucala Is",
    cityCode: "LUC",
    cityName: "Laucala Is",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "LUD",
    airportName: "Luderitz",
    cityCode: "LUD",
    cityName: "Luderitz",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "LUE",
    airportName: "Lucenec",
    cityCode: "LUE",
    cityName: "Lucenec",
    countryName: "Slovak Republic",
    countryCode: "SK",
  },
  {
    airportCode: "LUF",
    airportName: "Luke Afb",
    cityCode: "PHX",
    cityName: "Phoenix",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LUG",
    airportName: "Lugano",
    cityCode: "LUG",
    cityName: "Lugano/Agno",
    countryName: "Switzerland",
    countryCode: "CH",
  },
  {
    airportCode: "LUH",
    airportName: "Amritsar",
    cityCode: "LUH",
    cityName: "Ludhiana",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "LUI",
    airportName: "La Union",
    cityCode: "LUI",
    cityName: "La Union",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "LUJ",
    airportName: "Lusikisiki",
    cityCode: "LUJ",
    cityName: "Lusikisiki",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "LUK",
    airportName: "Cincinnati Municipal-Lunken Field",
    cityCode: "LUK",
    cityName: "Cincinnati, OH",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LUL",
    airportName: "Hesler-Noble Field",
    cityCode: "LUL",
    cityName: "Laurel, MS",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LUM",
    airportName: "Mangshi",
    cityCode: "LUM",
    cityName: "Luxi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LUN",
    airportName: "Lusaka",
    cityCode: "LUN",
    cityName: "Lusaka",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "LUO",
    airportName: "Luena",
    cityCode: "LUO",
    cityName: "Luena",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "LUP",
    airportName: "Kalaupapa",
    cityCode: "LUP",
    cityName: "Kalaupapa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LUQ",
    airportName: "San Luis",
    cityCode: "LUQ",
    cityName: "San Luis",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "LUR",
    airportName: "Cape Lisburne",
    cityCode: "LUR",
    cityName: "Cape Lisburne",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LUS",
    airportName: "Lusanga",
    cityCode: "LUS",
    cityName: "Lusanga",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "LUT",
    airportName: "Laura Station",
    cityCode: "LUT",
    cityName: "Laura Station",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LUU",
    airportName: "Laura",
    cityCode: "LUU",
    cityName: "Laura",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LUV",
    airportName: "Langgur",
    cityCode: "LUV",
    cityName: "Langgur",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LUW",
    airportName: "Luwuk",
    cityCode: "LUW",
    cityName: "Luwuk",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LUX",
    airportName: "Findel",
    cityCode: "LUX",
    cityName: "Luxembourg",
    countryName: "Luxembourg",
    countryCode: "LU",
  },
  {
    airportCode: "LUY",
    airportName: "Lushoto",
    cityCode: "LUY",
    cityName: "Lushoto",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "LUZ",
    airportName: "Lushan",
    cityCode: "LUZ",
    cityName: "Lushan",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LVA",
    airportName: "Entrammes",
    cityCode: "LVA",
    cityName: "Laval",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LVB",
    airportName: "Dos Galpoes",
    cityCode: "LVB",
    cityName: "Livramento",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "LVD",
    airportName: "Lime Village",
    cityCode: "LVD",
    cityName: "Lime Village",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LVI",
    airportName: "Livingstone",
    cityCode: "LVI",
    cityName: "Livingstone",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "LVK",
    airportName: "Livermore",
    cityCode: "LVK",
    cityName: "Livermore",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LVL",
    airportName: "Lawrenceville",
    cityCode: "LVL",
    cityName: "Lawrenceville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LVM",
    airportName: "Elliott Trudeau Intl. Arpt. (Montreal)",
    cityCode: "LVM",
    cityName: "Livingston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LVO",
    airportName: "Laverton",
    cityCode: "LVO",
    cityName: "Laverton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LVP",
    airportName: "Lavan",
    cityCode: "LVP",
    cityName: "Lavan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "LVS",
    airportName: "Las Vegas",
    cityCode: "LVS",
    cityName: "Las Vegas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LWB",
    airportName: "Greenbrier Valley",
    cityCode: "LWB",
    cityName: "Lewisburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LWC",
    airportName: "Lawrence",
    cityCode: "LWC",
    cityName: "Lawrence",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LWE",
    airportName: "Lewoleba",
    cityCode: "LWE",
    cityName: "Lewoleba",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LWH",
    airportName: "Lawn Hill",
    cityCode: "LWH",
    cityName: "Lawn Hill",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LWI",
    airportName: "Lowai",
    cityCode: "LWI",
    cityName: "Lowai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "LWK",
    airportName: "Lerwick/Tingwall",
    cityCode: "LWK",
    cityName: "Shetland Islands",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LWL",
    airportName: "Harriet Field",
    cityCode: "LWL",
    cityName: "Wells",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LWM",
    airportName: "Lawrence",
    cityCode: "LWM",
    cityName: "Lawrence",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LWN",
    airportName: "Gyoumri",
    cityCode: "LWN",
    cityName: "Gyoumri",
    countryName: "Armenia",
    countryCode: "AM",
  },
  {
    airportCode: "LWO",
    airportName: "Snilow",
    cityCode: "LWO",
    cityName: "Lviv",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "LWR",
    airportName: "Leeuwarden",
    cityCode: "LWR",
    cityName: "Leeuwarden",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "LWS",
    airportName: "Nez Perce County Rgnl",
    cityCode: "LWS",
    cityName: "Lewiston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LWT",
    airportName: "Municipal",
    cityCode: "LWT",
    cityName: "Lewistown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LWV",
    airportName: "Municipal",
    cityCode: "LWV",
    cityName: "Lawrenceville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LWY",
    airportName: "Lawas",
    cityCode: "LWY",
    cityName: "Lawas",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "LXA",
    airportName: "Gonggar/Lhasa",
    cityCode: "LXA",
    cityName: "Gonggar",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LXG",
    airportName: "Luang Namtha",
    cityCode: "LXG",
    cityName: "Luang Namtha",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "LXI",
    airportName: "Linxi",
    cityCode: "LXI",
    cityName: "Linxi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LXN",
    airportName: "Lexington",
    cityCode: "LXN",
    cityName: "Lexington",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LXR",
    airportName: "Luxor",
    cityCode: "LXR",
    cityName: "Luxor",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "LXS",
    airportName: "Limnos",
    cityCode: "LXS",
    cityName: "Limnos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "LXU",
    airportName: "Lukulu",
    cityCode: "LXU",
    cityName: "Lukulu",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "LXV",
    airportName: "Leadville",
    cityCode: "LXV",
    cityName: "Leadville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LYA",
    airportName: "Luoyang",
    cityCode: "LYA",
    cityName: "Luoyang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LYB",
    airportName: "Little Cayman",
    cityCode: "LYB",
    cityName: "Little Cayman",
    countryName: "Cayman Islands",
    countryCode: "KY",
  },
  {
    airportCode: "LYC",
    airportName: "Lycksele",
    cityCode: "LYC",
    cityName: "Lycksele",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "LYE",
    airportName: "RAF Station",
    cityCode: "LYE",
    cityName: "Lyneham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "LYG",
    airportName: "Lianyungang",
    cityCode: "LYG",
    cityName: "Lianyungang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LYH",
    airportName: "Preston-Glenn Field",
    cityCode: "LYH",
    cityName: "Lynchburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LYI",
    airportName: "Linyi",
    cityCode: "LYI",
    cityName: "Linyi",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LYK",
    airportName: "Lunyuk",
    cityCode: "LYK",
    cityName: "Lunyuk",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "LYN",
    airportName: "Bron",
    cityCode: "LYN",
    cityName: "Lyon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LYP",
    airportName: "Faisalabad",
    cityCode: "LYP",
    cityName: "Faisalabad",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "LYR",
    airportName: "Svalbard",
    cityCode: "LYR",
    cityName: "Longyearbyen",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "LYS",
    airportName: "Lyon - Saint-Exupery (Satolas)",
    cityCode: "LYS",
    cityName: "Lyon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "LYT",
    airportName: "Lady Elliot Island",
    cityCode: "LYT",
    cityName: "Lady Elliot Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LYU",
    airportName: "Ely",
    cityCode: "LYU",
    cityName: "Ely",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "LZA",
    airportName: "Luiza",
    cityCode: "LZA",
    cityName: "Luiza",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "LZC",
    airportName: "Lazaro Cardenas",
    cityCode: "LZC",
    cityName: "Lazaro Cardenas",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "LZD",
    airportName: "Lanzhoudong",
    cityCode: "LZD",
    cityName: "Lanzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LZH",
    airportName: "Liuzhou",
    cityCode: "LZH",
    cityName: "Liuzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LZI",
    airportName: "Luozi",
    cityCode: "LZI",
    cityName: "Luozi",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "LZM",
    airportName: "Luzamba",
    cityCode: "LZM",
    cityName: "Luzamba",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "LZN",
    airportName: "Nangan",
    cityCode: "LZN",
    cityName: "Nangan",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "LZO",
    airportName: "Luzhou",
    cityCode: "LZO",
    cityName: "Luzhou",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "LZR",
    airportName: "Lizard Island",
    cityCode: "LZR",
    cityName: "Lizard Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "LZU",
    airportName: "Gwinnett County Arpt.",
    cityCode: "LZU",
    cityName: "Lawrenceville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MAA",
    airportName: "Madras Intl.",
    cityCode: "MAA",
    cityName: "Chennai/Madras",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "MAB",
    airportName: "Maraba",
    cityCode: "MAB",
    cityName: "Maraba",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MAC",
    airportName: "Smart",
    cityCode: "MAC",
    cityName: "Macon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MAD",
    airportName: "Barajas",
    cityCode: "MAD",
    cityName: "Madrid",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "MAE",
    airportName: "Madera",
    cityCode: "MAE",
    cityName: "Madera",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MAF",
    airportName: "Midland Intl.",
    cityCode: "MAF",
    cityName: "Midland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MAG",
    airportName: "Madang",
    cityCode: "MAG",
    cityName: "Madang",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MAH",
    airportName: "Mahon",
    cityCode: "MAH",
    cityName: "Menorca",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "MAI",
    airportName: "Mangochi",
    cityCode: "MAI",
    cityName: "Mangochi",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "MAJ",
    airportName: "Amata Kabua Intl",
    cityCode: "MAJ",
    cityName: "Majuro",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "MAK",
    airportName: "Malakal",
    cityCode: "MAK",
    cityName: "Malakal",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "MAL",
    airportName: "Mangole",
    cityCode: "MAL",
    cityName: "Mangole",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MAM",
    airportName: "Matamoros",
    cityCode: "MAM",
    cityName: "Matamoros",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MAN",
    airportName: "Manchester",
    cityCode: "MAN",
    cityName: "Manchester",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "MAO",
    airportName: "Eduardo Gomes Intl",
    cityCode: "MAO",
    cityName: "Manaus",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MAP",
    airportName: "Mamai",
    cityCode: "MAP",
    cityName: "Mamai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MAQ",
    airportName: "Mae Sot",
    cityCode: "MAQ",
    cityName: "Mae Sot",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "MAR",
    airportName: "La Chinita",
    cityCode: "MAR",
    cityName: "Maracaibo",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "MAS",
    airportName: "Momote",
    cityCode: "MAS",
    cityName: "Manus Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MAT",
    airportName: "Matadi",
    cityCode: "MAT",
    cityName: "Matadi",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "MAU",
    airportName: "Maupiti",
    cityCode: "MAU",
    cityName: "Maupiti",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "MAV",
    airportName: "Maloelap Island",
    cityCode: "MAV",
    cityName: "Maloelap Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "MAW",
    airportName: "Malden",
    cityCode: "MAW",
    cityName: "Malden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MAX",
    airportName: "Matam",
    cityCode: "MAX",
    cityName: "Matam",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "MAY",
    airportName: "Mangrove Cay",
    cityCode: "MAY",
    cityName: "Mangrove Cay",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "MAZ",
    airportName: "Eugenio M De Hostos",
    cityCode: "MAZ",
    cityName: "Mayaguez",
    countryName: "Puerto Rico",
    countryCode: "PR",
  },
  {
    airportCode: "MBA",
    airportName: "Moi Intl.",
    cityCode: "MBA",
    cityName: "Mombasa",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "MBB",
    airportName: "Marble Bar",
    cityCode: "MBB",
    cityName: "Marble Bar",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MBC",
    airportName: "Mbigou",
    cityCode: "MBC",
    cityName: "Mbigou",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MBD",
    airportName: "Mafikeng Intl. Arpt.",
    cityCode: "MBD",
    cityName: "Mmabatho",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "MBE",
    airportName: "Monbetsu",
    cityCode: "MBE",
    cityName: "Monbetsu",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MBF",
    airportName: "Mount Buffalo",
    cityCode: "MBF",
    cityName: "Mount Buffalo",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MBG",
    airportName: "Mobridge",
    cityCode: "MBG",
    cityName: "Mobridge",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MBH",
    airportName: "Maryborough",
    cityCode: "MBH",
    cityName: "Maryborough",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MBI",
    airportName: "Mbeya",
    cityCode: "MBI",
    cityName: "Mbeya",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "MBJ",
    airportName: "Sangster Intl.",
    cityCode: "MBJ",
    cityName: "Montego Bay",
    countryName: "Jamaica",
    countryCode: "JM",
  },
  {
    airportCode: "MBK",
    airportName: "Matupa",
    cityCode: "MBK",
    cityName: "Matupa",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MBL",
    airportName: "Blacker",
    cityCode: "MBL",
    cityName: "Manistee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MBM",
    airportName: "Mkambati",
    cityCode: "MBM",
    cityName: "Mkambati",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "MBN",
    airportName: "Mt Barnett",
    cityCode: "MBN",
    cityName: "Mt Barnett",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MBO",
    airportName: "Mamburao",
    cityCode: "MBO",
    cityName: "Mamburao",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "MBP",
    airportName: "Moyobamba",
    cityCode: "MBP",
    cityName: "Moyobamba",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "MBQ",
    airportName: "Mbarara",
    cityCode: "MBQ",
    cityName: "Mbarara",
    countryName: "Uganda",
    countryCode: "UG",
  },
  {
    airportCode: "MBR",
    airportName: "Mbout",
    cityCode: "MBR",
    cityName: "Mbout",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "MBS",
    airportName: "MBS Intl.",
    cityCode: "MBS",
    cityName: "Saginaw",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MBT",
    airportName: "Masbate",
    cityCode: "MBT",
    cityName: "Masbate",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "MBU",
    airportName: "Mbambanakira",
    cityCode: "MBU",
    cityName: "Mbambanakira",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "MBV",
    airportName: "Masa",
    cityCode: "MBV",
    cityName: "Masa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MBW",
    airportName: "Moorabbin",
    cityCode: "MEL",
    cityName: "Melbourne",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MBX",
    airportName: "Maribor Intl. Arpt.",
    cityCode: "MBX",
    cityName: "Maribor",
    countryName: "Slovenia",
    countryCode: "SI",
  },
  {
    airportCode: "MBY",
    airportName: "Moberly",
    cityCode: "MBY",
    cityName: "Moberly",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MBZ",
    airportName: "Maues",
    cityCode: "MBZ",
    cityName: "Maues",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MCA",
    airportName: "Macenta",
    cityCode: "MCA",
    cityName: "Macenta",
    countryName: "Guinea",
    countryCode: "GN",
  },
  {
    airportCode: "MCB",
    airportName: "Pike County",
    cityCode: "MCB",
    cityName: "Mccomb",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCC",
    airportName: "Mcclellan AFB",
    cityCode: "MCC",
    cityName: "Sacramento",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCD",
    airportName: "Mackinac Island",
    cityCode: "MCD",
    cityName: "Mackinac Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCE",
    airportName: "Merced Municipal Arpt",
    cityCode: "MCE",
    cityName: "Merced",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCF",
    airportName: "Mac Dill AFB",
    cityCode: "MCF",
    cityName: "Tampa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCG",
    airportName: "Mcgrath",
    cityCode: "MCG",
    cityName: "Mcgrath",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCH",
    airportName: "Machala",
    cityCode: "MCH",
    cityName: "Machala",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "MCI",
    airportName: "Kansas City Intl. Arpt.",
    cityCode: "MCI",
    cityName: "Kansas City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCJ",
    airportName: "Maicao",
    cityCode: "MCJ",
    cityName: "Maicao",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MCK",
    airportName: "Mccook",
    cityCode: "MCK",
    cityName: "Mccook",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCL",
    airportName: "Mt Mckinley",
    cityCode: "MCL",
    cityName: "Mt Mckinley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCM",
    airportName: "Fontvieille Heliport",
    cityCode: "MCM",
    cityName: "Monte Carlo",
    countryName: "Monaco",
    countryCode: "MC",
  },
  {
    airportCode: "MCN",
    airportName: "Lewis B Wilson",
    cityCode: "MCN",
    cityName: "Macon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCO",
    airportName: "Orlando Intl.",
    cityCode: "ORL",
    cityName: "Orlando",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCP",
    airportName: "Internacional",
    cityCode: "MCP",
    cityName: "Macapa",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MCQ",
    airportName: "Miskolc",
    cityCode: "MCQ",
    cityName: "Miskolc",
    countryName: "Hungary",
    countryCode: "HU",
  },
  {
    airportCode: "MCR",
    airportName: "Melchor De Menco",
    cityCode: "MCR",
    cityName: "Melchor De Menco",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "MCS",
    airportName: "Monte Caseros",
    cityCode: "MCS",
    cityName: "Monte Caseros",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "MCT",
    airportName: "Seeb",
    cityCode: "MCT",
    cityName: "Muscat",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "MCU",
    airportName: "Gueret",
    cityCode: "MCU",
    cityName: "Montlucon",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "MCV",
    airportName: "Mcarthur River",
    cityCode: "MCV",
    cityName: "Mcarthur River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MCW",
    airportName: "Mason City Municipal",
    cityCode: "MCW",
    cityName: "Mason City, IA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MCX",
    airportName: "Makhachkala",
    cityCode: "MCX",
    cityName: "Makhachkala",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "MCY",
    airportName: "Maroochydore",
    cityCode: "MCY",
    cityName: "Sunshine Coast",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MCZ",
    airportName: "Palmares",
    cityCode: "MCZ",
    cityName: "Maceio",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MDA",
    airportName: "Martindale Army Heliport",
    cityCode: "MDA",
    cityName: "San Antonio, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDB",
    airportName: "Melinda",
    cityCode: "MDB",
    cityName: "Melinda",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "MDC",
    airportName: "Samratulangi",
    cityCode: "MDC",
    cityName: "Manado",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MDD",
    airportName: "Airpark",
    cityCode: "MDD",
    cityName: "Midland",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDE",
    airportName: "Jose Maria Cordova",
    cityCode: "MDE",
    cityName: "Medellin",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MDF",
    airportName: "Medford",
    cityCode: "MDF",
    cityName: "Medford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDG",
    airportName: "Mudanjiang",
    cityCode: "MDG",
    cityName: "Mudanjiang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "MDH",
    airportName: "Southern Illinois",
    cityCode: "MDH",
    cityName: "Carbondale",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDI",
    airportName: "Makurdi",
    cityCode: "MDI",
    cityName: "Makurdi",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "MDJ",
    airportName: "City-county",
    cityCode: "MDJ",
    cityName: "Madras",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDK",
    airportName: "Mbandaka",
    cityCode: "MDK",
    cityName: "Mbandaka",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "MDL",
    airportName: "Mandalay Intl.",
    cityCode: "MDL",
    cityName: "Mandalay",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MDM",
    airportName: "Munduku",
    cityCode: "MDM",
    cityName: "Munduku",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MDN",
    airportName: "Jefferson Proving Grd",
    cityCode: "MDN",
    cityName: "Madison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDO",
    airportName: "Intermediate",
    cityCode: "MDO",
    cityName: "Middleton Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDP",
    airportName: "Mindiptana",
    cityCode: "MDP",
    cityName: "Mindiptana",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MDQ",
    airportName: "Mar Del Plata",
    cityCode: "MDQ",
    cityName: "Mar Del Plata",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "MDR",
    airportName: "Medfra",
    cityCode: "MDR",
    cityName: "Medfra",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDS",
    airportName: "Middle Caicos",
    cityCode: "MDS",
    cityName: "Middle Caicos",
    countryName: "Turks and Caicos Islands",
    countryCode: "TC",
  },
  {
    airportCode: "MDT",
    airportName: "Harrisburg Intl.",
    cityCode: "MDT",
    cityName: "Harrisburg, PA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDU",
    airportName: "Mendi",
    cityCode: "MDU",
    cityName: "Mendi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MDV",
    airportName: "Medouneu",
    cityCode: "MDV",
    cityName: "Medouneu",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MDW",
    airportName: "Midway",
    cityCode: "CHI",
    cityName: "Chicago",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MDX",
    airportName: "Mercedes",
    cityCode: "MDX",
    cityName: "Mercedes",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "MDY",
    airportName: "Sand Island Field",
    cityCode: "MDY",
    cityName: "Midway Island",
    countryName: "US Minor Outlying Islands",
    countryCode: "UM",
  },
  {
    airportCode: "MDZ",
    airportName: "El Plumerillo",
    cityCode: "MDZ",
    cityName: "Mendoza",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "MEA",
    airportName: "Macae",
    cityCode: "MEA",
    cityName: "Macae",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MEB",
    airportName: "Essendon",
    cityCode: "MEB",
    cityName: "Melbourne",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MEC",
    airportName: "Manta",
    cityCode: "MEC",
    cityName: "Manta",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "MED",
    airportName: "Mohammad Bin Abdulaziz",
    cityCode: "MED",
    cityName: "Madinah",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "MEE",
    airportName: "Mare",
    cityCode: "MEE",
    cityName: "Mare",
    countryName: "New Caledonia",
    countryCode: "NC",
  },
  {
    airportCode: "MEF",
    airportName: "Melfi",
    cityCode: "MEF",
    cityName: "Melfi",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "MEG",
    airportName: "Malange",
    cityCode: "MEG",
    cityName: "Malange",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "MEH",
    airportName: "Mehamn",
    cityCode: "MEH",
    cityName: "Mehamn",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "MEI",
    airportName: "Key Field",
    cityCode: "MEI",
    cityName: "Meridian",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MEJ",
    airportName: "Meadville",
    cityCode: "MEJ",
    cityName: "Meadville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MEK",
    airportName: "Meknes",
    cityCode: "MEK",
    cityName: "Meknes",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "MEL",
    airportName: "Tullamarine",
    cityCode: "MEL",
    cityName: "Melbourne",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MEM",
    airportName: "Memphis Intl.",
    cityCode: "MEM",
    cityName: "Memphis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MEN",
    airportName: "Brenoux",
    cityCode: "MEN",
    cityName: "Mende",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "MEO",
    airportName: "Dare County Regional",
    cityCode: "MEO",
    cityName: "Manteo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MEP",
    airportName: "Mersing",
    cityCode: "MEP",
    cityName: "Mersing",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "MEQ",
    airportName: "Seunagan",
    cityCode: "MEQ",
    cityName: "Meulaboh",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MER",
    airportName: "Castle AFB",
    cityCode: "MER",
    cityName: "Atwater, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MES",
    airportName: "Polonia",
    cityCode: "MES",
    cityName: "Medan",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MET",
    airportName: "Moreton",
    cityCode: "MET",
    cityName: "Moreton",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MEU",
    airportName: "Monte Dourado",
    cityCode: "MEU",
    cityName: "Monte Dourado",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MEV",
    airportName: "Douglas County",
    cityCode: "MEV",
    cityName: "Minden",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MEW",
    airportName: "Mweka",
    cityCode: "MEW",
    cityName: "Mweka",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "MEX",
    airportName: "Internacional Benito Juarez",
    cityCode: "MEX",
    cityName: "Mexico City",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MEY",
    airportName: "Meghauli",
    cityCode: "MEY",
    cityName: "Meghauli",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "MEZ",
    airportName: "Messina",
    cityCode: "MEZ",
    cityName: "Messina",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "MFA",
    airportName: "Mafia",
    cityCode: "MFA",
    cityName: "Mafia",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "MFB",
    airportName: "Monfort",
    cityCode: "MFB",
    cityName: "Monfort",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MFC",
    airportName: "Mafeteng",
    cityCode: "MFC",
    cityName: "Mafeteng",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "MFD",
    airportName: "Lahm Municipal",
    cityCode: "MFD",
    cityName: "Mansfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MFE",
    airportName: "Mc Allen Miller Intl.",
    cityCode: "MFE",
    cityName: "Mc Allen, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MFF",
    airportName: "Moanda",
    cityCode: "MFF",
    cityName: "Moanda",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MFG",
    airportName: "Muzaffarabad",
    cityCode: "MFG",
    cityName: "Muzaffarabad",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "MFI",
    airportName: "Municipal",
    cityCode: "MFI",
    cityName: "Marshfield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MFJ",
    airportName: "Moala",
    cityCode: "MFJ",
    cityName: "Moala",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "MFK",
    airportName: "Matsu",
    cityCode: "MFK",
    cityName: "Matsu",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "MFL",
    airportName: "Mount Full Stop",
    cityCode: "MFL",
    cityName: "Mount Full Stop",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MFM",
    airportName: "Macau Intl.",
    cityCode: "MFM",
    cityName: "Macau",
    countryName: "Macau",
    countryCode: "MO",
  },
  {
    airportCode: "MFN",
    airportName: "Milford Sound",
    cityCode: "MFN",
    cityName: "Milford Sound",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "MFO",
    airportName: "Manguna",
    cityCode: "MFO",
    cityName: "Manguna",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "MFP",
    airportName: "Manners Creek",
    cityCode: "MFP",
    cityName: "Manners Creek",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MFQ",
    airportName: "Maradi",
    cityCode: "MFQ",
    cityName: "Maradi",
    countryName: "Niger",
    countryCode: "NE",
  },
  {
    airportCode: "MFR",
    airportName: "Rogue Valley Intl. - Medford Arpt.",
    cityCode: "MFR",
    cityName: "Medford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MFS",
    airportName: "Miraflores",
    cityCode: "MFS",
    cityName: "Miraflores",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MFT",
    airportName: "Machu Picchu",
    cityCode: "MFT",
    cityName: "Machu Picchu",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "MFU",
    airportName: "Mfuwe",
    cityCode: "MFU",
    cityName: "Mfuwe",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "MFV",
    airportName: "Accomack County",
    cityCode: "MFV",
    cityName: "Melfa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MFW",
    airportName: "Magaruque",
    cityCode: "MFW",
    cityName: "Magaruque",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "MGA",
    airportName: "Augusto C Sandino",
    cityCode: "MGA",
    cityName: "Managua",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "MGB",
    airportName: "Mount Gambier",
    cityCode: "MGB",
    cityName: "Mount Gambier",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MGC",
    airportName: "Michigan City Arpt",
    cityCode: "MGC",
    cityName: "Michigan City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MGD",
    airportName: "Magdalena",
    cityCode: "MGD",
    cityName: "Magdalena",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "MGE",
    airportName: "Dobbins Afb",
    cityCode: "MGE",
    cityName: "Marietta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MGF",
    airportName: "Regional De Maringa SBMG",
    cityCode: "MGF",
    cityName: "Maringa",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MGG",
    airportName: "Margarima",
    cityCode: "MGG",
    cityName: "Margarima",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MGH",
    airportName: "Margate",
    cityCode: "MGH",
    cityName: "Margate",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "MGI",
    airportName: "Aransas National Wildlife Refuge",
    cityCode: "MGI",
    cityName: "Matagorda Island, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MGJ",
    airportName: "Orange County",
    cityCode: "MGJ",
    cityName: "Montgomery",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MGK",
    airportName: "Mong Ton",
    cityCode: "MGK",
    cityName: "Mong Ton",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MGL",
    airportName: "Moenchen-gl. - Dus Exp",
    cityCode: "MGL",
    cityName: "Moenchengladbach",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "MGM",
    airportName: "Dannelly Fld",
    cityCode: "MGM",
    cityName: "Montgomery",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MGN",
    airportName: "Baracoa",
    cityCode: "MGN",
    cityName: "Magangue",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MGO",
    airportName: "Manega",
    cityCode: "MGO",
    cityName: "Manega",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MGP",
    airportName: "Manga",
    cityCode: "MGP",
    cityName: "Manga",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MGQ",
    airportName: "Mogadishu Intl. Arpt.",
    cityCode: "MGQ",
    cityName: "Mogadishu",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "MGR",
    airportName: "Moultrie Municipal",
    cityCode: "MGR",
    cityName: "Moultrie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MGS",
    airportName: "Mangaia Island",
    cityCode: "MGS",
    cityName: "Mangaia Island",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "MGT",
    airportName: "Milingimbi",
    cityCode: "MGT",
    cityName: "Milingimbi",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MGU",
    airportName: "Manaung",
    cityCode: "MGU",
    cityName: "Manaung",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MGV",
    airportName: "Margaret River",
    cityCode: "MGV",
    cityName: "Margaret River Station",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MGW",
    airportName: "Morgantown",
    cityCode: "MGW",
    cityName: "Morgantown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MGX",
    airportName: "Moabi",
    cityCode: "MGX",
    cityName: "Moabi",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MGY",
    airportName: "Dayton-Wright Brothers Arpt.",
    cityCode: "MGY",
    cityName: "Dayton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MGZ",
    airportName: "Myeik",
    cityCode: "MGZ",
    cityName: "Myeik",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MHA",
    airportName: "Mahdia",
    cityCode: "MHA",
    cityName: "Mahdia",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "MHB",
    airportName: "Mechanics Bay",
    cityCode: "MHB",
    cityName: "Auckland",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "MHC",
    airportName: "Macmahon Camp 4",
    cityCode: "MHC",
    cityName: "Macmahon Camp 4",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MHD",
    airportName: "Mashad",
    cityCode: "MHD",
    cityName: "Mashad",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "MHE",
    airportName: "Municipal",
    cityCode: "MHE",
    cityName: "Mitchell",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MHF",
    airportName: "Morichal",
    cityCode: "MHF",
    cityName: "Morichal",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MHG",
    airportName: "Mannheim Arpt",
    cityCode: "MHG",
    cityName: "Mannheim",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "MHH",
    airportName: "Intl.",
    cityCode: "MHH",
    cityName: "Marsh Harbour",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "MHI",
    airportName: "Musha",
    cityCode: "MHI",
    cityName: "Musha",
    countryName: "Djibouti",
    countryCode: "DJ",
  },
  {
    airportCode: "MHK",
    airportName: "Municipal",
    cityCode: "MHK",
    cityName: "Manhattan",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MHM",
    airportName: "Intermediate",
    cityCode: "MHM",
    cityName: "Minchumina",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MHN",
    airportName: "Mullen",
    cityCode: "MHN",
    cityName: "Mullen",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MHO",
    airportName: "Mount House",
    cityCode: "MHO",
    cityName: "Mount House",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MHP",
    airportName: "Minsk Intl. 1",
    cityCode: "MSQ",
    cityName: "Minsk",
    countryName: "Belarus",
    countryCode: "BY",
  },
  {
    airportCode: "MHQ",
    airportName: "Mariehamn",
    cityCode: "MHQ",
    cityName: "Mariehamn",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "MHR",
    airportName: "Sacramento Mather Arpt.",
    cityCode: "MHR",
    cityName: "Sacramento, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MHS",
    airportName: "Rep",
    cityCode: "MHS",
    cityName: "Mount Shasta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MHT",
    airportName: "Manchester-boston Regional Arpt.",
    cityCode: "BOS",
    cityName: "Boston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MHU",
    airportName: "Mount Hotham",
    cityCode: "MHU",
    cityName: "Mount Hotham",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MHV",
    airportName: "Kern County",
    cityCode: "MHV",
    cityName: "Mojave",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MHW",
    airportName: "Monteagudo",
    cityCode: "MHW",
    cityName: "Monteagudo",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "MHX",
    airportName: "Manihiki Island",
    cityCode: "MHX",
    cityName: "Manihiki Island",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "MHY",
    airportName: "Morehead",
    cityCode: "MHY",
    cityName: "Morehead",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MHZ",
    airportName: "Mildenhall Arpt",
    cityCode: "MHZ",
    cityName: "Mildenhall",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "MIA",
    airportName: "Miami Intl. Arpt.",
    cityCode: "MIA",
    cityName: "Miami",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MIB",
    airportName: "Minot AFB",
    cityCode: "MIB",
    cityName: "Minot",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MIC",
    airportName: "Crystal",
    cityCode: "MIC",
    cityName: "Minneapolis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MID",
    airportName: "Rejon",
    cityCode: "MID",
    cityName: "Merida",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MIE",
    airportName: "Delaware County",
    cityCode: "MIE",
    cityName: "Muncie",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MIF",
    airportName: "Roy Hurd Memorial",
    cityCode: "MIF",
    cityName: "Monahans",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MIG",
    airportName: "Mian Yang",
    cityCode: "MIG",
    cityName: "Mian Yang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "MIH",
    airportName: "Mitchell Plateau",
    cityCode: "MIH",
    cityName: "Mitchell Plateau",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MII",
    airportName: "Dr Gastao Vidigal",
    cityCode: "MII",
    cityName: "Marilia",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MIJ",
    airportName: "Mili Island",
    cityCode: "MIJ",
    cityName: "Mili Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "MIK",
    airportName: "Mikkeli",
    cityCode: "MIK",
    cityName: "Mikkeli",
    countryName: "Finland",
    countryCode: "FI",
  },
  {
    airportCode: "MIM",
    airportName: "Merimbula",
    cityCode: "MIM",
    cityName: "Merimbula",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MIN",
    airportName: "Minnipa",
    cityCode: "MIN",
    cityName: "Minnipa",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MIO",
    airportName: "Miami",
    cityCode: "MIO",
    cityName: "Miami",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MIP",
    airportName: "Mitspeh Ramon",
    cityCode: "MIP",
    cityName: "Mitspeh Ramon",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "MIQ",
    airportName: "SimÃ³n BolÃ­var Intl.",
    cityCode: "MIQ",
    cityName: "MaiquetÃ­a",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "MIR",
    airportName: "Habib Bourguiba",
    cityCode: "MIR",
    cityName: "Monastir",
    countryName: "Tunisia",
    countryCode: "TN",
  },
  {
    airportCode: "MIS",
    airportName: "Misima Island",
    cityCode: "MIS",
    cityName: "Misima Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MIT",
    airportName: "Kern County",
    cityCode: "MIT",
    cityName: "Shafter",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MIU",
    airportName: "Maiduguri",
    cityCode: "MIU",
    cityName: "Maiduguri",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "MIV",
    airportName: "Millville",
    cityCode: "MIV",
    cityName: "Millville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MIW",
    airportName: "Municipal",
    cityCode: "MIW",
    cityName: "Marshalltown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MIX",
    airportName: "Miriti",
    cityCode: "MIX",
    cityName: "Miriti",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MIY",
    airportName: "Mittiebah",
    cityCode: "MIY",
    cityName: "Mittiebah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MIZ",
    airportName: "Mainoru",
    cityCode: "MIZ",
    cityName: "Mainoru",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MJA",
    airportName: "Manja",
    cityCode: "MJA",
    cityName: "Manja",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "MJB",
    airportName: "Mejit Island",
    cityCode: "MJB",
    cityName: "Mejit Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "MJC",
    airportName: "Man",
    cityCode: "MJC",
    cityName: "Man",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "MJD",
    airportName: "Mohenjodaro",
    cityCode: "MJD",
    cityName: "Mohenjodaro",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "MJE",
    airportName: "Majkin",
    cityCode: "MJE",
    cityName: "Majkin",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "MJF",
    airportName: "Kjaerstad",
    cityCode: "MJF",
    cityName: "Mosjoen",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "MJG",
    airportName: "Mayajigua",
    cityCode: "MJG",
    cityName: "Mayajigua",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "MJH",
    airportName: "Majma",
    cityCode: "MJH",
    cityName: "Majma",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "MJI",
    airportName: "Mitiga",
    cityCode: "MJI",
    cityName: "Mitiga",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "MJJ",
    airportName: "Moki",
    cityCode: "MJJ",
    cityName: "Moki",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MJK",
    airportName: "Shark Bay",
    cityCode: "MJK",
    cityName: "Monkey Mia",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MJL",
    airportName: "Mouila",
    cityCode: "MJL",
    cityName: "Mouila",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MJM",
    airportName: "Mbuji Mayi",
    cityCode: "MJM",
    cityName: "Mbuji Mayi",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "MJN",
    airportName: "Amborovy",
    cityCode: "MJN",
    cityName: "Majunga",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "MJO",
    airportName: "Mount Etjo Lodge",
    cityCode: "MJO",
    cityName: "Mount Etjo Lodge",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "MJP",
    airportName: "Manjimup",
    cityCode: "MJP",
    cityName: "Manjimup",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MJQ",
    airportName: "Jackson",
    cityCode: "MJQ",
    cityName: "Jackson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MJR",
    airportName: "Miramar",
    cityCode: "MJR",
    cityName: "Miramar",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "MJS",
    airportName: "Maganja Da Costa",
    cityCode: "MJS",
    cityName: "Maganja Da Costa",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "MJT",
    airportName: "Mytilene Intl. Arpt.",
    cityCode: "MJT",
    cityName: "Mytilene",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "MJU",
    airportName: "Mamuju",
    cityCode: "MJU",
    cityName: "Mamuju",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MJV",
    airportName: "San Javier",
    cityCode: "MJV",
    cityName: "Murcia",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "MJW",
    airportName: "Mahenye",
    cityCode: "MJW",
    cityName: "Mahenye",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "MJY",
    airportName: "Mangunjaya",
    cityCode: "MJY",
    cityName: "Mangunjaya",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MJZ",
    airportName: "Mirnyj",
    cityCode: "MJZ",
    cityName: "Mirnyj",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "MKA",
    airportName: "Marianske Lazne",
    cityCode: "MKA",
    cityName: "Marianske Lazne",
    countryName: "Czech Republic",
    countryCode: "CZ",
  },
  {
    airportCode: "MKB",
    airportName: "Mekambo",
    cityCode: "MKB",
    cityName: "Mekambo",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MKC",
    airportName: "Downtown",
    cityCode: "MKC",
    cityName: "Kansas City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MKD",
    airportName: "Chagni",
    cityCode: "MKD",
    cityName: "Chagni",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "MKE",
    airportName: "General Mitchell Intl.",
    cityCode: "MKE",
    cityName: "Milwaukee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MKF",
    airportName: "Mckenna AAF",
    cityCode: "MKF",
    cityName: "Columbus",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MKG",
    airportName: "Muskegon",
    cityCode: "MKG",
    cityName: "Muskegon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MKH",
    airportName: "Mokhotlong",
    cityCode: "MKH",
    cityName: "Mokhotlong",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "MKJ",
    airportName: "Makoua",
    cityCode: "MKJ",
    cityName: "Makoua",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "MKK",
    airportName: "Molokai",
    cityCode: "MKK",
    cityName: "Hoolehua",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MKL",
    airportName: "Mc Kellar-Sipes Regional",
    cityCode: "MKL",
    cityName: "Jackson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MKM",
    airportName: "Mukah",
    cityCode: "MKM",
    cityName: "Mukah",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "MKN",
    airportName: "Malekolon",
    cityCode: "MKN",
    cityName: "Malekolon",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MKO",
    airportName: "Davis Field",
    cityCode: "MKO",
    cityName: "Muskogee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MKP",
    airportName: "Makemo",
    cityCode: "MKP",
    cityName: "Makemo",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "MKQ",
    airportName: "Mopah",
    cityCode: "MKQ",
    cityName: "Merauke",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MKR",
    airportName: "Meekatharra",
    cityCode: "MKR",
    cityName: "Meekatharra",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MKS",
    airportName: "Mekane Selam",
    cityCode: "MKS",
    cityName: "Mekane Selam",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "MKT",
    airportName: "Municipal",
    cityCode: "MKT",
    cityName: "Mankato",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MKU",
    airportName: "Makokou",
    cityCode: "MKU",
    cityName: "Makokou",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MKV",
    airportName: "Mt Cavenagh",
    cityCode: "MKV",
    cityName: "Mt Cavenagh",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MKW",
    airportName: "Rendani",
    cityCode: "MKW",
    cityName: "Manokwari",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MKX",
    airportName: "Mukalla",
    cityCode: "MKX",
    cityName: "Mukalla",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "MKY",
    airportName: "Mackay",
    cityCode: "MKY",
    cityName: "Mackay",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MKZ",
    airportName: "Batu Berendam",
    cityCode: "MKZ",
    cityName: "Malacca",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "XIV",
    airportName: "Valladolid-Campo Grande Train Station",
    cityCode: "VLL",
    cityName: "Valladolid",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "MLA",
    airportName: "Malta Intl.",
    cityCode: "MLA",
    cityName: "Gudja",
    countryName: "Malta",
    countryCode: "MT",
  },
  {
    airportCode: "MLB",
    airportName: "Melbourne Intl. Arpt.",
    cityCode: "MLB",
    cityName: "Melbourne",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLC",
    airportName: "Mc Alester",
    cityCode: "MLC",
    cityName: "Mc Alester",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLD",
    airportName: "Malad City",
    cityCode: "MLD",
    cityName: "Malad City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLE",
    airportName: "Male Intl.",
    cityCode: "MLE",
    cityName: "Male",
    countryName: "Maldives",
    countryCode: "MV",
  },
  {
    airportCode: "MLF",
    airportName: "Milford",
    cityCode: "MLF",
    cityName: "Milford",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLG",
    airportName: "Abdul Rahman Saleh",
    cityCode: "MLG",
    cityName: "Malang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MLH",
    airportName: "EuroArpt.",
    cityCode: "MLH",
    cityName: "Basel Mulhouse Freiburg",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "MLI",
    airportName: "Quad-City",
    cityCode: "MLI",
    cityName: "Moline",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLJ",
    airportName: "Baldwin County",
    cityCode: "MLJ",
    cityName: "Milledgeville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLK",
    airportName: "Malta",
    cityCode: "MLK",
    cityName: "Malta",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLL",
    airportName: "Marshall",
    cityCode: "MLL",
    cityName: "Marshall",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLM",
    airportName: "Francisco J. Magica Internacional",
    cityCode: "MLM",
    cityName: "Morelia",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MLN",
    airportName: "Melilla",
    cityCode: "MLN",
    cityName: "Melilla",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "MLO",
    airportName: "Milos",
    cityCode: "MLO",
    cityName: "Milos",
    countryName: "Greece",
    countryCode: "GR",
  },
  {
    airportCode: "MLP",
    airportName: "Malabang",
    cityCode: "MLP",
    cityName: "Malabang",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "MLQ",
    airportName: "Malalaua",
    cityCode: "MLQ",
    cityName: "Malalaua",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MLR",
    airportName: "Millicent",
    cityCode: "MLR",
    cityName: "Millicent",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MLS",
    airportName: "Municipal",
    cityCode: "MLS",
    cityName: "Miles City",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLT",
    airportName: "Millinocket",
    cityCode: "MLT",
    cityName: "Millinocket",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLU",
    airportName: "Municipal",
    cityCode: "MLU",
    cityName: "Monroe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLV",
    airportName: "Merluna",
    cityCode: "MLV",
    cityName: "Merluna",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MLW",
    airportName: "Sprigg Payne",
    cityCode: "MLW",
    cityName: "Monrovia",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "MLX",
    airportName: "Malatya",
    cityCode: "MLX",
    cityName: "Malatya",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "MLY",
    airportName: "Manley Hot Springs",
    cityCode: "MLY",
    cityName: "Manley Hot Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MLZ",
    airportName: "Melo",
    cityCode: "MLZ",
    cityName: "Melo",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "MMB",
    airportName: "Memanbetsu",
    cityCode: "MMB",
    cityName: "Memanbetsu",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MMC",
    airportName: "Ciudad Mante",
    cityCode: "MMC",
    cityName: "Ciudad Mante",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MMD",
    airportName: "Maridor",
    cityCode: "MMD",
    cityName: "Minami Daito",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MME",
    airportName: "Durham Tees Valley",
    cityCode: "MME",
    cityName: "Teesside, Durham",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "MMF",
    airportName: "Mamfe",
    cityCode: "MMF",
    cityName: "Mamfe",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "MMG",
    airportName: "Mount Magnet",
    cityCode: "MMG",
    cityName: "Mount Magnet",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MMH",
    airportName: "Mammoth Lakes",
    cityCode: "MMH",
    cityName: "Mammoth Lakes",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MMI",
    airportName: "McMinn County",
    cityCode: "MMI",
    cityName: "Athens",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MMJ",
    airportName: "Matsumoto",
    cityCode: "MMJ",
    cityName: "Matsumoto",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MMK",
    airportName: "Murmansk Arpt. Murmashi",
    cityCode: "MMK",
    cityName: "Murmansk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "MML",
    airportName: "Municipal-Ryan Field",
    cityCode: "MML",
    cityName: "Marshall",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MMM",
    airportName: "Middlemount",
    cityCode: "MMM",
    cityName: "Middlemount",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MMN",
    airportName: "Minute Man Airfield",
    cityCode: "MMN",
    cityName: "Stow",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MMO",
    airportName: "Vila Do Maio",
    cityCode: "MMO",
    cityName: "Maio",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "MMP",
    airportName: "Mompos",
    cityCode: "MMP",
    cityName: "Mompos",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MMQ",
    airportName: "Mbala",
    cityCode: "MMQ",
    cityName: "Mbala",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "MMT",
    airportName: "Mc Entire ANG Base",
    cityCode: "MMT",
    cityName: "Columbia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MMU",
    airportName: "Municipal",
    cityCode: "MMU",
    cityName: "Morristown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MMV",
    airportName: "Mal",
    cityCode: "MMV",
    cityName: "Mal",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MMW",
    airportName: "Moma",
    cityCode: "MMW",
    cityName: "Moma",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "MMX",
    airportName: "Sturup",
    cityCode: "MMX",
    cityName: "Malmo",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "MMY",
    airportName: "Hirara",
    cityCode: "MMY",
    cityName: "Miyako Jima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MMZ",
    airportName: "Maimana",
    cityCode: "MMZ",
    cityName: "Maimana",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "MNA",
    airportName: "Melangguane",
    cityCode: "MNA",
    cityName: "Melangguane",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MNB",
    airportName: "Moanda",
    cityCode: "MNB",
    cityName: "Moanda",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "MNC",
    airportName: "Nacala",
    cityCode: "MNC",
    cityName: "Nacala",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "MND",
    airportName: "Medina",
    cityCode: "MND",
    cityName: "Medina",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MNE",
    airportName: "Mungeranie",
    cityCode: "MNE",
    cityName: "Mungeranie",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MNF",
    airportName: "Mana Island Airstrip",
    cityCode: "MNF",
    cityName: "Mana Island",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "MNG",
    airportName: "Maningrida",
    cityCode: "MNG",
    cityName: "Maningrida",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MNH",
    airportName: "Minneriya",
    cityCode: "MNH",
    cityName: "Minneriya",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "MNI",
    airportName: "Gerald's Arpt.",
    cityCode: "MNI",
    cityName: "Gerald's Park",
    countryName: "Montserrat",
    countryCode: "MS",
  },
  {
    airportCode: "MNJ",
    airportName: "Mananjary",
    cityCode: "MNJ",
    cityName: "Mananjary",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "MNK",
    airportName: "Maiana",
    cityCode: "MNK",
    cityName: "Maiana",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "MNL",
    airportName: "Ninoy Aquino Intl",
    cityCode: "MNL",
    cityName: "Manila",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "MNM",
    airportName: "Menominee",
    cityCode: "MNM",
    cityName: "Menominee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MNN",
    airportName: "Municipal",
    cityCode: "MNN",
    cityName: "Marion",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MNO",
    airportName: "Manono",
    cityCode: "MNO",
    cityName: "Manono",
    countryName: "Democratic Republic Of Congo",
    countryCode: "CD",
  },
  {
    airportCode: "MNP",
    airportName: "Maron",
    cityCode: "MNP",
    cityName: "Maron",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MNQ",
    airportName: "Monto",
    cityCode: "MNQ",
    cityName: "Monto",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MNR",
    airportName: "Mongu",
    cityCode: "MNR",
    cityName: "Mongu",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "MNS",
    airportName: "Mansa",
    cityCode: "MNS",
    cityName: "Mansa",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "MNT",
    airportName: "Minto",
    cityCode: "MNT",
    cityName: "Minto",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MNU",
    airportName: "Maulmyine",
    cityCode: "MNU",
    cityName: "Maulmyine",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MNV",
    airportName: "Mountain Valley",
    cityCode: "MNV",
    cityName: "Mountain Valley",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MNW",
    airportName: "Macdonald Downs",
    cityCode: "MNW",
    cityName: "Macdonald Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MNX",
    airportName: "Manicore",
    cityCode: "MNX",
    cityName: "Manicore",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MNY",
    airportName: "Mono",
    cityCode: "MNY",
    cityName: "Mono",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "MNZ",
    airportName: "Manassas",
    cityCode: "MNZ",
    cityName: "Manassas",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOA",
    airportName: "Orestes Acosta",
    cityCode: "MOA",
    cityName: "Moa",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "MOB",
    airportName: "Mobile Regional Arpt.",
    cityCode: "MOB",
    cityName: "Alabama, AL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOC",
    airportName: "Montes Claros",
    cityCode: "MOC",
    cityName: "Montes Claros",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MOD",
    airportName: "Municipal",
    cityCode: "MOD",
    cityName: "Modesto, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOE",
    airportName: "Momeik",
    cityCode: "MOE",
    cityName: "Momeik",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MOF",
    airportName: "Waioti",
    cityCode: "MOF",
    cityName: "Maumere",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MOG",
    airportName: "Mong Hsat",
    cityCode: "MOG",
    cityName: "Mong Hsat",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MOH",
    airportName: "Mohanbari",
    cityCode: "MOH",
    cityName: "Mohanbari",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "MOI",
    airportName: "Mitiaro Island",
    cityCode: "MOI",
    cityName: "Mitiaro Island",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "MOJ",
    airportName: "Moengo",
    cityCode: "MOJ",
    cityName: "Moengo",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "MOK",
    airportName: "Mankono",
    cityCode: "MOK",
    cityName: "Mankono",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "MOL",
    airportName: "Aro",
    cityCode: "MOL",
    cityName: "Molde",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "MOM",
    airportName: "Moudjeria",
    cityCode: "MOM",
    cityName: "Moudjeria",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "MON",
    airportName: "Mount Cook Arpt.",
    cityCode: "MON",
    cityName: "Mount Cook",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "MOO",
    airportName: "Moomba",
    cityCode: "MOO",
    cityName: "Moomba",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MOP",
    airportName: "Municipal",
    cityCode: "MOP",
    cityName: "Mount Pleasant",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOQ",
    airportName: "Morondava",
    cityCode: "MOQ",
    cityName: "Morondava",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "MOR",
    airportName: "Moore-Murrell",
    cityCode: "MOR",
    cityName: "Morristown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOS",
    airportName: "Intermediate",
    cityCode: "MOS",
    cityName: "Moses Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOT",
    airportName: "Intl.",
    cityCode: "MOT",
    cityName: "Minot",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOU",
    airportName: "Mountain Village",
    cityCode: "MOU",
    cityName: "Mountain Village",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOV",
    airportName: "Moranbah",
    cityCode: "MOV",
    cityName: "Moranbah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MOX",
    airportName: "Municipal",
    cityCode: "MOX",
    cityName: "Morris",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MOY",
    airportName: "Monterrey",
    cityCode: "MOY",
    cityName: "Monterrey",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MOZ",
    airportName: "Temae",
    cityCode: "MOZ",
    cityName: "Moorea",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "MPA",
    airportName: "Mpacha",
    cityCode: "MPA",
    cityName: "Mpacha",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "MPB",
    airportName: "SPB",
    cityCode: "MPB",
    cityName: "Miami",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MPC",
    airportName: "Muko-Muko",
    cityCode: "MPC",
    cityName: "Muko-Muko",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MPD",
    airportName: "Mirpur Khas",
    cityCode: "MPD",
    cityName: "Mirpur Khas",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "MPE",
    airportName: "Griswold",
    cityCode: "MPE",
    cityName: "Madison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MPF",
    airportName: "Mapoda",
    cityCode: "MPF",
    cityName: "Mapoda",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MPG",
    airportName: "Makini",
    cityCode: "MPG",
    cityName: "Makini",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MPH",
    airportName: "Malay",
    cityCode: "MPH",
    cityName: "Caticlan",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "MPI",
    airportName: "Mamitupo",
    cityCode: "MPI",
    cityName: "Mamitupo",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "MPJ",
    airportName: "Petit Jean Park",
    cityCode: "MPJ",
    cityName: "Morrilton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MPK",
    airportName: "Mokpo",
    cityCode: "MPK",
    cityName: "Mokpo",
    countryName: "Korea",
    countryCode: "KR",
  },
  {
    airportCode: "MPL",
    airportName: "Montpellier MÃ©diterranÃ©e",
    cityCode: "MPL",
    cityName: "Montpellier",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "MPM",
    airportName: "Maputo Intl.",
    cityCode: "MPM",
    cityName: "Maputo",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "MPN",
    airportName: "Mount Pleasant",
    cityCode: "MPN",
    cityName: "Mount Pleasant",
    countryName: "Falkland Islands",
    countryCode: "FK",
  },
  {
    airportCode: "MPO",
    airportName: "Mt Pocono",
    cityCode: "MPO",
    cityName: "Mt Pocono",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MPP",
    airportName: "Mulatupo",
    cityCode: "MPP",
    cityName: "Mulatupo",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "MPQ",
    airportName: "Maan",
    cityCode: "MPQ",
    cityName: "Maan",
    countryName: "Jordan",
    countryCode: "JO",
  },
  {
    airportCode: "MPR",
    airportName: "Mcpherson",
    cityCode: "MPR",
    cityName: "Mcpherson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MPS",
    airportName: "Mount Pleasant",
    cityCode: "MPS",
    cityName: "Mount Pleasant",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MPT",
    airportName: "Maliana",
    cityCode: "MPT",
    cityName: "Maliana",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MPU",
    airportName: "Mapua",
    cityCode: "MPU",
    cityName: "Mapua",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MPV",
    airportName: "Edward F Knapp State",
    cityCode: "MPV",
    cityName: "Montpelier, VT",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MPW",
    airportName: "Mariupol",
    cityCode: "MPW",
    cityName: "Mariupol, MN",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "MPX",
    airportName: "Miyanmin",
    cityCode: "MPX",
    cityName: "Miyanmin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MPY",
    airportName: "Maripasoula",
    cityCode: "MPY",
    cityName: "Maripasoula",
    countryName: "French Guiana",
    countryCode: "GF",
  },
  {
    airportCode: "MPZ",
    airportName: "Municipal",
    cityCode: "MPZ",
    cityName: "Mt Pleasant",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MQA",
    airportName: "Mandora",
    cityCode: "MQA",
    cityName: "Mandora",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MQB",
    airportName: "Municipal",
    cityCode: "MQB",
    cityName: "Macomb",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MQC",
    airportName: "Miquelon Aprt",
    cityCode: "MQC",
    cityName: "Miquelon",
    countryName: "St. Pierre and Miquelon",
    countryCode: "PM",
  },
  {
    airportCode: "MQD",
    airportName: "Maquinchao",
    cityCode: "MQD",
    cityName: "Maquinchao",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "MQE",
    airportName: "Marqua",
    cityCode: "MQE",
    cityName: "Marqua",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MQF",
    airportName: "Magnitogorsk",
    cityCode: "MQF",
    cityName: "Magnitogorsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "MQG",
    airportName: "Midgard",
    cityCode: "MQG",
    cityName: "Midgard",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "MQI",
    airportName: "Quincy",
    cityCode: "MQI",
    cityName: "Quincy",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MQJ",
    airportName: "Merkez",
    cityCode: "MQJ",
    cityName: "Balikesir",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "MQK",
    airportName: "San Matias",
    cityCode: "MQK",
    cityName: "San Matias",
    countryName: "Bolivia",
    countryCode: "BO",
  },
  {
    airportCode: "MQL",
    airportName: "Mildura",
    cityCode: "MQL",
    cityName: "Mildura",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MQM",
    airportName: "Mardin",
    cityCode: "MQM",
    cityName: "Mardin",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "MQN",
    airportName: "Mo I Rana",
    cityCode: "MQN",
    cityName: "Mo I Rana",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "MQO",
    airportName: "Malam",
    cityCode: "MQO",
    cityName: "Malam",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MQP",
    airportName: "Kruger Mpumalanga",
    cityCode: "MQP",
    cityName: "Nelspruit",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "MQQ",
    airportName: "Moundou",
    cityCode: "MQQ",
    cityName: "Moundou",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "MQR",
    airportName: "Mosquera",
    cityCode: "MQR",
    cityName: "Mosquera",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MQS",
    airportName: "Mustique",
    cityCode: "MQS",
    cityName: "Mustique Island",
    countryName: "Saint Vincent And The Grenadines",
    countryCode: "VC",
  },
  {
    airportCode: "MQT",
    airportName: "Sawyer Intl.",
    cityCode: "MQT",
    cityName: "Marquette",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MQU",
    airportName: "Mariquita",
    cityCode: "MQU",
    cityName: "Mariquita",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MQV",
    airportName: "Mostaganem",
    cityCode: "MQV",
    cityName: "Mostaganem",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "MQW",
    airportName: "Telfair-Wheeler",
    cityCode: "MQW",
    cityName: "Mc Rae",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MQX",
    airportName: "Makale",
    cityCode: "MQX",
    cityName: "Makale",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "MQY",
    airportName: "Smyrna",
    cityCode: "MQY",
    cityName: "Smyrna",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MQZ",
    airportName: "Margaret River",
    cityCode: "MQZ",
    cityName: "Margaret River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MRA",
    airportName: "Misurata",
    cityCode: "MRA",
    cityName: "Misurata",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "MRB",
    airportName: "Eastern Wv Regional Arpt./Shepherd Field",
    cityCode: "MRB",
    cityName: "Martinsburg",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MRC",
    airportName: "Maury County",
    cityCode: "MRC",
    cityName: "Columbia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MRD",
    airportName: "A Carnevalli",
    cityCode: "MRD",
    cityName: "Merida",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "MRE",
    airportName: "Mara Lodges",
    cityCode: "MRE",
    cityName: "Mara Lodges",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "MRF",
    airportName: "Municipal",
    cityCode: "MRF",
    cityName: "Marfa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MRG",
    airportName: "Mareeba",
    cityCode: "MRG",
    cityName: "Mareeba",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MRH",
    airportName: "May River",
    cityCode: "MRH",
    cityName: "May River",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MRI",
    airportName: "Merrill Field",
    cityCode: "MRI",
    cityName: "Anchorage",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MRJ",
    airportName: "Marcala",
    cityCode: "MRJ",
    cityName: "Marcala",
    countryName: "Honduras",
    countryCode: "HN",
  },
  {
    airportCode: "MRK",
    airportName: "Marco Island",
    cityCode: "MRK",
    cityName: "Marco Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MRL",
    airportName: "Miners Lake",
    cityCode: "MRL",
    cityName: "Miners Lake",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MRM",
    airportName: "Manare",
    cityCode: "MRM",
    cityName: "Manare",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MRN",
    airportName: "Lenoir",
    cityCode: "MRN",
    cityName: "Morganton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MRO",
    airportName: "Masterton",
    cityCode: "MRO",
    cityName: "Masterton",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "MRP",
    airportName: "Marla",
    cityCode: "MRP",
    cityName: "Marla",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MRQ",
    airportName: "Marinduque",
    cityCode: "MRQ",
    cityName: "Marinduque",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "MRR",
    airportName: "Macara",
    cityCode: "MRR",
    cityName: "Macara",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "MRS",
    airportName: "Marseille Provence Arpt.",
    cityCode: "MRS",
    cityName: "Marseille",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "MRT",
    airportName: "Moroak",
    cityCode: "MRT",
    cityName: "Moroak",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MRU",
    airportName: "Sir Seewoosagur Ramgoolam Int",
    cityCode: "MRU",
    cityName: "Plaisance",
    countryName: "Mauritius",
    countryCode: "MU",
  },
  {
    airportCode: "MRV",
    airportName: "Mineralnye Vodyj",
    cityCode: "MRV",
    cityName: "Mineralnye Vody",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "MRW",
    airportName: "Maribo",
    cityCode: "MRW",
    cityName: "Maribo",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "MRX",
    airportName: "Mahshahr",
    cityCode: "MRX",
    cityName: "Bandar Mahshahr",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "MRY",
    airportName: "Monterey Peninsula",
    cityCode: "MRY",
    cityName: "Monterey",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MRZ",
    airportName: "Moree",
    cityCode: "MRZ",
    cityName: "Moree",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MSA",
    airportName: "Muskrat Dam",
    cityCode: "MSA",
    cityName: "Muskrat Dam",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "MSB",
    airportName: "Marigot SPB",
    cityCode: "MSB",
    cityName: "St Martin",
    countryName: "Guadeloupe",
    countryCode: "GP",
  },
  {
    airportCode: "MSC",
    airportName: "Falcon Field",
    cityCode: "MSC",
    cityName: "Mesa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MSD",
    airportName: "Mt Pleasant",
    cityCode: "MSD",
    cityName: "Mt Pleasant",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MSE",
    airportName: "Kent Intl.",
    cityCode: "MSE",
    cityName: "Manston",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "MSF",
    airportName: "Mount Swan",
    cityCode: "MSF",
    cityName: "Mount Swan",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MSG",
    airportName: "Matsaile",
    cityCode: "MSG",
    cityName: "Matsaile",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "MSH",
    airportName: "Masirah",
    cityCode: "MSH",
    cityName: "Masirah",
    countryName: "Oman",
    countryCode: "OM",
  },
  {
    airportCode: "MSI",
    airportName: "Masalembo",
    cityCode: "MSI",
    cityName: "Masalembo",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MSJ",
    airportName: "Misawa",
    cityCode: "MSJ",
    cityName: "Misawa",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MSK",
    airportName: "Mastic Point",
    cityCode: "MSK",
    cityName: "Mastic Point",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "MSL",
    airportName: "Sheffield",
    cityCode: "MSL",
    cityName: "Sheffield",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MSM",
    airportName: "Masi Manimba",
    cityCode: "MSM",
    cityName: "Masi Manimba",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "MSN",
    airportName: "Dane County Regional",
    cityCode: "MSN",
    cityName: "Madison",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MSO",
    airportName: "Missoula Intl.",
    cityCode: "MSO",
    cityName: "Missoula",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MSP",
    airportName: "Minneapolis - St. Paul Intl",
    cityCode: "MSP",
    cityName: "Minneapolis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MSQ",
    airportName: "Minsk Intl. 2",
    cityCode: "MSQ",
    cityName: "Minsk",
    countryName: "Belarus",
    countryCode: "BY",
  },
  {
    airportCode: "MSS",
    airportName: "Richards Field",
    cityCode: "MSS",
    cityName: "Massena",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MST",
    airportName: "Maastricht/aachen",
    cityCode: "MST",
    cityName: "Maastricht",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "MSU",
    airportName: "Moshoeshoe Intl",
    cityCode: "MSU",
    cityName: "Maseru",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "MSV",
    airportName: "Sullivan County Intl",
    cityCode: "MSV",
    cityName: "Monticello",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MSW",
    airportName: "Massawa Intl.",
    cityCode: "MSW",
    cityName: "Massawa",
    countryName: "Eritrea",
    countryCode: "ER",
  },
  {
    airportCode: "MSX",
    airportName: "Mossendjo",
    cityCode: "MSX",
    cityName: "Mossendjo",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "MSY",
    airportName: "Louis Armstrong New Orleans",
    cityCode: "MSY",
    cityName: "New Orleans",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MSZ",
    airportName: "Namibe",
    cityCode: "MSZ",
    cityName: "Namibe",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "MTA",
    airportName: "Matamata",
    cityCode: "MTA",
    cityName: "Matamata",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "MTB",
    airportName: "Monte Libano",
    cityCode: "MTB",
    cityName: "Monte Libano",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MTD",
    airportName: "Mt Sandford",
    cityCode: "MTD",
    cityName: "Mt Sandford",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MTE",
    airportName: "Monte Alegre",
    cityCode: "MTE",
    cityName: "Monte Alegre",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MTF",
    airportName: "Mizan Teferi",
    cityCode: "MTF",
    cityName: "Mizan Teferi",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "MTG",
    airportName: "Mato Grosso",
    cityCode: "MTG",
    cityName: "Mato Grosso",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MTH",
    airportName: "Marathon Flight Strip",
    cityCode: "MTH",
    cityName: "Marathon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MTI",
    airportName: "Mosteiros",
    cityCode: "MTI",
    cityName: "Mosteiros",
    countryName: "Cape Verde",
    countryCode: "CV",
  },
  {
    airportCode: "MTJ",
    airportName: "Montrose Regional Arpt.",
    cityCode: "MTJ",
    cityName: "Montrose",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MTK",
    airportName: "Makin Island",
    cityCode: "MTK",
    cityName: "Makin Island",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "MTL",
    airportName: "Maitland",
    cityCode: "MTL",
    cityName: "Maitland",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MTM",
    airportName: "SPB",
    cityCode: "MTM",
    cityName: "Metlakatla",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MTN",
    airportName: "Glenn L Martin",
    cityCode: "MTN",
    cityName: "Baltimore",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MTO",
    airportName: "Coles County Memorial",
    cityCode: "MTO",
    cityName: "Mattoon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MTP",
    airportName: "Montauk Arpt.",
    cityCode: "MTP",
    cityName: "Montauk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MTQ",
    airportName: "Mitchell",
    cityCode: "MTQ",
    cityName: "Mitchell",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MTR",
    airportName: "S. Jeronimo",
    cityCode: "MTR",
    cityName: "Monteria",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MTS",
    airportName: "Matsapha Intl",
    cityCode: "MTS",
    cityName: "Manzini",
    countryName: "Swaziland",
    countryCode: "SZ",
  },
  {
    airportCode: "MTT",
    airportName: "Minatitlan",
    cityCode: "MTT",
    cityName: "Minatitlan",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MTU",
    airportName: "Montepuez",
    cityCode: "MTU",
    cityName: "Montepuez",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "MTV",
    airportName: "Mota Lava",
    cityCode: "MTV",
    cityName: "Mota Lava",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "MTW",
    airportName: "Municipal",
    cityCode: "MTW",
    cityName: "Manitowoc",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MTX",
    airportName: "Metro Field",
    cityCode: "MTX",
    cityName: "Fairbanks",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MTY",
    airportName: "Gen Mariano Escobedo",
    cityCode: "MTY",
    cityName: "Monterrey",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MTZ",
    airportName: "Masada",
    cityCode: "MTZ",
    cityName: "Masada",
    countryName: "Israel",
    countryCode: "IL",
  },
  {
    airportCode: "MUA",
    airportName: "Munda",
    cityCode: "MUA",
    cityName: "Munda",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "MUB",
    airportName: "Maun",
    cityCode: "MUB",
    cityName: "Maun",
    countryName: "Botswana",
    countryCode: "BW",
  },
  {
    airportCode: "MUC",
    airportName: "Franz Josef Strauss",
    cityCode: "MUC",
    cityName: "Munich",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "MUD",
    airportName: "Mueda",
    cityCode: "MUD",
    cityName: "Mueda",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "MUE",
    airportName: "Kamuela",
    cityCode: "MUE",
    cityName: "Kamuela",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MUF",
    airportName: "Muting",
    cityCode: "MUF",
    cityName: "Muting",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MUG",
    airportName: "Mulege",
    cityCode: "MUG",
    cityName: "Mulege",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MUH",
    airportName: "Mersa Matruh",
    cityCode: "MUH",
    cityName: "Mersa Matruh",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "MUI",
    airportName: "Muir AAF",
    cityCode: "MUI",
    cityName: "Fort Indiantown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MUJ",
    airportName: "Mui",
    cityCode: "MUJ",
    cityName: "Mui",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "MUK",
    airportName: "Mauke Island",
    cityCode: "MUK",
    cityName: "Mauke Island",
    countryName: "Cook Islands",
    countryCode: "CK",
  },
  {
    airportCode: "MUM",
    airportName: "Mumias",
    cityCode: "MUM",
    cityName: "Mumias",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "MUN",
    airportName: "Quiriquire",
    cityCode: "MUN",
    cityName: "Maturin",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "MUO",
    airportName: "AFB",
    cityCode: "MUO",
    cityName: "Mountain Home",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MUP",
    airportName: "Mulga Park",
    cityCode: "MUP",
    cityName: "Mulga Park",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MUQ",
    airportName: "Muccan",
    cityCode: "MUQ",
    cityName: "Muccan",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MUR",
    airportName: "Marudi",
    cityCode: "MUR",
    cityName: "Marudi",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "MUS",
    airportName: "Marcus Island",
    cityCode: "MUS",
    cityName: "Marcus Island",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MUT",
    airportName: "Muscatine",
    cityCode: "MUT",
    cityName: "Muscatine",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MUU",
    airportName: "Mount Union",
    cityCode: "MUU",
    cityName: "Mount Union",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MUV",
    airportName: "Mustin Alf",
    cityCode: "MUV",
    cityName: "Philadelphia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MUW",
    airportName: "Mascara",
    cityCode: "MUW",
    cityName: "Mascara",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "MUX",
    airportName: "Multan Intl.",
    cityCode: "MUX",
    cityName: "Multan",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "MUY",
    airportName: "Mouyondzi",
    cityCode: "MUY",
    cityName: "Mouyondzi",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "MUZ",
    airportName: "Musoma",
    cityCode: "MUZ",
    cityName: "Musoma",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "MVA",
    airportName: "Reykiahlid",
    cityCode: "MVA",
    cityName: "Myvatn",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "MVB",
    airportName: "Franceville/Mvengue",
    cityCode: "MVB",
    cityName: "Franceville",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MVC",
    airportName: "Monroe - Walton County",
    cityCode: "MVC",
    cityName: "Monroeville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MVD",
    airportName: "Carrasco Intl.",
    cityCode: "MVD",
    cityName: "Montevideo",
    countryName: "Uruguay",
    countryCode: "UY",
  },
  {
    airportCode: "MVE",
    airportName: "Montevideo-Chippewa",
    cityCode: "MVE",
    cityName: "Montevideo, MN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MVF",
    airportName: "Dixsept Rosado",
    cityCode: "MVF",
    cityName: "Mossoro",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MVG",
    airportName: "Mevang",
    cityCode: "MVG",
    cityName: "Mevang",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MVH",
    airportName: "Macksville",
    cityCode: "MVH",
    cityName: "Macksville",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MVI",
    airportName: "Manetai",
    cityCode: "MVI",
    cityName: "Manetai",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MVJ",
    airportName: "Marlboro",
    cityCode: "MVJ",
    cityName: "Mandeville",
    countryName: "Jamaica",
    countryCode: "JM",
  },
  {
    airportCode: "MVK",
    airportName: "Mulka",
    cityCode: "MVK",
    cityName: "Mulka",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MVL",
    airportName: "Morrisville-Stowe",
    cityCode: "MVL",
    cityName: "Stowe",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MVM",
    airportName: "Monument Valley",
    cityCode: "MVM",
    cityName: "Kayenta, AZ",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MVN",
    airportName: "Mt Vernon-Outland",
    cityCode: "MVN",
    cityName: "Mt Vernon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MVO",
    airportName: "Mongo",
    cityCode: "MVO",
    cityName: "Mongo",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "MVP",
    airportName: "Mitu",
    cityCode: "MVP",
    cityName: "Mitu",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MVQ",
    airportName: "Mogilev",
    cityCode: "MVQ",
    cityName: "Mogilev",
    countryName: "Belarus",
    countryCode: "BY",
  },
  {
    airportCode: "MVR",
    airportName: "Salam",
    cityCode: "MVR",
    cityName: "Maroua",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "MVS",
    airportName: "Aeroporto Max Feffer",
    cityCode: "MVS",
    cityName: "Mucuri",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "MVT",
    airportName: "Mataiva",
    cityCode: "MVT",
    cityName: "Mataiva",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "MVU",
    airportName: "Musgrave",
    cityCode: "MVU",
    cityName: "Musgrave",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MVV",
    airportName: "Megeve",
    cityCode: "MVV",
    cityName: "Megeve",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "MVW",
    airportName: "Skagit Regional",
    cityCode: "MVW",
    cityName: "Mount Vernon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MVX",
    airportName: "Minvoul",
    cityCode: "MVX",
    cityName: "Minvoul",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MVZ",
    airportName: "Masvingo",
    cityCode: "MVZ",
    cityName: "Masvingo",
    countryName: "Zimbabwe",
    countryCode: "ZW",
  },
  {
    airportCode: "MWA",
    airportName: "Williamson County",
    cityCode: "MWA",
    cityName: "Marion",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MWB",
    airportName: "Morawa",
    cityCode: "MWB",
    cityName: "Morawa",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MWC",
    airportName: "Lawrence J Timmerman",
    cityCode: "MWC",
    cityName: "Milwaukee",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MWD",
    airportName: "Mianwali",
    cityCode: "MWD",
    cityName: "Mianwali",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "MWE",
    airportName: "Merowe",
    cityCode: "MWE",
    cityName: "Merowe",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "MWF",
    airportName: "Maewo",
    cityCode: "MWF",
    cityName: "Maewo",
    countryName: "Vanuatu",
    countryCode: "VU",
  },
  {
    airportCode: "MWG",
    airportName: "Marawaka",
    cityCode: "MWG",
    cityName: "Marawaka",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MWH",
    airportName: "Grant County",
    cityCode: "MWH",
    cityName: "Moses Lake",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MWI",
    airportName: "Maramuni",
    cityCode: "MWI",
    cityName: "Maramuni",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MWJ",
    airportName: "Matthews Ridge",
    cityCode: "MWJ",
    cityName: "Matthews Ridge",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "MWK",
    airportName: "Matak",
    cityCode: "MWK",
    cityName: "Matak",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MWL",
    airportName: "Mineral Wells Arpt",
    cityCode: "MWL",
    cityName: "Mineral Wells",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MWM",
    airportName: "Windom Municipal",
    cityCode: "MWM",
    cityName: "Windom, MN",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MWN",
    airportName: "Mwadui",
    cityCode: "MWN",
    cityName: "Mwadui",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "MWO",
    airportName: "Hook Field",
    cityCode: "MWO",
    cityName: "Middletown, Ohio",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MWQ",
    airportName: "Magwe",
    cityCode: "MWQ",
    cityName: "Magwe",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MWS",
    airportName: "Mount Wilson",
    cityCode: "MWS",
    cityName: "Mount Wilson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MWT",
    airportName: "Moolawatana",
    cityCode: "MWT",
    cityName: "Moolawatana",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MWU",
    airportName: "Mussau",
    cityCode: "MWU",
    cityName: "Mussau",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MWV",
    airportName: "Mundulkiri",
    cityCode: "MWV",
    cityName: "Mundulkiri",
    countryName: "Cambodia",
    countryCode: "KH",
  },
  {
    airportCode: "MWY",
    airportName: "Miranda Downs",
    cityCode: "MWY",
    cityName: "Miranda Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MWZ",
    airportName: "Mwanza",
    cityCode: "MWZ",
    cityName: "Mwanza",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "MXA",
    airportName: "Municipal",
    cityCode: "MXA",
    cityName: "Manila",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MXB",
    airportName: "Masamba",
    cityCode: "MXB",
    cityName: "Masamba",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "MXC",
    airportName: "San Juan County",
    cityCode: "MXC",
    cityName: "Monticello",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MXD",
    airportName: "Marion Downs",
    cityCode: "MXD",
    cityName: "Marion Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MXE",
    airportName: "Maxton",
    cityCode: "MXE",
    cityName: "Maxton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MXG",
    airportName: "Marlborough",
    cityCode: "MXG",
    cityName: "Marlborough",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MXH",
    airportName: "Moro",
    cityCode: "MXH",
    cityName: "Moro",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MXI",
    airportName: "Imelda Romualdez Marcos",
    cityCode: "MXI",
    cityName: "Mati",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "MXJ",
    airportName: "Minna",
    cityCode: "MXJ",
    cityName: "Minna",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "MXK",
    airportName: "Mindik",
    cityCode: "MXK",
    cityName: "Mindik",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MXL",
    airportName: "Mexicali",
    cityCode: "MXL",
    cityName: "Mexicali",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MXM",
    airportName: "Morombe",
    cityCode: "MXM",
    cityName: "Morombe",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "MXN",
    airportName: "Morlaix",
    cityCode: "MXN",
    cityName: "Morlaix",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "MXO",
    airportName: "Municipal",
    cityCode: "MXO",
    cityName: "Monticello",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MXP",
    airportName: "Malpensa",
    cityCode: "MIL",
    cityName: "Milan",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "MXQ",
    airportName: "Mitchell River",
    cityCode: "MXQ",
    cityName: "Mitchell River",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MXR",
    airportName: "Mirgorod",
    cityCode: "MXR",
    cityName: "Mirgorod",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "MXS",
    airportName: "Maota Savaii Is",
    cityCode: "MXS",
    cityName: "Maota Savaii Is",
    countryName: "Samoa",
    countryCode: "WS",
  },
  {
    airportCode: "MXT",
    airportName: "Maintirano",
    cityCode: "MXT",
    cityName: "Maintirano",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "MXU",
    airportName: "Mullewa",
    cityCode: "MXU",
    cityName: "Mullewa",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MXV",
    airportName: "Moron",
    cityCode: "MXV",
    cityName: "Moron",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "MXW",
    airportName: "Mandalgobi",
    cityCode: "MXW",
    cityName: "Mandalgobi",
    countryName: "Mongolia",
    countryCode: "MN",
  },
  {
    airportCode: "MXX",
    airportName: "Mora",
    cityCode: "MXX",
    cityName: "Mora",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "MXY",
    airportName: "Mccarthy",
    cityCode: "MXY",
    cityName: "Mccarthy",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MXZ",
    airportName: "Meixian",
    cityCode: "MXZ",
    cityName: "Meixian",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "MYA",
    airportName: "Moruya",
    cityCode: "MYA",
    cityName: "Moruya",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MYB",
    airportName: "Mayoumba",
    cityCode: "MYB",
    cityName: "Mayoumba",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MYC",
    airportName: "Maracay",
    cityCode: "MYC",
    cityName: "Maracay",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "MYD",
    airportName: "Malindi",
    cityCode: "MYD",
    cityName: "Malindi",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "MYE",
    airportName: "Miyake Jima",
    cityCode: "MYE",
    cityName: "Miyake Jima",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MYF",
    airportName: "Montgomery Field",
    cityCode: "MYF",
    cityName: "San Diego",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MYG",
    airportName: "Mayaguana",
    cityCode: "MYG",
    cityName: "Mayaguana",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "MYH",
    airportName: "Marble Canyon",
    cityCode: "MYH",
    cityName: "Marble Canyon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MYI",
    airportName: "Murray Island",
    cityCode: "MYI",
    cityName: "Murray Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MYJ",
    airportName: "Matsuyama",
    cityCode: "MYJ",
    cityName: "Matsuyama",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "MYK",
    airportName: "May Creek",
    cityCode: "MYK",
    cityName: "May Creek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MYL",
    airportName: "Mccall",
    cityCode: "MYL",
    cityName: "Mccall",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MYM",
    airportName: "Monkey Mountain",
    cityCode: "MYM",
    cityName: "Monkey Mountain",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "MYN",
    airportName: "Mareb",
    cityCode: "MYN",
    cityName: "Mareb",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "MYO",
    airportName: "Myroodah",
    cityCode: "MYO",
    cityName: "Myroodah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "MYP",
    airportName: "Mary",
    cityCode: "MYP",
    cityName: "Mary",
    countryName: "Turkmenistan",
    countryCode: "TM",
  },
  {
    airportCode: "MYQ",
    airportName: "Mysore",
    cityCode: "MYQ",
    cityName: "Mysore",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "MYR",
    airportName: "Myrtle Beach Afb",
    cityCode: "MYR",
    cityName: "Myrtle Beach",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MYS",
    airportName: "Moyale",
    cityCode: "MYS",
    cityName: "Moyale",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "MYT",
    airportName: "Myitkyina",
    cityCode: "MYT",
    cityName: "Myitkyina",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "MYU",
    airportName: "Ellis Field",
    cityCode: "MYU",
    cityName: "Mekoryuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MYV",
    airportName: "Yuba County",
    cityCode: "MYV",
    cityName: "Marysville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MYW",
    airportName: "Mtwara",
    cityCode: "MYW",
    cityName: "Mtwara",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "MYX",
    airportName: "Menyamya",
    cityCode: "MYX",
    cityName: "Menyamya",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MYY",
    airportName: "Miri",
    cityCode: "MYY",
    cityName: "Miri",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "MYZ",
    airportName: "Monkey Bay",
    cityCode: "MYZ",
    cityName: "Monkey Bay",
    countryName: "Malawi",
    countryCode: "MW",
  },
  {
    airportCode: "MZA",
    airportName: "Muzaffarnagar",
    cityCode: "MZA",
    cityName: "Muzaffarnagar",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "MZB",
    airportName: "Mocimboa Praia",
    cityCode: "MZB",
    cityName: "Mocimboa Praia",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "MZC",
    airportName: "Mitzic",
    cityCode: "MZC",
    cityName: "Mitzic",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "MZD",
    airportName: "Mendez",
    cityCode: "MZD",
    cityName: "Mendez",
    countryName: "Ecuador",
    countryCode: "EC",
  },
  {
    airportCode: "MZE",
    airportName: "Manatee",
    cityCode: "MZE",
    cityName: "Manatee",
    countryName: "Belize",
    countryCode: "BZ",
  },
  {
    airportCode: "MZF",
    airportName: "Mzamba",
    cityCode: "MZF",
    cityName: "Mzamba",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "MZG",
    airportName: "Makung",
    cityCode: "MZG",
    cityName: "Makung",
    countryName: "Taiwan",
    countryCode: "TW",
  },
  {
    airportCode: "MZH",
    airportName: "Merzifon",
    cityCode: "MZH",
    cityName: "Merzifon",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "MZI",
    airportName: "Mopti",
    cityCode: "MZI",
    cityName: "Mopti",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "MZJ",
    airportName: "Marana",
    cityCode: "MZJ",
    cityName: "Marana",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "MZK",
    airportName: "Marakei",
    cityCode: "MZK",
    cityName: "Marakei",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "MZL",
    airportName: "Santaguida",
    cityCode: "MZL",
    cityName: "Manizales",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "MZM",
    airportName: "Frescaty",
    cityCode: "MZM",
    cityName: "Metz",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "MZN",
    airportName: "Minj",
    cityCode: "MZN",
    cityName: "Minj",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "MZO",
    airportName: "Sierra Maestra",
    cityCode: "MZO",
    cityName: "Manzanillo",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "MZP",
    airportName: "Motueka",
    cityCode: "MZP",
    cityName: "Motueka",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "MZQ",
    airportName: "Mkuze",
    cityCode: "MZQ",
    cityName: "Mkuze",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "MZR",
    airportName: "Mazar-i-sharif",
    cityCode: "MZR",
    cityName: "Mazar-i-sharif",
    countryName: "Afghanistan",
    countryCode: "AF",
  },
  {
    airportCode: "MZT",
    airportName: "Gen Rafael Buelna",
    cityCode: "MZT",
    cityName: "Mazatlan",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "MZU",
    airportName: "Muzaffarpur",
    cityCode: "MZU",
    cityName: "Muzaffarpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "MZV",
    airportName: "Mulu, Sarawak",
    cityCode: "MZV",
    cityName: "Mulu, Sarawak",
    countryName: "Malaysia",
    countryCode: "MY",
  },
  {
    airportCode: "MZW",
    airportName: "Mechria",
    cityCode: "MZW",
    cityName: "Mechria",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "MZX",
    airportName: "Mena",
    cityCode: "MZX",
    cityName: "Mena",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "MZY",
    airportName: "Mossel Bay",
    cityCode: "MZY",
    cityName: "Mossel Bay",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "MZZ",
    airportName: "Marion",
    cityCode: "MZZ",
    cityName: "Marion",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NAA",
    airportName: "Narrabri",
    cityCode: "NAA",
    cityName: "Narrabri",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NAC",
    airportName: "Naracoorte",
    cityCode: "NAC",
    cityName: "Naracoorte",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NAD",
    airportName: "Macanal",
    cityCode: "NAD",
    cityName: "Macanal",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "NAE",
    airportName: "Natitingou",
    cityCode: "NAE",
    cityName: "Natitingou",
    countryName: "Benin",
    countryCode: "BJ",
  },
  {
    airportCode: "NAF",
    airportName: "Banaina",
    cityCode: "NAF",
    cityName: "Banaina",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NAG",
    airportName: "Sonegaon",
    cityCode: "NAG",
    cityName: "Nagpur",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "NAH",
    airportName: "Naha",
    cityCode: "NAH",
    cityName: "Naha",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NAI",
    airportName: "Annai",
    cityCode: "NAI",
    cityName: "Annai",
    countryName: "Guyana",
    countryCode: "GY",
  },
  {
    airportCode: "NAJ",
    airportName: "Nakhichevan",
    cityCode: "NAJ",
    cityName: "Nakhichevan",
    countryName: "Azerbaijan",
    countryCode: "AZ",
  },
  {
    airportCode: "NAK",
    airportName: "Nakhon Ratchasima",
    cityCode: "NAK",
    cityName: "Nakhon Ratchasima",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "NAL",
    airportName: "Nalchik",
    cityCode: "NAL",
    cityName: "Nalchik",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NAM",
    airportName: "Namlea",
    cityCode: "NAM",
    cityName: "Namlea",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NAN",
    airportName: "Nadi Intl.",
    cityCode: "NAN",
    cityName: "Nadi",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "NAO",
    airportName: "Nanchong",
    cityCode: "NAO",
    cityName: "Nanchong",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NAP",
    airportName: "Capodichino",
    cityCode: "NAP",
    cityName: "Naples",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "NAQ",
    airportName: "Qaanaaq",
    cityCode: "NAQ",
    cityName: "Qaanaaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "NAR",
    airportName: "Nare",
    cityCode: "NAR",
    cityName: "Nare",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "NAS",
    airportName: "Nassau Intl.",
    cityCode: "NAS",
    cityName: "Nassau",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "NAT",
    airportName: "Augusto Severo",
    cityCode: "NAT",
    cityName: "Natal",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "NAU",
    airportName: "Napuka Island",
    cityCode: "NAU",
    cityName: "Napuka Island",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "NAW",
    airportName: "Narathiwat",
    cityCode: "NAW",
    cityName: "Narathiwat",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "NAX",
    airportName: "Barbers Point",
    cityCode: "NAX",
    cityName: "Barbers Point",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NAY",
    airportName: "Nanyuan Arpt.",
    cityCode: "BJS",
    cityName: "Beijing",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NAZ",
    airportName: "Nana",
    cityCode: "NAZ",
    cityName: "Star Habour",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "NBA",
    airportName: "Nambaiyufa",
    cityCode: "NBA",
    cityName: "Nambaiyufa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NBB",
    airportName: "Barrancominas",
    cityCode: "NBB",
    cityName: "Barrancominas",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "NBC",
    airportName: "Naberevnye Chelny",
    cityCode: "NBC",
    cityName: "Naberevnye Chelny",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NBG",
    airportName: "NAS/Alvin Callendar",
    cityCode: "NBG",
    cityName: "New Orleans",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NBH",
    airportName: "Nambucca Heads",
    cityCode: "NBH",
    cityName: "Nambucca Heads",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NBL",
    airportName: "San Blas",
    cityCode: "NBL",
    cityName: "San Blas",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "NBO",
    airportName: "Jomo Kenyatta Intl.",
    cityCode: "NBO",
    cityName: "Nairobi",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "NBP",
    airportName: "Battery Pk City/N. Cov",
    cityCode: "NBP",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NBR",
    airportName: "Nambour",
    cityCode: "NBR",
    cityName: "Nambour",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NBU",
    airportName: "NAS",
    cityCode: "NBU",
    cityName: "Glenview",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NBV",
    airportName: "Cana Brava",
    cityCode: "NBV",
    cityName: "Cana Brava",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "NBW",
    airportName: "Guantanamo NAS",
    cityCode: "NBW",
    cityName: "Guantanamo",
    countryName: "Cuba",
    countryCode: "CU",
  },
  {
    airportCode: "NBX",
    airportName: "Nabire",
    cityCode: "NBX",
    cityName: "Nabire",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NCA",
    airportName: "North Caicos",
    cityCode: "NCA",
    cityName: "North Caicos",
    countryName: "Turks and Caicos Islands",
    countryCode: "TC",
  },
  {
    airportCode: "NCE",
    airportName: "Cote D'azur Intl. Arpt.",
    cityCode: "NCE",
    cityName: "Nice",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "NCG",
    airportName: "Nueva Casas Grandes",
    cityCode: "NCG",
    cityName: "Paloma Grande",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "NCH",
    airportName: "Nachingwea",
    cityCode: "NCH",
    cityName: "Nachingwea",
    countryName: "Tanzania",
    countryCode: "TZ",
  },
  {
    airportCode: "NCI",
    airportName: "Necocli",
    cityCode: "NCI",
    cityName: "Necocli",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "NCL",
    airportName: "Newcastle Arpt.",
    cityCode: "NCL",
    cityName: "Newcastle",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "NCN",
    airportName: "New Chenega",
    cityCode: "NCN",
    cityName: "New Chenega",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NCP",
    airportName: "Cubi Pt NAS",
    cityCode: "NCP",
    cityName: "Luzon Is",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "NCQ",
    airportName: "Atlanta Nas",
    cityCode: "NCQ",
    cityName: "Marietta, GA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NCR",
    airportName: "San Carlos",
    cityCode: "NCR",
    cityName: "San Carlos",
    countryName: "Nicaragua",
    countryCode: "NI",
  },
  {
    airportCode: "NCS",
    airportName: "Newcastle",
    cityCode: "NCS",
    cityName: "Newcastle",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "NCT",
    airportName: "Guanacaste",
    cityCode: "NCT",
    cityName: "Nicoya",
    countryName: "Costa Rica",
    countryCode: "CR",
  },
  {
    airportCode: "NCU",
    airportName: "Nukus",
    cityCode: "NCU",
    cityName: "Nukus",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "NCY",
    airportName: "Annecy-Meythe",
    cityCode: "NCY",
    cityName: "Annecy",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "NDA",
    airportName: "Bandanaira",
    cityCode: "NDA",
    cityName: "Bandanaira",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NDB",
    airportName: "Nouadhibou",
    cityCode: "NDB",
    cityName: "Nouadhibou",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "NDC",
    airportName: "Nanded",
    cityCode: "NDC",
    cityName: "Nanded",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "NDD",
    airportName: "Sumbe",
    cityCode: "NDD",
    cityName: "Sumbe",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "NDE",
    airportName: "Mandera",
    cityCode: "NDE",
    cityName: "Mandera",
    countryName: "Kenya",
    countryCode: "KE",
  },
  {
    airportCode: "NDF",
    airportName: "Ndalatandos",
    cityCode: "NDF",
    cityName: "Ndalatandos",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "NDG",
    airportName: "Qiqihar",
    cityCode: "NDG",
    cityName: "Qiqihar",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NDI",
    airportName: "Namudi",
    cityCode: "NDI",
    cityName: "Namudi",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NDJ",
    airportName: "Ndjamena",
    cityCode: "NDJ",
    cityName: "Ndjamena",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "NDK",
    airportName: "Namdrik Island",
    cityCode: "NDK",
    cityName: "Namdrik Island",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "NDL",
    airportName: "Ndele",
    cityCode: "NDL",
    cityName: "Ndele",
    countryName: "Central African Republic",
    countryCode: "CF",
  },
  {
    airportCode: "NDM",
    airportName: "Mendi",
    cityCode: "NDM",
    cityName: "Mendi",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "NDN",
    airportName: "Nadunumu",
    cityCode: "NDN",
    cityName: "Nadunumu",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NDO",
    airportName: "La Palma Del Condado",
    cityCode: "NDO",
    cityName: "La Palma Del Condado",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "NDP",
    airportName: "Ellyson NAS",
    cityCode: "NDP",
    cityName: "Pensacola, FL",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NDR",
    airportName: "Nador",
    cityCode: "NDR",
    cityName: "Nador",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "NDS",
    airportName: "Sandstone",
    cityCode: "NDS",
    cityName: "Sandstone",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NDU",
    airportName: "Rundu",
    cityCode: "NDU",
    cityName: "Rundu",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "NDV",
    airportName: "USN Heliport",
    cityCode: "NDV",
    cityName: "Anacostia",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NDY",
    airportName: "Sanday",
    cityCode: "NDY",
    cityName: "Sanday",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "NDZ",
    airportName: "Cuxhaven",
    cityCode: "NDZ",
    cityName: "Nordholz-Spieka",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "NEA",
    airportName: "Brunswick Golden Isles",
    cityCode: "NEA",
    cityName: "Glynco",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NEC",
    airportName: "Necochea",
    cityCode: "NEC",
    cityName: "Necochea",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "NEF",
    airportName: "Neftekamsk",
    cityCode: "NEF",
    cityName: "Neftekamsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NEG",
    airportName: "Negril",
    cityCode: "NEG",
    cityName: "Negril",
    countryName: "Jamaica",
    countryCode: "JM",
  },
  {
    airportCode: "NEK",
    airportName: "Nekemt",
    cityCode: "NEK",
    cityName: "Nekemt",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "NEL",
    airportName: "Naec",
    cityCode: "NEL",
    cityName: "Lakehurst",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NEN",
    airportName: "Olf Usn",
    cityCode: "NEN",
    cityName: "Whitehouse",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NER",
    airportName: "Neryungri",
    cityCode: "NER",
    cityName: "Neryungri",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NES",
    airportName: "East 34th St Landing",
    cityCode: "NES",
    cityName: "New York",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NET",
    airportName: "New Bight",
    cityCode: "NET",
    cityName: "New Bight",
    countryName: "Bahamas",
    countryCode: "BS",
  },
  {
    airportCode: "NEU",
    airportName: "Sam Neua",
    cityCode: "NEU",
    cityName: "Sam Neua",
    countryName: "Laos",
    countryCode: "LA",
  },
  {
    airportCode: "NEV",
    airportName: "Newcastle",
    cityCode: "NEV",
    cityName: "Nevis",
    countryName: "Saint Kitts and Nevis",
    countryCode: "KN",
  },
  {
    airportCode: "NEW",
    airportName: "Lakefront",
    cityCode: "NEW",
    cityName: "New Orleans",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NFG",
    airportName: "Nefteyugansk",
    cityCode: "NFG",
    cityName: "Nefteyugansk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NFL",
    airportName: "NAS",
    cityCode: "NFL",
    cityName: "Fallon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NFR",
    airportName: "Nafoora",
    cityCode: "NFR",
    cityName: "Nafoora",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "NGA",
    airportName: "Young",
    cityCode: "NGA",
    cityName: "Young",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NGB",
    airportName: "Lishe Intl. Arpt.",
    cityCode: "NGB",
    cityName: "Ningbo",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NGC",
    airportName: "North Rim",
    cityCode: "NGC",
    cityName: "Grand Canyon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NGD",
    airportName: "Anegada",
    cityCode: "NGD",
    cityName: "Anegada",
    countryName: "Virgin Islands",
    countryCode: "VG",
  },
  {
    airportCode: "NGE",
    airportName: "Ngaoundere",
    cityCode: "NGE",
    cityName: "Ngaoundere",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "NGI",
    airportName: "Zürich-Klote",
    cityCode: "NGI",
    cityName: "Ngau Island",
    countryName: "Fiji",
    countryCode: "FJ",
  },
  {
    airportCode: "NGL",
    airportName: "Ngala Airfield",
    cityCode: "NGL",
    cityName: "Ngala",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "NGM",
    airportName: "Agana NAS",
    cityCode: "NGM",
    cityName: "Agana",
    countryName: "Guam",
    countryCode: "GU",
  },
  {
    airportCode: "NGN",
    airportName: "Nargana",
    cityCode: "NGN",
    cityName: "Nargana",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "NGO",
    airportName: "Nagoya Chubu Centrair International",
    cityCode: "NGO",
    cityName: "Nagoya",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "NGR",
    airportName: "Ningerum",
    cityCode: "NGR",
    cityName: "Ningerum",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NGS",
    airportName: "Nagasaki",
    cityCode: "NGS",
    cityName: "Nagasaki",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "NGU",
    airportName: "Nas Chambers",
    cityCode: "NGU",
    cityName: "Norfolk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NGV",
    airportName: "Ngiva",
    cityCode: "NGV",
    cityName: "Ngiva",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "NGX",
    airportName: "Manang",
    cityCode: "NGX",
    cityName: "Manang",
    countryName: "Nepal",
    countryCode: "NP",
  },
  {
    airportCode: "NGZ",
    airportName: "NAS",
    cityCode: "NGZ",
    cityName: "Alameda",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NHA",
    airportName: "Nha Trang",
    cityCode: "NHA",
    cityName: "Nha Trang",
    countryName: "Viet Nam",
    countryCode: "VN",
  },
  {
    airportCode: "NHD",
    airportName: "Minhad AB",
    cityCode: "NHD",
    cityName: "Minhad AB",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "NHF",
    airportName: "New Halfa",
    cityCode: "NHF",
    cityName: "New Halfa",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "NHK",
    airportName: "Patuxent River NAS",
    cityCode: "NHK",
    cityName: "Patuxent River, MD",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NHS",
    airportName: "Nushki",
    cityCode: "NHS",
    cityName: "Nushki",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "NHT",
    airportName: "Northolt",
    cityCode: "NHT",
    cityName: "Northolt",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "NHV",
    airportName: "Nuku Hiva",
    cityCode: "NHV",
    cityName: "Nuku Hiva",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "NHX",
    airportName: "Barin Olf Osn",
    cityCode: "NHX",
    cityName: "Foley",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NHZ",
    airportName: "NAS",
    cityCode: "NHZ",
    cityName: "Brunswick",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NIA",
    airportName: "Nimba",
    cityCode: "NIA",
    cityName: "Nimba",
    countryName: "Liberia",
    countryCode: "LR",
  },
  {
    airportCode: "NIB",
    airportName: "Nikolai",
    cityCode: "NIB",
    cityName: "Nikolai",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NIC",
    airportName: "Nicosia Intl.",
    cityCode: "NIC",
    cityName: "Nicosia",
    countryName: "Cyprus",
    countryCode: "CY",
  },
  {
    airportCode: "NIE",
    airportName: "Niblack",
    cityCode: "NIE",
    cityName: "Niblack",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NIG",
    airportName: "Nikunau",
    cityCode: "NIG",
    cityName: "Nikunau",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "NIK",
    airportName: "Niokolo Koba",
    cityCode: "NIK",
    cityName: "Niokolo Koba",
    countryName: "Senegal",
    countryCode: "SN",
  },
  {
    airportCode: "NIM",
    airportName: "Niamey",
    cityCode: "NIM",
    cityName: "Niamey",
    countryName: "Niger",
    countryCode: "NE",
  },
  {
    airportCode: "NIN",
    airportName: "Ninilchik",
    cityCode: "NIN",
    cityName: "Ninilchik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NIO",
    airportName: "Nioki",
    cityCode: "NIO",
    cityName: "Nioki",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "NIS",
    airportName: "Simberi",
    cityCode: "NIS",
    cityName: "Simberi Island",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NIT",
    airportName: "Niort",
    cityCode: "NIT",
    cityName: "Niort",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "NIX",
    airportName: "Nioro",
    cityCode: "NIX",
    cityName: "Nioro",
    countryName: "Mali",
    countryCode: "ML",
  },
  {
    airportCode: "NJA",
    airportName: "Atsugi NAS",
    cityCode: "NJA",
    cityName: "Atsugi",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "NJC",
    airportName: "Nizhnevartovsk",
    cityCode: "NJC",
    cityName: "Nizhnevartovsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NJK",
    airportName: "El Centro NAF",
    cityCode: "NJK",
    cityName: "El Centro, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NKA",
    airportName: "Nkan",
    cityCode: "NKA",
    cityName: "Nkan",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "NKB",
    airportName: "Noonkanbah",
    cityCode: "NKB",
    cityName: "Noonkanbah",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NKC",
    airportName: "Nouakchott",
    cityCode: "NKC",
    cityName: "Nouakchott",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "NKD",
    airportName: "Sinak",
    cityCode: "NKD",
    cityName: "Sinak",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "NKG",
    airportName: "Lukou Intl. Arpt.",
    cityCode: "NKG",
    cityName: "Nanking/nanjing",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NKI",
    airportName: "Naukiti",
    cityCode: "NKI",
    cityName: "Naukiti",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NKL",
    airportName: "Nkolo",
    cityCode: "NKL",
    cityName: "Nkolo",
    countryName: "Zaire",
    countryCode: "ZR",
  },
  {
    airportCode: "NKM",
    airportName: "Komaki AFB",
    cityCode: "NKM",
    cityName: "Nagoya",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "NKN",
    airportName: "Nankina",
    cityCode: "NKN",
    cityName: "Nankina",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NKO",
    airportName: "Ankokoambo",
    cityCode: "NKO",
    cityName: "Ankokoambo",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "NKS",
    airportName: "Nkongsamba",
    cityCode: "NKS",
    cityName: "Nkongsamba",
    countryName: "Cameroon",
    countryCode: "CM",
  },
  {
    airportCode: "NKU",
    airportName: "Nkaus",
    cityCode: "NKU",
    cityName: "Nkaus",
    countryName: "Lesotho",
    countryCode: "LS",
  },
  {
    airportCode: "NKV",
    airportName: "Nichen Cove",
    cityCode: "NKV",
    cityName: "Nichen Cove, AK",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NKX",
    airportName: "Miramar MCAS",
    cityCode: "NKX",
    cityName: "San Diego",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NKY",
    airportName: "Nkayi",
    cityCode: "NKY",
    cityName: "Nkayi",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "NLA",
    airportName: "Ndola",
    cityCode: "NLA",
    cityName: "Ndola",
    countryName: "Zambia",
    countryCode: "ZM",
  },
  {
    airportCode: "NLC",
    airportName: "Lemoore NAS",
    cityCode: "NLC",
    cityName: "Lemoore, CA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NLD",
    airportName: "Intl Quetzalcoatl",
    cityCode: "NLD",
    cityName: "Nuevo Laredo",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "NLE",
    airportName: "Jerry Tyler Memorial",
    cityCode: "NLE",
    cityName: "Niles",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NLF",
    airportName: "Darnley Island",
    cityCode: "NLF",
    cityName: "Darnley Island",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NLG",
    airportName: "Nelson Lagoon",
    cityCode: "NLG",
    cityName: "Nelson Lagoon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NLK",
    airportName: "Norfolk Island",
    cityCode: "NLK",
    cityName: "Norfolk Island",
    countryName: "Norfolk Island",
    countryCode: "NF",
  },
  {
    airportCode: "NLL",
    airportName: "Nullagine",
    cityCode: "NLL",
    cityName: "Nullagine",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NLP",
    airportName: "Nelspruit Intl.",
    cityCode: "NLP",
    cityName: "Nelspruit",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "NLS",
    airportName: "Nicholson",
    cityCode: "NLS",
    cityName: "Nicholson",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NLU",
    airportName: "Santa Lucia",
    cityCode: "NLU",
    cityName: "Santa Lucia",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "NLV",
    airportName: "Nikolaev",
    cityCode: "NLV",
    cityName: "Nikolaev",
    countryName: "Ukraine",
    countryCode: "UA",
  },
  {
    airportCode: "NMA",
    airportName: "Namangan",
    cityCode: "NMA",
    cityName: "Namangan",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "NMB",
    airportName: "Daman",
    cityCode: "NMB",
    cityName: "Daman",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "NME",
    airportName: "Nightmute",
    cityCode: "NME",
    cityName: "Nightmute",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NMG",
    airportName: "San Miguel",
    cityCode: "NMG",
    cityName: "San Miguel",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "NMN",
    airportName: "Nomane",
    cityCode: "NMN",
    cityName: "Nomane",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "NMP",
    airportName: "New Moon",
    cityCode: "NMP",
    cityName: "New Moon",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NMR",
    airportName: "Nappa Merry",
    cityCode: "NMR",
    cityName: "Nappa Merry",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "NMS",
    airportName: "Namsang",
    cityCode: "NMS",
    cityName: "Namsang",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "NMT",
    airportName: "Namtu",
    cityCode: "NMT",
    cityName: "Namtu",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "NMU",
    airportName: "Namu",
    cityCode: "NMU",
    cityName: "Namu",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "NNA",
    airportName: "NAF",
    cityCode: "NNA",
    cityName: "Kenitra",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "NNB",
    airportName: "Santa Ana",
    cityCode: "NNB",
    cityName: "Santa Ana",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "NND",
    airportName: "Nangade",
    cityCode: "NND",
    cityName: "Nangade",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "NNG",
    airportName: "Nanning",
    cityCode: "NNG",
    cityName: "Nanning",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "NNI",
    airportName: "Namutoni",
    cityCode: "NNI",
    cityName: "Namutoni",
    countryName: "Namibia",
    countryCode: "NA",
  },
  {
    airportCode: "NNK",
    airportName: "Naknek",
    cityCode: "NNK",
    cityName: "Naknek",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NNL",
    airportName: "Nondalton",
    cityCode: "NNL",
    cityName: "Nondalton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "NNM",
    airportName: "Naryan-Mar",
    cityCode: "NNM",
    cityName: "Naryan-Mar",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "NNR",
    airportName: "Connemara",
    cityCode: "NNR",
    cityName: "Spiddal",
    countryName: "Ireland",
    countryCode: "IE",
  },
  {
    airportCode: "TYO",
    airportName: "Tokyo Metropolitan Area",
    cityCode: "TYO",
    cityName: "Tokyo",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "ULV ",
    airportName: "Ulyanovsk Baratayevka Airport",
    cityCode: "ULY",
    cityName: "Ulyanovsk",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "BFN",
    airportName: "Bloemfontein",
    cityCode: "BFN",
    cityName: "Bloemfontein",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "GLA",
    airportName: "Glasgow Intl.",
    cityCode: "GLA",
    cityName: "Glasgow",
    countryName: "United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "MVY",
    airportName: "Marthas Vineyard Airport",
    cityCode: "MVY",
    cityName: "Vineyard Haven, MA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "XMB",
    airportName: "Dubai EY Bus Station",
    cityCode: "DXB",
    cityName: "Dubai",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "QUO",
    airportName: "Uyo",
    cityCode: "QUO",
    cityName: "Uyo",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "DMS",
    airportName: "SABTCO BUS ST",
    cityCode: "DMM",
    cityName: "Dammam",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "AKA",
    airportName: "Ankang",
    cityCode: "AKA",
    cityName: "Ankang",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "AKB",
    airportName: "Atka",
    cityCode: "AKB",
    cityName: "Atka",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AKC",
    airportName: "Fulton Intl.",
    cityCode: "AKC",
    cityName: "Akron/Canton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AKD",
    airportName: "Akola",
    cityCode: "AKD",
    cityName: "Akola",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "AKE",
    airportName: "Akieni",
    cityCode: "AKE",
    cityName: "Akieni",
    countryName: "Gabon",
    countryCode: "GA",
  },
  {
    airportCode: "AKF",
    airportName: "Kufrah",
    cityCode: "AKF",
    cityName: "Kufrah",
    countryName: "Libya",
    countryCode: "LY",
  },
  {
    airportCode: "AKG",
    airportName: "Anguganak",
    cityCode: "AKG",
    cityName: "Anguganak",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AKH",
    airportName: "Prince Sultan Air Base",
    cityCode: "AKH",
    cityName: "Al Kharj",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "AKI",
    airportName: "Akiak",
    cityCode: "AKI",
    cityName: "Akiak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AKJ",
    airportName: "Asahikawa",
    cityCode: "AKJ",
    cityName: "Asahikawa",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "AKK",
    airportName: "Akhiok SPB",
    cityCode: "AKK",
    cityName: "Akhiok",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AKL",
    airportName: "Auckland Intl.",
    cityCode: "AKL",
    cityName: "Auckland",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "AKM",
    airportName: "Zakouma",
    cityCode: "AKM",
    cityName: "Zakouma",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "AKN",
    airportName: "King Salmon",
    cityCode: "AKN",
    cityName: "King Salmon",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AKO",
    airportName: "Colorado Plains Regional Arpt.",
    cityCode: "AKO",
    cityName: "Akron, CO",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AKP",
    airportName: "Anaktuvuk",
    cityCode: "AKP",
    cityName: "Anaktuvuk",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AKQ",
    airportName: "Gunung Batin",
    cityCode: "AKQ",
    cityName: "Astraksetra",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AKR",
    airportName: "Akure",
    cityCode: "AKR",
    cityName: "Akure",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "AKT",
    airportName: "Akrotiri Raf",
    cityCode: "AKT",
    cityName: "Akrotiri",
    countryName: "Cyprus",
    countryCode: "CY",
  },
  {
    airportCode: "AKU",
    airportName: "Aksu",
    cityCode: "AKU",
    cityName: "Aksu",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "AKV",
    airportName: "Akulivik",
    cityCode: "AKV",
    cityName: "Akulivik",
    countryName: "Canada",
    countryCode: "CA",
  },
  {
    airportCode: "AKW",
    airportName: "Aghajari",
    cityCode: "AKW",
    cityName: "Aghajari",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "AKX",
    airportName: "Aktyubinsk",
    cityCode: "AKX",
    cityName: "Aktyubinsk",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "AKY",
    airportName: "Civil",
    cityCode: "AKY",
    cityName: "Sittwe",
    countryName: "Myanmar",
    countryCode: "MM",
  },
  {
    airportCode: "ALA",
    airportName: "Almaty",
    cityCode: "ALA",
    cityName: "Almaty",
    countryName: "Kazakhstan",
    countryCode: "KZ",
  },
  {
    airportCode: "ALB",
    airportName: "Albany Intl.",
    cityCode: "ALB",
    cityName: "Albany",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ALC",
    airportName: "Alicante El Altet",
    cityCode: "ALC",
    cityName: "Alicante",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "ALD",
    airportName: "Alerta",
    cityCode: "ALD",
    cityName: "Alerta",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "ALE",
    airportName: "Alpine",
    cityCode: "ALE",
    cityName: "Alpine",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ALF",
    airportName: "Alta",
    cityCode: "ALF",
    cityName: "Alta",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "ALG",
    airportName: "Houari Boumediene",
    cityCode: "ALG",
    cityName: "Algiers",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "ALH",
    airportName: "Albany",
    cityCode: "ALH",
    cityName: "Albany",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ALI",
    airportName: "Intl.",
    cityCode: "ALI",
    cityName: "Alice",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ALJ",
    airportName: "Kortdoorn",
    cityCode: "ALJ",
    cityName: "Alexander Bay",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "ALK",
    airportName: "Asela",
    cityCode: "ALK",
    cityName: "Asela",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "ALL",
    airportName: "Albenga",
    cityCode: "ALL",
    cityName: "Albenga",
    countryName: "Italy",
    countryCode: "IT",
  },
  {
    airportCode: "ALM",
    airportName: "Municipal",
    cityCode: "ALM",
    cityName: "Alamogordo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ALN",
    airportName: "Alton",
    cityCode: "ALN",
    cityName: "Alton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ALO",
    airportName: "Waterloo",
    cityCode: "ALO",
    cityName: "Waterloo",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ALP",
    airportName: "Nejrab",
    cityCode: "ALP",
    cityName: "Aleppo",
    countryName: "Syria",
    countryCode: "SY",
  },
  {
    airportCode: "ALQ",
    airportName: "Federal",
    cityCode: "ALQ",
    cityName: "Alegrete",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ALR",
    airportName: "Alexandra",
    cityCode: "ALR",
    cityName: "Alexandra",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "ALS",
    airportName: "Municipal",
    cityCode: "ALS",
    cityName: "Alamosa",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ALT",
    airportName: "Alenquer",
    cityCode: "ALT",
    cityName: "Alenquer",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "ALU",
    airportName: "Alula",
    cityCode: "ALU",
    cityName: "Alula",
    countryName: "Somalia",
    countryCode: "SO",
  },
  {
    airportCode: "ALV",
    airportName: "Andorra La Vella H/P",
    cityCode: "ALV",
    cityName: "Andorra La Vella",
    countryName: "Andorra",
    countryCode: "AD",
  },
  {
    airportCode: "AMA",
    airportName: "Rick Husband Amarillo Intl.",
    cityCode: "AMA",
    cityName: "Amarillo, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AMB",
    airportName: "Ambilobe",
    cityCode: "AMB",
    cityName: "Ambilobe",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "AMC",
    airportName: "Am Timan",
    cityCode: "AMC",
    cityName: "Am Timan",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "AMD",
    airportName: "Ahmedabad",
    cityCode: "AMD",
    cityName: "Ahmedabad",
    countryName: "India",
    countryCode: "IN",
  },
  {
    airportCode: "AME",
    airportName: "Alto Molocue",
    cityCode: "AME",
    cityName: "Alto Molocue",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "AMF",
    airportName: "Ama",
    cityCode: "AMF",
    cityName: "Ama",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AMG",
    airportName: "Amboin",
    cityCode: "AMG",
    cityName: "Amboin",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AMH",
    airportName: "Arba Mintch",
    cityCode: "AMH",
    cityName: "Arba Mintch",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "AMI",
    airportName: "Selaparang",
    cityCode: "AMI",
    cityName: "Mataram",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AMJ",
    airportName: "Almenara",
    cityCode: "AMJ",
    cityName: "Almenara",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AMK",
    airportName: "Animas Airpark",
    cityCode: "AMK",
    cityName: "Durango",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AML",
    airportName: "Puerto Armuellas",
    cityCode: "AML",
    cityName: "Puerto Armuellas",
    countryName: "Panama",
    countryCode: "PA",
  },
  {
    airportCode: "AMM",
    airportName: "Queen Alia Intl",
    cityCode: "AMM",
    cityName: "Amman",
    countryName: "Jordan",
    countryCode: "JO",
  },
  {
    airportCode: "AMN",
    airportName: "Gratiot Community",
    cityCode: "AMN",
    cityName: "Alma",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AMO",
    airportName: "Mao",
    cityCode: "AMO",
    cityName: "Mao",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "AMP",
    airportName: "Ampanihy",
    cityCode: "AMP",
    cityName: "Ampanihy",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "AMQ",
    airportName: "Pattimura",
    cityCode: "AMQ",
    cityName: "Ambon",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AMR",
    airportName: "Arno",
    cityCode: "AMR",
    cityName: "Arno",
    countryName: "Marshall Islands",
    countryCode: "MH",
  },
  {
    airportCode: "AMS",
    airportName: "Amsterdam-Schiphol",
    cityCode: "AMS",
    cityName: "Amsterdam",
    countryName: "Netherlands",
    countryCode: "NL",
  },
  {
    airportCode: "AMT",
    airportName: "Amata",
    cityCode: "AMT",
    cityName: "Amata",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AMU",
    airportName: "Amanab",
    cityCode: "AMU",
    cityName: "Amanab",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AMV",
    airportName: "Amderma",
    cityCode: "AMV",
    cityName: "Amderma",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "AMW",
    airportName: "Ames",
    cityCode: "AMW",
    cityName: "Ames",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AMX",
    airportName: "Ammaroo",
    cityCode: "AMX",
    cityName: "Ammaroo",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AMY",
    airportName: "Ambatomainty",
    cityCode: "AMY",
    cityName: "Ambatomainty",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "AMZ",
    airportName: "Ardmore",
    cityCode: "AMZ",
    cityName: "Ardmore",
    countryName: "New Zealand",
    countryCode: "NZ",
  },
  {
    airportCode: "ANA",
    airportName: "Orange County Steel Salvage Heliport",
    cityCode: "ANA",
    cityName: "Anaheim",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANB",
    airportName: "Anniston Metropolitan",
    cityCode: "ANB",
    cityName: "Anniston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANC",
    airportName: "Ted Stevens Anchorage Intl. Arpt.",
    cityCode: "ANC",
    cityName: "Anchorage",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AND",
    airportName: "Anderson",
    cityCode: "AND",
    cityName: "Anderson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANE",
    airportName: "Marce",
    cityCode: "ANE",
    cityName: "Angers",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "ANF",
    airportName: "Cerro Moreno",
    cityCode: "ANF",
    cityName: "Antofagasta",
    countryName: "Chile",
    countryCode: "CL",
  },
  {
    airportCode: "ANG",
    airportName: "Brie-Champniers",
    cityCode: "ANG",
    cityName: "Angouleme",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "ANH",
    airportName: "Anuha Island Resort",
    cityCode: "ANH",
    cityName: "Anuha Island Resort",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "ANI",
    airportName: "Aniak",
    cityCode: "ANI",
    cityName: "Aniak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANJ",
    airportName: "Zanaga",
    cityCode: "ANJ",
    cityName: "Zanaga",
    countryName: "Congo",
    countryCode: "CG",
  },
  {
    airportCode: "ANK",
    airportName: "Etimesgut",
    cityCode: "ANK",
    cityName: "Ankara",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "ANL",
    airportName: "Andulo",
    cityCode: "ANL",
    cityName: "Andulo",
    countryName: "Angola",
    countryCode: "AO",
  },
  {
    airportCode: "ANM",
    airportName: "Antsirabato",
    cityCode: "ANM",
    cityName: "Antalaha",
    countryName: "Madagascar",
    countryCode: "MG",
  },
  {
    airportCode: "ANN",
    airportName: "Annette Island",
    cityCode: "ANN",
    cityName: "Annette Island",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANO",
    airportName: "Angoche",
    cityCode: "ANO",
    cityName: "Angoche",
    countryName: "Mozambique",
    countryCode: "MZ",
  },
  {
    airportCode: "ANP",
    airportName: "Lee",
    cityCode: "ANP",
    cityName: "Annapolis",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANQ",
    airportName: "Tri-State Steuben Cty",
    cityCode: "ANQ",
    cityName: "Angola",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANR",
    airportName: "Deurne",
    cityCode: "ANR",
    cityName: "Antwerp",
    countryName: "Belgium",
    countryCode: "BE",
  },
  {
    airportCode: "ANS",
    airportName: "Andahuaylas",
    cityCode: "ANS",
    cityName: "Andahuaylas",
    countryName: "Peru",
    countryCode: "PE",
  },
  {
    airportCode: "ANU",
    airportName: "V. C. Bird Intl",
    cityCode: "ANU",
    cityName: "Antigua",
    countryName: "Antigua And Barbuda",
    countryCode: "AG",
  },
  {
    airportCode: "ANV",
    airportName: "Anvik",
    cityCode: "ANV",
    cityName: "Anvik",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANW",
    airportName: "Ainsworth",
    cityCode: "ANW",
    cityName: "Ainsworth",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANX",
    airportName: "Andenes",
    cityCode: "ANX",
    cityName: "Andenes",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "ANY",
    airportName: "Anthony",
    cityCode: "ANY",
    cityName: "Anthony",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ANZ",
    airportName: "Angus Downs",
    cityCode: "ANZ",
    cityName: "Angus Downs",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AOA",
    airportName: "Aroa",
    cityCode: "AOA",
    cityName: "Aroa",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AOB",
    airportName: "Annanberg",
    cityCode: "AOB",
    cityName: "Annanberg",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AOC",
    airportName: "Altenburg Nobitz",
    cityCode: "AOC",
    cityName: "Altenburg",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "AEA",
    airportName: "Abemama Atoll",
    cityCode: "AEA",
    cityName: "Abemama Atoll",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "AED",
    airportName: "Aleneva",
    cityCode: "AED",
    cityName: "Aleneva",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AEG",
    airportName: "Aek Godang",
    cityCode: "AEG",
    cityName: "Aek Godang",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AEH",
    airportName: "Abecher",
    cityCode: "AEH",
    cityName: "Abecher",
    countryName: "Chad",
    countryCode: "TD",
  },
  {
    airportCode: "AEK",
    airportName: "Aseki",
    cityCode: "AEK",
    cityName: "Aseki",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AEL",
    airportName: "Albert Lea",
    cityCode: "AEL",
    cityName: "Albert Lea",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AEO",
    airportName: "Aioun El Atrouss",
    cityCode: "AEO",
    cityName: "Aioun El Atrouss",
    countryName: "Mauritania",
    countryCode: "MR",
  },
  {
    airportCode: "AEP",
    airportName: "Arpt. Jorge Newbery",
    cityCode: "BUE",
    cityName: "Buenos Aires",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "AER",
    airportName: "Adler/Sochi",
    cityCode: "AER",
    cityName: "Adler/Sochi",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "AES",
    airportName: "Vigra",
    cityCode: "AES",
    cityName: "Aalesund",
    countryName: "Norway",
    countryCode: "NO",
  },
  {
    airportCode: "AET",
    airportName: "Allakaket",
    cityCode: "AET",
    cityName: "Allakaket",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AEU",
    airportName: "Abu Musa",
    cityCode: "AEU",
    cityName: "Abu Musa",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "AEX",
    airportName: "Alexandria Intl.",
    cityCode: "AEX",
    cityName: "Alexandria, LA",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AEY",
    airportName: "Akureyri",
    cityCode: "AEY",
    cityName: "Akureyri",
    countryName: "Iceland",
    countryCode: "IS",
  },
  {
    airportCode: "AFA",
    airportName: "San Rafael",
    cityCode: "AFA",
    cityName: "San Rafael",
    countryName: "Argentina",
    countryCode: "AR",
  },
  {
    airportCode: "AFD",
    airportName: "Port Alfred",
    cityCode: "AFD",
    cityName: "Port Alfred",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "AFF",
    airportName: "USAF Academy Airstrip",
    cityCode: "AFF",
    cityName: "Colorado Springs",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AFI",
    airportName: "Amalfi",
    cityCode: "AFI",
    cityName: "Amalfi",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "AFK",
    airportName: "Kondavattavan Tank",
    cityCode: "AFK",
    cityName: "Ampara",
    countryName: "Sri Lanka",
    countryCode: "LK",
  },
  {
    airportCode: "AFL",
    airportName: "Alta Floresta",
    cityCode: "AFL",
    cityName: "Alta Floresta",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AFN",
    airportName: "Municipal",
    cityCode: "AFN",
    cityName: "Jaffrey",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AFO",
    airportName: "Municipal",
    cityCode: "AFO",
    cityName: "Afton",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AFR",
    airportName: "Afore",
    cityCode: "AFR",
    cityName: "Afore",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AFS",
    airportName: "Zarafshan",
    cityCode: "AFS",
    cityName: "Zarafshan",
    countryName: "Uzbekistan",
    countryCode: "UZ",
  },
  {
    airportCode: "AFT",
    airportName: "Afutara Aerodrome",
    cityCode: "AFT",
    cityName: "Afutara",
    countryName: "Solomon Islands",
    countryCode: "SB",
  },
  {
    airportCode: "AFW",
    airportName: "Fort Worth Alliance",
    cityCode: "AFW",
    cityName: "Fort Worth, TX",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AFY",
    airportName: "Afyon",
    cityCode: "AFY",
    cityName: "Afyon",
    countryName: "Turkey",
    countryCode: "TR",
  },
  {
    airportCode: "AFZ",
    airportName: "Sabzevar",
    cityCode: "AFZ",
    cityName: "Sabzevar",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "AGA",
    airportName: "Agadir Almassira",
    cityCode: "AGA",
    cityName: "Agadir",
    countryName: "Morocco",
    countryCode: "MA",
  },
  {
    airportCode: "AGB",
    airportName: "Augsburg - Muehlhausen",
    cityCode: "AGB",
    cityName: "Munich",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "AGC",
    airportName: "Allegheny County",
    cityCode: "AGC",
    cityName: "Pittsburgh",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AGD",
    airportName: "Anggi",
    cityCode: "AGD",
    cityName: "Anggi",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AGE",
    airportName: "Flugplatz",
    cityCode: "AGE",
    cityName: "Wangerooge",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "AGF",
    airportName: "La Garenne",
    cityCode: "AGF",
    cityName: "Agen",
    countryName: "France",
    countryCode: "FR",
  },
  {
    airportCode: "AGG",
    airportName: "Angoram",
    cityCode: "AGG",
    cityName: "Angoram",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AGH",
    airportName: "Angelholm",
    cityCode: "AGH",
    cityName: "Angelholm/Helsingborg",
    countryName: "Sweden",
    countryCode: "SE",
  },
  {
    airportCode: "AGI",
    airportName: "Wageningen",
    cityCode: "AGI",
    cityName: "Wageningen",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "AGJ",
    airportName: "Aguni",
    cityCode: "AGJ",
    cityName: "Aguni",
    countryName: "Japan",
    countryCode: "JP",
  },
  {
    airportCode: "AGK",
    airportName: "Kagua",
    cityCode: "AGK",
    cityName: "Kagua",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AGL",
    airportName: "Wanigela",
    cityCode: "AGL",
    cityName: "Wanigela",
    countryName: "Papua New Guinea",
    countryCode: "PG",
  },
  {
    airportCode: "AGM",
    airportName: "Tasiilaq",
    cityCode: "AGM",
    cityName: "Tasiilaq",
    countryName: "Greenland",
    countryCode: "GL",
  },
  {
    airportCode: "AGV",
    airportName: "Acarigua",
    cityCode: "AGV",
    cityName: "Acarigua",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "ALY",
    airportName: "El Nohza",
    cityCode: "ALY",
    cityName: "Alexandria",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "ALZ",
    airportName: "Alitak SPB",
    cityCode: "ALZ",
    cityName: "Alitak",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "0A7",
    airportName: "Hendersonville Arpt.",
    cityCode: "0A7",
    cityName: "Hendersonville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AAA",
    airportName: "AnaaTest",
    cityCode: "AAA",
    cityName: "Anaa",
    countryName: "French Polynesia",
    countryCode: "PF",
  },
  {
    airportCode: "AAB",
    airportName: "Arrabury",
    cityCode: "AAB",
    cityName: "Arrabury",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "AAC",
    airportName: "Al Arish Intl. Arpt.",
    cityCode: "AAC",
    cityName: "Al Arish",
    countryName: "Egypt",
    countryCode: "EG",
  },
  {
    airportCode: "AAD",
    airportName: "Ad-Dabbah",
    cityCode: "AAD",
    cityName: "Ad-Dabbah",
    countryName: "Sudan",
    countryCode: "SD",
  },
  {
    airportCode: "AAE",
    airportName: "Les Salines",
    cityCode: "AAE",
    cityName: "Annaba",
    countryName: "Algeria",
    countryCode: "DZ",
  },
  {
    airportCode: "AAF",
    airportName: "Municipal",
    cityCode: "AAF",
    cityName: "Apalachicola",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AAG",
    airportName: "Arapoti",
    cityCode: "AAG",
    cityName: "Arapoti",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AAH",
    airportName: "Aachen/Merzbruck",
    cityCode: "AAH",
    cityName: "Aachen",
    countryName: "Germany",
    countryCode: "DE",
  },
  {
    airportCode: "AAI",
    airportName: "Arraias",
    cityCode: "AAI",
    cityName: "Arraias",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AAJ",
    airportName: "Cayana Airstrip",
    cityCode: "AAJ",
    cityName: "Awaradam",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "AAK",
    airportName: "Aranuka",
    cityCode: "AAK",
    cityName: "Aranuka",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "AAL",
    airportName: "Aalborg",
    cityCode: "AAL",
    cityName: "Aalborg",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "AAM",
    airportName: "Mala Mala",
    cityCode: "AAM",
    cityName: "Mala Mala",
    countryName: "South Africa",
    countryCode: "ZA",
  },
  {
    airportCode: "AAN",
    airportName: "Al Ain",
    cityCode: "AAN",
    cityName: "Al Ain",
    countryName: "United Arab Emirates",
    countryCode: "AE",
  },
  {
    airportCode: "AAO",
    airportName: "Anaco",
    cityCode: "AAO",
    cityName: "Anaco",
    countryName: "Venezuela",
    countryCode: "VE",
  },
  {
    airportCode: "AAP",
    airportName: "Andrau Airpark",
    cityCode: "HOU",
    cityName: "Houston",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "AAQ",
    airportName: "Anapa",
    cityCode: "AAQ",
    cityName: "Anapa",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "AAR",
    airportName: "Aarhus Arpt.",
    cityCode: "AAR",
    cityName: "Aarhus",
    countryName: "Denmark",
    countryCode: "DK",
  },
  {
    airportCode: "AAS",
    airportName: "Apalapsili",
    cityCode: "AAS",
    cityName: "Apalapsili",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "AAT",
    airportName: "Altay",
    cityCode: "AAT",
    cityName: "Altay",
    countryName: "China",
    countryCode: "CN",
  },
  {
    airportCode: "AAU",
    airportName: "Asau",
    cityCode: "AAU",
    cityName: "Asau",
    countryName: "Samoa",
    countryCode: "WS",
  },
  {
    airportCode: "AAV",
    airportName: "Allah Valley",
    cityCode: "AAV",
    cityName: "Surallah",
    countryName: "Philippines",
    countryCode: "PH",
  },
  {
    airportCode: "AAW",
    airportName: "Abbottabad",
    cityCode: "AAW",
    cityName: "Abbottabad",
    countryName: "Pakistan",
    countryCode: "PK",
  },
  {
    airportCode: "AAX",
    airportName: "Araxa",
    cityCode: "AAX",
    cityName: "Araxa",
    countryName: "Brazil",
    countryCode: "BR",
  },
  {
    airportCode: "AAY",
    airportName: "Al Ghaydah",
    cityCode: "AAY",
    cityName: "Al Ghaydah",
    countryName: "Yemen",
    countryCode: "YE",
  },
  {
    airportCode: "AAZ",
    airportName: "Quetzaltenango Airport",
    cityCode: "AAZ",
    cityName: "Quetzaltenango",
    countryName: "Guatemala",
    countryCode: "GT",
  },
  {
    airportCode: "ABA",
    airportName: "Abakan",
    cityCode: "ABA",
    cityName: "Abakan",
    countryName: "Russia",
    countryCode: "RU",
  },
  {
    airportCode: "ABB",
    airportName: "Asaba International Airport",
    cityCode: "ABB",
    cityName: "Asaba",
    countryName: "Nigeria",
    countryCode: "NG",
  },
  {
    airportCode: "ABC",
    airportName: "Los Llanos",
    cityCode: "ABC",
    cityName: "Albacete",
    countryName: "Spain",
    countryCode: "ES",
  },
  {
    airportCode: "ABD",
    airportName: "Abadan",
    cityCode: "ABD",
    cityName: "Abadan",
    countryName: "Iran",
    countryCode: "IR",
  },
  {
    airportCode: "ABE",
    airportName: "Lehigh Valley Intl.",
    cityCode: "ABE",
    cityName: "Allentown",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ABF",
    airportName: "Abaiang",
    cityCode: "ABF",
    cityName: "Abaiang",
    countryName: "Kiribati",
    countryCode: "KI",
  },
  {
    airportCode: "ABG",
    airportName: "Abingdon",
    cityCode: "ABG",
    cityName: "Abingdon",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ABH",
    airportName: "Alpha",
    cityCode: "ABH",
    cityName: "Alpha",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ABI",
    airportName: "Municipal",
    cityCode: "ABI",
    cityName: "Abilene",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ABJ",
    airportName: "Felix Houphouet Boigny",
    cityCode: "ABJ",
    cityName: "Abidjan",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "ABK",
    airportName: "Kabri Dar",
    cityCode: "ABK",
    cityName: "Kabri Dar",
    countryName: "Ethiopia",
    countryCode: "ET",
  },
  {
    airportCode: "ABL",
    airportName: "Ambler",
    cityCode: "ABL",
    cityName: "Ambler",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ABM",
    airportName: "Bamaga Injinoo",
    cityCode: "ABM",
    cityName: "Bamaga, Queensland",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ABN",
    airportName: "Albina",
    cityCode: "ABN",
    cityName: "Albina",
    countryName: "Suriname",
    countryCode: "SR",
  },
  {
    airportCode: "ABO",
    airportName: "Aboisso",
    cityCode: "ABO",
    cityName: "Aboisso",
    countryName: "Cote D'Ivoire",
    countryCode: "CI",
  },
  {
    airportCode: "ABX",
    airportName: "Albury",
    cityCode: "ABX",
    cityName: "Albury",
    countryName: "Australia",
    countryCode: "AU",
  },
  {
    airportCode: "ABY",
    airportName: "Dougherty County",
    cityCode: "ABY",
    cityName: "Albany",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ABZ",
    airportName: "Dyce",
    cityCode: "ABZ",
    cityName: "Aberdeen",
    countryName: "Scotland, United Kingdom",
    countryCode: "GB",
  },
  {
    airportCode: "ACA",
    airportName: "General Juan N. Alvarez Intl.",
    cityCode: "ACA",
    cityName: "Acapulco",
    countryName: "Mexico",
    countryCode: "MX",
  },
  {
    airportCode: "ACB",
    airportName: "Antrim County",
    cityCode: "ACB",
    cityName: "Bellaire",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ACC",
    airportName: "Kotoka",
    cityCode: "ACC",
    cityName: "Accra",
    countryName: "Ghana",
    countryCode: "GH",
  },
  {
    airportCode: "ACD",
    airportName: "Acandi",
    cityCode: "ACD",
    cityName: "Acandi",
    countryName: "Colombia",
    countryCode: "CO",
  },
  {
    airportCode: "FLZ",
    airportName: "Ferdinand Lumban Tobing Airport",
    cityCode: "FLZ",
    cityName: "Sibolga",
    countryName: "Indonesia",
    countryCode: "ID",
  },
  {
    airportCode: "CXB",
    airportName: "Cox's Bazar International Airport",
    cityCode: "CXB",
    cityName: "Cox's Bazar",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "RJH",
    airportName: "Shah Makhdum Airport",
    cityCode: "RJH",
    cityName: "Rajshahi",
    countryName: "Bangladesh",
    countryCode: "BD",
  },
  {
    airportCode: "BKK",
    airportName: "Suvarnabhumi Airport",
    cityCode: "BKK",
    cityName: "Bangkok",
    countryName: "Thailand",
    countryCode: "TH",
  },
  {
    airportCode: "DSV",
    airportName: "Dansville Municipal Airport",
    cityCode: "DSV",
    cityName: "Dansville",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "HCC",
    airportName: "Columbia County Airport",
    cityCode: "HCC",
    cityName: "Hudson",
    countryName: "United States",
    countryCode: "US",
  },
  {
    airportCode: "ABT",
    airportName: "Al-Baha Domestic",
    cityCode: "ABT",
    cityName: "AL BAHAH",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "ULH",
    airportName: "Al-Ahsa International Airport",
    cityCode: "ULH",
    cityName: "Al -Ula",
    countryName: "Bangladesh",
    countryCode: "SA",
  },
  {
    airportCode: "NUM",
    airportName: "Neom Bay Airport",
    cityCode: "NUM",
    cityName: "Neom",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
  {
    airportCode: "ABT",
    airportName: "Al-Baha Domestic Airport",
    cityCode: "ABT",
    cityName: "Al Bahah",
    countryName: "Saudi Arabia",
    countryCode: "SA",
  },
];

export default airportDetails;
